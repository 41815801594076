import * as React from 'react'

import './TimeRangeInput.scss'
import TimeInput from './TimeInput'
import { DateTimeRange, Duration } from '../utils'

interface Props {
  isInvalid: boolean
  onChange: (value: DateTimeRange) => void
  step?: Duration
  value: DateTimeRange
}

const TimeRangeInput: React.FC<Props> = ({
  isInvalid,
  onChange,
  step,
  value,
}) => {
  return (
    <div className="TimeRangeInput d-flex align-items-center flex-nowrap">
      <TimeInput
        isInvalid={isInvalid}
        onChange={newStart =>
          onChange(new DateTimeRange(newStart, value.endDateTime))
        }
        step={step}
        value={value.startDateTime}
      />
      <span className="mx-8">{' - '}</span>
      <TimeInput
        isInvalid={isInvalid}
        onChange={newEnd => onChange(new DateTimeRange(value.start, newEnd))}
        step={step}
        value={value.endDateTime}
      />
    </div>
  )
}

export default TimeRangeInput
