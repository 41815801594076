import * as React from 'react'

import './MeetingsListDateBlock.scss'
import TimeLabel from './TimeLabel'
import { DateTime } from '../utils'

interface Props {
  date: DateTime
}

const MeetingsListDateBlock: React.FC<Props> = ({ date }) => (
  <div className="MeetingsListDateBlock">
    <p className="weekday">
      <TimeLabel format="localized-full-day-of-week" time={date} />
    </p>
    <p className="calendar-date">
      <TimeLabel format="localized-full-date" time={date} />
    </p>
  </div>
)

export default MeetingsListDateBlock
