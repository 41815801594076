import * as React from 'react'
import { Button, Media } from 'react-bootstrap'
import { Calendar } from '../../../types'
import { Maybe } from '../../../__generated__/graphql'
import CalendarBadge from '../../CalendarBadge'
import Icon from '../../Icon'

export interface AddToCalendarOptionProps {
  meetingsCalendar: Maybe<Pick<Calendar, 'id' | 'name' | 'color'>>
  onClick: () => void
}

export interface CheckForConflictsOptionProps {
  availabilityCalendarsTrunc?: number
  availabilityCalendars: Array<Pick<Calendar, 'id' | 'name' | 'color'>>
  onClick: () => void
}

interface CalendarSettingsOptionContainerProps {
  assetName: string
  onClick: () => void
  children: React.ReactNode
}

export const CalendarSettingsOptionContainer: React.FC<
  CalendarSettingsOptionContainerProps
> = ({
  assetName,
  onClick,
  children,
}: CalendarSettingsOptionContainerProps) => {
  return (
    <Media className="align-items-center">
      <Icon.InternalAsset assetName={assetName} size={50} />
      {children}
      <Button variant="link" onClick={onClick}>
        Change
      </Button>
    </Media>
  )
}

export const AddToCalendarOption: React.FC<AddToCalendarOptionProps> = ({
  meetingsCalendar,
  onClick,
}: AddToCalendarOptionProps) => {
  return (
    <CalendarSettingsOptionContainer
      assetName="Icon-Calendar-Add"
      onClick={onClick}
    >
      <Media.Body className="mx-12">
        {meetingsCalendar && (
          <span>
            We'll add meetings to{' '}
            <CalendarBadge
              name={meetingsCalendar.name}
              color={meetingsCalendar.color}
            />
          </span>
        )}
        {!meetingsCalendar && (
          <span>We won’t add meetings to any calendars.</span>
        )}
      </Media.Body>
    </CalendarSettingsOptionContainer>
  )
}

export const CheckForConflictsOption: React.FC<
  CheckForConflictsOptionProps
> = ({
  availabilityCalendarsTrunc,
  availabilityCalendars,
  onClick,
}: CheckForConflictsOptionProps) => {
  // Number of availability calendars to show before truncating defaults to 4
  availabilityCalendarsTrunc = availabilityCalendarsTrunc || 4
  return (
    <CalendarSettingsOptionContainer
      assetName="Icon-Available-Dates"
      onClick={onClick}
    >
      <Media.Body className="mx-12">
        {availabilityCalendars.length === 0 && (
          <span>We won't check any calendars for conflicts.</span>
        )}

        {availabilityCalendars.length > 0 && (
          <span style={{ lineHeight: '2rem' }}>
            We'll check these calendars for conflicts:{' '}
            {availabilityCalendars
              .slice(0, availabilityCalendarsTrunc)
              .map(calendar => (
                <span className="mr-4" key={calendar.id}>
                  <CalendarBadge name={calendar.name} color={calendar.color} />
                </span>
              ))}
            {availabilityCalendars.length > availabilityCalendarsTrunc ? (
              <span>
                and{' '}
                <var>
                  {availabilityCalendars.length - availabilityCalendarsTrunc}
                </var>{' '}
                others.
              </span>
            ) : (
              ''
            )}
          </span>
        )}
      </Media.Body>
    </CalendarSettingsOptionContainer>
  )
}
