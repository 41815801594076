import { decodeQueryParams } from './queryParams'
import { OAuth2Response } from '../types'

type WindowOptions = {
  width: number
  height: number
  top?: number
  left?: number
}

export const WindowClosed = 'window_closed'

export const openWindow = (
  url: string,
  name: string,
  options: WindowOptions
) => {
  const optionsPieces: string[] = []

  // If there is a height/width we're going to try and center the window
  if (options.width && !options.left) {
    options.left = window.outerWidth / 2 + window.screenX - options.width / 2
  }

  if (options.height && !options.top) {
    options.top = window.outerHeight / 2 + window.screenY - options.height / 2
  }

  // Loop through the options object and push the key value
  // pairs to the array.
  Object.keys(options).forEach(key => {
    // @ts-ignore
    const val = options[key]
    optionsPieces.push(`${key}=${val}`)
  })

  // Open the url with the applied options.
  const win = window.open(url, name, optionsPieces.join(','))

  return new Promise(resolve => {
    const intervalId = setInterval(() => {
      // Check if the window is closed...
      if (win!.closed || !win!.window) {
        // Resolve the promise if closed & clear the interval.
        resolve({})
        clearInterval(intervalId)
      }
    }, 100)
  })
}

type OpenerOptions = {
  height: number
  width: number
}

export const startOAuthFlow = (
  url: string,
  openerOptions = {} as OpenerOptions
) => {
  return new Promise<string>((resolve, reject) => {
    console.log('startOAuthFlow', 'opening', url)

    // Open a new window to OAuth flow.
    const win = openWindow(url, url, openerOptions)
    const messageHandler = (ev: MessageEvent) => {
      // Ignore message that are not from us.
      if (ev.origin !== 'https://oauth2.appointlet.com') {
        return
      }

      const queryString = decodeQueryParams<OAuth2Response>(ev.data)

      // If we got a code resolve the promise.
      if (queryString.code) {
        console.log('startOAuthFlow', 'received code', queryString)
        resolve(queryString.code)
        return
      }

      // If we got an error reject the promise.
      if (queryString.error) {
        console.error('startOAuthFlow', 'received error', queryString)
        reject(queryString.error)
        return
      }

      // cleanup
      removeMessageHandler()
    }

    const removeMessageHandler = () => {
      window.removeEventListener('message', messageHandler)
    }

    // If window closes, cleanup the listner and reject the promise.
    win.then(() => {
      console.log('startOAuthFlow', 'closed window')
      removeMessageHandler()
      reject(WindowClosed)
    })

    // Start listening for messages.
    window.addEventListener('message', messageHandler, false)
  })
}
