import * as React from 'react'
import Helmet from 'react-helmet'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'

import './ImageButton.scss'
import EmojiPicker from './EmojiPicker'
import { openUploader } from '../utils'

interface Props {
  crop?: string
  onChange: (string: string | null) => void
  uploadOnly?: boolean
  value: string | null
}

interface UploadData {
  cdnUrl: string
}

const ImageButton: React.FC<Props> = ({
  crop,
  onChange,
  uploadOnly,
  value,
}) => {
  const selectAndClose = (url: string) => {
    onChange(url)

    // Closes the popover
    document.body.click()
  }
  const openImageUploader = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    openUploader({ crop }).then((result: UploadData) => {
      selectAndClose(result.cdnUrl)
    })
  }
  return (
    <div className="ImageButton">
      <Helmet>
        <script
          async
          defer
          charSet="utf-8"
          src="https://ucarecdn.com/libs/widget/3.x/uploadcare.full.min.js"
        ></script>
      </Helmet>
      <OverlayTrigger
        placement="right"
        rootClose
        rootCloseEvent="mousedown"
        trigger="click"
        show={uploadOnly ? false : undefined}
        popperConfig={{
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                // This causes the popover to position itself relative to the window
                // instead of the first position:fixed parent.  It allows it to hang
                // off a modal's edge, for example.
                boundariesElement: 'window',
              },
            },
          ],
        }}
        overlay={
          <Popover id="ImageButton-popover">
            {!uploadOnly && (
              <React.Fragment>
                <EmojiPicker onSelect={selectAndClose} />

                <Popover.Content>
                  <div>
                    <Button onClick={openImageUploader} variant="link">
                      Upload custom image...
                    </Button>
                  </div>
                </Popover.Content>
              </React.Fragment>
            )}
          </Popover>
        }
      >
        <Button
          variant="outline-primary"
          onClick={e => (uploadOnly ? openImageUploader(e) : null)}
        >
          Upload Picture
        </Button>
      </OverlayTrigger>
    </div>
  )
}

export default ImageButton
