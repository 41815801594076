import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Col, Form, Media, Modal, Row } from 'react-bootstrap'
import { gql } from '@apollo/client'

import FormErrorSubscription from './FormErrorSubscription'
import HelpToolTip from './HelpToolTip'
import ImageInput from './ImageInput'
import LanguagePicker from './LanguagePicker'
import LoadingButton from './LoadingButton'
import TimeZonePicker from './TimeZonePicker'
import UserContext from './UserContext'
import {
  Maybe,
  MemberNode,
  ProfileNode,
  RoleNode,
  SettingsModalProfileSectionQuery as Response,
  Timezone,
  useSettingsModalProfileSectionQuery,
  useSettingsModalProfileSectionUpdateMutation,
  UpdateUserInput,
  UserNode,
  Language,
} from '../__generated__/graphql'
import {
  composeValidators,
  isEmailAddress,
  isRequired,
  mutationErrorsToFormErrors,
  toast,
} from '../utils'
import Translate from './Translate'
import SettingsSectionPicker from './SettingsSectionPicker'
import { SettingsSection } from './SettingsModal'
import Icon from './Icon'

gql`
  query SettingsModalProfileSection {
    user {
      id
      email
      firstName
      language
      lastName
      image
      timezone
      members {
        edges {
          node {
            id
            meetingCount
            profile {
              id
              members {
                edges {
                  node {
                    id
                    user {
                      email
                      id
                      image
                      firstName
                      lastName
                    }
                  }
                }
              }
              name
              personal
            }
            role {
              canManageBilling
              canManageMeetings
              canManageOrgSettings
              canManageMeetingTypes
            }
          }
        }
      }
    }
  }
`

gql`
  mutation SettingsModalProfileSectionUpdate($input: UpdateUserInput!) {
    updateUser(input: $input) {
      data {
        createdAt
        firstName
        email
        id
        image
        language
        lastName
        timezone
      }
      errors {
        field
        messages
      }
    }
  }
`

type Props = {
  onSelect: (section: SettingsSection) => void
}

type InternalData = {
  user: Maybe<
    Pick<
      UserNode,
      | 'email'
      | 'firstName'
      | 'id'
      | 'image'
      | 'language'
      | 'lastName'
      | 'timezone'
    > & {
      members: Maybe<
        Array<
          Pick<MemberNode, 'id' | 'meetingCount'> & {
            profile: Pick<ProfileNode, 'id' | 'name' | 'personal'> & {
              members: Maybe<
                Array<
                  Pick<MemberNode, 'id'> & {
                    user: Pick<
                      UserNode,
                      'email' | 'firstName' | 'id' | 'image' | 'lastName'
                    >
                  }
                >
              >
            }
            role: Pick<
              RoleNode,
              | 'canManageBilling'
              | 'canManageMeetings'
              | 'canManageMeetingTypes'
              | 'canManageOrgSettings'
            >
          }
        >
      >
    }
  >
}

const wireDataToInternalData = (wireData: Response): InternalData => ({
  user: wireData.user
    ? {
        ...wireData.user,
        members: wireData.user.members
          ? wireData.user.members.edges.map(edge => ({
              ...edge?.node!,
              profile: {
                ...edge?.node?.profile!,
                members: edge?.node?.profile.members
                  ? edge.node.profile.members.edges.map(edge => ({
                      ...edge?.node!,
                      user: edge?.node?.user!,
                    }))
                  : null,
              },
              role: edge?.node?.role!,
            }))
          : null,
      }
    : null,
})

type FormValues = Pick<
  UpdateUserInput,
  'email' | 'firstName' | 'image' | 'language' | 'lastName' | 'timezone'
>

const SettingsModalProfileSection: React.FC<Props> = ({ onSelect }) => {
  const { user } = React.useContext(UserContext)
  const { data, loading } = useSettingsModalProfileSectionQuery()

  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data) : null),
    [data]
  )

  const [updateUser, { loading: mutationLoading }] =
    useSettingsModalProfileSectionUpdateMutation()

  const onSubmit = async (values: FormValues) => {
    try {
      const res = await updateUser({
        variables: {
          input: {
            email: values.email,
            firstName: values.firstName,
            id: user.id,
            image: values.image,
            language: values.language,
            lastName: values.lastName,
            timezone: values.timezone,
          },
        },
      })
      // If client validation and GraphQL error related errors exist
      if (res.data?.updateUser?.errors) {
        console.error(
          'updateUser mutation [SettingsModalProfileSection.tsx]',
          res.data.updateUser.errors
        )
        return mutationErrorsToFormErrors(res.data.updateUser.errors)
      }
      // If data is present trigger the toast to display and close the modal
      if (res.data?.updateUser?.data) {
        toast.success('Settings Updated')
        return
      }
    } catch (err) {
      toast.error('Something went wrong')
      console.error(
        'updateUser mutation [SettingsModalProfileSection.tsx]',
        err
      )
    }
  }
  return (
    <div className="SettingsModalProfileSection">
      <Modal.Header closeButton>
        <Icon.User className="tw-hidden tw-text-bsGray-600 lg:tw-block" />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title as="h2" className="tw-hidden md:tw-block">
            <Translate>My Profile</Translate>
          </Modal.Title>
          <Translate as="p">Manage your account information.</Translate>
        </div>
        <SettingsSectionPicker onChange={onSelect} value="profile" />
      </Modal.Header>
      {/*HACK: this overflowY allows the timezone picker below to not get clipped off*/}
      <Modal.Body style={{ overflowY: 'visible' }}>
        {!loading && internalData && internalData.user ? (
          <FinalForm.Form<FormValues>
            initialValues={{
              email: internalData.user.email,
              firstName: internalData.user.firstName,
              image: internalData.user.image,
              language: internalData.user.language,
              lastName: internalData.user.lastName,
              timezone: internalData.user.timezone!,
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col className="tw-order-2 md:tw-order-1" sm={6}>
                    <Form.Group>
                      <Form.Group>
                        <Form.Label>
                          <Translate>Email Address</Translate>
                        </Form.Label>
                        <FinalForm.Field<FormValues['email']>
                          name="email"
                          validate={composeValidators(
                            isRequired,
                            isEmailAddress
                          )}
                        >
                          {({ input, meta }) => (
                            <Form.Control
                              {...input}
                              isInvalid={meta.error}
                              type="email"
                            />
                          )}
                        </FinalForm.Field>
                        <FormErrorSubscription name="email" />
                      </Form.Group>
                      <Form.Label>
                        <Translate>First Name</Translate>
                      </Form.Label>
                      <FinalForm.Field<FormValues['firstName']>
                        name="firstName"
                        validate={isRequired}
                      >
                        {({ input, meta }) => (
                          <React.Fragment>
                            <Form.Control
                              {...input}
                              isInvalid={meta.error}
                              value={input.value || ''}
                            />
                            <FormErrorSubscription name="firstName" />
                          </React.Fragment>
                        )}
                      </FinalForm.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        <Translate>Last Name</Translate>
                      </Form.Label>
                      <FinalForm.Field<FormValues['lastName']>
                        name="lastName"
                        validate={isRequired}
                      >
                        {({ input, meta }) => (
                          <Form.Control
                            {...input}
                            isInvalid={meta.error}
                            value={input.value || ''}
                          />
                        )}
                      </FinalForm.Field>
                      <FormErrorSubscription name="lastName" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        <Translate>Timezone</Translate>
                      </Form.Label>
                      <FinalForm.Field<Timezone> name="timezone">
                        {({ input, meta }) => (
                          <TimeZonePicker
                            onChange={input.onChange}
                            required
                            value={input.value}
                            isInvalid={meta.invalid}
                          />
                        )}
                      </FinalForm.Field>
                      <FormErrorSubscription name="timezone" />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="tw-flex tw-items-center tw-space-x-[5px]">
                        <Translate>Language</Translate>
                        <HelpToolTip>
                          <Translate>
                            Used for this dashboard and emails you receive. To
                            control the language your users see when scheduling
                            with you, edit the settings of your scheduling page
                          </Translate>
                          .
                        </HelpToolTip>
                      </Form.Label>
                      <FinalForm.Field<Language> name="language">
                        {({ input }) => (
                          <LanguagePicker
                            onChange={input.onChange}
                            value={input.value}
                          />
                        )}
                      </FinalForm.Field>
                      <FormErrorSubscription name="timezone" />
                    </Form.Group>

                    <LoadingButton
                      loading={mutationLoading}
                      type="submit"
                      variant="primary"
                    >
                      <Translate>Save Changes</Translate>
                    </LoadingButton>
                  </Col>
                  <Col
                    className="tw-order-1 tw-mb-4 md:tw-mb-0 md:tw-order-2"
                    sm={6}
                  >
                    <Form.Group>
                      <Form.Label>
                        <Translate>Picture</Translate>
                      </Form.Label>
                      <FinalForm.Field<FormValues['image']> name="image">
                        {({ input: { onChange, value: image } }) => (
                          <Media>
                            <Media.Body>
                              <ImageInput
                                crop="640x640"
                                onChange={onChange}
                                uploadOnly
                                value={image || null}
                              />
                            </Media.Body>
                          </Media>
                        )}
                      </FinalForm.Field>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </FinalForm.Form>
        ) : null}
        {/* TODO: Uncomment and add Mutation. */}
        {/* <Row className="PersonalSettingsSection--delete">
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label>Delete your account</Form.Label>
                      <Form.Text>
                        This will delete all your user data, and is not
                        reversible. You have been warned!
                      </Form.Text>
                      <Button variant="outline-danger">Delete account</Button>
                    </Form.Group>
                  </Col>
                </Row> */}
      </Modal.Body>
    </div>
  )
}

export default SettingsModalProfileSection
