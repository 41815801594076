import * as React from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { Schedule } from '../types'
import SchedulePanel from './SchedulePanel'
import Toggler from './Toggler'
import UpdateScheduleModal from './UpdateScheduleModal'
import { arrayMove, Duration } from '../utils'

interface Props {
  minimumTimeBlockDuration: Duration
  onChange: (schedules: Schedule[]) => void
  schedules: Schedule[]
}

const scheduleReplace = (
  schedules: Schedule[],
  schedule: Schedule,
  index: number
): Schedule[] => [
  // Get a copy of the schedules up to the indexed schedule.
  ...schedules.slice(0, index),
  // Add in the updated schedule.
  schedule,
  // Get the remainder of the schedules after the indexed schedule.
  ...schedules.slice(index + 1),
]

const AdvancedAvailabilityPicker: React.FC<Props> = ({
  minimumTimeBlockDuration,
  onChange,
  schedules,
}) => {
  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result
    // No destination, nothing happened.
    // Check if location of draggable has changed.
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }
    // Reorder the schedules and update the form values.
    onChange(arrayMove(schedules, source.index, destination.index))
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-panels">
        {({ droppableProps, innerRef, placeholder }) => (
          <div
            className="AdvancedAvailabilityPicker"
            ref={innerRef}
            {...droppableProps}
          >
            {schedules.map((schedule, i) => (
              <Toggler key={i}>
                {updateScheduleModal => (
                  <>
                    <Draggable draggableId={`${schedule.name}-${i}`} index={i}>
                      {({ dragHandleProps, draggableProps, innerRef }) => (
                        // TYPESCRIPT: This is a really weird TS bug that deals with react-beautiful-dnd & internal react typings.
                        // @ts-ignore
                        <div
                          className="tw-flex tw-items-center tw-mb-4 tw-flex-1"
                          ref={innerRef}
                          {...draggableProps}
                          {...dragHandleProps}
                        >
                          <SchedulePanel
                            onClick={updateScheduleModal.setOn}
                            onDelete={() => {
                              // Remove the schedule at current index.
                              onChange([
                                ...schedules.slice(0, i),
                                ...schedules.slice(i + 1),
                              ])
                            }}
                            schedule={schedule}
                          />
                        </div>
                      )}
                    </Draggable>
                    {updateScheduleModal.isToggled && (
                      <UpdateScheduleModal
                        minimumTimeBlockDuration={minimumTimeBlockDuration}
                        onChange={schedule => {
                          onChange(scheduleReplace(schedules, schedule, i))
                          updateScheduleModal.setOff()
                        }}
                        onHide={updateScheduleModal.setOff}
                        schedule={schedule}
                      />
                    )}
                  </>
                )}
              </Toggler>
            ))}
            {placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default AdvancedAvailabilityPicker
