import * as React from 'react'
import { RouteComponentProps } from '@reach/router'
import InfiniteScroll from 'react-infinite-scroller'
import { Container } from 'react-bootstrap'
import { debounce } from 'lodash'
import { gql } from '@apollo/client'
import Helmet from 'react-helmet'

import './MeetingsPage.scss'
import CancelMeetingModal, { useCancelMeetingModal } from './CancelMeetingModal'
import ApproveMeetingModal, {
  useApproveMeetingModal,
} from './ApproveMeetingModal'
import DeclineMeetingModal, {
  useDeclineMeetingModal,
} from './DeclineMeetingModal'
import Disabler from './Disabler'
import Icon from './Icon'
import LoadingButton from './LoadingButton'
import MeetingFilters from './MeetingFilters'
import MeetingsPageDateFilter from './MeetingsPageDateFilter'
import MeetingsPageEmptyState from './MeetingsPageEmptyState'
import MeetingsPageList from './MeetingsPageList'
import Page from './Page'
import RescheduleMeetingModal from './RescheduleMeetingModal'
import SearchInput from './SearchInput'
import TopBar from './TopBar'
import Spinner from './Spinner'
import TimezoneDiffAlert from './TimezoneDiffAlert'
import ChangeMeetingViewingTimezoneModal from './ChangeMeetingViewingTimezoneModal'
import UserContext from './UserContext'
import MeetingViewingTimezoneContext from './MeetingViewingTimezoneContext'

import { MeetingsFragment } from '../graphql/fragments'
import {
  AttendeeLaunchActionComponent,
  MeetingLaunchActionComponent,
  Maybe,
  MeetingsPageQuery,
  PageInfo,
  Timezone,
  useUpdateUserTimezoneMutation,
  useMeetingsPageQuery,
} from '../__generated__/graphql'
import {
  Attendee,
  ConferencingAccount,
  ConferenceProvider,
  Meeting,
  MeetingType,
  Member,
  User,
} from '../types'
import { DateTime, Analytics, toast } from '../utils'

import {
  useGenerateReport,
  meetingStatusFilter,
  useMeetingsPageData,
  useMeetingViewingTimezone,
} from '../hooks'
import MemberContext from './MemberContext'
import ProfileContext from './ProfileContext'
import Drawer from './molecules/Drawer/Drawer'
import ConnectedMeetingDetailDrawer from './meetingDetailDrawer/molecules/ConnectedMeetingDetailDrawer/ConnectedMeetingDetailDrawer'
import ApproveAttendeesModal, {
  useApproveAttendeesModal,
} from './ApproveAttendeesModal'
import DeclineAttendeesModal, {
  useDeclineAttendeesModal,
} from './DeclineAttendeesModal'

gql`
  query MeetingsPage(
    $after: String
    $before: String
    $first: Int
    $id: ID!
    $isCancelled: Boolean
    $isConfirmed: Boolean
    $meetingTypes: [ID]
    $members: [ID]
    $search: String
    $startOnOrAfter: DateTime
    $startOnOrBefore: DateTime
    $orderBy: String
  ) {
    profile: getProfileById(id: $id) {
      id
      meetings(
        after: $after
        before: $before
        first: $first
        isCancelled: $isCancelled
        isConfirmed: $isConfirmed
        meetingTypes: $meetingTypes
        members: $members
        search: $search
        startOnOrAfter: $startOnOrAfter
        startOnOrBefore: $startOnOrBefore
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          startCursor
          endCursor
          hasPreviousPage
        }
        ...Meetings
      }
    }
  }
  ${MeetingsFragment}
`

gql`
  query MeetingLaunchAction($id: ID!) {
    meeting: getMeetingById(id: $id) {
      id
      name
      start
    }
  }
`

gql`
  query AttendeeLaunchAction($id: ID!) {
    attendee: getAttendeeById(id: $id) {
      id
      firstName
      lastName
    }
  }
`

gql`
  mutation UpdateUserTimezone($input: UpdateUserInput!) {
    user: updateUser(input: $input) {
      data {
        id
        timezone
      }
      errors {
        field
        messages
      }
    }
  }
`

type Props = RouteComponentProps & { profileId?: string }

export type IAttendee = Pick<
  Attendee,
  'email' | 'firstName' | 'id' | 'lastName' | 'status' | 'timezone' | 'approved'
> & {
  member: Maybe<
    Pick<Member, 'id'> & {
      user: Pick<User, 'email' | 'firstName' | 'id' | 'image' | 'timezone'>
    }
  >
}

export type IMeeting = Pick<
  Meeting,
  | 'cancelled'
  | 'conferenceId'
  | 'conferenceUrl'
  | 'confirmed'
  | 'end'
  | 'id'
  | 'isGroup'
  | 'location'
  | 'locationType'
  | 'name'
  | 'start'
> & {
  attendees: Array<IAttendee>
  conferencingAccount: Maybe<
    Pick<ConferencingAccount, 'id' | 'name'> & {
      provider: Pick<ConferenceProvider, 'id' | 'name' | 'slug'>
    }
  >
  meetingType: Pick<MeetingType, 'color' | 'id' | 'name'> & {
    conferencingProvider: Pick<ConferenceProvider, 'id' | 'name' | 'slug'>
  }
}

type MeetingsPageData = {
  meetings: Array<IMeeting>
  pageInfo: PageInfo
}

// Convert the data we get from our query into something
// more useable that we can hand off to components.
const wireDataToInternalData = (
  wire: MeetingsPageQuery['profile']
): MeetingsPageData => ({
  meetings: wire.meetings.edges.map(edge => ({
    ...edge!.node!,
    attendees: edge!.node!.attendees.edges.map(edge => ({
      ...edge!.node!,
      member: edge!.node!.member
        ? {
            ...edge!.node!.member!,
            user: edge!.node!.member!.user!,
          }
        : null,
    })),
    conferencingAccount: edge!.node!.conferencingAccount
      ? edge!.node!.conferencingAccount
      : null,
    end: new DateTime(edge!.node!.end),
    meetingType: {
      ...edge!.node!.meetingType,
      conferencingProvider: {
        ...edge!.node!.meetingType.conferencingProvider!,
      },
    },
    start: new DateTime(edge!.node!.start),
  })),
  pageInfo: wire.meetings.pageInfo,
})

const meetingsFilter = (meetings: Array<IMeeting>): Array<IMeeting> =>
  meetings
    // When we reschedule a meeting it will stay in the results and it's possible
    // it was scheduled for a time outside of the query results so we should filter
    // it out by comparing the start times against the start time of the last
    // fetched result.
    .filter(
      m => m.start.toUnix() <= meetings[meetings.length - 1].start.toUnix()
    )
    // When we reschedule a meeting it will stay in it's place in the DOM which will be
    // out of order when it comes to the dates. We sort the filtered list so that it
    // appears in descending order (i.e. Nov 1st, 2nd, 3rd, etc.) Doing this places
    // the rescheduled meeting in the correct spot in the list.
    .sort((a, b) => (a.start.isAfter(b.start) ? 1 : -1))

const MeetingsPage: React.FC<Props> = ({ profileId }) => {
  const member = React.useContext(MemberContext)
  const profile = React.useContext(ProfileContext)
  const { user } = React.useContext(UserContext)

  // setupMeetingsPage creates all the initialState & interprets the URL
  // to set any other initial states on the first render & gives access
  // to functions for updating that state.
  const { filters, filtersToVariables, launchAction, setFilters } =
    useMeetingsPageData(member, profile)

  // Prep state for the CancelMeetingModal
  const {
    open: openCancelMeetingModal,
    close: closeCancelMeetingModal,
    toCancel: meetingToCancel,
    submit: submitCancelMeeting,
    submitting: submittingCancelMeeting,
  } = useCancelMeetingModal(
    launchAction?.id && launchAction?.action === 'cancelMeeting'
      ? launchAction.id
      : undefined
  )

  // Prep state for the ApproveMeetingModal
  const {
    open: openApproveMeetingModal,
    close: closeApproveMeetingModal,
    toApprove: meetingToApprove,
    submit: submitApproveMeeting,
    submitting: submittingApproveMeeting,
  } = useApproveMeetingModal(
    launchAction?.id && launchAction?.action === 'approveMeeting'
      ? launchAction.id
      : undefined
  )

  // Prep state for the DeclineMeetingModal
  const {
    open: openDeclineMeetingModal,
    close: closeDeclineMeetingModal,
    toDecline: meetingToDecline,
    submit: submitDeclineMeeting,
    submitting: submittingDeclineMeeting,
  } = useDeclineMeetingModal(
    launchAction?.id && launchAction?.action === 'declineMeeting'
      ? launchAction.id
      : undefined
  )

  // Prep state for the ApproveAttendeesModal
  const {
    // open: openApproveAttendeesModal,
    close: closeApproveAttendeesModal,
    toApprove: attendeesToApprove,
    submit: submitApproveAttendees,
    submitting: submittingApproveAttendees,
  } = useApproveAttendeesModal(
    launchAction?.id && launchAction?.action === 'approveAttendee'
      ? [launchAction.id]
      : undefined
  )

  // Prep state for the DeclineAttendeesModal
  const {
    // open: openDeclineAttendeesModal,
    close: closeDeclineAttendeesModal,
    toDecline: attendeesToDecline,
    submit: submitDeclineAttendees,
    submitting: submittingDeclineAttendees,
  } = useDeclineAttendeesModal(
    launchAction?.id && launchAction?.action === 'declineAttendee'
      ? [launchAction.id]
      : undefined
  )

  const [rescheduleMeetingIsOpen, setRescheduleMeetingIsOpen] =
    React.useState<boolean>(
      launchAction?.id && launchAction?.action === 'rescheduleMeeting'
        ? true
        : false
    )

  // State for open/closing meeting drawer
  const [currentMeeting, setCurrentMeeting] = React.useState<string | null>(
    null
  )

  // Get the current timezone in the browser.
  // REVIEW
  const browserTimezone = new DateTime().getTimeZoneEnum()

  // Get the user's viewing timezone
  const [
    meetingViewingTimezone,
    setMeetingViewingTimezone,
    clearMeetingViewingTimezone,
  ] = useMeetingViewingTimezone()

  // Prepare mutation for updating a user's timezone
  const [updateUserTimezoneMutation] = useUpdateUserTimezoneMutation()

  // This feels a bit hacky, but other options are difficult to implement.
  // If the user's timezone matches the browser's timezone and there is
  // a meetingViewingTimezone that is set that doesn't match, then clear
  // it out.  The explanation here is that the user is probably returning
  // home to their native zone after having been somewhere else and now
  // should see events as they did originally.
  React.useEffect(() => {
    if (
      user.timezone === browserTimezone &&
      meetingViewingTimezone &&
      user.timezone !== meetingViewingTimezone
    ) {
      clearMeetingViewingTimezone()
    }
  }, [
    user.timezone,
    browserTimezone,
    meetingViewingTimezone,
    setMeetingViewingTimezone,
    clearMeetingViewingTimezone,
  ])

  // Controls whether or not the viewing timezone modal is open.
  const [
    showChangeMeetingViewingTimezoneModal,
    setShowChangeMeetingViewingTimezoneModal,
  ] = React.useState<boolean>(
    () =>
      // The user has a timezone set
      !!user.timezone &&
      // But the timezone doesn't match the browser's timezone
      // REVIEW
      !new DateTime()
        .setZone(user.timezone)
        .isSameTimeZoneOffset(new DateTime()) &&
      // And the meetingViewingTimezone (if present) doesn't match
      // the user's zone or the browser's zone.
      meetingViewingTimezone !== user.timezone &&
      meetingViewingTimezone !== browserTimezone
  )

  // If a user moves to a different geographic area than where they setup their
  // account, we'll ask them if they want to change the timezone they view meetings in.
  // This is the callback after they tell us if they want to do that and if they also
  // want to have it changed permanently (as in they are moving to this new location).
  const onMeetingChangeViewingTimezone = async (
    timezone: Timezone,
    shouldUpdateUser: boolean
  ) => {
    // Update the viewing timezone
    setMeetingViewingTimezone(timezone)

    // If the user has indicated that they want their timezone changed
    // in a more permanent way, update their settings.
    if (shouldUpdateUser) {
      try {
        // Perform the mutation
        const { data, errors } = await updateUserTimezoneMutation({
          variables: {
            input: {
              email: user.email,
              id: user.id,
              // Make sure we send the timezone to the server in the correct format.
              timezone: timezone,
            },
          },
        })

        // If there were some graphQL errors
        if (errors) {
          console.error(
            "Received graphQL error(s) trying to update user's timezone",
            errors
          )

          throw new Error('Failed to update timezone')
        }

        // Check for validation errors
        if (data?.user?.errors) {
          console.error(
            "Received validation error(s) trying to update user's timezone",
            data?.user?.errors
          )

          throw new Error('Failed to update timezone')
        }

        // Clear our the viewingTimezone
        clearMeetingViewingTimezone()
      } catch (error) {
        toast.error('Something went wrong')
      }
    }

    // Close the modal
    setShowChangeMeetingViewingTimezoneModal(false)
  }

  //
  const { onGenerateReport, loading: reportLoading } = useGenerateReport()

  // We debounce keystrokes in the search field
  const searchUpdated = debounce((search: string) => {
    setFilters({ ...filters, search })
  }, 350)

  // Run the query for meetings
  const { data, loading, error, fetchMore } = useMeetingsPageQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: filtersToVariables({
      ...filters,
      profile: profileId!,
    }),
  })

  // If for some reason we encounter an error, reset all of our filters
  // and try again.
  //
  // This is a hack that we're using to fix a situation where a user
  // might have deleted a meeting type or member that we're trying to
  // filter on, and the server rejects it because of that.  We should
  // either replace this with something better or at least fix up the
  // backend errors so that they are more specific and passed back
  // in a better way than graphQL errors
  React.useEffect(() => {
    if (!error) return

    if (filters.meetingTypes.length > 0 || filters.members.length > 0) {
      // reset the filters
      setFilters({ ...filters, meetingTypes: [], members: [] })
    }
  }, [error, filters, setFilters])

  // Convert the data into a more usable format that we can chew on.
  const internalData: Maybe<MeetingsPageData> = React.useMemo(
    () => (data ? wireDataToInternalData(data.profile) : null),
    [data]
  )

  return (
    <Page className="MeetingsPage">
      <MeetingViewingTimezoneContext.Provider value={meetingViewingTimezone}>
        <Helmet title="Meetings" />

        <TopBar>
          <div className="mr-8">
            <Disabler disabled={!!filters.search.length}>
              <MeetingsPageDateFilter
                customRange={filters.range}
                onChange={(option, range) =>
                  setFilters({ ...filters, dateFilterOption: option, range })
                }
                value={filters.dateFilterOption}
              />
            </Disabler>
          </div>
          <div className="flex-grow-1">
            <SearchInput
              // Set defaultValue and not value so debounce doesn't go crazy.
              // https://stackoverflow.com/a/41962233/6520579
              defaultValue={filters.search}
              onChange={ev => searchUpdated(ev.currentTarget.value!)}
              type="text"
            />
          </div>
          <div className="ml-8">
            <MeetingFilters
              filters={filters}
              setFilters={updatedFilters =>
                setFilters({ ...filters, ...updatedFilters })
              }
            />
          </div>
          <LoadingButton
            className="ml-8"
            disabled={loading}
            light
            loading={reportLoading}
            onClick={() => {
              const { isCancelled, isConfirmed } = meetingStatusFilter(
                filters.status
              )
              const { startOnOrAfter, startOnOrBefore } =
                filtersToVariables(filters)
              onGenerateReport({
                ...filters,
                isCancelled,
                isConfirmed,
                profileId: profileId!,
                startOnOrAfter,
                startOnOrBefore,
                orderBy:
                  filters.dateFilterOption === 'PAST' ? '-start' : 'start',
              })
              Analytics.trackEvent('Exported Meetings')
            }}
            variant="outline-secondary"
          >
            <Icon.Download size={21} />
          </LoadingButton>
        </TopBar>

        <Container className="MeetingsListContainer d-flex flex-column">
          {/* We only want to render this alert if the browser timezone does not match the user's timezone in the database. */}
          {user.timezone &&
            // REVIEW
            !new DateTime()
              .setZone(user.timezone)
              .isSameTimeZoneOffset(new DateTime()) && (
              <TimezoneDiffAlert
                timezone={meetingViewingTimezone || user.timezone}
                onChange={() => setShowChangeMeetingViewingTimezoneModal(true)}
              />
            )}

          {loading && (
            <div className="align-items-center d-flex justify-content-center py-128">
              <Spinner />
            </div>
          )}

          {!loading && internalData?.meetings.length === 0 && (
            <MeetingsPageEmptyState />
          )}

          {!loading && internalData && internalData.meetings.length > 0 && (
            <InfiniteScroll
              hasMore={internalData.pageInfo.hasNextPage}
              // This is necessary to prevent the InfiniteScroll
              // from calling fetchMore on the mounting of the component.
              // This was put here to avoid the issue of switching between
              // filter states causing the fetchMore to be executed leading
              // to two requests (previous and new) and possible race conditions.
              initialLoad={false}
              loader={
                <div key={0} className="d-flex justify-content-center">
                  <Spinner />
                </div>
              }
              loadMore={async () =>
                await fetchMore({
                  variables: {
                    after: internalData.pageInfo.endCursor,
                  },
                })
              }
              threshold={500}
            >
              <MeetingsPageList
                meetings={
                  // The meetingsFilter breaks when sorting meetings from
                  // most recent to least recent, so just don't use it in
                  // those cases.
                  //
                  // HACK: fix this hack at some point.
                  filters.dateFilterOption === 'PAST'
                    ? internalData.meetings
                    : meetingsFilter(internalData.meetings)
                }
                onCancel={openCancelMeetingModal}
                onApprove={openApproveMeetingModal}
                onDecline={openDeclineMeetingModal}
                onViewDetails={meetingId => setCurrentMeeting(meetingId)}
              />
            </InfiniteScroll>
          )}
        </Container>

        {showChangeMeetingViewingTimezoneModal && (
          <ChangeMeetingViewingTimezoneModal
            onHide={() => setShowChangeMeetingViewingTimezoneModal(false)}
            onSuccess={onMeetingChangeViewingTimezone}
            userTimezone={user.timezone!}
            browserTimezone={browserTimezone}
            meetingViewingTimezone={meetingViewingTimezone}
          />
        )}

        {/*
          These are the modals that will be launched by setupMeetingsPage's
          first React.useEffect call should specific values be found in
          the URL query string.
          */}
        {meetingToCancel && (
          <MeetingLaunchActionComponent variables={{ id: meetingToCancel }}>
            {({ data, loading }) => (
              <React.Fragment>
                {!loading && data && (
                  <CancelMeetingModal
                    name={data.meeting.name}
                    submitting={submittingCancelMeeting}
                    onCancel={closeCancelMeetingModal}
                    onConfirm={async message => {
                      try {
                        await submitCancelMeeting(meetingToCancel, message)
                      } catch (e) {
                        toast.error('Something went wrong')
                      }
                    }}
                  />
                )}
              </React.Fragment>
            )}
          </MeetingLaunchActionComponent>
        )}

        {meetingToApprove && (
          <MeetingLaunchActionComponent variables={{ id: meetingToApprove }}>
            {({ data, loading }) => (
              <React.Fragment>
                {!loading && data && (
                  <ApproveMeetingModal
                    name={data.meeting.name}
                    submitting={submittingApproveMeeting}
                    onCancel={closeApproveMeetingModal}
                    onConfirm={async message => {
                      try {
                        await submitApproveMeeting(meetingToApprove, message)
                      } catch (e) {
                        toast.error('Something went wrong')
                      }
                    }}
                  />
                )}
              </React.Fragment>
            )}
          </MeetingLaunchActionComponent>
        )}

        {meetingToDecline && (
          <MeetingLaunchActionComponent variables={{ id: meetingToDecline }}>
            {({ data, loading }) => (
              <React.Fragment>
                {!loading && data && (
                  <DeclineMeetingModal
                    name={data.meeting.name}
                    submitting={submittingDeclineMeeting}
                    onCancel={closeDeclineMeetingModal}
                    onConfirm={async message => {
                      try {
                        await submitDeclineMeeting(meetingToDecline, message)
                      } catch (e) {
                        toast.error('Something went wrong')
                      }
                    }}
                  />
                )}
              </React.Fragment>
            )}
          </MeetingLaunchActionComponent>
        )}

        {attendeesToDecline && (
          <AttendeeLaunchActionComponent
            variables={{ id: attendeesToDecline[0] }}
          >
            {({ data, loading }) => (
              <React.Fragment>
                {!loading && data?.attendee && (
                  <DeclineAttendeesModal
                    attendees={[data.attendee]}
                    submitting={submittingDeclineAttendees}
                    onCancel={closeDeclineAttendeesModal}
                    onConfirm={async message => {
                      try {
                        await submitDeclineAttendees(message)
                      } catch (e) {
                        toast.error('Something went wrong')
                      }
                    }}
                  />
                )}
              </React.Fragment>
            )}
          </AttendeeLaunchActionComponent>
        )}

        {attendeesToApprove && (
          <AttendeeLaunchActionComponent
            variables={{ id: attendeesToApprove[0] }}
          >
            {({ data, loading }) => (
              <React.Fragment>
                {!loading && data?.attendee && (
                  <ApproveAttendeesModal
                    attendees={[data.attendee]}
                    submitting={submittingApproveAttendees}
                    onCancel={closeApproveAttendeesModal}
                    onConfirm={async message => {
                      try {
                        await submitApproveAttendees(message)
                      } catch (e) {
                        toast.error('Something went wrong')
                      }
                    }}
                  />
                )}
              </React.Fragment>
            )}
          </AttendeeLaunchActionComponent>
        )}

        {rescheduleMeetingIsOpen && (
          <MeetingLaunchActionComponent variables={{ id: launchAction!.id }}>
            {({ data, loading }) => (
              <React.Fragment>
                {!loading && data && (
                  <RescheduleMeetingModal
                    meeting={{
                      id: data.meeting.id,
                      start: new DateTime(data.meeting.start),
                    }}
                    onHide={() => setRescheduleMeetingIsOpen(false)}
                    onSuccess={() => setRescheduleMeetingIsOpen(false)}
                  />
                )}
              </React.Fragment>
            )}
          </MeetingLaunchActionComponent>
        )}
        <Drawer
          isOpen={!!currentMeeting}
          onHide={() => setCurrentMeeting(null)}
        >
          <ConnectedMeetingDetailDrawer meetingId={currentMeeting || ''} />
        </Drawer>
      </MeetingViewingTimezoneContext.Provider>
    </Page>
  )
}

export default MeetingsPage
