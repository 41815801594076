import * as React from 'react'

interface RenderProps {
  isToggled: boolean
  setOn: () => void
  setOff: () => void
  toggle: () => void
}

interface Props {
  initial?: boolean
  children: (props: RenderProps) => React.ReactNode | React.ReactNode[]
}

const Toggler: React.FC<Props> = ({ children, initial }) => {
  // !!undefined = false, !!true = true
  const [isToggled, setIsToggled] = React.useState(!!initial)

  React.useEffect(() => {
    // This prevents memory leaks (warning/bug) that happen
    // when an asynchronous action happens and we unmount the
    // component before the action is finished.
    return () => {}
  })
  return (
    <React.Fragment>
      {children({
        isToggled,
        setOn: () => setIsToggled(true),
        setOff: () => setIsToggled(false),
        toggle: () => setIsToggled(!isToggled),
      })}
    </React.Fragment>
  )
}

export default Toggler
