import * as React from 'react'
import { ConnectCalendarView } from '../../views/ConnectCalendarView/ConnectCalendarView'
import { OnboardingShell } from '../../molecules/OnboardingShell/OnboardingShell'
import { Maybe } from '../../../../__generated__/graphql'
import { ID } from '../../../../types'
import { FormValues } from '../../../OnboardingCalendarIntegrationsPage'

export interface ConnectCalendarScreenProps {
  readonly email: string
  readonly onSubmitCallback: (slug: FormValues) => void
  readonly skipSectionCallback: () => void
  readonly isLoading: Maybe<ID>
  readonly isSingleUser?: boolean
}

export const ConnectCalendarScreen: React.FC<ConnectCalendarScreenProps> = ({
  email,
  onSubmitCallback,
  skipSectionCallback,
  isLoading,
}: ConnectCalendarScreenProps) => {
  return (
    <OnboardingShell email={email} currentTask="ConnectYourCalendar">
      <ConnectCalendarView
        doLaterOnClick={skipSectionCallback}
        googleOnClick={() => onSubmitCallback('google')}
        officeOnClick={() => onSubmitCallback('office365')}
        isGoogleLoading={isLoading === 'google'}
        isOfficeLoading={isLoading === 'office365'}
      />
    </OnboardingShell>
  )
}
