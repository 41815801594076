import * as React from 'react'
import Picker, { PickerOption, Props as PickerProps } from './Picker'
import { gql } from '@apollo/client'
import {
  __EnumValue,
  Language,
  Maybe,
  useLanguagePickerQuery,
} from '../__generated__/graphql'

gql`
  query LanguagePicker {
    languages: __type(name: "Language") {
      enumValues {
        name
        description
      }
    }
  }
`
interface Props extends Omit<PickerProps, 'value' | 'options' | 'onChange'> {
  onChange: (language: Language) => void
  value: Maybe<Language>
}

const languageToPickerOption = (
  enumValue: Pick<__EnumValue, 'name' | 'description'>
) => ({
  label: enumValue.description!,
  value: enumValue.name as Language,
})

const LanguagePicker: React.FC<Props> = ({ disabled, onChange, value }) => {
  const { data, loading } = useLanguagePickerQuery()

  // Convert the language enum data into PickerOptions
  const options: Maybe<PickerOption<Language>[]> | undefined = React.useMemo(
    () => data?.languages?.enumValues?.map(languageToPickerOption),
    [data]
  )

  let pickerValue: Maybe<PickerOption<Language>> = null

  // If there is a value provided, look up it's equivalent enumValue
  if (value && options) {
    pickerValue = options.find(language => language.value === value) || null
  }

  return (
    <Picker<Language>
      required
      disabled={disabled}
      loading={loading}
      onChange={option => onChange(option!.value)}
      options={options || []}
      value={pickerValue}
      placeholder="Select a language"
    />
  )
}

export default LanguagePicker
