import * as React from 'react'
import { OverlayTrigger, Popover, OverlayTriggerProps } from 'react-bootstrap'
import Toggler from './Toggler'

type PopoverOptions = {
  close: () => void
}

interface Props extends Omit<OverlayTriggerProps, 'overlay'> {
  popover: (options: PopoverOptions) => React.ReactNode

  // Make overlay optional to the caller, but available if they want to use it.
  overlay?: OverlayTriggerProps['overlay']
}

const PopoverOverlay: React.FC<Props> = ({
  children,
  popover,
  ...overlayProps
}) => (
  <Toggler>
    {toggler => (
      <OverlayTrigger
        // This fixes the bug where if the window is resized or opened to small the
        // popover and it's contents are clipped.
        // https://app.clubhouse.io/appointlet/story/1143/when-adding-time-blocks-to-a-schedule-if-the-window-is-too-small-the-popover-goes-off-the-screen
        flip
        placement="top"
        show={toggler.isToggled}
        onToggle={toggler.toggle}
        popperConfig={{
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                // This causes the popover to position itself relative to the window
                // instead of the first position:fixed parent.  It allows it to hang
                // off a modal's edge, for example.
                boundariesElement: 'window',
              },
            },
          ],
        }}
        rootClose
        rootCloseEvent="mousedown"
        trigger="click"
        overlay={
          <Popover id={`Popover--${Math.random()}`}>
            <Popover.Content>
              {popover({ close: toggler.setOff })}
            </Popover.Content>
          </Popover>
        }
        {...overlayProps}
      >
        {children}
      </OverlayTrigger>
    )}
  </Toggler>
)

export default PopoverOverlay
