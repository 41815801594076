import * as React from 'react'
import { gql } from '@apollo/client'

import {
  Maybe,
  useCreateStripeCheckoutSessionMutation,
} from '../__generated__/graphql'
import { Profile } from '../types'

gql`
  mutation CreateStripeCheckoutSession(
    $input: StartStripePaymentMethodUpdateSessionInput!
  ) {
    stripe: startStripePaymentMethodUpdateSession(input: $input) {
      sessionId
      errors {
        field
        messages
      }
    }
  }
`

type StripeCheckoutSessionHook = (
  profile: Pick<Profile, 'id'>
) => StripeCheckoutSessionHookReturn

type StripeCheckoutSessionHookReturn = {
  stripeSessionId: Maybe<string>
}

export const useStripeCheckoutSession: StripeCheckoutSessionHook = profile => {
  // Internal State
  const [sessionId, setSessionId] = React.useState<Maybe<string>>(null)

  // Mutation
  const [createStripeCheckoutSession] = useCreateStripeCheckoutSessionMutation({
    variables: { input: { profile: profile.id } },
  })

  React.useEffect(() => {
    // We cannot use async/await inside useEffect.
    // Start by creating a Stripe Checkout session
    const res = createStripeCheckoutSession()

    res.then(({ data, errors }) => {
      // If for some reason we didn't get a good response
      if (errors || !data?.stripe?.sessionId) {
        console.error(
          'startStripePaymentMethodUpdateSession mutation [useStripeCheckoutSession hook]',
          errors
        )
        throw new Error('Failed to create Stripe Checkout session')
      }
      setSessionId(data.stripe.sessionId)
    })
  }, [createStripeCheckoutSession])

  return { stripeSessionId: sessionId }
}
