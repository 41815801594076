import * as React from 'react'
import { Card, Col } from 'react-bootstrap'
import Icon from '../../../Icon'
import Spinner from '../../../Spinner'
import './ConnectCalendarOption.scss'

type CalendarOption = 'google' | 'office365'

export interface ConnectCalendarOptionProps {
  variant: CalendarOption
  onClick: () => void
  isLoading: boolean
}

export const ConnectCalendarOption: React.FC<ConnectCalendarOptionProps> = ({
  variant,
  onClick,
  isLoading,
}: ConnectCalendarOptionProps) => {
  const title = variant === 'google' ? 'Google Calendar' : 'Office 365'
  const asset = variant === 'google' ? 'google' : 'office365'
  const description =
    variant === 'google'
      ? 'Two-way integration with your Google Calendar and Google Meet.'
      : 'Two-way integration with your Office 365 or Outlook.com account.'
  return (
    <Card
      className="CalendarButton d-flex flex-row px-16 space-between-8 align-items-center"
      role="button"
      onClick={onClick}
    >
      <Icon.InternalAsset assetName={asset} size={48} />
      <React.Fragment>
        <Col className="align-items-center pt-sm-16">
          <strong>{title}</strong>
          <p className="description text-left text-muted mt-4">{description}</p>
        </Col>
        {!isLoading ? (
          <div>
            <Icon.ChevronRight className="text-gray-500 d-flex" size={24} />
          </div>
        ) : (
          <div>
            <Spinner size={24} />
          </div>
        )}
      </React.Fragment>
    </Card>
  )
}
