import * as React from 'react'
import { Button, Dropdown } from 'react-bootstrap'

import './MeetingsPageDateFilter.scss'
import DateRangePicker from './DateRangePicker'
import DropdownItem from './DropdownItem'
import Icon from './Icon'
import {
  DateTimeRange,
  currentWeekDateRange,
  dateRangeLabelRenderer,
} from '../utils'

export type DateFilterOptions =
  | 'CUSTOM'
  | 'NEXT-7'
  | 'NEXT-30'
  | 'PAST'
  | 'TODAY'
  | 'TOMORROW'
  | 'UPCOMING'

type Props = {
  customRange?: DateTimeRange
  onChange: (option: DateFilterOptions, range?: DateTimeRange) => void
  value: DateFilterOptions
}

const readableValue = {
  'NEXT-7': 'Next 7 Days',
  'NEXT-30': 'Next 30 Days',
  PAST: 'Past',
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  UPCOMING: 'Upcoming',
}

const MeetingsPageDateFilter: React.FC<Props> = ({
  customRange,
  onChange,
  value,
}) => {
  const [isCustom, setIsCustom] = React.useState(() => value === 'CUSTOM')
  const [range, setRange] = React.useState(() =>
    customRange ? customRange : currentWeekDateRange()
  )

  return (
    <Dropdown
      className="MeetingsPageDateFilter"
      onSelect={value =>
        value === 'CUSTOM'
          ? onChange(value, range)
          : onChange(value as DateFilterOptions)
      }
    >
      <Dropdown.Toggle
        id="meetings-page-date-filter"
        variant="outline-secondary"
      >
        <Icon.Calendar className="mr-8" size="16" />
        {value !== 'CUSTOM'
          ? readableValue[value]
          : dateRangeLabelRenderer(range, customRange)}
      </Dropdown.Toggle>

      <Dropdown.Menu
        className={
          isCustom ? 'd-flex flex-column p-16 space-between-16' : undefined
        }
      >
        {isCustom ? (
          <React.Fragment>
            <div
              className="dropdown-item p-0 text-primary MeetingsPageDateFilter--Back"
              onClick={() => setIsCustom(false)}
              role="button"
            >
              &#8592; Back
            </div>

            <DateRangePicker onChange={setRange} value={range} />

            <Button
              as={DropdownItem}
              block
              className="MeetingsPageDateFilter--ApplyCustomRange"
              eventKey="CUSTOM"
            >
              Apply
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DropdownItem eventKey="UPCOMING">Upcoming</DropdownItem>
            <DropdownItem eventKey="PAST">Past</DropdownItem>
            <DropdownItem eventKey="TODAY">Today</DropdownItem>
            <DropdownItem eventKey="TOMORROW">Tomorrow</DropdownItem>
            <DropdownItem eventKey="NEXT-7">Next 7 Days</DropdownItem>
            <DropdownItem eventKey="NEXT-30">Next 30 Days</DropdownItem>
            <Dropdown.Divider />
            <div
              className="dropdown-item"
              onClick={() => setIsCustom(true)}
              role="button"
            >
              Select a date range...
            </div>
          </React.Fragment>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default MeetingsPageDateFilter
