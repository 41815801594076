import * as React from 'react'
import { Col, Row } from 'react-bootstrap'

import SelectableCard from './SelectableCard'
import Icon from './Icon'

export interface Props {
  value: boolean
  onChange?: (isGroup: boolean) => void
}

const MeetingTypeIsGroupPicker: React.FC<Props> = ({ onChange, value }) => (
  <Row className="MeetingTypeIsGroupPicker">
    <Col md={6} className="mb-24 mb-md-0">
      <SelectableCard
        selected={!value}
        onClick={() => onChange && onChange(false)}
      >
        <Icon.InternalAsset assetName="Icon-Bird-Blue" size={48} />

        <div className="mt-8">
          <strong>One-on-One</strong>
        </div>

        <span className="text-gray-600">
          Schedule meetings between yourself and another person.
        </span>
      </SelectableCard>
    </Col>

    <Col md={6}>
      <SelectableCard
        selected={value}
        onClick={() => onChange && onChange(true)}
      >
        <Icon.InternalAsset assetName="Icon-Max-Attendees" size={48} />

        <div className="mt-8">
          <strong>Class or Group</strong>
        </div>

        <span className="text-gray-600">
          Schedule meetings between yourself and many others.
        </span>
      </SelectableCard>
    </Col>
  </Row>
)

export default MeetingTypeIsGroupPicker
