import * as React from 'react'
import {
  Draggable,
  DragDropContext,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'

import FormBuilderField from './FormBuilderField'
import UpdateFormFieldModal from './UpdateFormFieldModal'
import { IFormField, IMeetingType } from './UpdateMeetingTypeContext'
import { arrayMove } from '../utils'

type Props = {
  formFields: IMeetingType['fields']
  onChange: (formFields: IMeetingType['fields']) => void
}

const FormBuilder: React.FC<Props> = ({ formFields, onChange }) => {
  // Controls field we're editing (if any)
  const [editingField, setEditingField] = React.useState<
    IFormField | undefined
  >(undefined)

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result
    // No destination, nothing happened.
    // Check if location of draggable has changed.
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    // Reorder the array and update the order attributes
    onChange(
      arrayMove(formFields, source.index, destination.index).map(
        (field, i) => ({
          ...field,
          order: i + 1,
        })
      )
    )
  }

  // Replace the old field with the changed one
  const onFieldChange = (changedField: IFormField) => {
    onChange(
      formFields.map(field =>
        field.id === changedField.id ? changedField : field
      )
    )
  }

  // Remove the field from the form fields
  const removeField = (field: IFormField) =>
    onChange(formFields.filter(f => f !== field))

  return (
    <div className="FormBuilder">
      {formFields.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-id-1" key="droppable-key-1">
            {({ droppableProps, innerRef, placeholder }) => (
              <div
                className="tw-flex tw-flex-col tw-space-y-[10px] md:tw-space-y-4"
                ref={innerRef}
                {...droppableProps}
              >
                {formFields.map((field, index) => (
                  <Draggable
                    draggableId={field.id}
                    index={index}
                    key={field.id}
                  >
                    {(
                      { dragHandleProps, draggableProps, innerRef },
                      { isDragging }
                    ) => (
                      // TYPESCRIPT: This is a really weird TS bug that deals with react-beautiful-dnd & internal react typings.
                      // @ts-ignore
                      <div
                        className={isDragging ? 'is-dragging' : ''}
                        ref={innerRef}
                        {...draggableProps}
                        {...dragHandleProps}
                      >
                        <FormBuilderField
                          field={field}
                          className={isDragging ? 'is-dragging' : ''}
                          onClick={() => setEditingField(field)}
                          onRemove={field => removeField(field)}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {editingField && (
        <UpdateFormFieldModal
          field={editingField}
          onChange={field => {
            // notify that a field changed
            onFieldChange(field)

            // Close the modal
            setEditingField(undefined)
          }}
          onHide={() => setEditingField(undefined)}
        />
      )}
    </div>
  )
}

export default FormBuilder
