import * as React from 'react'
import { Modal } from 'react-bootstrap'

import LoadingButton from './LoadingButton'
import Icon from './Icon'
import Translate from './Translate'
import Var from './Var'

export type Props = {
  name: string
  loading: boolean
  onConfirm?: () => void
  onCancel?: () => void
}

const DeleteMeetingTypeModal: React.FC<Props> = ({
  name,
  loading,
  onConfirm,
  onCancel,
}) => (
  <Modal onHide={onCancel} show size="sm">
    <Modal.Header closeButton>
      <Icon.Calendar className="tw-text-bsDanger" size={30} />
      <Modal.Title>
        <Translate>Delete Meeting Type</Translate>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Translate as="p" className="tw-text-base tw-font-normal">
        You are about to delete{' '}
        <Var name="name">
          <strong>{name}</strong>
        </Var>
        . Are you sure you want to do that?
      </Translate>

      <Translate as="p" className="tw-text-bsGray-600">
        Existing meetings will not be touched, but you won't be able to schedule
        new ones.
      </Translate>
    </Modal.Body>
    <Modal.Footer>
      <LoadingButton
        className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
        loading={loading}
        onClick={onConfirm}
        variant="danger"
      >
        <Translate>Delete Meeting Type</Translate>
      </LoadingButton>
    </Modal.Footer>
  </Modal>
)
export default DeleteMeetingTypeModal
