import * as React from 'react'
import './Spinner.scss'
import blueLogo from '../assets/logo-blue.svg'
import grayLogo from '../assets/logo-gray.svg'
import { toRem } from '../utils'

interface Props {
  light?: boolean
  size?: number
}

const Spinner: React.FC<Props> = ({ light, size }) => (
  <img
    src={light ? blueLogo : grayLogo}
    className="Spinner"
    alt="Spinner"
    style={{ width: toRem(size || 30), height: toRem(size || 30) }}
  />
)

export default Spinner
