import * as React from 'react'
import './DateRangeInput.scss'
import { DropdownButton, Button, Popover } from 'react-bootstrap'
import DateRangePicker from './DateRangePicker'
import Icon from './Icon'
import { DateTimeRange, dateRangeLabelRenderer } from '../utils'

interface Props {
  onChange: (range: DateTimeRange) => void
  value: DateTimeRange
  as?: React.ElementType
}

const DateRangeInput: React.FC<Props> = ({ onChange, value, as }) => {
  const [dateTimeRange, setDateTimeRange] = React.useState<DateTimeRange>(
    () => value
  )

  return (
    <DropdownButton
      id="date-input-dropdown"
      variant="outline-secondary"
      className="DateRangeInput"
      as={as}
      title={
        <>
          <Icon.Calendar size={18} />
          {dateRangeLabelRenderer(value)}
        </>
      }
    >
      <Popover.Content>
        <DateRangePicker onChange={setDateTimeRange} value={dateTimeRange} />

        <Button
          variant="primary"
          size="sm"
          className="mt-8"
          block
          onClick={() => {
            onChange(dateTimeRange)

            // close the dropdown
            document.body.click()
          }}
        >
          Apply
        </Button>
      </Popover.Content>
    </DropdownButton>
  )
}
export default DateRangeInput
