import * as React from 'react'
import { DropdownButton } from 'react-bootstrap'

import './DateInput.scss'
import DatePicker, { Props as DatePickerProps } from './DatePicker'
import Icon from './Icon'
import TimeLabel from './TimeLabel'

export interface Props extends DatePickerProps {
  as?: React.ElementType
}

const DateInput: React.FC<Props> = ({ as, ...rest }) => (
  <DropdownButton
    id="date-input-dropdown"
    variant="outline-secondary"
    className="DateInput"
    as={as}
    title={
      <React.Fragment>
        <Icon.Calendar size={18} />
        <TimeLabel format="localized-medium-date" time={rest.selected} />
      </React.Fragment>
    }
  >
    <DatePicker
      {...rest}
      onSelect={date => {
        rest.onSelect(date)

        // Close the menu
        document.body.click()
      }}
    />
  </DropdownButton>
)

export default DateInput
