import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, InputGroup } from 'react-bootstrap'
import slugify from '@sindresorhus/slugify'

import './DynamicBookingURLInput.scss'
import Alert from './Alert'
import FormErrorSubscription from './FormErrorSubscription'
import {
  composeValidators,
  env,
  isFormFieldInvalid,
  isRequired,
  isSlug,
  trimHttp,
} from '../utils'

type Props = {
  bookingUrl?: string
  nameLabel: string
  onEditLink?: () => void
  slugLabel: string
  updating?: boolean
}

const DynamicBookingURLInput: React.FC<Props> = ({
  bookingUrl,
  nameLabel,
  onEditLink,
  slugLabel,
  updating,
}) => {
  const [isToggled, setIsToggled] = React.useState<boolean>(false)
  const schedulingPageURL = (url: string | undefined): string =>
    trimHttp(url ? url : env('BOOKING_PAGE_BASE_URL')!)
  return (
    <>
      <Form.Group>
        <Form.Label>{nameLabel}</Form.Label>
        <FinalForm.Field<string> name="name" validate={isRequired}>
          {({ input: name, meta: nameMeta }) => (
            <FinalForm.Field<string> name="slug">
              {({ input: slug, meta: slugMeta }) => (
                <>
                  <Form.Control
                    {...name}
                    isInvalid={isFormFieldInvalid(nameMeta)}
                    onChange={event => {
                      // In any case we want to listen for changes and
                      // update the name.value.
                      name.onChange(event.currentTarget.value)
                      if (!updating) {
                        // If we are not doing an update we want to bind
                        // the inputs name --> slug.
                        // We will slugify the value as the user inputs
                        // the MT name.
                        slug.onChange(slugify(event.currentTarget.value))
                        return
                      }
                    }}
                  />

                  {!isToggled && !slugMeta.submitError && (
                    <Alert className="tw-mt-2" variant="primary">
                      {/* First remove 'http(s)://' and then truncate */}
                      <div className="tw-flex-grow tw-truncate">
                        {schedulingPageURL(bookingUrl)}/{slug.value}
                      </div>
                      <Button
                        className="tw-ml-2"
                        onClick={async () => {
                          onEditLink && onEditLink()
                          setIsToggled(true)
                        }}
                        variant="link"
                      >
                        Customize
                      </Button>
                    </Alert>
                  )}
                </>
              )}
            </FinalForm.Field>
          )}
        </FinalForm.Field>
      </Form.Group>
      {/* The actual input field block for 'slug */}
      <FinalForm.Field<string>
        name="slug"
        validate={composeValidators(isRequired, isSlug)}
      >
        {({ input: slug, meta }) => (
          <>
            {/* Should a server error be present we want to render the input for editing the slug. */}
            {(isToggled || !!meta.submitError) && (
              <Form.Group>
                <Form.Label>{slugLabel}</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>/</InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    {...slug}
                    isInvalid={
                      isFormFieldInvalid(meta) && !meta.modifiedSinceLastSubmit
                    }
                    onClick={() => {
                      setIsToggled(true)
                    }}
                  />
                </InputGroup>

                {!meta.modifiedSinceLastSubmit && (
                  <FormErrorSubscription name="slug" />
                )}

                {updating && (
                  <Alert className="tw-mt-2" variant="danger">
                    Changing the scheduling page link will break any existing
                    links!
                  </Alert>
                )}
              </Form.Group>
            )}
          </>
        )}
      </FinalForm.Field>
    </>
  )
}

export default DynamicBookingURLInput
