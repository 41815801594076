import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Dropdown, Form, Button } from 'react-bootstrap'

import './MeetingFilters.scss'
import MembersPicker from './MembersPicker'
import MeetingTypesPicker from './MeetingTypesPicker'
import { ID } from '../types'
import Icon from './Icon'
import ProfileContext from './ProfileContext'
import {
  booleansToStatusFilter,
  meetingStatusFilter,
  MeetingsPageFilters,
} from '../hooks'
import { Maybe } from '../__generated__/graphql'

type Filters = Pick<MeetingsPageFilters, 'meetingTypes' | 'members' | 'status'>

type Props = {
  filters: Filters
  setFilters: (filters: Filters) => void
}

type FormValues = Pick<MeetingsPageFilters, 'meetingTypes' | 'members'> & {
  isCancelled: Maybe<boolean>
  isConfirmed: Maybe<boolean>
  isPending: boolean
}

const MeetingFilters: React.FC<Props> = ({ filters, setFilters }) => {
  const profile = React.useContext(ProfileContext)
  let activeFilterCount: number = 0

  if (filters.meetingTypes.length) {
    activeFilterCount++
  }

  if (filters.members.length) {
    activeFilterCount++
  }
  const { isCancelled, isConfirmed, isPending } = meetingStatusFilter(
    filters.status
  )
  return (
    <Dropdown className="MeetingFilters" alignRight>
      <Dropdown.Toggle
        variant={
          activeFilterCount > 0 ? 'outline-primary' : 'outline-secondary'
        }
        id="MeetingFilters"
      >
        <Icon.Filter size={16} />
        Filters {activeFilterCount > 0 && `(${activeFilterCount})`}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <FinalForm.Form<FormValues>
          initialValues={{
            // 'null' in the query is the same as true so make sure
            // we make that the same here in the checkbox UI.
            isCancelled: isCancelled === null ? true : isCancelled,
            // 'null' in the query is the same as true so make sure
            // we make that the same here in the checkbox UI.
            isConfirmed: isConfirmed === null ? true : isConfirmed,
            isPending,
            meetingTypes: filters.meetingTypes,
            members: filters.members,
          }}
          onSubmit={values => {
            let status: MeetingsPageFilters['status']
            const { isCancelled, isConfirmed, isPending, ...rest } = values

            // If every checkbox is unchecked set status to ALL.
            // This way the UI will revert to all boxes checked
            // all checked & none checked are the same state.
            if (!isCancelled && !isConfirmed && !isPending) {
              status = 'ALL'
            } else {
              status = booleansToStatusFilter(
                !!isCancelled,
                !!isConfirmed,
                isPending
              )!
            }

            setFilters({
              ...rest,
              status,
            })

            // close the dropdown
            document.body.click()
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {profile.members.length > 1 && (
                <FinalForm.Field<ID[]> name="members">
                  {({ input }) => (
                    <Form.Group>
                      <Form.Label className="d-flex justify-content-between">
                        <span>Filter by members</span>
                        {!!input.value.length && (
                          <Button
                            variant="link"
                            onClick={() => input.onChange([])}
                          >
                            Clear
                          </Button>
                        )}
                      </Form.Label>

                      <MembersPicker
                        onChange={input.onChange}
                        value={input.value}
                      />
                    </Form.Group>
                  )}
                </FinalForm.Field>
              )}

              <FinalForm.Field<ID[]> name="meetingTypes">
                {({ input }) => (
                  <Form.Group>
                    <Form.Label className="d-flex justify-content-between">
                      <span>Filter by meeting types</span>
                      {!!input.value.length && (
                        <Button
                          variant="link"
                          onClick={() => input.onChange([])}
                        >
                          Clear
                        </Button>
                      )}
                    </Form.Label>

                    <MeetingTypesPicker
                      onChange={input.onChange}
                      value={input.value}
                    />
                  </Form.Group>
                )}
              </FinalForm.Field>

              <Form.Group>
                <Form.Label>Filter by status</Form.Label>
                <FinalForm.Field<boolean> name="isPending" type="checkbox">
                  {({ input: { checked, name, onChange } }) => (
                    <Form.Check
                      checked={checked}
                      id="pending-meetings"
                      label="Pending"
                      name={name}
                      onChange={() => onChange(!checked)}
                      type="checkbox"
                    />
                  )}
                </FinalForm.Field>
                <FinalForm.Field<boolean> name="isConfirmed" type="checkbox">
                  {({ input: { checked, name, onChange } }) => (
                    <Form.Check
                      checked={checked}
                      id="confirmed-meetings"
                      label="Confirmed"
                      name={name}
                      onChange={() => onChange(!checked)}
                      type="checkbox"
                    />
                  )}
                </FinalForm.Field>
                <FinalForm.Field<boolean> name="isCancelled" type="checkbox">
                  {({ input: { checked, name, onChange } }) => (
                    <Form.Check
                      checked={checked}
                      id="cancelled-meetings"
                      label="Cancelled"
                      name={name}
                      onChange={() => onChange(!checked)}
                      type="checkbox"
                    />
                  )}
                </FinalForm.Field>
              </Form.Group>

              <Form.Group className="d-flex justify-content-between">
                <Button
                  variant="primary"
                  type="submit"
                  size="sm"
                  block={activeFilterCount === 0}
                >
                  Apply Filters
                </Button>

                {activeFilterCount > 0 && (
                  <Button
                    variant="link"
                    onClick={() => {
                      // reset the filters
                      setFilters({
                        meetingTypes: [],
                        members: [],
                        status: 'CONFIRMED_AND_PENDING',
                      })

                      // close the dropdown
                      document.body.click()
                    }}
                  >
                    <span className="text-danger">Clear all filters</span>
                  </Button>
                )}
              </Form.Group>
            </form>
          )}
        </FinalForm.Form>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default MeetingFilters
