import * as React from 'react'
import { Modal, Nav, Tab, ModalProps } from 'react-bootstrap'

import './TabbedModal.scss'
import Icon from './Icon'

interface Props {
  className?: string
  initialTab?: string
  onHide: () => void
}

const TabbedModal: React.FC<Props & ModalProps> = ({
  className,
  onHide,
  initialTab,
  activeKey,
  children,
  ...modalProps
}) => (
  <Modal
    className={`TabbedModal ${className}`}
    onHide={onHide}
    show={true}
    size="xl"
    {...modalProps}
  >
    <Tab.Container
      activeKey={activeKey}
      defaultActiveKey={initialTab}
      mountOnEnter
    >
      {children}
    </Tab.Container>

    <div
      className="tw-items-center tw-bg-bsGray-900 tw-rounded-full tw-cursor-pointer tw-flex tw-h-9 tw-w-9 tw-absolute tw-justify-center -tw-right-[50px]"
      onClick={onHide}
    >
      <Icon.X className="tw-text-white" size={18} />
      <p className="tw-text-bsGray-200 tw-absolute -tw-bottom-9 tw-tracking-wide">
        esc
      </p>
    </div>
  </Modal>
)

export const TabbedModalNavigation: React.FC = ({ children }) => (
  <Nav
    variant="pills"
    className="TabbedModalNavigation tw-hidden tw-border-l-0 tw-border-r tw-border-y-0 tw-border-solid tw-border-bsGray-400 tw-bg-bsGray-100 tw-rounded-tl-xl tw-flex-[0_0_240px] tw-flex-col tw-px-6 tw-py-4 lg:tw-block"
  >
    {children}
  </Nav>
)

export const TabbedModalContent = Tab.Content

export const TabbedModalFooter = Modal.Footer

export const TabbedModalWrapper: React.FC = ({ children }) => (
  <div className="tw-flex tw-flex-1 tw-overflow-y-auto md:tw-overflow-y-visible md:tw-min-h-[640px] md:tw-max-h-[640px]">
    {children}
  </div>
)

export default TabbedModal
