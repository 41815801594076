import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import Icon from './Icon'
import { Meeting } from '../types'
import UserContext from './UserContext'
import TimeZoneLabel from './TimeZoneLabel'
import TimeLabel from './TimeLabel'
import { DateTime, toast } from '../utils'
import LoadingButton from './LoadingButton'
import { useNewMeetingTimeModalMutation } from '../__generated__/graphql'
import Translate from './Translate'

gql`
  mutation NewMeetingTimeModal($input: RescheduleMeetingInput!) {
    rescheduleMeeting(input: $input) {
      data {
        end
        id
        name
        start
      }
      errors {
        field
        messages
      }
    }
  }
`
export type Props = {
  meeting: Pick<Meeting, 'end' | 'id' | 'start'>
  newStartTime: DateTime
  onHide: () => void
  onSuccess: () => void
}
type FormValues = {
  message?: string
}

const NewMeetingTimeModal: React.FC<Props> = ({
  meeting,
  newStartTime,
  onHide,
  onSuccess,
}) => {
  const [rescheduleMeeting, { loading }] = useNewMeetingTimeModalMutation()
  const onSubmit = async (values: FormValues) => {
    try {
      const res = await rescheduleMeeting({
        variables: {
          input: {
            id: meeting.id,
            start: newStartTime.toISO(),
            ...values,
          },
        },
      })
      // If client validation and GraphQL error related errors exist
      if (res.data?.rescheduleMeeting?.errors) {
        console.error(
          'rescheduleMeeting mutation [NewMeetingTimeModal.tsx]',
          res.data.rescheduleMeeting.errors
        )
        return
      }
      // If data is present trigger the toast to display and close the modal
      if (res.data?.rescheduleMeeting?.data) {
        toast.success(
          `${
            res.data.rescheduleMeeting.data.name
          } rescheduled for ${new DateTime(
            res.data.rescheduleMeeting.data.start
          ).format('localized-medium-date-with-time')}`
        )
        onSuccess()
        return
      }
      // Here we are catching sever related errors.
    } catch (err) {
      toast.error('Something went wrong')
      console.error('rescheduleMeeting mutation [NewMeetingTimeModal.tsx]', err)
    }
  }

  // NOTE
  // Here we get the difference between the start & end of the meeting.
  // `diff()` defaults to milliseconds and we need this to be in 'seconds'
  // We can handle that conversion here or down in `createDuration` but if
  // we do not the 'endTime' presentation wll be super F'd!
  const diff = meeting.end.diff(meeting.start)
  // We use the diff to create the duration value of the meeting (aka: meetingType.duration)
  // We will add the duration to the newTime to generate what the new
  // meetings 'endTime' would be.
  const newEndTime = newStartTime.add(diff)
  return (
    <Modal
      className="NewMeetingTimeModal"
      onHide={onHide}
      show={true}
      size="sm"
    >
      <Modal.Header closeButton>
        <Icon.Repeat size={32} />
        <Modal.Title>
          <Translate>Confirm your new meeting time</Translate>
        </Modal.Title>
      </Modal.Header>
      <FinalForm.Form<FormValues> onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Form
            className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
            onSubmit={handleSubmit}
          >
            <Modal.Body>
              <Form.Group className="tw-items-center tw-flex tw-justify-between tw-pb-[2px]">
                <div>
                  <Translate as="h6" className="tw-mb-[2.4px]">
                    Old Time
                  </Translate>
                  <span className="tw-font-medium">
                    <TimeLabel
                      format="localized-full-date-with-weekday"
                      time={meeting.start}
                    />
                  </span>
                  <div className="tw-font-medium">
                    <TimeLabel format="localized-time" time={meeting.start} />
                    {' - '}
                    <TimeLabel
                      format="localized-time"
                      time={meeting.end}
                    />{' '}
                    <span className="tw-text-bsGray-600">
                      <UserContext.Consumer>
                        {({ user }) => (
                          <TimeZoneLabel shorthand timezone={user.timezone!} />
                        )}
                      </UserContext.Consumer>
                    </span>
                  </div>
                </div>
                <Icon.ArrowRight className="tw-text-bsGray-400" size={40} />
                <div>
                  <Translate as="h6" className="tw-mb-[2.4px]">
                    New Time
                  </Translate>
                  <span className="tw-font-medium">
                    <TimeLabel
                      format="localized-full-date-with-weekday"
                      time={newStartTime}
                    />
                  </span>
                  <div className="tw-font-medium">
                    <TimeLabel format="localized-time" time={newStartTime} />
                    {' - '}
                    <TimeLabel format="localized-time" time={newEndTime} />{' '}
                    <span className="tw-text-bsGray-600">
                      <UserContext.Consumer>
                        {({ user }) => (
                          <TimeZoneLabel shorthand timezone={user.timezone!} />
                        )}
                      </UserContext.Consumer>
                    </span>
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <FinalForm.Field name="message">
                  {({ input }) => (
                    <Form.Control
                      as="textarea"
                      className="placeholder:tw-text-bsGray-500"
                      placeholder="Anything you'd like us to pass along to the attendees?"
                      rows={3}
                      {...input}
                    />
                  )}
                </FinalForm.Field>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="tw-items-stretch tw-justify-between md:tw-justify-end">
              <Button
                className="tw-flex-1 md:tw-flex-shrink md:tw-flex-grow-0"
                onClick={onHide}
                variant="outline-secondary"
              >
                <Translate>Choose Another Time</Translate>
              </Button>
              <LoadingButton
                className="tw-flex-1 md:tw-flex-shrink md:tw-flex-grow-0"
                loading={loading}
                type="submit"
              >
                <Translate>Reschedule Meeting</Translate>
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </FinalForm.Form>
    </Modal>
  )
}

export default NewMeetingTimeModal
