import './MembersPicker.scss'
import * as React from 'react'
import { gql } from '@apollo/client'
import Icon from './Icon'
import { PickerOption } from './Picker'
import Multipicker from './Multipicker'
import ProfileContext from './ProfileContext'
import UserImage from './UserImage'
import { generateUserLabel } from './UserLabel'
import {
  MemberNode,
  useMembersPickerQuery,
  UserNode,
} from '../__generated__/graphql'

gql`
  query MembersPicker($id: ID!) {
    profile: getProfileById(id: $id) {
      id
      members {
        edges {
          node {
            id
            user {
              email
              firstName
              id
              image
              lastName
            }
          }
        }
      }
    }
  }
`

type Props = {
  isInvalid?: boolean
  onChange: (members: Array<string>) => void
  value: Array<string> | null
}

type PickerMemberNode = Pick<MemberNode, 'id'> & {
  user: Pick<UserNode, 'email' | 'firstName' | 'id' | 'image' | 'lastName'>
}

const pickerMemberNodeToPickerOption = (
  member: PickerMemberNode
): PickerOption<PickerMemberNode> => ({
  image: <UserImage user={member.user} size={20} />,
  label: generateUserLabel(member.user),
  value: member,
})

const MembersPicker: React.FC<Props> = ({ isInvalid, onChange, value }) => {
  const profile = React.useContext(ProfileContext)

  const { data, loading } = useMembersPickerQuery({
    variables: { id: profile.id },
  })

  // Compute the PickerOptions from the members
  const options: PickerOption<PickerMemberNode>[] = React.useMemo(
    () =>
      data?.profile.members.edges.map(edge =>
        pickerMemberNodeToPickerOption(edge!.node!)
      ) ?? [],
    [data]
  )

  // Get a PickerOption for the value if one is passed in.
  const pickerValue: PickerOption<PickerMemberNode>[] = React.useMemo(
    () =>
      (value &&
        options &&
        options.filter(option => value.indexOf(option.value.id) !== -1)) ??
      [],
    [value, options]
  )

  return (
    <Multipicker<PickerMemberNode>
      value={pickerValue}
      onChange={values => onChange(values.map(({ value }) => value.id))}
      options={options}
      invalid={isInvalid}
      loading={loading}
      placeholder={
        <React.Fragment>
          <Icon.Users className="mr-8" size={20} />
          Choose members
        </React.Fragment>
      }
    />
  )
}

export default MembersPicker
