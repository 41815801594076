import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import LoadingButton from './LoadingButton'
import {
  AttendeeNode,
  useCancelAttendeesMutation,
} from '../__generated__/graphql'
import { ID } from '../types'
import Icon from './Icon'
import Translate from './Translate'
import Var from './Var'

gql`
  mutation CancelAttendees($input: CancelAttendeesInput!) {
    cancelAttendees(input: $input) {
      data {
        id
        status
        cancelled
      }
      errors {
        field
        messages
      }
    }
  }
`

// State manager for operating on the cancel attendees modal and the related mutation.
export const useCancelAttendeesModal = () => {
  // Prep the mutation
  const [mutation, { loading: submitting }] = useCancelAttendeesMutation()

  // Prep a piece of state that manages the attendees we're about to
  // approve (which will determine if the modal is open/closed).
  const [toCancel, setToCancel] = React.useState<ReadonlyArray<ID> | null>(null)

  // Function to call to trigger the mutation and handle errors.
  const submit = async (meetingId: ID, message: string) => {
    const res = await mutation({
      variables: {
        input: {
          meeting: meetingId,
          attendees: [...toCancel!],
          message: message || null,
        },
      },
    })

    // Check for graphQL errors
    if (res.errors) {
      console.error('Unexpected error cancel attendees', res.errors)
      throw new Error('Unexpected error cancel attendees')
    }

    // Check for validation errors
    if (res.data?.cancelAttendees?.errors) {
      console.error(
        'Validation error cancel attendees',
        res.data.cancelAttendees.errors
      )
      throw new Error('Validation error cancel attendees')
    }

    // If for some reason the expected data isn't present then generate
    // an error.
    if (!res.data?.cancelAttendees?.data) {
      console.error('No attendee data found in response', res)
      throw new Error('No attendee data found in response')
    }

    // Close the modal
    setToCancel(null)

    // Return the attendee data
    return res.data.cancelAttendees.data.map(attendee => attendee!)
  }

  return {
    open: setToCancel,
    close: () => setToCancel(null),
    toCancel: toCancel,
    submit,
    submitting,
  }
}

export type Props = {
  attendees: ReadonlyArray<Pick<AttendeeNode, 'firstName' | 'lastName'>>
  submitting: boolean
  onConfirm?: (message: string) => void
  onCancel?: () => void
}

type FormValues = {
  message: string
}

const CancelAttendeesModal: React.FC<Props> = ({
  attendees,
  submitting,
  onConfirm,
  onCancel,
}) => (
  <Modal className="CancelAttendeesModal" onHide={onCancel} show size="sm">
    <Modal.Header closeButton>
      <Icon.XCircle className="tw-text-bsDanger" size={36} />
      <Modal.Title>
        <Translate>
          {attendees.length > 1 ? 'Cancel Attendees' : 'Cancel Attendee'}
        </Translate>
      </Modal.Title>
    </Modal.Header>
    <FinalForm.Form<FormValues>
      initialValues={{ message: '' }}
      onSubmit={values => onConfirm && onConfirm(values.message)}
    >
      {({ handleSubmit }) => (
        <Form
          className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
          onSubmit={handleSubmit}
        >
          <Modal.Body>
            <p>
              {attendees.length > 1 ? (
                <Translate>
                  We'll send a notification to attendees to let them know their
                  invitation has been cancelled.
                </Translate>
              ) : (
                <Translate>
                  We'll send a notification to
                  <Var name="name">
                    `${attendees[0].firstName} {attendees[0].lastName}`
                  </Var>
                  to let them know their invitation has been cancelled.
                </Translate>
              )}
            </p>

            <Form.Group>
              <FinalForm.Field<string> name="message">
                {({ input }) => (
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Anything you'd like to pass along?"
                    {...input}
                  />
                )}
              </FinalForm.Field>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              block
              loading={submitting}
              type="submit"
              variant="danger"
            >
              <Translate>
                {attendees.length > 1 ? (
                  <>
                    Cancel <Var name="attendees">{attendees.length} </Var>
                    Attendees
                  </>
                ) : (
                  'Cancel Attendee'
                )}
              </Translate>
            </LoadingButton>
          </Modal.Footer>
        </Form>
      )}
    </FinalForm.Form>
  </Modal>
)

export default CancelAttendeesModal
