import * as React from 'react'
import { Maybe } from '../../../../__generated__/graphql'
import { Calendar, CalendarProviderNode } from '../../../../types'
import { Button, Col, ListGroup } from 'react-bootstrap'
import LoadingButton from '../../../LoadingButton'
import {
  AddToCalendarOption,
  CheckForConflictsOption,
} from '../../../molecules/CalendarSettingsOption/CalendarSettingsOption'

export interface CalendarSettingsViewProps {
  readonly calendarType: CalendarProviderNode['name']
  readonly doLaterOnClick: () => void
  readonly onSubmit: () => Promise<void>
  readonly changeAddMeetingSettingOnClick: () => void
  readonly changeCheckConflictSettingOnClick: () => void
  readonly meetingsCalendar: Maybe<Pick<Calendar, 'id' | 'name' | 'color'>>
  readonly availabilityCalendars: Array<Pick<Calendar, 'id' | 'name' | 'color'>>
}

export const CalendarSettingsView: React.FC<CalendarSettingsViewProps> = ({
  calendarType,
  doLaterOnClick,
  onSubmit,
  changeAddMeetingSettingOnClick,
  changeCheckConflictSettingOnClick,
  meetingsCalendar,
  availabilityCalendars,
}: CalendarSettingsViewProps) => {
  return (
    <div className="CalendarSettingsView d-flex flex-column">
      <h1>Your {calendarType} account is connected!</h1>
      <h5 className="subtitle text-gray-600 mt-8">
        Here's how Appointlet will interact with it:
      </h5>
      <Col className="px-0 mt-24">
        <ListGroup>
          <ListGroup.Item>
            <AddToCalendarOption
              onClick={changeAddMeetingSettingOnClick}
              meetingsCalendar={meetingsCalendar}
            />
          </ListGroup.Item>
          <ListGroup.Item>
            <CheckForConflictsOption
              onClick={changeCheckConflictSettingOnClick}
              availabilityCalendars={availabilityCalendars}
            />
          </ListGroup.Item>
        </ListGroup>
      </Col>
      <div className="w-100 mt-24 d-flex justify-content-between">
        <Button
          className="text-primary text-left"
          onClick={doLaterOnClick}
          type="button"
          variant="link"
        >
          I'll do this later
        </Button>
        <LoadingButton className="bg-success border-0" onClick={onSubmit}>
          Continue →
        </LoadingButton>
      </div>
    </div>
  )
}
