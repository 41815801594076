import { AnalyticsEventAttributes } from './analytics'
import { env } from './env'
import { InternalUser } from '../components/UserContext'

let CUSTOM_ANALYTICS_URL: string | undefined

try {
  CUSTOM_ANALYTICS_URL = env('CUSTOM_ANALYTICS_URL')
} catch (error) {
  // Error will be thrown on staging, because we do not
  // want these analytics running in the environment.
  // Catch the error & set to undefined for staging.
  CUSTOM_ANALYTICS_URL = undefined
}

const CustomAnalyticsHandler = {
  userId: '',
  getUserId: () => {
    return CustomAnalyticsHandler.userId
  },
  setUserId: (id: string) => {
    CustomAnalyticsHandler.userId = id
    return CustomAnalyticsHandler
  },
  initialize: (user: InternalUser) => {
    console.log('CustomAnalyticsHandler.initialize', user)

    if (!CUSTOM_ANALYTICS_URL) {
      return Promise.resolve(false)
    }

    CustomAnalyticsHandler.setUserId(user.id)

    return Promise.resolve(true)
  },

  trackEvent: async (
    name: string,
    attributes?: AnalyticsEventAttributes
  ): Promise<boolean> => {
    console.log('CustomAnalyticsHandler.trackEvent', name, attributes)

    if (!CUSTOM_ANALYTICS_URL) {
      return Promise.resolve(false)
    }

    if (!attributes || !attributes.profileId) {
      console.error(
        'No attributes were given or no profileId was present',
        attributes
      )
      return Promise.resolve(false)
    }

    const url = new URL(CUSTOM_ANALYTICS_URL)

    // Add the event name
    url.searchParams.append('name', name)

    // Add information about the profile/user that peformed the event.
    url.searchParams.append('profile_id', String(attributes.profileId))
    url.searchParams.append('user_id', CustomAnalyticsHandler.getUserId())

    // We use this to ensure that the GET request is never cached.
    url.searchParams.append('cache_id', Math.random().toString())

    const res = await fetch(url.href, {
      method: 'GET',
      mode: 'no-cors',
    })

    if (!res.ok) {
      return Promise.resolve(false)
    }

    return Promise.resolve(true)
  },

  pageChanged: (): Promise<boolean> => {
    console.log('CustomAnalyticsHandler.pageChanged')

    if (!CUSTOM_ANALYTICS_URL) {
      return Promise.resolve(false)
    }

    // Nothing to do here.
    return Promise.resolve(true)
  },
}

export default CustomAnalyticsHandler
