import { env } from './env'

const LAST_PAGE_URL_KEY = 'lastPage'
const ACCESS_TOKEN_KEY = 'access_token'

// Redirects the agent to the login page to authenticate
export const redirectToLoginPage = (): void => {
  // Make a note of where the user left off
  storeLastPageURL()

  // Send them to the login page
  window.location.assign(env('LOGIN_URL')!)
}

// Stores the current page URL in the session store
export const storeLastPageURL = (): void => {
  sessionStorage.setItem(
    LAST_PAGE_URL_KEY,
    window.location.pathname + window.location.search
  )
}

// Retrieves the agent's access token from localStorage
export const getAccessToken = (): string | null => {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
}

export const logout = () => {
  localStorage.removeItem('access_token')
  window.location.replace(env('LOGOUT_URL')!)
}
