import * as React from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { Button, Container, Dropdown } from 'react-bootstrap'
import { sortBy } from 'lodash'
import { gql } from '@apollo/client'
import { debounce } from 'lodash'
import Helmet from 'react-helmet'

import './MeetingTypesPage.scss'
import CreateMeetingTypeModal from './CreateMeetingTypeModal'
import CustomizeSchedulingPageModal from './CustomizeSchedulingPageModal'
import DeleteSchedulingPageModal from './DeleteSchedulingPageModal'
import DropdownItem from './DropdownItem'
import Icon from './Icon'
import MeetingTypesPageEmptyState from './MeetingTypesPageEmptyState'
import MoreButton from './MoreButton'
import Page from './Page'
import SchedulingPagePicker from './SchedulingPagePicker'
import SearchInput from './SearchInput'
import SortableMeetingTypeList from './SortableMeetingTypeList'
import UpdateMeetingTypeModal from './UpdateMeetingTypeModal'
import Toggler from './Toggler'
import TopBar from './TopBar'
import ShareButton from './ShareButton'
import Spinner from './Spinner'
import {
  MeetingTypesPageQuery as Response,
  useMeetingTypesPageQuery,
  useCloneMeetingTypeMutation,
  useDeleteMeetingTypeModalMutation,
  Maybe,
} from '../__generated__/graphql'
import {
  Member,
  MeetingType,
  MeetingTypeTeamMember,
  Team,
  TeamMember,
  User,
  ID,
} from '../types'
import {
  Analytics,
  DateTime,
  Duration,
  isNotSoftDeleted,
  toast,
} from '../utils'

import DeleteMeetingTypeModal from './DeleteMeetingTypeModal'
import PostSetupWizardTour from './PostSetupWizardTour'
import PermissionsFlag from './PermissionsFlag'

gql`
  query MeetingTypesPage($id: ID!, $search: String) {
    team: getTeamById(id: $id) {
      bookingUrl
      id
      meetingTypes(search: $search) {
        edges {
          node {
            bookingUrl
            color
            deleted
            description
            duration
            hostAssignmentStrategy
            id
            image
            isGroup
            maxAttendees
            meetingTypeTeamMembers {
              edges {
                node {
                  active
                  id
                  teamMember {
                    id
                    member {
                      id
                      user {
                        email
                        firstName
                        id
                        image
                        lastName
                      }
                    }
                  }
                }
              }
            }
            name
            order
            price
            priceCurrency
            tags
          }
        }
      }
      name
      slug
      teamMembers {
        edges {
          node {
            id
            member {
              id
              user {
                email
                firstName
                id
                image
                lastName
                timezone
              }
            }
          }
        }
      }
    }
  }
`

export type InternalData = {
  team: Pick<Team, 'bookingUrl' | 'id' | 'name' | 'slug'> & {
    meetingTypes: Array<
      Pick<
        MeetingType,
        | 'bookingUrl'
        | 'color'
        | 'deleted'
        | 'description'
        | 'duration'
        | 'hostAssignmentStrategy'
        | 'id'
        | 'image'
        | 'isGroup'
        | 'maxAttendees'
        | 'name'
        | 'price'
        | 'priceCurrency'
        | 'tags'
      > & {
        meetingTypeTeamMembers: Array<
          Pick<MeetingTypeTeamMember, 'active' | 'id'> & {
            teamMember: Pick<TeamMember, 'id'> & {
              member: Pick<Member, 'id'> & {
                user: Pick<
                  User,
                  'email' | 'firstName' | 'id' | 'image' | 'lastName'
                >
              }
            }
          }
        >
      }
    >
    teamMembers: Array<
      Pick<TeamMember, 'id'> & {
        member: Pick<Member, 'id'> & {
          user: Pick<
            User,
            'email' | 'firstName' | 'id' | 'image' | 'lastName' | 'timezone'
          >
        }
      }
    >
  }
}

const wireDataToInternalData = (wireData: Response): InternalData => ({
  team: {
    ...wireData.team,
    // TYPESCRIPT: tags is not typed correctly
    // @ts-ignore
    meetingTypes: wireData.team.meetingTypes.edges.map(edge => ({
      ...edge!.node!,
      deleted: edge!.node!.deleted ? new DateTime(edge!.node!.deleted) : null,
      duration: new Duration({ seconds: edge!.node!.duration }),
      meetingTypeTeamMembers: edge!.node!.meetingTypeTeamMembers
        ? edge!.node!.meetingTypeTeamMembers.edges.map(edge => ({
            ...edge!.node!,
            teamMember: {
              ...edge!.node!.teamMember,
              member: {
                ...edge!.node!.teamMember!.member,
                user: {
                  ...edge!.node!.teamMember!.member!.user,
                },
              },
            },
          }))
        : null,
    })),
    teamMembers: wireData.team.teamMembers!.edges.map(edge => ({
      ...edge!.node!,
      member: {
        ...edge!.node!.member,
        user: {
          ...edge!.node!.member!.user,
        },
      },
    })),
  },
})

gql`
  mutation CloneMeetingType($input: CloneMeetingTypeInput!) {
    cloneMeetingType(input: $input) {
      data {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation DeleteMeetingTypeModal($input: DeleteMeetingTypeInput!) {
    deleteMeetingType(input: $input) {
      data {
        id
        deleted
      }
    }
  }
`

interface Props extends RouteComponentProps {
  // This must remain optional even though it should
  // always be present. Second item on page:
  // https://reach.tech/router/typescript
  schedulingPageId?: string
  profileId?: string
}

const MeetingTypesPage: React.FC<Props> = ({ schedulingPageId, profileId }) => {
  // Prepare mutations
  const [cloneMeetingTypeMutation] = useCloneMeetingTypeMutation()
  const [deleteMeetingTypeMutation, { loading: deletingMeetingType }] =
    useDeleteMeetingTypeModalMutation()

  // Prepare state for search field
  const [search, setSearch] = React.useState<string | undefined>()
  const searchUpdated = debounce(setSearch, 350)

  // Create state for handling the opening/closing of the update meeting type modal
  const [updateModalMeetingTypeId, setUpdateModalMeetingTypeId] =
    React.useState<Maybe<ID>>(null)

  // Create state for handling the opening/closing of the delete meeting type modal
  const [meetingTypeToDelete, setMeetingTypeToDelete] =
    React.useState<Maybe<Pick<MeetingType, 'id' | 'name'>>>(null)

  // Create state for handling the opening/closing of the delete meeting type modal
  const [createMeetingTypeModalIsOpen, setCreateMeetingTypeModalIsOpen] =
    React.useState<boolean>(false)

  // Fetch data to draw page
  const { data, refetch, loading } = useMeetingTypesPageQuery({
    variables: { id: schedulingPageId!, search },
  })

  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data) : null),
    [data]
  )

  // Create a list that excludes deleted meeting types
  const meetingTypes: Maybe<InternalData['team']['meetingTypes']> =
    React.useMemo(
      () =>
        internalData
          ? internalData.team.meetingTypes.filter(isNotSoftDeleted)
          : null,
      [internalData]
    )

  // Handler for cloning a meeting type
  const cloneMeetingType = async (meetingTypeId: ID) => {
    // This should never happen, but guard anyway
    if (!meetingTypes) {
      toast.error('Something went wrong')
      return
    }

    // Get the meeting type we're cloning
    const meetingType = meetingTypes.find(mt => mt.id === meetingTypeId)

    // This should also never happen, but guard
    if (!meetingType) {
      toast.error('Something went wrong')
      return
    }

    try {
      const res = await cloneMeetingTypeMutation({
        variables: { input: { id: meetingType.id } },
      })

      // If client validation and GraphQL error related errors exist
      if (res.data?.cloneMeetingType?.errors) {
        console.error(
          'cloneMeetingType mutation [MeetingTypeCard]',
          res.data.cloneMeetingType.errors
        )
        throw new Error('Failed to clone meeting type.')
      }

      toast.success('Duplicated Meeting Type', meetingType.name)

      // Reload the meeting types
      refetch()
    } catch (err) {
      toast.error('Something went wrong')
      console.error('cloneMeetingType mutation [MeetingTypeCard]', err)
    }
  }

  // Handler for updating a meeting type
  const openUpdateMeetingTypeModal = (meetingTypeId: ID) => {
    // Open the meeting type update modal
    setUpdateModalMeetingTypeId(meetingTypeId)
  }

  const openDeleteMeetingTypeModal = (meetingTypeId: ID) => {
    setMeetingTypeToDelete(meetingTypes?.find(mt => mt.id === meetingTypeId)!)
  }

  // Deletes a meeting type on the server.
  const deleteMeetingType = async () => {
    // This should never happen, but guard anyway.
    if (!meetingTypeToDelete) {
      toast.error('Something went wrong')
      return
    }

    try {
      // Attempt the delete
      const res = await deleteMeetingTypeMutation({
        variables: { input: { id: meetingTypeToDelete.id } },
      })

      if (res.errors) {
        console.error('deleteMeetingType', 'GraphQL Error', res.errors)
        throw new Error('GraphQL Error')
      }

      // If client validation and GraphQL error related errors exist
      if (!res.data?.deleteMeetingType?.data) {
        console.error('deleteMeetingType', 'Validation Error')
        throw new Error('Validation Error')
      }

      // Let the user know it worked
      toast.success(`Deleted Meeting Type`, meetingTypeToDelete.name)

      // Close the modal
      setMeetingTypeToDelete(null)
    } catch (err) {
      toast.error('Something went wrong.')
    }
  }

  return (
    <Page className="MeetingTypesPage">
      <Helmet title="Meeting Types" />

      <TopBar>
        <div className="d-flex w-100">
          <div className="d-flex flex-grow-1 space-between-8">
            <SchedulingPagePicker
              onChange={id =>
                navigate(`/profiles/${profileId}/scheduling-pages/${id}`)
              }
              value={schedulingPageId!}
            />

            <Button
              as="a"
              href={internalData?.team.bookingUrl}
              target="_blank"
              variant="outline-secondary"
              className="d-none d-sm-inline"
              onClick={() => {
                Analytics.trackEvent('Preview Scheduling Page', {
                  source: 'meeting-types-page-scheduling-page-toolbar',
                })
              }}
            >
              <Icon.ExternalLink className="text-success" size={16} />
              View Live
            </Button>

            <ShareButton
              label={internalData?.team.name || ''}
              url={internalData?.team.bookingUrl || ''}
              analyticsSource="meeting-types-page-scheduling-page-toolbar"
            />

            <PermissionsFlag permission="canManageMeetingTypes">
              <MoreButton>
                <Toggler>
                  {({ isToggled, setOff, setOn }) => (
                    <React.Fragment>
                      <DropdownItem onClick={setOn}>
                        <Icon.Settings size={20} />
                        Design & Settings
                      </DropdownItem>

                      {isToggled && (
                        <CustomizeSchedulingPageModal
                          onHide={setOff}
                          onSuccess={setOff}
                          team={internalData!.team}
                        />
                      )}
                    </React.Fragment>
                  )}
                </Toggler>

                <Dropdown.Divider />

                <Toggler>
                  {({ isToggled, setOff, setOn }) => (
                    <React.Fragment>
                      <DropdownItem onClick={setOn}>
                        <Icon.Trash2 size={20} />
                        Delete Scheduling Page
                      </DropdownItem>

                      {isToggled && (
                        <DeleteSchedulingPageModal
                          onHide={setOff}
                          onSuccess={async () => {
                            // navigate the user back to /scheduling-pages
                            // we will execute the query again and send them
                            // to the first scheduling page found or show them
                            // the empty state.
                            navigate(`/profiles/${profileId}/scheduling-pages`)
                          }}
                          schedulingPage={internalData!.team}
                        />
                      )}
                    </React.Fragment>
                  )}
                </Toggler>
              </MoreButton>
            </PermissionsFlag>
          </div>

          <div className="ml-8 ml-lg-auto">
            <Button
              className="MeetingTypesPage--AddMeetingType"
              // Disable the button if the data has not
              // populated yet or explosions ensue.
              disabled={!internalData?.team}
              onClick={() => setCreateMeetingTypeModalIsOpen(true)}
              variant="success"
            >
              <Icon.PlusCircle className="text-white" size={16} />
              <span className="d-md-none">New</span>
              <span className="d-none d-md-inline">New Meeting Type</span>
            </Button>
          </div>
        </div>
      </TopBar>

      {(loading || !meetingTypes) && (
        // While the query is loading show this UI.
        <div className="align-items-center d-flex justify-content-center py-128">
          <Spinner />
        </div>
      )}

      {meetingTypes && (
        <Container>
          {
            // If we aren't searching & there are no MT's found.
            (!search || search.length === 0) && meetingTypes.length === 0 ? (
              <MeetingTypesPageEmptyState />
            ) : (
              <React.Fragment>
                <header className="align-items-center d-flex mb-24 MeetingTypesPage--header">
                  <h1 className="align-self-end">Meeting Types</h1>

                  <div className="ml-auto">
                    <SearchInput
                      defaultValue={search}
                      onChange={ev => searchUpdated(ev.currentTarget.value)}
                    />
                  </div>
                </header>

                <SortableMeetingTypeList
                  meetingTypes={sortBy(meetingTypes, 'order')}
                  onClone={cloneMeetingType}
                  onUpdate={openUpdateMeetingTypeModal}
                  onDelete={openDeleteMeetingTypeModal}
                  // Don't allow sorting if there is a search term present,
                  // it breaks things.
                  sortingDisabled={!!search}
                />
              </React.Fragment>
            )
          }
        </Container>
      )}

      {updateModalMeetingTypeId && (
        <UpdateMeetingTypeModal
          id={updateModalMeetingTypeId}
          onHide={() => setUpdateModalMeetingTypeId(null)}
        />
      )}

      {meetingTypeToDelete && (
        <DeleteMeetingTypeModal
          name={meetingTypeToDelete.name}
          loading={deletingMeetingType}
          onConfirm={deleteMeetingType}
          onCancel={() => setMeetingTypeToDelete(null)}
        />
      )}

      {createMeetingTypeModalIsOpen && (
        <CreateMeetingTypeModal
          onHide={() => setCreateMeetingTypeModalIsOpen(false)}
          onSuccess={() => {
            setCreateMeetingTypeModalIsOpen(false)
            refetch()
          }}
          team={internalData!.team}
        />
      )}

      {!loading &&
        !updateModalMeetingTypeId &&
        !createMeetingTypeModalIsOpen && (
          <PostSetupWizardTour
            hasMeetingTypes={!!(meetingTypes && meetingTypes.length > 0)}
          />
        )}
    </Page>
  )
}

export default MeetingTypesPage
