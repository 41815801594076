import * as React from 'react'

import './Switch.scss'
import { expandClassName } from '../utils'

interface Props {}

const Switch: React.FC<Props & React.HTMLProps<HTMLInputElement>> = ({
  className,
  ...rest
}) => (
  <input
    className={expandClassName('Switch', className)}
    type="checkbox"
    {...rest}
  />
)

export default Switch
