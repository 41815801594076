import * as React from 'react'
import Select from 'react-select'

import { Maybe } from '../__generated__/graphql'
import { Duration, translateString } from '../utils'

type Props = {
  isCancellation?: boolean
  onChange: (value: boolean) => void
  onCustomOptionChange: (value: Duration) => void
  onCustomOptionSelect: () => void
  value: Maybe<boolean | Duration>
}

interface OptionType<TObject = any> {
  value: any
  label: string | React.ReactElement
  image?: React.ReactElement
  object?: TObject
  isDisabled?: boolean
}

type Option = OptionType<{
  label: string
  value: Maybe<boolean | number>
}>

const options = (isCancellation?: boolean) => [
  {
    label: translateString(
      `Allow ${isCancellation ? 'cancellations' : 'reschedules'}`
    ),
    value: true,
  },
  {
    label: translateString(
      `Don't allow ${isCancellation ? 'cancellations' : 'reschedules'}`
    ),
    value: false,
  },
  {
    label: translateString('Allow up to %{count} hour before meeting', {
      count: 1,
    }),
    value: 3600,
  },
  {
    label: translateString('Allow up to %{count} hours before meeting', {
      count: 2,
    }),
    value: 7200,
  },
  {
    label: translateString('Allow up to %{count} hours before meeting', {
      count: 4,
    }),
    value: 14400,
  },
  {
    label: translateString('Allow up to %{count} hours before meeting', {
      count: 8,
    }),
    value: 28800,
  },
  {
    label: translateString('Allow up to %{count} day before meeting', {
      count: 1,
    }),
    value: 86400,
  },
  {
    label: translateString('Allow up to %{count} days before meeting', {
      count: 2,
    }),
    value: 172800,
  },
  {
    label: translateString('Allow up to %{count} days before meeting', {
      count: 3,
    }),
    value: 259200,
  },
  {
    label: translateString('Allow up to %{count} days before meeting', {
      count: 7,
    }),
    value: 604800,
  },
  {
    label: translateString('Custom Policy...'),
    value: null,
  },
]

const policyOptionToPickerOptionType = (option: {
  label: string
  value: Maybe<boolean | number>
}): Option => ({
  ...option,
})

const PolicyPicker: React.FC<Props> = ({
  isCancellation,
  onChange,
  onCustomOptionChange,
  onCustomOptionSelect,
  value,
}) => {
  const opts = options(isCancellation).map(option =>
    policyOptionToPickerOptionType(option)
  )
  return (
    <Select<Option>
      className="lg:tw-w-1/2"
      blurInputOnSelect
      components={{
        ClearIndicator: null,
        IndicatorSeparator: null,
      }}
      isClearable={false}
      isDisabled={false}
      isSearchable={false}
      menuPosition="fixed"
      onChange={option => {
        // Check for 'null' here to toggle the custom option.
        if ((option as Option).value === null) {
          return onCustomOptionSelect()
        }
        if (typeof (option as Option).value === 'boolean') {
          return onChange((option as Option).value as boolean)
        }
        return onCustomOptionChange(
          new Duration((option as Option).value as number)
        )
      }}
      options={opts}
      value={opts.filter(o => o.value === value)[0]}
    />
  )
}

export default PolicyPicker
