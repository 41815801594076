import * as React from 'react'
import DayPicker, {
  DateUtils,
  Modifiers,
  RangeModifier,
} from 'react-day-picker'

import './DateRangePicker.scss'
import { CustomLocaleUtils } from './DatePicker'
import { DateTimeRange, DateTime } from '../utils'

interface Props {
  // Called to determine a busy rating for the day.  This should return a
  // number between 1-3, which will determine how many dots to show below the
  // date.
  busyRating?: (date: DateTime) => number

  // Called to set the starting and ending date of the next/previous month
  // for use with the getMeetingsCountByDay query.
  onMonthChange?: (date: DateTime) => void

  // Called when a date is selected on the date picker
  onChange: (range: DateTimeRange) => void

  // Date to consider as "selected"
  value: DateTimeRange
}

const DateRangePicker: React.FC<Props> = ({
  busyRating,
  onMonthChange,
  onChange,
  value,
}) => {
  const { endDateTime, startDateTime } = value
  // Container for any modifiers we want to have on a date
  const mods: Partial<Modifiers> = {}

  // If start is specified set time to 12:00 to match how DayPicker returns it's dates.
  // This is relevant in the range mod for comparing dates.
  const start = startDateTime.startOf('day').set({ hour: 12 }).toDate()

  // If end is specified set time to 12:00 to match how DayPicker returns it's dates.
  // This is relevant in the range mod for comparing dates.
  const end = endDateTime
    .startOf('day')
    .subtract({ day: 1 })
    .set({ hour: 12 })
    .toDate()

  // Container for days or range selected.
  const selectedDays: RangeModifier[] = []

  // When date is selected we need to calculate the new range.
  // This handles whether the start or end date has changed.
  const didSelect = (date: Date): void => {
    // If the user repeatedly selects the same date the picker removes the selection
    // this causes 'invalid dates' because 'start' & 'end' evaluate to null. To prevent
    // this we check to see if the current 'selection', 'start', and 'end', all are the same.
    // If they are we ignore the select.
    if (
      new DateTime(date).isSame(new DateTime(end)) &&
      new DateTime(date).isSame(new DateTime(start))
    ) {
      return
    }

    const { from, to } = DateUtils.addDayToRange(date, {
      from: start,
      to: end,
    })

    // As per IRL conversation the date range picker should return the
    // start of day for both objects in the range. We are making the decision
    // on the client to be exclusive with our ranges. So that
    // 01-Jan-2020 - 02-Jan-2020 is really just representative of 01-Jan-2020.
    // REVIEW: Is their ever an instance where these args evaluate to null or undefined?
    onChange(
      new DateTimeRange(
        new DateTime(from as Date).startOf('day'),
        new DateTime(to as Date).add({ day: 1 }).startOf('day')
      )
    )
  }

  // Adds '--start' & '--end' classes to DayPicker.
  mods.start = start
  mods.end = end

  // Push the date range into selectedDays.
  selectedDays.push({
    from: start,
    to: end,
  })

  // Cache these values for use in mods.range.
  const modStart = mods.start.getTime()
  const modEnd = mods.end.getTime()

  // Adds '--range' class to days in between start and end.
  mods.range = (date: Date) => {
    const time = date.getTime()
    return time > modStart && time < modEnd
  }

  // If a busyRating function is passed in, then setup the busy modifiers
  if (busyRating) {
    mods.busy1 = (date: Date) => busyRating(new DateTime(date)) === 1
    mods.busy2 = (date: Date) => busyRating(new DateTime(date)) === 2
    mods.busy3 = (date: Date) => busyRating(new DateTime(date)) === 3
  }
  return (
    <div className="DateRangePicker">
      <DayPicker
        initialMonth={start}
        locale={new DateTime().getLocale()}
        localeUtils={CustomLocaleUtils}
        modifiers={mods}
        onDayClick={didSelect}
        onMonthChange={date =>
          onMonthChange && onMonthChange(new DateTime(date))
        }
        selectedDays={selectedDays}
        showOutsideDays
      />
    </div>
  )
}

export default DateRangePicker
