import { Currency } from '../__generated__/graphql'

export const formatMoney = (
  amount: number,
  currency: Currency,
  locale: string
) => {
  // Yes Javascript has -0 for some reason and
  // the NumberFormat actually shows -$0.00 for it.
  if (amount === -0) {
    amount = 0
  }

  return new Intl.NumberFormat(locale, {
    currency: currency,
    style: 'currency',
  }).format(amount)
}
