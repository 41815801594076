import * as React from 'react'
import Helmet from 'react-helmet'

import './ErrorScreen.scss'

import Illustration from './Illustration'

interface Props {
  helmetTitle: string
  title: string
  subTitle?: string
}

const ErrorScreen: React.FC<Props> = ({ helmetTitle, title, subTitle }) => {
  return (
    <>
      <Helmet title={helmetTitle} />
      <div className="ErrorScreen space-between-24">
        <Illustration name="pluggingInCable" className="mw-100" />

        <div className="text-center d-flex flex-column space-between-8">
          <h1>{title}</h1>
          {subTitle && <h5 className="text-gray-600">{subTitle}</h5>}
        </div>
      </div>
    </>
  )
}

export default ErrorScreen
