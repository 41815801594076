export const unsavedChangesAlert = (
  state: boolean,
  callback: () => void
): void | null =>
  !state
    ? callback()
    : window.confirm(
        'You have unsaved changes, are you sure you want to continue?'
      )
    ? callback()
    : null
