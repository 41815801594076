import * as React from 'react'
import UserContext from './UserContext'
import MeetingsListDateItem from './MeetingsListDateItem'
import { IMeeting } from './MeetingsPage'
import { ID } from '../types'
import { DateTime } from '../utils'
import MeetingViewingTimezoneContext from './MeetingViewingTimezoneContext'

type Props = {
  meetings: Array<IMeeting>
  onCancel?: (meetingId: ID) => void
  onApprove?: (meetingId: ID) => void
  onDecline?: (meetingId: ID) => void
  onViewDetails?: (meetingId: ID) => void
}

type MeetingsGroup = {
  date: DateTime
  meetings: Array<IMeeting>
}

// Takes a list of meetings and groups them by their common start dates.
// Currently this operates on the user's locale timezone, but in the future it
// might need to be extended to work on a given zone.
const groupMeetingsByDate = (
  meetings: Array<IMeeting>,
  timezone: string
): MeetingsGroup[] => {
  return meetings.reduce((days: MeetingsGroup[], meeting) => {
    // Take the start date for the meeting and find the start of the day it's on
    const startOfDay = meeting.start.setZone(timezone).startOf('day')

    // Get the last day out of the days array
    const [lastDay] = days.slice(-1)

    // If there is a last day, and it's date matches our meeting's start date,
    // add our meeting to it
    if (lastDay && lastDay.date.isSame(startOfDay)) {
      lastDay.meetings.push(meeting)
    } else {
      // If there is no place for this meeting, create a new day for it.
      days.push({ date: startOfDay, meetings: [meeting] })
    }

    return days
  }, [] as MeetingsGroup[])
}

const MeetingsPageList: React.FC<Props> = ({
  meetings,
  onCancel,
  onApprove,
  onDecline,
  onViewDetails,
}) => {
  const { user } = React.useContext(UserContext)
  const viewingTimezone = React.useContext(MeetingViewingTimezoneContext)

  return (
    <div className="MeetingsPageList">
      {groupMeetingsByDate(meetings, viewingTimezone || user.timezone!).map(
        (meetingGroup, idx) => (
          <MeetingsListDateItem
            date={meetingGroup.date}
            key={idx}
            meetings={meetingGroup.meetings}
            onCancel={onCancel}
            onApprove={onApprove}
            onDecline={onDecline}
            onViewDetails={onViewDetails}
          />
        )
      )}
    </div>
  )
}

export default MeetingsPageList
