import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, Modal } from 'react-bootstrap'

import AvailabilityInput from './AvailabilityInput'
import {
  IMeetingType,
  IMeetingTypeTeamMember,
} from './UpdateMeetingTypeContext'
import { MeetingTypeTeamMember, Schedule } from '../types'
import { Duration } from '../utils'
import Translate from './Translate'

type Value = Pick<MeetingTypeTeamMember, 'customAvailability' | 'schedules'>

export type Props = {
  value: Value
  onChange: (value: Value) => void
  onHide: () => void
  defaultSchedules: Array<Schedule>
  minimumTimeBlockDuration: Duration
}

type FormValues = Value

const UpdateMeetingTypeTeamMemberAvailabilityModal: React.FC<Props> = ({
  minimumTimeBlockDuration,
  defaultSchedules,
  onChange,
  onHide,
  value,
}) => (
  <Modal
    className="UpdateMeetingTypeTeamMemberAvailabilityModal"
    enforceFocus={false}
    onHide={onHide}
    show
    size="lg"
  >
    <Modal.Header closeButton>
      <Modal.Title>
        <Translate>Update Availability</Translate>
      </Modal.Title>
    </Modal.Header>
    <FinalForm.Form<FormValues>
      initialValues={{
        customAvailability: value.customAvailability,
        schedules: value.customAvailability
          ? value.schedules
          : defaultSchedules,
      }}
      onSubmit={values => {
        onChange(values)
        onHide()
      }}
    >
      {({ handleSubmit }) => (
        <FinalForm.Field<
          IMeetingTypeTeamMember['customAvailability']
        > name="customAvailability">
          {({ input: customAvailabilityInput }) => (
            <FinalForm.Field<IMeetingType['schedules']> name="schedules">
              {({ input: schedulesInput }) => (
                <Form
                  className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
                  onSubmit={handleSubmit}
                >
                  <Modal.Body>
                    <AvailabilityInput
                      minimumTimeBlockDuration={minimumTimeBlockDuration}
                      onChange={schedules => {
                        // Update the schedules
                        schedulesInput.onChange(schedules)

                        // mark availability as customized
                        customAvailabilityInput.onChange(true)
                      }}
                      value={schedulesInput.value}
                    />
                  </Modal.Body>
                  <Modal.Footer className="tw-flex">
                    {true && (
                      <Button
                        className="tw-mr-auto tw-flex-1 tw-mb-4 tw-text-bsDanger md:tw-flex-shrink md:tw-flex-grow-0 md:tw-mb-0"
                        onClick={() => {
                          // toggle schedules back to the defaults
                          schedulesInput.onChange(defaultSchedules)

                          // toggle availability back to not customized
                          customAvailabilityInput.onChange(false)
                        }}
                        variant="link"
                      >
                        <Translate>
                          Revert to Meeting Type Availability
                        </Translate>
                      </Button>
                    )}
                    <Button
                      className="tw-flex-1 md:tw-flex-shrink md:tw-flex-grow-0"
                      type="submit"
                    >
                      <Translate>Save Changes</Translate>
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </FinalForm.Field>
          )}
        </FinalForm.Field>
      )}
    </FinalForm.Form>
  </Modal>
)

export default UpdateMeetingTypeTeamMemberAvailabilityModal
