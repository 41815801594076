import * as React from 'react'
import * as Draft from 'draft-js'

import './RichTextEditorControls.scss'
import Icon from './Icon'
import RichTextEditorButton from './RichTextEditorButton'

interface Props {
  editorState: Draft.EditorState
  toggleBlock: (blockStyle: string) => void
  toggleInline: (inlineStyle: string) => void
}

interface Control {
  component: React.ReactNode
  label: string
  style: string
}

// Add & Remove inline controls here.
const INLINE: Control[] = [
  { component: <Icon.Bold size={16} />, label: 'bold', style: 'BOLD' },
  { component: <Icon.Italic size={16} />, label: 'italic', style: 'ITALIC' },
  {
    component: <Icon.Underline size={16} />,
    label: 'underline',
    style: 'UNDERLINE',
  },
]

// Add & Remove block controls here.
const BLOCK: Control[] = [
  {
    component: <Icon.List size={16} />,
    label: 'unordered-list-item',
    style: 'unordered-list-item',
  },
  {
    component: <Icon.List size={16} />,
    label: 'ordered-list-item',
    style: 'ordered-list-item',
  },
]

const RichTextEditorControls: React.FC<Props> = ({
  editorState,
  toggleBlock,
  toggleInline,
}) => {
  const currentStyle = editorState.getCurrentInlineStyle()
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()
  return (
    <div className="RichTextEditorControls">
      {INLINE.map(i => (
        <RichTextEditorButton
          active={currentStyle.has(i.style)}
          icon={i.component}
          key={i.label}
          onToggle={toggleInline}
          style={i.style}
        />
      ))}
      {BLOCK.map(b => (
        <RichTextEditorButton
          active={b.style === blockType}
          icon={b.component}
          key={b.label}
          onToggle={toggleBlock}
          style={b.style}
        />
      ))}
    </div>
  )
}

export default RichTextEditorControls
