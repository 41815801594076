import * as React from 'react'
import { Button, Dropdown, Media } from 'react-bootstrap'

import './CalendarProviderItem.scss'
import DropdownItem from './DropdownItem'
import Icon from './Icon'
import LoadingButton from './LoadingButton'
import MoreButton from './MoreButton'

type Props = {
  name: string
  description: string
  slug: string
  onConnect: () => void
  isConnecting: boolean

  // When there is only one calendar account, we change the look of this
  // unit to act more like an account than a provider.  If they connect
  // more than one, it goes back to being a provider-only row.
  calendarAccount?: {
    name: string
    isConnected: boolean
    onDelete: () => void
    onReconnect: () => void
  }
}

const CalendarProviderItem: React.FC<Props> = ({
  name,
  description,
  slug,
  onConnect,
  isConnecting,
  calendarAccount,
}) => (
  <Media className="align-items-center space-between-12">
    <Icon.InternalAsset assetName={slug} size={40} />

    <Media.Body className="d-flex flex-column">
      <strong className="mb-2">{name}</strong>
      <p className="text-gray-600">{description}</p>
    </Media.Body>

    {calendarAccount && !calendarAccount.isConnected && (
      <Button onClick={calendarAccount.onReconnect} variant="outline-danger">
        <Icon.RefreshCw size={16} />
        Reconnect
      </Button>
    )}

    {!calendarAccount && (
      <LoadingButton
        disabled={isConnecting}
        loading={isConnecting}
        onClick={onConnect}
        variant="success"
      >
        Connect
      </LoadingButton>
    )}

    {calendarAccount && (
      <MoreButton alignRight>
        <DropdownItem onClick={onConnect}>
          <Icon.Plus size={16} />
          Connect Another
        </DropdownItem>
        <Dropdown.Divider />
        <Dropdown.Header>{calendarAccount.name}</Dropdown.Header>
        <DropdownItem onClick={calendarAccount.onReconnect}>
          <Icon.RefreshCw size={16} />
          Reconnect
        </DropdownItem>
        <DropdownItem onClick={calendarAccount.onDelete}>
          <Icon.Trash2 size={16} />
          Disconnect
        </DropdownItem>
      </MoreButton>
    )}
  </Media>
)

export default CalendarProviderItem
