import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Col, Form, Media, Modal, Row } from 'react-bootstrap'
import { gql } from '@apollo/client'

import DynamicBookingURLInput from './DynamicBookingURLInput'
import HelpToolTip from './HelpToolTip'
import Icon from './Icon'
import ImageButton from './ImageButton'
import ImageInput from './ImageInput'
import LanguagePicker from './LanguagePicker'
import LoadingButton from './LoadingButton'
import { InternalData as MeetingTypesPageData } from './MeetingTypesPage'
import ProfileContext from './ProfileContext'
import Translate from './Translate'
import {
  CustomizeSchedulingPageModalQuery as Response,
  Maybe,
  useCustomizeSchedulingPageModalQuery,
  useCustomizeSchedulingPageModalUpdateMutation,
} from '../__generated__/graphql'
import { Member, Team, TeamMember } from '../types'
import { mutationErrorsToFormErrors, toast, Analytics } from '../utils'

gql`
  query CustomizeSchedulingPageModal($id: ID!) {
    team: getTeamById(id: $id) {
      description
      id
      image
      language
      name
      slug
      teamMembers {
        edges {
          node {
            id
            member {
              id
            }
          }
        }
      }
    }
  }
`

gql`
  mutation CustomizeSchedulingPageModalUpdate($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      data {
        # We return the bookingUrl to flush the cache.
        # bookingUrl is what is being used elsewhere in the app, not slug.
        bookingUrl
        description
        id
        image
        language
        meetingTypes {
          edges {
            node {
              # We return the bookingUrl for the MT's related to the Team to
              # flush the cache for those values so they are not stale.
              bookingUrl
              id
            }
          }
        }
        name
        slug
      }
      errors {
        field
        messages
      }
    }
  }
`

export type Props = {
  onHide: () => void
  onSuccess: () => void
  team: Pick<MeetingTypesPageData['team'], 'id' | 'name'>
}

type FormValues = Pick<
  Team,
  'description' | 'image' | 'language' | 'name' | 'slug'
>

type InternalData = Pick<
  Team,
  'description' | 'image' | 'language' | 'name' | 'slug'
> & {
  teamMembers: Array<
    Pick<TeamMember, 'id'> & {
      member: Pick<Member, 'id'>
    }
  >
}

const wireDataToInternalData = (wireData: Response): InternalData => ({
  ...wireData.team!,
  teamMembers: wireData.team!.teamMembers!.edges.map(edge => ({
    ...edge!.node!,
    member: edge?.node?.member!,
  })),
})

const CustomizeSchedulingPageModal: React.FC<Props> = ({
  onHide,
  onSuccess,
  team,
}) => {
  const { data, loading } = useCustomizeSchedulingPageModalQuery({
    variables: { id: team.id },
  })

  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data) : null),
    [data]
  )

  const [updateTeam, { loading: mutationLoading }] =
    useCustomizeSchedulingPageModalUpdateMutation()

  React.useEffect(() => {
    Analytics.trackEvent('Update Scheduling Page Modal: Opened')
  }, [])

  const onSubmit = async (values: FormValues) => {
    try {
      const res = await updateTeam({
        variables: {
          input: {
            id: team.id,
            ...values,
            description: values.description ? values.description : '',
          },
        },
      })
      // If client validation and GraphQL error related errors exist
      if (res.data?.updateTeam?.errors) {
        console.error(
          'updateTeam mutation [CustomizeSchedulingPageModal.tsx]',
          res.data.updateTeam.errors
        )
        return mutationErrorsToFormErrors(res.data.updateTeam.errors)
      }
      // If data is present trigger the toast to display and close the modal
      if (res.data?.updateTeam?.data) {
        onSuccess()
        Analytics.trackEvent('Updated Scheduling Page Modal: Submitted')
      }
    } catch (err) {
      toast.error('Something went wrong')
      console.error(
        'updateTeam mutation [CustomizeSchedulingPageModal.tsx]',
        err
      )
    }
  }
  return (
    <Modal
      className="CustomizeSchedulingPageModal"
      onHide={onHide}
      show
      size="xl"
    >
      <Modal.Header closeButton>
        <Icon.Settings size={32} />
        <Modal.Title>
          <Translate>Scheduling Page Settings</Translate>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loading && internalData && (
          <FinalForm.Form<FormValues>
            initialValues={{
              description: internalData.description,
              image: internalData.image,
              language: internalData.language,
              name: internalData.name,
              slug: internalData.slug,
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <DynamicBookingURLInput
                      bookingUrl=""
                      nameLabel="Name / Page Title"
                      onEditLink={() =>
                        Analytics.trackEvent(
                          'Customize Scheduling Page Modal: Edited Link'
                        )
                      }
                      slugLabel="Link URL"
                    />

                    <Form.Group>
                      <Form.Label>
                        <Translate>Welcome Message</Translate>
                      </Form.Label>
                      <FinalForm.Field<string> name="description">
                        {({ input }) => (
                          <Form.Control
                            as="textarea"
                            placeholder="Looking forward to meeting you!"
                            rows={4}
                            {...input}
                          />
                        )}
                      </FinalForm.Field>
                    </Form.Group>

                    <FinalForm.Field<FormValues['image']> name="image">
                      {({ input: { onChange, value } }) => (
                        <Form.Group>
                          <Form.Label>
                            <Translate>Picture or Logo</Translate>
                          </Form.Label>
                          <Media className="tw-space-x-2">
                            <ImageInput onChange={onChange} value={value!} />
                            <Media.Body className="tw-mr-3 tw-flex tw-flex-col tw-space-x-2">
                              <ImageButton onChange={onChange} value={value!} />
                              <p className="tw-text-bsGray-600">
                                <Translate>
                                  JPG or PNG. For best presentation, should be
                                  square and at least 128px by 128px.
                                </Translate>
                              </p>
                            </Media.Body>
                          </Media>
                        </Form.Group>
                      )}
                    </FinalForm.Field>

                    <Form.Group>
                      <Form.Label className="tw-flex tw-items-center tw-space-x-[5px]">
                        <Translate>Language</Translate>
                        <HelpToolTip>
                          <Translate>
                            Language used for attendees viewing the scheduling
                            page and in emails they receive after scheduling
                          </Translate>
                        </HelpToolTip>
                      </Form.Label>

                      <FinalForm.Field name="language">
                        {({ input }) => (
                          <LanguagePicker
                            onChange={input.onChange}
                            value={input.value}
                          />
                        )}
                      </FinalForm.Field>
                    </Form.Group>

                    <LoadingButton
                      block
                      loading={mutationLoading}
                      type="submit"
                    >
                      <Translate>Save Changes</Translate>
                    </LoadingButton>
                  </Col>

                  <ProfileContext.Consumer>
                    {profile => (
                      <Col lg={6} className="tw-hidden lg:tw-block">
                        <div
                          className="tw-w-auto tw-border-bsGray-300 tw-border-t-bsPrimary tw-border-solid tw-rounded-[5px] tw-border-b tw-border-l tw-border-r tw-flex tw-flex-col tw-h-full tw-shadow-[0_0_15px_rgba(0,0,0,0.1)]"
                          style={{
                            borderTop: `0.3125rem solid ${
                              profile.brandColor
                                ? profile.brandColor
                                : '#1e9bff'
                            }`,
                          }}
                        >
                          <div
                            className={`tw-bg-white tw-items-center tw-border-x-0 tw-border-t-0 tw-border-bsGray-300 tw-border-b tw-border-solid tw-flex tw-relative tw-h-[69px] tw-shadow-[0_1px_2px_rgba(0,0,0,0.04)] ${
                              !internalData.image ? 'tw-pl-3' : ''
                            }`}
                          >
                            {internalData.image && (
                              <img
                                alt={internalData.name}
                                className="tw-mx-3 tw-max-h-9"
                                src={internalData.image}
                              />
                            )}
                            <div className="tw-flex tw-flex-col tw-justify-center tw-truncate tw-items-start tw-h-[69px]">
                              <FinalForm.Field name="name">
                                {({ input }) => (
                                  <span className="tw-text-bsGray-600 tw-text-xs">
                                    {input.value}
                                  </span>
                                )}
                              </FinalForm.Field>
                              <span className="tw-text-bsGray-900 tw-text-base tw-font-heading">
                                <Translate>Choose a meeting</Translate>
                              </span>
                            </div>
                            <div className="tw-absolute -tw-right-[1px] -tw-top-[5px]">
                              <div
                                className="tw-text-white tw-items-center tw-rounded-t-none tw-rounded-r-none tw-rounded-b-none tw-rounded-l-[7px] tw-shadow-[0_4px_4px_rgba(0,0,0,0.1)] tw-flex tw-flex-col tw-justify-center tw-h-[42px] tw-w-[84px] md:tw-w-[100px] md:tw-rounded-bl-2xl md:tw-rounded-tr-[4px]"
                                style={{
                                  backgroundColor: profile.brandColor
                                    ? profile.brandColor
                                    : '#1e9bff',
                                }}
                              >
                                <span className="tw-text-bsGray-300 tw-text-[10px] tw-leading-3">
                                  <Translate>Powered by</Translate>
                                </span>
                                <span className="tw-text-white tw-font-heading tw-text-sm tw-leading-[18px]">
                                  Appointlet
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="tw-p-6 tw-bg-bsGray-100 tw-flex tw-flex-col tw-flex-grow tw-rounded-b-[5px] tw-space-y-6">
                            <FinalForm.Field<string> name="description">
                              {({ input }) =>
                                input.value ? (
                                  <p className="tw-p-4 tw-border tw-border-solid tw-rounded-[5px] tw-border-bsWarning tw-highlight tw-break-words">
                                    {input.value}
                                  </p>
                                ) : null
                              }
                            </FinalForm.Field>
                            {[1, 2, 3].map(i => (
                              <div
                                className="tw-rounded-[5px] tw-bg-bsGray-200 tw-h-[60px]"
                                key={i}
                              />
                            ))}
                          </div>
                        </div>
                      </Col>
                    )}
                  </ProfileContext.Consumer>
                </Row>
              </Form>
            )}
          </FinalForm.Form>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default CustomizeSchedulingPageModal
