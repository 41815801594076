import * as React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

import './SelectableButton.scss'
import { expandClassName } from '../utils'

interface Props {
  noCheck?: boolean
  selectionVariant: 'success' | 'warning' | null
}

const SelectableButton: React.FC<
  Props & ButtonProps & Omit<React.HTMLProps<HTMLButtonElement>, 'ref' | 'as'>
> = ({ children, className, noCheck, selectionVariant, ...rest }) => (
  <Button
    className={expandClassName(
      `SelectableButton ${selectionVariant} ${noCheck && 'no-check'}`,
      className
    )}
    variant="outline-secondary"
    type="button"
    {...rest}
  >
    {children}
  </Button>
)

export default SelectableButton
