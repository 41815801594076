import * as React from 'react'

import useLocalStorageState from 'use-local-storage-state'
import { Timezone } from '../__generated__/graphql'
import ProfileContext from '../components/ProfileContext'

export const useMeetingViewingTimezone = (): [
  Timezone | undefined,
  (timezone: Timezone | undefined) => void,
  () => void
] => {
  const profile = React.useContext(ProfileContext)

  // We include the profile ID in the cache key so that it
  // doesn't leak into other profiles.  Our support team is
  // usually the one that has this issue.
  const [timezone, setTimezone, { removeItem }] = useLocalStorageState<
    Timezone | undefined
  >(`profile:${profile.id}:MeetingViewingTimezone`)

  return [timezone, setTimezone, removeItem]
}
