import * as React from 'react'
import { Role } from '../types'

import MemberContext from './MemberContext'

type Props = {
  notAllowed?: boolean
  // This becomes a union type
  permission: keyof Pick<
    Role,
    | 'canManageBilling'
    | 'canManageMeetings'
    | 'canManageMeetingTypes'
    | 'canManageMembers'
    | 'canManageOrgSettings'
  >
}

const PermissionsFlag: React.FC<Props> = ({
  children,
  notAllowed,
  permission,
}) => {
  const { role } = React.useContext(MemberContext)
  // Do a key/value lookup and if the permission is set to
  // true render the children. If not do not show the UI to
  // the current user.
  return (
    <React.Fragment>
      {role[permission] === !notAllowed ? children : null}
    </React.Fragment>
  )
}

export default PermissionsFlag
