import { Maybe } from '../__generated__/graphql'
import { User } from '../types'

export class UserLocalSettings {
  constructor(public user: Pick<User, 'id'>) {
    this.user = user
  }
  public get(key: string): Maybe<string> {
    return window.localStorage.getItem(`user:${this.user.id}:${key}`)
  }
  public remove(key: string): void {
    return window.localStorage.removeItem(`user:${this.user.id}:${key}`)
  }
  public set(key: string, value: string): void {
    return window.localStorage.setItem(`user:${this.user.id}:${key}`, value)
  }
}
