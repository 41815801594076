import * as React from 'react'
import './Badge.scss'
import {
  Badge as BootstrapBadge,
  BadgeProps as BootstrapBadgeProps,
} from 'react-bootstrap'

type Props = Pick<BootstrapBadgeProps, 'variant' | 'className'>

const Badge: React.FC<Props> = ({ ...rest }) => (
  <BootstrapBadge pill {...rest} />
)

export default Badge
