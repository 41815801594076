export function decodeQueryParams<TParams extends { [key: string]: any }>(
  params?: string
): TParams {
  return (params || window.location.search.substr(1))
    .split('&')
    .reduce((acc, paramPair) => {
      // Split the key/val
      const [key, val] = paramPair.split('=')

      // Decode and store
      // @ts-ignore
      acc[decodeURIComponent(key)] = decodeURIComponent(val)

      return acc
    }, {} as TParams)
}

export function encodeQueryParams(data: {
  [key: string]: string | number | null | undefined | boolean | unknown
}): string {
  return (
    Object.keys(data)
      .reduce((acc, key) => {
        if (
          data[key] === null ||
          data[key] === undefined ||
          // Stops the propagation of search=&meetingTypes=&members=&.....
          (typeof data[key] === 'string' && (data[key] as string).length === 0)
        ) {
          return acc
        }
        return (
          acc +
          encodeURIComponent(key) +
          '=' +
          encodeURIComponent(data[key] as string) +
          '&'
        )
      }, '')
      // Remove the trailing '&'.
      .replace(/&$/, '')
  )
}
