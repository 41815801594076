import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, Modal } from 'react-bootstrap'

import Icon from './Icon'
import LoadingButton from './LoadingButton'
import TimeZoneLabel from './TimeZoneLabel'
import { Timezone } from '../__generated__/graphql'
import Translate from './Translate'
import Var from './Var'

export type Props = {
  userTimezone: Timezone
  browserTimezone: Timezone
  meetingViewingTimezone: Timezone | undefined
  onHide?: () => void
  onSuccess?: (timezone: Timezone, shouldUpdateUser: boolean) => void
}

type FormValues = {
  timezone: Timezone
  shouldUpdateUser: boolean
}

const ChangeMeetingViewingTimezoneModal: React.FC<Props> = ({
  onHide,
  onSuccess,
  userTimezone,
  browserTimezone,
  meetingViewingTimezone,
}) => (
  <Modal
    className="ChangeMeetingViewingTimezoneModal"
    onHide={onHide}
    show
    size="sm"
  >
    <Modal.Header closeButton>
      <Modal.Title className="align-items-center d-flex">
        <Icon.Globe className="mr-12 text-success" size="36" />
        <Translate>Where in the world?</Translate>
      </Modal.Title>
    </Modal.Header>
    <FinalForm.Form<FormValues>
      initialValues={{
        // If there is a meetingViewingTimezone set use that value as
        // the default, else fallback to the user's timezone.
        timezone: meetingViewingTimezone || userTimezone,
        shouldUpdateUser: false,
      }}
      onSubmit={async values =>
        onSuccess && (await onSuccess(values.timezone, values.shouldUpdateUser))
      }
    >
      {({ handleSubmit, submitting, values }) => (
        <Form
          className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
          onSubmit={handleSubmit}
        >
          <Modal.Body>
            <Translate as="p">
              It looks like you're in a different timezone than what you have
              configured in your settings.
            </Translate>

            <Translate as="p">
              What timezone would you like to view meetings in?
            </Translate>

            <Form.Group className="mb-12">
              <FinalForm.Field<Timezone>
                name="timezone"
                value={browserTimezone}
                type="radio"
              >
                {({ input: { checked, name, onChange, value } }) => (
                  <Form.Check
                    checked={checked}
                    value={value}
                    label={
                      <div>
                        <TimeZoneLabel timezone={browserTimezone} />

                        <span className="ml-4 text-gray-600">
                          (<Translate>current timezone</Translate>)
                        </span>
                      </div>
                    }
                    id="browser-timezone"
                    name={name}
                    onChange={onChange}
                    type="radio"
                  />
                )}
              </FinalForm.Field>
            </Form.Group>

            <Form.Group>
              <FinalForm.Field<Timezone>
                name="timezone"
                value={userTimezone}
                type="radio"
              >
                {({ input: { checked, name, onChange, value } }) => (
                  <Form.Check
                    checked={checked}
                    value={value}
                    label={
                      <div>
                        <TimeZoneLabel timezone={userTimezone} />

                        <span className="ml-4 text-gray-600">
                          (<Translate>settings timezone</Translate>)
                        </span>
                      </div>
                    }
                    id="settings-timezone"
                    name={name}
                    onChange={onChange}
                    type="radio"
                  />
                )}
              </FinalForm.Field>
            </Form.Group>

            {values.timezone === browserTimezone && (
              <Form.Group>
                <FinalForm.Field<boolean>
                  name="shouldUpdateUser"
                  type="checkbox"
                >
                  {({ input: { checked, name, onChange } }) => (
                    <Form.Check
                      checked={checked}
                      label={
                        <div className="space-between-4">
                          <Translate>
                            Update my settings to{' '}
                            <Var name="timezone">
                              <TimeZoneLabel timezone={values.timezone} />
                            </Var>{' '}
                            (only do this if you're moving)
                          </Translate>
                        </div>
                      }
                      id="should-update-user"
                      name={name}
                      onChange={onChange}
                      type="checkbox"
                    />
                  )}
                </FinalForm.Field>
              </Form.Group>
            )}
          </Modal.Body>

          <Modal.Footer>
            <LoadingButton
              block
              loading={submitting}
              type="submit"
              variant="primary"
            >
              <Translate>
                View times in{' '}
                <Var name="timezone">
                  <TimeZoneLabel timezone={values.timezone} />
                </Var>
              </Translate>
            </LoadingButton>
          </Modal.Footer>
        </Form>
      )}
    </FinalForm.Form>
  </Modal>
)
export default ChangeMeetingViewingTimezoneModal
