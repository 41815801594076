import * as React from 'react'
import './AttendeeImage.scss'
import Gravatar from './Gravatar'
import { Attendee } from '../types'

interface Props {
  size: number | 'sm' | 'md' | 'lg'
  attendee: Pick<Attendee, 'firstName' | 'lastName' | 'email'>
}

const Sizes = {
  sm: 18,
  md: 32,
  lg: 48,
}

const AttendeeImage: React.FC<Props> = ({ attendee, size }) => (
  <div className="AttendeeImage">
    <Gravatar
      email={attendee.email!}
      size={typeof size === 'number' ? size : Sizes[size]}
    />
  </div>
)

export default AttendeeImage
