import * as React from 'react'

import './ColorPicker.scss'
import checkIcon from '../assets/icn-color-picker-select.svg'

interface Props {
  onSelect: (color: string) => void
  value: string
}

const defaultColors = [
  '#1e9bff',
  '#2980b9',
  '#0ed70a',
  '#009432',
  '#c40404',
  '#ED4C67',
  '#fa8a1a',
  '#851eff',
  '#D980FA',
  '#f1c40f',
  '#8a9199',
]

const ColorPicker: React.FC<Props> = ({ onSelect, value }) => (
  <div className="ColorPicker">
    {defaultColors.map(color => (
      <div
        className={`ColorPicker-item ${
          value === color && 'ColorPicker-item-selected'
        }`}
        key={color}
        onClick={() => onSelect(color)}
        style={{
          backgroundColor: color,
        }}
      >
        <img src={checkIcon} alt="check" />
      </div>
    ))}
  </div>
)

export default ColorPicker
