import * as React from 'react'
import { Card } from 'react-bootstrap'

import './SelectableCard.scss'

export interface Props {
  selected?: boolean
  onClick?: () => void
}

const SelectableCard: React.FC<Props> = ({ onClick, selected, children }) => (
  <Card
    className={`SelectableCard p-16 ${selected ? 'selected' : ''}`}
    role="button"
    onClick={onClick}
  >
    {children}
  </Card>
)

export default SelectableCard
