import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, Media, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'
import slugify from '@sindresorhus/slugify'

import DynamicBookingURLInput from './DynamicBookingURLInput'
import HelpToolTip from './HelpToolTip'
import Illustration from './Illustration'
import ImageButton from './ImageButton'
import ImageInput from './ImageInput'
import LanguagePicker from './LanguagePicker'
import LoadingButton from './LoadingButton'
import ProfileContext from './ProfileContext'
import Translate from './Translate'
import {
  CreateTeamInput as ServerInput,
  Maybe,
  useCreateSchedulingPageModalMutation,
} from '../__generated__/graphql'
import { mutationErrorsToFormErrors, toast, Analytics } from '../utils'
import UserContext from './UserContext'

gql`
  mutation CreateSchedulingPageModal($input: CreateTeamInput!) {
    schedulingPage: createTeam(input: $input) {
      data {
        id
        name
        private
        slug
        teamMembers {
          edges {
            node {
              id
              member {
                id
                user {
                  email
                  firstName
                  id
                  image
                  lastName
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export type Props = {
  name?: Maybe<string>
  onHide: () => void
  onSuccess: (id: string) => void
}

type FormValues = Pick<ServerInput, 'image' | 'language' | 'name' | 'slug'>

const CreateSchedulingPageModal: React.FC<Props> = ({
  name,
  onHide,
  onSuccess,
}) => {
  const profile = React.useContext(ProfileContext)
  const { user } = React.useContext(UserContext)
  const [createSchedulingPage, { loading: createLoading }] =
    useCreateSchedulingPageModalMutation()
  // We memoize the initial values to prevent final-form
  // from resetting the form when submitting due to
  // equality checking. The library is not performing
  // deep equality checking so the array will not be
  // checked.
  const initialValues = React.useMemo<FormValues>(
    () => ({
      language: user.language,
      // In the event the name prop is used render the value.
      name: name ? name : '',
      private: false,
      // In the event the name prop is used render the value.
      // Make sure we slugify the value for the correct representation.
      slug: name ? slugify(name) : '',
    }),
    [name, user.language]
  )

  React.useEffect(() => {
    Analytics.trackEvent('Create Scheduling Page Modal: Opened')
  }, [])

  const onSubmit = async (values: FormValues) => {
    try {
      // In any case we want to run the 'createTeam' mutation since the user is expecting
      // to create a scheduling page from this modal.
      const res = await createSchedulingPage({
        variables: {
          input: {
            profile: profile.id,
            ...values,
          },
        },
      })

      // Handle any errors from the server.
      if (res.data?.schedulingPage?.errors) {
        console.log(
          'createTeam mutation [CreateSchedulingPageModal.tsx]',
          res.data.schedulingPage.errors
        )
        return mutationErrorsToFormErrors(res.data.schedulingPage.errors)
      }

      // Handle success.
      if (res.data?.schedulingPage?.data) {
        toast.success(
          `Created Scheduling Page`,
          res.data.schedulingPage.data.name
        )
        Analytics.trackEvent('Create Scheduling Page Modal: Submitted', {
          uploadedImage: !!values.image,
        })
        onSuccess(res.data.schedulingPage.data.id)
        return
      }
    } catch (err) {
      toast.error('Something went wrong')
      console.error('createTeam mutation [CreateSchedulingPageModal.tsx]', err)
    }
  }

  return (
    <Modal
      className="CreateSchedulingPageModal"
      // We override the default so that Overlays & Popovers
      // are focusable.\
      // https://github.com/react-bootstrap/react-bootstrap/issues/1551#issuecomment-162875520
      enforceFocus={false}
      onHide={async () => {
        Analytics.trackEvent('Create Scheduling Page Modal: Cancelled')
        onHide()
      }}
      show
    >
      <Modal.Header className="tw-pb-0 tw-border-b-0" closeButton />
      <Modal.Body className="tw-pb-12 tw-px-12 tw-pt-0">
        <div className="tw-mb-8 tw-text-center tw-flex-col tw-justify-center">
          <div className="tw-mb-6">
            <Illustration name="dayPlanner" className="tw-h-40" />
          </div>
          <h1 className="tw-mb-1">
            <Translate>Create a scheduling page</Translate>
          </h1>
          <p className="tw-text-bsGray-600">
            <Translate>
              People will use this to schedule a meeting with you or your team.
            </Translate>
          </p>
        </div>
        <FinalForm.Form<FormValues>
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, hasValidationErrors, submitting }) => (
            <Form onSubmit={handleSubmit}>
              <DynamicBookingURLInput
                bookingUrl=""
                nameLabel="Name"
                onEditLink={() =>
                  Analytics.trackEvent(
                    'Create Scheduling Page Modal: Edited Link'
                  )
                }
                slugLabel="Customize your link"
              />

              <FinalForm.Field<FormValues['image']> name="image">
                {({ input: { onChange, value } }) => (
                  <Form.Group>
                    <Form.Label>
                      <Translate>Picture or Logo</Translate>
                    </Form.Label>
                    <Media className="tw-space-x-2">
                      <ImageInput onChange={onChange} value={value!} />
                      <Media.Body className="tw-mr-3 tw-flex tw-flex-col tw-space-y-2">
                        <ImageButton onChange={onChange} value={value!} />
                        <p className="tw-text-bsGray-600">
                          <Translate>
                            JPG or PNG. For best presentation, should be square
                            and at least 128px by 128px.
                          </Translate>
                        </p>
                      </Media.Body>
                    </Media>
                  </Form.Group>
                )}
              </FinalForm.Field>

              <Form.Group>
                <Form.Label className="tw-flex tw-items-center tw-space-x-[5px]">
                  <Translate>Language</Translate>
                  <HelpToolTip>
                    <Translate>
                      Language used for attendees viewing the scheduling page
                      and in emails they receive after scheduling
                    </Translate>
                  </HelpToolTip>
                </Form.Label>

                <FinalForm.Field<FormValues['language']> name="language">
                  {({ input }) => (
                    <LanguagePicker
                      onChange={input.onChange}
                      value={input.value!}
                    />
                  )}
                </FinalForm.Field>
              </Form.Group>

              <LoadingButton
                block
                disabled={hasValidationErrors || submitting}
                loading={createLoading}
                type="submit"
              >
                <Translate>Create Scheduling Page</Translate>
              </LoadingButton>
            </Form>
          )}
        </FinalForm.Form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateSchedulingPageModal
