import * as React from 'react'

import sittingOnEgg from '../assets/illustrations/sitting-on-egg.svg'
import standing from '../assets/illustrations/standing.svg'
import markingCalendar from '../assets/illustrations/marking-calendar.svg'
import lookingInMailbox from '../assets/illustrations/looking-in-mailbox.svg'
import holdingClipboard from '../assets/illustrations/holding-clipboard.svg'
import flyingWithCard from '../assets/illustrations/flying-with-card.svg'
import appointletBranding from '../assets/illustrations/appointlet-branding-preview.svg'
import dayPlanner from '../assets/illustrations/day-planner.svg'
import pluggingInCable from '../assets/illustrations/plugging-in-cable.svg'

// Smaller illustrations used mostly in onboarding
import webConferencing from '../assets/illustrations/web-conferencing.svg'
import paperAndFeather from '../assets/illustrations/paper-and-feather.svg'
import invitation from '../assets/illustrations/invitation.svg'
import celebrating from '../assets/illustrations/celebrating.svg'
import calendar from '../assets/illustrations/calendar.svg'
import calendarChecked from '../assets/illustrations/calendar-checked.svg'
import waving from '../assets/illustrations/waving.svg'
import atComputer from '../assets/illustrations/working-at-computer.svg'

export const illustrations = {
  appointletBranding: {
    src: appointletBranding,
    alt: 'Appointlet Branding',
  },
  sittingOnEgg: {
    src: sittingOnEgg,
    alt: 'Bird sitting on egg',
  },
  standing: {
    src: standing,
    alt: 'Bird standing',
  },
  markingCalendar: {
    src: markingCalendar,
    alt: 'Bird marking calendar',
  },
  lookingInMailbox: {
    src: lookingInMailbox,
    alt: 'Bird looking in an empty mailbox',
  },
  holdingClipboard: {
    src: holdingClipboard,
    alt: 'Bird holding a clipboard',
  },
  flyingWithCard: {
    src: flyingWithCard,
    alt: 'Bird flying and holding a card',
  },
  webConferencing: {
    src: webConferencing,
    alt: 'Bird web conferencing',
  },
  paperAndFeather: {
    src: paperAndFeather,
    alt: 'Sheet of paper and feather pen',
  },
  invitation: {
    src: invitation,
    alt: 'Invitation card coming out of envelope',
  },
  celebrating: {
    src: celebrating,
    alt: 'Bird celebrating',
  },
  calendar: {
    src: calendar,
    alt: 'Desk calendar',
  },
  calendarChecked: {
    src: calendarChecked,
    alt: 'Desk calendar with check mark',
  },
  waving: {
    src: waving,
    alt: 'Bird waving hello',
  },
  atComputer: {
    src: atComputer,
    alt: 'Bird working at computer',
  },
  dayPlanner: {
    src: dayPlanner,
    alt: 'Day planner',
  },
  pluggingInCable: {
    src: pluggingInCable,
    alt: 'Plugging in cable',
  },
}

// Not sure why we need to omit crossOrigin here but Typescript gets angry if we don't.
interface Props extends Omit<React.HTMLProps<HTMLImageElement>, 'crossOrigin'> {
  name: keyof typeof illustrations
}

const Illustration: React.FC<Props> = ({ name, ...rest }) => {
  return (
    <img
      src={illustrations[name].src}
      alt={illustrations[name].alt}
      {...rest}
    />
  )
}

export default Illustration
