import * as React from 'react'
import { expandClassName, toRem } from '../utils'

export interface Props extends React.HTMLProps<HTMLImageElement> {
  width?: number
  height?: number
  roundedCircle?: boolean
}

const Image: React.FC<Props> = ({
  width,
  height,
  roundedCircle,
  className,
  ...rest
}) => (
  // TODO: Figure out this typing nightmare
  // @ts-ignore
  // eslint-disable-next-line jsx-a11y/alt-text
  <img
    style={{
      height: height ? toRem(height) : undefined,
      width: width ? toRem(width) : undefined,
    }}
    className={expandClassName(
      `Image ${roundedCircle ? 'rounded-circle' : ''}`,
      className
    )}
    {...rest}
  />
)

export default Image
