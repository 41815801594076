import * as React from 'react'
import { Dropdown } from 'react-bootstrap'
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem'

import './DropdownItem.scss'

import { expandClassName } from '../utils/expandClassName'

export interface Props extends DropdownItemProps {}

const DropdownItem: React.FC<Props> = ({ children, className, ...rest }) => (
  <Dropdown.Item
    className={expandClassName('tw-flex tw-items-center', className)}
    {...rest}
  >
    {children}
  </Dropdown.Item>
)

export default DropdownItem
