import * as React from 'react'
import { OverlayTrigger, Popover, OverlayTriggerProps } from 'react-bootstrap'

import Icon from './Icon'

interface Props {
  placement?: OverlayTriggerProps['placement']
}

const HelpToolTip: React.FC<Props> = ({ placement, children }) => (
  <OverlayTrigger
    flip
    rootClose
    rootCloseEvent="mousedown"
    trigger="click"
    placement={placement || 'right'}
    overlay={
      <Popover id="HelpToolTip" className="tw-max-w-[300px]">
        <Popover.Content className="tw-flex tw-flex-col">
          {children}
        </Popover.Content>
      </Popover>
    }
    popperConfig={{
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            // This causes the popover to position itself relative to the window
            // instead of the first position:fixed parent.  It allows it to hang
            // off a modal's edge, for example.
            boundariesElement: 'window',
          },
        },
      ],
    }}
  >
    <Icon.HelpCircle
      className="tw-cursor-pointer tw-text-bsGray-500"
      size={18}
    />
  </OverlayTrigger>
)

export default HelpToolTip
