import * as React from 'react'
import { Picker, EmojiData } from 'emoji-mart'

import './EmojiPicker.scss'

// Import the emoji-mart styles
if (process.env.NODE_ENV !== 'test') {
  require('emoji-mart/css/emoji-mart.css')
}

const getURL = (emoji: EmojiData) => {
  // TYPESCRIPT: figure out why this typing doesn't seem to exist...
  // @ts-ignore
  const unified = emoji.unified as string

  //emoji.unified may end with unicode variation selector -fe0f,
  //which does not match the CDN URIs for emojis with URIs less than 10 characters
  const slug: string =
    unified.length <= 10 && unified.slice(-4) === 'fe0f'
      ? unified.slice(0, -5)
      : unified
  return `https://twemoji.maxcdn.com/2/svg/${slug}.svg`
}
interface Props {
  onSelect: (url: string) => void
}

const EmojiPicker: React.FC<Props> = ({ onSelect }) => (
  <div className="EmojiPicker">
    <Picker
      color="#1e9bff"
      set="twitter"
      onSelect={emoji => onSelect(getURL(emoji))}
      showPreview={false}
      title=""
    />
  </div>
)

export default EmojiPicker
