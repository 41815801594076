import * as React from 'react'
import { convertFromRaw, EditorState } from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import { linkifyPlugin, linkPlugin } from '../../../RichTextEditor'

// Helper function to parse the draftjs state blob into something recognizable,
// with a fallback if the blob is invalid in some way.
const parseStringToState = (string: string): EditorState => {
  try {
    return EditorState.createWithContent(convertFromRaw(JSON.parse(string)))
  } catch (error) {
    //if contentState could not be parsed from string, render unrecognized content warning
    return EditorState.createWithContent(
      convertFromRaw(unrecognizedContentWarning)
    )
  }
}

const unrecognizedContentWarning = {
  blocks: [
    {
      key: 'c0qlo',
      text: 'The content of this note could not be recognized.',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
}

export type Props = {
  content: string
}

export const RenderRichText: React.FC<Props> = ({ content }) => {
  // This is weird, but for the plugins to kick in and identify their
  // rich pieces in the editor state, we need to let an onChange cycle
  // occur, so this little piece of state allows for that without having
  // to burden calling components.
  //
  // https://github.com/draft-js-plugins/draft-js-plugins/issues/530
  const [internalEditorState, setInternalEditorState] =
    React.useState<EditorState>(() => parseStringToState(content))

  return (
    <div>
      {content.length && (
        <Editor
          editorState={internalEditorState}
          readOnly={true}
          plugins={[linkPlugin, linkifyPlugin]}
          onChange={setInternalEditorState}
        />
      )}
    </div>
  )
}
