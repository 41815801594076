import * as React from 'react'
import Icon from './Icon'
import { PickerOption } from './Picker'
import Picker from './Picker'
import ProfileContext from './ProfileContext'
import UserImage from './UserImage'
import { generateUserLabel } from './UserLabel'
import {
  MemberNode,
  useMembersPickerQuery,
  UserNode,
  Maybe,
} from '../__generated__/graphql'
import { ID } from '../types'

type Props = {
  isInvalid?: boolean
  filterOptions?: (member: PickerMemberNode) => boolean
  onChange: (member: ID) => void
  value: Maybe<ID>
}

type PickerMemberNode = Pick<MemberNode, 'id'> & {
  user: Pick<UserNode, 'email' | 'firstName' | 'id' | 'image' | 'lastName'>
}

const pickerMemberNodeToPickerOption = (
  member: PickerMemberNode
): PickerOption<PickerMemberNode> => ({
  image: <UserImage user={member.user} size={20} />,
  label: generateUserLabel(member.user),
  value: member,
})

const MemberPicker: React.FC<Props> = ({
  isInvalid,
  onChange,
  value,
  filterOptions,
}) => {
  const profile = React.useContext(ProfileContext)

  const { data, loading } = useMembersPickerQuery({
    variables: { id: profile.id },
    fetchPolicy: 'network-only', //first fetch
    nextFetchPolicy: 'network-only', // all subsequent fetches
  })

  // Compute the PickerOptions from the members
  const options: PickerOption<PickerMemberNode>[] = React.useMemo(
    () =>
      data?.profile.members.edges.map(edge =>
        pickerMemberNodeToPickerOption(edge!.node!)
      ) ?? [],
    [data]
    // This filter has to happen outside of the memo
  ).filter(option => (filterOptions ? filterOptions(option.value) : true))

  // Get a PickerOption for the value if one is passed in.
  const pickerValue: Maybe<PickerOption<PickerMemberNode>> = React.useMemo(
    () =>
      (value && options && options.find(option => option.value.id === value)) ||
      null,
    [value, options]
  )

  return (
    <Picker<PickerMemberNode>
      value={pickerValue}
      onChange={option => onChange(option!.value.id)}
      options={options}
      invalid={isInvalid}
      loading={loading}
      placeholder={
        <>
          <Icon.Users className="tw-mr-2" size={20} />
          Choose members
        </>
      }
    />
  )
}

export default MemberPicker
