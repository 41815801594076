import * as React from 'react'
import TooltipOverlay from './TooltipOverlay'
import Disabler from './Disabler'
import './ReschedulableTimesButton.scss'
import SelectableButton from './SelectableButton'
import TimeLabel from './TimeLabel'
import { Meeting } from '../types'
import { DateTime } from '../utils'

interface Props {
  meeting: Pick<Meeting, 'start'>
  onClick: () => void
  time: DateTime
}

const ReschedulableTimesButton: React.FC<Props> = ({
  meeting,
  onClick,
  time,
}) => (
  <div className="ReschedulableTimesButton">
    <TooltipOverlay
      id="ReschedulableTimesButton--tooltip"
      text="Current Meeting Time"
      disabled={!time.isSame(meeting.start)}
    >
      {/* We must wrap the button in this <div/> to trigger to tooltip */}
      <div>
        <Disabler disabled={time.isSame(meeting.start)}>
          <SelectableButton selectionVariant={null} onClick={onClick}>
            <TimeLabel format="localized-time" time={time} />
          </SelectableButton>
        </Disabler>
      </div>
    </TooltipOverlay>
  </div>
)

export default ReschedulableTimesButton
