import * as React from 'react'
import { Media, Button } from 'react-bootstrap'
import Icon from './Icon'
import { toast, Analytics } from '../utils'
import {
  startStripeFlow as startStripeCheckoutFlow,
  StripeErrorType,
} from '../utils/stripe'
import { BillingData } from './SettingsModalBillingSection'
import { useStripeCheckoutSession } from '../hooks'
import Translate from './Translate'
import Var from './Var'

interface Props {
  billingData: BillingData
  onPaymentMethodUpdated: () => void
}

const BillingPaymentMethodSection: React.FC<Props> = ({
  billingData,
  onPaymentMethodUpdated,
}) => {
  const { stripeSessionId } = useStripeCheckoutSession(billingData.profile)

  // Creates a Stripe Checkout session and then opens a window to the Stripe Checkout flow.
  const startPaymentMethodFlow = async () => {
    try {
      // Start the Stripe Checkout flow
      await startStripeCheckoutFlow(stripeSessionId!)

      // Refetch the payment method data so we have something to display to the user.
      onPaymentMethodUpdated()

      // Let the user know it worked.
      toast.success('Payment method added')

      // Fire analytics
      Analytics.trackEvent('Billing: Added Payment Card')
    } catch (error) {
      if (error && error !== StripeErrorType.UserCancelled) {
        throw new Error(`Failed to redirect to Stripe Checkout: ${error}`)
      }
    }
  }

  return (
    <Media className="tw-flex-col tw-space-y-4 md:tw-flex-row md:tw-items-center md:tw-space-y-0 md:tw-space-x-4">
      <Icon.InternalAsset assetName="Icon-Credit-Card" size={50} />

      <Media.Body className="tw-flex-col">
        <strong>
          <Translate>Payment Method</Translate>
        </strong>
        <p className="tw-text-bsGray-600">
          {billingData.paymentMethod ? (
            <Translate>
              <Var name="brand">
                {billingData.paymentMethod.brand[0].toUpperCase() +
                  billingData.paymentMethod.brand.slice(1)}
              </Var>{' '}
              ending in,{' '}
              <Var name="last4">{billingData.paymentMethod.last4}</Var> expires
              on,{' '}
              <Var name="expiration">
                {billingData.paymentMethod.expiration}
              </Var>
            </Translate>
          ) : (
            <Translate>You don’t have an attached payment method.</Translate>
          )}
        </p>
      </Media.Body>

      <Button
        variant="link"
        onClick={() => {
          Analytics.trackEvent('Payment Card Modal: Opened', {
            // Coerce the PaymentSourceNode to a boolean.
            'Has existing method': !!billingData.paymentMethod,
            source: 'billing-settings-screen',
          })

          startPaymentMethodFlow()
        }}
      >
        {billingData.paymentMethod ? (
          <Translate>Update Payment Method</Translate>
        ) : (
          <Translate>Add Payment Method</Translate>
        )}
      </Button>
    </Media>
  )
}

export default BillingPaymentMethodSection
