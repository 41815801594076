import * as React from 'react'
import Icon from './Icon'

type Props = {
  stars?: number
  size?: number
  onChange: (value: number) => void
  value: number
}

const StarRating: React.FC<Props> = ({ onChange, value, stars, size }) => (
  <div className="tw-cursor-pointer">
    {Array.from(Array(stars || 3).keys()).map((_, i) => (
      <Icon.Star
        key={i}
        size={size || 20}
        // If they click the same star, set to 0, else set to the star amount.
        onClick={() => onChange(value === i + 1 ? 0 : i + 1)}
        className={value >= i + 1 ? 'tw-text-bsPink' : 'tw-text-bsGray-400'}
      />
    ))}
  </div>
)

export default StarRating
