import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Maybe, Timezone, __EnumValue } from '../../../../__generated__/graphql'
import LoadingButton from '../../../LoadingButton'
import { PureTimeZonePicker } from '../../../molecules/PureTimeZonePicker/PureTimeZonePicker'
import { OnboardTitle } from '../../molecules/OnboardTitle/OnboardTitle'
import { SlugInput } from '../../molecules/SlugInput/SlugInput'
import { TimeZoneBanner } from '../../../molecules/TimeZoneBanner/TimeZoneBanner'
import { isFormFieldInvalid } from '../../../../utils'
import './CreateSchedulingPageView.scss'

export interface CreateSchedulingPageViewProps {
  readonly onSubmit: () => void
  readonly isLoading?: boolean
  readonly timezoneOptionEnums: ReadonlyArray<
    Pick<__EnumValue, 'name' | 'description'>
  >
  readonly isLoadingTimezones?: boolean
}

export const CreateSchedulingPageView: React.FC<
  CreateSchedulingPageViewProps
> = ({
  isLoading,
  onSubmit,
  timezoneOptionEnums,
  isLoadingTimezones,
}: CreateSchedulingPageViewProps) => {
  const [isEditing, setIsEditing] = React.useState<boolean>(false)

  return (
    <div className="CreateSchedulingPageView d-flex flex-column">
      <div className="mb-24">
        <OnboardTitle title="Welcome to Appointlet!" />
      </div>
      <FinalForm.Field<string> name="slug">
        {({ input: { onChange, value }, meta }) => (
          <SlugInput onChange={onChange} slug={value} error={meta} />
        )}
      </FinalForm.Field>
      <div className="mt-24">
        <FinalForm.Field<Maybe<Timezone>> name="timezone">
          {({ input: { onChange, value }, meta }) => {
            const bannerLabel = timezoneOptionEnums.find(
              timeZone => timeZone.name === value
            )
            return (
              <>
                {!isEditing && (
                  <TimeZoneBanner
                    currentTimeZone={value as string}
                    displayLabel={(bannerLabel?.description as string) || ''}
                    onChange={() => setIsEditing(true)}
                  />
                )}
                {isEditing && (
                  <>
                    <div
                      className={`${
                        isFormFieldInvalid(meta)
                          ? 'text-danger'
                          : 'text-gray-900'
                      } font-weight-bold mb-8`}
                    >
                      Your Timezone
                    </div>
                    <div className="PickerContainer">
                      <PureTimeZonePicker
                        onChange={onChange}
                        value={value!}
                        isInvalid={isFormFieldInvalid(meta)}
                        timezoneOptionEnums={timezoneOptionEnums}
                        isLoading={isLoadingTimezones}
                        required
                      />
                    </div>
                  </>
                )}
              </>
            )
          }}
        </FinalForm.Field>
      </div>
      <div className="w-100 mt-24 d-flex justify-content-end">
        <LoadingButton
          loading={isLoading}
          className="bg-success border-0 SaveButton"
          onClick={onSubmit}
        >
          Save & Continue →
        </LoadingButton>
      </div>
    </div>
  )
}
