import * as React from 'react'

import './PanelIcon.scss'
import { expandClassName } from '../utils'

interface Props extends React.HTMLProps<HTMLDivElement> {}

const PanelIcon: React.FC<Props> = ({ children, className, ...rest }) => (
  <div
    className={expandClassName(
      'tw-flex-[0_0_16px] tw-mr-4 PanelIcon',
      className
    )}
    {...rest}
  >
    {children}
  </div>
)

export default PanelIcon
