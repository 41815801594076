import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, Media, Modal } from 'react-bootstrap'
import { FieldArray } from 'react-final-form-arrays'
import Badge from './Badge'
import './UpdateMeetingTypeModalNotificationsSection.scss'
import DurationInput from './DurationInput'
import ExternalLink from './ExternalLink'
import Icon from './Icon'
import ProfileContext from './ProfileContext'
import RemoveButton from './RemoveButton'
import RichTextEditor from './RichTextEditor'
import Switch from './Switch'
import UnsupportedPlan from './UnsupportedPlan'
import { IMeetingType, IReminder } from './UpdateMeetingTypeContext'
import { Duration } from '../utils'
import Translate from './Translate'
import MeetingTypeEditorSectionPicker from './MeetingTypeEditorSectionPicker'
import { MTESection } from './UpdateMeetingTypeModal'

type Props = {
  onSelect: (section: MTESection) => void
}

const UpdateMeetingTypeModalNotificationsSection: React.FC<Props> = ({
  onSelect,
}) => {
  const profile = React.useContext(ProfileContext)
  return (
    <div className="UpdateMeetingTypeModalNotificationsSection tw-w-full">
      <Modal.Header closeButton>
        <Icon.InternalAsset
          assetName="icn-mte-header-reminder"
          className="tw-hidden lg:tw-block"
        />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title>
            <Translate>Notifications & Reminders</Translate>
          </Modal.Title>
          <Translate as="p">
            Emails sent out after a meeting is booked.
          </Translate>
        </div>
        <MeetingTypeEditorSectionPicker
          onChange={onSelect}
          value="notifications"
        />
      </Modal.Header>
      <Modal.Body>
        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
          <Icon.InternalAsset
            assetName="Icon-Calendar-Add"
            className="tw-mr-4"
            size={50}
          />

          <Media.Body className="tw-flex tw-items-center tw-justify-between">
            <Form.Group>
              <Form.Label>
                <Translate>Calendar Invitations</Translate>
              </Form.Label>
              <Form.Text className="tw-flex tw-flex-col md:tw-block">
                <Translate>
                  Use the default Google & Office 365 calendar invitation emails
                  instead of Appointlet-branded. Requires a connected calendar
                  integration.
                </Translate>{' '}
                <ExternalLink href="http://kb.appointlet.help/en/articles/4694077">
                  <Translate>Learn more</Translate>
                </ExternalLink>
              </Form.Text>
            </Form.Group>
          </Media.Body>

          <div className="md:tw-self-center md:tw-flex-[0_0_50px]">
            <FinalForm.Field<
              IMeetingType['useCalendarInvitations']
            > name="useCalendarInvitations">
              {({ input: { onChange, value: useCalendarInvitations } }) => (
                <Switch
                  // Default is false.
                  checked={useCalendarInvitations}
                  onChange={() => onChange(!useCalendarInvitations)}
                />
              )}
            </FinalForm.Field>
          </div>
        </Media>

        <hr />

        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
          <Icon.InternalAsset
            assetName="Icon-Customize-Blue"
            className="tw-mr-4"
            size={50}
          />

          <Media.Body>
            <Form.Group>
              <Form.Label>
                <Translate>Meeting Instructions</Translate>
              </Form.Label>

              <FinalForm.Field<
                IMeetingType['useCalendarInvitations']
              > name="useCalendarInvitations">
                {({ input: { value: useCalendarInvitations } }) =>
                  useCalendarInvitations ? (
                    <Form.Text>
                      <Translate>
                        Will be included in the calendar event description and
                        reminder emails that attendees receive after they book a
                        meeting.
                      </Translate>
                    </Form.Text>
                  ) : (
                    <Form.Text>
                      <Translate>
                        Will be included in the confirmation and reminder emails
                        that attendees receive after they book a meeting.
                      </Translate>
                    </Form.Text>
                  )
                }
              </FinalForm.Field>

              <div className="tw-mt-2">
                <FinalForm.Field<
                  IMeetingType['instructions']
                > name="instructions">
                  {({ input: { onChange, value: instructions } }) => (
                    <RichTextEditor
                      onChange={onChange}
                      value={instructions}
                      variables={[]}
                    />
                  )}
                </FinalForm.Field>
              </div>
            </Form.Group>
          </Media.Body>
        </Media>

        <hr />

        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
          <Icon.InternalAsset
            assetName="Icon-Reminder-Blue"
            className="tw-mr-4"
            size={50}
          />

          <Media.Body>
            <Form.Group className="tw-flex-col tw-space-y-2">
              <div>
                <Form.Label>
                  <Translate>Reminder Emails</Translate>{' '}
                  <Badge className="tw-ml-2" variant="success">
                    <Translate>Premium</Translate>
                  </Badge>
                </Form.Label>
                <Form.Text>
                  <Translate>
                    We'll send reminder to your attendees so they have details
                    on when and where to show up.
                  </Translate>
                </Form.Text>
                {!profile.plan.allowReminders && (
                  <div className="tw-mt-2">
                    <UnsupportedPlan source="Reminders" />
                  </div>
                )}
              </div>

              <ul className="tw-mb-0 tw-flex tw-flex-col tw-list-none tw-pl-0 tw-space-y-2">
                <FieldArray<IReminder> name="reminders">
                  {({ fields }) =>
                    fields.map((name, i) => (
                      <FinalForm.Field<IReminder['leadTime']>
                        key={i}
                        name={`${name}.leadTime`}
                      >
                        {({ input }) => (
                          <li className="d-flex align-items-center space-between-8">
                            <DurationInput
                              includeDays
                              onChange={input.onChange}
                              value={input.value}
                            />
                            <Translate>before meeting</Translate>
                            <RemoveButton onClick={() => fields.remove(i)} />
                          </li>
                        )}
                      </FinalForm.Field>
                    ))
                  }
                </FieldArray>
              </ul>

              <FinalForm.Field<IMeetingType['reminders']> name="reminders">
                {({ input: { onChange, value: reminders } }) => (
                  <Button
                    onClick={() =>
                      onChange([
                        ...reminders,
                        {
                          leadTime: new Duration({ minutes: 30 }),

                          // In the API we support each reminder having it's own
                          // email templates, but for now on the UI we're just going
                          // to support setting the template for all of them at once.
                          //
                          // This code here grabs the existing emailBody from the
                          // first reminder, if it exists.
                          emailBody:
                            reminders.length > 0
                              ? reminders[0].emailBody
                              : null,
                        },
                      ])
                    }
                    variant="link"
                  >
                    + <Translate>Add reminder</Translate>
                  </Button>
                )}
              </FinalForm.Field>
            </Form.Group>
          </Media.Body>
        </Media>
      </Modal.Body>
    </div>
  )
}

export default UpdateMeetingTypeModalNotificationsSection
