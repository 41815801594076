import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, Media, Modal } from 'react-bootstrap'

import FormErrorSubscription from './FormErrorSubscription'
import Icon from './Icon'
import ProfileContext from './ProfileContext'
import UpdateMeetingTypeContext, {
  IMeetingType,
} from './UpdateMeetingTypeContext'
import Switch from './Switch'
import UnsupportedPlan from './UnsupportedPlan'
import { isSecureURL } from '../utils'
import FeatureFlag from './FeatureFlag'
import ExternalLink from './ExternalLink'
import Badge from './Badge'
import Translate from './Translate'
import MeetingTypeEditorSectionPicker from './MeetingTypeEditorSectionPicker'
import { MTESection } from './UpdateMeetingTypeModal'

type Props = {
  onSelect: (section: MTESection) => void
}

const UpdateMeetingTypeModalConfirmationSection: React.FC<Props> = ({
  onSelect,
}) => {
  const meetingType = React.useContext(UpdateMeetingTypeContext)
  const profile = React.useContext(ProfileContext)
  return (
    <div className="tw-w-full">
      <Modal.Header closeButton>
        <Icon.InternalAsset
          assetName="ConfirmationHeader"
          className="tw-hidden lg:tw-block"
        />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title>
            <Translate>Confirmation</Translate>
          </Modal.Title>
          <Translate as="p">
            Customize the messaging you send to attendees
          </Translate>
        </div>
        <MeetingTypeEditorSectionPicker
          onChange={onSelect}
          value="confirmation"
        />
      </Modal.Header>
      <Modal.Body>
        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
          <Icon.InternalAsset
            assetName="Icon-Requests-Blue"
            className="md:tw-mr-4"
            size={50}
          />
          <FinalForm.Field<IMeetingType['autoConfirm']> name="autoConfirm">
            {({ input: { onChange, value: isConfirmed } }) => (
              <Media.Body>
                <Form.Group className="tw-flex tw-flex-col tw-mb-0 md:tw-flex-row md:tw-items-center">
                  <div className="tw-flex tw-flex-col md:tw-flex-grow-0 md:tw-flex-shrink">
                    <Form.Label>
                      <Translate>
                        Manually approve new{' '}
                        {meetingType.isGroup ? 'attendees' : 'meetings'}{' '}
                      </Translate>
                      <Badge className="tw-ml-2" variant="success">
                        <Translate>Premium</Translate>
                      </Badge>
                    </Form.Label>
                    <Form.Text>
                      <Translate>
                        Give yourself an opportunity to approve/reject{' '}
                        {meetingType.isGroup ? 'attendees ' : 'meetings '}
                        before they are confirmed.
                      </Translate>{' '}
                      <ExternalLink href="http://kb.appointlet.help/en/articles/4220732">
                        <Translate>Learn more</Translate>
                      </ExternalLink>
                    </Form.Text>
                  </div>

                  {/* If autoConfirm === false, then manual confirmation is
                  enabled. // If autoConfirm === true, then manual confirmation
                  is disabled. */}
                  <Switch
                    checked={!isConfirmed && profile.plan.allowManualConfirm}
                    disabled={!profile.plan.allowManualConfirm}
                    className="tw-mt-2 md:tw-ml-2 md:tw-mt-0 md:tw-flex-grow tw-flex-shrink-0"
                    onChange={() => onChange(!isConfirmed)}
                  />
                </Form.Group>
                {!profile.plan.allowManualConfirm && (
                  <div className="tw-mt-2">
                    <UnsupportedPlan source="Manual Confirmation" />
                  </div>
                )}
              </Media.Body>
            )}
          </FinalForm.Field>
        </Media>

        <hr />

        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
          <Icon.InternalAsset
            assetName="Icon-Redirect"
            className="md:tw-mr-4"
            size={50}
          />
          <Media.Body>
            <Form.Group className="tw-flex tw-flex-col tw-mb-0">
              <Form.Label>
                <Translate>Redirect after booking</Translate>
                <Badge className="tw-ml-2" variant="success">
                  <Translate>Premium</Translate>
                </Badge>
              </Form.Label>
              <Form.Text>
                <Translate>
                  Send people to your website after they are done booking. We'll
                  also send along data related to the booking.
                </Translate>
              </Form.Text>

              <FinalForm.Field<IMeetingType['redirectUrl']>
                name="redirectUrl"
                validate={isSecureURL}
              >
                {({
                  input: { onChange, value: redirectUrl, ...rest },
                  meta,
                }) => (
                  <React.Fragment>
                    {!profile.plan.allowRedirect && (
                      <div className="tw-mt-2">
                        <UnsupportedPlan source="Redirect Url" />
                      </div>
                    )}
                    <Form.Control
                      className="tw-mt-2"
                      isInvalid={meta.invalid}
                      onChange={onChange}
                      placeholder="https://"
                      // Again value could be 'null' so check!
                      value={redirectUrl ? redirectUrl : ''}
                      {...rest}
                    />
                  </React.Fragment>
                )}
              </FinalForm.Field>

              <FormErrorSubscription name="redirectUrl" />

              <FeatureFlag flag="redirect-data">
                <FinalForm.Field<
                  IMeetingType['includeDataInRedirectUrl']
                > name="includeDataInRedirectUrl">
                  {({ input, meta, ...rest }) => (
                    <div className="tw-flex tw-items-center tw-my-2 md:tw-mb-0 md:tw-space-x-2">
                      <Form.Check
                        className="tw-flex-shrink tw-flex-grow-0"
                        id="includeDataInRedirectUrl"
                        inline
                        onChange={input.onChange}
                        checked={input.value}
                        label="Include meeting data in the URL query string"
                        {...rest}
                      />

                      <ExternalLink
                        className="tw-flex-grow tw-shrink-0"
                        href="http://kb.appointlet.help/en/articles/4220530"
                      >
                        <Translate>Learn More</Translate>
                      </ExternalLink>
                    </div>
                  )}
                </FinalForm.Field>
              </FeatureFlag>
            </Form.Group>
          </Media.Body>
        </Media>
      </Modal.Body>
    </div>
  )
}

export default UpdateMeetingTypeModalConfirmationSection
