import * as React from 'react'
import { DateTime } from '../../../utils'
import { Maybe, Timezone, __EnumValue } from '../../../__generated__/graphql'
import Icon from '../../Icon'
import Picker, { PickerOption, PickerOptionGroup } from '../../Picker'
import TimeLabel from '../../TimeLabel'
import './PureTimeZonePicker.scss'

export interface PureTimeZonePickerProps {
  readonly onChange: (timezone: Timezone | null) => void
  readonly value: Timezone | null
  readonly timezoneOptionEnums: ReadonlyArray<
    Pick<__EnumValue, 'name' | 'description'>
  >
  readonly isLoading?: boolean
  readonly timezoneError?: string
  readonly isInvalid?: boolean
  readonly required?: boolean
  readonly disabled?: boolean
}

export const PureTimeZonePicker: React.FC<PureTimeZonePickerProps> = ({
  onChange,
  value,
  timezoneOptionEnums,
  isLoading,
  timezoneError,
  isInvalid,
  disabled,
  required,
}: PureTimeZonePickerProps) => {
  const [selected, setSelected] = React.useState<Maybe<Timezone>>(value || null)

  // format raw enums
  const rawOptionGroups: Maybe<PickerOptionGroup<Timezone>[]> | undefined =
    React.useMemo(
      () =>
        timezoneOptionEnums?.reduce((regions, zone) => {
          // The region is the first part of the label like "America/" or "Asia/"
          const region = zone.description!.split('/')[0]

          // Create an option
          const option: PickerOption<Timezone> = {
            label: zone.description!.replace(/[#_]/g, ' '),
            value: zone.name as Timezone,
          }

          // Check to see if we already have an option group for the current region
          if (!regions.length || regions[regions.length - 1].label !== region) {
            regions.push({
              options: [option],
              label: region,
            })
          } else {
            regions[regions.length - 1].options.push(option)
          }

          return regions
        }, [] as Array<PickerOptionGroup<Timezone>>),
      [timezoneOptionEnums]
    )

  const datetime = new DateTime()
  const groupsToUse = rawOptionGroups?.map(group => {
    return {
      ...group,
      options: group.options.map(option => {
        return {
          ...option,
          rightImage: (
            <span className="justify-self-end">
              <TimeLabel
                format="localized-time"
                time={datetime}
                timezone={option.value}
              />
            </span>
          ),
        }
      }),
    }
  })

  const pickerValue = React.useMemo(
    () =>
      selected &&
      groupsToUse &&
      groupsToUse.reduce(
        (acc, group) =>
          acc || group.options.find(opt => opt.value === selected) || null,
        null as Maybe<PickerOption<Timezone>>
      ),
    [selected, groupsToUse]
  )

  return (
    <div className="PureTimeZonePicker">
      <div className="PickerGroup">
        <Picker<Timezone>
          onChange={option => {
            onChange(option ? option.value : null)
            setSelected(option ? option.value : null)
          }}
          options={groupsToUse || []}
          value={
            ({
              ...pickerValue,
              image: (
                <span className="text-primary">
                  <Icon.Globe size="20" />
                </span>
              ),
            } as Maybe<PickerOption<Timezone>>) || null
          }
          required={required}
          disabled={disabled}
          loading={isLoading}
          invalid={isInvalid}
        />
      </div>
      {isInvalid && <div className="text-danger">{timezoneError}</div>}
    </div>
  )
}
