import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { gql } from '@apollo/client'

import MeetingTypeCard from './MeetingTypeCard'
import { InternalData as MeetingTypesPageData } from './MeetingTypesPage'
import { useMoveMeetingTypeMutation } from '../__generated__/graphql'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { SortableContext, useSortable, arrayMove } from '@dnd-kit/sortable'
import { ID } from '../types'

gql`
  mutation MoveMeetingType($input: MoveMeetingTypeInput!) {
    moveMeetingType(input: $input) {
      data {
        id
        order
      }
    }
  }
`

const SortableElement = ({
  meetingType,
  onClone,
  onDelete,
  onUpdate,
  sortingDisabled,
}: {
  meetingType: MeetingTypesPageData['team']['meetingTypes'][0]
  onClone?: () => void
  onDelete?: () => void
  onUpdate?: () => void
  sortingDisabled?: boolean
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } =
    useSortable({ id: meetingType.id })

  // We wrap the MeetingCard in a memo here because dnd-kit
  // calls re-render a lot and performance is really bad if
  // we don't.
  const memoizedMeetingCard = React.useMemo<JSX.Element>(
    () => (
      <MeetingTypeCard
        name={meetingType.name}
        description={meetingType.description}
        bookingUrl={meetingType.bookingUrl}
        color={meetingType.color}
        duration={meetingType.duration}
        isGroup={meetingType.isGroup}
        maxAttendees={meetingType.maxAttendees}
        price={
          meetingType.price > 0 && meetingType.priceCurrency
            ? {
                amount: meetingType.price,
                currency: meetingType.priceCurrency,
              }
            : null
        }
        members={meetingType.meetingTypeTeamMembers
          .filter(mttm => mttm.active)
          .map(mttm => ({
            firstName: mttm.teamMember.member.user.firstName,
            lastName: mttm.teamMember.member.user.lastName,
            image: mttm.teamMember.member.user.image,
            isSelf: true,
          }))}
        onClone={onClone}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    ),
    [meetingType, onClone, onDelete, onUpdate]
  )

  return (
    <Col
      ref={setNodeRef}
      style={{
        transition,

        // We use CSS.Translate instead of CSS.Transform because
        // transform adds scale properties that produce some strange
        // results when meeting type cards are different heights.
        transform: CSS.Translate.toString(transform),

        // Make sure that the grabbed card hovers above the others.
        zIndex: active?.id === meetingType.id ? 10 : undefined,
      }}
      {...attributes}
      // If sorting is disabled, we just don't pass in the listeners
      // which will effectively prevent sorting.
      {...(sortingDisabled ? {} : listeners)}
      lg={4}
      md={6}
      className="mb-24"
    >
      {memoizedMeetingCard}
    </Col>
  )
}

type Props = {
  meetingTypes: MeetingTypesPageData['team']['meetingTypes']
  onClone: (meetingTypeId: ID) => void
  onDelete: (meetingTypeId: ID) => void
  onUpdate: (meetingTypeId: ID) => void
  sortingDisabled?: boolean
}

const SortableMeetingTypeList: React.FC<Props> = ({
  meetingTypes,
  onClone,
  onDelete,
  onUpdate,
  sortingDisabled,
}) => {
  const [moveMeetingType] = useMoveMeetingTypeMutation()

  return (
    <DndContext
      sensors={useSensors(
        // Don't consider the drag activated unless it's been moved at least
        // 10px.  We do this so that dnd-kit doesn't preventDefault on click
        // events that bubble up from the card below.
        useSensor(PointerSensor, {
          activationConstraint: {
            distance: 10,
          },
        })
      )}
      onDragEnd={ev => {
        // If there was no valid drop or it's the same item, stop here.
        if (!ev.over || ev.active.id === ev.over.id) return

        // Calculate the index of the meeting types in the original list
        const oldIndex = meetingTypes.findIndex(mt => mt.id === ev.active.id)
        const newIndex = meetingTypes.findIndex(mt => mt.id === ev.over!.id)

        moveMeetingType({
          optimisticResponse: {
            moveMeetingType: {
              __typename: 'MoveMeetingTypePayload',
              // Here we reorder the array, placing the moved MT in
              // correct index. We then hand the cache the updated list
              // to render while the request is being sent to the server.
              data: arrayMove(meetingTypes, oldIndex, newIndex).map(
                ({ id }, i) => ({
                  __typename: 'MeetingTypeNode',
                  id,
                  // The server 1-indexes and the client 0-indexes, so add
                  // one here to keep them in sync.
                  order: i + 1,
                })
              ),
            },
          },
          variables: {
            input: {
              id: meetingTypes[oldIndex].id,

              // The server 1-indexes and the client 0-indexes, so add
              // one here to keep them in sync.
              index: newIndex + 1,
            },
          },
        })
      }}
    >
      <SortableContext items={meetingTypes}>
        <Row>
          {meetingTypes.map((meetingType, i) => (
            <SortableElement
              key={i}
              meetingType={meetingType}
              onClone={() => onClone(meetingType.id)}
              onDelete={() => onDelete(meetingType.id)}
              onUpdate={() => onUpdate(meetingType.id)}
              sortingDisabled={sortingDisabled}
            />
          ))}
        </Row>
      </SortableContext>
    </DndContext>
  )
}

export default SortableMeetingTypeList
