import * as React from 'react'
import { Media } from 'react-bootstrap'
import { Timezone } from '../../../__generated__/graphql'
import Icon from '../../Icon'
import MeetingViewingTimezoneContext from '../../MeetingViewingTimezoneContext'
import TimeLabel from '../../TimeLabel'
import TimeZoneLabel from '../../TimeZoneLabel'
import UserContext from '../../UserContext'
import { DateTime } from '../../../utils'

export type MeetingTimeUnitProps = {
  start: DateTime
  end: DateTime
  timezone: Timezone | null
  showTimezone?: boolean
}

const MeetingTimeUnit: React.FC<MeetingTimeUnitProps> = ({
  start,
  end,
  // TODO: at some point we should use this to communicate
  // to the user that the meeting is taking place in a specific zone.
  timezone,
}) => {
  const meetingViewingTimezone = React.useContext(MeetingViewingTimezoneContext)
  const { user } = React.useContext(UserContext)

  return (
    <Media className="align-items-center space-between-8">
      <Icon.Calendar size={20} strokeWidth={1} className="text-gray-500" />
      <Media.Body>
        <TimeLabel format="localized-time" time={start} /> -{' '}
        <TimeLabel format="localized-time" time={end} />,{' '}
        <TimeLabel format="localized-full-date" time={start} />{' '}
        <span className="text-gray-600">
          <TimeZoneLabel timezone={meetingViewingTimezone || user.timezone!} />
        </span>
      </Media.Body>
    </Media>
  )
}

export default MeetingTimeUnit
