import * as React from 'react'
import Creatable from 'react-select/creatable'
import { gql } from '@apollo/client'
import { GroupTypeBase } from 'react-select/src/types'
import { Button } from 'react-bootstrap'
import { navigate } from '@reach/router'

import './SchedulingPagePicker.scss'
import CreateSchedulingPageModal from './CreateSchedulingPageModal'
import Icon from './Icon'
import {
  PickerOption,
  Control,
  Input,
  Menu,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
} from './Picker'
import MemberContext from './MemberContext'
import SchedulingPageImage from './SchedulingPageImage'
import {
  SchedulingPagePickerQuery as Response,
  Maybe,
  TeamNode,
  useSchedulingPagePickerQuery,
} from '../__generated__/graphql'
import { isNotSoftDeleted } from '../utils'

gql`
  query SchedulingPagePicker($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            deleted
            id
            image
            name
          }
        }
      }
    }
  }
`

type PickerSchedulingPage = Pick<TeamNode, 'deleted' | 'id' | 'image' | 'name'>

type InternalData = {
  schedulingPages: Array<PickerSchedulingPage>
}

type Props = {
  onChange: (id: string) => void
  value: string
}

const pickerSchedulingPageNodeToPickerOptionType = (
  schedulingPage: PickerSchedulingPage
): PickerOption<PickerSchedulingPage> => ({
  label: schedulingPage.name,
  value: schedulingPage,
  image: (
    <SchedulingPageImage schedulingPage={schedulingPage} rounded size="sm" />
  ),
})

const wireDataToInternalData = (
  wireData: Response['member']
): InternalData => ({
  schedulingPages: wireData.teams.edges
    ? wireData.teams.edges.map(edge => ({
        ...edge?.node!,
      }))
    : [],
})

const SchedulingPagePicker: React.FC<Props> = ({ onChange, value }) => {
  const member = React.useContext(MemberContext)

  const { data, loading, refetch } = useSchedulingPagePickerQuery({
    // This prevents an issue where the query would not run when
    // the user creates their first scheduling page.
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { id: member.id },
  })

  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data.member) : null),
    [data]
  )

  const [createSchedulingPageModal, setCreateSchedulingPageModal] =
    React.useState<boolean>(false)
  const [newSchedulingPage, setNewSchedulingPage] =
    React.useState<Maybe<string>>(null)

  const schedulingPage: GroupTypeBase<PickerOption<PickerSchedulingPage>> = {
    // We still want to set this React.ReactNode up here it will exist
    // as 'children' in the customizable component 'GroupHeading' below
    // in the Creatable.
    label: (
      <React.Fragment>
        <h6 className="mb-0">Scheduling Pages</h6>
        <Button
          onClick={() => setCreateSchedulingPageModal(true)}
          variant="link"
        >
          Create
        </Button>
      </React.Fragment>
    ),
    options:
      !loading && internalData
        ? internalData.schedulingPages
            .filter(isNotSoftDeleted)
            .map(schedulingPage =>
              pickerSchedulingPageNodeToPickerOptionType(schedulingPage)
            )
        : [],
  }

  const schedulingPageValue: PickerSchedulingPage | undefined =
    !loading && internalData
      ? internalData.schedulingPages.find(b => b.id === value)
      : undefined

  return (
    <React.Fragment>
      <Creatable<PickerOption<PickerSchedulingPage>>
        blurInputOnSelect
        className="SchedulingPagePicker"
        formatCreateLabel={val => (
          <div className="d-flex align-items-center space-between-8">
            <Icon.PlusCircle className="text-primary" size={18} />
            <strong>Create: {val}</strong>
          </div>
        )}
        components={{
          IndicatorSeparator: null,
          Control,
          Input,
          Menu,
          Option,
          Placeholder,
          SingleValue,
          ValueContainer,
          GroupHeading: props => {
            // We will remove this header when the user
            // begins searching the picker and instead
            // add append the creatable option to the
            // list of scheduling pages.
            return !props.selectProps.inputValue ? (
              <div className="align-items-center d-flex justify-content-between SchedulingPagePicker--GroupLabel">
                {props.children}
              </div>
            ) : null
          },
          NoOptionsMessage: props => {
            return (
              <div
                className="align-items-center d-flex flex-column justify-content-between PickerNoOptionsMessage"
                {...props}
              >
                <h6>You have no scheduling pages!</h6>
                <Button
                  onClick={() => setCreateSchedulingPageModal(true)}
                  variant="link"
                >
                  Create Scheduling Page
                </Button>
              </div>
            )
          },
        }}
        isLoading={loading}
        onChange={option => onChange(option!.value.id)}
        onCreateOption={async name => {
          // When a user opts to create a new scheduling page via the Creatable
          // option we will set the user input in state & pipe that value
          // into the CreateSchedulingPageModal before toggling it open so
          // the modal starts in a state with the user's input already
          // present.
          setNewSchedulingPage(name)
          setCreateSchedulingPageModal(true)
        }}
        options={[schedulingPage]}
        placeholder={
          loading ? (
            <span>Loading...</span>
          ) : (
            <div className="align-items-center d-flex justify-content-between">
              <Icon.UserPlus className="mr-2" size={20} />
              Search scheduling pages
            </div>
          )
        }
        value={
          schedulingPageValue
            ? pickerSchedulingPageNodeToPickerOptionType(schedulingPageValue)
            : null
        }
      />
      {createSchedulingPageModal && (
        <CreateSchedulingPageModal
          name={newSchedulingPage}
          onHide={() => setCreateSchedulingPageModal(false)}
          onSuccess={async id => {
            // Refetch the query first so that the new scheduling page is present
            // in the picker to select.
            await refetch()
            // This code will ensure that upon creation the user is immediately
            // on the scheduling-pages page for this new scheduling page.
            // This code also ensures that the 'value' passed into the component
            // is the newly created scheduling page's 'id' so it is the selected
            // option in the picker.
            await navigate(
              `/profiles/${member.profile.id}/scheduling-pages/${id}`
            )
            // We still must call this because there seems to be a race condition
            // in which MeetingTypesPage does not re-render before this component
            // so the state is still in a 'true' state.
            setCreateSchedulingPageModal(false)
          }}
        />
      )}
    </React.Fragment>
  )
}

export default SchedulingPagePicker
