import * as React from 'react'
import { gql } from '@apollo/client'

import { MeetingsPageFilters } from './useMeetingsPageData'
import {
  Maybe,
  useCreateReportMutation,
  useGetReportLazyQuery,
} from '../__generated__/graphql'
import { toast } from '../utils'

gql`
  mutation CreateReport($input: CreateMeetingReportInput!) {
    report: createMeetingReport(input: $input) {
      data {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  query GetReport($id: ID!) {
    report: getReportById(id: $id) {
      id
      url
      status
    }
  }
`

type Args = MeetingsPageFilters & {
  isCancelled: Maybe<boolean>
  isConfirmed: Maybe<boolean>
  profileId: string
  startOnOrAfter: Maybe<string>
  startOnOrBefore: Maybe<string>
  orderBy: Maybe<string>
}

type UseGenerateReport = () => UseGenerateReportReturn

type UseGenerateReportReturn = {
  onGenerateReport: (args: Args) => Promise<void>
  loading: boolean
}

const REPORT_POLLING_INTERVAL = 1000
const REPORT_POLLING_TIME0UT = 300000

export const useGenerateReport: UseGenerateReport = () => {
  const [loading, setLoading] = React.useState(false)
  const [getReport, { called, data, stopPolling }] = useGetReportLazyQuery({
    pollInterval: REPORT_POLLING_INTERVAL,
  })
  const [createReport] = useCreateReportMutation()

  const onGenerateReport = React.useCallback(async (args: Args) => {
    // Tell the user we are working on it.
    setLoading(true)
    try {
      // Get a date time reference for today.
      const promise = createReport({
        variables: {
          input: {
            cancelled: args.isCancelled,
            confirmed: args.isConfirmed,
            meetingTypes: args.meetingTypes,
            members: args.members,
            profile: args.profileId,
            end: args.startOnOrBefore,
            start: args.startOnOrAfter,
            search: args.search || null,
            order: args.orderBy,
          },
        },
      })

      promise.then(({ data }) => {
        if (data && data.report && data.report.errors) {
          console.error('createMeetingReport mutation', data.report.errors)
          toast.error('Something went wrong')
          return
        }
        if (data && data.report && data.report.data) {
          getReport({ variables: { id: data.report.data.id } })
          return
        }
      })
    } catch (error) {
      toast.error('Something went wrong')
      console.error('createMeetingReport mutation', error)
    }
    // We do not need either GQL hook as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    let timer: NodeJS.Timeout
    // We only want to start the timeout if 'getReport' has been called.
    if (called) {
      // This timeout is necessary in the event that the server never
      // generates the report and we just continue polling blindly.
      timer = setTimeout(() => {
        setLoading(false)
        // @ts-ignore
        stopPolling()
        toast.error('Something went wrong')
      }, REPORT_POLLING_TIME0UT)
    }
    // If the report failed to be generated:
    // 1. Stop the loading process.
    // 2. Stop the polling.
    // 3. Alert the user there was a problem.
    if (data && data.report && data.report.status === 'FAILED') {
      setLoading(false)
      // @ts-ignore
      stopPolling()
      toast.error('Something went wrong')
      return
    }
    // If the report was generated:
    // 1. Stop the loading process.
    // 2. Stop the polling.
    // 3. Redirect the user to the report url so it is downloaded to their machine.
    if (data && data.report && data.report.url) {
      setLoading(false)
      // @ts-ignore
      stopPolling()
      window.location.href = data.report.url
    }
    // Clean up the timeout.
    return () => clearTimeout(timer)
    // We don't need stopPolling as a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called, data])
  return {
    onGenerateReport,
    loading,
  }
}
