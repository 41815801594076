import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Col, Form, Media, Modal, Row } from 'react-bootstrap'
import { gql } from '@apollo/client'
import Illustration from './Illustration'
import './CreateMeetingTypeModal.scss'
import DynamicBookingURLInput from './DynamicBookingURLInput'
import Icon from './Icon'
import LoadingButton from './LoadingButton'
import { InternalData as MeetingTypesPageData } from './MeetingTypesPage'
import ProfileContext from './ProfileContext'
import {
  CreateMeetingTypeInput,
  useCreateMeetingTypeModalMutation,
} from '../__generated__/graphql'
import {
  Duration,
  expandClassName,
  mutationErrorsToFormErrors,
  toast,
  Analytics,
} from '../utils'
import Translate from './Translate'

gql`
  mutation CreateMeetingTypeModal($input: CreateMeetingTypeInput!) {
    createMeetingType(input: $input) {
      data {
        id
        order
        name
        color
        duration
        bookingUrl
        slug
      }
      errors {
        field
        messages
      }
    }
  }
`
export type Props = {
  onHide: () => void
  onSuccess: (id: string) => void
  team: MeetingTypesPageData['team']
}

type FormValues = Pick<CreateMeetingTypeInput, 'isGroup' | 'name' | 'slug'>

const CreateMeetingTypeModal: React.FC<Props> = ({
  onHide,
  onSuccess,
  team,
}) => {
  const profile = React.useContext(ProfileContext)
  const [createMeetingType, { loading }] = useCreateMeetingTypeModalMutation()

  React.useEffect(() => {
    Analytics.trackEvent('Create Meeting Type Modal: Opened')
  }, [])

  const onSubmit = async (values: FormValues) => {
    try {
      const res = await createMeetingType({
        variables: {
          input: {
            duration: new Duration({ minutes: 30 }).asSeconds().toString(),
            profile: profile.id,
            team: team.id,
            ...values,
          },
        },
      })
      // Network level errors from Apollo.
      if (res.data?.createMeetingType?.errors) {
        const errors = mutationErrorsToFormErrors(
          res.data.createMeetingType.errors
        )
        // We do this because this endpoint's error wrapper
        // returns a generic (not specific to the input) message.
        if (errors.nonFieldErrors) {
          // Reassign the error message.
          errors.slug = errors.nonFieldErrors
          // Remove this entry from the map.
          delete errors.nonFieldErrors
        }
        return errors
      }
      // We have no network errors and the server has returned either
      // the data we are looking for or some validation error messages.
      if (res.data?.createMeetingType?.data) {
        Analytics.trackEvent('Created Meeting Type', {
          source: 'create-meeting-type-modal',
          isGroup: values.isGroup,
        })
        toast.success(
          `Created Meeting Type`,
          res.data.createMeetingType.data.name
        )
        onSuccess(res.data.createMeetingType.data.id)
        return
      }
      // Here we are catching sever related errors.
    } catch (err) {
      toast.error('Something went wrong')
      console.error(
        'createMeetingType mutation, [CreateMeetingTypeModal.tsx]',
        err
      )
    }
  }
  return (
    <Modal
      className="CreateMeetingTypeModal"
      onHide={async () => {
        Analytics.trackEvent('Create Meeting Type Modal: Cancelled')
        onHide()
      }}
      show
    >
      <Modal.Header className="tw-pb-0 tw-border-b-0" closeButton />
      <Modal.Body className="tw-px-12 tw-pt-0 tw-pb-12">
        <div className="tw-mb-8 tw-text-center tw-flex-col tw-justify-center">
          <div className="tw-mb-6">
            <Illustration
              name="markingCalendar"
              className="tw-h-32 md:tw-h-40"
            />
          </div>
          <h1 className="tw-mb-1">
            <Translate>Create a meeting type</Translate>
          </h1>
          <p className="tw-text-bsGray-600">
            <Translate>
              Meeting types are the services that people want to schedule you
              for. They will appear on your scheduling page, and have a direct
              link you can share.
            </Translate>
          </p>
        </div>

        <FinalForm.Form<FormValues>
          initialValues={{ isGroup: false, name: '', slug: '' }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, hasValidationErrors, submitting }) => (
            <Form onSubmit={handleSubmit}>
              <DynamicBookingURLInput
                bookingUrl={team.bookingUrl}
                nameLabel="Meeting Type Name"
                onEditLink={() =>
                  Analytics.trackEvent('Create Meeting Type Modal: Edited Link')
                }
                slugLabel="Customize Link"
              />
              <Form.Group>
                <FinalForm.Field<boolean> name="isGroup" type="checkbox">
                  {({ input: { checked, name, onChange } }) => (
                    <Row>
                      <Col>
                        <Media
                          className={expandClassName(
                            'tw-items-center tw-border tw-border-solid tw-cursor-pointer tw-border-bsGray-300 tw-p-4 tw-rounded-[5px] tw-space-x-3',
                            checked ? 'tw-selected' : undefined
                          )}
                          onClick={() => onChange(!checked)}
                        >
                          <Form.Check
                            checked={checked}
                            className="tw-pl-0"
                            name={name}
                            type="checkbox"
                          />
                          <Icon.InternalAsset
                            assetName="Icon-Max-Attendees"
                            size={48}
                          />
                          <Media.Body className="">
                            <Translate as="strong">
                              This is a class or group meeting.
                            </Translate>
                            <p className="tw-mb-0 tw-text-bsGray-600">
                              <Translate>
                                Multiple attendees will be able to schedule for
                                the same time slot.
                              </Translate>
                            </p>
                          </Media.Body>
                        </Media>
                      </Col>
                    </Row>
                  )}
                </FinalForm.Field>
              </Form.Group>
              <LoadingButton
                block
                disabled={hasValidationErrors || submitting}
                loading={loading}
                type="submit"
              >
                <Translate>Create Meeting Type</Translate>
              </LoadingButton>
            </Form>
          )}
        </FinalForm.Form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateMeetingTypeModal
