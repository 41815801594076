import * as React from 'react'
import { Button, Modal } from 'react-bootstrap'
import BillingSubscriptionSection from './BillingSubscriptionSection'
import BillingInvoicesSection from './BillingInvoicesSection'
import BillingPaymentMethodSection from './BillingPaymentMethodSection'
import PremiumUpgradeModal from './PremiumUpgradeModal'
import { gql } from '@apollo/client'
import {
  useSettingsModalBillingSectionQuery,
  SettingsModalBillingSectionQuery,
  Maybe,
} from '../__generated__/graphql'
import { DateTime } from '../utils'
import ProfileContext from './ProfileContext'
import Spinner from './Spinner'
import Translate from './Translate'
import SettingsSectionPicker from './SettingsSectionPicker'
import { SettingsSection } from './SettingsModal'
import Icon from './Icon'

gql`
  query SettingsModalBillingSection($profile: ID!) {
    subscription: getSubscription(profile: $profile) {
      id
      paymentType
      markedForCancelation
      status
      cycleInterval
      cycleEnd
      trialEnd
      seats
    }

    paymentMethod: getDefaultPaymentSource(profile: $profile) {
      id
      brand
      last4
      expiration
    }

    profile: getProfileById(id: $profile) {
      id
      billedAnnually
      allocatedSeats
      occupiedSeats
      upcomingInvoiceAmount
      billingbotToken
      plan {
        ...PlanData
      }
    }

    plans: getPlans {
      edges {
        node {
          ...PlanData
        }
      }
    }
  }

  fragment PlanData on PlanNode {
    id
    name
    freeDefault
    trialDefault
    seatPriceMonthly
    seatPriceYearly
    requireAppointletBranding
    allowEmailCustomization
    allowFormFields
    allowManualConfirm
    allowPrivateMeetingTypes
    allowRedirect
    allowReminders
    allowWebConferencing
    allowWebhooks
    allowZapier
  }
`

export type BillingData = {
  subscription: Omit<
    SettingsModalBillingSectionQuery['subscription'],
    'cycleEnd' | 'trialEnd'
  > & {
    cycleEnd: DateTime
    trialEnd: DateTime
  }
  profile: SettingsModalBillingSectionQuery['profile']
  paymentMethod: SettingsModalBillingSectionQuery['paymentMethod']
}

type Props = {
  onSelect: (section: SettingsSection) => void
}

const SettingsModalBillingSection: React.FC<Props> = ({ onSelect }) => {
  const profile = React.useContext(ProfileContext)

  const { data, loading, error, refetch } = useSettingsModalBillingSectionQuery(
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { profile: profile.id },
    }
  )

  const billingData: Maybe<BillingData> = React.useMemo(
    () =>
      data && data.subscription
        ? {
            subscription: {
              ...data.subscription,
              cycleEnd: new DateTime(data.subscription.cycleEnd),
              trialEnd: new DateTime(data.subscription.trialEnd),
            },
            profile: data.profile!,
            paymentMethod: data.paymentMethod!,
          }
        : null,
    [data]
  )

  // Tracks if the premium upgrade modal is open or not
  const [premiumUpgradeModalIsOpen, setPremiumUpgradeModalIsOpen] =
    React.useState<boolean>(false)

  return (
    <div className="SettingsModalBillingSection tw-w-full">
      {premiumUpgradeModalIsOpen && (
        <PremiumUpgradeModal
          profile={profile}
          onSuccess={() => setPremiumUpgradeModalIsOpen(false)}
          onHide={() => setPremiumUpgradeModalIsOpen(false)}
        />
      )}
      <Modal.Header closeButton>
        <Icon.CreditCard className="tw-hidden tw-text-bsGray-600 lg:tw-block" />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title as="h2" className="tw-hidden md:tw-block">
            <Translate>Billing & Invoices</Translate>
          </Modal.Title>
          <Translate as="p">Manage your payment preferences.</Translate>
        </div>
        <SettingsSectionPicker onChange={onSelect} value="billing" />
      </Modal.Header>
      <Modal.Body>
        {loading || !billingData || error ? (
          <div className="tw-flex tw-w-full tw-h-3/4 tw-items-center tw-justify-center tw-flex-col">
            {loading && <Spinner />}

            {/* Show an error message if we failed to get the data. */}
            {error && !loading && (
              <p className="tw-mt-4">
                <Translate>An error occurred.</Translate>{' '}
                <Button variant="link" onClick={() => refetch()}>
                  <Translate>Retry</Translate>
                </Button>
              </p>
            )}
          </div>
        ) : (
          <>
            <BillingSubscriptionSection
              billingData={billingData}
              onChange={refetch}
            />

            <hr />

            <BillingPaymentMethodSection
              billingData={billingData}
              onPaymentMethodUpdated={() => {
                refetch()

                // If the user is on the free plan, pop the premium upgrade modal up
                // to make sure they don't accidentally walk off.
                if (billingData.profile.plan?.freeDefault) {
                  setPremiumUpgradeModalIsOpen(true)
                }
              }}
            />

            <hr />

            <BillingInvoicesSection billingData={billingData} />
          </>
        )}
      </Modal.Body>
    </div>
  )
}

export default SettingsModalBillingSection
