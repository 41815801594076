import * as React from 'react'

import './PanelButton.scss'

interface Props {}

const PanelButton: React.FC<Props & React.HTMLProps<HTMLDivElement>> = ({
  children,
  className,
  ...rest
}) => (
  <div className={`PanelButton ${className}`} {...rest}>
    {children}
  </div>
)

export default PanelButton
