import * as React from 'react'
import { Dropdown } from 'react-bootstrap'
import DropdownItem from './DropdownItem'
import { RoleNode } from '../__generated__/graphql'
import Translate from './Translate'
import Var from './Var'

type PickedRole = Pick<RoleNode, 'id' | 'name'>

type Props = {
  disabled?: boolean
  role: PickedRole
  roles: PickedRole[]
  onRoleChange: (role: PickedRole) => void
  onRemoveMember: () => void
  onTransferOwnership?: () => void
}

// This is a total hack -- we need to get this helpText into the database and fetch it
// from there.
const roleDescriptions: Record<string, string> = {
  Admin: 'Full control over the account.',
  Member: 'Can manage scheduling pages, meeting types, and meetings.',
  Booker:
    'Can view scheduling pages and meeting types and manage their own meetings.',
}

const MemberSettingsListItemMenu: React.FC<Props> = ({
  disabled,
  role,
  roles,
  onRoleChange,
  onRemoveMember,
  onTransferOwnership,
}) => (
  <Dropdown alignRight={true}>
    <Dropdown.Toggle id="role" variant="outline-secondary" disabled={disabled}>
      {role.name}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {roles.map(r => (
        <DropdownItem
          className="md:tw-items-start tw-flex-col tw-w-60 tw-whitespace-normal tw-group hover:tw-white"
          key={r.id}
          onClick={() => onRoleChange(r)}
        >
          <Translate>
            <Var name="name">
              <strong className="tw-text-bsGray-900">{r.name}</strong>
            </Var>
          </Translate>

          <Translate>
            <Var name="name">
              <p className="tw-mt-1 tw-text-xs md:tw-text-sm tw-text-bsGray-600">
                {roleDescriptions[r.name as string]}
              </p>
            </Var>
          </Translate>
        </DropdownItem>
      ))}

      {onTransferOwnership && (
        <>
          <Dropdown.Divider />
          <DropdownItem onClick={onTransferOwnership}>
            <Translate>Transfer Ownership</Translate>...
          </DropdownItem>
        </>
      )}

      <Dropdown.Divider />
      <DropdownItem onClick={onRemoveMember}>
        <Translate>Remove Member</Translate>...
      </DropdownItem>
    </Dropdown.Menu>
  </Dropdown>
)

export default MemberSettingsListItemMenu
