import './CurrencyPicker.scss'
import * as React from 'react'
import Picker, { PickerOption, Props as PickerProps } from './Picker'
import { gql } from '@apollo/client'
import {
  __EnumValue,
  Currency,
  Maybe,
  useCurrencyPickerQuery,
} from '../__generated__/graphql'

gql`
  query CurrencyPicker {
    currencies: __type(name: "Currency") {
      enumValues {
        name
        description
      }
    }
  }
`
interface Props extends Omit<PickerProps, 'value' | 'options' | 'onChange'> {
  onChange: (currency: Currency) => void
  value: Maybe<Currency>
}

const currencyToPickerOption = (
  enumValue: Pick<__EnumValue, 'name' | 'description'>
) => ({
  label: enumValue.description!,
  value: enumValue.name as Currency,
})

const CurrencyPicker: React.FC<Props> = ({ disabled, onChange, value }) => {
  const { data, loading } = useCurrencyPickerQuery()

  // Convert the currency enum data into PickerOptions
  const options: Maybe<PickerOption<Currency>[]> | undefined = React.useMemo(
    () => data?.currencies?.enumValues?.map(currencyToPickerOption),
    [data]
  )

  let pickerValue: Maybe<PickerOption<Currency>> = null

  // If there is a value provided, look up it's equivalent enumValue
  if (value && options) {
    pickerValue = options.find(currency => currency.value === value) || null
  }

  return (
    <div className="CurrencyPicker">
      <Picker<Currency>
        required
        disabled={disabled}
        loading={loading}
        onChange={option => onChange(option!.value)}
        options={options || []}
        value={pickerValue}
        placeholder="Select a currency"
      />
    </div>
  )
}

export default CurrencyPicker
