import * as React from 'react'
/**
 * It might be tempting to write
 * import * as Feather from 'react-feather'
 * however this load the entire library which is extremely large
 * and has a large impact on the bundle size.
 */
import {
  Activity,
  AlertCircle,
  AlertTriangle,
  AlignLeft,
  ArrowLeft,
  ArrowRight,
  AtSign,
  Bell,
  Bold,
  Book,
  Briefcase,
  Calendar,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Clock,
  Code,
  Copy,
  CreditCard,
  Download,
  DownloadCloud,
  Edit,
  Edit3,
  ExternalLink,
  Eye,
  EyeOff,
  Facebook,
  Filter,
  Gift,
  Globe,
  Grid,
  HelpCircle,
  Home,
  Image,
  Inbox,
  Info,
  Italic,
  Link,
  Link2,
  List,
  LifeBuoy,
  Lock,
  Linkedin,
  Loader,
  LogOut,
  Mail,
  MapPin,
  Maximize2,
  MessageSquare,
  Minus,
  Monitor,
  MoreHorizontal,
  Move,
  Phone,
  Plus,
  PlusCircle,
  Radio,
  RefreshCw,
  Repeat,
  Search,
  Server,
  Settings,
  Share,
  Share2,
  Shield,
  ShoppingCart,
  Slash,
  Star,
  Tag,
  Target,
  ThumbsDown,
  ThumbsUp,
  Trash2,
  Twitter,
  Underline,
  User,
  UserCheck,
  Users,
  UserPlus,
  UserMinus,
  UploadCloud,
  X,
  XCircle,
} from 'react-feather'

import { expandClassName } from '../utils/expandClassName'

interface Props extends React.HTMLProps<HTMLImageElement> {
  assetName: string
  size?: number
}

export interface ZapierIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

const InternalAsset: React.FC<Props> = ({ assetName, className, size }) => (
  // I would love to use our Image tag here, but for some reason it creates
  // weird import cycle errors in Storybook.
  <img
    alt={assetName}
    className={expandClassName('Icon-InternalAsset', className)}
    src={require(`../assets/${assetName}.svg`)}
    style={{ height: size, width: size }}
  />
)

const Zapier: React.FC<ZapierIconProps> = ({ size, ...rest }) => (
  <svg
    width={size || '24'}
    height={size || '24'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.5 10.0044C12.4995 10.7474 12.3636 11.4588 12.1159 12.1156C11.4594 12.3632 10.7477 12.4995 10.0043 12.5H9.9957C9.25265 12.4995 8.54115 12.3636 7.8846 12.116C7.6369 11.4593 7.5005 10.7477 7.5 10.0044V9.9957C7.5005 9.2526 7.6366 8.5412 7.8839 7.88455C8.5408 7.6368 9.25235 7.50045 9.9957 7.5H10.0043C10.7477 7.50045 11.4594 7.6368 12.1159 7.88455C12.3636 8.5412 12.4995 9.2526 12.5 9.9957V10.0044ZM19.861 8.33335H14.0237L18.1512 4.2058C17.8268 3.7502 17.4651 3.3229 17.0706 2.92845L17.0704 2.9282C16.6763 2.5342 16.2493 2.17295 15.7942 1.8488L11.6666 5.9763V0.139051C11.1263 0.0483513 10.5715 0.000301361 10.0053 0H9.99465C9.42855 0.000301361 8.87375 0.0483513 8.33335 0.139051V5.97635L4.20578 1.8488C3.75041 2.17305 3.32334 2.53455 2.92908 2.9288L2.92765 2.9302C2.53385 3.32415 2.17278 3.7508 1.84883 4.2058L5.9764 8.33335H0.139004C0.139004 8.33335 0.000195312 9.4297 0 9.99635V10.0035C0.000195312 10.5702 0.0482812 11.1257 0.139043 11.6666H5.9763L1.84885 15.7941C2.49748 16.705 3.29496 17.5026 4.20584 18.1512L8.33335 14.0238V19.861C8.87315 19.9515 9.4274 19.9996 9.9929 20H10.0072C10.5726 19.9996 11.1269 19.9515 11.6666 19.861V14.0237L15.7943 18.1512C16.2495 17.827 16.6764 17.4656 17.0706 17.0715L17.0716 17.0706C17.4657 16.6764 17.827 16.2495 18.1511 15.7943L14.0236 11.6666H19.8609C19.9515 11.1269 19.9996 10.5726 20 10.0071V9.9928C19.9996 9.42735 19.9515 8.87315 19.861 8.33335Z"
      fill="currentColor"
    />
  </svg>
)

const Icon = {
  // Our local icon
  InternalAsset,
  // Feather icons
  Activity,
  AlertCircle,
  AlertTriangle,
  AlignLeft,
  ArrowLeft,
  ArrowRight,
  AtSign,
  Bell,
  Bold,
  Book,
  Briefcase,
  Calendar,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Clock,
  Code,
  Copy,
  CreditCard,
  Download,
  DownloadCloud,
  Edit,
  Edit3,
  ExternalLink,
  Eye,
  EyeOff,
  Facebook,
  Filter,
  Gift,
  Globe,
  Grid,
  HelpCircle,
  Home,
  Image,
  Inbox,
  Info,
  Italic,
  Link,
  Link2,
  Linkedin,
  List,
  LifeBuoy,
  Lock,
  LogOut,
  Loader,
  Mail,
  MapPin,
  Maximize2,
  MessageSquare,
  Minus,
  Monitor,
  MoreHorizontal,
  Move,
  Phone,
  Plus,
  PlusCircle,
  Radio,
  RefreshCw,
  Repeat,
  Search,
  Server,
  Settings,
  Share,
  Share2,
  Shield,
  ShoppingCart,
  Slash,
  Star,
  Tag,
  Target,
  ThumbsDown,
  ThumbsUp,
  Trash2,
  Twitter,
  Underline,
  User,
  UserCheck,
  Users,
  UserPlus,
  UserMinus,
  UploadCloud,
  X,
  XCircle,
  Zapier,
}

export default Icon
