import * as React from 'react'
import { gql } from '@apollo/client'
import MultiPicker from './Multipicker'
import { MeetingTypeNode, ID } from '../types'
import MemberContext from './MemberContext'
import { PickerOption, PickerOptionGroup } from './Picker'
import MeetingTypeImage from './MeetingTypeImage'
import Icon from './Icon'
import { useMeetingTypesPickerQuery } from '../__generated__/graphql'

gql`
  query MeetingTypesPicker($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            id
            name
            meetingTypes {
              edges {
                node {
                  id
                  color
                  image
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

type PickerMeetingTypeNode = Pick<
  MeetingTypeNode,
  'id' | 'name' | 'image' | 'color'
>

interface Props {
  onChange: (meetingTypes: ID[]) => void
  value: ID[] | null
}

const pickerMeetingTypeNodeToPickerOption = (
  meetingType: PickerMeetingTypeNode
): PickerOption<PickerMeetingTypeNode> => ({
  image: meetingType.image ? (
    <MeetingTypeImage meetingType={meetingType} size="xs" />
  ) : undefined,
  label: meetingType.name,
  value: meetingType,
})

const MeetingTypesPicker: React.FC<Props> = ({ onChange, value }) => {
  const member = React.useContext(MemberContext)

  const { data, loading } = useMeetingTypesPickerQuery({
    variables: { id: member.id },
  })

  // Convert the results into OptionGroups
  const optionGroups: PickerOptionGroup<PickerMeetingTypeNode>[] =
    React.useMemo(
      () =>
        data?.member.teams.edges.map<PickerOptionGroup>(edge => ({
          label: edge!.node!.name,
          options: edge!.node!.meetingTypes.edges.map(edge =>
            pickerMeetingTypeNodeToPickerOption(edge!.node!)
          ),
        })) ?? [],
      [data]
    )

  // Get a PickerOption for the value if one is passed in.
  const pickerValue: PickerOption<PickerMeetingTypeNode>[] = React.useMemo(
    () =>
      (value &&
        optionGroups &&
        optionGroups.reduce(
          (pickedOptions, group) => [
            ...pickedOptions,
            // Add options that have a matching ID to the value list
            ...group.options.filter(
              option => value.indexOf(option.value.id) !== -1
            ),
          ],
          [] as PickerOption<PickerMeetingTypeNode>[]
        )) ??
      [],
    [value, optionGroups]
  )

  return (
    <MultiPicker<PickerMeetingTypeNode>
      value={pickerValue}
      onChange={values => onChange(values.map(({ value }) => value.id))}
      loading={loading}
      options={optionGroups}
      placeholder={
        <React.Fragment>
          <Icon.Grid className="mr-8 text-gray-400" size="18" />
          <span>Choose Meeting Types</span>
        </React.Fragment>
      }
    />
  )
}

export default MeetingTypesPicker
