import { DateTimeRange } from './datetime'
// NOTE: Why?
// We subtract 1 day from the end time when rendering a range label
// because the range is designed to be inclusive on the API side but
// the label needs to be accurate to what the user selected:
// ex: Selected April 15 & April 20
// api:  start: 2022-04-15T00:00 end: 2022-04-21T00:00
export const dateRangeLabelRenderer = (
  range: DateTimeRange,
  customRange?: DateTimeRange
) => {
  if (customRange) {
    const { endDateTime: e, startDateTime: s } = customRange
    return s.isSame(e.subtract({ day: 1 }))
      ? s.format('localized-medium-date')
      : `${s.format('localized-medium-date')} - ${e
          .subtract({ day: 1 })
          .format('localized-medium-date')}`
  }
  const { endDateTime: e, startDateTime: s } = range
  return s.isSame(e.subtract({ day: 1 }))
    ? s.format('localized-medium-date')
    : `${s.format('localized-medium-date')} - ${e
        .subtract({ day: 1 })
        .format('localized-medium-date')}`
}
