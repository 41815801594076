import * as React from 'react'
import { DropdownButton, Dropdown, ButtonProps } from 'react-bootstrap'
import { ReplaceProps } from 'react-bootstrap/helpers'

import DropdownItem from './DropdownItem'
import Icon from './Icon'
import InstallOnWebsiteModal from './InstallOnWebsiteModal'
import Toggler from './Toggler'
import {
  encodeQueryParams,
  copyToClipboard,
  toast,
  openWindow,
  Analytics,
} from '../utils'

interface Props {
  label: string
  url: string
  analyticsSource?: string
}

const ShareButton: React.FC<
  Props & ReplaceProps<React.ElementType<any>, ButtonProps>
> = ({ label, url, analyticsSource, ...props }) => (
  // TYPESCRIPT: The typing here is incorrect and it deals with how we are defining props.
  // @ts-ignore
  <DropdownButton
    {...props}
    id="share-button"
    variant="outline-secondary"
    className="ShareButton no-caret"
    title={
      <React.Fragment>
        <Icon.Share size={16} className="text-pink" />
        Share
      </React.Fragment>
    }
  >
    <DropdownItem
      onClick={() => {
        copyToClipboard(url)
        toast.success('Copied Link', url)

        Analytics.trackEvent('Share: Copied Link', {
          source: analyticsSource,
        })
      }}
    >
      <Icon.Link size={20} />
      Copy Link
    </DropdownItem>

    <Toggler>
      {({ isToggled, setOff, setOn }) => (
        <React.Fragment>
          <DropdownItem onClick={setOn}>
            <Icon.Code size={20} />
            Embed on Website
          </DropdownItem>
          {isToggled && (
            <InstallOnWebsiteModal
              onHide={setOff}
              onSuccess={setOff}
              url={url}
            />
          )}
        </React.Fragment>
      )}
    </Toggler>

    <Dropdown.Divider />
    <Dropdown.Header as="h6">Social</Dropdown.Header>
    <DropdownItem
      onClick={() => {
        openWindow(
          `https://twitter.com/intent/tweet?${encodeQueryParams({
            text: label + ' ' + url,
          })}`,
          'twitter',
          { width: 550, height: 400 }
        )

        Analytics.trackEvent('Share: Twitter', {
          source: analyticsSource,
        })
      }}
    >
      <Icon.Twitter size={20} />
      Twitter
    </DropdownItem>
    <DropdownItem
      onClick={() => {
        openWindow(
          `https://facebook.com/sharer/sharer.php?${encodeQueryParams({
            u: url,
          })}`,
          'facebook',
          { width: 550, height: 400 }
        )

        Analytics.trackEvent('Share: LinkedIn', {
          source: analyticsSource,
        })
      }}
    >
      <Icon.Facebook size={20} />
      Facebook
    </DropdownItem>
    <DropdownItem
      onClick={() => {
        openWindow(
          `https://www.linkedin.com/sharing/share-offsite/?${encodeQueryParams({
            url: url,
          })}`,
          'linkedin',
          { width: 550, height: 400 }
        )

        Analytics.trackEvent('Share: LinkedIn', {
          source: analyticsSource,
        })
      }}
    >
      <Icon.Linkedin size={20} />
      LinkedIn
    </DropdownItem>
  </DropdownButton>
)

export default ShareButton
