import * as React from 'react'
import { Row } from 'react-bootstrap'
import './OnboardFooter.scss'

export const OnboardFooter: React.FC = () => {
  return (
    <div className="OnboardFooter">
      <Row>
        <div className="col-sm-8">
          <a
            className="text-decoration-none text-gray-500"
            href="http://www.appointlet.help/en/articles/63297-privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
          <span className="mx-4 text-gray-500">|</span>
          <a
            className="text-decoration-none text-gray-500"
            href="http://www.appointlet.help/en/articles/63295-terms-conditions"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms & Conditions
          </a>
        </div>
        <div className="col-sm-4 text-sm-right">
          © {new Date().getFullYear()} Appointlet
        </div>
      </Row>
    </div>
  )
}
