import { EditorState } from 'draft-js'
import PluginEditor from '@draft-js-plugins/editor'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import Icon from '../../../Icon'
import IconTextInput from '../../../IconTextInput'
import LoadingButton from '../../../LoadingButton'
import RichTextEditor from '../../../RichTextEditor'
import './NotesTextInput.scss'

export type NotesTextInputProps = {
  readonly onSubmit: (val: string | null) => void
  readonly value: string
  readonly renderExpanded?: boolean
  readonly inlineCloseCallback?: () => void
}

export const NotesTextInput: React.FC<NotesTextInputProps> = ({
  onSubmit,
  value,
  renderExpanded,
  inlineCloseCallback,
}: NotesTextInputProps) => {
  const [editorInput, setEditorInput] = React.useState<string | null>(value)
  const [isExpanded, setIsExpanded] = React.useState<boolean>(
    renderExpanded ? true : false
  )
  const textBoxRef = React.useRef<PluginEditor>(null)

  const focusTextBox = () => {
    textBoxRef?.current?.focus()
  }

  const onChange = (value: string | null) => {
    setEditorInput(value)
  }
  return (
    <div className="NotesTextInput">
      <div
        className={`${!isExpanded ? 'visible' : 'invisible'}`}
        style={isExpanded ? { height: 0 } : undefined}
        onClick={() => {
          focusTextBox()
          setIsExpanded(true)
        }}
      >
        <IconTextInput
          icon={Icon.Edit}
          //@ts-ignore
          placeholder="Write a note"
        />
      </div>
      <div
        className={`${isExpanded ? 'visible' : 'invisible'}`}
        style={!isExpanded ? { height: 0 } : undefined}
      >
        <RichTextEditor
          onChange={onChange}
          value={editorInput}
          variables={[]}
          editorRef={textBoxRef}
        />
        <div className="w-100 mt-12 d-flex flex-row justify-content-end">
          <Button
            className="text-gray-600 mr-16"
            type="button"
            variant="link"
            onClick={() => {
              renderExpanded && !!inlineCloseCallback
                ? inlineCloseCallback()
                : setIsExpanded(false)
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            className="bg-primary"
            onClick={() => {
              onSubmit(editorInput)
              // clear editor state so next time it opens it does not show previous state
              textBoxRef.current
                ?.getPluginMethods()
                .setEditorState(EditorState.createEmpty())
              setIsExpanded(false)
            }}
          >
            {renderExpanded ? 'Save Changes' : 'Add Note'}
          </LoadingButton>
        </div>
      </div>
    </div>
  )
}
