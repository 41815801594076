import * as React from 'react'
import { Button } from 'react-bootstrap'

import Alert from './Alert'
import TimeZoneLabel from './TimeZoneLabel'
import { Timezone } from '../__generated__/graphql'

export type Props = {
  timezone: Timezone
  onChange?: () => void
}

const TimezoneDiffAlert: React.FC<Props> = ({ onChange, timezone }) => (
  <Alert className="mb-24" variant="warning">
    <span>
      Times are currently displayed in{' '}
      <strong>
        <TimeZoneLabel timezone={timezone} />
      </strong>
      .
    </span>

    <Button onClick={onChange} variant="link" className="ml-auto">
      Change
    </Button>
  </Alert>
)

export default TimezoneDiffAlert
