import * as React from 'react'

interface Props {
  name: string
  pluralize?: number
}

const Var: React.FC<Props> = ({ children, name, pluralize }) => {
  return (
    <var data-var={name} data-pluralize={pluralize}>
      {children}
    </var>
  )
}

export default Var
