import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, Media, Modal } from 'react-bootstrap'

import './UpdateMeetingTypeModalGeneralSection.scss'
import ColorPicker from './ColorPicker'
import DynamicBookingURLInput from './DynamicBookingURLInput'
import Icon from './Icon'
import ImageInput from './ImageInput'
import UpdateMeetingTypeContext, {
  IMeetingType,
} from './UpdateMeetingTypeContext'
import DurationInput from './DurationInput'
import { Duration } from '../utils'
import {
  durationGreaterThan,
  durationLessThan,
  composeValidators,
} from '../utils/validators'
import HelpToolTip from './HelpToolTip'
import Translate from './Translate'
import { MTESection } from './UpdateMeetingTypeModal'
import MeetingTypeEditorSectionPicker from './MeetingTypeEditorSectionPicker'

const minDuration = new Duration({ minutes: 5 })
const maxDuration = new Duration({ hours: 8 })

type Props = {
  onSelect: (section: MTESection) => void
}

const UpdateMeetingTypeModalGeneralSection: React.FC<Props> = ({
  onSelect,
}) => {
  const meetingType = React.useContext(UpdateMeetingTypeContext)
  return (
    <div className="UpdateMeetingTypeModalGeneralSection tw-w-full">
      <Modal.Header closeButton>
        <Icon.InternalAsset
          assetName="icn-mte-header-general"
          className="tw-hidden lg:tw-block"
        />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title>
            <Translate>General</Translate>
          </Modal.Title>
          <Translate as="p">Set the basic details of your meeting</Translate>
        </div>
        <MeetingTypeEditorSectionPicker onChange={onSelect} value="general" />
      </Modal.Header>
      <Modal.Body>
        <Media className="tw-mb-6 tw-order-1">
          <Media.Body className="tw-mr-4 tw-truncate">
            <DynamicBookingURLInput
              bookingUrl={meetingType.team.bookingUrl}
              nameLabel="Name"
              slugLabel="Scheduling Page Link"
              updating
            />
          </Media.Body>

          <FinalForm.Field<IMeetingType['image']> name="image">
            {({ input }) => (
              <div className="tw-hidden md:tw-block">
                <ImageInput value={input.value} onChange={input.onChange} />
              </div>
            )}
          </FinalForm.Field>
        </Media>

        <FinalForm.Field<IMeetingType['image']> name="image">
          {({ input }) => (
            <Media className="tw-mb-6 tw-order-2 tw-space-x-4 md:tw-hidden">
              <ImageInput onChange={input.onChange} value={input.value} />
              <Media.Body>
                <Form.Group>
                  <Form.Label>
                    <Translate>Image</Translate>
                  </Form.Label>
                  <p className="tw-text-bsGray-600">
                    <Translate>
                      Recommend square images, at least 250x250 in size.
                    </Translate>
                  </p>
                </Form.Group>
              </Media.Body>
            </Media>
          )}
        </FinalForm.Field>

        <Form.Group className="tw-order-4 md:tw-order-2">
          <Form.Label className="tw-flex tw-items-center tw-space-x-[5px]">
            <Translate>Description</Translate>
            <HelpToolTip placement="auto">
              <Translate>
                Outline of what will happen in this meeting and why attendees
                should schedule it. This will be displayed on the scheduling
                page.
              </Translate>
            </HelpToolTip>
          </Form.Label>
          <FinalForm.Field<IMeetingType['description']> name="description">
            {({ input }) => (
              <Form.Control
                {...input}
                as="textarea"
                rows={4}
                value={input.value || ''}
              />
            )}
          </FinalForm.Field>
        </Form.Group>

        <Form.Group className="tw-order-3">
          <Form.Label className="tw-flex tw-items-center tw-space-x-[5px]">
            <Translate>Length</Translate>
            <HelpToolTip placement="auto">
              <Translate>
                How long this meeting usually takes. This will be shown on the
                scheduling page and will dictate how much time the corresponding
                event consumes in your calendar.
              </Translate>
            </HelpToolTip>
          </Form.Label>
          <FinalForm.Field<IMeetingType['duration']>
            name="duration"
            validate={composeValidators(
              durationGreaterThan(minDuration),
              durationLessThan(maxDuration)
            )}
          >
            {({ input: { onChange, value }, meta }) => (
              <React.Fragment>
                <DurationInput
                  isInvalid={meta.invalid}
                  onChange={onChange}
                  value={value}
                />

                {meta.error && (
                  <Form.Text className="text-danger">
                    <Translate>{meta.error}</Translate>
                  </Form.Text>
                )}
              </React.Fragment>
            )}
          </FinalForm.Field>
        </Form.Group>

        <Form.Group className="tw-order-last">
          <Form.Label className="tw-hidden md:tw-block">
            <Translate>Color</Translate>
          </Form.Label>

          <FinalForm.Field<IMeetingType['color']> name="color">
            {({ input }) => (
              <ColorPicker onSelect={input.onChange} value={input.value} />
            )}
          </FinalForm.Field>
        </Form.Group>
      </Modal.Body>
    </div>
  )
}

export default UpdateMeetingTypeModalGeneralSection
