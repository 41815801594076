import * as React from 'react'
import Portrait, { Props as PortraitProps } from '../../../Portrait'
import Image from '../../../Image'
import './AttendeePortrait.scss'

import goingIcon from './AttendeePortrait-Going.svg'
import notGoingIcon from './AttendeePortrait-NotGoing.svg'
import pendingIcon from './AttendeePortrait-Pending.svg'

export interface Props extends Omit<PortraitProps, 'hideTooltip' | 'size'> {
  status: 'going' | 'not-going' | 'pending' | null
}

const iconMap: Record<'going' | 'not-going' | 'pending', string> = {
  going: goingIcon,
  'not-going': notGoingIcon,
  pending: pendingIcon,
}

const AttendeePortrait: React.FC<Props> = ({ status, ...rest }) => (
  <div className="AttendeePortrait position-relative">
    {status && (
      <Image
        src={iconMap[status]}
        alt="Status"
        className="AttendeePortrait--StatusIcon position-absolute"
        width={18}
        height={18}
      />
    )}

    <Portrait {...rest} size={40} hideToolTip />
  </div>
)
export default AttendeePortrait
