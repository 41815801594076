import * as React from 'react'
import { Button, Col } from 'react-bootstrap'
import { OnboardTitle } from '../../molecules/OnboardTitle/OnboardTitle'
import { ConnectCalendarOption } from '../../molecules/ConnectCalendarOption/ConnectCalendarOption'

export interface ConnectCalendarViewProps {
  readonly doLaterOnClick: () => void
  readonly googleOnClick: () => void
  readonly officeOnClick: () => void
  readonly isGoogleLoading: boolean
  readonly isOfficeLoading: boolean
}

export const ConnectCalendarView: React.FC<ConnectCalendarViewProps> = ({
  doLaterOnClick,
  googleOnClick,
  officeOnClick,
  isGoogleLoading,
  isOfficeLoading,
}: ConnectCalendarViewProps) => {
  return (
    <div className="ConnectCalendarView d-flex flex-column">
      <OnboardTitle
        title="Connect your Calendar"
        subtitle="Keep your calendar in sync and prevent double-bookings"
      />
      <Col className="mt-24 px-0">
        <ConnectCalendarOption
          variant="google"
          isLoading={isGoogleLoading}
          onClick={googleOnClick}
        />
        <ConnectCalendarOption
          variant="office365"
          isLoading={isOfficeLoading}
          onClick={officeOnClick}
        />
      </Col>
      <Button
        className="text-primary text-left mt-24"
        onClick={doLaterOnClick}
        type="button"
        variant="link"
      >
        I'll do this later
      </Button>
    </div>
  )
}
