import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import Icon from './Icon'
import RescheduleMeetingDatePicker from './RescheduleMeetingDatePicker'
import ReschedulableTimesPicker from './ReschedulableTimesPicker'
import Spinner from './Spinner'
import {
  Maybe,
  RescheduleMeetingModalQuery as Response,
  useRescheduleMeetingModalQuery,
} from '../__generated__/graphql'
import { Meeting } from '../types'
import { DateTime } from '../utils'
import Translate from './Translate'

gql`
  query RescheduleMeetingModal($end: DateTime, $id: ID!, $start: DateTime) {
    meeting: getMeetingById(id: $id) {
      end
      id
      reschedulableTimes(end: $end, start: $start) {
        data
      }
      start
    }
  }
`

type InternalData = Pick<Meeting, 'end' | 'id' | 'reschedulableTimes' | 'start'>

const wireDataToInternalData = (wire: Response['meeting']): InternalData => ({
  ...wire,
  end: new DateTime(wire.end),
  reschedulableTimes: wire.reschedulableTimes?.data
    ? wire.reschedulableTimes?.data?.map(t => new DateTime(t))
    : null,
  start: new DateTime(wire.start),
})
export type Props = {
  meeting: Pick<Meeting, 'id' | 'start'>
  onHide: () => void
  onSuccess: () => void
}

const RescheduleMeetingModal: React.FC<Props> = ({
  meeting,
  onHide,
  onSuccess,
}) => {
  const [startTime, setStartTime] = React.useState<DateTime>(
    meeting.start.startOf('day')
  )

  const { data, loading } = useRescheduleMeetingModalQuery({
    fetchPolicy: 'network-only',
    variables: {
      end: startTime.endOf('day').toISO(),
      id: meeting.id,
      start: startTime.toISO(),
    },
  })

  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data.meeting) : null),
    [data]
  )

  return (
    <Modal
      className="RescheduleMeetingModal"
      onHide={onHide}
      show={true}
      size="lg"
    >
      <Modal.Header closeButton>
        <Icon.Repeat size={32} />
        <Modal.Title>
          <Translate>Choose a new time for the meeting</Translate>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="tw-rounded-bl-xl tw-rounded-br-xl tw-flex tw-flex-col tw-h-[600px] tw-overflow-hidden tw-p-0 md:tw-flex-row">
        <div className="tw-bg-white tw-flex tw-items-center tw-justify-center tw-border-b tw-border-t-0 tw-border-x-0 md:tw-border-y-0 md:tw-border-l-0 md:tw-border-r tw-border-solid tw-border-bsGray-300 md:tw-items-start md:tw-justify-start">
          <RescheduleMeetingDatePicker
            id={meeting.id}
            onSelect={setStartTime}
            startDate={startTime}
          />
        </div>
        <div className="tw-bg-bsGray-100 tw-flex-1 tw-p-5 tw-overflow-y-auto">
          {!loading && internalData ? (
            <ReschedulableTimesPicker
              meeting={internalData}
              onSuccess={onSuccess}
              startTime={startTime}
            />
          ) : (
            <div className="tw-items-center tw-flex tw-h-full tw-justify-center tw-w-full">
              <Spinner />
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RescheduleMeetingModal
