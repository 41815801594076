import * as React from 'react'

import { MeetingTypeVisibility } from './MeetingTypeVisibilityPicker'
import { Maybe } from '../__generated__/graphql'
import {
  FormField,
  MeetingType,
  MeetingTypeTeamMember,
  Member,
  Reminder,
  Schedule,
  Team,
  TeamMember,
  User,
  ConferenceProvider,
  CalendarAccount,
  CalendarProvider,
  ConferencingAccount,
  PaymentCollectionProvider,
} from '../types'
import { DateTime } from '../utils'

export type IMeetingType = Pick<
  MeetingType,
  | '__typename'
  | 'allowCancellations'
  | 'allowReschedules'
  | 'autoConfirm'
  | 'bookingUrl'
  | 'bufferAfter'
  | 'bufferBefore'
  | 'cancellationEmailBody'
  | 'cancellationEmailSubject'
  | 'cancellationNotice'
  | 'color'
  | 'deleted'
  | 'description'
  | 'duration'
  | 'frequency'
  | 'hostAssignmentStrategy'
  | 'id'
  | 'image'
  | 'instructions'
  | 'isGroup'
  | 'location'
  | 'locationType'
  | 'maxAttendees'
  | 'maxBookingsPerDay'
  | 'maximumNotice'
  | 'minimumNotice'
  | 'name'
  | 'price'
  | 'priceCurrency'
  | 'priceFormatted'
  | 'redirectUrl'
  | 'includeDataInRedirectUrl'
  | 'rescheduleNotice'
  | 'sendCancellationEmail'
  | 'sendConfirmationEmail'
  | 'slug'
  | 'tags'
  | 'timezone'
  | 'useCalendarInvitations'
  | 'usesLocalTimezone'
> & {
  conferencingProvider: Maybe<Pick<ConferenceProvider, 'id' | 'slug'>>
  fields: Array<
    Pick<
      FormField,
      | '__typename'
      | 'choices'
      | 'fieldType'
      | 'helpText'
      | 'id'
      | 'name'
      | 'order'
      | 'required'
      | 'slug'
      | 'visible'
    >
  >
  meetingTypeTeamMembers: Array<
    Pick<
      MeetingTypeTeamMember,
      | '__typename'
      | 'active'
      | 'customAvailability'
      | 'id'
      | 'order'
      | 'priority'
      | 'willHost'
    > & {
      schedules: Array<Schedule>
      teamMember: Pick<TeamMember, 'id'> & {
        member: Pick<Member, 'id' | 'invitationAccepted'> & {
          calendarAccounts: Array<
            Pick<CalendarAccount, 'id'> & {
              provider: Pick<CalendarProvider, 'id' | 'slug'>
            }
          >
          conferencingAccounts: Array<
            Pick<ConferencingAccount, 'id' | 'deleted'> & {
              provider: Pick<ConferenceProvider, 'id' | 'slug'>
            }
          >
          deleted: Maybe<DateTime>
          user: Pick<
            User,
            'email' | 'firstName' | 'id' | 'image' | 'lastName' | 'timezone'
          >
        }
      }
    }
  >
  paymentCollectionProvider: Maybe<
    Pick<PaymentCollectionProvider, 'id' | 'slug'>
  >
  reminders: Array<
    Pick<Reminder, '__typename' | 'emailBody' | 'emailSubject' | 'leadTime'>
  >
  schedules: Array<Schedule>
  team: Pick<
    Team,
    'bookingUrl' | 'description' | 'id' | 'image' | 'name' | 'slug'
  >
} & {
  // These are added properties and do not live on
  // the MeetingTypeNode.
  meetingTypeStatus: MeetingTypeVisibility
  phoneLocation: Maybe<string>
  placeLocation: Maybe<string>
}

export type IFormField = IMeetingType['fields'][0]
export type IMeetingTypeTeamMember = IMeetingType['meetingTypeTeamMembers'][0]
export type IReminder = IMeetingType['reminders'][0]
export type ISchedule = IMeetingType['schedules'][0]

const UpdateMeetingTypeContext = React.createContext<IMeetingType>(
  {} as IMeetingType
)

UpdateMeetingTypeContext.displayName = 'UpdateMeetingTypeContext'

export default UpdateMeetingTypeContext
