import React from 'react'
import type { BsPrefixRefForwardingComponent } from 'react-bootstrap/esm/helpers'
interface Props {
  as?: React.ElementType
}

const Translate: BsPrefixRefForwardingComponent<'span', Props> =
  React.forwardRef<HTMLElement, Props>((props, ref) => {
    const { as: Component = 'span', children, ...rest } = props

    // This className lives in the LocalizeJS dashboard as the className
    // Localize should scan the DOM for. All content between this node will
    // be scheduled for translation.
    // https://developers.localizejs.com/docs/library-api#translateclasses
    return (
      <Component className="appointlet-translate" ref={ref} {...rest}>
        {children}
      </Component>
    )
  })

export default Translate
