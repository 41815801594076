import * as React from 'react'
import Tour from './Tour'
import { useViewedTours } from '../hooks/useViewedTours'
import MemberContext from './MemberContext'
import UserContext from './UserContext'
import Translate from './Translate'

type Props = Readonly<{
  hasMeetingTypes: boolean
}>

const PostSetupWizardTour: React.FC<Props> = ({ hasMeetingTypes }) => {
  // State for managing the tour on this page.
  const [viewedTours, addViewedTour] = useViewedTours()

  const { user } = React.useContext(UserContext)
  const member = React.useContext(MemberContext)

  // We only want to show the tour to users who signed up
  // in the last day.  This is a safe guard to ensure that
  // we don't show this tour to users who have been long-time
  // customers.  In the future we can relax this to 30-60 days
  // to ensure that all possible customers see it.
  const signedUpInLast7Days = React.useMemo(
    () => user.createdAt.fromNow().asDays(false) > -1,
    [user.createdAt]
  )

  // Don't render anything if this user shouldn't see the tour.
  if (!member.isOwner || !signedUpInLast7Days || viewedTours.postSetupWizard) {
    return null
  }

  return (
    <Tour
      name="postSetupWizard"
      delay={0}
      onCancel={() => {
        // Mark the tour as viewed.
        addViewedTour('postSetupWizard')
      }}
      onComplete={() => {
        // Mark the tour as viewed.
        addViewedTour('postSetupWizard')
      }}
      steps={[
        hasMeetingTypes
          ? {
              id: 'meetingTypeCard',
              selector: '.MeetingTypeCard',
              placement: 'auto',
              title: "Ta-da!  Here's your first meeting type.",
              content: (
                <div>
                  <p>
                    <Translate>
                      You've set up the basics, but now it's time to choose
                      where meetings will be located, customize your intake
                      form, and more!
                    </Translate>
                  </p>
                  <p>
                    <span>←</span>{' '}
                    <Translate>Click anywhere on this card to start.</Translate>
                  </p>
                </div>
              ),
              buttons: [],
            }
          : {
              id: 'newMeetingTypeButton',
              selector: '.MeetingTypesPage--AddMeetingType',
              placement: 'bottom-end',
              title: "Didn't create a meeting type?",
              content: (
                <div>
                  <p>
                    <Translate>
                      We really recommend you do! Without one there will be
                      nothing for your attendees to schedule.
                    </Translate>
                  </p>
                  <p>
                    <Translate>
                      As a reminder, meeting types are the kinds of meetings you
                      want your attendees to schedule with you.
                    </Translate>
                  </p>
                </div>
              ),
              buttons: [],
            },
      ]}
    />
  )
}
export default PostSetupWizardTour
