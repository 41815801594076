import * as React from 'react'
import { Card, Dropdown } from 'react-bootstrap'
import { truncate } from 'lodash'
import TooltipOverlay from './TooltipOverlay'
import './MeetingTypeCard.scss'
import Icon from './Icon'
import MoreButton from './MoreButton'
import PortraitList from './PortraitList'
import UserContext from './UserContext'
import { Currency, Maybe } from '../__generated__/graphql'
import { Analytics, Duration, formatMoney } from '../utils'
import ShareButton from './ShareButton'
import DropdownItem from './DropdownItem'
import Translate from './Translate'
import Var from './Var'

export type Props = {
  name: string
  description: Maybe<string>
  bookingUrl: string
  color: string
  duration: Duration
  isGroup: boolean
  maxAttendees: Maybe<number>
  price: Maybe<{
    amount: number
    currency: Currency
  }>

  members: Array<{
    firstName: string
    lastName: string
    image: Maybe<string>
    isSelf: boolean
  }>

  onClone?: () => void
  onDelete?: () => void
  onUpdate?: () => void
}

const MeetingTypeCard: React.FC<Props> = ({
  name,
  description,
  bookingUrl,
  color,
  duration,
  isGroup,
  maxAttendees,
  price,
  members,
  onClone,
  onDelete,
  onUpdate,
}) => {
  const { user } = React.useContext(UserContext)

  // only show the portraits if there is at least one that isn't the current member.
  const hasOneMemberBesidesSelf = React.useMemo(
    () => members.filter(member => !member.isSelf).length > 0,
    [members]
  )

  return (
    <Card className="MeetingTypeCard">
      <Card.Body
        onClick={onUpdate}
        className="p-16 d-flex flex-column space-between-8"
      >
        <Card.Title
          className="align-items-center d-flex space-between-4"
          as="h4"
        >
          <div
            className="MeetingTypeCard--color"
            style={{ backgroundColor: color }}
          />

          <span className="flex-grow-1 text-truncate">{name}</span>

          <TooltipOverlay
            id={`MeetingTypeCard-view-live-${bookingUrl}`}
            text={<Translate>View Live Page</Translate>}
          >
            <a
              href={bookingUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="MeetingTypeCard--link"
              onClick={ev => {
                // Make sure that click doesn't propagate
                ev.stopPropagation()

                Analytics.trackEvent('Preview Scheduling Page', {
                  source: 'meeting-types-page-meeting-type-card',
                })
              }}
            >
              <Icon.ExternalLink size={18} />
            </a>
          </TooltipOverlay>
        </Card.Title>

        <div className="d-flex space-between-8 text-muted">
          <span className=" align-items-center d-flex">
            <Icon.Clock className="mr-4 text-gray-500" size={16} />
            {duration.toHuman()}
          </span>
          <span className=" align-items-center d-flex space-between-8">
            {isGroup && (
              <span className="align-items-center d-flex">
                <Icon.Users className="mr-4 text-gray-500" size={16} />
                <Translate>
                  <Var name="count">1-{maxAttendees}</Var> attendees
                </Translate>
              </span>
            )}
            {!isGroup && (
              <span className="align-items-center d-flex">
                <Icon.User className="mr-4 text-gray-500" size={16} />
                <Translate>One-on-One</Translate>
              </span>
            )}
          </span>
        </div>
        {price && price.amount > 0 && (
          <span className="align-items-center d-flex text-muted">
            <Icon.CreditCard className="mr-4 text-gray-500" size="16" />
            {formatMoney(price.amount, price.currency!, user.language)}
          </span>
        )}

        {description && (
          <p className="text-muted MeetingTypeCard--description">
            {truncate(description, { length: 90 })}
          </p>
        )}

        {hasOneMemberBesidesSelf && (
          <div className="MeetingTypeCard--team-members">
            <PortraitList max={5} portraits={members} size={32} />
          </div>
        )}
      </Card.Body>

      <Card.Footer className="d-flex px-16 py-12">
        <div className="align-items-center d-flex justify-content-between space-between-8 w-100">
          <ShareButton
            url={bookingUrl}
            label={name}
            size="sm"
            analyticsSource="meeting-types-page-meeting-type-card"
          />

          <MoreButton alignRight size="sm">
            <DropdownItem onClick={onUpdate}>
              <Icon.Edit3 size={20} />
              <Translate>Edit</Translate>
            </DropdownItem>

            {/*
            // @ts-ignore */}
            <DropdownItem as="a" href={bookingUrl} target="_blank">
              <Icon.ExternalLink size={20} />
              <Translate>View Live Page</Translate>
            </DropdownItem>

            <DropdownItem onClick={onClone}>
              <Icon.Copy size={20} />
              <Translate>Duplicate</Translate>
            </DropdownItem>

            <Dropdown.Divider />

            <DropdownItem onClick={onDelete}>
              <Icon.Trash2 size={20} />
              <Translate>Delete</Translate>
            </DropdownItem>
          </MoreButton>
        </div>
      </Card.Footer>
    </Card>
  )
}

export default MeetingTypeCard
