import * as React from 'react'
import { Dropdown, Media, Button } from 'react-bootstrap'

import './CalendarAccountItem.scss'
import DropdownItem from './DropdownItem'
import Icon from './Icon'
import { DateTime } from '../utils'
import MoreButton from './MoreButton'

type Props = {
  name: string
  lastSyncedAt: DateTime | null
  isConnected: boolean
  onDelete: () => void
  onReconnect: () => void
}

const CalendarAccountItem: React.FC<Props> = ({
  name,
  lastSyncedAt,
  isConnected,
  onDelete,
  onReconnect,
}) => (
  <Media className="align-items-center space-between-12">
    {isConnected && <Icon.InternalAsset assetName={'Green-Check'} size={24} />}
    {!isConnected && <Icon.InternalAsset assetName={'Red-X'} size={24} />}

    <Media.Body>
      <span>{name}</span>
    </Media.Body>

    {!isConnected && (
      <Button onClick={onReconnect} variant="outline-danger">
        <Icon.RefreshCw size={16} />
        Reconnect
      </Button>
    )}

    <MoreButton alignRight>
      <DropdownItem onClick={onReconnect}>
        <Icon.RefreshCw size={16} />
        Reconnect
      </DropdownItem>
      <Dropdown.Divider />
      <DropdownItem onClick={onDelete}>
        <Icon.Trash2 size={16} />
        Disconnect
      </DropdownItem>
    </MoreButton>
  </Media>
)

export default CalendarAccountItem
