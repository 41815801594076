import * as React from 'react'
import { truncate } from 'lodash'

import './CalendarBadge.scss'
import ColoredDot from './ColoredDot'

export type Props = {
  name: string
  color: string | null
}

const CalendarBadge: React.FC<Props> = ({ name, color }) => (
  <div className="CalendarBadge d-inline-flex rounded-pill px-8 align-items-center space-between-4">
    {color && <ColoredDot color={color} size={10} />}
    <strong>{truncate(name, { length: 30 })}</strong>
  </div>
)

export default CalendarBadge
