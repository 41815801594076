import * as React from 'react'
import MeetingDetailDrawerHeader from '../MeetingDetailDrawerHeader/MeetingDetailDrawerHeader'
import {
  MeetingDetailSummary,
  MeetingDetailSummaryProps,
} from '../MeetingDetailSummary/MeetingDetailSummary'
import MeetingDetailView, {
  Props as MeetingDetailViewProps,
} from '../MeetingDetailView/MeetingDetailView'
import { Props as MeetingDetailDrawerHeaderProps } from '../MeetingDetailDrawerHeader/MeetingDetailDrawerHeader'

export interface PureMeetingDetailDrawerProps {
  readonly topBarMeta: MeetingDetailDrawerHeaderProps
  readonly summaryMeta: MeetingDetailSummaryProps
  readonly detailViewMeta: MeetingDetailViewProps
}

const PureMeetingDetailDrawer: React.FC<PureMeetingDetailDrawerProps> = ({
  topBarMeta,
  summaryMeta,
  detailViewMeta,
}) => (
  <>
    <div className="mb-12">
      <MeetingDetailDrawerHeader {...topBarMeta} />
    </div>

    <MeetingDetailSummary {...summaryMeta} />

    <div className="mt-24">
      <MeetingDetailView
        attendeesMeta={detailViewMeta.attendeesMeta}
        notesMeta={detailViewMeta.notesMeta}
      />
    </div>
  </>
)

export default PureMeetingDetailDrawer
