import { Maybe } from '../__generated__/graphql'
import { Webhook } from '../types'
import { DateTime } from './datetime'

type SoftDelete = {
  deleted: Maybe<DateTime | string>
}

export function isNotSoftDeleted<T extends SoftDelete>(el: T) {
  return !el.deleted
}

export function isNotZapierHook(webhook: Pick<Webhook, 'url'>) {
  return !webhook.url.includes('https://hooks.zapier.com/hooks/standard/')
}
