import { gql } from '@apollo/client'

export const MeetingTypeFragment = gql`
  fragment MeetingType on MeetingTypeNode {
    active
    allowCancellations
    allowReschedules
    autoConfirm
    bookingUrl
    bufferAfter
    bufferBefore
    cancellationEmailBody
    cancellationEmailSubject
    cancellationNotice
    color
    conferencingProvider {
      id
      name
      slug
    }
    confirmationEmailBody
    confirmationEmailSubject
    deleted
    description
    duration
    fields {
      edges {
        node {
          id
          choices
          fieldType
          helpText
          name
          order
          required
          visible
          slug
        }
      }
    }
    frequency
    hostAssignmentStrategy
    id
    image
    instructions
    isGroup
    location
    locationType
    maxAttendees
    maxBookingsPerDay
    maximumNotice
    meetingTypeTeamMembers {
      edges {
        node {
          active
          customAvailability
          id
          order
          priority
          schedules {
            edges {
              node {
                bounds {
                  lower
                  upper
                }
                kind
                name
                order
                timezone
                timeBlocks {
                  edges {
                    node {
                      date
                      endTime
                      frequency
                      startTime
                      weekday
                    }
                  }
                }
              }
            }
          }
          teamMember {
            id
            member {
              calendarAccounts {
                edges {
                  node {
                    id
                    provider {
                      id
                      slug
                    }
                  }
                }
              }
              conferencingAccounts {
                edges {
                  node {
                    id
                    deleted
                    provider {
                      id
                      slug
                    }
                  }
                }
              }
              deleted
              id
              invitationAccepted
              user {
                email
                firstName
                id
                image
                lastName
                timezone
              }
            }
          }
          willHost
        }
      }
    }
    minimumNotice
    name
    paymentCollectionProvider {
      id
      slug
    }
    price
    priceCurrency
    priceFormatted
    public
    redirectUrl
    includeDataInRedirectUrl
    reminders {
      edges {
        node {
          emailBody
          emailSubject
          leadTime
        }
      }
    }
    rescheduleNotice
    schedules {
      edges {
        node {
          bounds {
            lower
            upper
          }
          kind
          name
          order
          timezone
          timeBlocks {
            edges {
              node {
                date
                endTime
                frequency
                startTime
                weekday
              }
            }
          }
        }
      }
    }
    sendCancellationEmail
    sendConfirmationEmail
    slug
    tags
    team {
      bookingUrl
      description
      id
      image
      name
      slug
      teamMembers {
        edges {
          node {
            id
          }
        }
      }
    }
    timezone
    useCalendarInvitations
    usesLocalTimezone
  }
`
