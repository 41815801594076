import * as React from 'react'
import { Form } from 'react-bootstrap'

import FormFieldControl from './FormFieldControl'
import { FormField, FormFieldType } from '../types'
import { DateTime, expandClassName } from '../utils'

type Props = React.HTMLProps<HTMLDivElement> & {
  field: Pick<
    FormField,
    'choices' | 'fieldType' | 'helpText' | 'name' | 'required'
  >
}

const FormBuilderFieldPreview: React.FC<Props> = ({
  className,
  field,
  ...rest
}) => {
  return (
    <div
      className={expandClassName(
        'tw-break-words tw-bg-white tw-rounded-[6.4px] tw-flex tw-flex-1 tw-mb-2 tw-p-6 tw-relative tw-outline-none',
        className
      )}
      {...rest}
    >
      {/* Makes it so the field isn't click-able */}
      <div className="tw-inset-0 tw-absolute tw-cursor-pointer tw-z-[2]" />

      <Form.Group className="tw-flex-1">
        {field.name && (
          <Form.Label>
            {field.name}{' '}
            {!field.required && (
              <span className="tw-text-bsGray-500">Optional</span>
            )}
          </Form.Label>
        )}
        <FormFieldControl
          field={field}
          value={
            field.fieldType === FormFieldType.Date
              ? `"${new DateTime().toISO()}"`
              : undefined
          }
        />
        {field.fieldType !== FormFieldType.Toggle && (
          <Form.Text className="tw-break-words">{field.helpText}</Form.Text>
        )}
      </Form.Group>
    </div>
  )
}

export default FormBuilderFieldPreview
