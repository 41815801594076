import * as React from 'react'
import { Button } from 'react-bootstrap'
import { DateTime } from '../../../utils'
import Icon from '../../Icon'
import './TimeZoneBanner.scss'

export interface TimeZoneBannerProps {
  readonly currentTimeZone: string
  readonly displayLabel: string
  readonly onChange: () => void
}

export const TimeZoneBanner: React.FC<TimeZoneBannerProps> = ({
  currentTimeZone,
  displayLabel,
  onChange,
}: TimeZoneBannerProps) => {
  const timeZoneLabel = displayLabel.replace(/[#_]/g, ' ')
  const dt = new DateTime().setZone(currentTimeZone)

  return (
    <div className="DisplayGroup d-flex flex-row justify-content-between align-items-center px-12">
      <div className="w-100 d-flex flex-row align-items-center">
        <div className="GlobeIcon mr-8">
          <Icon.Globe size="24" />
        </div>
        <div className="w-100 d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-wrap">
            <span>
              <span>We think you're in </span>
              <span className="font-weight-bold">
                {currentTimeZone && `${timeZoneLabel}`}&nbsp;
              </span>
              <span>
                {currentTimeZone &&
                  `(currently ${dt
                    .format('localized-time')
                    .toLocaleLowerCase()})`}
              </span>
            </span>
          </div>

          <span>
            <Button variant="link" onClick={onChange}>
              Change
            </Button>
          </span>
        </div>
      </div>
    </div>
  )
}
