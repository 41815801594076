import * as React from 'react'
import { DateTime } from '../../../../utils'

import DropdownItem from '../../../DropdownItem'
import Icon from '../../../Icon'
import MoreButton from '../../../MoreButton'
import Portrait from '../../../Portrait'
import { NotesTextInput } from '../NotesTextInput/NotesTextInput'
import { RenderRichText } from '../RenderRichText/RenderRichText'
import './MeetingDetailNoteUnit.scss'

export type AttendeeNote = {
  readonly userId: string
  readonly id: string
  readonly email: string
  readonly image: string
  readonly firstName: string
  readonly lastName: string
  readonly time: DateTime
  readonly note: string
  readonly isLoggedInUser?: boolean
}

export interface MeetingDetailNoteUnitProps {
  readonly attendeeNote: AttendeeNote
  readonly editCallback: (note: AttendeeNote) => void
  readonly deleteCallback: (id: string) => void
}

const MeetingDetailNoteUnit: React.FC<MeetingDetailNoteUnitProps> = ({
  attendeeNote,
  editCallback,
  deleteCallback,
}: MeetingDetailNoteUnitProps) => {
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [currentNote, setCurrentNote] =
    React.useState<AttendeeNote>(attendeeNote)
  const handleUpdate = (note: string) => {
    setCurrentNote({
      ...currentNote,
      note: note,
    })
    editCallback({ ...currentNote, note: note })
  }
  const title = currentNote.isLoggedInUser
    ? 'You'
    : `${currentNote.firstName} ${currentNote.lastName}`
  return (
    <div>
      <div className="MeetingDetailNoteUnit d-flex flex-row align-items-center justify-content-between">
        <div className="d-flex flex-row align-items-center">
          <Portrait {...currentNote} hideToolTip size={24} />
          <div className="d-flex flex-row">
            <strong className="d-flex text-gray-900 mx-4">{title}</strong>
            <div className="time-container d-flex align-items-end text-gray-600">
              {currentNote.time.toRelative()}
            </div>
          </div>
        </div>
        {!!attendeeNote.isLoggedInUser && (
          <MoreButton size="sm">
            <DropdownItem
              className="text-gray-900"
              onClick={() => setIsEditing(true)}
            >
              <Icon.Edit className="icon" size={20} /> Edit
            </DropdownItem>
            <DropdownItem onClick={() => deleteCallback(currentNote.id)}>
              <Icon.Trash2 className="icon" size={20} />
              Delete
            </DropdownItem>
          </MoreButton>
        )}
      </div>
      <div className="text-gray-700">
        {!isEditing && <RenderRichText content={currentNote.note} />}
        {!!isEditing && (
          <NotesTextInput
            renderExpanded
            value={currentNote.note}
            onSubmit={note => {
              handleUpdate(note || '')
              setIsEditing(false)
            }}
            inlineCloseCallback={() => setIsEditing(false)}
          />
        )}
      </div>
    </div>
  )
}

export default MeetingDetailNoteUnit
