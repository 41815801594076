import * as React from 'react'

import TimeBlockTableRow from './TimeBlockTableRow'
import { TimeBlock, ScheduleKind, Weekday } from '../types'
import { DateTimeRange, DateTime, Duration } from '../utils'

const orderedWeekdays: Array<[string, Weekday]> = [
  ['Monday', Weekday.Monday],
  ['Tuesday', Weekday.Tuesday],
  ['Wednesday', Weekday.Wednesday],
  ['Thursday', Weekday.Thursday],
  ['Friday', Weekday.Friday],
  ['Saturday', Weekday.Saturday],
  ['Sunday', Weekday.Sunday],
]

interface Props {
  scheduleBounds: DateTimeRange | null
  scheduleKind: ScheduleKind
  minimumTimeBlockDuration?: Duration
  value: TimeBlock[]
  onChange: (timeBlocks: TimeBlock[]) => void
  hideWeekends?: boolean
}

export const RecurringTimeBlockTable: React.FC<Props> = ({
  value,
  onChange,
  minimumTimeBlockDuration,
  hideWeekends = false,
}) => {
  const filteredWeekdays = orderedWeekdays.filter(([_, weekday]) =>
    hideWeekends ? ![Weekday.Saturday, Weekday.Sunday].includes(weekday) : true
  )

  return (
    <div className="tw-bg-white tw-border tw-border-solid tw-border-black/[.125] tw-rounded-md">
      {filteredWeekdays.map(([_, weekday]) => (
        <TimeBlockTableRow
          key={weekday}
          date={null}
          allDates={null}
          weekday={weekday}
          minimumTimeBlockDuration={minimumTimeBlockDuration}
          value={value.filter(timeBlock => timeBlock.weekday! === weekday)}
          onChange={(timeBlocks, copyTo) =>
            onChange(
              // Create a list of weekdays that need to have the timeBlocks added to them,
              // then reduce over the list, removing timeBlocks from those days and adding
              // in the new ones.
              [weekday, ...copyTo].reduce(
                (existingTimeBlocks, weekday) => [
                  // Remove the timeBlocks that match the weekday
                  ...existingTimeBlocks.filter(
                    timeBlock => timeBlock.weekday! !== weekday
                  ),
                  // Add in the new ones
                  ...timeBlocks.map(tb => ({
                    ...tb,
                    // Make sure we imprint the correct weekday
                    weekday: weekday as Weekday,
                  })),
                ],
                // Pass the existing time blocks in as a starting point
                value
              )
            )
          }
        />
      ))}
    </div>
  )
}

export const FixedDatesTimeBlockTable: React.FC<Props> = ({
  value,
  onChange,
  scheduleBounds,
  minimumTimeBlockDuration,
}) => {
  const timeBlocksForDate = (date: DateTime) =>
    value.filter(timeBlock => date.isSame(timeBlock.date!))

  return (
    <div className="tw-bg-white tw-border tw-border-solid tw-border-black/[.125] tw-rounded-md">
      {scheduleBounds &&
        scheduleBounds.createDateTimeList({ days: 1 }).map(date => (
          <TimeBlockTableRow
            key={date.toISO()}
            date={date}
            allDates={scheduleBounds.createDateTimeList({ days: 1 })}
            minimumTimeBlockDuration={minimumTimeBlockDuration}
            weekday={null}
            value={timeBlocksForDate(date)}
            onChange={(updatedTimeBlocks, copyTo) =>
              onChange(
                [date, ...copyTo].reduce(
                  (existingTimeBlocks, date) => [
                    ...existingTimeBlocks.filter(
                      existingTimeBlock =>
                        !existingTimeBlock.date?.isSame(date as DateTime)
                    ),
                    ...updatedTimeBlocks.map(updatedTimeBlock => ({
                      ...updatedTimeBlock,
                      date: date as DateTime,
                    })),
                  ],
                  value
                )
              )
            }
          />
        ))}
    </div>
  )
}

const TimeBlockTable: React.FC<Props> = props => (
  <>
    {props.scheduleKind === ScheduleKind.Recurring && (
      <RecurringTimeBlockTable {...props} />
    )}
    {props.scheduleKind === ScheduleKind.FixedRange && (
      <FixedDatesTimeBlockTable {...props} />
    )}
  </>
)

export default TimeBlockTable
