import * as React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { IconProps } from 'react-feather'

import './SettingsModal.scss'
import Icon, { ZapierIconProps } from './Icon'
import MemberContext from './MemberContext'
import PermissionsFlag from './PermissionsFlag'
import SettingsModalBillingSection from './SettingsModalBillingSection'
import SettingsModalCalendarSection from './SettingsModalCalendarSection'
import SettingsModalGeneralSection from './SettingsModalGeneralSection'
import SettingsModalLoginSection from './SettingsModalLoginSection'
import SettingsModalPaymentsSection from './SettingsModalPaymentsSection'
import SettingsModalProfileSection from './SettingsModalProfileSection'
import SettingsModalUsersSection from './SettingsModalUsersSection'
import SettingsModalWebConferencingSection from './SettingsModalWebConferencingSection'
import SettingsModalWebhooksSection from './SettingsModalWebhooksSection'
import TabbedModal, {
  TabbedModalNavigation,
  TabbedModalContent,
} from './TabbedModal'
import SettingsModalZapierSection from './SettingsModalZapierSection'
import Translate from './Translate'
import { Analytics, expandClassName } from '../utils'
import { useToggleIntercom } from '../hooks/useToggleIntercom'

export type SettingsSection =
  | 'billing'
  | 'calendars'
  | 'general'
  | 'login'
  | 'payments'
  | 'profile'
  | 'resources'
  | 'users'
  | 'web-conferencing'
  | 'webhooks'
  | 'zapier'

type PickerItem = {
  description: string
  Icon: React.FC<IconProps> | React.FC<ZapierIconProps>
  permission?: 'canManageBilling' | 'canManageMembers' | 'canManageOrgSettings'
  sectionName: string
  value: SettingsSection
}

type PickerSection = {
  divider?: boolean
  heading: string
  items: Array<PickerItem>
}

export const settingsMenuOptions: Array<PickerSection> = [
  {
    divider: true,
    heading: 'Me',
    items: [
      {
        description: 'Manage your account information.',
        Icon: Icon.User,
        sectionName: 'My Profile',
        value: 'profile',
      },
      {
        description: 'Set your security preferences.',
        Icon: Icon.Lock,
        sectionName: 'Login & Password',
        value: 'login',
      },
    ],
  },
  {
    divider: true,
    heading: 'Account',
    items: [
      {
        description: 'Customize your workspace.',
        Icon: Icon.Settings,
        permission: 'canManageOrgSettings',
        sectionName: 'General Settings',
        value: 'general',
      },
      {
        description: 'Invite other members of your company/team.',
        Icon: Icon.Users,
        permission: 'canManageMembers',
        sectionName: 'Members',
        value: 'users',
      },
      {
        description: 'Manage your payment preferences.',
        Icon: Icon.CreditCard,
        permission: 'canManageBilling',
        sectionName: 'Billing & Invoices',
        value: 'billing',
      },
    ],
  },
  {
    heading: 'Integrations',
    items: [
      {
        description: 'Connect your calendar to sync meetings.',
        Icon: Icon.Calendar,
        sectionName: 'Calendar Sync',
        value: 'calendars',
      },
      {
        description: 'Connect your preferred tool for virtual meetings.',
        Icon: Icon.Monitor,
        sectionName: 'Web Conferencing',
        value: 'web-conferencing',
      },
      {
        description: 'Start collecting payments when users book.',
        Icon: Icon.ShoppingCart,
        sectionName: 'Payment Collection',
        value: 'payments',
      },
      {
        description: 'Connect Appointlet to more applications.',
        Icon: Icon.Zapier,
        sectionName: 'Zapier',
        value: 'zapier',
      },
      {
        description: 'Trigger custom actions from Appointlet.',
        Icon: Icon.Radio,
        sectionName: 'Webhooks',
        value: 'webhooks',
      },
    ],
  },
]

interface Props {
  initialSection?: SettingsSection
  onHide: () => void
}

const SettingsModal: React.FC<Props> = ({ initialSection, onHide }) => {
  useToggleIntercom()
  const [activeTab, setActiveTab] = React.useState<SettingsSection>(
    initialSection || 'profile'
  )
  const { role } = React.useContext(MemberContext)

  React.useEffect(() => {
    Analytics.trackEvent('Settings Modal: Opened')
  }, [])

  const onNavigateToNewTab = React.useCallback(
    (section: SettingsSection) => {
      if (activeTab === section) return
      setActiveTab(section)
      Analytics.trackEvent(`Settings Modal: Loaded Section - ${section}`, {
        tab: section,
      })
    },
    [activeTab]
  )

  return (
    <TabbedModal
      className="SettingsModal"
      initialTab={activeTab}
      activeKey={activeTab}
      onHide={onHide}
    >
      <TabbedModalNavigation>
        {settingsMenuOptions.map(({ heading, items }) => (
          <React.Fragment key={heading}>
            <h6
              className={expandClassName(
                'tw-text-bsGray-500 tw-mb-2 tw-mt-6 first:tw-mt-0',
                heading === 'Account'
                  ? 'tw-block'
                  : role.canManageBilling ||
                    role.canManageMembers ||
                    role.canManageOrgSettings
                  ? 'tw-block'
                  : 'tw-hidden'
              )}
            >
              <Translate>{heading}</Translate>
            </h6>
            {items.map(({ Icon, permission, sectionName, value }) =>
              permission ? (
                <PermissionsFlag key={value} permission={permission}>
                  <Nav.Item onClick={() => onNavigateToNewTab(value)}>
                    <Nav.Link
                      className="tw-font-normal tw-space-x-2"
                      eventKey={value}
                    >
                      <Icon className="tw-mr-0 tw-h-5 tw-w-5" />
                      <Translate>{sectionName}</Translate>
                    </Nav.Link>
                  </Nav.Item>
                </PermissionsFlag>
              ) : (
                <Nav.Item key={value} onClick={() => onNavigateToNewTab(value)}>
                  <Nav.Link
                    className="tw-font-normal tw-space-x-2"
                    eventKey={value}
                  >
                    <Icon className="tw-mr-0 tw-h-5 tw-w-5" />
                    <Translate>{sectionName}</Translate>
                  </Nav.Link>
                </Nav.Item>
              )
            )}
          </React.Fragment>
        ))}
      </TabbedModalNavigation>

      <TabbedModalContent>
        <Tab.Pane eventKey="billing">
          <SettingsModalBillingSection
            onSelect={section => setActiveTab(section)}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="login">
          <SettingsModalLoginSection
            onSelect={section => setActiveTab(section)}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="calendars">
          <SettingsModalCalendarSection
            onSelect={section => setActiveTab(section)}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="general">
          <SettingsModalGeneralSection
            onSelect={section => setActiveTab(section)}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="payments">
          <SettingsModalPaymentsSection
            onSelect={section => setActiveTab(section)}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="profile">
          <SettingsModalProfileSection
            onSelect={section => setActiveTab(section)}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="users">
          <SettingsModalUsersSection
            onSelect={section => setActiveTab(section)}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="web-conferencing">
          <SettingsModalWebConferencingSection
            onSelect={section => setActiveTab(section)}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="webhooks">
          <SettingsModalWebhooksSection
            onSelect={section => setActiveTab(section)}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="zapier">
          <SettingsModalZapierSection
            onSelect={section => setActiveTab(section)}
          />
        </Tab.Pane>
      </TabbedModalContent>
    </TabbedModal>
  )
}

export default SettingsModal
