import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, Media, Modal } from 'react-bootstrap'

import AvailabilityInput from './AvailabilityInput'
import Icon from './Icon'
import MeetingTypeTimezonePopover from './MeetingTypeTimezonePopover'
import MeetingTypeFrequencyPopover from './MeetingTypeFrequencyPopover'
import TimeZoneLabel from './TimeZoneLabel'
import { IMeetingType } from './UpdateMeetingTypeContext'
import {
  composeValidators,
  Duration,
  isListOfSchedulesWithTimeBlocksWithMinimumDuration,
  isListOfSchedulesWithTimeBlocksWithNoOverlappingRanges,
  isListOfSchedulesWithTimeBlocksWithValidTimeRanges,
} from '../utils'
import Translate from './Translate'
import Var from './Var'
import MeetingTypeEditorSectionPicker from './MeetingTypeEditorSectionPicker'
import { MTESection } from './UpdateMeetingTypeModal'

type Props = {
  onSelect: (section: MTESection) => void
}

const UpdateMeetingTypeModalAvailabilitySection: React.FC<Props> = ({
  onSelect,
}) => {
  return (
    <div className="UpdateMeetingTypeModalAvailabilitySection tw-w-full">
      <Modal.Header closeButton>
        <Icon.InternalAsset
          assetName="icn-mte-header-availability"
          className="tw-hidden lg:tw-block"
        />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title>
            <Translate>Availability</Translate>
          </Modal.Title>
          <Translate as="p">Set up rules on when you can be booked</Translate>
        </div>
        <MeetingTypeEditorSectionPicker
          onChange={onSelect}
          value="availability"
        />
      </Modal.Header>
      <Modal.Body>
        <div className="tw-space-y-6">
          <Form.Group className="tw-mb-0">
            <FinalForm.Field<Duration> name="duration">
              {({ input: { value: duration } }) => (
                <FinalForm.Field<IMeetingType['schedules']>
                  name="schedules"
                  validate={composeValidators(
                    isListOfSchedulesWithTimeBlocksWithNoOverlappingRanges(),
                    isListOfSchedulesWithTimeBlocksWithValidTimeRanges(),
                    isListOfSchedulesWithTimeBlocksWithMinimumDuration(duration)
                  )}
                >
                  {({ input }) => (
                    <AvailabilityInput
                      minimumTimeBlockDuration={duration}
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )}
                </FinalForm.Field>
              )}
            </FinalForm.Field>
          </Form.Group>

          <FinalForm.Field<IMeetingType['frequency']> name="frequency">
            {({ input }) => (
              <Media className="tw-items-center tw-space-x-2">
                <Icon.Loader className="tw-text-bsSuccess" size="24" />
                <Media.Body>
                  <FinalForm.Field<IMeetingType['duration']> name="duration">
                    {({ input: { value: duration } }) => (
                      <>
                        <Translate>
                          Time slots will be offered{' '}
                          <strong>
                            every{' '}
                            <Var name="frequency">
                              {(input.value || duration).toHuman()}
                            </Var>
                          </strong>
                          .
                        </Translate>

                        <MeetingTypeFrequencyPopover
                          onChange={input.onChange}
                          value={input.value}
                          duration={duration}
                        />
                      </>
                    )}
                  </FinalForm.Field>
                </Media.Body>
              </Media>
            )}
          </FinalForm.Field>

          <FinalForm.Field<IMeetingType['timezone']> name="timezone">
            {({ input }) => (
              <Media className="tw-items-center tw-space-x-2">
                <Icon.Globe className="tw-text-bsSuccess" size="24" />
                <Media.Body>
                  <Translate>
                    When scheduling, users will see these times in{' '}
                    <strong>
                      {input.value ? (
                        <Var name="timezone">
                          <TimeZoneLabel timezone={input.value} />
                        </Var>
                      ) : (
                        'their local timezone'
                      )}
                    </strong>
                    .
                  </Translate>
                  <MeetingTypeTimezonePopover
                    onChange={input.onChange}
                    value={input.value}
                  />
                </Media.Body>
              </Media>
            )}
          </FinalForm.Field>
        </div>
      </Modal.Body>
    </div>
  )
}

export default UpdateMeetingTypeModalAvailabilitySection
