import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, Media, Modal } from 'react-bootstrap'

import './UpdateMeetingTypeModalSchedulingRulesSection.scss'
import Icon from './Icon'
import DurationInput from './DurationInput'
import FormErrorSubscription from './FormErrorSubscription'
import PolicyPicker from './PolicyPicker'
import RemoveButton from './RemoveButton'
import Toggler from './Toggler'
import UpdateMeetingTypeContext, {
  IMeetingType,
} from './UpdateMeetingTypeContext'
import { Duration } from '../utils'
import Translate from './Translate'
import Var from './Var'
import { MTESection } from './UpdateMeetingTypeModal'
import MeetingTypeEditorSectionPicker from './MeetingTypeEditorSectionPicker'

type Props = {
  onSelect: (section: MTESection) => void
}

const DEFAULTS = {
  attendees: 10,
  buffers: new Duration({ minutes: 5 }),
  meetings: 5,
}

const UpdateMeetingTypeModalSchedulingRulesSection: React.FC<Props> = ({
  onSelect,
}) => {
  const meetingType = React.useContext(UpdateMeetingTypeContext)
  const [isNoticeInvalid, setIsNoticeInvalid] = React.useState<boolean>(false)
  return (
    <div className="UpdateMeetingTypeModalSchedulingRulesSection tw-w-full">
      <Modal.Header closeButton>
        <Icon.InternalAsset
          assetName="SchedulingRulesHeader"
          className="tw-hidden lg:tw-block"
        />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title>
            <Translate>Scheduling Rules</Translate>
          </Modal.Title>
          <Translate as="p">Set up limits for your availability</Translate>
        </div>
        <MeetingTypeEditorSectionPicker
          onChange={onSelect}
          value="scheduling"
        />
      </Modal.Header>
      <Modal.Body>
        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
          <Icon.InternalAsset
            assetName="Icon-Notice-1-Blue"
            className="tw-mr-4"
            size={50}
          />
          <Media.Body className="tw-flex-wrap">
            <Form.Group className="tw-flex-wrap">
              <Form.Label>
                <Translate>Required Notice</Translate>
              </Form.Label>
              <Form.Text className="tw-mb-2">
                <Translate>
                  Prevent users from booking you without enough notice or too
                  much notice.
                </Translate>
              </Form.Text>
              <FinalForm.Field<IMeetingType['minimumNotice']>
                name="minimumNotice"
                validate={(minimumNotice, allValues: any) => {
                  const isInvalid =
                    allValues.maximumNotice.asMilliseconds() <=
                    minimumNotice.asMilliseconds()
                  setIsNoticeInvalid(isInvalid)
                  return isInvalid
                    ? 'Maximum notice must be greater than minimum notice.'
                    : undefined
                }}
              >
                {({ input: { onChange, value } }) => (
                  <div className="tw-mb-2">
                    <Translate>
                      <Var name="minimumNotice">
                        <DurationInput
                          includeDays
                          onChange={onChange}
                          value={value}
                          isInvalid={isNoticeInvalid}
                        />
                      </Var>
                      <span className="tw-ml-2">minimum notice</span>
                    </Translate>
                  </div>
                )}
              </FinalForm.Field>

              <FinalForm.Field<
                IMeetingType['minimumNotice']
              > name="minimumNotice">
                {({ input: { value: minimumNotice } }) => (
                  <FinalForm.Field<IMeetingType['maximumNotice']>
                    name="maximumNotice"
                    validate={(maximumNotice, allValues: any) => {
                      const isInvalid =
                        allValues.minimumNotice.asMilliseconds() >=
                        maximumNotice.asMilliseconds()
                      setIsNoticeInvalid(isInvalid)
                      return isInvalid
                        ? 'Maximum notice must be greater than minimum notice.'
                        : undefined
                    }}
                  >
                    {({ input: { onChange, value: maximumNotice } }) => (
                      <React.Fragment>
                        <div>
                          <Translate>
                            <Var name="maximumNotice">
                              <DurationInput
                                includeDays
                                isInvalid={isNoticeInvalid}
                                onChange={onChange}
                                value={maximumNotice}
                              />
                            </Var>
                            <span className="tw-ml-2">maximum notice</span>
                          </Translate>
                        </div>
                        {maximumNotice.asMilliseconds() <=
                          minimumNotice.asMilliseconds() && (
                          <p className="tw-mt-2 tw-mb-0 tw-text-bsDanger">
                            <Translate>
                              Maximum notice must be greater than minimum
                              notice.
                            </Translate>
                          </p>
                        )}
                      </React.Fragment>
                    )}
                  </FinalForm.Field>
                )}
              </FinalForm.Field>
            </Form.Group>
          </Media.Body>
        </Media>

        <hr />

        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
          <Icon.InternalAsset
            assetName="Icon-Buffers-Blue"
            className="tw-mr-4"
            size={50}
          />
          <Media.Body>
            <Form.Group className="tw-flex-wrap">
              <Form.Label>
                <Translate>Buffers</Translate>
              </Form.Label>
              <Form.Text className="tw-mb-2">
                <Translate>
                  Prevent your meetings from running into each other by giving
                  yourself a break.
                </Translate>
              </Form.Text>
              <div className="tw-flex tw-flex-col tw-items-start tw-space-y-2">
                <FinalForm.Field<
                  IMeetingType['bufferBefore']
                > name="bufferBefore">
                  {({ input: { onChange, value } }) => (
                    <Toggler initial={!!value.asSeconds()}>
                      {({ isToggled, setOff, setOn }) => (
                        <React.Fragment>
                          {!isToggled && (
                            <Button
                              onClick={() => {
                                // Set the input to the desired default value before opening the option.
                                onChange(DEFAULTS.buffers)
                                setOn()
                              }}
                              variant="link"
                            >
                              + <Translate>Add break before</Translate>
                            </Button>
                          )}
                          {isToggled && (
                            <div className="tw-flex tw-items-center tw-space-x-2">
                              <Translate>
                                <Var name="bufferBefore">
                                  <DurationInput
                                    onChange={onChange}
                                    value={value}
                                  />
                                </Var>
                                <span className="tw-ml-2">before meeting</span>
                              </Translate>
                              <RemoveButton
                                onClick={() => {
                                  setOff()
                                  //  Reset the form value if the user exits the option.
                                  onChange(new Duration())
                                }}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </Toggler>
                  )}
                </FinalForm.Field>

                <FinalForm.Field<
                  IMeetingType['bufferAfter']
                > name="bufferAfter">
                  {({ input: { onChange, value } }) => (
                    <Toggler initial={!!value.asSeconds()}>
                      {({ isToggled, setOff, setOn }) => (
                        <React.Fragment>
                          {!isToggled && (
                            <Button
                              onClick={() => {
                                // Set the input to the desired default value before opening the option.
                                onChange(DEFAULTS.buffers)
                                setOn()
                              }}
                              variant="link"
                            >
                              + <Translate>Add break after</Translate>
                            </Button>
                          )}
                          {isToggled && (
                            <div className="tw-flex tw-items-center tw-space-x-2">
                              <Translate>
                                <Var name="bufferAfter">
                                  <DurationInput
                                    onChange={onChange}
                                    value={value}
                                  />
                                </Var>
                                <span className="tw-ml-2">after meeting</span>
                              </Translate>
                              <RemoveButton
                                onClick={() => {
                                  setOff()
                                  //  Reset the form value if the user exits the option.
                                  onChange(new Duration())
                                }}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </Toggler>
                  )}
                </FinalForm.Field>
              </div>
            </Form.Group>
          </Media.Body>
        </Media>

        <hr />

        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
          <Icon.InternalAsset
            assetName="Icon-Max-Blue"
            className="tw-mr-4"
            size={50}
          />
          <Media.Body>
            <Form.Group className="tw-flex-wrap">
              <Form.Label>
                <Translate>Maximum meetings per day</Translate>
              </Form.Label>
              <Form.Text className="tw-mb-2">
                <Translate>
                  Limit the number of meetings you can be booked for in one day.
                </Translate>
              </Form.Text>

              <FinalForm.Field<
                IMeetingType['maxBookingsPerDay']
              > name="maxBookingsPerDay">
                {({ input: { onChange, value, ...rest } }) => (
                  <Toggler initial={!!value}>
                    {({ isToggled, setOff, setOn }) => (
                      <React.Fragment>
                        {!isToggled && (
                          <Button
                            onClick={() => {
                              // Set default value on input.
                              onChange(DEFAULTS.meetings)
                              setOn()
                            }}
                            variant="link"
                          >
                            +{' '}
                            <Translate>
                              Add a maximum number of meetings per day
                            </Translate>
                          </Button>
                        )}
                        {isToggled && (
                          <div className="tw-flex tw-items-center tw-space-x-2">
                            <Translate>
                              Allow up to
                              <Var name="maxBookingsPerDay">
                                <div className="tw-mx-2">
                                  <Form.Control
                                    {...rest}
                                    onChange={onChange}
                                    style={{ width: '4rem' }}
                                    value={value || 0}
                                  />
                                </div>
                              </Var>
                              meetings per day
                            </Translate>
                            <RemoveButton
                              onClick={() => {
                                setOff()
                                //  Reset the form value if the user exits the option.
                                onChange(null)
                              }}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </Toggler>
                )}
              </FinalForm.Field>
            </Form.Group>
          </Media.Body>
        </Media>

        <hr />

        {meetingType.isGroup && (
          <>
            <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
              <Icon.InternalAsset
                assetName="Icon-Max-Attendees"
                className="tw-mr-4"
                size={50}
              />
              <Media.Body>
                <Form.Group className="tw-flex-wrap">
                  <Form.Label>
                    <Translate>Maximum attendees per meeting</Translate>
                  </Form.Label>
                  <Form.Text className="tw-mb-2">
                    <Translate>
                      Limit the number of people who can attend a single
                      meeting.
                    </Translate>
                  </Form.Text>

                  <FinalForm.Field<IMeetingType['maxAttendees']>
                    name="maxAttendees"
                    validate={attendees =>
                      attendees > 1
                        ? undefined
                        : 'Must have a minimum of 2 attendees'
                    }
                  >
                    {({ input: { onChange, value, ...rest } }) => (
                      <Toggler initial={!!value}>
                        {({ isToggled, setOff, setOn }) => (
                          <React.Fragment>
                            {!isToggled && (
                              <Button
                                onClick={() => {
                                  // Set default value on input.
                                  onChange(DEFAULTS.attendees)
                                  setOn()
                                }}
                                variant="link"
                              >
                                +{' '}
                                <Translate>
                                  Add a maximum number of attendees per meeting
                                </Translate>
                              </Button>
                            )}
                            {isToggled && (
                              <div className="tw-flex tw-items-center tw-space-y-2">
                                <Translate>
                                  Allow up to
                                  <Var name="maxAttendees">
                                    <div className="tw-mx-2">
                                      <Form.Control
                                        {...rest}
                                        onChange={onChange}
                                        style={{ width: '4rem' }}
                                        value={value}
                                      />
                                    </div>
                                  </Var>
                                  attendees per meeting
                                </Translate>
                                <RemoveButton
                                  onClick={() => {
                                    setOff()
                                    onChange(null)
                                  }}
                                />
                              </div>
                            )}
                            <FormErrorSubscription name="maxAttendees" />
                          </React.Fragment>
                        )}
                      </Toggler>
                    )}
                  </FinalForm.Field>
                </Form.Group>
              </Media.Body>
            </Media>

            <hr />
          </>
        )}
        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
          <Icon.InternalAsset
            assetName="Icon-Cancellations"
            className="tw-mr-4"
            size={50}
          />
          <Media.Body>
            <Form.Group className="tw-flex-wrap tw-mb-[14px]">
              <Form.Label>
                <Translate>Cancellation Policy</Translate>
              </Form.Label>
              <Form.Text>
                <Translate>
                  Whether or not users can cancel meetings they schedule.
                </Translate>
              </Form.Text>
            </Form.Group>
            <FinalForm.Field<
              IMeetingType['allowCancellations']
            > name="allowCancellations">
              {({
                input: {
                  onChange: onAllowCancellationChange,
                  value: allowCancellation,
                },
              }) => (
                <FinalForm.Field<
                  IMeetingType['cancellationNotice']
                > name="cancellationNotice">
                  {({
                    input: {
                      onChange: onCancellationNoticeChange,
                      value: cancellationNotice,
                    },
                  }) => (
                    // If cancellationNotice === 0 then this evaluates to false
                    // and the custom option is hidden.
                    // if cancellationNotice > 0 then this evaluates to true
                    // and the custom option is shown.
                    <Toggler initial={!!cancellationNotice.asSeconds()}>
                      {({ isToggled, setOff, setOn }) => (
                        <>
                          {!isToggled && (
                            <PolicyPicker
                              isCancellation
                              onChange={onAllowCancellationChange}
                              onCustomOptionChange={onCancellationNoticeChange}
                              onCustomOptionSelect={setOn}
                              value={
                                cancellationNotice.asSeconds() === 0
                                  ? allowCancellation
                                  : cancellationNotice
                              }
                            />
                          )}
                          {isToggled && (
                            <div className="tw-flex tw-items-center tw-space-y-2">
                              <Translate>
                                Up to
                                <Var name="duration">
                                  <div className="tw-mx-2">
                                    <DurationInput
                                      includeDays
                                      onChange={onCancellationNoticeChange}
                                      value={cancellationNotice}
                                    />
                                  </div>
                                </Var>
                                before meeting
                              </Translate>
                              <RemoveButton
                                onClick={() => {
                                  setOff()
                                  //  Reset the form value if the user exits the option.
                                  onCancellationNoticeChange(new Duration())
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </Toggler>
                  )}
                </FinalForm.Field>
              )}
            </FinalForm.Field>
          </Media.Body>
        </Media>

        <hr />

        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
          <Icon.InternalAsset
            assetName="Icon-Rescheduling"
            className="tw-mr-4"
            size={50}
          />
          <Media.Body>
            <Form.Group className="tw-flex-wrap tw-mb-[14px]">
              <Form.Label>
                <Translate>Rescheduling Policy</Translate>
              </Form.Label>
              <Form.Text>
                <Translate>
                  Whether or not users can reschedule meetings they book with
                  you.
                </Translate>
              </Form.Text>
            </Form.Group>
            <FinalForm.Field<
              IMeetingType['allowReschedules']
            > name="allowReschedules">
              {({
                input: {
                  onChange: onAllowReschedulesChange,
                  value: allowReschedules,
                },
              }) => (
                <FinalForm.Field<
                  IMeetingType['rescheduleNotice']
                > name="rescheduleNotice">
                  {({
                    input: {
                      onChange: onRescheduleNoticeChange,
                      value: rescheduleNotice,
                    },
                  }) => (
                    // If rescheduleNotice === 0 then this evaluates to false
                    // and the custom option is hidden.
                    // if rescheduleNotice > 0 then this evaluates to true
                    // and the custom option is shown.
                    <Toggler initial={!!rescheduleNotice.asSeconds()}>
                      {({ isToggled, setOff, setOn }) => (
                        <>
                          {!isToggled && (
                            <PolicyPicker
                              onChange={onAllowReschedulesChange}
                              onCustomOptionChange={onRescheduleNoticeChange}
                              onCustomOptionSelect={setOn}
                              value={
                                rescheduleNotice.asSeconds() === 0
                                  ? allowReschedules
                                  : rescheduleNotice
                              }
                            />
                          )}
                          {isToggled && (
                            <div className="tw-flex tw-items-center tw-space-y-2">
                              <Translate>
                                Up to
                                <Var name="duration">
                                  <div className="tw-mx-2">
                                    <DurationInput
                                      includeDays
                                      onChange={onRescheduleNoticeChange}
                                      value={rescheduleNotice}
                                    />
                                  </div>
                                </Var>
                                before meeting
                              </Translate>
                              <RemoveButton
                                onClick={() => {
                                  setOff()
                                  //  Reset the form value if the user exits the option.
                                  onRescheduleNoticeChange(new Duration())
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </Toggler>
                  )}
                </FinalForm.Field>
              )}
            </FinalForm.Field>
          </Media.Body>
        </Media>
      </Modal.Body>
    </div>
  )
}

export default UpdateMeetingTypeModalSchedulingRulesSection
