/* Generated by `yarn run codegen`.  Do not update manually. */
import { gql } from '@apollo/client'
import * as React from 'react'
import * as Apollo from '@apollo/client'
import * as ApolloReactComponents from '@apollo/client/react/components'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any
  /** Extends the Float field to support timedelta */
  Timedelta: any
}

/** An enumeration. */
export enum ActivityType {
  /** Attendee.cancelled */
  AttendeeCancelled = 'ATTENDEE_CANCELLED',
  /** Attendee.confirmed */
  AttendeeConfirmed = 'ATTENDEE_CONFIRMED',
  /** Attendee.declined */
  AttendeeDeclined = 'ATTENDEE_DECLINED',
  /** Attendee.reminded */
  AttendeeReminded = 'ATTENDEE_REMINDED',
  /** Attendee.rescheduled */
  AttendeeRescheduled = 'ATTENDEE_RESCHEDULED',
  /** Attendee.scheduled */
  AttendeeScheduled = 'ATTENDEE_SCHEDULED',
  /** Attendee.updated */
  AttendeeUpdated = 'ATTENDEE_UPDATED',
  /** CalendarAccount.created */
  CalendaraccountCreated = 'CALENDARACCOUNT_CREATED',
  /** CalendarAccount.updated */
  CalendaraccountUpdated = 'CALENDARACCOUNT_UPDATED',
  /** ConferencingAccount.created */
  ConferencingaccountCreated = 'CONFERENCINGACCOUNT_CREATED',
  /** ConferencingAccount.deleted */
  ConferencingaccountDeleted = 'CONFERENCINGACCOUNT_DELETED',
  /** ConferencingAccount.updated */
  ConferencingaccountUpdated = 'CONFERENCINGACCOUNT_UPDATED',
  /** MeetingNote.created */
  MeetingnoteCreated = 'MEETINGNOTE_CREATED',
  /** MeetingNote.deleted */
  MeetingnoteDeleted = 'MEETINGNOTE_DELETED',
  /** MeetingNote.updated */
  MeetingnoteUpdated = 'MEETINGNOTE_UPDATED',
  /** MeetingType.created */
  MeetingtypeCreated = 'MEETINGTYPE_CREATED',
  /** MeetingType.deleted */
  MeetingtypeDeleted = 'MEETINGTYPE_DELETED',
  /** MeetingType.updated */
  MeetingtypeUpdated = 'MEETINGTYPE_UPDATED',
  /** Meeting.cancelled */
  MeetingCancelled = 'MEETING_CANCELLED',
  /** Meeting.confirmed */
  MeetingConfirmed = 'MEETING_CONFIRMED',
  /** Meeting.declined */
  MeetingDeclined = 'MEETING_DECLINED',
  /** Meeting.rescheduled */
  MeetingRescheduled = 'MEETING_RESCHEDULED',
  /** Meeting.scheduled */
  MeetingScheduled = 'MEETING_SCHEDULED',
  /** Member.deleted */
  MemberDeleted = 'MEMBER_DELETED',
  /** Member.invited */
  MemberInvited = 'MEMBER_INVITED',
  /** Member.updated */
  MemberUpdated = 'MEMBER_UPDATED',
  /** PaymentCollectionAccount.deleted */
  PaymentcollectionaccountDeleted = 'PAYMENTCOLLECTIONACCOUNT_DELETED',
  /** Profile.created */
  ProfileCreated = 'PROFILE_CREATED',
  /** Profile.deleted */
  ProfileDeleted = 'PROFILE_DELETED',
  /** Profile.updated */
  ProfileUpdated = 'PROFILE_UPDATED',
  /** Team.created */
  TeamCreated = 'TEAM_CREATED',
  /** Team.deleted */
  TeamDeleted = 'TEAM_DELETED',
  /** Team.updated */
  TeamUpdated = 'TEAM_UPDATED',
  /** Webhook.created */
  WebhookCreated = 'WEBHOOK_CREATED',
  /** Webhook.deleted */
  WebhookDeleted = 'WEBHOOK_DELETED',
  /** Webhook.updated */
  WebhookUpdated = 'WEBHOOK_UPDATED',
}

export type ApproveAttendeesInput = {
  attendees: Array<InputMaybe<Scalars['ID']>>
  clientMutationId?: InputMaybe<Scalars['String']>
  message?: InputMaybe<Scalars['String']>
}

export type ApproveAttendeesPayload = {
  __typename?: 'ApproveAttendeesPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<Array<Maybe<AttendeeNode>>>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type ApproveMeetingInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  meeting: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
}

export type ApproveMeetingPayload = {
  __typename?: 'ApproveMeetingPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type AttendeeFieldInput = {
  choices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fieldType: FieldType
  helpText?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  order: Scalars['Int']
  required: Scalars['Boolean']
  slug: Scalars['String']
  value: Scalars['JSONString']
  visible?: InputMaybe<Scalars['Boolean']>
}

export type AttendeeFieldNode = Node & {
  __typename?: 'AttendeeFieldNode'
  choices: Maybe<Array<Scalars['String']>>
  fieldType: FieldType
  helpText: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  name: Scalars['String']
  order: Scalars['Int']
  required: Scalars['Boolean']
  slug: Scalars['String']
  value: Scalars['JSONString']
  visible: Scalars['Boolean']
}

export type AttendeeFieldNodeConnection = {
  __typename?: 'AttendeeFieldNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AttendeeFieldNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `AttendeeFieldNode` and its cursor. */
export type AttendeeFieldNodeEdge = {
  __typename?: 'AttendeeFieldNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<AttendeeFieldNode>
}

/** GraphQL representation of Attendee model */
export type AttendeeNode = Node & {
  __typename?: 'AttendeeNode'
  addToGoogleCalendarUrl: Scalars['String']
  /** If this attendee is approved to go to this meeting. */
  approved: Maybe<Scalars['Boolean']>
  cancelUrl: Scalars['String']
  /** If this attendee is no longer coming. */
  cancelled: Scalars['Boolean']
  conferenceUrl: Maybe<Scalars['String']>
  /** If this attendee has confirmed that they are attending. */
  confirmed: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  email: Scalars['String']
  externalId: Scalars['String']
  fieldSubmissions: AttendeeFieldNodeConnection
  firstName: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  isCreator: Scalars['Boolean']
  isHost: Scalars['Boolean']
  lastName: Maybe<Scalars['String']>
  meeting: MeetingNode
  member: Maybe<MemberNode>
  rescheduleUrl: Scalars['String']
  status: AttendeeStatus
  timezone: Timezone
  utmCampaign: Maybe<Scalars['String']>
  utmContent: Maybe<Scalars['String']>
  utmMedium: Maybe<Scalars['String']>
  utmSource: Maybe<Scalars['String']>
  utmTerm: Maybe<Scalars['String']>
}

/** GraphQL representation of Attendee model */
export type AttendeeNodeFieldSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type AttendeeNodeConnection = {
  __typename?: 'AttendeeNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AttendeeNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `AttendeeNode` and its cursor. */
export type AttendeeNodeEdge = {
  __typename?: 'AttendeeNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<AttendeeNode>
}

/** An enumeration. */
export enum AttendeeStatus {
  /** cancelled */
  Cancelled = 'CANCELLED',
  /** confirmed */
  Confirmed = 'CONFIRMED',
  /** invited */
  Invited = 'INVITED',
  /** requested */
  Requested = 'REQUESTED',
}

export type AvailableTimesNode = {
  __typename?: 'AvailableTimesNode'
  data: Maybe<Array<Maybe<Scalars['DateTime']>>>
  hasNextPage: Maybe<Scalars['Boolean']>
  hasPreviousPage: Maybe<Scalars['Boolean']>
  nextPageStart: Maybe<Scalars['DateTime']>
}

/** Representation of the Account model */
export type CalendarAccountNode = Node & {
  __typename?: 'CalendarAccountNode'
  active: Scalars['Boolean']
  calendars: CalendarNodeConnection
  createdAt: Scalars['DateTime']
  deactivatedAt: Maybe<Scalars['DateTime']>
  deactivatedReason: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  isConnected: Scalars['Boolean']
  member: Maybe<MemberNode>
  name: Scalars['String']
  oauth2AuthorizationUrl: Scalars['String']
  provider: CalendarProviderNode
}

/** Representation of the Account model */
export type CalendarAccountNodeCalendarsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type CalendarAccountNodeConnection = {
  __typename?: 'CalendarAccountNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CalendarAccountNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CalendarAccountNode` and its cursor. */
export type CalendarAccountNodeEdge = {
  __typename?: 'CalendarAccountNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<CalendarAccountNode>
}

/** Representation of the Calendar model */
export type CalendarNode = Node & {
  __typename?: 'CalendarNode'
  account: CalendarAccountNode
  color: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  lastSyncAt: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  permissionLevel: CalendarPermissionLevel
  syncMeetings: Scalars['Boolean']
  transparent: Scalars['Boolean']
}

export type CalendarNodeConnection = {
  __typename?: 'CalendarNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CalendarNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CalendarNode` and its cursor. */
export type CalendarNodeEdge = {
  __typename?: 'CalendarNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<CalendarNode>
}

/** An enumeration. */
export enum CalendarPermissionLevel {
  /** freeBusy */
  Freebusy = 'FREEBUSY',
  /** owner */
  Owner = 'OWNER',
  /** reader */
  Reader = 'READER',
  /** writer */
  Writer = 'WRITER',
}

/** Representation of the Provider model */
export type CalendarProviderNode = Node & {
  __typename?: 'CalendarProviderNode'
  description: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  name: Scalars['String']
  oauth2AuthorizationUrl: Scalars['String']
  shortDescription: Maybe<Scalars['String']>
  slug: Scalars['String']
}

/** Representation of the Provider model */
export type CalendarProviderNodeOauth2AuthorizationUrlArgs = {
  redirectUri?: InputMaybe<Scalars['String']>
}

export type CalendarProviderNodeConnection = {
  __typename?: 'CalendarProviderNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CalendarProviderNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `CalendarProviderNode` and its cursor. */
export type CalendarProviderNodeEdge = {
  __typename?: 'CalendarProviderNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<CalendarProviderNode>
}

export type CancelAttendeesInput = {
  attendees: Array<Scalars['ID']>
  clientMutationId?: InputMaybe<Scalars['String']>
  meeting: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
}

export type CancelAttendeesPayload = {
  __typename?: 'CancelAttendeesPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<Array<Maybe<AttendeeNode>>>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CancelMeetingInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
}

export type CancelMeetingPayload = {
  __typename?: 'CancelMeetingPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CancelSubscriptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  profile: Scalars['ID']
}

export type CancelSubscriptionPayload = {
  __typename?: 'CancelSubscriptionPayload'
  clientMutationId: Maybe<Scalars['String']>
  ok: Maybe<Scalars['Boolean']>
}

export type CloneMeetingTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type CloneMeetingTypePayload = {
  __typename?: 'CloneMeetingTypePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingTypeNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

/** GraphQL representation of Provider model */
export type ConferenceProviderNode = Node & {
  __typename?: 'ConferenceProviderNode'
  /** The ID of the object. */
  id: Scalars['ID']
  kbArticle: Scalars['String']
  name: Scalars['String']
  oauth2AuthorizationUrl: Scalars['String']
  slug: Scalars['String']
}

export type ConferenceProviderNodeConnection = {
  __typename?: 'ConferenceProviderNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConferenceProviderNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ConferenceProviderNode` and its cursor. */
export type ConferenceProviderNodeEdge = {
  __typename?: 'ConferenceProviderNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<ConferenceProviderNode>
}

/** Interface that conferencing providers can utilize for their accounts */
export type ConferencingAccountNode = {
  createdAt: Scalars['DateTime']
  deleted: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  isConnected: Scalars['Boolean']
  isDefault: Scalars['Boolean']
  name: Scalars['String']
  provider: ConferenceProviderNode
}

/**
 * We create this manually because we can't use
 * DjangoFilterConnectionField with interfaces (which we use for accounts).
 */
export type ConferencingAccountNodeConnection = {
  __typename?: 'ConferencingAccountNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConferencingAccountNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ConferencingAccountNode` and its cursor. */
export type ConferencingAccountNodeEdge = {
  __typename?: 'ConferencingAccountNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<ConferencingAccountNode>
}

export type ConfirmAttendeesInput = {
  attendees: Array<Scalars['ID']>
  clientMutationId?: InputMaybe<Scalars['String']>
  meeting: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
}

export type ConfirmAttendeesPayload = {
  __typename?: 'ConfirmAttendeesPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<Array<Maybe<AttendeeNode>>>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type ConfirmMeetingInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  message?: InputMaybe<Scalars['String']>
}

export type ConfirmMeetingPayload = {
  __typename?: 'ConfirmMeetingPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CreateCalendarAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  oauth2Code: Scalars['String']
  profile: Scalars['ID']
  provider: Scalars['ID']
}

export type CreateCalendarAccountPayload = {
  __typename?: 'CreateCalendarAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<CalendarAccountNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CreateConferencingAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  oauth2Code: Scalars['String']
  profile: Scalars['ID']
  provider: Scalars['ID']
}

export type CreateConferencingAccountPayload = {
  __typename?: 'CreateConferencingAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<ConferencingAccountNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CreateMeetingNoteInput = {
  body: Scalars['String']
  clientMutationId?: InputMaybe<Scalars['String']>
  meeting: Scalars['ID']
}

export type CreateMeetingNotePayload = {
  __typename?: 'CreateMeetingNotePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingNoteNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CreateMeetingReportInput = {
  cancelled?: InputMaybe<Scalars['Boolean']>
  clientMutationId?: InputMaybe<Scalars['String']>
  confirmed?: InputMaybe<Scalars['Boolean']>
  end?: InputMaybe<Scalars['DateTime']>
  meetingTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  members?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  order?: InputMaybe<Scalars['String']>
  profile: Scalars['ID']
  search?: InputMaybe<Scalars['String']>
  start?: InputMaybe<Scalars['DateTime']>
}

export type CreateMeetingReportPayload = {
  __typename?: 'CreateMeetingReportPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<ReportNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CreateMeetingTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  duration?: InputMaybe<Scalars['String']>
  isGroup?: InputMaybe<Scalars['Boolean']>
  maxAttendees?: InputMaybe<Scalars['Int']>
  name: Scalars['String']
  profile: Scalars['ID']
  slug?: InputMaybe<Scalars['String']>
  team: Scalars['ID']
}

export type CreateMeetingTypePayload = {
  __typename?: 'CreateMeetingTypePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingTypeNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CreatePaymentCollectionAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  oauth2Code: Scalars['String']
  profile: Scalars['ID']
  provider: Scalars['ID']
}

export type CreatePaymentCollectionAccountPayload = {
  __typename?: 'CreatePaymentCollectionAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<PaymentCollectionAccountNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CreateProfileInput = {
  brandColor?: InputMaybe<Scalars['String']>
  clientMutationId?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  image?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  personal?: InputMaybe<Scalars['Boolean']>
}

export type CreateProfilePayload = {
  __typename?: 'CreateProfilePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<ProfileNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CreateScheduleInput = {
  bounds?: InputMaybe<DateRangeInput>
  clientMutationId?: InputMaybe<Scalars['String']>
  member?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  profile: Scalars['ID']
  timeBlocks: Array<InputMaybe<TimeBlockSerializerInput>>
  timezone: Timezone
}

export type CreateSchedulePayload = {
  __typename?: 'CreateSchedulePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<ScheduleNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CreateTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  image?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Language>
  name: Scalars['String']
  private?: InputMaybe<Scalars['Boolean']>
  profile: Scalars['ID']
  slug?: InputMaybe<Scalars['String']>
}

export type CreateTeamPayload = {
  __typename?: 'CreateTeamPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<TeamNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type CreateWebhookInput = {
  active?: InputMaybe<Scalars['Boolean']>
  clientMutationId?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  profile: Scalars['ID']
  selectedActivities?: InputMaybe<Array<InputMaybe<ActivityType>>>
  url: Scalars['String']
}

export type CreateWebhookPayload = {
  __typename?: 'CreateWebhookPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<WebhookNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

/** An enumeration. */
export enum Currency {
  /** ADP */
  Adp = 'ADP',
  /** AED */
  Aed = 'AED',
  /** AFA */
  Afa = 'AFA',
  /** AFN */
  Afn = 'AFN',
  /** ALK */
  Alk = 'ALK',
  /** ALL */
  All = 'ALL',
  /** AMD */
  Amd = 'AMD',
  /** ANG */
  Ang = 'ANG',
  /** AOA */
  Aoa = 'AOA',
  /** AOK */
  Aok = 'AOK',
  /** AON */
  Aon = 'AON',
  /** AOR */
  Aor = 'AOR',
  /** ARA */
  Ara = 'ARA',
  /** ARL */
  Arl = 'ARL',
  /** ARM */
  Arm = 'ARM',
  /** ARP */
  Arp = 'ARP',
  /** ARS */
  Ars = 'ARS',
  /** ATS */
  Ats = 'ATS',
  /** AUD */
  Aud = 'AUD',
  /** AWG */
  Awg = 'AWG',
  /** AZM */
  Azm = 'AZM',
  /** AZN */
  Azn = 'AZN',
  /** BAD */
  Bad = 'BAD',
  /** BAM */
  Bam = 'BAM',
  /** BAN */
  Ban = 'BAN',
  /** BBD */
  Bbd = 'BBD',
  /** BDT */
  Bdt = 'BDT',
  /** BEC */
  Bec = 'BEC',
  /** BEF */
  Bef = 'BEF',
  /** BEL */
  Bel = 'BEL',
  /** BGL */
  Bgl = 'BGL',
  /** BGM */
  Bgm = 'BGM',
  /** BGN */
  Bgn = 'BGN',
  /** BGO */
  Bgo = 'BGO',
  /** BHD */
  Bhd = 'BHD',
  /** BIF */
  Bif = 'BIF',
  /** BMD */
  Bmd = 'BMD',
  /** BND */
  Bnd = 'BND',
  /** BOB */
  Bob = 'BOB',
  /** BOL */
  Bol = 'BOL',
  /** BOP */
  Bop = 'BOP',
  /** BOV */
  Bov = 'BOV',
  /** BRB */
  Brb = 'BRB',
  /** BRC */
  Brc = 'BRC',
  /** BRE */
  Bre = 'BRE',
  /** BRL */
  Brl = 'BRL',
  /** BRN */
  Brn = 'BRN',
  /** BRR */
  Brr = 'BRR',
  /** BRZ */
  Brz = 'BRZ',
  /** BSD */
  Bsd = 'BSD',
  /** BTN */
  Btn = 'BTN',
  /** BUK */
  Buk = 'BUK',
  /** BWP */
  Bwp = 'BWP',
  /** BYB */
  Byb = 'BYB',
  /** BYN */
  Byn = 'BYN',
  /** BYR */
  Byr = 'BYR',
  /** BZD */
  Bzd = 'BZD',
  /** CAD */
  Cad = 'CAD',
  /** CDF */
  Cdf = 'CDF',
  /** CHE */
  Che = 'CHE',
  /** CHF */
  Chf = 'CHF',
  /** CHW */
  Chw = 'CHW',
  /** CLE */
  Cle = 'CLE',
  /** CLF */
  Clf = 'CLF',
  /** CLP */
  Clp = 'CLP',
  /** CNH */
  Cnh = 'CNH',
  /** CNX */
  Cnx = 'CNX',
  /** CNY */
  Cny = 'CNY',
  /** COP */
  Cop = 'COP',
  /** COU */
  Cou = 'COU',
  /** CRC */
  Crc = 'CRC',
  /** CSD */
  Csd = 'CSD',
  /** CSK */
  Csk = 'CSK',
  /** CUC */
  Cuc = 'CUC',
  /** CUP */
  Cup = 'CUP',
  /** CVE */
  Cve = 'CVE',
  /** CYP */
  Cyp = 'CYP',
  /** CZK */
  Czk = 'CZK',
  /** DDM */
  Ddm = 'DDM',
  /** DEM */
  Dem = 'DEM',
  /** DJF */
  Djf = 'DJF',
  /** DKK */
  Dkk = 'DKK',
  /** DOP */
  Dop = 'DOP',
  /** DZD */
  Dzd = 'DZD',
  /** ECS */
  Ecs = 'ECS',
  /** ECV */
  Ecv = 'ECV',
  /** EEK */
  Eek = 'EEK',
  /** EGP */
  Egp = 'EGP',
  /** ERN */
  Ern = 'ERN',
  /** ESA */
  Esa = 'ESA',
  /** ESB */
  Esb = 'ESB',
  /** ESP */
  Esp = 'ESP',
  /** ETB */
  Etb = 'ETB',
  /** EUR */
  Eur = 'EUR',
  /** FIM */
  Fim = 'FIM',
  /** FJD */
  Fjd = 'FJD',
  /** FKP */
  Fkp = 'FKP',
  /** FRF */
  Frf = 'FRF',
  /** GBP */
  Gbp = 'GBP',
  /** GEK */
  Gek = 'GEK',
  /** GEL */
  Gel = 'GEL',
  /** GHC */
  Ghc = 'GHC',
  /** GHS */
  Ghs = 'GHS',
  /** GIP */
  Gip = 'GIP',
  /** GMD */
  Gmd = 'GMD',
  /** GNF */
  Gnf = 'GNF',
  /** GNS */
  Gns = 'GNS',
  /** GQE */
  Gqe = 'GQE',
  /** GRD */
  Grd = 'GRD',
  /** GTQ */
  Gtq = 'GTQ',
  /** GWE */
  Gwe = 'GWE',
  /** GWP */
  Gwp = 'GWP',
  /** GYD */
  Gyd = 'GYD',
  /** HKD */
  Hkd = 'HKD',
  /** HNL */
  Hnl = 'HNL',
  /** HRD */
  Hrd = 'HRD',
  /** HRK */
  Hrk = 'HRK',
  /** HTG */
  Htg = 'HTG',
  /** HUF */
  Huf = 'HUF',
  /** IDR */
  Idr = 'IDR',
  /** IEP */
  Iep = 'IEP',
  /** ILP */
  Ilp = 'ILP',
  /** ILR */
  Ilr = 'ILR',
  /** ILS */
  Ils = 'ILS',
  /** IMP */
  Imp = 'IMP',
  /** INR */
  Inr = 'INR',
  /** IQD */
  Iqd = 'IQD',
  /** IRR */
  Irr = 'IRR',
  /** ISJ */
  Isj = 'ISJ',
  /** ISK */
  Isk = 'ISK',
  /** ITL */
  Itl = 'ITL',
  /** JMD */
  Jmd = 'JMD',
  /** JOD */
  Jod = 'JOD',
  /** JPY */
  Jpy = 'JPY',
  /** KES */
  Kes = 'KES',
  /** KGS */
  Kgs = 'KGS',
  /** KHR */
  Khr = 'KHR',
  /** KMF */
  Kmf = 'KMF',
  /** KPW */
  Kpw = 'KPW',
  /** KRH */
  Krh = 'KRH',
  /** KRO */
  Kro = 'KRO',
  /** KRW */
  Krw = 'KRW',
  /** KWD */
  Kwd = 'KWD',
  /** KYD */
  Kyd = 'KYD',
  /** KZT */
  Kzt = 'KZT',
  /** LAK */
  Lak = 'LAK',
  /** LBP */
  Lbp = 'LBP',
  /** LKR */
  Lkr = 'LKR',
  /** LRD */
  Lrd = 'LRD',
  /** LSL */
  Lsl = 'LSL',
  /** LTL */
  Ltl = 'LTL',
  /** LTT */
  Ltt = 'LTT',
  /** LUC */
  Luc = 'LUC',
  /** LUF */
  Luf = 'LUF',
  /** LUL */
  Lul = 'LUL',
  /** LVL */
  Lvl = 'LVL',
  /** LVR */
  Lvr = 'LVR',
  /** LYD */
  Lyd = 'LYD',
  /** MAD */
  Mad = 'MAD',
  /** MAF */
  Maf = 'MAF',
  /** MCF */
  Mcf = 'MCF',
  /** MDC */
  Mdc = 'MDC',
  /** MDL */
  Mdl = 'MDL',
  /** MGA */
  Mga = 'MGA',
  /** MGF */
  Mgf = 'MGF',
  /** MKD */
  Mkd = 'MKD',
  /** MKN */
  Mkn = 'MKN',
  /** MLF */
  Mlf = 'MLF',
  /** MMK */
  Mmk = 'MMK',
  /** MNT */
  Mnt = 'MNT',
  /** MOP */
  Mop = 'MOP',
  /** MRO */
  Mro = 'MRO',
  /** MRU */
  Mru = 'MRU',
  /** MTL */
  Mtl = 'MTL',
  /** MTP */
  Mtp = 'MTP',
  /** MUR */
  Mur = 'MUR',
  /** MVP */
  Mvp = 'MVP',
  /** MVR */
  Mvr = 'MVR',
  /** MWK */
  Mwk = 'MWK',
  /** MXN */
  Mxn = 'MXN',
  /** MXP */
  Mxp = 'MXP',
  /** MXV */
  Mxv = 'MXV',
  /** MYR */
  Myr = 'MYR',
  /** MZE */
  Mze = 'MZE',
  /** MZM */
  Mzm = 'MZM',
  /** MZN */
  Mzn = 'MZN',
  /** NAD */
  Nad = 'NAD',
  /** NGN */
  Ngn = 'NGN',
  /** NIC */
  Nic = 'NIC',
  /** NIO */
  Nio = 'NIO',
  /** NLG */
  Nlg = 'NLG',
  /** NOK */
  Nok = 'NOK',
  /** NPR */
  Npr = 'NPR',
  /** NZD */
  Nzd = 'NZD',
  /** OMR */
  Omr = 'OMR',
  /** PAB */
  Pab = 'PAB',
  /** PEI */
  Pei = 'PEI',
  /** PEN */
  Pen = 'PEN',
  /** PES */
  Pes = 'PES',
  /** PGK */
  Pgk = 'PGK',
  /** PHP */
  Php = 'PHP',
  /** PKR */
  Pkr = 'PKR',
  /** PLN */
  Pln = 'PLN',
  /** PLZ */
  Plz = 'PLZ',
  /** PTE */
  Pte = 'PTE',
  /** PYG */
  Pyg = 'PYG',
  /** QAR */
  Qar = 'QAR',
  /** RHD */
  Rhd = 'RHD',
  /** ROL */
  Rol = 'ROL',
  /** RON */
  Ron = 'RON',
  /** RSD */
  Rsd = 'RSD',
  /** RUB */
  Rub = 'RUB',
  /** RUR */
  Rur = 'RUR',
  /** RWF */
  Rwf = 'RWF',
  /** SAR */
  Sar = 'SAR',
  /** SBD */
  Sbd = 'SBD',
  /** SCR */
  Scr = 'SCR',
  /** SDD */
  Sdd = 'SDD',
  /** SDG */
  Sdg = 'SDG',
  /** SDP */
  Sdp = 'SDP',
  /** SEK */
  Sek = 'SEK',
  /** SGD */
  Sgd = 'SGD',
  /** SHP */
  Shp = 'SHP',
  /** SIT */
  Sit = 'SIT',
  /** SKK */
  Skk = 'SKK',
  /** SLL */
  Sll = 'SLL',
  /** SOS */
  Sos = 'SOS',
  /** SRD */
  Srd = 'SRD',
  /** SRG */
  Srg = 'SRG',
  /** SSP */
  Ssp = 'SSP',
  /** STD */
  Std = 'STD',
  /** STN */
  Stn = 'STN',
  /** SUR */
  Sur = 'SUR',
  /** SVC */
  Svc = 'SVC',
  /** SYP */
  Syp = 'SYP',
  /** SZL */
  Szl = 'SZL',
  /** THB */
  Thb = 'THB',
  /** TJR */
  Tjr = 'TJR',
  /** TJS */
  Tjs = 'TJS',
  /** TMM */
  Tmm = 'TMM',
  /** TMT */
  Tmt = 'TMT',
  /** TND */
  Tnd = 'TND',
  /** TOP */
  Top = 'TOP',
  /** TPE */
  Tpe = 'TPE',
  /** TRL */
  Trl = 'TRL',
  /** TRY */
  Try = 'TRY',
  /** TTD */
  Ttd = 'TTD',
  /** TVD */
  Tvd = 'TVD',
  /** TWD */
  Twd = 'TWD',
  /** TZS */
  Tzs = 'TZS',
  /** UAH */
  Uah = 'UAH',
  /** UAK */
  Uak = 'UAK',
  /** UGS */
  Ugs = 'UGS',
  /** UGX */
  Ugx = 'UGX',
  /** USD */
  Usd = 'USD',
  /** USN */
  Usn = 'USN',
  /** USS */
  Uss = 'USS',
  /** UYI */
  Uyi = 'UYI',
  /** UYP */
  Uyp = 'UYP',
  /** UYU */
  Uyu = 'UYU',
  /** UYW */
  Uyw = 'UYW',
  /** UZS */
  Uzs = 'UZS',
  /** VEB */
  Veb = 'VEB',
  /** VEF */
  Vef = 'VEF',
  /** VES */
  Ves = 'VES',
  /** VND */
  Vnd = 'VND',
  /** VNN */
  Vnn = 'VNN',
  /** VUV */
  Vuv = 'VUV',
  /** WST */
  Wst = 'WST',
  /** XAF */
  Xaf = 'XAF',
  /** XAG */
  Xag = 'XAG',
  /** XAU */
  Xau = 'XAU',
  /** XBA */
  Xba = 'XBA',
  /** XBB */
  Xbb = 'XBB',
  /** XBC */
  Xbc = 'XBC',
  /** XBD */
  Xbd = 'XBD',
  /** XCD */
  Xcd = 'XCD',
  /** XDR */
  Xdr = 'XDR',
  /** XEU */
  Xeu = 'XEU',
  /** XFO */
  Xfo = 'XFO',
  /** XFU */
  Xfu = 'XFU',
  /** XOF */
  Xof = 'XOF',
  /** XPD */
  Xpd = 'XPD',
  /** XPF */
  Xpf = 'XPF',
  /** XPT */
  Xpt = 'XPT',
  /** XRE */
  Xre = 'XRE',
  /** XSU */
  Xsu = 'XSU',
  /** XTS */
  Xts = 'XTS',
  /** XUA */
  Xua = 'XUA',
  /** XXX */
  Xxx = 'XXX',
  /** XYZ */
  Xyz = 'XYZ',
  /** YDD */
  Ydd = 'YDD',
  /** YER */
  Yer = 'YER',
  /** YUD */
  Yud = 'YUD',
  /** YUM */
  Yum = 'YUM',
  /** YUN */
  Yun = 'YUN',
  /** YUR */
  Yur = 'YUR',
  /** ZAL */
  Zal = 'ZAL',
  /** ZAR */
  Zar = 'ZAR',
  /** ZMK */
  Zmk = 'ZMK',
  /** ZMW */
  Zmw = 'ZMW',
  /** ZRN */
  Zrn = 'ZRN',
  /** ZRZ */
  Zrz = 'ZRZ',
  /** ZWD */
  Zwd = 'ZWD',
  /** ZWL */
  Zwl = 'ZWL',
  /** ZWN */
  Zwn = 'ZWN',
  /** ZWR */
  Zwr = 'ZWR',
}

export type DateRangeInput = {
  lower?: InputMaybe<Scalars['Date']>
  upper?: InputMaybe<Scalars['Date']>
}

export type DateRangeNode = {
  __typename?: 'DateRangeNode'
  lower: Maybe<Scalars['Date']>
  upper: Maybe<Scalars['Date']>
}

export type DeclineAttendeesInput = {
  attendees: Array<InputMaybe<Scalars['ID']>>
  clientMutationId?: InputMaybe<Scalars['String']>
  message?: InputMaybe<Scalars['String']>
}

export type DeclineAttendeesPayload = {
  __typename?: 'DeclineAttendeesPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<Array<Maybe<AttendeeNode>>>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type DeclineMeetingInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  message?: InputMaybe<Scalars['String']>
}

export type DeclineMeetingPayload = {
  __typename?: 'DeclineMeetingPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type DeleteCalendarAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteCalendarAccountPayload = {
  __typename?: 'DeleteCalendarAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<CalendarAccountNode>
}

export type DeleteConferencingAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteConferencingAccountPayload = {
  __typename?: 'DeleteConferencingAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<ConferencingAccountNode>
}

export type DeleteMeetingNoteInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Deletes a MeetingNote */
export type DeleteMeetingNotePayload = {
  __typename?: 'DeleteMeetingNotePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingNoteNode>
}

export type DeleteMeetingTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteMeetingTypePayload = {
  __typename?: 'DeleteMeetingTypePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingTypeNode>
}

export type DeleteMemberInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteMemberPayload = {
  __typename?: 'DeleteMemberPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MemberNode>
}

export type DeletePaymentCollectionAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeletePaymentCollectionAccountPayload = {
  __typename?: 'DeletePaymentCollectionAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<PaymentCollectionAccountNode>
}

export type DeleteScheduleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteSchedulePayload = {
  __typename?: 'DeleteSchedulePayload'
  clientMutationId: Maybe<Scalars['String']>
  ok: Scalars['Boolean']
}

export type DeleteTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type DeleteTeamPayload = {
  __typename?: 'DeleteTeamPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<TeamNode>
}

export type DeleteWebhookInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Deletes a Webhook */
export type DeleteWebhookPayload = {
  __typename?: 'DeleteWebhookPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<WebhookNode>
}

export type ErrorType = {
  __typename?: 'ErrorType'
  field: Scalars['String']
  messages: Array<Scalars['String']>
}

/** An enumeration. */
export enum FieldType {
  /** choice */
  Choice = 'CHOICE',
  /** date */
  Date = 'DATE',
  /** multichoice */
  Multichoice = 'MULTICHOICE',
  /** multitext */
  Multitext = 'MULTITEXT',
  /** place */
  Place = 'PLACE',
  /** text */
  Text = 'TEXT',
  /** time */
  Time = 'TIME',
  /** toggle */
  Toggle = 'TOGGLE',
}

export type FormFieldNode = Node & {
  __typename?: 'FormFieldNode'
  choices: Maybe<Array<Scalars['String']>>
  fieldType: FieldType
  helpText: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  meetingType: MeetingTypeNode
  name: Scalars['String']
  order: Scalars['Int']
  required: Scalars['Boolean']
  slug: Scalars['String']
  visible: Scalars['Boolean']
}

export type FormFieldNodeConnection = {
  __typename?: 'FormFieldNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FormFieldNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `FormFieldNode` and its cursor. */
export type FormFieldNodeEdge = {
  __typename?: 'FormFieldNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<FormFieldNode>
}

export type FormFieldSerializerInput = {
  choices?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fieldType: FieldType
  helpText?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  order: Scalars['Int']
  required: Scalars['Boolean']
  slug?: InputMaybe<Scalars['String']>
  visible?: InputMaybe<Scalars['Boolean']>
}

/** Connected GoToMeeting account. */
export type GoToMeetingAccountNode = ConferencingAccountNode & {
  __typename?: 'GoToMeetingAccountNode'
  /** Audio options for the meeting. */
  conferenceCallInfo: Maybe<GoToMeetingConferenceCallInfoOptions>
  createdAt: Scalars['DateTime']
  deleted: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  isConnected: Scalars['Boolean']
  isDefault: Scalars['Boolean']
  name: Scalars['String']
  provider: ConferenceProviderNode
}

export enum GoToMeetingConferenceCallInfoOptions {
  Hybrid = 'HYBRID',
  Private = 'PRIVATE',
  Pstn = 'PSTN',
  Voip = 'VOIP',
}

/** An enumeration. */
export enum HostAssignmentStrategy {
  /** Attendee will choose from list of members */
  AttendeeChooses = 'ATTENDEE_CHOOSES',
  /** All hosts must be available for meeting */
  Joint = 'JOINT',
  /** Automatically distribute meetings */
  Pooled = 'POOLED',
}

export type InviteMembersInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  invitees: Array<InputMaybe<InviteMembersUserSerializerInput>>
  profile: Scalars['ID']
}

export type InviteMembersPayload = {
  __typename?: 'InviteMembersPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<Array<Maybe<MemberNode>>>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type InviteMembersUserSerializerInput = {
  email: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
}

/** Connected Join.me account. */
export type JoinMeAccountNode = ConferencingAccountNode & {
  __typename?: 'JoinMeAccountNode'
  createdAt: Scalars['DateTime']
  deleted: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  isConnected: Scalars['Boolean']
  isDefault: Scalars['Boolean']
  name: Scalars['String']
  provider: ConferenceProviderNode
  /** Use personal meeting URL instead of unique link. */
  startWithPersonalUrl: Maybe<Scalars['Boolean']>
}

/** An enumeration. */
export enum Language {
  /** Danish */
  Da = 'DA',
  /** German */
  De = 'DE',
  /** English */
  En = 'EN',
  /** Spanish */
  Es = 'ES',
  /** French */
  Fr = 'FR',
  /** Italian */
  It = 'IT',
  /** Dutch */
  Nl = 'NL',
  /** Portuguese */
  Pt = 'PT',
  /** Swedish */
  Sv = 'SV',
}

/** An enumeration. */
export enum LocationType {
  /** Google Meet */
  GoogleMeet = 'GOOGLE_MEET',
  /** Host provides a number to call */
  Phone = 'PHONE',
  /** Attendee provides a number to call */
  PhoneUser = 'PHONE_USER',
  /** Host chooses a place to meet */
  Place = 'PLACE',
  /** Attendee chooses a place to meet */
  PlaceUser = 'PLACE_USER',
  /** Web Conference */
  WebConference = 'WEB_CONFERENCE',
}

export type MeetingCountNode = {
  __typename?: 'MeetingCountNode'
  date: Scalars['Date']
  meetings: Scalars['Int']
}

/** GraphQL representation of Meeting model */
export type MeetingNode = Node & {
  __typename?: 'MeetingNode'
  /** Determines if attendees added to this meeting will need to be approved. */
  approved: Maybe<Scalars['Boolean']>
  attendees: AttendeeNodeConnection
  cancelled: Scalars['Boolean']
  conferenceId: Maybe<Scalars['String']>
  conferencePassword: Maybe<Scalars['String']>
  conferenceUrl: Maybe<Scalars['String']>
  conferencingAccount: Maybe<ConferencingAccountNode>
  confirmed: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  description: Maybe<Scalars['String']>
  end: Scalars['DateTime']
  host: Maybe<AttendeeNode>
  /** The ID of the object. */
  id: Scalars['ID']
  instructions: Maybe<Scalars['String']>
  isCancellable: Scalars['Boolean']
  isGroup: Scalars['Boolean']
  isReschedulable: Scalars['Boolean']
  location: Maybe<Scalars['String']>
  locationType: Maybe<LocationType>
  meetingType: MeetingTypeNode
  name: Scalars['String']
  notes: MeetingNoteNodeConnection
  reminders: MeetingReminderNodeConnection
  reschedulableTimes: Maybe<AvailableTimesNode>
  start: Scalars['DateTime']
  timezone: Maybe<Timezone>
  usesLocalTimezone: Scalars['Boolean']
}

/** GraphQL representation of Meeting model */
export type MeetingNodeAttendeesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}

/** GraphQL representation of Meeting model */
export type MeetingNodeNotesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

/** GraphQL representation of Meeting model */
export type MeetingNodeRemindersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

/** GraphQL representation of Meeting model */
export type MeetingNodeReschedulableTimesArgs = {
  end?: InputMaybe<Scalars['DateTime']>
  member?: InputMaybe<Scalars['ID']>
  start?: InputMaybe<Scalars['DateTime']>
}

export type MeetingNodeConnection = {
  __typename?: 'MeetingNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MeetingNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MeetingNode` and its cursor. */
export type MeetingNodeEdge = {
  __typename?: 'MeetingNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<MeetingNode>
}

/** GraphQL representation of the MeetingNote model */
export type MeetingNoteNode = Node & {
  __typename?: 'MeetingNoteNode'
  author: MemberNode
  body: Scalars['String']
  createdAt: Scalars['DateTime']
  deleted: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  meeting: MeetingNode
}

export type MeetingNoteNodeConnection = {
  __typename?: 'MeetingNoteNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MeetingNoteNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MeetingNoteNode` and its cursor. */
export type MeetingNoteNodeEdge = {
  __typename?: 'MeetingNoteNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<MeetingNoteNode>
}

/** GraphQL representation of the MeetingReminder model */
export type MeetingReminderNode = Node & {
  __typename?: 'MeetingReminderNode'
  emailBody: Maybe<Scalars['String']>
  emailSubject: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  leadTime: Scalars['Timedelta']
}

export type MeetingReminderNodeConnection = {
  __typename?: 'MeetingReminderNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MeetingReminderNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MeetingReminderNode` and its cursor. */
export type MeetingReminderNodeEdge = {
  __typename?: 'MeetingReminderNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<MeetingReminderNode>
}

export type MeetingTypeNode = Node & {
  __typename?: 'MeetingTypeNode'
  active: Scalars['Boolean']
  allowCancellations: Scalars['Boolean']
  allowReschedules: Scalars['Boolean']
  autoConfirm: Scalars['Boolean']
  availableTimes: Maybe<AvailableTimesNode>
  bookingUrl: Scalars['String']
  bufferAfter: Scalars['Timedelta']
  bufferBefore: Scalars['Timedelta']
  cancellationEmailBody: Maybe<Scalars['String']>
  cancellationEmailSubject: Maybe<Scalars['String']>
  cancellationNotice: Scalars['Timedelta']
  color: Scalars['String']
  conferencingProvider: Maybe<ConferenceProviderNode>
  confirmationEmailBody: Maybe<Scalars['String']>
  confirmationEmailSubject: Maybe<Scalars['String']>
  deleted: Maybe<Scalars['DateTime']>
  description: Maybe<Scalars['String']>
  duration: Scalars['Timedelta']
  fields: FormFieldNodeConnection
  frequency: Maybe<Scalars['Timedelta']>
  hostAssignmentStrategy: HostAssignmentStrategy
  /** The ID of the object. */
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  includeDataInRedirectUrl: Scalars['Boolean']
  instructions: Maybe<Scalars['String']>
  isGroup: Scalars['Boolean']
  lastMeetingCreatedAt: Maybe<Scalars['DateTime']>
  location: Maybe<Scalars['String']>
  locationType: Maybe<LocationType>
  /** Determines if attendees will have to be manually approved before joining a meeting. */
  manuallyApproveAttendees: Scalars['Boolean']
  maxAttendees: Scalars['Int']
  maxBookingsPerDay: Maybe<Scalars['Int']>
  maximumNotice: Scalars['Timedelta']
  meetingCount: Scalars['Int']
  meetingTypeTeamMembers: MeetingTypeTeamMemberNodeConnection
  minimumNotice: Scalars['Timedelta']
  name: Scalars['String']
  order: Scalars['Int']
  paymentCollectionProvider: Maybe<PaymentCollectionProviderNode>
  paymentRule: Maybe<PaymentRule>
  price: Scalars['Float']
  priceCurrency: Maybe<Currency>
  priceFormatted: Scalars['String']
  profile: ProfileNode
  public: Scalars['Boolean']
  redirectUrl: Maybe<Scalars['String']>
  reminders: MeetingTypeReminderNodeConnection
  /** Determines if attendees should be asked to confirm they are attending in reminder emails. */
  requestAttendeeConfirmation: Scalars['Boolean']
  rescheduleNotice: Scalars['Timedelta']
  schedules: MeetingTypeScheduleNodeConnection
  sendCancellationEmail: Scalars['Boolean']
  sendConfirmationEmail: Scalars['Boolean']
  slug: Scalars['String']
  tags: Array<Scalars['String']>
  team: TeamNode
  timezone: Maybe<Timezone>
  useCalendarInvitations: Scalars['Boolean']
  usesLocalTimezone: Scalars['Boolean']
}

export type MeetingTypeNodeAvailableTimesArgs = {
  end?: InputMaybe<Scalars['DateTime']>
  hostMemberId?: InputMaybe<Scalars['ID']>
  start?: InputMaybe<Scalars['DateTime']>
}

export type MeetingTypeNodeFieldsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MeetingTypeNodeMeetingTypeTeamMembersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MeetingTypeNodeRemindersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MeetingTypeNodeSchedulesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MeetingTypeNodeConnection = {
  __typename?: 'MeetingTypeNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MeetingTypeNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MeetingTypeNode` and its cursor. */
export type MeetingTypeNodeEdge = {
  __typename?: 'MeetingTypeNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<MeetingTypeNode>
}

export type MeetingTypeReminderNode = Node & {
  __typename?: 'MeetingTypeReminderNode'
  emailBody: Maybe<Scalars['String']>
  emailSubject: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  leadTime: Scalars['Timedelta']
  meetingType: MeetingTypeNode
}

export type MeetingTypeReminderNodeConnection = {
  __typename?: 'MeetingTypeReminderNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MeetingTypeReminderNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MeetingTypeReminderNode` and its cursor. */
export type MeetingTypeReminderNodeEdge = {
  __typename?: 'MeetingTypeReminderNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<MeetingTypeReminderNode>
}

export type MeetingTypeReminderSerializerInput = {
  emailBody?: InputMaybe<Scalars['String']>
  emailSubject?: InputMaybe<Scalars['String']>
  leadTime?: InputMaybe<Scalars['String']>
}

export type MeetingTypeScheduleNode = Node & {
  __typename?: 'MeetingTypeScheduleNode'
  bounds: Maybe<DateRangeNode>
  /** The ID of the object. */
  id: Scalars['ID']
  kind: ScheduleKind
  meetingType: MeetingTypeNode
  name: Maybe<Scalars['String']>
  order: Scalars['Int']
  timeBlocks: MeetingTypeTimeBlockNodeConnection
  timezone: Timezone
}

export type MeetingTypeScheduleNodeTimeBlocksArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MeetingTypeScheduleNodeConnection = {
  __typename?: 'MeetingTypeScheduleNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MeetingTypeScheduleNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MeetingTypeScheduleNode` and its cursor. */
export type MeetingTypeScheduleNodeEdge = {
  __typename?: 'MeetingTypeScheduleNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<MeetingTypeScheduleNode>
}

export type MeetingTypeScheduleSerializerInput = {
  bounds?: InputMaybe<DateRangeInput>
  kind: ScheduleKind
  name?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Scalars['Int']>
  timeBlocks: Array<InputMaybe<MeetingTypeTimeBlockSerializerInput>>
  timezone: Timezone
}

export type MeetingTypeTeamMemberNode = Node & {
  __typename?: 'MeetingTypeTeamMemberNode'
  active: Scalars['Boolean']
  customAvailability: Scalars['Boolean']
  /** The ID of the object. */
  id: Scalars['ID']
  meetingType: MeetingTypeNode
  order: Scalars['Int']
  priority: Scalars['Int']
  schedules: TeamMemberScheduleNodeConnection
  teamMember: TeamMemberNode
  willHost: Scalars['Boolean']
}

export type MeetingTypeTeamMemberNodeSchedulesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MeetingTypeTeamMemberNodeConnection = {
  __typename?: 'MeetingTypeTeamMemberNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MeetingTypeTeamMemberNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MeetingTypeTeamMemberNode` and its cursor. */
export type MeetingTypeTeamMemberNodeEdge = {
  __typename?: 'MeetingTypeTeamMemberNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<MeetingTypeTeamMemberNode>
}

export type MeetingTypeTeamMemberSerializerInput = {
  active?: InputMaybe<Scalars['Boolean']>
  customAvailability?: InputMaybe<Scalars['Boolean']>
  order: Scalars['Int']
  priority: Scalars['Int']
  schedules: Array<InputMaybe<TeamMemberScheduleSerializerInput>>
  teamMember: Scalars['ID']
  willHost?: InputMaybe<Scalars['Boolean']>
}

export type MeetingTypeTimeBlockNode = Node & {
  __typename?: 'MeetingTypeTimeBlockNode'
  date: Maybe<Scalars['Date']>
  endTime: Maybe<Scalars['Time']>
  frequency: Maybe<Scalars['Timedelta']>
  /** The ID of the object. */
  id: Scalars['ID']
  schedule: MeetingTypeScheduleNode
  startTime: Scalars['Time']
  weekday: Maybe<Weekday>
}

export type MeetingTypeTimeBlockNodeConnection = {
  __typename?: 'MeetingTypeTimeBlockNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MeetingTypeTimeBlockNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MeetingTypeTimeBlockNode` and its cursor. */
export type MeetingTypeTimeBlockNodeEdge = {
  __typename?: 'MeetingTypeTimeBlockNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<MeetingTypeTimeBlockNode>
}

export type MeetingTypeTimeBlockSerializerInput = {
  date?: InputMaybe<Scalars['Date']>
  endTime?: InputMaybe<Scalars['Time']>
  frequency?: InputMaybe<Scalars['String']>
  startTime: Scalars['Time']
  weekday?: InputMaybe<Weekday>
}

export type MemberNode = Node & {
  __typename?: 'MemberNode'
  allowsSyncsFromCalendar: Scalars['Boolean']
  availabilityCalendars: CalendarNodeConnection
  calendarAccounts: CalendarAccountNodeConnection
  conferencingAccounts: ConferencingAccountNodeConnection
  createdAt: Scalars['DateTime']
  deleted: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  invitationAccepted: Scalars['Boolean']
  isOwner: Scalars['Boolean']
  isSchedulable: Scalars['Boolean']
  meetingCount: Scalars['Int']
  meetingsCalendar: Maybe<CalendarNode>
  profile: ProfileNode
  role: RoleNode
  schedules: ScheduleNodeConnection
  sendMeetingStatusNotifications: Scalars['Boolean']
  teams: TeamNodeConnection
  user: UserNode
  webhooks: WebhookNodeConnection
}

export type MemberNodeAvailabilityCalendarsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MemberNodeCalendarAccountsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MemberNodeConferencingAccountsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type MemberNodeSchedulesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MemberNodeTeamsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  hasMember?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MemberNodeWebhooksArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type MemberNodeConnection = {
  __typename?: 'MemberNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MemberNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `MemberNode` and its cursor. */
export type MemberNodeEdge = {
  __typename?: 'MemberNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<MemberNode>
}

/** Connected Microsoft Teams account. */
export type MicrosoftTeamsAccountNode = ConferencingAccountNode & {
  __typename?: 'MicrosoftTeamsAccountNode'
  createdAt: Scalars['DateTime']
  deleted: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  isConnected: Scalars['Boolean']
  isDefault: Scalars['Boolean']
  name: Scalars['String']
  provider: ConferenceProviderNode
}

export type MoveMeetingTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  index: Scalars['Int']
}

/** Reorders a MeetingType */
export type MoveMeetingTypePayload = {
  __typename?: 'MoveMeetingTypePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<Array<Maybe<MeetingTypeNode>>>
}

/** mutations */
export type Mutations = {
  __typename?: 'Mutations'
  approveAttendees: Maybe<ApproveAttendeesPayload>
  approveMeeting: Maybe<ApproveMeetingPayload>
  cancelAttendees: Maybe<CancelAttendeesPayload>
  cancelMeeting: Maybe<CancelMeetingPayload>
  cancelSubscription: Maybe<CancelSubscriptionPayload>
  cloneMeetingType: Maybe<CloneMeetingTypePayload>
  confirmAttendees: Maybe<ConfirmAttendeesPayload>
  confirmMeeting: Maybe<ConfirmMeetingPayload>
  createCalendarAccount: Maybe<CreateCalendarAccountPayload>
  createConferencingAccount: Maybe<CreateConferencingAccountPayload>
  createMeetingNote: Maybe<CreateMeetingNotePayload>
  createMeetingReport: Maybe<CreateMeetingReportPayload>
  createMeetingType: Maybe<CreateMeetingTypePayload>
  createPaymentCollectionAccount: Maybe<CreatePaymentCollectionAccountPayload>
  createProfile: Maybe<CreateProfilePayload>
  createSchedule: Maybe<CreateSchedulePayload>
  createTeam: Maybe<CreateTeamPayload>
  createWebhook: Maybe<CreateWebhookPayload>
  declineAttendees: Maybe<DeclineAttendeesPayload>
  declineMeeting: Maybe<DeclineMeetingPayload>
  deleteCalendarAccount: Maybe<DeleteCalendarAccountPayload>
  deleteConferencingAccount: Maybe<DeleteConferencingAccountPayload>
  /** Deletes a MeetingNote */
  deleteMeetingNote: Maybe<DeleteMeetingNotePayload>
  deleteMeetingType: Maybe<DeleteMeetingTypePayload>
  deleteMember: Maybe<DeleteMemberPayload>
  deletePaymentCollectionAccount: Maybe<DeletePaymentCollectionAccountPayload>
  deleteSchedule: Maybe<DeleteSchedulePayload>
  deleteTeam: Maybe<DeleteTeamPayload>
  /** Deletes a Webhook */
  deleteWebhook: Maybe<DeleteWebhookPayload>
  inviteMembers: Maybe<InviteMembersPayload>
  /** Reorders a MeetingType */
  moveMeetingType: Maybe<MoveMeetingTypePayload>
  reconnectConferencingAccount: Maybe<ReconnectConferencingAccountPayload>
  rescheduleMeeting: Maybe<RescheduleMeetingPayload>
  resendMemberInvitation: Maybe<ResendMemberInvitationPayload>
  restoreMeetingType: Maybe<RestoreMeetingTypePayload>
  resumeSubscription: Maybe<ResumeSubscriptionPayload>
  setCalendarTransparency: Maybe<SetCalendarTransparencyPayload>
  setDefaultCalendar: Maybe<SetDefaultCalendarPayload>
  setProfileOwner: Maybe<SetProfileOwnerPayload>
  startStripePaymentMethodUpdateSession: Maybe<StartStripePaymentMethodUpdateSessionPayload>
  /** Synchronizes an account's calendar list */
  syncAccountCalendars: Maybe<SyncCalendarAccountCalendarsPayload>
  /** Synchronizes a calendar's events */
  syncCalendarEvents: Maybe<SyncCalendarEventsPayload>
  syncMeetingConference: Maybe<SyncMeetingConferencePayload>
  updateAttendee: Maybe<UpdateAttendeePayload>
  updateCalendarAccount: Maybe<UpdateCalendarAccountPayload>
  updateGoToMeetingAccount: Maybe<UpdateGoToMeetingAccountPayload>
  updateJoinMeAccount: Maybe<UpdateJoinMeAccountPayload>
  updateMeetingNote: Maybe<UpdateMeetingNotePayload>
  updateMeetingType: Maybe<UpdateMeetingTypePayload>
  updateMember: Maybe<UpdateMemberPayload>
  updatePaymentCollectionAccount: Maybe<UpdatePaymentCollectionAccountPayload>
  updateProfile: Maybe<UpdateProfilePayload>
  updateProfilePlan: Maybe<UpdateProfilePlanPayload>
  updateSchedule: Maybe<UpdateSchedulePayload>
  updateTeam: Maybe<UpdateTeamPayload>
  updateUser: Maybe<UpdateUserPayload>
  updateUserPassword: Maybe<UpdateUserPasswordPayload>
  updateWebexAccount: Maybe<UpdateWebexAccountPayload>
  updateWebhook: Maybe<UpdateWebhookPayload>
  updateZoomAccount: Maybe<UpdateZoomAccountPayload>
}

/** mutations */
export type MutationsApproveAttendeesArgs = {
  input: ApproveAttendeesInput
}

/** mutations */
export type MutationsApproveMeetingArgs = {
  input: ApproveMeetingInput
}

/** mutations */
export type MutationsCancelAttendeesArgs = {
  input: CancelAttendeesInput
}

/** mutations */
export type MutationsCancelMeetingArgs = {
  input: CancelMeetingInput
}

/** mutations */
export type MutationsCancelSubscriptionArgs = {
  input: CancelSubscriptionInput
}

/** mutations */
export type MutationsCloneMeetingTypeArgs = {
  input: CloneMeetingTypeInput
}

/** mutations */
export type MutationsConfirmAttendeesArgs = {
  input: ConfirmAttendeesInput
}

/** mutations */
export type MutationsConfirmMeetingArgs = {
  input: ConfirmMeetingInput
}

/** mutations */
export type MutationsCreateCalendarAccountArgs = {
  input: CreateCalendarAccountInput
}

/** mutations */
export type MutationsCreateConferencingAccountArgs = {
  input: CreateConferencingAccountInput
}

/** mutations */
export type MutationsCreateMeetingNoteArgs = {
  input: CreateMeetingNoteInput
}

/** mutations */
export type MutationsCreateMeetingReportArgs = {
  input: CreateMeetingReportInput
}

/** mutations */
export type MutationsCreateMeetingTypeArgs = {
  input: CreateMeetingTypeInput
}

/** mutations */
export type MutationsCreatePaymentCollectionAccountArgs = {
  input: CreatePaymentCollectionAccountInput
}

/** mutations */
export type MutationsCreateProfileArgs = {
  input: CreateProfileInput
}

/** mutations */
export type MutationsCreateScheduleArgs = {
  input: CreateScheduleInput
}

/** mutations */
export type MutationsCreateTeamArgs = {
  input: CreateTeamInput
}

/** mutations */
export type MutationsCreateWebhookArgs = {
  input: CreateWebhookInput
}

/** mutations */
export type MutationsDeclineAttendeesArgs = {
  input: DeclineAttendeesInput
}

/** mutations */
export type MutationsDeclineMeetingArgs = {
  input: DeclineMeetingInput
}

/** mutations */
export type MutationsDeleteCalendarAccountArgs = {
  input: DeleteCalendarAccountInput
}

/** mutations */
export type MutationsDeleteConferencingAccountArgs = {
  input: DeleteConferencingAccountInput
}

/** mutations */
export type MutationsDeleteMeetingNoteArgs = {
  input: DeleteMeetingNoteInput
}

/** mutations */
export type MutationsDeleteMeetingTypeArgs = {
  input: DeleteMeetingTypeInput
}

/** mutations */
export type MutationsDeleteMemberArgs = {
  input: DeleteMemberInput
}

/** mutations */
export type MutationsDeletePaymentCollectionAccountArgs = {
  input: DeletePaymentCollectionAccountInput
}

/** mutations */
export type MutationsDeleteScheduleArgs = {
  input: DeleteScheduleInput
}

/** mutations */
export type MutationsDeleteTeamArgs = {
  input: DeleteTeamInput
}

/** mutations */
export type MutationsDeleteWebhookArgs = {
  input: DeleteWebhookInput
}

/** mutations */
export type MutationsInviteMembersArgs = {
  input: InviteMembersInput
}

/** mutations */
export type MutationsMoveMeetingTypeArgs = {
  input: MoveMeetingTypeInput
}

/** mutations */
export type MutationsReconnectConferencingAccountArgs = {
  input: ReconnectConferencingAccountInput
}

/** mutations */
export type MutationsRescheduleMeetingArgs = {
  input: RescheduleMeetingInput
}

/** mutations */
export type MutationsResendMemberInvitationArgs = {
  input: ResendMemberInvitationInput
}

/** mutations */
export type MutationsRestoreMeetingTypeArgs = {
  input: RestoreMeetingTypeInput
}

/** mutations */
export type MutationsResumeSubscriptionArgs = {
  input: ResumeSubscriptionInput
}

/** mutations */
export type MutationsSetCalendarTransparencyArgs = {
  input: SetCalendarTransparencyInput
}

/** mutations */
export type MutationsSetDefaultCalendarArgs = {
  input: SetDefaultCalendarInput
}

/** mutations */
export type MutationsSetProfileOwnerArgs = {
  input: SetProfileOwnerInput
}

/** mutations */
export type MutationsStartStripePaymentMethodUpdateSessionArgs = {
  input: StartStripePaymentMethodUpdateSessionInput
}

/** mutations */
export type MutationsSyncAccountCalendarsArgs = {
  input: SyncCalendarAccountCalendarsInput
}

/** mutations */
export type MutationsSyncCalendarEventsArgs = {
  input: SyncCalendarEventsInput
}

/** mutations */
export type MutationsSyncMeetingConferenceArgs = {
  input: SyncMeetingConferenceInput
}

/** mutations */
export type MutationsUpdateAttendeeArgs = {
  input: UpdateAttendeeInput
}

/** mutations */
export type MutationsUpdateCalendarAccountArgs = {
  input: UpdateCalendarAccountInput
}

/** mutations */
export type MutationsUpdateGoToMeetingAccountArgs = {
  input: UpdateGoToMeetingAccountInput
}

/** mutations */
export type MutationsUpdateJoinMeAccountArgs = {
  input: UpdateJoinMeAccountInput
}

/** mutations */
export type MutationsUpdateMeetingNoteArgs = {
  input: UpdateMeetingNoteInput
}

/** mutations */
export type MutationsUpdateMeetingTypeArgs = {
  input: UpdateMeetingTypeInput
}

/** mutations */
export type MutationsUpdateMemberArgs = {
  input: UpdateMemberInput
}

/** mutations */
export type MutationsUpdatePaymentCollectionAccountArgs = {
  input: UpdatePaymentCollectionAccountInput
}

/** mutations */
export type MutationsUpdateProfileArgs = {
  input: UpdateProfileInput
}

/** mutations */
export type MutationsUpdateProfilePlanArgs = {
  input: UpdateProfilePlanInput
}

/** mutations */
export type MutationsUpdateScheduleArgs = {
  input: UpdateScheduleInput
}

/** mutations */
export type MutationsUpdateTeamArgs = {
  input: UpdateTeamInput
}

/** mutations */
export type MutationsUpdateUserArgs = {
  input: UpdateUserInput
}

/** mutations */
export type MutationsUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput
}

/** mutations */
export type MutationsUpdateWebexAccountArgs = {
  input: UpdateWebexAccountInput
}

/** mutations */
export type MutationsUpdateWebhookArgs = {
  input: UpdateWebhookInput
}

/** mutations */
export type MutationsUpdateZoomAccountArgs = {
  input: UpdateZoomAccountInput
}

/**
 * Custom Node class that uses just a plain ID instead of relay's
 * base64-encoded type/id combo thing.
 */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>
}

/** Representation of the Account model */
export type PaymentCollectionAccountNode = Node & {
  __typename?: 'PaymentCollectionAccountNode'
  createdAt: Scalars['DateTime']
  deleted: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  isConnected: Scalars['Boolean']
  name: Scalars['String']
  profile: Maybe<ProfileNode>
  provider: PaymentCollectionProviderNode
}

export type PaymentCollectionAccountNodeConnection = {
  __typename?: 'PaymentCollectionAccountNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentCollectionAccountNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `PaymentCollectionAccountNode` and its cursor. */
export type PaymentCollectionAccountNodeEdge = {
  __typename?: 'PaymentCollectionAccountNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<PaymentCollectionAccountNode>
}

/** GraphQL representation of Provider model */
export type PaymentCollectionProviderNode = Node & {
  __typename?: 'PaymentCollectionProviderNode'
  /** The ID of the object. */
  id: Scalars['ID']
  name: Scalars['String']
  oauth2AuthorizationUrl: Scalars['String']
  slug: Scalars['String']
}

/** GraphQL representation of Provider model */
export type PaymentCollectionProviderNodeOauth2AuthorizationUrlArgs = {
  profile: Scalars['ID']
}

export type PaymentCollectionProviderNodeConnection = {
  __typename?: 'PaymentCollectionProviderNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentCollectionProviderNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `PaymentCollectionProviderNode` and its cursor. */
export type PaymentCollectionProviderNodeEdge = {
  __typename?: 'PaymentCollectionProviderNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<PaymentCollectionProviderNode>
}

/** An enumeration. */
export enum PaymentRule {
  /** Payments are allowed but not required for booking */
  Allowed = 'ALLOWED',
  /** Payments are not configured */
  Disallowed = 'DISALLOWED',
  /** Payments are required for booking */
  Required = 'REQUIRED',
}

export type PaymentSourceNode = {
  __typename?: 'PaymentSourceNode'
  brand: Scalars['String']
  expiration: Scalars['String']
  id: Scalars['ID']
  last4: Scalars['String']
}

export type PlanNode = Node & {
  __typename?: 'PlanNode'
  allowEmailCustomization: Scalars['Boolean']
  allowFormFields: Scalars['Boolean']
  allowManualConfirm: Scalars['Boolean']
  allowPaymentCollection: Scalars['Boolean']
  allowPrivateMeetingTypes: Scalars['Boolean']
  allowRedirect: Scalars['Boolean']
  allowReminders: Scalars['Boolean']
  allowRoundRobin: Scalars['Boolean']
  allowUtmParameters: Scalars['Boolean']
  allowWebConferencing: Scalars['Boolean']
  allowWebhooks: Scalars['Boolean']
  allowZapier: Scalars['Boolean']
  freeDefault: Scalars['Boolean']
  /** The ID of the object. */
  id: Scalars['ID']
  name: Scalars['String']
  requireAppointletBranding: Scalars['Boolean']
  seatPriceMonthly: Scalars['Float']
  seatPriceYearly: Scalars['Float']
  stripeIdMonthly: Scalars['String']
  stripeIdYearly: Scalars['String']
  trialDefault: Scalars['Boolean']
}

export type PlanNodeConnection = {
  __typename?: 'PlanNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PlanNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `PlanNode` and its cursor. */
export type PlanNodeEdge = {
  __typename?: 'PlanNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<PlanNode>
}

export type ProfileNode = Node & {
  __typename?: 'ProfileNode'
  allocatedSeats: Maybe<Scalars['Int']>
  attendees: AttendeeNodeConnection
  billedAnnually: Scalars['Boolean']
  billingCustomerId: Maybe<Scalars['String']>
  billingSubscriptionId: Maybe<Scalars['String']>
  billingbotToken: Maybe<Scalars['String']>
  brandColor: Scalars['String']
  description: Maybe<Scalars['String']>
  featureFlags: Maybe<Array<Maybe<Scalars['String']>>>
  /** The ID of the object. */
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  meetings: MeetingNodeConnection
  members: MemberNodeConnection
  name: Maybe<Scalars['String']>
  needsOnboarding: Scalars['Boolean']
  occupiedSeats: Scalars['Int']
  paymentCollectionAccounts: PaymentCollectionAccountNodeConnection
  personal: Scalars['Boolean']
  plan: Maybe<PlanNode>
  redactMeetingDataAfter: Maybe<Scalars['Timedelta']>
  roles: RoleNodeConnection
  schedules: ScheduleNodeConnection
  showAppointletBranding: Scalars['Boolean']
  upcomingInvoiceAmount: Maybe<Scalars['Int']>
  webhooks: WebhookNodeConnection
}

export type ProfileNodeAttendeesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
}

export type ProfileNodeMeetingsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isCancelled?: InputMaybe<Scalars['Boolean']>
  isConfirmed?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  meetingTypes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  members?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<Scalars['String']>
  profile?: InputMaybe<Scalars['ID']>
  search?: InputMaybe<Scalars['String']>
  startOnOrAfter?: InputMaybe<Scalars['DateTime']>
  startOnOrBefore?: InputMaybe<Scalars['DateTime']>
}

export type ProfileNodeMembersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDeleted?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

export type ProfileNodePaymentCollectionAccountsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDeleted?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProfileNodeRolesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  profile?: InputMaybe<Scalars['ID']>
}

export type ProfileNodeSchedulesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ProfileNodeWebhooksArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type Query = {
  __typename?: 'Query'
  getAttendeeById: Maybe<AttendeeNode>
  getCalendarAccountById: Maybe<CalendarAccountNode>
  getCalendarProviderById: Maybe<CalendarProviderNode>
  getCalendarProviders: CalendarProviderNodeConnection
  getConferenceProviderById: Maybe<ConferenceProviderNode>
  getConferenceProviders: ConferenceProviderNodeConnection
  getConferencingAccountById: Maybe<ConferencingAccountNode>
  getDefaultPaymentSource: Maybe<PaymentSourceNode>
  getFreePlan: PlanNode
  getMeetingById: MeetingNode
  getMeetingCountsByDay: Maybe<Array<Maybe<MeetingCountNode>>>
  getMeetingTypeById: MeetingTypeNode
  getMeetingTypeTags: Maybe<Array<Maybe<Scalars['String']>>>
  getMemberById: MemberNode
  getPaymentCollectionAccountById: Maybe<PaymentCollectionAccountNode>
  getPaymentCollectionProviderById: Maybe<PaymentCollectionProviderNode>
  getPaymentCollectionProviders: PaymentCollectionProviderNodeConnection
  getPlanById: Maybe<PlanNode>
  getPlans: Maybe<PlanNodeConnection>
  getProfileById: ProfileNode
  getReportById: Maybe<ReportNode>
  getScheduleById: ScheduleNode
  getSubscription: SubscriptionNode
  getTeamById: TeamNode
  getTrialPlan: PlanNode
  getWebhookById: Maybe<WebhookNode>
  previewInvoice: Maybe<UpcomingInvoice>
  teamSlugAvailability: UniqueIdentifierAvailability
  user: Maybe<UserNode>
}

export type QueryGetAttendeeByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetCalendarAccountByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetCalendarProviderByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetCalendarProvidersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryGetConferenceProviderByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetConferenceProvidersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryGetConferencingAccountByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetDefaultPaymentSourceArgs = {
  profile: Scalars['ID']
}

export type QueryGetMeetingByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetMeetingCountsByDayArgs = {
  end: Scalars['Date']
  members?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  profile?: InputMaybe<Scalars['ID']>
  start: Scalars['Date']
  timezone?: InputMaybe<Scalars['String']>
}

export type QueryGetMeetingTypeByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetMeetingTypeTagsArgs = {
  profile: Scalars['ID']
}

export type QueryGetMemberByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetPaymentCollectionAccountByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetPaymentCollectionProviderByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetPaymentCollectionProvidersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryGetPlanByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetPlansArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type QueryGetProfileByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetReportByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetScheduleByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetSubscriptionArgs = {
  profile: Scalars['ID']
}

export type QueryGetTeamByIdArgs = {
  id: Scalars['ID']
}

export type QueryGetWebhookByIdArgs = {
  id: Scalars['ID']
}

export type QueryPreviewInvoiceArgs = {
  billedAnnually?: InputMaybe<Scalars['Boolean']>
  profile: Scalars['ID']
}

export type QueryTeamSlugAvailabilityArgs = {
  slug: Scalars['String']
}

export type ReconnectConferencingAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  oauth2Code: Scalars['String']
}

export type ReconnectConferencingAccountPayload = {
  __typename?: 'ReconnectConferencingAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<ConferencingAccountNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type ReportNode = Node & {
  __typename?: 'ReportNode'
  completedAt: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  member: MemberNode
  params: Maybe<Scalars['JSONString']>
  reportType: ReportReportType
  startedAt: Maybe<Scalars['DateTime']>
  status: ReportStatus
  url: Maybe<Scalars['String']>
}

/** An enumeration. */
export enum ReportReportType {
  /** attendees */
  Attendees = 'ATTENDEES',
}

/** An enumeration. */
export enum ReportStatus {
  /** completed */
  Completed = 'COMPLETED',
  /** failed */
  Failed = 'FAILED',
  /** started */
  Started = 'STARTED',
  /** unstarted */
  Unstarted = 'UNSTARTED',
}

export type RescheduleMeetingInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  message?: InputMaybe<Scalars['String']>
  start: Scalars['DateTime']
}

export type RescheduleMeetingPayload = {
  __typename?: 'RescheduleMeetingPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type ResendMemberInvitationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type ResendMemberInvitationPayload = {
  __typename?: 'ResendMemberInvitationPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MemberNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type RestoreMeetingTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type RestoreMeetingTypePayload = {
  __typename?: 'RestoreMeetingTypePayload'
  clientMutationId: Maybe<Scalars['String']>
  ok: Maybe<Scalars['Boolean']>
}

export type ResumeSubscriptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  profile: Scalars['ID']
}

export type ResumeSubscriptionPayload = {
  __typename?: 'ResumeSubscriptionPayload'
  clientMutationId: Maybe<Scalars['String']>
  ok: Maybe<Scalars['Boolean']>
}

export type RoleNode = Node & {
  __typename?: 'RoleNode'
  canManageBilling: Scalars['Boolean']
  canManageMeetingTypes: Scalars['Boolean']
  canManageMeetings: Scalars['Boolean']
  canManageMembers: Scalars['Boolean']
  canManageOrgSettings: Scalars['Boolean']
  default: Scalars['Boolean']
  /** The ID of the object. */
  id: Scalars['ID']
  name: Maybe<Scalars['String']>
  profile: Maybe<ProfileNode>
}

export type RoleNodeConnection = {
  __typename?: 'RoleNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RoleNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `RoleNode` and its cursor. */
export type RoleNodeEdge = {
  __typename?: 'RoleNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<RoleNode>
}

/** An enumeration. */
export enum ScheduleKind {
  /** Schedule runs for a limited time. */
  FixedRange = 'FIXED_RANGE',
  /** Schedule recurs regularly. */
  Recurring = 'RECURRING',
  /** Schedule is applicable to a single day. */
  SingleDate = 'SINGLE_DATE',
}

export type ScheduleNode = Node & {
  __typename?: 'ScheduleNode'
  bounds: Maybe<DateRangeNode>
  /** The ID of the object. */
  id: Scalars['ID']
  member: Maybe<MemberNode>
  name: Scalars['String']
  profile: ProfileNode
  timeBlocks: TimeBlockNodeConnection
  timezone: Timezone
}

export type ScheduleNodeTimeBlocksArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type ScheduleNodeConnection = {
  __typename?: 'ScheduleNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ScheduleNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `ScheduleNode` and its cursor. */
export type ScheduleNodeEdge = {
  __typename?: 'ScheduleNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<ScheduleNode>
}

export type SetCalendarTransparencyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  transparent: Scalars['Boolean']
}

export type SetCalendarTransparencyPayload = {
  __typename?: 'SetCalendarTransparencyPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<CalendarNode>
}

export type SetDefaultCalendarInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type SetDefaultCalendarPayload = {
  __typename?: 'SetDefaultCalendarPayload'
  clientMutationId: Maybe<Scalars['String']>
  new: Maybe<CalendarNode>
  old: Maybe<CalendarNode>
}

export type SetProfileOwnerInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  member: Scalars['ID']
  profile: Scalars['ID']
}

export type SetProfileOwnerPayload = {
  __typename?: 'SetProfileOwnerPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MemberNode>
}

export type StartStripePaymentMethodUpdateSessionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  profile: Scalars['ID']
}

export type StartStripePaymentMethodUpdateSessionPayload = {
  __typename?: 'StartStripePaymentMethodUpdateSessionPayload'
  clientMutationId: Maybe<Scalars['String']>
  errors: Maybe<Array<Maybe<ErrorType>>>
  sessionId: Maybe<Scalars['String']>
}

export type SubscriptionNode = {
  __typename?: 'SubscriptionNode'
  cycleEnd: Scalars['DateTime']
  cycleInterval: Scalars['String']
  id: Scalars['ID']
  markedForCancelation: Scalars['Boolean']
  paymentType: Scalars['String']
  seats: Scalars['Int']
  status: Scalars['String']
  trialEnd: Maybe<Scalars['DateTime']>
}

export type SyncCalendarAccountCalendarsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Synchronizes an account's calendar list */
export type SyncCalendarAccountCalendarsPayload = {
  __typename?: 'SyncCalendarAccountCalendarsPayload'
  clientMutationId: Maybe<Scalars['String']>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type SyncCalendarEventsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Synchronizes a calendar's events */
export type SyncCalendarEventsPayload = {
  __typename?: 'SyncCalendarEventsPayload'
  clientMutationId: Maybe<Scalars['String']>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type SyncMeetingConferenceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  meeting: Scalars['ID']
}

export type SyncMeetingConferencePayload = {
  __typename?: 'SyncMeetingConferencePayload'
  clientMutationId: Maybe<Scalars['String']>
  meeting: Maybe<MeetingNode>
}

export type TeamMemberNode = Node & {
  __typename?: 'TeamMemberNode'
  /** The ID of the object. */
  id: Scalars['ID']
  member: MemberNode
  team: TeamNode
}

export type TeamMemberNodeConnection = {
  __typename?: 'TeamMemberNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamMemberNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `TeamMemberNode` and its cursor. */
export type TeamMemberNodeEdge = {
  __typename?: 'TeamMemberNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<TeamMemberNode>
}

export type TeamMemberScheduleNode = Node & {
  __typename?: 'TeamMemberScheduleNode'
  bounds: Maybe<DateRangeNode>
  /** The ID of the object. */
  id: Scalars['ID']
  kind: ScheduleKind
  name: Maybe<Scalars['String']>
  order: Scalars['Int']
  teamMember: MeetingTypeTeamMemberNode
  timeBlocks: TeamMemberTimeBlockNodeConnection
  timezone: Timezone
}

export type TeamMemberScheduleNodeTimeBlocksArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type TeamMemberScheduleNodeConnection = {
  __typename?: 'TeamMemberScheduleNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamMemberScheduleNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `TeamMemberScheduleNode` and its cursor. */
export type TeamMemberScheduleNodeEdge = {
  __typename?: 'TeamMemberScheduleNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<TeamMemberScheduleNode>
}

export type TeamMemberScheduleSerializerInput = {
  bounds?: InputMaybe<DateRangeInput>
  kind: ScheduleKind
  name?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Scalars['Int']>
  timeBlocks: Array<InputMaybe<TeamMemberTimeBlockSerializerInput>>
  timezone: Timezone
}

export type TeamMemberTimeBlockNode = Node & {
  __typename?: 'TeamMemberTimeBlockNode'
  date: Maybe<Scalars['Date']>
  endTime: Maybe<Scalars['Time']>
  frequency: Maybe<Scalars['Timedelta']>
  /** The ID of the object. */
  id: Scalars['ID']
  schedule: TeamMemberScheduleNode
  startTime: Scalars['Time']
  weekday: Maybe<Weekday>
}

export type TeamMemberTimeBlockNodeConnection = {
  __typename?: 'TeamMemberTimeBlockNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamMemberTimeBlockNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `TeamMemberTimeBlockNode` and its cursor. */
export type TeamMemberTimeBlockNodeEdge = {
  __typename?: 'TeamMemberTimeBlockNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<TeamMemberTimeBlockNode>
}

export type TeamMemberTimeBlockSerializerInput = {
  date?: InputMaybe<Scalars['Date']>
  endTime?: InputMaybe<Scalars['Time']>
  frequency?: InputMaybe<Scalars['String']>
  startTime: Scalars['Time']
  weekday?: InputMaybe<Weekday>
}

export type TeamNode = Node & {
  __typename?: 'TeamNode'
  bookingUrl: Scalars['String']
  createdAt: Scalars['DateTime']
  deleted: Maybe<Scalars['DateTime']>
  description: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  language: Language
  meetingTypeCount: Scalars['Int']
  meetingTypes: MeetingTypeNodeConnection
  name: Scalars['String']
  private: Scalars['Boolean']
  profile: ProfileNode
  slug: Scalars['String']
  teamMembers: TeamMemberNodeConnection
}

export type TeamNodeMeetingTypesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDeleted?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

export type TeamNodeTeamMembersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type TeamNodeConnection = {
  __typename?: 'TeamNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeamNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `TeamNode` and its cursor. */
export type TeamNodeEdge = {
  __typename?: 'TeamNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<TeamNode>
}

export type TimeBlockNode = Node & {
  __typename?: 'TimeBlockNode'
  date: Maybe<Scalars['Date']>
  end: Scalars['Time']
  /** The ID of the object. */
  id: Scalars['ID']
  schedule: ScheduleNode
  start: Scalars['Time']
  weekday: Maybe<Weekday>
}

export type TimeBlockNodeConnection = {
  __typename?: 'TimeBlockNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TimeBlockNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `TimeBlockNode` and its cursor. */
export type TimeBlockNodeEdge = {
  __typename?: 'TimeBlockNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<TimeBlockNode>
}

export type TimeBlockSerializerInput = {
  date?: InputMaybe<Scalars['Date']>
  end: Scalars['Time']
  start: Scalars['Time']
  weekday?: InputMaybe<Weekday>
}

/** An enumeration. */
export enum Timezone {
  /** Africa/Abidjan */
  AfricaAbidjan = 'AFRICA_ABIDJAN',
  /** Africa/Accra */
  AfricaAccra = 'AFRICA_ACCRA',
  /** Africa/Addis_Ababa */
  AfricaAddisAbaba = 'AFRICA_ADDIS_ABABA',
  /** Africa/Algiers */
  AfricaAlgiers = 'AFRICA_ALGIERS',
  /** Africa/Asmara */
  AfricaAsmara = 'AFRICA_ASMARA',
  /** Africa/Bamako */
  AfricaBamako = 'AFRICA_BAMAKO',
  /** Africa/Bangui */
  AfricaBangui = 'AFRICA_BANGUI',
  /** Africa/Banjul */
  AfricaBanjul = 'AFRICA_BANJUL',
  /** Africa/Bissau */
  AfricaBissau = 'AFRICA_BISSAU',
  /** Africa/Blantyre */
  AfricaBlantyre = 'AFRICA_BLANTYRE',
  /** Africa/Brazzaville */
  AfricaBrazzaville = 'AFRICA_BRAZZAVILLE',
  /** Africa/Bujumbura */
  AfricaBujumbura = 'AFRICA_BUJUMBURA',
  /** Africa/Cairo */
  AfricaCairo = 'AFRICA_CAIRO',
  /** Africa/Casablanca */
  AfricaCasablanca = 'AFRICA_CASABLANCA',
  /** Africa/Ceuta */
  AfricaCeuta = 'AFRICA_CEUTA',
  /** Africa/Conakry */
  AfricaConakry = 'AFRICA_CONAKRY',
  /** Africa/Dakar */
  AfricaDakar = 'AFRICA_DAKAR',
  /** Africa/Dar_es_Salaam */
  AfricaDarEsSalaam = 'AFRICA_DAR_ES_SALAAM',
  /** Africa/Djibouti */
  AfricaDjibouti = 'AFRICA_DJIBOUTI',
  /** Africa/Douala */
  AfricaDouala = 'AFRICA_DOUALA',
  /** Africa/El_Aaiun */
  AfricaElAaiun = 'AFRICA_EL_AAIUN',
  /** Africa/Freetown */
  AfricaFreetown = 'AFRICA_FREETOWN',
  /** Africa/Gaborone */
  AfricaGaborone = 'AFRICA_GABORONE',
  /** Africa/Harare */
  AfricaHarare = 'AFRICA_HARARE',
  /** Africa/Johannesburg */
  AfricaJohannesburg = 'AFRICA_JOHANNESBURG',
  /** Africa/Juba */
  AfricaJuba = 'AFRICA_JUBA',
  /** Africa/Kampala */
  AfricaKampala = 'AFRICA_KAMPALA',
  /** Africa/Khartoum */
  AfricaKhartoum = 'AFRICA_KHARTOUM',
  /** Africa/Kigali */
  AfricaKigali = 'AFRICA_KIGALI',
  /** Africa/Kinshasa */
  AfricaKinshasa = 'AFRICA_KINSHASA',
  /** Africa/Lagos */
  AfricaLagos = 'AFRICA_LAGOS',
  /** Africa/Libreville */
  AfricaLibreville = 'AFRICA_LIBREVILLE',
  /** Africa/Lome */
  AfricaLome = 'AFRICA_LOME',
  /** Africa/Luanda */
  AfricaLuanda = 'AFRICA_LUANDA',
  /** Africa/Lubumbashi */
  AfricaLubumbashi = 'AFRICA_LUBUMBASHI',
  /** Africa/Lusaka */
  AfricaLusaka = 'AFRICA_LUSAKA',
  /** Africa/Malabo */
  AfricaMalabo = 'AFRICA_MALABO',
  /** Africa/Maputo */
  AfricaMaputo = 'AFRICA_MAPUTO',
  /** Africa/Maseru */
  AfricaMaseru = 'AFRICA_MASERU',
  /** Africa/Mbabane */
  AfricaMbabane = 'AFRICA_MBABANE',
  /** Africa/Mogadishu */
  AfricaMogadishu = 'AFRICA_MOGADISHU',
  /** Africa/Monrovia */
  AfricaMonrovia = 'AFRICA_MONROVIA',
  /** Africa/Nairobi */
  AfricaNairobi = 'AFRICA_NAIROBI',
  /** Africa/Ndjamena */
  AfricaNdjamena = 'AFRICA_NDJAMENA',
  /** Africa/Niamey */
  AfricaNiamey = 'AFRICA_NIAMEY',
  /** Africa/Nouakchott */
  AfricaNouakchott = 'AFRICA_NOUAKCHOTT',
  /** Africa/Ouagadougou */
  AfricaOuagadougou = 'AFRICA_OUAGADOUGOU',
  /** Africa/Porto-Novo */
  AfricaPortoNovo = 'AFRICA_PORTO_NOVO',
  /** Africa/Sao_Tome */
  AfricaSaoTome = 'AFRICA_SAO_TOME',
  /** Africa/Tripoli */
  AfricaTripoli = 'AFRICA_TRIPOLI',
  /** Africa/Tunis */
  AfricaTunis = 'AFRICA_TUNIS',
  /** Africa/Windhoek */
  AfricaWindhoek = 'AFRICA_WINDHOEK',
  /** America/Adak */
  AmericaAdak = 'AMERICA_ADAK',
  /** America/Anchorage */
  AmericaAnchorage = 'AMERICA_ANCHORAGE',
  /** America/Anguilla */
  AmericaAnguilla = 'AMERICA_ANGUILLA',
  /** America/Antigua */
  AmericaAntigua = 'AMERICA_ANTIGUA',
  /** America/Araguaina */
  AmericaAraguaina = 'AMERICA_ARAGUAINA',
  /** America/Argentina/Buenos_Aires */
  AmericaArgentinaBuenosAires = 'AMERICA_ARGENTINA_BUENOS_AIRES',
  /** America/Argentina/Catamarca */
  AmericaArgentinaCatamarca = 'AMERICA_ARGENTINA_CATAMARCA',
  /** America/Argentina/Cordoba */
  AmericaArgentinaCordoba = 'AMERICA_ARGENTINA_CORDOBA',
  /** America/Argentina/Jujuy */
  AmericaArgentinaJujuy = 'AMERICA_ARGENTINA_JUJUY',
  /** America/Argentina/La_Rioja */
  AmericaArgentinaLaRioja = 'AMERICA_ARGENTINA_LA_RIOJA',
  /** America/Argentina/Mendoza */
  AmericaArgentinaMendoza = 'AMERICA_ARGENTINA_MENDOZA',
  /** America/Argentina/Rio_Gallegos */
  AmericaArgentinaRioGallegos = 'AMERICA_ARGENTINA_RIO_GALLEGOS',
  /** America/Argentina/Salta */
  AmericaArgentinaSalta = 'AMERICA_ARGENTINA_SALTA',
  /** America/Argentina/San_Juan */
  AmericaArgentinaSanJuan = 'AMERICA_ARGENTINA_SAN_JUAN',
  /** America/Argentina/San_Luis */
  AmericaArgentinaSanLuis = 'AMERICA_ARGENTINA_SAN_LUIS',
  /** America/Argentina/Tucuman */
  AmericaArgentinaTucuman = 'AMERICA_ARGENTINA_TUCUMAN',
  /** America/Argentina/Ushuaia */
  AmericaArgentinaUshuaia = 'AMERICA_ARGENTINA_USHUAIA',
  /** America/Aruba */
  AmericaAruba = 'AMERICA_ARUBA',
  /** America/Asuncion */
  AmericaAsuncion = 'AMERICA_ASUNCION',
  /** America/Atikokan */
  AmericaAtikokan = 'AMERICA_ATIKOKAN',
  /** America/Bahia */
  AmericaBahia = 'AMERICA_BAHIA',
  /** America/Bahia_Banderas */
  AmericaBahiaBanderas = 'AMERICA_BAHIA_BANDERAS',
  /** America/Barbados */
  AmericaBarbados = 'AMERICA_BARBADOS',
  /** America/Belem */
  AmericaBelem = 'AMERICA_BELEM',
  /** America/Belize */
  AmericaBelize = 'AMERICA_BELIZE',
  /** America/Blanc-Sablon */
  AmericaBlancSablon = 'AMERICA_BLANC_SABLON',
  /** America/Boa_Vista */
  AmericaBoaVista = 'AMERICA_BOA_VISTA',
  /** America/Bogota */
  AmericaBogota = 'AMERICA_BOGOTA',
  /** America/Boise */
  AmericaBoise = 'AMERICA_BOISE',
  /** America/Cambridge_Bay */
  AmericaCambridgeBay = 'AMERICA_CAMBRIDGE_BAY',
  /** America/Campo_Grande */
  AmericaCampoGrande = 'AMERICA_CAMPO_GRANDE',
  /** America/Cancun */
  AmericaCancun = 'AMERICA_CANCUN',
  /** America/Caracas */
  AmericaCaracas = 'AMERICA_CARACAS',
  /** America/Cayenne */
  AmericaCayenne = 'AMERICA_CAYENNE',
  /** America/Cayman */
  AmericaCayman = 'AMERICA_CAYMAN',
  /** America/Chicago */
  AmericaChicago = 'AMERICA_CHICAGO',
  /** America/Chihuahua */
  AmericaChihuahua = 'AMERICA_CHIHUAHUA',
  /** America/Costa_Rica */
  AmericaCostaRica = 'AMERICA_COSTA_RICA',
  /** America/Creston */
  AmericaCreston = 'AMERICA_CRESTON',
  /** America/Cuiaba */
  AmericaCuiaba = 'AMERICA_CUIABA',
  /** America/Curacao */
  AmericaCuracao = 'AMERICA_CURACAO',
  /** America/Danmarkshavn */
  AmericaDanmarkshavn = 'AMERICA_DANMARKSHAVN',
  /** America/Dawson */
  AmericaDawson = 'AMERICA_DAWSON',
  /** America/Dawson_Creek */
  AmericaDawsonCreek = 'AMERICA_DAWSON_CREEK',
  /** America/Denver */
  AmericaDenver = 'AMERICA_DENVER',
  /** America/Detroit */
  AmericaDetroit = 'AMERICA_DETROIT',
  /** America/Dominica */
  AmericaDominica = 'AMERICA_DOMINICA',
  /** America/Edmonton */
  AmericaEdmonton = 'AMERICA_EDMONTON',
  /** America/Eirunepe */
  AmericaEirunepe = 'AMERICA_EIRUNEPE',
  /** America/El_Salvador */
  AmericaElSalvador = 'AMERICA_EL_SALVADOR',
  /** America/Fortaleza */
  AmericaFortaleza = 'AMERICA_FORTALEZA',
  /** America/Fort_Nelson */
  AmericaFortNelson = 'AMERICA_FORT_NELSON',
  /** America/Glace_Bay */
  AmericaGlaceBay = 'AMERICA_GLACE_BAY',
  /** America/Goose_Bay */
  AmericaGooseBay = 'AMERICA_GOOSE_BAY',
  /** America/Grand_Turk */
  AmericaGrandTurk = 'AMERICA_GRAND_TURK',
  /** America/Grenada */
  AmericaGrenada = 'AMERICA_GRENADA',
  /** America/Guadeloupe */
  AmericaGuadeloupe = 'AMERICA_GUADELOUPE',
  /** America/Guatemala */
  AmericaGuatemala = 'AMERICA_GUATEMALA',
  /** America/Guayaquil */
  AmericaGuayaquil = 'AMERICA_GUAYAQUIL',
  /** America/Guyana */
  AmericaGuyana = 'AMERICA_GUYANA',
  /** America/Halifax */
  AmericaHalifax = 'AMERICA_HALIFAX',
  /** America/Havana */
  AmericaHavana = 'AMERICA_HAVANA',
  /** America/Hermosillo */
  AmericaHermosillo = 'AMERICA_HERMOSILLO',
  /** America/Indiana/Indianapolis */
  AmericaIndianaIndianapolis = 'AMERICA_INDIANA_INDIANAPOLIS',
  /** America/Indiana/Knox */
  AmericaIndianaKnox = 'AMERICA_INDIANA_KNOX',
  /** America/Indiana/Marengo */
  AmericaIndianaMarengo = 'AMERICA_INDIANA_MARENGO',
  /** America/Indiana/Petersburg */
  AmericaIndianaPetersburg = 'AMERICA_INDIANA_PETERSBURG',
  /** America/Indiana/Tell_City */
  AmericaIndianaTellCity = 'AMERICA_INDIANA_TELL_CITY',
  /** America/Indiana/Vevay */
  AmericaIndianaVevay = 'AMERICA_INDIANA_VEVAY',
  /** America/Indiana/Vincennes */
  AmericaIndianaVincennes = 'AMERICA_INDIANA_VINCENNES',
  /** America/Indiana/Winamac */
  AmericaIndianaWinamac = 'AMERICA_INDIANA_WINAMAC',
  /** America/Inuvik */
  AmericaInuvik = 'AMERICA_INUVIK',
  /** America/Iqaluit */
  AmericaIqaluit = 'AMERICA_IQALUIT',
  /** America/Jamaica */
  AmericaJamaica = 'AMERICA_JAMAICA',
  /** America/Juneau */
  AmericaJuneau = 'AMERICA_JUNEAU',
  /** America/Kentucky/Louisville */
  AmericaKentuckyLouisville = 'AMERICA_KENTUCKY_LOUISVILLE',
  /** America/Kentucky/Monticello */
  AmericaKentuckyMonticello = 'AMERICA_KENTUCKY_MONTICELLO',
  /** America/Kralendijk */
  AmericaKralendijk = 'AMERICA_KRALENDIJK',
  /** America/La_Paz */
  AmericaLaPaz = 'AMERICA_LA_PAZ',
  /** America/Lima */
  AmericaLima = 'AMERICA_LIMA',
  /** America/Los_Angeles */
  AmericaLosAngeles = 'AMERICA_LOS_ANGELES',
  /** America/Lower_Princes */
  AmericaLowerPrinces = 'AMERICA_LOWER_PRINCES',
  /** America/Maceio */
  AmericaMaceio = 'AMERICA_MACEIO',
  /** America/Managua */
  AmericaManagua = 'AMERICA_MANAGUA',
  /** America/Manaus */
  AmericaManaus = 'AMERICA_MANAUS',
  /** America/Marigot */
  AmericaMarigot = 'AMERICA_MARIGOT',
  /** America/Martinique */
  AmericaMartinique = 'AMERICA_MARTINIQUE',
  /** America/Matamoros */
  AmericaMatamoros = 'AMERICA_MATAMOROS',
  /** America/Mazatlan */
  AmericaMazatlan = 'AMERICA_MAZATLAN',
  /** America/Menominee */
  AmericaMenominee = 'AMERICA_MENOMINEE',
  /** America/Merida */
  AmericaMerida = 'AMERICA_MERIDA',
  /** America/Metlakatla */
  AmericaMetlakatla = 'AMERICA_METLAKATLA',
  /** America/Mexico_City */
  AmericaMexicoCity = 'AMERICA_MEXICO_CITY',
  /** America/Miquelon */
  AmericaMiquelon = 'AMERICA_MIQUELON',
  /** America/Moncton */
  AmericaMoncton = 'AMERICA_MONCTON',
  /** America/Monterrey */
  AmericaMonterrey = 'AMERICA_MONTERREY',
  /** America/Montevideo */
  AmericaMontevideo = 'AMERICA_MONTEVIDEO',
  /** America/Montserrat */
  AmericaMontserrat = 'AMERICA_MONTSERRAT',
  /** America/Nassau */
  AmericaNassau = 'AMERICA_NASSAU',
  /** America/New_York */
  AmericaNewYork = 'AMERICA_NEW_YORK',
  /** America/Nipigon */
  AmericaNipigon = 'AMERICA_NIPIGON',
  /** America/Nome */
  AmericaNome = 'AMERICA_NOME',
  /** America/Noronha */
  AmericaNoronha = 'AMERICA_NORONHA',
  /** America/North_Dakota/Beulah */
  AmericaNorthDakotaBeulah = 'AMERICA_NORTH_DAKOTA_BEULAH',
  /** America/North_Dakota/Center */
  AmericaNorthDakotaCenter = 'AMERICA_NORTH_DAKOTA_CENTER',
  /** America/North_Dakota/New_Salem */
  AmericaNorthDakotaNewSalem = 'AMERICA_NORTH_DAKOTA_NEW_SALEM',
  /** America/Nuuk */
  AmericaNuuk = 'AMERICA_NUUK',
  /** America/Ojinaga */
  AmericaOjinaga = 'AMERICA_OJINAGA',
  /** America/Panama */
  AmericaPanama = 'AMERICA_PANAMA',
  /** America/Pangnirtung */
  AmericaPangnirtung = 'AMERICA_PANGNIRTUNG',
  /** America/Paramaribo */
  AmericaParamaribo = 'AMERICA_PARAMARIBO',
  /** America/Phoenix */
  AmericaPhoenix = 'AMERICA_PHOENIX',
  /** America/Porto_Velho */
  AmericaPortoVelho = 'AMERICA_PORTO_VELHO',
  /** America/Port-au-Prince */
  AmericaPortAuPrince = 'AMERICA_PORT_AU_PRINCE',
  /** America/Port_of_Spain */
  AmericaPortOfSpain = 'AMERICA_PORT_OF_SPAIN',
  /** America/Puerto_Rico */
  AmericaPuertoRico = 'AMERICA_PUERTO_RICO',
  /** America/Punta_Arenas */
  AmericaPuntaArenas = 'AMERICA_PUNTA_ARENAS',
  /** America/Rainy_River */
  AmericaRainyRiver = 'AMERICA_RAINY_RIVER',
  /** America/Rankin_Inlet */
  AmericaRankinInlet = 'AMERICA_RANKIN_INLET',
  /** America/Recife */
  AmericaRecife = 'AMERICA_RECIFE',
  /** America/Regina */
  AmericaRegina = 'AMERICA_REGINA',
  /** America/Resolute */
  AmericaResolute = 'AMERICA_RESOLUTE',
  /** America/Rio_Branco */
  AmericaRioBranco = 'AMERICA_RIO_BRANCO',
  /** America/Santarem */
  AmericaSantarem = 'AMERICA_SANTAREM',
  /** America/Santiago */
  AmericaSantiago = 'AMERICA_SANTIAGO',
  /** America/Santo_Domingo */
  AmericaSantoDomingo = 'AMERICA_SANTO_DOMINGO',
  /** America/Sao_Paulo */
  AmericaSaoPaulo = 'AMERICA_SAO_PAULO',
  /** America/Scoresbysund */
  AmericaScoresbysund = 'AMERICA_SCORESBYSUND',
  /** America/Sitka */
  AmericaSitka = 'AMERICA_SITKA',
  /** America/St_Barthelemy */
  AmericaStBarthelemy = 'AMERICA_ST_BARTHELEMY',
  /** America/St_Johns */
  AmericaStJohns = 'AMERICA_ST_JOHNS',
  /** America/St_Kitts */
  AmericaStKitts = 'AMERICA_ST_KITTS',
  /** America/St_Lucia */
  AmericaStLucia = 'AMERICA_ST_LUCIA',
  /** America/St_Thomas */
  AmericaStThomas = 'AMERICA_ST_THOMAS',
  /** America/St_Vincent */
  AmericaStVincent = 'AMERICA_ST_VINCENT',
  /** America/Swift_Current */
  AmericaSwiftCurrent = 'AMERICA_SWIFT_CURRENT',
  /** America/Tegucigalpa */
  AmericaTegucigalpa = 'AMERICA_TEGUCIGALPA',
  /** America/Thule */
  AmericaThule = 'AMERICA_THULE',
  /** America/Thunder_Bay */
  AmericaThunderBay = 'AMERICA_THUNDER_BAY',
  /** America/Tijuana */
  AmericaTijuana = 'AMERICA_TIJUANA',
  /** America/Toronto */
  AmericaToronto = 'AMERICA_TORONTO',
  /** America/Tortola */
  AmericaTortola = 'AMERICA_TORTOLA',
  /** America/Vancouver */
  AmericaVancouver = 'AMERICA_VANCOUVER',
  /** America/Whitehorse */
  AmericaWhitehorse = 'AMERICA_WHITEHORSE',
  /** America/Winnipeg */
  AmericaWinnipeg = 'AMERICA_WINNIPEG',
  /** America/Yakutat */
  AmericaYakutat = 'AMERICA_YAKUTAT',
  /** America/Yellowknife */
  AmericaYellowknife = 'AMERICA_YELLOWKNIFE',
  /** Antarctica/Casey */
  AntarcticaCasey = 'ANTARCTICA_CASEY',
  /** Antarctica/Davis */
  AntarcticaDavis = 'ANTARCTICA_DAVIS',
  /** Antarctica/DumontDUrville */
  AntarcticaDumontdurville = 'ANTARCTICA_DUMONTDURVILLE',
  /** Antarctica/Macquarie */
  AntarcticaMacquarie = 'ANTARCTICA_MACQUARIE',
  /** Antarctica/Mawson */
  AntarcticaMawson = 'ANTARCTICA_MAWSON',
  /** Antarctica/McMurdo */
  AntarcticaMcmurdo = 'ANTARCTICA_MCMURDO',
  /** Antarctica/Palmer */
  AntarcticaPalmer = 'ANTARCTICA_PALMER',
  /** Antarctica/Rothera */
  AntarcticaRothera = 'ANTARCTICA_ROTHERA',
  /** Antarctica/Syowa */
  AntarcticaSyowa = 'ANTARCTICA_SYOWA',
  /** Antarctica/Troll */
  AntarcticaTroll = 'ANTARCTICA_TROLL',
  /** Antarctica/Vostok */
  AntarcticaVostok = 'ANTARCTICA_VOSTOK',
  /** Arctic/Longyearbyen */
  ArcticLongyearbyen = 'ARCTIC_LONGYEARBYEN',
  /** Asia/Aden */
  AsiaAden = 'ASIA_ADEN',
  /** Asia/Almaty */
  AsiaAlmaty = 'ASIA_ALMATY',
  /** Asia/Amman */
  AsiaAmman = 'ASIA_AMMAN',
  /** Asia/Anadyr */
  AsiaAnadyr = 'ASIA_ANADYR',
  /** Asia/Aqtau */
  AsiaAqtau = 'ASIA_AQTAU',
  /** Asia/Aqtobe */
  AsiaAqtobe = 'ASIA_AQTOBE',
  /** Asia/Ashgabat */
  AsiaAshgabat = 'ASIA_ASHGABAT',
  /** Asia/Atyrau */
  AsiaAtyrau = 'ASIA_ATYRAU',
  /** Asia/Baghdad */
  AsiaBaghdad = 'ASIA_BAGHDAD',
  /** Asia/Bahrain */
  AsiaBahrain = 'ASIA_BAHRAIN',
  /** Asia/Baku */
  AsiaBaku = 'ASIA_BAKU',
  /** Asia/Bangkok */
  AsiaBangkok = 'ASIA_BANGKOK',
  /** Asia/Barnaul */
  AsiaBarnaul = 'ASIA_BARNAUL',
  /** Asia/Beirut */
  AsiaBeirut = 'ASIA_BEIRUT',
  /** Asia/Bishkek */
  AsiaBishkek = 'ASIA_BISHKEK',
  /** Asia/Brunei */
  AsiaBrunei = 'ASIA_BRUNEI',
  /** Asia/Chita */
  AsiaChita = 'ASIA_CHITA',
  /** Asia/Choibalsan */
  AsiaChoibalsan = 'ASIA_CHOIBALSAN',
  /** Asia/Colombo */
  AsiaColombo = 'ASIA_COLOMBO',
  /** Asia/Damascus */
  AsiaDamascus = 'ASIA_DAMASCUS',
  /** Asia/Dhaka */
  AsiaDhaka = 'ASIA_DHAKA',
  /** Asia/Dili */
  AsiaDili = 'ASIA_DILI',
  /** Asia/Dubai */
  AsiaDubai = 'ASIA_DUBAI',
  /** Asia/Dushanbe */
  AsiaDushanbe = 'ASIA_DUSHANBE',
  /** Asia/Famagusta */
  AsiaFamagusta = 'ASIA_FAMAGUSTA',
  /** Asia/Gaza */
  AsiaGaza = 'ASIA_GAZA',
  /** Asia/Hebron */
  AsiaHebron = 'ASIA_HEBRON',
  /** Asia/Hong_Kong */
  AsiaHongKong = 'ASIA_HONG_KONG',
  /** Asia/Hovd */
  AsiaHovd = 'ASIA_HOVD',
  /** Asia/Ho_Chi_Minh */
  AsiaHoChiMinh = 'ASIA_HO_CHI_MINH',
  /** Asia/Irkutsk */
  AsiaIrkutsk = 'ASIA_IRKUTSK',
  /** Asia/Jakarta */
  AsiaJakarta = 'ASIA_JAKARTA',
  /** Asia/Jayapura */
  AsiaJayapura = 'ASIA_JAYAPURA',
  /** Asia/Jerusalem */
  AsiaJerusalem = 'ASIA_JERUSALEM',
  /** Asia/Kabul */
  AsiaKabul = 'ASIA_KABUL',
  /** Asia/Kamchatka */
  AsiaKamchatka = 'ASIA_KAMCHATKA',
  /** Asia/Karachi */
  AsiaKarachi = 'ASIA_KARACHI',
  /** Asia/Kathmandu */
  AsiaKathmandu = 'ASIA_KATHMANDU',
  /** Asia/Khandyga */
  AsiaKhandyga = 'ASIA_KHANDYGA',
  /** Asia/Kolkata */
  AsiaKolkata = 'ASIA_KOLKATA',
  /** Asia/Krasnoyarsk */
  AsiaKrasnoyarsk = 'ASIA_KRASNOYARSK',
  /** Asia/Kuala_Lumpur */
  AsiaKualaLumpur = 'ASIA_KUALA_LUMPUR',
  /** Asia/Kuching */
  AsiaKuching = 'ASIA_KUCHING',
  /** Asia/Kuwait */
  AsiaKuwait = 'ASIA_KUWAIT',
  /** Asia/Macau */
  AsiaMacau = 'ASIA_MACAU',
  /** Asia/Magadan */
  AsiaMagadan = 'ASIA_MAGADAN',
  /** Asia/Makassar */
  AsiaMakassar = 'ASIA_MAKASSAR',
  /** Asia/Manila */
  AsiaManila = 'ASIA_MANILA',
  /** Asia/Muscat */
  AsiaMuscat = 'ASIA_MUSCAT',
  /** Asia/Nicosia */
  AsiaNicosia = 'ASIA_NICOSIA',
  /** Asia/Novokuznetsk */
  AsiaNovokuznetsk = 'ASIA_NOVOKUZNETSK',
  /** Asia/Novosibirsk */
  AsiaNovosibirsk = 'ASIA_NOVOSIBIRSK',
  /** Asia/Omsk */
  AsiaOmsk = 'ASIA_OMSK',
  /** Asia/Oral */
  AsiaOral = 'ASIA_ORAL',
  /** Asia/Phnom_Penh */
  AsiaPhnomPenh = 'ASIA_PHNOM_PENH',
  /** Asia/Pontianak */
  AsiaPontianak = 'ASIA_PONTIANAK',
  /** Asia/Pyongyang */
  AsiaPyongyang = 'ASIA_PYONGYANG',
  /** Asia/Qatar */
  AsiaQatar = 'ASIA_QATAR',
  /** Asia/Qostanay */
  AsiaQostanay = 'ASIA_QOSTANAY',
  /** Asia/Qyzylorda */
  AsiaQyzylorda = 'ASIA_QYZYLORDA',
  /** Asia/Riyadh */
  AsiaRiyadh = 'ASIA_RIYADH',
  /** Asia/Sakhalin */
  AsiaSakhalin = 'ASIA_SAKHALIN',
  /** Asia/Samarkand */
  AsiaSamarkand = 'ASIA_SAMARKAND',
  /** Asia/Seoul */
  AsiaSeoul = 'ASIA_SEOUL',
  /** Asia/Shanghai */
  AsiaShanghai = 'ASIA_SHANGHAI',
  /** Asia/Singapore */
  AsiaSingapore = 'ASIA_SINGAPORE',
  /** Asia/Srednekolymsk */
  AsiaSrednekolymsk = 'ASIA_SREDNEKOLYMSK',
  /** Asia/Taipei */
  AsiaTaipei = 'ASIA_TAIPEI',
  /** Asia/Tashkent */
  AsiaTashkent = 'ASIA_TASHKENT',
  /** Asia/Tbilisi */
  AsiaTbilisi = 'ASIA_TBILISI',
  /** Asia/Tehran */
  AsiaTehran = 'ASIA_TEHRAN',
  /** Asia/Thimphu */
  AsiaThimphu = 'ASIA_THIMPHU',
  /** Asia/Tokyo */
  AsiaTokyo = 'ASIA_TOKYO',
  /** Asia/Tomsk */
  AsiaTomsk = 'ASIA_TOMSK',
  /** Asia/Ulaanbaatar */
  AsiaUlaanbaatar = 'ASIA_ULAANBAATAR',
  /** Asia/Urumqi */
  AsiaUrumqi = 'ASIA_URUMQI',
  /** Asia/Ust-Nera */
  AsiaUstNera = 'ASIA_UST_NERA',
  /** Asia/Vientiane */
  AsiaVientiane = 'ASIA_VIENTIANE',
  /** Asia/Vladivostok */
  AsiaVladivostok = 'ASIA_VLADIVOSTOK',
  /** Asia/Yakutsk */
  AsiaYakutsk = 'ASIA_YAKUTSK',
  /** Asia/Yangon */
  AsiaYangon = 'ASIA_YANGON',
  /** Asia/Yekaterinburg */
  AsiaYekaterinburg = 'ASIA_YEKATERINBURG',
  /** Asia/Yerevan */
  AsiaYerevan = 'ASIA_YEREVAN',
  /** Atlantic/Azores */
  AtlanticAzores = 'ATLANTIC_AZORES',
  /** Atlantic/Bermuda */
  AtlanticBermuda = 'ATLANTIC_BERMUDA',
  /** Atlantic/Canary */
  AtlanticCanary = 'ATLANTIC_CANARY',
  /** Atlantic/Cape_Verde */
  AtlanticCapeVerde = 'ATLANTIC_CAPE_VERDE',
  /** Atlantic/Faroe */
  AtlanticFaroe = 'ATLANTIC_FAROE',
  /** Atlantic/Madeira */
  AtlanticMadeira = 'ATLANTIC_MADEIRA',
  /** Atlantic/Reykjavik */
  AtlanticReykjavik = 'ATLANTIC_REYKJAVIK',
  /** Atlantic/South_Georgia */
  AtlanticSouthGeorgia = 'ATLANTIC_SOUTH_GEORGIA',
  /** Atlantic/Stanley */
  AtlanticStanley = 'ATLANTIC_STANLEY',
  /** Atlantic/St_Helena */
  AtlanticStHelena = 'ATLANTIC_ST_HELENA',
  /** Australia/Adelaide */
  AustraliaAdelaide = 'AUSTRALIA_ADELAIDE',
  /** Australia/Brisbane */
  AustraliaBrisbane = 'AUSTRALIA_BRISBANE',
  /** Australia/Broken_Hill */
  AustraliaBrokenHill = 'AUSTRALIA_BROKEN_HILL',
  /** Australia/Darwin */
  AustraliaDarwin = 'AUSTRALIA_DARWIN',
  /** Australia/Eucla */
  AustraliaEucla = 'AUSTRALIA_EUCLA',
  /** Australia/Hobart */
  AustraliaHobart = 'AUSTRALIA_HOBART',
  /** Australia/Lindeman */
  AustraliaLindeman = 'AUSTRALIA_LINDEMAN',
  /** Australia/Lord_Howe */
  AustraliaLordHowe = 'AUSTRALIA_LORD_HOWE',
  /** Australia/Melbourne */
  AustraliaMelbourne = 'AUSTRALIA_MELBOURNE',
  /** Australia/Perth */
  AustraliaPerth = 'AUSTRALIA_PERTH',
  /** Australia/Sydney */
  AustraliaSydney = 'AUSTRALIA_SYDNEY',
  /** Canada/Atlantic */
  CanadaAtlantic = 'CANADA_ATLANTIC',
  /** Canada/Central */
  CanadaCentral = 'CANADA_CENTRAL',
  /** Canada/Eastern */
  CanadaEastern = 'CANADA_EASTERN',
  /** Canada/Mountain */
  CanadaMountain = 'CANADA_MOUNTAIN',
  /** Canada/Newfoundland */
  CanadaNewfoundland = 'CANADA_NEWFOUNDLAND',
  /** Canada/Pacific */
  CanadaPacific = 'CANADA_PACIFIC',
  /** Europe/Amsterdam */
  EuropeAmsterdam = 'EUROPE_AMSTERDAM',
  /** Europe/Andorra */
  EuropeAndorra = 'EUROPE_ANDORRA',
  /** Europe/Astrakhan */
  EuropeAstrakhan = 'EUROPE_ASTRAKHAN',
  /** Europe/Athens */
  EuropeAthens = 'EUROPE_ATHENS',
  /** Europe/Belgrade */
  EuropeBelgrade = 'EUROPE_BELGRADE',
  /** Europe/Berlin */
  EuropeBerlin = 'EUROPE_BERLIN',
  /** Europe/Bratislava */
  EuropeBratislava = 'EUROPE_BRATISLAVA',
  /** Europe/Brussels */
  EuropeBrussels = 'EUROPE_BRUSSELS',
  /** Europe/Bucharest */
  EuropeBucharest = 'EUROPE_BUCHAREST',
  /** Europe/Budapest */
  EuropeBudapest = 'EUROPE_BUDAPEST',
  /** Europe/Busingen */
  EuropeBusingen = 'EUROPE_BUSINGEN',
  /** Europe/Chisinau */
  EuropeChisinau = 'EUROPE_CHISINAU',
  /** Europe/Copenhagen */
  EuropeCopenhagen = 'EUROPE_COPENHAGEN',
  /** Europe/Dublin */
  EuropeDublin = 'EUROPE_DUBLIN',
  /** Europe/Gibraltar */
  EuropeGibraltar = 'EUROPE_GIBRALTAR',
  /** Europe/Guernsey */
  EuropeGuernsey = 'EUROPE_GUERNSEY',
  /** Europe/Helsinki */
  EuropeHelsinki = 'EUROPE_HELSINKI',
  /** Europe/Isle_of_Man */
  EuropeIsleOfMan = 'EUROPE_ISLE_OF_MAN',
  /** Europe/Istanbul */
  EuropeIstanbul = 'EUROPE_ISTANBUL',
  /** Europe/Jersey */
  EuropeJersey = 'EUROPE_JERSEY',
  /** Europe/Kaliningrad */
  EuropeKaliningrad = 'EUROPE_KALININGRAD',
  /** Europe/Kiev */
  EuropeKiev = 'EUROPE_KIEV',
  /** Europe/Kirov */
  EuropeKirov = 'EUROPE_KIROV',
  /** Europe/Lisbon */
  EuropeLisbon = 'EUROPE_LISBON',
  /** Europe/Ljubljana */
  EuropeLjubljana = 'EUROPE_LJUBLJANA',
  /** Europe/London */
  EuropeLondon = 'EUROPE_LONDON',
  /** Europe/Luxembourg */
  EuropeLuxembourg = 'EUROPE_LUXEMBOURG',
  /** Europe/Madrid */
  EuropeMadrid = 'EUROPE_MADRID',
  /** Europe/Malta */
  EuropeMalta = 'EUROPE_MALTA',
  /** Europe/Mariehamn */
  EuropeMariehamn = 'EUROPE_MARIEHAMN',
  /** Europe/Minsk */
  EuropeMinsk = 'EUROPE_MINSK',
  /** Europe/Monaco */
  EuropeMonaco = 'EUROPE_MONACO',
  /** Europe/Moscow */
  EuropeMoscow = 'EUROPE_MOSCOW',
  /** Europe/Oslo */
  EuropeOslo = 'EUROPE_OSLO',
  /** Europe/Paris */
  EuropeParis = 'EUROPE_PARIS',
  /** Europe/Podgorica */
  EuropePodgorica = 'EUROPE_PODGORICA',
  /** Europe/Prague */
  EuropePrague = 'EUROPE_PRAGUE',
  /** Europe/Riga */
  EuropeRiga = 'EUROPE_RIGA',
  /** Europe/Rome */
  EuropeRome = 'EUROPE_ROME',
  /** Europe/Samara */
  EuropeSamara = 'EUROPE_SAMARA',
  /** Europe/San_Marino */
  EuropeSanMarino = 'EUROPE_SAN_MARINO',
  /** Europe/Sarajevo */
  EuropeSarajevo = 'EUROPE_SARAJEVO',
  /** Europe/Saratov */
  EuropeSaratov = 'EUROPE_SARATOV',
  /** Europe/Simferopol */
  EuropeSimferopol = 'EUROPE_SIMFEROPOL',
  /** Europe/Skopje */
  EuropeSkopje = 'EUROPE_SKOPJE',
  /** Europe/Sofia */
  EuropeSofia = 'EUROPE_SOFIA',
  /** Europe/Stockholm */
  EuropeStockholm = 'EUROPE_STOCKHOLM',
  /** Europe/Tallinn */
  EuropeTallinn = 'EUROPE_TALLINN',
  /** Europe/Tirane */
  EuropeTirane = 'EUROPE_TIRANE',
  /** Europe/Ulyanovsk */
  EuropeUlyanovsk = 'EUROPE_ULYANOVSK',
  /** Europe/Uzhgorod */
  EuropeUzhgorod = 'EUROPE_UZHGOROD',
  /** Europe/Vaduz */
  EuropeVaduz = 'EUROPE_VADUZ',
  /** Europe/Vatican */
  EuropeVatican = 'EUROPE_VATICAN',
  /** Europe/Vienna */
  EuropeVienna = 'EUROPE_VIENNA',
  /** Europe/Vilnius */
  EuropeVilnius = 'EUROPE_VILNIUS',
  /** Europe/Volgograd */
  EuropeVolgograd = 'EUROPE_VOLGOGRAD',
  /** Europe/Warsaw */
  EuropeWarsaw = 'EUROPE_WARSAW',
  /** Europe/Zagreb */
  EuropeZagreb = 'EUROPE_ZAGREB',
  /** Europe/Zaporozhye */
  EuropeZaporozhye = 'EUROPE_ZAPOROZHYE',
  /** Europe/Zurich */
  EuropeZurich = 'EUROPE_ZURICH',
  /** GMT */
  Gmt = 'GMT',
  /** Indian/Antananarivo */
  IndianAntananarivo = 'INDIAN_ANTANANARIVO',
  /** Indian/Chagos */
  IndianChagos = 'INDIAN_CHAGOS',
  /** Indian/Christmas */
  IndianChristmas = 'INDIAN_CHRISTMAS',
  /** Indian/Cocos */
  IndianCocos = 'INDIAN_COCOS',
  /** Indian/Comoro */
  IndianComoro = 'INDIAN_COMORO',
  /** Indian/Kerguelen */
  IndianKerguelen = 'INDIAN_KERGUELEN',
  /** Indian/Mahe */
  IndianMahe = 'INDIAN_MAHE',
  /** Indian/Maldives */
  IndianMaldives = 'INDIAN_MALDIVES',
  /** Indian/Mauritius */
  IndianMauritius = 'INDIAN_MAURITIUS',
  /** Indian/Mayotte */
  IndianMayotte = 'INDIAN_MAYOTTE',
  /** Indian/Reunion */
  IndianReunion = 'INDIAN_REUNION',
  /** Pacific/Apia */
  PacificApia = 'PACIFIC_APIA',
  /** Pacific/Auckland */
  PacificAuckland = 'PACIFIC_AUCKLAND',
  /** Pacific/Bougainville */
  PacificBougainville = 'PACIFIC_BOUGAINVILLE',
  /** Pacific/Chatham */
  PacificChatham = 'PACIFIC_CHATHAM',
  /** Pacific/Chuuk */
  PacificChuuk = 'PACIFIC_CHUUK',
  /** Pacific/Easter */
  PacificEaster = 'PACIFIC_EASTER',
  /** Pacific/Efate */
  PacificEfate = 'PACIFIC_EFATE',
  /** Pacific/Fakaofo */
  PacificFakaofo = 'PACIFIC_FAKAOFO',
  /** Pacific/Fiji */
  PacificFiji = 'PACIFIC_FIJI',
  /** Pacific/Funafuti */
  PacificFunafuti = 'PACIFIC_FUNAFUTI',
  /** Pacific/Galapagos */
  PacificGalapagos = 'PACIFIC_GALAPAGOS',
  /** Pacific/Gambier */
  PacificGambier = 'PACIFIC_GAMBIER',
  /** Pacific/Guadalcanal */
  PacificGuadalcanal = 'PACIFIC_GUADALCANAL',
  /** Pacific/Guam */
  PacificGuam = 'PACIFIC_GUAM',
  /** Pacific/Honolulu */
  PacificHonolulu = 'PACIFIC_HONOLULU',
  /** Pacific/Kanton */
  PacificKanton = 'PACIFIC_KANTON',
  /** Pacific/Kiritimati */
  PacificKiritimati = 'PACIFIC_KIRITIMATI',
  /** Pacific/Kosrae */
  PacificKosrae = 'PACIFIC_KOSRAE',
  /** Pacific/Kwajalein */
  PacificKwajalein = 'PACIFIC_KWAJALEIN',
  /** Pacific/Majuro */
  PacificMajuro = 'PACIFIC_MAJURO',
  /** Pacific/Marquesas */
  PacificMarquesas = 'PACIFIC_MARQUESAS',
  /** Pacific/Midway */
  PacificMidway = 'PACIFIC_MIDWAY',
  /** Pacific/Nauru */
  PacificNauru = 'PACIFIC_NAURU',
  /** Pacific/Niue */
  PacificNiue = 'PACIFIC_NIUE',
  /** Pacific/Norfolk */
  PacificNorfolk = 'PACIFIC_NORFOLK',
  /** Pacific/Noumea */
  PacificNoumea = 'PACIFIC_NOUMEA',
  /** Pacific/Pago_Pago */
  PacificPagoPago = 'PACIFIC_PAGO_PAGO',
  /** Pacific/Palau */
  PacificPalau = 'PACIFIC_PALAU',
  /** Pacific/Pitcairn */
  PacificPitcairn = 'PACIFIC_PITCAIRN',
  /** Pacific/Pohnpei */
  PacificPohnpei = 'PACIFIC_POHNPEI',
  /** Pacific/Port_Moresby */
  PacificPortMoresby = 'PACIFIC_PORT_MORESBY',
  /** Pacific/Rarotonga */
  PacificRarotonga = 'PACIFIC_RAROTONGA',
  /** Pacific/Saipan */
  PacificSaipan = 'PACIFIC_SAIPAN',
  /** Pacific/Tahiti */
  PacificTahiti = 'PACIFIC_TAHITI',
  /** Pacific/Tarawa */
  PacificTarawa = 'PACIFIC_TARAWA',
  /** Pacific/Tongatapu */
  PacificTongatapu = 'PACIFIC_TONGATAPU',
  /** Pacific/Wake */
  PacificWake = 'PACIFIC_WAKE',
  /** Pacific/Wallis */
  PacificWallis = 'PACIFIC_WALLIS',
  /** US/Alaska */
  UsAlaska = 'US_ALASKA',
  /** US/Arizona */
  UsArizona = 'US_ARIZONA',
  /** US/Central */
  UsCentral = 'US_CENTRAL',
  /** US/Eastern */
  UsEastern = 'US_EASTERN',
  /** US/Hawaii */
  UsHawaii = 'US_HAWAII',
  /** US/Mountain */
  UsMountain = 'US_MOUNTAIN',
  /** US/Pacific */
  UsPacific = 'US_PACIFIC',
  /** UTC */
  Utc = 'UTC',
}

export type UniqueIdentifierAvailability = {
  __typename?: 'UniqueIdentifierAvailability'
  alternative: Maybe<Scalars['String']>
  available: Scalars['Boolean']
}

export type UpcomingInvoice = {
  __typename?: 'UpcomingInvoice'
  amountDue: Scalars['Int']
  discountAmount: Scalars['Int']
  discountName: Maybe<Scalars['String']>
  discountPercentOff: Maybe<Scalars['Int']>
  endingBalance: Scalars['Int']
  prorationAmount: Scalars['Int']
  startingBalance: Scalars['Int']
  subscriptionAmount: Scalars['Int']
  subscriptionQuantity: Scalars['Int']
  total: Scalars['Int']
}

export type UpdateAttendeeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  fieldSubmissions: Array<InputMaybe<AttendeeFieldInput>>
  firstName?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: InputMaybe<Scalars['String']>
  timezone: Timezone
}

export type UpdateAttendeePayload = {
  __typename?: 'UpdateAttendeePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<AttendeeNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateCalendarAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  oauth2Code?: InputMaybe<Scalars['String']>
}

export type UpdateCalendarAccountPayload = {
  __typename?: 'UpdateCalendarAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<CalendarAccountNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateGoToMeetingAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Audio options for the meeting. */
  conferenceCallInfo?: InputMaybe<GoToMeetingConferenceCallInfoOptions>
  id: Scalars['ID']
  name: Scalars['String']
}

export type UpdateGoToMeetingAccountPayload = {
  __typename?: 'UpdateGoToMeetingAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<GoToMeetingAccountNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateJoinMeAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  /** Use personal meeting URL instead of unique link. */
  startWithPersonalUrl?: InputMaybe<Scalars['Boolean']>
}

export type UpdateJoinMeAccountPayload = {
  __typename?: 'UpdateJoinMeAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<JoinMeAccountNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateMeetingNoteInput = {
  body: Scalars['String']
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

export type UpdateMeetingNotePayload = {
  __typename?: 'UpdateMeetingNotePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingNoteNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateMeetingTypeInput = {
  active?: InputMaybe<Scalars['Boolean']>
  allowCancellations?: InputMaybe<Scalars['Boolean']>
  allowReschedules?: InputMaybe<Scalars['Boolean']>
  autoConfirm?: InputMaybe<Scalars['Boolean']>
  bufferAfter?: InputMaybe<Scalars['String']>
  bufferBefore?: InputMaybe<Scalars['String']>
  cancellationEmailBody?: InputMaybe<Scalars['String']>
  cancellationEmailSubject?: InputMaybe<Scalars['String']>
  cancellationNotice?: InputMaybe<Scalars['String']>
  clientMutationId?: InputMaybe<Scalars['String']>
  color?: InputMaybe<Scalars['String']>
  conferencingProvider?: InputMaybe<Scalars['ID']>
  confirmationEmailBody?: InputMaybe<Scalars['String']>
  confirmationEmailSubject?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  duration?: InputMaybe<Scalars['String']>
  fields: Array<InputMaybe<FormFieldSerializerInput>>
  frequency?: InputMaybe<Scalars['String']>
  hostAssignmentStrategy?: InputMaybe<HostAssignmentStrategy>
  id: Scalars['ID']
  image?: InputMaybe<Scalars['String']>
  includeDataInRedirectUrl?: InputMaybe<Scalars['Boolean']>
  instructions?: InputMaybe<Scalars['String']>
  location?: InputMaybe<Scalars['String']>
  locationType?: InputMaybe<LocationType>
  maxAttendees?: InputMaybe<Scalars['Int']>
  maxBookingsPerDay?: InputMaybe<Scalars['Int']>
  maximumNotice?: InputMaybe<Scalars['String']>
  meetingTypeTeamMembers: Array<
    InputMaybe<MeetingTypeTeamMemberSerializerInput>
  >
  minimumNotice?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  paymentCollectionProvider?: InputMaybe<Scalars['ID']>
  paymentRule?: InputMaybe<PaymentRule>
  price?: InputMaybe<Scalars['Float']>
  priceCurrency?: InputMaybe<Currency>
  public?: InputMaybe<Scalars['Boolean']>
  redirectUrl?: InputMaybe<Scalars['String']>
  reminders: Array<InputMaybe<MeetingTypeReminderSerializerInput>>
  rescheduleNotice?: InputMaybe<Scalars['String']>
  schedules: Array<InputMaybe<MeetingTypeScheduleSerializerInput>>
  sendCancellationEmail?: InputMaybe<Scalars['Boolean']>
  sendConfirmationEmail?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  timezone?: InputMaybe<Timezone>
  useCalendarInvitations?: InputMaybe<Scalars['Boolean']>
  usesLocalTimezone?: InputMaybe<Scalars['Boolean']>
}

export type UpdateMeetingTypePayload = {
  __typename?: 'UpdateMeetingTypePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MeetingTypeNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateMemberInput = {
  allowsSyncsFromCalendar?: InputMaybe<Scalars['Boolean']>
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  isSchedulable?: InputMaybe<Scalars['Boolean']>
  role: Scalars['ID']
}

export type UpdateMemberPayload = {
  __typename?: 'UpdateMemberPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<MemberNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdatePaymentCollectionAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  oauth2Code?: InputMaybe<Scalars['String']>
}

export type UpdatePaymentCollectionAccountPayload = {
  __typename?: 'UpdatePaymentCollectionAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<PaymentCollectionAccountNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateProfileInput = {
  billedAnnually?: InputMaybe<Scalars['Boolean']>
  brandColor?: InputMaybe<Scalars['String']>
  clientMutationId?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  image?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  needsOnboarding?: InputMaybe<Scalars['Boolean']>
  personal?: InputMaybe<Scalars['Boolean']>
  redactMeetingDataAfter?: InputMaybe<Scalars['String']>
  showAppointletBranding?: InputMaybe<Scalars['Boolean']>
}

export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<ProfileNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateProfilePlanInput = {
  billedAnnually?: InputMaybe<Scalars['Boolean']>
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  plan?: InputMaybe<Scalars['ID']>
}

export type UpdateProfilePlanPayload = {
  __typename?: 'UpdateProfilePlanPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<ProfileNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateScheduleInput = {
  bounds?: InputMaybe<DateRangeInput>
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  timeBlocks: Array<InputMaybe<TimeBlockSerializerInput>>
  timezone: Timezone
}

export type UpdateSchedulePayload = {
  __typename?: 'UpdateSchedulePayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<ScheduleNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateTeamInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  image?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Language>
  name: Scalars['String']
  private?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
}

export type UpdateTeamPayload = {
  __typename?: 'UpdateTeamPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<TeamNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  image?: InputMaybe<Scalars['String']>
  language?: InputMaybe<Language>
  lastName?: InputMaybe<Scalars['String']>
  needsOnboarding?: InputMaybe<Scalars['Boolean']>
  timezone: Timezone
}

export type UpdateUserPasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  newPassword: Scalars['String']
  oldPassword: Scalars['String']
}

export type UpdateUserPasswordPayload = {
  __typename?: 'UpdateUserPasswordPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<UserNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<UserNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateWebexAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
}

export type UpdateWebexAccountPayload = {
  __typename?: 'UpdateWebexAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<WebexAccountNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateWebhookInput = {
  active?: InputMaybe<Scalars['Boolean']>
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  selectedActivities?: InputMaybe<Array<InputMaybe<ActivityType>>>
  url: Scalars['String']
}

export type UpdateWebhookPayload = {
  __typename?: 'UpdateWebhookPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<WebhookNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UpdateZoomAccountInput = {
  /** Determine how participants can join the audio portion of the meeting. */
  audio?: InputMaybe<ZoomAudioOptions>
  autoRecording?: InputMaybe<ZoomAutoRecordingOptions>
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Host meeting in China. */
  cnMeeting?: InputMaybe<Scalars['Boolean']>
  /** Start video when the host joins the meeting. */
  hostVideo?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  /** Host meeting in India. */
  inMeeting?: InputMaybe<Scalars['Boolean']>
  /** Allow participants to join the meeting before the host starts the meeting. */
  joinBeforeHost?: InputMaybe<Scalars['Boolean']>
  /** Mute participants upon entry. */
  muteUponEntry?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  /** Start video when participants join the meeting. */
  participantVideo?: InputMaybe<Scalars['Boolean']>
  /** Add watermark when viewing a shared screen. */
  watermark?: InputMaybe<Scalars['Boolean']>
}

export type UpdateZoomAccountPayload = {
  __typename?: 'UpdateZoomAccountPayload'
  clientMutationId: Maybe<Scalars['String']>
  data: Maybe<ZoomAccountNode>
  errors: Maybe<Array<Maybe<ErrorType>>>
}

export type UserNode = Node & {
  __typename?: 'UserNode'
  calendarAccounts: CalendarAccountNodeConnection
  createdAt: Scalars['DateTime']
  email: Scalars['String']
  firstName: Scalars['String']
  /** The ID of the object. */
  id: Scalars['ID']
  image: Maybe<Scalars['String']>
  language: Language
  lastName: Scalars['String']
  members: MemberNodeConnection
  needsOnboarding: Scalars['Boolean']
  passwordUpdatedAt: Maybe<Scalars['DateTime']>
  timezone: Maybe<Timezone>
}

export type UserNodeCalendarAccountsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}

export type UserNodeMembersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  isDeleted?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}

/** Connected Webex account. */
export type WebexAccountNode = ConferencingAccountNode & {
  __typename?: 'WebexAccountNode'
  createdAt: Scalars['DateTime']
  deleted: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  isConnected: Scalars['Boolean']
  isDefault: Scalars['Boolean']
  name: Scalars['String']
  provider: ConferenceProviderNode
}

export type WebhookNode = Node & {
  __typename?: 'WebhookNode'
  active: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  deleted: Maybe<Scalars['DateTime']>
  /** The ID of the object. */
  id: Scalars['ID']
  member: Maybe<MemberNode>
  name: Maybe<Scalars['String']>
  profile: ProfileNode
  secret: Maybe<Scalars['String']>
  selectedActivities: Maybe<Array<Scalars['String']>>
  url: Scalars['String']
}

export type WebhookNodeConnection = {
  __typename?: 'WebhookNodeConnection'
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WebhookNodeEdge>>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
}

/** A Relay edge containing a `WebhookNode` and its cursor. */
export type WebhookNodeEdge = {
  __typename?: 'WebhookNodeEdge'
  /** A cursor for use in pagination */
  cursor: Scalars['String']
  /** The item at the end of the edge */
  node: Maybe<WebhookNode>
}

/** An enumeration. */
export enum Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

/** Connected Zoom account. */
export type ZoomAccountNode = ConferencingAccountNode & {
  __typename?: 'ZoomAccountNode'
  /** Determine how participants can join the audio portion of the meeting. */
  audio: Maybe<ZoomAudioOptions>
  autoRecording: Maybe<ZoomAutoRecordingOptions>
  /** Host meeting in China. */
  cnMeeting: Maybe<Scalars['Boolean']>
  createdAt: Scalars['DateTime']
  deleted: Maybe<Scalars['DateTime']>
  /** Start video when the host joins the meeting. */
  hostVideo: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  /** Host meeting in India. */
  inMeeting: Maybe<Scalars['Boolean']>
  isConnected: Scalars['Boolean']
  isDefault: Scalars['Boolean']
  /** Allow participants to join the meeting before the host starts the meeting. */
  joinBeforeHost: Maybe<Scalars['Boolean']>
  /** Mute participants upon entry. */
  muteUponEntry: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  /** Start video when participants join the meeting. */
  participantVideo: Maybe<Scalars['Boolean']>
  provider: ConferenceProviderNode
  /** Add watermark when viewing a shared screen. */
  watermark: Maybe<Scalars['Boolean']>
}

export enum ZoomAudioOptions {
  /** Allow both Telephony and VoIP. */
  Both = 'BOTH',
  /** Allow telephony only. */
  Telephony = 'TELEPHONY',
  /** Allow VoIP only. */
  Voip = 'VOIP',
}

export enum ZoomAutoRecordingOptions {
  /** Record conference to Zoom cloud. */
  Cloud = 'CLOUD',
  /** Record conference to your computer. */
  Local = 'LOCAL',
  /** Disable recording. */
  None = 'NONE',
}

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue'
  name: Scalars['String']
  description: Maybe<Scalars['String']>
  isDeprecated: Scalars['Boolean']
  deprecationReason: Maybe<Scalars['String']>
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field'
  name: Scalars['String']
  description: Maybe<Scalars['String']>
  args: Array<__InputValue>
  type: __Type
  isDeprecated: Scalars['Boolean']
  deprecationReason: Maybe<Scalars['String']>
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>
}

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue'
  name: Scalars['String']
  description: Maybe<Scalars['String']>
  type: __Type
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue: Maybe<Scalars['String']>
  isDeprecated: Scalars['Boolean']
  deprecationReason: Maybe<Scalars['String']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type'
  kind: __TypeKind
  name: Maybe<Scalars['String']>
  description: Maybe<Scalars['String']>
  specifiedByURL: Maybe<Scalars['String']>
  fields: Maybe<Array<__Field>>
  interfaces: Maybe<Array<__Type>>
  possibleTypes: Maybe<Array<__Type>>
  enumValues: Maybe<Array<__EnumValue>>
  inputFields: Maybe<Array<__InputValue>>
  ofType: Maybe<__Type>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>
}

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL',
}

export type AppQueryVariables = Exact<{ [key: string]: never }>

export type AppQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'UserNode'
    createdAt: any
    email: string
    firstName: string
    id: string
    image: string | null
    language: Language
    lastName: string
    needsOnboarding: boolean
    timezone: Timezone | null
    members: {
      __typename?: 'MemberNodeConnection'
      edges: Array<{
        __typename?: 'MemberNodeEdge'
        node: {
          __typename?: 'MemberNode'
          id: string
          invitationAccepted: boolean
          isOwner: boolean
          meetingCount: number
          role: {
            __typename?: 'RoleNode'
            id: string
            canManageBilling: boolean
            canManageMeetings: boolean
            canManageMeetingTypes: boolean
            canManageMembers: boolean
            canManageOrgSettings: boolean
          }
          profile: {
            __typename?: 'ProfileNode'
            allocatedSeats: number | null
            billedAnnually: boolean
            needsOnboarding: boolean
            billingCustomerId: string | null
            billingSubscriptionId: string | null
            brandColor: string
            featureFlags: Array<string | null> | null
            id: string
            image: string | null
            name: string | null
            occupiedSeats: number
            personal: boolean
            members: {
              __typename?: 'MemberNodeConnection'
              edges: Array<{
                __typename?: 'MemberNodeEdge'
                node: {
                  __typename?: 'MemberNode'
                  id: string
                  isOwner: boolean
                  user: {
                    __typename?: 'UserNode'
                    email: string
                    id: string
                    image: string | null
                    firstName: string
                    lastName: string
                  }
                } | null
              } | null>
            }
            plan: {
              __typename?: 'PlanNode'
              allowEmailCustomization: boolean
              allowFormFields: boolean
              allowManualConfirm: boolean
              allowPaymentCollection: boolean
              allowPrivateMeetingTypes: boolean
              allowRedirect: boolean
              allowReminders: boolean
              allowRoundRobin: boolean
              allowWebConferencing: boolean
              allowWebhooks: boolean
              allowUtmParameters: boolean
              allowZapier: boolean
              freeDefault: boolean
              id: string
              name: string
              requireAppointletBranding: boolean
              seatPriceMonthly: number
              seatPriceYearly: number
              trialDefault: boolean
            } | null
          }
        } | null
      } | null>
    }
  } | null
}

export type AppSetTimeZoneMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type AppSetTimeZoneMutation = {
  __typename?: 'Mutations'
  user: {
    __typename?: 'UpdateUserPayload'
    data: {
      __typename?: 'UserNode'
      id: string
      timezone: Timezone | null
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type ApproveAttendeesMutationVariables = Exact<{
  input: ApproveAttendeesInput
}>

export type ApproveAttendeesMutation = {
  __typename?: 'Mutations'
  approveAttendees: {
    __typename?: 'ApproveAttendeesPayload'
    data: Array<{
      __typename?: 'AttendeeNode'
      id: string
      status: AttendeeStatus
      approved: boolean | null
    } | null> | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type ApproveMeetingMutationVariables = Exact<{
  input: ApproveMeetingInput
}>

export type ApproveMeetingMutation = {
  __typename?: 'Mutations'
  approveMeeting: {
    __typename?: 'ApproveMeetingPayload'
    data: {
      __typename?: 'MeetingNode'
      id: string
      approved: boolean | null
      confirmed: boolean
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type ResumeSubscriptionMutationVariables = Exact<{
  input: ResumeSubscriptionInput
}>

export type ResumeSubscriptionMutation = {
  __typename?: 'Mutations'
  resumeSubscription: {
    __typename?: 'ResumeSubscriptionPayload'
    ok: boolean | null
  } | null
}

export type GetUpcomingInvoiceQueryVariables = Exact<{
  profile: Scalars['ID']
  billedAnnually?: InputMaybe<Scalars['Boolean']>
}>

export type GetUpcomingInvoiceQuery = {
  __typename?: 'Query'
  invoice: {
    __typename?: 'UpcomingInvoice'
    amountDue: number
    discountAmount: number
    discountName: string | null
    discountPercentOff: number | null
    endingBalance: number
    prorationAmount: number
    startingBalance: number
    subscriptionAmount: number
    subscriptionQuantity: number
    total: number
  } | null
}

export type CancelAttendeesMutationVariables = Exact<{
  input: CancelAttendeesInput
}>

export type CancelAttendeesMutation = {
  __typename?: 'Mutations'
  cancelAttendees: {
    __typename?: 'CancelAttendeesPayload'
    data: Array<{
      __typename?: 'AttendeeNode'
      id: string
      status: AttendeeStatus
      cancelled: boolean
    } | null> | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type CancelMeetingMutationVariables = Exact<{
  input: CancelMeetingInput
}>

export type CancelMeetingMutation = {
  __typename?: 'Mutations'
  cancelMeeting: {
    __typename?: 'CancelMeetingPayload'
    data: {
      __typename?: 'MeetingNode'
      cancelled: boolean
      id: string
      name: string
      start: any
      attendees: {
        __typename?: 'AttendeeNodeConnection'
        edges: Array<{
          __typename?: 'AttendeeNodeEdge'
          node: {
            __typename?: 'AttendeeNode'
            id: string
            status: AttendeeStatus
          } | null
        } | null>
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type CancelSubscriptionMutationVariables = Exact<{
  input: CancelSubscriptionInput
}>

export type CancelSubscriptionMutation = {
  __typename?: 'Mutations'
  cancelSubscription: {
    __typename?: 'CancelSubscriptionPayload'
    ok: boolean | null
  } | null
}

export type ChangePasswordModalMutationVariables = Exact<{
  input: UpdateUserPasswordInput
}>

export type ChangePasswordModalMutation = {
  __typename?: 'Mutations'
  user: {
    __typename?: 'UpdateUserPasswordPayload'
    data: {
      __typename?: 'UserNode'
      id: string
      passwordUpdatedAt: any | null
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type CreateMeetingTypeModalMutationVariables = Exact<{
  input: CreateMeetingTypeInput
}>

export type CreateMeetingTypeModalMutation = {
  __typename?: 'Mutations'
  createMeetingType: {
    __typename?: 'CreateMeetingTypePayload'
    data: {
      __typename?: 'MeetingTypeNode'
      id: string
      order: number
      name: string
      color: string
      duration: any
      bookingUrl: string
      slug: string
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type CreateProfileModalMutationVariables = Exact<{
  input: CreateProfileInput
}>

export type CreateProfileModalMutation = {
  __typename?: 'Mutations'
  createProfile: {
    __typename?: 'CreateProfilePayload'
    data: {
      __typename?: 'ProfileNode'
      allocatedSeats: number | null
      billedAnnually: boolean
      billingCustomerId: string | null
      billingSubscriptionId: string | null
      brandColor: string
      id: string
      image: string | null
      name: string | null
      occupiedSeats: number
      personal: boolean
      members: {
        __typename?: 'MemberNodeConnection'
        edges: Array<{
          __typename?: 'MemberNodeEdge'
          node: {
            __typename?: 'MemberNode'
            id: string
            user: {
              __typename?: 'UserNode'
              email: string
              id: string
              image: string | null
              firstName: string
              lastName: string
            }
          } | null
        } | null>
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type CreateSchedulingPageModalMutationVariables = Exact<{
  input: CreateTeamInput
}>

export type CreateSchedulingPageModalMutation = {
  __typename?: 'Mutations'
  schedulingPage: {
    __typename?: 'CreateTeamPayload'
    data: {
      __typename?: 'TeamNode'
      id: string
      name: string
      private: boolean
      slug: string
      teamMembers: {
        __typename?: 'TeamMemberNodeConnection'
        edges: Array<{
          __typename?: 'TeamMemberNodeEdge'
          node: {
            __typename?: 'TeamMemberNode'
            id: string
            member: {
              __typename?: 'MemberNode'
              id: string
              user: {
                __typename?: 'UserNode'
                email: string
                firstName: string
                id: string
                image: string | null
                lastName: string
              }
            }
          } | null
        } | null>
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type CreateWebhookModalMutationVariables = Exact<{
  input: CreateWebhookInput
}>

export type CreateWebhookModalMutation = {
  __typename?: 'Mutations'
  createWebhook: {
    __typename?: 'CreateWebhookPayload'
    data: { __typename?: 'WebhookNode'; id: string; url: string } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type CurrencyPickerQueryVariables = Exact<{ [key: string]: never }>

export type CurrencyPickerQuery = {
  __typename?: 'Query'
  currencies: {
    __typename?: '__Type'
    enumValues: Array<{
      __typename?: '__EnumValue'
      name: string
      description: string | null
    }> | null
  } | null
}

export type CustomizeSchedulingPageModalQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type CustomizeSchedulingPageModalQuery = {
  __typename?: 'Query'
  team: {
    __typename?: 'TeamNode'
    description: string | null
    id: string
    image: string | null
    language: Language
    name: string
    slug: string
    teamMembers: {
      __typename?: 'TeamMemberNodeConnection'
      edges: Array<{
        __typename?: 'TeamMemberNodeEdge'
        node: {
          __typename?: 'TeamMemberNode'
          id: string
          member: { __typename?: 'MemberNode'; id: string }
        } | null
      } | null>
    }
  }
}

export type CustomizeSchedulingPageModalUpdateMutationVariables = Exact<{
  input: UpdateTeamInput
}>

export type CustomizeSchedulingPageModalUpdateMutation = {
  __typename?: 'Mutations'
  updateTeam: {
    __typename?: 'UpdateTeamPayload'
    data: {
      __typename?: 'TeamNode'
      bookingUrl: string
      description: string | null
      id: string
      image: string | null
      language: Language
      name: string
      slug: string
      meetingTypes: {
        __typename?: 'MeetingTypeNodeConnection'
        edges: Array<{
          __typename?: 'MeetingTypeNodeEdge'
          node: {
            __typename?: 'MeetingTypeNode'
            bookingUrl: string
            id: string
          } | null
        } | null>
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type DeclineAttendeesMutationVariables = Exact<{
  input: DeclineAttendeesInput
}>

export type DeclineAttendeesMutation = {
  __typename?: 'Mutations'
  declineAttendees: {
    __typename?: 'DeclineAttendeesPayload'
    data: Array<{
      __typename?: 'AttendeeNode'
      id: string
      approved: boolean | null
      cancelled: boolean
      status: AttendeeStatus
    } | null> | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type DeclineMeetingModalMutationVariables = Exact<{
  input: DeclineMeetingInput
}>

export type DeclineMeetingModalMutation = {
  __typename?: 'Mutations'
  declineMeeting: {
    __typename?: 'DeclineMeetingPayload'
    data: {
      __typename?: 'MeetingNode'
      cancelled: boolean
      confirmed: boolean
      id: string
      name: string
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type DeleteCalendarAccountModalQueryVariables = Exact<{
  calendarAccountId: Scalars['ID']
}>

export type DeleteCalendarAccountModalQuery = {
  __typename?: 'Query'
  getCalendarAccountById: {
    __typename?: 'CalendarAccountNode'
    id: string
    name: string
    provider: {
      __typename?: 'CalendarProviderNode'
      id: string
      name: string
      slug: string
    }
  } | null
}

export type CalendarAccountDeleteMutationVariables = Exact<{
  input: DeleteCalendarAccountInput
}>

export type CalendarAccountDeleteMutation = {
  __typename?: 'Mutations'
  deleteCalendarAccount: {
    __typename?: 'DeleteCalendarAccountPayload'
    data: { __typename?: 'CalendarAccountNode'; id: string } | null
  } | null
}

export type ConferencingAccountDeleteMutationVariables = Exact<{
  input: DeleteConferencingAccountInput
}>

export type ConferencingAccountDeleteMutation = {
  __typename?: 'Mutations'
  account: {
    __typename?: 'DeleteConferencingAccountPayload'
    data:
      | {
          __typename?: 'GoToMeetingAccountNode'
          id: string
          deleted: any | null
        }
      | { __typename?: 'JoinMeAccountNode'; id: string; deleted: any | null }
      | {
          __typename?: 'MicrosoftTeamsAccountNode'
          id: string
          deleted: any | null
        }
      | { __typename?: 'WebexAccountNode'; id: string; deleted: any | null }
      | { __typename?: 'ZoomAccountNode'; id: string; deleted: any | null }
      | null
  } | null
}

export type DeleteSchedulingPageModalMutationVariables = Exact<{
  input: DeleteTeamInput
}>

export type DeleteSchedulingPageModalMutation = {
  __typename?: 'Mutations'
  schedulingPage: {
    __typename?: 'DeleteTeamPayload'
    data: { __typename?: 'TeamNode'; id: string; deleted: any | null } | null
  } | null
}

export type DeletePaymentCollectionAccountMutationVariables = Exact<{
  input: DeletePaymentCollectionAccountInput
}>

export type DeletePaymentCollectionAccountMutation = {
  __typename?: 'Mutations'
  account: {
    __typename?: 'DeletePaymentCollectionAccountPayload'
    data: {
      __typename?: 'PaymentCollectionAccountNode'
      deleted: any | null
      id: string
      name: string
    } | null
  } | null
}

export type InviteMembersModalMutationVariables = Exact<{
  input: InviteMembersInput
}>

export type InviteMembersModalMutation = {
  __typename?: 'Mutations'
  inviteMembers: {
    __typename?: 'InviteMembersPayload'
    data: Array<{
      __typename?: 'MemberNode'
      id: string
      deleted: any | null
      user: {
        __typename?: 'UserNode'
        email: string
        firstName: string
        id: string
      }
    } | null> | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type LanguagePickerQueryVariables = Exact<{ [key: string]: never }>

export type LanguagePickerQuery = {
  __typename?: 'Query'
  languages: {
    __typename?: '__Type'
    enumValues: Array<{
      __typename?: '__EnumValue'
      name: string
      description: string | null
    }> | null
  } | null
}

export type MeetingCalendarPickerQueryVariables = Exact<{
  memberId: Scalars['ID']
}>

export type MeetingCalendarPickerQuery = {
  __typename?: 'Query'
  member: {
    __typename?: 'MemberNode'
    id: string
    calendarAccounts: {
      __typename?: 'CalendarAccountNodeConnection'
      edges: Array<{
        __typename?: 'CalendarAccountNodeEdge'
        node: {
          __typename?: 'CalendarAccountNode'
          id: string
          name: string
          provider: {
            __typename?: 'CalendarProviderNode'
            id: string
            name: string
            slug: string
          }
          calendars: {
            __typename?: 'CalendarNodeConnection'
            edges: Array<{
              __typename?: 'CalendarNodeEdge'
              node: {
                __typename?: 'CalendarNode'
                id: string
                name: string
                color: string | null
                permissionLevel: CalendarPermissionLevel
              } | null
            } | null>
          }
        } | null
      } | null>
    }
  }
}

export type MeetingTypesPageQueryVariables = Exact<{
  id: Scalars['ID']
  search?: InputMaybe<Scalars['String']>
}>

export type MeetingTypesPageQuery = {
  __typename?: 'Query'
  team: {
    __typename?: 'TeamNode'
    bookingUrl: string
    id: string
    name: string
    slug: string
    meetingTypes: {
      __typename?: 'MeetingTypeNodeConnection'
      edges: Array<{
        __typename?: 'MeetingTypeNodeEdge'
        node: {
          __typename?: 'MeetingTypeNode'
          bookingUrl: string
          color: string
          deleted: any | null
          description: string | null
          duration: any
          hostAssignmentStrategy: HostAssignmentStrategy
          id: string
          image: string | null
          isGroup: boolean
          maxAttendees: number
          name: string
          order: number
          price: number
          priceCurrency: Currency | null
          tags: Array<string>
          meetingTypeTeamMembers: {
            __typename?: 'MeetingTypeTeamMemberNodeConnection'
            edges: Array<{
              __typename?: 'MeetingTypeTeamMemberNodeEdge'
              node: {
                __typename?: 'MeetingTypeTeamMemberNode'
                active: boolean
                id: string
                teamMember: {
                  __typename?: 'TeamMemberNode'
                  id: string
                  member: {
                    __typename?: 'MemberNode'
                    id: string
                    user: {
                      __typename?: 'UserNode'
                      email: string
                      firstName: string
                      id: string
                      image: string | null
                      lastName: string
                    }
                  }
                }
              } | null
            } | null>
          }
        } | null
      } | null>
    }
    teamMembers: {
      __typename?: 'TeamMemberNodeConnection'
      edges: Array<{
        __typename?: 'TeamMemberNodeEdge'
        node: {
          __typename?: 'TeamMemberNode'
          id: string
          member: {
            __typename?: 'MemberNode'
            id: string
            user: {
              __typename?: 'UserNode'
              email: string
              firstName: string
              id: string
              image: string | null
              lastName: string
              timezone: Timezone | null
            }
          }
        } | null
      } | null>
    }
  }
}

export type CloneMeetingTypeMutationVariables = Exact<{
  input: CloneMeetingTypeInput
}>

export type CloneMeetingTypeMutation = {
  __typename?: 'Mutations'
  cloneMeetingType: {
    __typename?: 'CloneMeetingTypePayload'
    data: { __typename?: 'MeetingTypeNode'; id: string } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type DeleteMeetingTypeModalMutationVariables = Exact<{
  input: DeleteMeetingTypeInput
}>

export type DeleteMeetingTypeModalMutation = {
  __typename?: 'Mutations'
  deleteMeetingType: {
    __typename?: 'DeleteMeetingTypePayload'
    data: {
      __typename?: 'MeetingTypeNode'
      id: string
      deleted: any | null
    } | null
  } | null
}

export type MeetingTypesPickerQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type MeetingTypesPickerQuery = {
  __typename?: 'Query'
  member: {
    __typename?: 'MemberNode'
    id: string
    teams: {
      __typename?: 'TeamNodeConnection'
      edges: Array<{
        __typename?: 'TeamNodeEdge'
        node: {
          __typename?: 'TeamNode'
          id: string
          name: string
          meetingTypes: {
            __typename?: 'MeetingTypeNodeConnection'
            edges: Array<{
              __typename?: 'MeetingTypeNodeEdge'
              node: {
                __typename?: 'MeetingTypeNode'
                id: string
                color: string
                image: string | null
                name: string
              } | null
            } | null>
          }
        } | null
      } | null>
    }
  }
}

export type MeetingsPageQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  id: Scalars['ID']
  isCancelled?: InputMaybe<Scalars['Boolean']>
  isConfirmed?: InputMaybe<Scalars['Boolean']>
  meetingTypes?: InputMaybe<
    Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
  >
  members?: InputMaybe<
    Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>
  >
  search?: InputMaybe<Scalars['String']>
  startOnOrAfter?: InputMaybe<Scalars['DateTime']>
  startOnOrBefore?: InputMaybe<Scalars['DateTime']>
  orderBy?: InputMaybe<Scalars['String']>
}>

export type MeetingsPageQuery = {
  __typename?: 'Query'
  profile: {
    __typename?: 'ProfileNode'
    id: string
    meetings: {
      __typename?: 'MeetingNodeConnection'
      pageInfo: {
        __typename?: 'PageInfo'
        hasNextPage: boolean
        startCursor: string | null
        endCursor: string | null
        hasPreviousPage: boolean
      }
      edges: Array<{
        __typename?: 'MeetingNodeEdge'
        node: {
          __typename?: 'MeetingNode'
          cancelled: boolean
          conferenceId: string | null
          conferenceUrl: string | null
          confirmed: boolean
          end: any
          id: string
          isGroup: boolean
          location: string | null
          locationType: LocationType | null
          name: string
          start: any
          attendees: {
            __typename?: 'AttendeeNodeConnection'
            edges: Array<{
              __typename?: 'AttendeeNodeEdge'
              node: {
                __typename?: 'AttendeeNode'
                firstName: string | null
                email: string
                id: string
                lastName: string | null
                status: AttendeeStatus
                approved: boolean | null
                timezone: Timezone
                member: {
                  __typename?: 'MemberNode'
                  id: string
                  user: {
                    __typename?: 'UserNode'
                    firstName: string
                    email: string
                    id: string
                    image: string | null
                    timezone: Timezone | null
                  }
                } | null
              } | null
            } | null>
          }
          conferencingAccount:
            | {
                __typename?: 'GoToMeetingAccountNode'
                id: string
                name: string
                provider: {
                  __typename?: 'ConferenceProviderNode'
                  id: string
                  name: string
                  slug: string
                }
              }
            | {
                __typename?: 'JoinMeAccountNode'
                id: string
                name: string
                provider: {
                  __typename?: 'ConferenceProviderNode'
                  id: string
                  name: string
                  slug: string
                }
              }
            | {
                __typename?: 'MicrosoftTeamsAccountNode'
                id: string
                name: string
                provider: {
                  __typename?: 'ConferenceProviderNode'
                  id: string
                  name: string
                  slug: string
                }
              }
            | {
                __typename?: 'WebexAccountNode'
                id: string
                name: string
                provider: {
                  __typename?: 'ConferenceProviderNode'
                  id: string
                  name: string
                  slug: string
                }
              }
            | {
                __typename?: 'ZoomAccountNode'
                id: string
                name: string
                provider: {
                  __typename?: 'ConferenceProviderNode'
                  id: string
                  name: string
                  slug: string
                }
              }
            | null
          meetingType: {
            __typename?: 'MeetingTypeNode'
            id: string
            name: string
            color: string
            conferencingProvider: {
              __typename?: 'ConferenceProviderNode'
              id: string
              name: string
              slug: string
            } | null
          }
        } | null
      } | null>
    }
  }
}

export type MeetingLaunchActionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type MeetingLaunchActionQuery = {
  __typename?: 'Query'
  meeting: { __typename?: 'MeetingNode'; id: string; name: string; start: any }
}

export type AttendeeLaunchActionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type AttendeeLaunchActionQuery = {
  __typename?: 'Query'
  attendee: {
    __typename?: 'AttendeeNode'
    id: string
    firstName: string | null
    lastName: string | null
  } | null
}

export type UpdateUserTimezoneMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserTimezoneMutation = {
  __typename?: 'Mutations'
  user: {
    __typename?: 'UpdateUserPayload'
    data: {
      __typename?: 'UserNode'
      id: string
      timezone: Timezone | null
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type MembersPickerQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type MembersPickerQuery = {
  __typename?: 'Query'
  profile: {
    __typename?: 'ProfileNode'
    id: string
    members: {
      __typename?: 'MemberNodeConnection'
      edges: Array<{
        __typename?: 'MemberNodeEdge'
        node: {
          __typename?: 'MemberNode'
          id: string
          user: {
            __typename?: 'UserNode'
            email: string
            firstName: string
            id: string
            image: string | null
            lastName: string
          }
        } | null
      } | null>
    }
  }
}

export type NewMeetingTimeModalMutationVariables = Exact<{
  input: RescheduleMeetingInput
}>

export type NewMeetingTimeModalMutation = {
  __typename?: 'Mutations'
  rescheduleMeeting: {
    __typename?: 'RescheduleMeetingPayload'
    data: {
      __typename?: 'MeetingNode'
      end: any
      id: string
      name: string
      start: any
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingCalendarIntegrationsPageQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type OnboardingCalendarIntegrationsPageQuery = {
  __typename?: 'Query'
  member: {
    __typename?: 'MemberNode'
    id: string
    calendarAccounts: {
      __typename?: 'CalendarAccountNodeConnection'
      edges: Array<{
        __typename?: 'CalendarAccountNodeEdge'
        node: {
          __typename?: 'CalendarAccountNode'
          id: string
          calendars: {
            __typename?: 'CalendarNodeConnection'
            edges: Array<{
              __typename?: 'CalendarNodeEdge'
              node: {
                __typename?: 'CalendarNode'
                color: string | null
                id: string
                name: string
                permissionLevel: CalendarPermissionLevel
                syncMeetings: boolean
                transparent: boolean
              } | null
            } | null>
          }
          provider: {
            __typename?: 'CalendarProviderNode'
            id: string
            name: string
            slug: string
          }
        } | null
      } | null>
    }
  }
  providers: {
    __typename?: 'CalendarProviderNodeConnection'
    edges: Array<{
      __typename?: 'CalendarProviderNodeEdge'
      node: {
        __typename?: 'CalendarProviderNode'
        id: string
        name: string
        oauth2AuthorizationUrl: string
        slug: string
        shortDescription: string | null
      } | null
    } | null>
  }
}

export type OnboardingCalendarIntegrationsPageCreateMutationVariables = Exact<{
  input: CreateCalendarAccountInput
}>

export type OnboardingCalendarIntegrationsPageCreateMutation = {
  __typename?: 'Mutations'
  createCalendarAccount: {
    __typename?: 'CreateCalendarAccountPayload'
    data: {
      __typename?: 'CalendarAccountNode'
      id: string
      isConnected: boolean
      name: string
      calendars: {
        __typename?: 'CalendarNodeConnection'
        edges: Array<{
          __typename?: 'CalendarNodeEdge'
          node: {
            __typename?: 'CalendarNode'
            color: string | null
            id: string
            lastSyncAt: any | null
            name: string
            syncMeetings: boolean
            transparent: boolean
          } | null
        } | null>
      }
      provider: {
        __typename?: 'CalendarProviderNode'
        name: string
        slug: string
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingFinishedPageUpdateMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type OnboardingFinishedPageUpdateMutation = {
  __typename?: 'Mutations'
  user: {
    __typename?: 'UpdateUserPayload'
    data: {
      __typename?: 'UserNode'
      id: string
      needsOnboarding: boolean
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingInviteMembersPageMutationVariables = Exact<{
  input: InviteMembersInput
}>

export type OnboardingInviteMembersPageMutation = {
  __typename?: 'Mutations'
  inviteMembers: {
    __typename?: 'InviteMembersPayload'
    data: Array<{
      __typename?: 'MemberNode'
      id: string
      user: {
        __typename?: 'UserNode'
        email: string
        firstName: string
        id: string
        image: string | null
        lastName: string
      }
    } | null> | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingProfileInfoPageMutationVariables = Exact<{
  input: UpdateProfileInput
}>

export type OnboardingProfileInfoPageMutation = {
  __typename?: 'Mutations'
  updateProfile: {
    __typename?: 'UpdateProfilePayload'
    data: {
      __typename?: 'ProfileNode'
      id: string
      image: string | null
      name: string | null
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingProfileTypeCreateMutationVariables = Exact<{
  input: CreateProfileInput
}>

export type OnboardingProfileTypeCreateMutation = {
  __typename?: 'Mutations'
  profile: {
    __typename?: 'CreateProfilePayload'
    data: {
      __typename?: 'ProfileNode'
      billingCustomerId: string | null
      id: string
      personal: boolean
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingProfileTypeUpdateMutationVariables = Exact<{
  input: UpdateProfileInput
}>

export type OnboardingProfileTypeUpdateMutation = {
  __typename?: 'Mutations'
  profile: {
    __typename?: 'UpdateProfilePayload'
    data: {
      __typename?: 'ProfileNode'
      billingCustomerId: string | null
      id: string
      personal: boolean
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingWebConferencingPageQueryVariables = Exact<{
  [key: string]: never
}>

export type OnboardingWebConferencingPageQuery = {
  __typename?: 'Query'
  providers: {
    __typename?: 'ConferenceProviderNodeConnection'
    edges: Array<{
      __typename?: 'ConferenceProviderNodeEdge'
      node: {
        __typename?: 'ConferenceProviderNode'
        id: string
        name: string
        oauth2AuthorizationUrl: string
        slug: string
      } | null
    } | null>
  }
}

export type OnboardingWebConferencingPageCreateMutationVariables = Exact<{
  input: CreateConferencingAccountInput
}>

export type OnboardingWebConferencingPageCreateMutation = {
  __typename?: 'Mutations'
  account: {
    __typename?: 'CreateConferencingAccountPayload'
    data:
      | { __typename?: 'GoToMeetingAccountNode'; id: string }
      | { __typename?: 'JoinMeAccountNode'; id: string }
      | { __typename?: 'MicrosoftTeamsAccountNode'; id: string }
      | { __typename?: 'WebexAccountNode'; id: string }
      | { __typename?: 'ZoomAccountNode'; id: string }
      | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type PaymentCollectionAccountIsConnectedQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type PaymentCollectionAccountIsConnectedQuery = {
  __typename?: 'Query'
  account: {
    __typename?: 'PaymentCollectionAccountNode'
    id: string
    isConnected: boolean
  } | null
}

export type CreatePaymentCollectionAccountMutationVariables = Exact<{
  input: CreatePaymentCollectionAccountInput
}>

export type CreatePaymentCollectionAccountMutation = {
  __typename?: 'Mutations'
  account: {
    __typename?: 'CreatePaymentCollectionAccountPayload'
    data: {
      __typename?: 'PaymentCollectionAccountNode'
      createdAt: any
      deleted: any | null
      id: string
      name: string
      profile: { __typename?: 'ProfileNode'; id: string } | null
      provider: {
        __typename?: 'PaymentCollectionProviderNode'
        id: string
        name: string
        slug: string
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type ReconnectPaymentCollectionAccountMutationVariables = Exact<{
  input: UpdatePaymentCollectionAccountInput
}>

export type ReconnectPaymentCollectionAccountMutation = {
  __typename?: 'Mutations'
  account: {
    __typename?: 'UpdatePaymentCollectionAccountPayload'
    data: {
      __typename?: 'PaymentCollectionAccountNode'
      id: string
      isConnected: boolean
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type PremiumUpgradeModalQueryVariables = Exact<{
  profile: Scalars['ID']
}>

export type PremiumUpgradeModalQuery = {
  __typename?: 'Query'
  paymentMethod: {
    __typename?: 'PaymentSourceNode'
    brand: string
    expiration: string
    id: string
    last4: string
  } | null
  premiumPlan: {
    __typename?: 'PlanNode'
    id: string
    name: string
    seatPriceMonthly: number
    seatPriceYearly: number
  }
}

export type UpdatePlanProfileMutationVariables = Exact<{
  input: UpdateProfilePlanInput
}>

export type UpdatePlanProfileMutation = {
  __typename?: 'Mutations'
  profile: {
    __typename?: 'UpdateProfilePlanPayload'
    data: {
      __typename?: 'ProfileNode'
      id: string
      billedAnnually: boolean
      plan: {
        __typename?: 'PlanNode'
        id: string
        name: string
        freeDefault: boolean
        requireAppointletBranding: boolean
        seatPriceMonthly: number
        seatPriceYearly: number
        trialDefault: boolean
        allowEmailCustomization: boolean
        allowFormFields: boolean
        allowManualConfirm: boolean
        allowPaymentCollection: boolean
        allowPrivateMeetingTypes: boolean
        allowRedirect: boolean
        allowReminders: boolean
        allowRoundRobin: boolean
        allowWebConferencing: boolean
        allowWebhooks: boolean
        allowUtmParameters: boolean
        allowZapier: boolean
      } | null
    } | null
  } | null
}

export type RemoveMemberModalMutationVariables = Exact<{
  input: DeleteMemberInput
}>

export type RemoveMemberModalMutation = {
  __typename?: 'Mutations'
  deleteMember: {
    __typename?: 'DeleteMemberPayload'
    data: { __typename?: 'MemberNode'; deleted: any | null; id: string } | null
  } | null
}

export type RescheduleMeetingDatePickerQueryVariables = Exact<{
  end?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
  start?: InputMaybe<Scalars['DateTime']>
}>

export type RescheduleMeetingDatePickerQuery = {
  __typename?: 'Query'
  availableTimes: {
    __typename?: 'MeetingNode'
    id: string
    reschedulableTimes: {
      __typename?: 'AvailableTimesNode'
      data: Array<any | null> | null
    } | null
  }
}

export type RescheduleMeetingModalQueryVariables = Exact<{
  end?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
  start?: InputMaybe<Scalars['DateTime']>
}>

export type RescheduleMeetingModalQuery = {
  __typename?: 'Query'
  meeting: {
    __typename?: 'MeetingNode'
    end: any
    id: string
    start: any
    reschedulableTimes: {
      __typename?: 'AvailableTimesNode'
      data: Array<any | null> | null
    } | null
  }
}

export type SchedulingPagePickerQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SchedulingPagePickerQuery = {
  __typename?: 'Query'
  member: {
    __typename?: 'MemberNode'
    id: string
    teams: {
      __typename?: 'TeamNodeConnection'
      edges: Array<{
        __typename?: 'TeamNodeEdge'
        node: {
          __typename?: 'TeamNode'
          deleted: any | null
          id: string
          image: string | null
          name: string
        } | null
      } | null>
    }
  }
}

export type SchedulingPagesPageQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SchedulingPagesPageQuery = {
  __typename?: 'Query'
  member: {
    __typename?: 'MemberNode'
    id: string
    teams: {
      __typename?: 'TeamNodeConnection'
      edges: Array<{
        __typename?: 'TeamNodeEdge'
        node: {
          __typename?: 'TeamNode'
          deleted: any | null
          id: string
        } | null
      } | null>
    }
  }
}

export type SchedulingPagesPageEmptyStateCreateMeetingTypeMutationVariables =
  Exact<{
    input1: CreateMeetingTypeInput
    input2: CreateMeetingTypeInput
    input3: CreateMeetingTypeInput
  }>

export type SchedulingPagesPageEmptyStateCreateMeetingTypeMutation = {
  __typename?: 'Mutations'
  meetingType1: {
    __typename?: 'CreateMeetingTypePayload'
    data: {
      __typename?: 'MeetingTypeNode'
      id: string
      order: number
      name: string
      color: string
      duration: any
      bookingUrl: string
      slug: string
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
  meetingType2: {
    __typename?: 'CreateMeetingTypePayload'
    data: {
      __typename?: 'MeetingTypeNode'
      id: string
      order: number
      name: string
      color: string
      duration: any
      bookingUrl: string
      slug: string
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
  meetingType3: {
    __typename?: 'CreateMeetingTypePayload'
    data: {
      __typename?: 'MeetingTypeNode'
      id: string
      order: number
      name: string
      color: string
      duration: any
      bookingUrl: string
      slug: string
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type SettingsModalBillingSectionQueryVariables = Exact<{
  profile: Scalars['ID']
}>

export type SettingsModalBillingSectionQuery = {
  __typename?: 'Query'
  subscription: {
    __typename?: 'SubscriptionNode'
    id: string
    paymentType: string
    markedForCancelation: boolean
    status: string
    cycleInterval: string
    cycleEnd: any
    trialEnd: any | null
    seats: number
  }
  paymentMethod: {
    __typename?: 'PaymentSourceNode'
    id: string
    brand: string
    last4: string
    expiration: string
  } | null
  profile: {
    __typename?: 'ProfileNode'
    id: string
    billedAnnually: boolean
    allocatedSeats: number | null
    occupiedSeats: number
    upcomingInvoiceAmount: number | null
    billingbotToken: string | null
    plan: {
      __typename?: 'PlanNode'
      id: string
      name: string
      freeDefault: boolean
      trialDefault: boolean
      seatPriceMonthly: number
      seatPriceYearly: number
      requireAppointletBranding: boolean
      allowEmailCustomization: boolean
      allowFormFields: boolean
      allowManualConfirm: boolean
      allowPrivateMeetingTypes: boolean
      allowRedirect: boolean
      allowReminders: boolean
      allowWebConferencing: boolean
      allowWebhooks: boolean
      allowZapier: boolean
    } | null
  }
  plans: {
    __typename?: 'PlanNodeConnection'
    edges: Array<{
      __typename?: 'PlanNodeEdge'
      node: {
        __typename?: 'PlanNode'
        id: string
        name: string
        freeDefault: boolean
        trialDefault: boolean
        seatPriceMonthly: number
        seatPriceYearly: number
        requireAppointletBranding: boolean
        allowEmailCustomization: boolean
        allowFormFields: boolean
        allowManualConfirm: boolean
        allowPrivateMeetingTypes: boolean
        allowRedirect: boolean
        allowReminders: boolean
        allowWebConferencing: boolean
        allowWebhooks: boolean
        allowZapier: boolean
      } | null
    } | null>
  } | null
}

export type PlanDataFragment = {
  __typename?: 'PlanNode'
  id: string
  name: string
  freeDefault: boolean
  trialDefault: boolean
  seatPriceMonthly: number
  seatPriceYearly: number
  requireAppointletBranding: boolean
  allowEmailCustomization: boolean
  allowFormFields: boolean
  allowManualConfirm: boolean
  allowPrivateMeetingTypes: boolean
  allowRedirect: boolean
  allowReminders: boolean
  allowWebConferencing: boolean
  allowWebhooks: boolean
  allowZapier: boolean
}

export type SettingsModalCalendarSectionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SettingsModalCalendarSectionQuery = {
  __typename?: 'Query'
  calendarProviders: {
    __typename?: 'CalendarProviderNodeConnection'
    edges: Array<{
      __typename?: 'CalendarProviderNodeEdge'
      node: {
        __typename?: 'CalendarProviderNode'
        id: string
        name: string
        oauth2AuthorizationUrl: string
        slug: string
        shortDescription: string | null
      } | null
    } | null>
  }
  member: {
    __typename?: 'MemberNode'
    id: string
    calendarAccounts: {
      __typename?: 'CalendarAccountNodeConnection'
      edges: Array<{
        __typename?: 'CalendarAccountNodeEdge'
        node: {
          __typename?: 'CalendarAccountNode'
          id: string
          name: string
          isConnected: boolean
          oauth2AuthorizationUrl: string
          provider: { __typename?: 'CalendarProviderNode'; id: string }
        } | null
      } | null>
    }
    meetingsCalendar: {
      __typename?: 'CalendarNode'
      id: string
      name: string
      color: string | null
    } | null
    availabilityCalendars: {
      __typename?: 'CalendarNodeConnection'
      edges: Array<{
        __typename?: 'CalendarNodeEdge'
        node: {
          __typename?: 'CalendarNode'
          id: string
          name: string
          color: string | null
        } | null
      } | null>
    }
  }
}

export type CreateCalendarAccountMutationVariables = Exact<{
  input: CreateCalendarAccountInput
}>

export type CreateCalendarAccountMutation = {
  __typename?: 'Mutations'
  createCalendarAccount: {
    __typename?: 'CreateCalendarAccountPayload'
    data: {
      __typename?: 'CalendarAccountNode'
      id: string
      name: string
      isConnected: boolean
      oauth2AuthorizationUrl: string
      provider: {
        __typename?: 'CalendarProviderNode'
        id: string
        name: string
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type UpdateCalendarAccountMutationVariables = Exact<{
  input: UpdateCalendarAccountInput
}>

export type UpdateCalendarAccountMutation = {
  __typename?: 'Mutations'
  updateCalendarAccount: {
    __typename?: 'UpdateCalendarAccountPayload'
    data: {
      __typename?: 'CalendarAccountNode'
      id: string
      name: string
      isConnected: boolean
      oauth2AuthorizationUrl: string
      provider: {
        __typename?: 'CalendarProviderNode'
        id: string
        name: string
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type SettingsModalGeneralSectionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SettingsModalGeneralSectionQuery = {
  __typename?: 'Query'
  profile: {
    __typename?: 'ProfileNode'
    brandColor: string
    id: string
    image: string | null
    name: string | null
    showAppointletBranding: boolean
  }
}

export type SettingsModalGeneralSectionUpdateMutationVariables = Exact<{
  input: UpdateProfileInput
}>

export type SettingsModalGeneralSectionUpdateMutation = {
  __typename?: 'Mutations'
  profile: {
    __typename?: 'UpdateProfilePayload'
    data: {
      __typename?: 'ProfileNode'
      brandColor: string
      id: string
      image: string | null
      name: string | null
      showAppointletBranding: boolean
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type SettingsModalLoginSectionQueryVariables = Exact<{
  [key: string]: never
}>

export type SettingsModalLoginSectionQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'UserNode'
    id: string
    passwordUpdatedAt: any | null
  } | null
}

export type SettingsModalPaymentSectionQueryVariables = Exact<{
  profile: Scalars['ID']
}>

export type SettingsModalPaymentSectionQuery = {
  __typename?: 'Query'
  profile: {
    __typename?: 'ProfileNode'
    id: string
    paymentCollectionAccounts: {
      __typename?: 'PaymentCollectionAccountNodeConnection'
      edges: Array<{
        __typename?: 'PaymentCollectionAccountNodeEdge'
        node: {
          __typename?: 'PaymentCollectionAccountNode'
          deleted: any | null
          id: string
          name: string
          provider: {
            __typename?: 'PaymentCollectionProviderNode'
            id: string
            name: string
            oauth2AuthorizationUrl: string
            slug: string
          }
        } | null
      } | null>
    }
  }
  providers: {
    __typename?: 'PaymentCollectionProviderNodeConnection'
    edges: Array<{
      __typename?: 'PaymentCollectionProviderNodeEdge'
      node: {
        __typename?: 'PaymentCollectionProviderNode'
        id: string
        name: string
        oauth2AuthorizationUrl: string
        slug: string
      } | null
    } | null>
  }
}

export type SettingsModalProfileSectionQueryVariables = Exact<{
  [key: string]: never
}>

export type SettingsModalProfileSectionQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'UserNode'
    id: string
    email: string
    firstName: string
    language: Language
    lastName: string
    image: string | null
    timezone: Timezone | null
    members: {
      __typename?: 'MemberNodeConnection'
      edges: Array<{
        __typename?: 'MemberNodeEdge'
        node: {
          __typename?: 'MemberNode'
          id: string
          meetingCount: number
          profile: {
            __typename?: 'ProfileNode'
            id: string
            name: string | null
            personal: boolean
            members: {
              __typename?: 'MemberNodeConnection'
              edges: Array<{
                __typename?: 'MemberNodeEdge'
                node: {
                  __typename?: 'MemberNode'
                  id: string
                  user: {
                    __typename?: 'UserNode'
                    email: string
                    id: string
                    image: string | null
                    firstName: string
                    lastName: string
                  }
                } | null
              } | null>
            }
          }
          role: {
            __typename?: 'RoleNode'
            canManageBilling: boolean
            canManageMeetings: boolean
            canManageOrgSettings: boolean
            canManageMeetingTypes: boolean
          }
        } | null
      } | null>
    }
  } | null
}

export type SettingsModalProfileSectionUpdateMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type SettingsModalProfileSectionUpdateMutation = {
  __typename?: 'Mutations'
  updateUser: {
    __typename?: 'UpdateUserPayload'
    data: {
      __typename?: 'UserNode'
      createdAt: any
      firstName: string
      email: string
      id: string
      image: string | null
      language: Language
      lastName: string
      timezone: Timezone | null
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type SettingsModalUsersSectionQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  id: Scalars['ID']
  isDeleted?: InputMaybe<Scalars['Boolean']>
  last?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
}>

export type SettingsModalUsersSectionQuery = {
  __typename?: 'Query'
  profile: {
    __typename?: 'ProfileNode'
    id: string
    roles: {
      __typename?: 'RoleNodeConnection'
      edges: Array<{
        __typename?: 'RoleNodeEdge'
        node: {
          __typename?: 'RoleNode'
          default: boolean
          id: string
          name: string | null
        } | null
      } | null>
    }
    members: {
      __typename?: 'MemberNodeConnection'
      edges: Array<{
        __typename?: 'MemberNodeEdge'
        node: {
          __typename?: 'MemberNode'
          deleted: any | null
          id: string
          invitationAccepted: boolean
          isOwner: boolean
          isSchedulable: boolean
          role: { __typename?: 'RoleNode'; id: string; name: string | null }
          user: {
            __typename?: 'UserNode'
            email: string
            firstName: string
            id: string
            image: string | null
            lastName: string
          }
        } | null
      } | null>
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor: string | null
      }
    }
  }
}

export type UpdateMemberRoleMutationVariables = Exact<{
  input: UpdateMemberInput
}>

export type UpdateMemberRoleMutation = {
  __typename?: 'Mutations'
  updateMember: {
    __typename?: 'UpdateMemberPayload'
    data: {
      __typename?: 'MemberNode'
      id: string
      role: { __typename?: 'RoleNode'; id: string; name: string | null }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type ResendMemberInviteMutationVariables = Exact<{
  input: ResendMemberInvitationInput
}>

export type ResendMemberInviteMutation = {
  __typename?: 'Mutations'
  resendMemberInvitation: {
    __typename?: 'ResendMemberInvitationPayload'
    data: { __typename?: 'MemberNode'; id: string } | null
  } | null
}

export type SettingsModalWebConferencingSectionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SettingsModalWebConferencingSectionQuery = {
  __typename?: 'Query'
  member: {
    __typename?: 'MemberNode'
    id: string
    conferencingAccounts: {
      __typename?: 'ConferencingAccountNodeConnection'
      edges: Array<{
        __typename?: 'ConferencingAccountNodeEdge'
        node:
          | {
              __typename: 'GoToMeetingAccountNode'
              deleted: any | null
              id: string
              isDefault: boolean
              name: string
              provider: {
                __typename?: 'ConferenceProviderNode'
                id: string
                name: string
                slug: string
                oauth2AuthorizationUrl: string
              }
            }
          | {
              __typename: 'JoinMeAccountNode'
              deleted: any | null
              id: string
              isDefault: boolean
              name: string
              provider: {
                __typename?: 'ConferenceProviderNode'
                id: string
                name: string
                slug: string
                oauth2AuthorizationUrl: string
              }
            }
          | {
              __typename: 'MicrosoftTeamsAccountNode'
              deleted: any | null
              id: string
              isDefault: boolean
              name: string
              provider: {
                __typename?: 'ConferenceProviderNode'
                id: string
                name: string
                slug: string
                oauth2AuthorizationUrl: string
              }
            }
          | {
              __typename: 'WebexAccountNode'
              deleted: any | null
              id: string
              isDefault: boolean
              name: string
              provider: {
                __typename?: 'ConferenceProviderNode'
                id: string
                name: string
                slug: string
                oauth2AuthorizationUrl: string
              }
            }
          | {
              __typename: 'ZoomAccountNode'
              deleted: any | null
              id: string
              isDefault: boolean
              name: string
              provider: {
                __typename?: 'ConferenceProviderNode'
                id: string
                name: string
                slug: string
                oauth2AuthorizationUrl: string
              }
            }
          | null
      } | null>
    }
  }
  providers: {
    __typename?: 'ConferenceProviderNodeConnection'
    edges: Array<{
      __typename?: 'ConferenceProviderNodeEdge'
      node: {
        __typename?: 'ConferenceProviderNode'
        id: string
        name: string
        oauth2AuthorizationUrl: string
        slug: string
      } | null
    } | null>
  }
}

export type SettingsModalWebhooksSectionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SettingsModalWebhooksSectionQuery = {
  __typename?: 'Query'
  profile: {
    __typename?: 'ProfileNode'
    id: string
    webhooks: {
      __typename?: 'WebhookNodeConnection'
      edges: Array<{
        __typename?: 'WebhookNodeEdge'
        node: {
          __typename?: 'WebhookNode'
          active: boolean
          createdAt: any
          deleted: any | null
          id: string
          name: string | null
          url: string
          member: {
            __typename?: 'MemberNode'
            id: string
            user: {
              __typename?: 'UserNode'
              firstName: string
              id: string
              lastName: string
            }
          } | null
        } | null
      } | null>
    }
  }
}

export type SettingsModalWebhookDeleteMutationVariables = Exact<{
  input: DeleteWebhookInput
}>

export type SettingsModalWebhookDeleteMutation = {
  __typename?: 'Mutations'
  webHook: {
    __typename?: 'DeleteWebhookPayload'
    data: { __typename?: 'WebhookNode'; deleted: any | null; id: string } | null
  } | null
}

export type MoveMeetingTypeMutationVariables = Exact<{
  input: MoveMeetingTypeInput
}>

export type MoveMeetingTypeMutation = {
  __typename?: 'Mutations'
  moveMeetingType: {
    __typename?: 'MoveMeetingTypePayload'
    data: Array<{
      __typename?: 'MeetingTypeNode'
      id: string
      order: number
    } | null> | null
  } | null
}

export type TimeZonePickerQueryVariables = Exact<{ [key: string]: never }>

export type TimeZonePickerQuery = {
  __typename?: 'Query'
  timeZones: {
    __typename?: '__Type'
    enumValues: Array<{
      __typename?: '__EnumValue'
      name: string
      description: string | null
    }> | null
  } | null
}

export type RolePickerChangeOwnerMutationVariables = Exact<{
  input: SetProfileOwnerInput
}>

export type RolePickerChangeOwnerMutation = {
  __typename?: 'Mutations'
  profile: {
    __typename?: 'SetProfileOwnerPayload'
    data: { __typename?: 'MemberNode'; id: string } | null
  } | null
}

export type UpdateAttendeeMutationVariables = Exact<{
  input: UpdateAttendeeInput
}>

export type UpdateAttendeeMutation = {
  __typename?: 'Mutations'
  updatedAttendee: {
    __typename?: 'UpdateAttendeePayload'
    data: {
      __typename?: 'AttendeeNode'
      id: string
      firstName: string | null
      lastName: string | null
      email: string
      timezone: Timezone
      fieldSubmissions: {
        __typename?: 'AttendeeFieldNodeConnection'
        edges: Array<{
          __typename?: 'AttendeeFieldNodeEdge'
          node: {
            __typename?: 'AttendeeFieldNode'
            id: string
            fieldType: FieldType
            slug: string
            order: number
            required: boolean
            visible: boolean
            name: string
            helpText: string | null
            value: any
            choices: Array<string> | null
          } | null
        } | null>
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type UpdateAttendeeModalQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UpdateAttendeeModalQuery = {
  __typename?: 'Query'
  attendee: {
    __typename?: 'AttendeeNode'
    id: string
    email: string
    firstName: string | null
    lastName: string | null
    timezone: Timezone
    fieldSubmissions: {
      __typename?: 'AttendeeFieldNodeConnection'
      edges: Array<{
        __typename?: 'AttendeeFieldNodeEdge'
        node: {
          __typename?: 'AttendeeFieldNode'
          id: string
          fieldType: FieldType
          slug: string
          order: number
          required: boolean
          visible: boolean
          name: string
          helpText: string | null
          value: any
          choices: Array<string> | null
        } | null
      } | null>
    }
  } | null
}

export type UpdateAvailabilityCalendarsModalMutationVariables = Exact<{
  input: SetCalendarTransparencyInput
}>

export type UpdateAvailabilityCalendarsModalMutation = {
  __typename?: 'Mutations'
  setCalendarTransparency: {
    __typename?: 'SetCalendarTransparencyPayload'
    data: {
      __typename?: 'CalendarNode'
      id: string
      transparent: boolean
    } | null
  } | null
}

export type UpdateConferenceAccountModalQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UpdateConferenceAccountModalQuery = {
  __typename?: 'Query'
  conferencingAccount:
    | {
        __typename?: 'GoToMeetingAccountNode'
        conferenceCallInfo: GoToMeetingConferenceCallInfoOptions | null
        id: string
        name: string
      }
    | {
        __typename?: 'JoinMeAccountNode'
        startWithPersonalUrl: boolean | null
        id: string
        name: string
      }
    | { __typename?: 'MicrosoftTeamsAccountNode'; id: string; name: string }
    | { __typename?: 'WebexAccountNode'; id: string; name: string }
    | {
        __typename?: 'ZoomAccountNode'
        audio: ZoomAudioOptions | null
        autoRecording: ZoomAutoRecordingOptions | null
        cnMeeting: boolean | null
        hostVideo: boolean | null
        inMeeting: boolean | null
        joinBeforeHost: boolean | null
        muteUponEntry: boolean | null
        participantVideo: boolean | null
        watermark: boolean | null
        id: string
        name: string
      }
    | null
}

export type UpdateGoToMeetingMutationVariables = Exact<{
  input: UpdateGoToMeetingAccountInput
}>

export type UpdateGoToMeetingMutation = {
  __typename?: 'Mutations'
  account: {
    __typename?: 'UpdateGoToMeetingAccountPayload'
    data: {
      __typename?: 'GoToMeetingAccountNode'
      conferenceCallInfo: GoToMeetingConferenceCallInfoOptions | null
      id: string
      name: string
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type UpdateJoinMeMutationVariables = Exact<{
  input: UpdateJoinMeAccountInput
}>

export type UpdateJoinMeMutation = {
  __typename?: 'Mutations'
  account: {
    __typename?: 'UpdateJoinMeAccountPayload'
    data: {
      __typename?: 'JoinMeAccountNode'
      startWithPersonalUrl: boolean | null
      id: string
      name: string
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type UpdateWebexMutationVariables = Exact<{
  input: UpdateWebexAccountInput
}>

export type UpdateWebexMutation = {
  __typename?: 'Mutations'
  account: {
    __typename?: 'UpdateWebexAccountPayload'
    data: { __typename?: 'WebexAccountNode'; id: string; name: string } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type UpdateZoomMutationVariables = Exact<{
  input: UpdateZoomAccountInput
}>

export type UpdateZoomMutation = {
  __typename?: 'Mutations'
  account: {
    __typename?: 'UpdateZoomAccountPayload'
    data: {
      __typename?: 'ZoomAccountNode'
      audio: ZoomAudioOptions | null
      autoRecording: ZoomAutoRecordingOptions | null
      cnMeeting: boolean | null
      hostVideo: boolean | null
      inMeeting: boolean | null
      joinBeforeHost: boolean | null
      muteUponEntry: boolean | null
      participantVideo: boolean | null
      watermark: boolean | null
      id: string
      name: string
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type UpdateMeetingCalendarModalMutationVariables = Exact<{
  input: SetDefaultCalendarInput
}>

export type UpdateMeetingCalendarModalMutation = {
  __typename?: 'Mutations'
  setDefaultCalendar: {
    __typename?: 'SetDefaultCalendarPayload'
    new: {
      __typename?: 'CalendarNode'
      id: string
      name: string
      syncMeetings: boolean
    } | null
    old: {
      __typename?: 'CalendarNode'
      id: string
      syncMeetings: boolean
    } | null
  } | null
}

export type UpdateMeetingTypeModalQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UpdateMeetingTypeModalQuery = {
  __typename?: 'Query'
  meetingType: {
    __typename?: 'MeetingTypeNode'
    active: boolean
    allowCancellations: boolean
    allowReschedules: boolean
    autoConfirm: boolean
    bookingUrl: string
    bufferAfter: any
    bufferBefore: any
    cancellationEmailBody: string | null
    cancellationEmailSubject: string | null
    cancellationNotice: any
    color: string
    confirmationEmailBody: string | null
    confirmationEmailSubject: string | null
    deleted: any | null
    description: string | null
    duration: any
    frequency: any | null
    hostAssignmentStrategy: HostAssignmentStrategy
    id: string
    image: string | null
    instructions: string | null
    isGroup: boolean
    location: string | null
    locationType: LocationType | null
    maxAttendees: number
    maxBookingsPerDay: number | null
    maximumNotice: any
    minimumNotice: any
    name: string
    price: number
    priceCurrency: Currency | null
    priceFormatted: string
    public: boolean
    redirectUrl: string | null
    includeDataInRedirectUrl: boolean
    rescheduleNotice: any
    sendCancellationEmail: boolean
    sendConfirmationEmail: boolean
    slug: string
    tags: Array<string>
    timezone: Timezone | null
    useCalendarInvitations: boolean
    usesLocalTimezone: boolean
    conferencingProvider: {
      __typename?: 'ConferenceProviderNode'
      id: string
      name: string
      slug: string
    } | null
    fields: {
      __typename?: 'FormFieldNodeConnection'
      edges: Array<{
        __typename?: 'FormFieldNodeEdge'
        node: {
          __typename?: 'FormFieldNode'
          id: string
          choices: Array<string> | null
          fieldType: FieldType
          helpText: string | null
          name: string
          order: number
          required: boolean
          visible: boolean
          slug: string
        } | null
      } | null>
    }
    meetingTypeTeamMembers: {
      __typename?: 'MeetingTypeTeamMemberNodeConnection'
      edges: Array<{
        __typename?: 'MeetingTypeTeamMemberNodeEdge'
        node: {
          __typename?: 'MeetingTypeTeamMemberNode'
          active: boolean
          customAvailability: boolean
          id: string
          order: number
          priority: number
          willHost: boolean
          schedules: {
            __typename?: 'TeamMemberScheduleNodeConnection'
            edges: Array<{
              __typename?: 'TeamMemberScheduleNodeEdge'
              node: {
                __typename?: 'TeamMemberScheduleNode'
                kind: ScheduleKind
                name: string | null
                order: number
                timezone: Timezone
                bounds: {
                  __typename?: 'DateRangeNode'
                  lower: any | null
                  upper: any | null
                } | null
                timeBlocks: {
                  __typename?: 'TeamMemberTimeBlockNodeConnection'
                  edges: Array<{
                    __typename?: 'TeamMemberTimeBlockNodeEdge'
                    node: {
                      __typename?: 'TeamMemberTimeBlockNode'
                      date: any | null
                      endTime: any | null
                      frequency: any | null
                      startTime: any
                      weekday: Weekday | null
                    } | null
                  } | null>
                }
              } | null
            } | null>
          }
          teamMember: {
            __typename?: 'TeamMemberNode'
            id: string
            member: {
              __typename?: 'MemberNode'
              deleted: any | null
              id: string
              invitationAccepted: boolean
              calendarAccounts: {
                __typename?: 'CalendarAccountNodeConnection'
                edges: Array<{
                  __typename?: 'CalendarAccountNodeEdge'
                  node: {
                    __typename?: 'CalendarAccountNode'
                    id: string
                    provider: {
                      __typename?: 'CalendarProviderNode'
                      id: string
                      slug: string
                    }
                  } | null
                } | null>
              }
              conferencingAccounts: {
                __typename?: 'ConferencingAccountNodeConnection'
                edges: Array<{
                  __typename?: 'ConferencingAccountNodeEdge'
                  node:
                    | {
                        __typename?: 'GoToMeetingAccountNode'
                        id: string
                        deleted: any | null
                        provider: {
                          __typename?: 'ConferenceProviderNode'
                          id: string
                          slug: string
                        }
                      }
                    | {
                        __typename?: 'JoinMeAccountNode'
                        id: string
                        deleted: any | null
                        provider: {
                          __typename?: 'ConferenceProviderNode'
                          id: string
                          slug: string
                        }
                      }
                    | {
                        __typename?: 'MicrosoftTeamsAccountNode'
                        id: string
                        deleted: any | null
                        provider: {
                          __typename?: 'ConferenceProviderNode'
                          id: string
                          slug: string
                        }
                      }
                    | {
                        __typename?: 'WebexAccountNode'
                        id: string
                        deleted: any | null
                        provider: {
                          __typename?: 'ConferenceProviderNode'
                          id: string
                          slug: string
                        }
                      }
                    | {
                        __typename?: 'ZoomAccountNode'
                        id: string
                        deleted: any | null
                        provider: {
                          __typename?: 'ConferenceProviderNode'
                          id: string
                          slug: string
                        }
                      }
                    | null
                } | null>
              }
              user: {
                __typename?: 'UserNode'
                email: string
                firstName: string
                id: string
                image: string | null
                lastName: string
                timezone: Timezone | null
              }
            }
          }
        } | null
      } | null>
    }
    paymentCollectionProvider: {
      __typename?: 'PaymentCollectionProviderNode'
      id: string
      slug: string
    } | null
    reminders: {
      __typename?: 'MeetingTypeReminderNodeConnection'
      edges: Array<{
        __typename?: 'MeetingTypeReminderNodeEdge'
        node: {
          __typename?: 'MeetingTypeReminderNode'
          emailBody: string | null
          emailSubject: string | null
          leadTime: any
        } | null
      } | null>
    }
    schedules: {
      __typename?: 'MeetingTypeScheduleNodeConnection'
      edges: Array<{
        __typename?: 'MeetingTypeScheduleNodeEdge'
        node: {
          __typename?: 'MeetingTypeScheduleNode'
          kind: ScheduleKind
          name: string | null
          order: number
          timezone: Timezone
          bounds: {
            __typename?: 'DateRangeNode'
            lower: any | null
            upper: any | null
          } | null
          timeBlocks: {
            __typename?: 'MeetingTypeTimeBlockNodeConnection'
            edges: Array<{
              __typename?: 'MeetingTypeTimeBlockNodeEdge'
              node: {
                __typename?: 'MeetingTypeTimeBlockNode'
                date: any | null
                endTime: any | null
                frequency: any | null
                startTime: any
                weekday: Weekday | null
              } | null
            } | null>
          }
        } | null
      } | null>
    }
    team: {
      __typename?: 'TeamNode'
      bookingUrl: string
      description: string | null
      id: string
      image: string | null
      name: string
      slug: string
      teamMembers: {
        __typename?: 'TeamMemberNodeConnection'
        edges: Array<{
          __typename?: 'TeamMemberNodeEdge'
          node: { __typename?: 'TeamMemberNode'; id: string } | null
        } | null>
      }
    }
  }
}

export type UpdateMeetingTypeModalUpdateMutationVariables = Exact<{
  input: UpdateMeetingTypeInput
}>

export type UpdateMeetingTypeModalUpdateMutation = {
  __typename?: 'Mutations'
  updateMeetingType: {
    __typename?: 'UpdateMeetingTypePayload'
    data: {
      __typename?: 'MeetingTypeNode'
      active: boolean
      allowCancellations: boolean
      allowReschedules: boolean
      autoConfirm: boolean
      bookingUrl: string
      bufferAfter: any
      bufferBefore: any
      cancellationEmailBody: string | null
      cancellationEmailSubject: string | null
      cancellationNotice: any
      color: string
      confirmationEmailBody: string | null
      confirmationEmailSubject: string | null
      deleted: any | null
      description: string | null
      duration: any
      frequency: any | null
      hostAssignmentStrategy: HostAssignmentStrategy
      id: string
      image: string | null
      instructions: string | null
      isGroup: boolean
      location: string | null
      locationType: LocationType | null
      maxAttendees: number
      maxBookingsPerDay: number | null
      maximumNotice: any
      minimumNotice: any
      name: string
      price: number
      priceCurrency: Currency | null
      priceFormatted: string
      public: boolean
      redirectUrl: string | null
      includeDataInRedirectUrl: boolean
      rescheduleNotice: any
      sendCancellationEmail: boolean
      sendConfirmationEmail: boolean
      slug: string
      tags: Array<string>
      timezone: Timezone | null
      useCalendarInvitations: boolean
      usesLocalTimezone: boolean
      conferencingProvider: {
        __typename?: 'ConferenceProviderNode'
        id: string
        name: string
        slug: string
      } | null
      fields: {
        __typename?: 'FormFieldNodeConnection'
        edges: Array<{
          __typename?: 'FormFieldNodeEdge'
          node: {
            __typename?: 'FormFieldNode'
            id: string
            choices: Array<string> | null
            fieldType: FieldType
            helpText: string | null
            name: string
            order: number
            required: boolean
            visible: boolean
            slug: string
          } | null
        } | null>
      }
      meetingTypeTeamMembers: {
        __typename?: 'MeetingTypeTeamMemberNodeConnection'
        edges: Array<{
          __typename?: 'MeetingTypeTeamMemberNodeEdge'
          node: {
            __typename?: 'MeetingTypeTeamMemberNode'
            active: boolean
            customAvailability: boolean
            id: string
            order: number
            priority: number
            willHost: boolean
            schedules: {
              __typename?: 'TeamMemberScheduleNodeConnection'
              edges: Array<{
                __typename?: 'TeamMemberScheduleNodeEdge'
                node: {
                  __typename?: 'TeamMemberScheduleNode'
                  kind: ScheduleKind
                  name: string | null
                  order: number
                  timezone: Timezone
                  bounds: {
                    __typename?: 'DateRangeNode'
                    lower: any | null
                    upper: any | null
                  } | null
                  timeBlocks: {
                    __typename?: 'TeamMemberTimeBlockNodeConnection'
                    edges: Array<{
                      __typename?: 'TeamMemberTimeBlockNodeEdge'
                      node: {
                        __typename?: 'TeamMemberTimeBlockNode'
                        date: any | null
                        endTime: any | null
                        frequency: any | null
                        startTime: any
                        weekday: Weekday | null
                      } | null
                    } | null>
                  }
                } | null
              } | null>
            }
            teamMember: {
              __typename?: 'TeamMemberNode'
              id: string
              member: {
                __typename?: 'MemberNode'
                deleted: any | null
                id: string
                invitationAccepted: boolean
                calendarAccounts: {
                  __typename?: 'CalendarAccountNodeConnection'
                  edges: Array<{
                    __typename?: 'CalendarAccountNodeEdge'
                    node: {
                      __typename?: 'CalendarAccountNode'
                      id: string
                      provider: {
                        __typename?: 'CalendarProviderNode'
                        id: string
                        slug: string
                      }
                    } | null
                  } | null>
                }
                conferencingAccounts: {
                  __typename?: 'ConferencingAccountNodeConnection'
                  edges: Array<{
                    __typename?: 'ConferencingAccountNodeEdge'
                    node:
                      | {
                          __typename?: 'GoToMeetingAccountNode'
                          id: string
                          deleted: any | null
                          provider: {
                            __typename?: 'ConferenceProviderNode'
                            id: string
                            slug: string
                          }
                        }
                      | {
                          __typename?: 'JoinMeAccountNode'
                          id: string
                          deleted: any | null
                          provider: {
                            __typename?: 'ConferenceProviderNode'
                            id: string
                            slug: string
                          }
                        }
                      | {
                          __typename?: 'MicrosoftTeamsAccountNode'
                          id: string
                          deleted: any | null
                          provider: {
                            __typename?: 'ConferenceProviderNode'
                            id: string
                            slug: string
                          }
                        }
                      | {
                          __typename?: 'WebexAccountNode'
                          id: string
                          deleted: any | null
                          provider: {
                            __typename?: 'ConferenceProviderNode'
                            id: string
                            slug: string
                          }
                        }
                      | {
                          __typename?: 'ZoomAccountNode'
                          id: string
                          deleted: any | null
                          provider: {
                            __typename?: 'ConferenceProviderNode'
                            id: string
                            slug: string
                          }
                        }
                      | null
                  } | null>
                }
                user: {
                  __typename?: 'UserNode'
                  email: string
                  firstName: string
                  id: string
                  image: string | null
                  lastName: string
                  timezone: Timezone | null
                }
              }
            }
          } | null
        } | null>
      }
      paymentCollectionProvider: {
        __typename?: 'PaymentCollectionProviderNode'
        id: string
        slug: string
      } | null
      reminders: {
        __typename?: 'MeetingTypeReminderNodeConnection'
        edges: Array<{
          __typename?: 'MeetingTypeReminderNodeEdge'
          node: {
            __typename?: 'MeetingTypeReminderNode'
            emailBody: string | null
            emailSubject: string | null
            leadTime: any
          } | null
        } | null>
      }
      schedules: {
        __typename?: 'MeetingTypeScheduleNodeConnection'
        edges: Array<{
          __typename?: 'MeetingTypeScheduleNodeEdge'
          node: {
            __typename?: 'MeetingTypeScheduleNode'
            kind: ScheduleKind
            name: string | null
            order: number
            timezone: Timezone
            bounds: {
              __typename?: 'DateRangeNode'
              lower: any | null
              upper: any | null
            } | null
            timeBlocks: {
              __typename?: 'MeetingTypeTimeBlockNodeConnection'
              edges: Array<{
                __typename?: 'MeetingTypeTimeBlockNodeEdge'
                node: {
                  __typename?: 'MeetingTypeTimeBlockNode'
                  date: any | null
                  endTime: any | null
                  frequency: any | null
                  startTime: any
                  weekday: Weekday | null
                } | null
              } | null>
            }
          } | null
        } | null>
      }
      team: {
        __typename?: 'TeamNode'
        bookingUrl: string
        description: string | null
        id: string
        image: string | null
        name: string
        slug: string
        teamMembers: {
          __typename?: 'TeamMemberNodeConnection'
          edges: Array<{
            __typename?: 'TeamMemberNodeEdge'
            node: { __typename?: 'TeamMemberNode'; id: string } | null
          } | null>
        }
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type UpdateMeetingTypeModalHostAssignmentSectionQueryVariables = Exact<{
  teamId: Scalars['ID']
}>

export type UpdateMeetingTypeModalHostAssignmentSectionQuery = {
  __typename?: 'Query'
  team: {
    __typename?: 'TeamNode'
    id: string
    teamMembers: {
      __typename?: 'TeamMemberNodeConnection'
      edges: Array<{
        __typename?: 'TeamMemberNodeEdge'
        node: {
          __typename?: 'TeamMemberNode'
          id: string
          member: {
            __typename?: 'MemberNode'
            id: string
            invitationAccepted: boolean
            user: {
              __typename?: 'UserNode'
              email: string
              firstName: string
              id: string
              image: string | null
              lastName: string
              timezone: Timezone | null
            }
            calendarAccounts: {
              __typename?: 'CalendarAccountNodeConnection'
              edges: Array<{
                __typename?: 'CalendarAccountNodeEdge'
                node: {
                  __typename?: 'CalendarAccountNode'
                  id: string
                  provider: {
                    __typename?: 'CalendarProviderNode'
                    id: string
                    slug: string
                  }
                } | null
              } | null>
            }
            conferencingAccounts: {
              __typename?: 'ConferencingAccountNodeConnection'
              edges: Array<{
                __typename?: 'ConferencingAccountNodeEdge'
                node:
                  | {
                      __typename?: 'GoToMeetingAccountNode'
                      id: string
                      provider: {
                        __typename?: 'ConferenceProviderNode'
                        id: string
                        slug: string
                      }
                    }
                  | {
                      __typename?: 'JoinMeAccountNode'
                      id: string
                      provider: {
                        __typename?: 'ConferenceProviderNode'
                        id: string
                        slug: string
                      }
                    }
                  | {
                      __typename?: 'MicrosoftTeamsAccountNode'
                      id: string
                      provider: {
                        __typename?: 'ConferenceProviderNode'
                        id: string
                        slug: string
                      }
                    }
                  | {
                      __typename?: 'WebexAccountNode'
                      id: string
                      provider: {
                        __typename?: 'ConferenceProviderNode'
                        id: string
                        slug: string
                      }
                    }
                  | {
                      __typename?: 'ZoomAccountNode'
                      id: string
                      provider: {
                        __typename?: 'ConferenceProviderNode'
                        id: string
                        slug: string
                      }
                    }
                  | null
              } | null>
            }
          }
        } | null
      } | null>
    }
  }
}

export type MeetingTypeEditorLocationQueryVariables = Exact<{
  meetingTypeId: Scalars['ID']
}>

export type MeetingTypeEditorLocationQuery = {
  __typename?: 'Query'
  providers: {
    __typename?: 'ConferenceProviderNodeConnection'
    edges: Array<{
      __typename?: 'ConferenceProviderNodeEdge'
      node: {
        __typename?: 'ConferenceProviderNode'
        id: string
        name: string
        slug: string
      } | null
    } | null>
  }
  teamMembers: {
    __typename?: 'MeetingTypeNode'
    id: string
    meetingTypeTeamMembers: {
      __typename?: 'MeetingTypeTeamMemberNodeConnection'
      edges: Array<{
        __typename?: 'MeetingTypeTeamMemberNodeEdge'
        node: {
          __typename?: 'MeetingTypeTeamMemberNode'
          id: string
          teamMember: {
            __typename?: 'TeamMemberNode'
            id: string
            member: {
              __typename?: 'MemberNode'
              id: string
              calendarAccounts: {
                __typename?: 'CalendarAccountNodeConnection'
                edges: Array<{
                  __typename?: 'CalendarAccountNodeEdge'
                  node: {
                    __typename?: 'CalendarAccountNode'
                    id: string
                    provider: {
                      __typename?: 'CalendarProviderNode'
                      id: string
                      slug: string
                    }
                  } | null
                } | null>
              }
              conferencingAccounts: {
                __typename?: 'ConferencingAccountNodeConnection'
                edges: Array<{
                  __typename?: 'ConferencingAccountNodeEdge'
                  node:
                    | {
                        __typename?: 'GoToMeetingAccountNode'
                        deleted: any | null
                        id: string
                        provider: {
                          __typename?: 'ConferenceProviderNode'
                          id: string
                          slug: string
                        }
                      }
                    | {
                        __typename?: 'JoinMeAccountNode'
                        deleted: any | null
                        id: string
                        provider: {
                          __typename?: 'ConferenceProviderNode'
                          id: string
                          slug: string
                        }
                      }
                    | {
                        __typename?: 'MicrosoftTeamsAccountNode'
                        deleted: any | null
                        id: string
                        provider: {
                          __typename?: 'ConferenceProviderNode'
                          id: string
                          slug: string
                        }
                      }
                    | {
                        __typename?: 'WebexAccountNode'
                        deleted: any | null
                        id: string
                        provider: {
                          __typename?: 'ConferenceProviderNode'
                          id: string
                          slug: string
                        }
                      }
                    | {
                        __typename?: 'ZoomAccountNode'
                        deleted: any | null
                        id: string
                        provider: {
                          __typename?: 'ConferenceProviderNode'
                          id: string
                          slug: string
                        }
                      }
                    | null
                } | null>
              }
            }
          }
        } | null
      } | null>
    }
  }
}

export type WebConferencingProviderRowQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type WebConferencingProviderRowQuery = {
  __typename?: 'Query'
  conferencingAccount:
    | { __typename?: 'GoToMeetingAccountNode'; isConnected: boolean }
    | { __typename?: 'JoinMeAccountNode'; isConnected: boolean }
    | { __typename?: 'MicrosoftTeamsAccountNode'; isConnected: boolean }
    | { __typename?: 'WebexAccountNode'; isConnected: boolean }
    | { __typename?: 'ZoomAccountNode'; isConnected: boolean }
    | null
}

export type ReconnectConferencingAccountMutationVariables = Exact<{
  input: ReconnectConferencingAccountInput
}>

export type ReconnectConferencingAccountMutation = {
  __typename?: 'Mutations'
  reconnectConferencingAccount: {
    __typename?: 'ReconnectConferencingAccountPayload'
    data:
      | {
          __typename?: 'GoToMeetingAccountNode'
          id: string
          isConnected: boolean
        }
      | { __typename?: 'JoinMeAccountNode'; id: string; isConnected: boolean }
      | {
          __typename?: 'MicrosoftTeamsAccountNode'
          id: string
          isConnected: boolean
        }
      | { __typename?: 'WebexAccountNode'; id: string; isConnected: boolean }
      | { __typename?: 'ZoomAccountNode'; id: string; isConnected: boolean }
      | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type CreateConferencingAccountMutationVariables = Exact<{
  input: CreateConferencingAccountInput
}>

export type CreateConferencingAccountMutation = {
  __typename?: 'Mutations'
  account: {
    __typename?: 'CreateConferencingAccountPayload'
    data:
      | { __typename?: 'GoToMeetingAccountNode'; id: string }
      | { __typename?: 'JoinMeAccountNode'; id: string }
      | { __typename?: 'MicrosoftTeamsAccountNode'; id: string }
      | { __typename?: 'WebexAccountNode'; id: string }
      | { __typename?: 'ZoomAccountNode'; id: string }
      | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type ConnectedMeetingDetailDrawerQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ConnectedMeetingDetailDrawerQuery = {
  __typename?: 'Query'
  meeting: {
    __typename?: 'MeetingNode'
    approved: boolean | null
    cancelled: boolean
    conferenceId: string | null
    conferenceUrl: string | null
    end: any
    id: string
    timezone: Timezone | null
    location: string | null
    locationType: LocationType | null
    name: string
    start: any
    attendees: {
      __typename?: 'AttendeeNodeConnection'
      edges: Array<{
        __typename?: 'AttendeeNodeEdge'
        node: {
          __typename?: 'AttendeeNode'
          approved: boolean | null
          cancelled: boolean
          confirmed: boolean
          email: string
          firstName: string | null
          id: string
          isHost: boolean
          lastName: string | null
          timezone: Timezone
          fieldSubmissions: {
            __typename?: 'AttendeeFieldNodeConnection'
            edges: Array<{
              __typename?: 'AttendeeFieldNodeEdge'
              node: {
                __typename?: 'AttendeeFieldNode'
                id: string
                fieldType: FieldType
                name: string
                value: any
              } | null
            } | null>
          }
          member: {
            __typename?: 'MemberNode'
            id: string
            user: { __typename?: 'UserNode'; id: string; image: string | null }
          } | null
        } | null
      } | null>
    }
    notes: {
      __typename?: 'MeetingNoteNodeConnection'
      edges: Array<{
        __typename?: 'MeetingNoteNodeEdge'
        node: {
          __typename?: 'MeetingNoteNode'
          createdAt: any
          id: string
          deleted: any | null
          body: string
          author: {
            __typename?: 'MemberNode'
            id: string
            user: {
              __typename?: 'UserNode'
              id: string
              firstName: string
              lastName: string
              email: string
              image: string | null
            }
            profile: { __typename?: 'ProfileNode'; id: string }
          }
        } | null
      } | null>
    }
    conferencingAccount:
      | {
          __typename: 'GoToMeetingAccountNode'
          deleted: any | null
          id: string
          name: string
          provider: {
            __typename?: 'ConferenceProviderNode'
            id: string
            name: string
            oauth2AuthorizationUrl: string
            slug: string
          }
        }
      | {
          __typename: 'JoinMeAccountNode'
          deleted: any | null
          id: string
          name: string
          provider: {
            __typename?: 'ConferenceProviderNode'
            id: string
            name: string
            oauth2AuthorizationUrl: string
            slug: string
          }
        }
      | {
          __typename: 'MicrosoftTeamsAccountNode'
          deleted: any | null
          id: string
          name: string
          provider: {
            __typename?: 'ConferenceProviderNode'
            id: string
            name: string
            oauth2AuthorizationUrl: string
            slug: string
          }
        }
      | {
          __typename: 'WebexAccountNode'
          deleted: any | null
          id: string
          name: string
          provider: {
            __typename?: 'ConferenceProviderNode'
            id: string
            name: string
            oauth2AuthorizationUrl: string
            slug: string
          }
        }
      | {
          __typename: 'ZoomAccountNode'
          deleted: any | null
          id: string
          name: string
          provider: {
            __typename?: 'ConferenceProviderNode'
            id: string
            name: string
            oauth2AuthorizationUrl: string
            slug: string
          }
        }
      | null
    meetingType: {
      __typename?: 'MeetingTypeNode'
      id: string
      image: string | null
      name: string
      isGroup: boolean
      color: string
      timezone: Timezone | null
      price: number
      conferencingProvider: {
        __typename?: 'ConferenceProviderNode'
        name: string
        slug: string
        oauth2AuthorizationUrl: string
      } | null
    }
  }
}

export type CreateMeetingNoteMutationVariables = Exact<{
  input: CreateMeetingNoteInput
}>

export type CreateMeetingNoteMutation = {
  __typename?: 'Mutations'
  createMeetingNote: {
    __typename?: 'CreateMeetingNotePayload'
    data: {
      __typename?: 'MeetingNoteNode'
      createdAt: any
      id: string
      deleted: any | null
      body: string
      author: { __typename?: 'MemberNode'; id: string }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type UpdateMeetingNoteMutationVariables = Exact<{
  input: UpdateMeetingNoteInput
}>

export type UpdateMeetingNoteMutation = {
  __typename?: 'Mutations'
  updateMeetingNote: {
    __typename?: 'UpdateMeetingNotePayload'
    data: {
      __typename?: 'MeetingNoteNode'
      createdAt: any
      id: string
      deleted: any | null
      body: string
      author: { __typename?: 'MemberNode'; id: string }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type DeleteMeetingNoteMutationVariables = Exact<{
  input: DeleteMeetingNoteInput
}>

export type DeleteMeetingNoteMutation = {
  __typename?: 'Mutations'
  deleteMeetingNote: {
    __typename?: 'DeleteMeetingNotePayload'
    data: {
      __typename?: 'MeetingNoteNode'
      createdAt: any
      id: string
      deleted: any | null
      body: string
      author: { __typename?: 'MemberNode'; id: string }
    } | null
  } | null
}

export type MeetingDetailDrawerReconnectConferencingAccountMutationVariables =
  Exact<{
    input: ReconnectConferencingAccountInput
  }>

export type MeetingDetailDrawerReconnectConferencingAccountMutation = {
  __typename?: 'Mutations'
  reconnectConferencingAccount: {
    __typename?: 'ReconnectConferencingAccountPayload'
    data:
      | { __typename: 'GoToMeetingAccountNode'; id: string }
      | { __typename: 'JoinMeAccountNode'; id: string }
      | { __typename: 'MicrosoftTeamsAccountNode'; id: string }
      | { __typename: 'WebexAccountNode'; id: string }
      | { __typename: 'ZoomAccountNode'; id: string }
      | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingAvailabilityPageQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type OnboardingAvailabilityPageQuery = {
  __typename?: 'Query'
  member: {
    __typename?: 'MemberNode'
    id: string
    teams: {
      __typename?: 'TeamNodeConnection'
      edges: Array<{
        __typename?: 'TeamNodeEdge'
        node: {
          __typename?: 'TeamNode'
          id: string
          name: string
          slug: string
          bookingUrl: string
        } | null
      } | null>
    }
    schedules: {
      __typename?: 'ScheduleNodeConnection'
      edges: Array<{
        __typename?: 'ScheduleNodeEdge'
        node: {
          __typename?: 'ScheduleNode'
          id: string
          timeBlocks: {
            __typename?: 'TimeBlockNodeConnection'
            edges: Array<{
              __typename?: 'TimeBlockNodeEdge'
              node: {
                __typename?: 'TimeBlockNode'
                weekday: Weekday | null
                start: any
                end: any
              } | null
            } | null>
          }
        } | null
      } | null>
    }
  }
}

export type SetDefaultAvailabilityMutationVariables = Exact<{
  input: CreateScheduleInput
}>

export type SetDefaultAvailabilityMutation = {
  __typename?: 'Mutations'
  createSchedule: {
    __typename?: 'CreateSchedulePayload'
    data: {
      __typename?: 'ScheduleNode'
      id: string
      timeBlocks: {
        __typename?: 'TimeBlockNodeConnection'
        edges: Array<{
          __typename?: 'TimeBlockNodeEdge'
          node: {
            __typename?: 'TimeBlockNode'
            id: string
            start: any
            end: any
            weekday: Weekday | null
          } | null
        } | null>
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type UpdateMemberAvailabilityMutationVariables = Exact<{
  input: UpdateScheduleInput
}>

export type UpdateMemberAvailabilityMutation = {
  __typename?: 'Mutations'
  updateSchedule: {
    __typename?: 'UpdateSchedulePayload'
    data: {
      __typename?: 'ScheduleNode'
      id: string
      timeBlocks: {
        __typename?: 'TimeBlockNodeConnection'
        edges: Array<{
          __typename?: 'TimeBlockNodeEdge'
          node: {
            __typename?: 'TimeBlockNode'
            id: string
            start: any
            end: any
            weekday: Weekday | null
          } | null
        } | null>
      }
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingAvailabilityPageUpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type OnboardingAvailabilityPageUpdateUserMutation = {
  __typename?: 'Mutations'
  user: {
    __typename?: 'UpdateUserPayload'
    data: {
      __typename?: 'UserNode'
      id: string
      needsOnboarding: boolean
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingAvailabilityPageUpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput
}>

export type OnboardingAvailabilityPageUpdateProfileMutation = {
  __typename?: 'Mutations'
  profile: {
    __typename?: 'UpdateProfilePayload'
    data: {
      __typename?: 'ProfileNode'
      id: string
      needsOnboarding: boolean
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingCreateMeetingTypePageDataQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type OnboardingCreateMeetingTypePageDataQuery = {
  __typename?: 'Query'
  member: {
    __typename?: 'MemberNode'
    id: string
    teams: {
      __typename?: 'TeamNodeConnection'
      edges: Array<{
        __typename?: 'TeamNodeEdge'
        node: { __typename?: 'TeamNode'; id: string } | null
      } | null>
    }
  }
}

export type OnboardingCreateMeetingTypePageCreateMutationVariables = Exact<{
  input: CreateMeetingTypeInput
}>

export type OnboardingCreateMeetingTypePageCreateMutation = {
  __typename?: 'Mutations'
  createMeetingType: {
    __typename?: 'CreateMeetingTypePayload'
    data: {
      __typename?: 'MeetingTypeNode'
      id: string
      name: string
      duration: any
      isGroup: boolean
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingCreateMeetingTypePageDeleteAndCreateMutationVariables =
  Exact<{
    delete: DeleteMeetingTypeInput
    input: CreateMeetingTypeInput
  }>

export type OnboardingCreateMeetingTypePageDeleteAndCreateMutation = {
  __typename?: 'Mutations'
  deleteMeetingType: {
    __typename?: 'DeleteMeetingTypePayload'
    data: {
      __typename?: 'MeetingTypeNode'
      id: string
      deleted: any | null
    } | null
  } | null
  createMeetingType: {
    __typename?: 'CreateMeetingTypePayload'
    data: {
      __typename?: 'MeetingTypeNode'
      id: string
      name: string
      duration: any
      isGroup: boolean
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type TeamSlugAvailabilityQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type TeamSlugAvailabilityQuery = {
  __typename?: 'Query'
  data: {
    __typename?: 'UniqueIdentifierAvailability'
    available: boolean
    alternative: string | null
  }
}

export type OnboardingCreateSchedulingPageMemberQueryQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type OnboardingCreateSchedulingPageMemberQueryQuery = {
  __typename?: 'Query'
  member: {
    __typename?: 'MemberNode'
    id: string
    teams: {
      __typename?: 'TeamNodeConnection'
      edges: Array<{
        __typename?: 'TeamNodeEdge'
        node: {
          __typename?: 'TeamNode'
          id: string
          name: string
          slug: string
          bookingUrl: string
        } | null
      } | null>
    }
    profile: {
      __typename?: 'ProfileNode'
      featureFlags: Array<string | null> | null
      id: string
      name: string | null
    }
  }
}

export type OnboardingCreateSchedulingPageSlugUpdateMutationVariables = Exact<{
  input: UpdateTeamInput
}>

export type OnboardingCreateSchedulingPageSlugUpdateMutation = {
  __typename?: 'Mutations'
  updateTeam: {
    __typename?: 'UpdateTeamPayload'
    data: {
      __typename?: 'TeamNode'
      bookingUrl: string
      id: string
      name: string
      slug: string
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type OnboardingCreateSchedulingPageUpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type OnboardingCreateSchedulingPageUpdateUserMutation = {
  __typename?: 'Mutations'
  updateUser: {
    __typename?: 'UpdateUserPayload'
    data: {
      __typename?: 'UserNode'
      firstName: string
      id: string
      lastName: string
      timezone: Timezone | null
    } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type MeetingTypeFragment = {
  __typename?: 'MeetingTypeNode'
  active: boolean
  allowCancellations: boolean
  allowReschedules: boolean
  autoConfirm: boolean
  bookingUrl: string
  bufferAfter: any
  bufferBefore: any
  cancellationEmailBody: string | null
  cancellationEmailSubject: string | null
  cancellationNotice: any
  color: string
  confirmationEmailBody: string | null
  confirmationEmailSubject: string | null
  deleted: any | null
  description: string | null
  duration: any
  frequency: any | null
  hostAssignmentStrategy: HostAssignmentStrategy
  id: string
  image: string | null
  instructions: string | null
  isGroup: boolean
  location: string | null
  locationType: LocationType | null
  maxAttendees: number
  maxBookingsPerDay: number | null
  maximumNotice: any
  minimumNotice: any
  name: string
  price: number
  priceCurrency: Currency | null
  priceFormatted: string
  public: boolean
  redirectUrl: string | null
  includeDataInRedirectUrl: boolean
  rescheduleNotice: any
  sendCancellationEmail: boolean
  sendConfirmationEmail: boolean
  slug: string
  tags: Array<string>
  timezone: Timezone | null
  useCalendarInvitations: boolean
  usesLocalTimezone: boolean
  conferencingProvider: {
    __typename?: 'ConferenceProviderNode'
    id: string
    name: string
    slug: string
  } | null
  fields: {
    __typename?: 'FormFieldNodeConnection'
    edges: Array<{
      __typename?: 'FormFieldNodeEdge'
      node: {
        __typename?: 'FormFieldNode'
        id: string
        choices: Array<string> | null
        fieldType: FieldType
        helpText: string | null
        name: string
        order: number
        required: boolean
        visible: boolean
        slug: string
      } | null
    } | null>
  }
  meetingTypeTeamMembers: {
    __typename?: 'MeetingTypeTeamMemberNodeConnection'
    edges: Array<{
      __typename?: 'MeetingTypeTeamMemberNodeEdge'
      node: {
        __typename?: 'MeetingTypeTeamMemberNode'
        active: boolean
        customAvailability: boolean
        id: string
        order: number
        priority: number
        willHost: boolean
        schedules: {
          __typename?: 'TeamMemberScheduleNodeConnection'
          edges: Array<{
            __typename?: 'TeamMemberScheduleNodeEdge'
            node: {
              __typename?: 'TeamMemberScheduleNode'
              kind: ScheduleKind
              name: string | null
              order: number
              timezone: Timezone
              bounds: {
                __typename?: 'DateRangeNode'
                lower: any | null
                upper: any | null
              } | null
              timeBlocks: {
                __typename?: 'TeamMemberTimeBlockNodeConnection'
                edges: Array<{
                  __typename?: 'TeamMemberTimeBlockNodeEdge'
                  node: {
                    __typename?: 'TeamMemberTimeBlockNode'
                    date: any | null
                    endTime: any | null
                    frequency: any | null
                    startTime: any
                    weekday: Weekday | null
                  } | null
                } | null>
              }
            } | null
          } | null>
        }
        teamMember: {
          __typename?: 'TeamMemberNode'
          id: string
          member: {
            __typename?: 'MemberNode'
            deleted: any | null
            id: string
            invitationAccepted: boolean
            calendarAccounts: {
              __typename?: 'CalendarAccountNodeConnection'
              edges: Array<{
                __typename?: 'CalendarAccountNodeEdge'
                node: {
                  __typename?: 'CalendarAccountNode'
                  id: string
                  provider: {
                    __typename?: 'CalendarProviderNode'
                    id: string
                    slug: string
                  }
                } | null
              } | null>
            }
            conferencingAccounts: {
              __typename?: 'ConferencingAccountNodeConnection'
              edges: Array<{
                __typename?: 'ConferencingAccountNodeEdge'
                node:
                  | {
                      __typename?: 'GoToMeetingAccountNode'
                      id: string
                      deleted: any | null
                      provider: {
                        __typename?: 'ConferenceProviderNode'
                        id: string
                        slug: string
                      }
                    }
                  | {
                      __typename?: 'JoinMeAccountNode'
                      id: string
                      deleted: any | null
                      provider: {
                        __typename?: 'ConferenceProviderNode'
                        id: string
                        slug: string
                      }
                    }
                  | {
                      __typename?: 'MicrosoftTeamsAccountNode'
                      id: string
                      deleted: any | null
                      provider: {
                        __typename?: 'ConferenceProviderNode'
                        id: string
                        slug: string
                      }
                    }
                  | {
                      __typename?: 'WebexAccountNode'
                      id: string
                      deleted: any | null
                      provider: {
                        __typename?: 'ConferenceProviderNode'
                        id: string
                        slug: string
                      }
                    }
                  | {
                      __typename?: 'ZoomAccountNode'
                      id: string
                      deleted: any | null
                      provider: {
                        __typename?: 'ConferenceProviderNode'
                        id: string
                        slug: string
                      }
                    }
                  | null
              } | null>
            }
            user: {
              __typename?: 'UserNode'
              email: string
              firstName: string
              id: string
              image: string | null
              lastName: string
              timezone: Timezone | null
            }
          }
        }
      } | null
    } | null>
  }
  paymentCollectionProvider: {
    __typename?: 'PaymentCollectionProviderNode'
    id: string
    slug: string
  } | null
  reminders: {
    __typename?: 'MeetingTypeReminderNodeConnection'
    edges: Array<{
      __typename?: 'MeetingTypeReminderNodeEdge'
      node: {
        __typename?: 'MeetingTypeReminderNode'
        emailBody: string | null
        emailSubject: string | null
        leadTime: any
      } | null
    } | null>
  }
  schedules: {
    __typename?: 'MeetingTypeScheduleNodeConnection'
    edges: Array<{
      __typename?: 'MeetingTypeScheduleNodeEdge'
      node: {
        __typename?: 'MeetingTypeScheduleNode'
        kind: ScheduleKind
        name: string | null
        order: number
        timezone: Timezone
        bounds: {
          __typename?: 'DateRangeNode'
          lower: any | null
          upper: any | null
        } | null
        timeBlocks: {
          __typename?: 'MeetingTypeTimeBlockNodeConnection'
          edges: Array<{
            __typename?: 'MeetingTypeTimeBlockNodeEdge'
            node: {
              __typename?: 'MeetingTypeTimeBlockNode'
              date: any | null
              endTime: any | null
              frequency: any | null
              startTime: any
              weekday: Weekday | null
            } | null
          } | null>
        }
      } | null
    } | null>
  }
  team: {
    __typename?: 'TeamNode'
    bookingUrl: string
    description: string | null
    id: string
    image: string | null
    name: string
    slug: string
    teamMembers: {
      __typename?: 'TeamMemberNodeConnection'
      edges: Array<{
        __typename?: 'TeamMemberNodeEdge'
        node: { __typename?: 'TeamMemberNode'; id: string } | null
      } | null>
    }
  }
}

export type MeetingsFragment = {
  __typename?: 'MeetingNodeConnection'
  edges: Array<{
    __typename?: 'MeetingNodeEdge'
    node: {
      __typename?: 'MeetingNode'
      cancelled: boolean
      conferenceId: string | null
      conferenceUrl: string | null
      confirmed: boolean
      end: any
      id: string
      isGroup: boolean
      location: string | null
      locationType: LocationType | null
      name: string
      start: any
      attendees: {
        __typename?: 'AttendeeNodeConnection'
        edges: Array<{
          __typename?: 'AttendeeNodeEdge'
          node: {
            __typename?: 'AttendeeNode'
            firstName: string | null
            email: string
            id: string
            lastName: string | null
            status: AttendeeStatus
            approved: boolean | null
            timezone: Timezone
            member: {
              __typename?: 'MemberNode'
              id: string
              user: {
                __typename?: 'UserNode'
                firstName: string
                email: string
                id: string
                image: string | null
                timezone: Timezone | null
              }
            } | null
          } | null
        } | null>
      }
      conferencingAccount:
        | {
            __typename?: 'GoToMeetingAccountNode'
            id: string
            name: string
            provider: {
              __typename?: 'ConferenceProviderNode'
              id: string
              name: string
              slug: string
            }
          }
        | {
            __typename?: 'JoinMeAccountNode'
            id: string
            name: string
            provider: {
              __typename?: 'ConferenceProviderNode'
              id: string
              name: string
              slug: string
            }
          }
        | {
            __typename?: 'MicrosoftTeamsAccountNode'
            id: string
            name: string
            provider: {
              __typename?: 'ConferenceProviderNode'
              id: string
              name: string
              slug: string
            }
          }
        | {
            __typename?: 'WebexAccountNode'
            id: string
            name: string
            provider: {
              __typename?: 'ConferenceProviderNode'
              id: string
              name: string
              slug: string
            }
          }
        | {
            __typename?: 'ZoomAccountNode'
            id: string
            name: string
            provider: {
              __typename?: 'ConferenceProviderNode'
              id: string
              name: string
              slug: string
            }
          }
        | null
      meetingType: {
        __typename?: 'MeetingTypeNode'
        id: string
        name: string
        color: string
        conferencingProvider: {
          __typename?: 'ConferenceProviderNode'
          id: string
          name: string
          slug: string
        } | null
      }
    } | null
  } | null>
}

export type CreateReportMutationVariables = Exact<{
  input: CreateMeetingReportInput
}>

export type CreateReportMutation = {
  __typename?: 'Mutations'
  report: {
    __typename?: 'CreateMeetingReportPayload'
    data: { __typename?: 'ReportNode'; id: string } | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export type GetReportQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetReportQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportNode'
    id: string
    url: string | null
    status: ReportStatus
  } | null
}

export type CreateStripeCheckoutSessionMutationVariables = Exact<{
  input: StartStripePaymentMethodUpdateSessionInput
}>

export type CreateStripeCheckoutSessionMutation = {
  __typename?: 'Mutations'
  stripe: {
    __typename?: 'StartStripePaymentMethodUpdateSessionPayload'
    sessionId: string | null
    errors: Array<{
      __typename?: 'ErrorType'
      field: string
      messages: Array<string>
    } | null> | null
  } | null
}

export const PlanDataFragmentDoc = gql`
  fragment PlanData on PlanNode {
    id
    name
    freeDefault
    trialDefault
    seatPriceMonthly
    seatPriceYearly
    requireAppointletBranding
    allowEmailCustomization
    allowFormFields
    allowManualConfirm
    allowPrivateMeetingTypes
    allowRedirect
    allowReminders
    allowWebConferencing
    allowWebhooks
    allowZapier
  }
`
export const MeetingTypeFragmentDoc = gql`
  fragment MeetingType on MeetingTypeNode {
    active
    allowCancellations
    allowReschedules
    autoConfirm
    bookingUrl
    bufferAfter
    bufferBefore
    cancellationEmailBody
    cancellationEmailSubject
    cancellationNotice
    color
    conferencingProvider {
      id
      name
      slug
    }
    confirmationEmailBody
    confirmationEmailSubject
    deleted
    description
    duration
    fields {
      edges {
        node {
          id
          choices
          fieldType
          helpText
          name
          order
          required
          visible
          slug
        }
      }
    }
    frequency
    hostAssignmentStrategy
    id
    image
    instructions
    isGroup
    location
    locationType
    maxAttendees
    maxBookingsPerDay
    maximumNotice
    meetingTypeTeamMembers {
      edges {
        node {
          active
          customAvailability
          id
          order
          priority
          schedules {
            edges {
              node {
                bounds {
                  lower
                  upper
                }
                kind
                name
                order
                timezone
                timeBlocks {
                  edges {
                    node {
                      date
                      endTime
                      frequency
                      startTime
                      weekday
                    }
                  }
                }
              }
            }
          }
          teamMember {
            id
            member {
              calendarAccounts {
                edges {
                  node {
                    id
                    provider {
                      id
                      slug
                    }
                  }
                }
              }
              conferencingAccounts {
                edges {
                  node {
                    id
                    deleted
                    provider {
                      id
                      slug
                    }
                  }
                }
              }
              deleted
              id
              invitationAccepted
              user {
                email
                firstName
                id
                image
                lastName
                timezone
              }
            }
          }
          willHost
        }
      }
    }
    minimumNotice
    name
    paymentCollectionProvider {
      id
      slug
    }
    price
    priceCurrency
    priceFormatted
    public
    redirectUrl
    includeDataInRedirectUrl
    reminders {
      edges {
        node {
          emailBody
          emailSubject
          leadTime
        }
      }
    }
    rescheduleNotice
    schedules {
      edges {
        node {
          bounds {
            lower
            upper
          }
          kind
          name
          order
          timezone
          timeBlocks {
            edges {
              node {
                date
                endTime
                frequency
                startTime
                weekday
              }
            }
          }
        }
      }
    }
    sendCancellationEmail
    sendConfirmationEmail
    slug
    tags
    team {
      bookingUrl
      description
      id
      image
      name
      slug
      teamMembers {
        edges {
          node {
            id
          }
        }
      }
    }
    timezone
    useCalendarInvitations
    usesLocalTimezone
  }
`
export const MeetingsFragmentDoc = gql`
  fragment Meetings on MeetingNodeConnection {
    edges {
      node {
        attendees {
          edges {
            node {
              firstName
              email
              id
              lastName
              status
              approved
              member {
                id
                user {
                  firstName
                  email
                  id
                  image
                  timezone
                }
              }
              timezone
            }
          }
        }
        cancelled
        conferenceId
        conferenceUrl
        conferencingAccount {
          id
          name
          provider {
            id
            name
            slug
          }
        }
        confirmed
        end
        id
        isGroup
        location
        locationType
        meetingType {
          id
          name
          color
          conferencingProvider {
            id
            name
            slug
          }
        }
        name
        start
      }
    }
  }
`
export const AppDocument = gql`
  query App {
    user {
      createdAt
      email
      firstName
      id
      image
      language
      lastName
      needsOnboarding
      timezone
      members {
        edges {
          node {
            id
            invitationAccepted
            isOwner
            role {
              id
              canManageBilling
              canManageMeetings
              canManageMeetingTypes
              canManageMembers
              canManageOrgSettings
            }
            meetingCount
            profile {
              allocatedSeats
              billedAnnually
              needsOnboarding
              billingCustomerId
              billingSubscriptionId
              brandColor
              featureFlags
              id
              image
              members {
                edges {
                  node {
                    id
                    isOwner
                    user {
                      email
                      id
                      image
                      firstName
                      lastName
                    }
                  }
                }
              }
              name
              occupiedSeats
              personal
              plan {
                allowEmailCustomization
                allowFormFields
                allowManualConfirm
                allowPaymentCollection
                allowPrivateMeetingTypes
                allowRedirect
                allowReminders
                allowRoundRobin
                allowWebConferencing
                allowWebhooks
                allowUtmParameters
                allowZapier
                freeDefault
                id
                name
                requireAppointletBranding
                seatPriceMonthly
                seatPriceYearly
                trialDefault
              }
            }
          }
        }
      }
    }
  }
`
export type AppComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AppQuery, AppQueryVariables>,
  'query'
>

export const AppComponent = (props: AppComponentProps) => (
  <ApolloReactComponents.Query<AppQuery, AppQueryVariables>
    query={AppDocument}
    {...props}
  />
)

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppQuery(
  baseOptions?: Apollo.QueryHookOptions<AppQuery, AppQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AppQuery, AppQueryVariables>(AppDocument, options)
}
export function useAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppQuery, AppQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AppQuery, AppQueryVariables>(AppDocument, options)
}
export type AppQueryHookResult = ReturnType<typeof useAppQuery>
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>
export type AppQueryResult = Apollo.QueryResult<AppQuery, AppQueryVariables>
export const AppSetTimeZoneDocument = gql`
  mutation AppSetTimeZone($input: UpdateUserInput!) {
    user: updateUser(input: $input) {
      data {
        id
        timezone
      }
      errors {
        field
        messages
      }
    }
  }
`
export type AppSetTimeZoneMutationFn = Apollo.MutationFunction<
  AppSetTimeZoneMutation,
  AppSetTimeZoneMutationVariables
>
export type AppSetTimeZoneComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AppSetTimeZoneMutation,
    AppSetTimeZoneMutationVariables
  >,
  'mutation'
>

export const AppSetTimeZoneComponent = (
  props: AppSetTimeZoneComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AppSetTimeZoneMutation,
    AppSetTimeZoneMutationVariables
  >
    mutation={AppSetTimeZoneDocument}
    {...props}
  />
)

/**
 * __useAppSetTimeZoneMutation__
 *
 * To run a mutation, you first call `useAppSetTimeZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppSetTimeZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appSetTimeZoneMutation, { data, loading, error }] = useAppSetTimeZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppSetTimeZoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AppSetTimeZoneMutation,
    AppSetTimeZoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AppSetTimeZoneMutation,
    AppSetTimeZoneMutationVariables
  >(AppSetTimeZoneDocument, options)
}
export type AppSetTimeZoneMutationHookResult = ReturnType<
  typeof useAppSetTimeZoneMutation
>
export type AppSetTimeZoneMutationResult =
  Apollo.MutationResult<AppSetTimeZoneMutation>
export type AppSetTimeZoneMutationOptions = Apollo.BaseMutationOptions<
  AppSetTimeZoneMutation,
  AppSetTimeZoneMutationVariables
>
export const ApproveAttendeesDocument = gql`
  mutation ApproveAttendees($input: ApproveAttendeesInput!) {
    approveAttendees(input: $input) {
      data {
        id
        status
        approved
      }
      errors {
        field
        messages
      }
    }
  }
`
export type ApproveAttendeesMutationFn = Apollo.MutationFunction<
  ApproveAttendeesMutation,
  ApproveAttendeesMutationVariables
>
export type ApproveAttendeesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ApproveAttendeesMutation,
    ApproveAttendeesMutationVariables
  >,
  'mutation'
>

export const ApproveAttendeesComponent = (
  props: ApproveAttendeesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ApproveAttendeesMutation,
    ApproveAttendeesMutationVariables
  >
    mutation={ApproveAttendeesDocument}
    {...props}
  />
)

/**
 * __useApproveAttendeesMutation__
 *
 * To run a mutation, you first call `useApproveAttendeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAttendeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAttendeesMutation, { data, loading, error }] = useApproveAttendeesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveAttendeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveAttendeesMutation,
    ApproveAttendeesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ApproveAttendeesMutation,
    ApproveAttendeesMutationVariables
  >(ApproveAttendeesDocument, options)
}
export type ApproveAttendeesMutationHookResult = ReturnType<
  typeof useApproveAttendeesMutation
>
export type ApproveAttendeesMutationResult =
  Apollo.MutationResult<ApproveAttendeesMutation>
export type ApproveAttendeesMutationOptions = Apollo.BaseMutationOptions<
  ApproveAttendeesMutation,
  ApproveAttendeesMutationVariables
>
export const ApproveMeetingDocument = gql`
  mutation ApproveMeeting($input: ApproveMeetingInput!) {
    approveMeeting(input: $input) {
      data {
        id
        approved
        confirmed
      }
      errors {
        field
        messages
      }
    }
  }
`
export type ApproveMeetingMutationFn = Apollo.MutationFunction<
  ApproveMeetingMutation,
  ApproveMeetingMutationVariables
>
export type ApproveMeetingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ApproveMeetingMutation,
    ApproveMeetingMutationVariables
  >,
  'mutation'
>

export const ApproveMeetingComponent = (
  props: ApproveMeetingComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ApproveMeetingMutation,
    ApproveMeetingMutationVariables
  >
    mutation={ApproveMeetingDocument}
    {...props}
  />
)

/**
 * __useApproveMeetingMutation__
 *
 * To run a mutation, you first call `useApproveMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveMeetingMutation, { data, loading, error }] = useApproveMeetingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveMeetingMutation,
    ApproveMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ApproveMeetingMutation,
    ApproveMeetingMutationVariables
  >(ApproveMeetingDocument, options)
}
export type ApproveMeetingMutationHookResult = ReturnType<
  typeof useApproveMeetingMutation
>
export type ApproveMeetingMutationResult =
  Apollo.MutationResult<ApproveMeetingMutation>
export type ApproveMeetingMutationOptions = Apollo.BaseMutationOptions<
  ApproveMeetingMutation,
  ApproveMeetingMutationVariables
>
export const ResumeSubscriptionDocument = gql`
  mutation ResumeSubscription($input: ResumeSubscriptionInput!) {
    resumeSubscription(input: $input) {
      ok
    }
  }
`
export type ResumeSubscriptionMutationFn = Apollo.MutationFunction<
  ResumeSubscriptionMutation,
  ResumeSubscriptionMutationVariables
>
export type ResumeSubscriptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ResumeSubscriptionMutation,
    ResumeSubscriptionMutationVariables
  >,
  'mutation'
>

export const ResumeSubscriptionComponent = (
  props: ResumeSubscriptionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ResumeSubscriptionMutation,
    ResumeSubscriptionMutationVariables
  >
    mutation={ResumeSubscriptionDocument}
    {...props}
  />
)

/**
 * __useResumeSubscriptionMutation__
 *
 * To run a mutation, you first call `useResumeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeSubscriptionMutation, { data, loading, error }] = useResumeSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResumeSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResumeSubscriptionMutation,
    ResumeSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResumeSubscriptionMutation,
    ResumeSubscriptionMutationVariables
  >(ResumeSubscriptionDocument, options)
}
export type ResumeSubscriptionMutationHookResult = ReturnType<
  typeof useResumeSubscriptionMutation
>
export type ResumeSubscriptionMutationResult =
  Apollo.MutationResult<ResumeSubscriptionMutation>
export type ResumeSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  ResumeSubscriptionMutation,
  ResumeSubscriptionMutationVariables
>
export const GetUpcomingInvoiceDocument = gql`
  query GetUpcomingInvoice($profile: ID!, $billedAnnually: Boolean) {
    invoice: previewInvoice(
      profile: $profile
      billedAnnually: $billedAnnually
    ) {
      amountDue
      discountAmount
      discountName
      discountPercentOff
      endingBalance
      prorationAmount
      startingBalance
      subscriptionAmount
      subscriptionQuantity
      total
    }
  }
`
export type GetUpcomingInvoiceComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetUpcomingInvoiceQuery,
    GetUpcomingInvoiceQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetUpcomingInvoiceQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const GetUpcomingInvoiceComponent = (
  props: GetUpcomingInvoiceComponentProps
) => (
  <ApolloReactComponents.Query<
    GetUpcomingInvoiceQuery,
    GetUpcomingInvoiceQueryVariables
  >
    query={GetUpcomingInvoiceDocument}
    {...props}
  />
)

/**
 * __useGetUpcomingInvoiceQuery__
 *
 * To run a query within a React component, call `useGetUpcomingInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingInvoiceQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *      billedAnnually: // value for 'billedAnnually'
 *   },
 * });
 */
export function useGetUpcomingInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUpcomingInvoiceQuery,
    GetUpcomingInvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUpcomingInvoiceQuery,
    GetUpcomingInvoiceQueryVariables
  >(GetUpcomingInvoiceDocument, options)
}
export function useGetUpcomingInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpcomingInvoiceQuery,
    GetUpcomingInvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUpcomingInvoiceQuery,
    GetUpcomingInvoiceQueryVariables
  >(GetUpcomingInvoiceDocument, options)
}
export type GetUpcomingInvoiceQueryHookResult = ReturnType<
  typeof useGetUpcomingInvoiceQuery
>
export type GetUpcomingInvoiceLazyQueryHookResult = ReturnType<
  typeof useGetUpcomingInvoiceLazyQuery
>
export type GetUpcomingInvoiceQueryResult = Apollo.QueryResult<
  GetUpcomingInvoiceQuery,
  GetUpcomingInvoiceQueryVariables
>
export const CancelAttendeesDocument = gql`
  mutation CancelAttendees($input: CancelAttendeesInput!) {
    cancelAttendees(input: $input) {
      data {
        id
        status
        cancelled
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CancelAttendeesMutationFn = Apollo.MutationFunction<
  CancelAttendeesMutation,
  CancelAttendeesMutationVariables
>
export type CancelAttendeesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CancelAttendeesMutation,
    CancelAttendeesMutationVariables
  >,
  'mutation'
>

export const CancelAttendeesComponent = (
  props: CancelAttendeesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CancelAttendeesMutation,
    CancelAttendeesMutationVariables
  >
    mutation={CancelAttendeesDocument}
    {...props}
  />
)

/**
 * __useCancelAttendeesMutation__
 *
 * To run a mutation, you first call `useCancelAttendeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAttendeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAttendeesMutation, { data, loading, error }] = useCancelAttendeesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAttendeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAttendeesMutation,
    CancelAttendeesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelAttendeesMutation,
    CancelAttendeesMutationVariables
  >(CancelAttendeesDocument, options)
}
export type CancelAttendeesMutationHookResult = ReturnType<
  typeof useCancelAttendeesMutation
>
export type CancelAttendeesMutationResult =
  Apollo.MutationResult<CancelAttendeesMutation>
export type CancelAttendeesMutationOptions = Apollo.BaseMutationOptions<
  CancelAttendeesMutation,
  CancelAttendeesMutationVariables
>
export const CancelMeetingDocument = gql`
  mutation CancelMeeting($input: CancelMeetingInput!) {
    cancelMeeting(input: $input) {
      data {
        cancelled
        id
        name
        start
        attendees {
          edges {
            node {
              id
              status
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CancelMeetingMutationFn = Apollo.MutationFunction<
  CancelMeetingMutation,
  CancelMeetingMutationVariables
>
export type CancelMeetingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CancelMeetingMutation,
    CancelMeetingMutationVariables
  >,
  'mutation'
>

export const CancelMeetingComponent = (props: CancelMeetingComponentProps) => (
  <ApolloReactComponents.Mutation<
    CancelMeetingMutation,
    CancelMeetingMutationVariables
  >
    mutation={CancelMeetingDocument}
    {...props}
  />
)

/**
 * __useCancelMeetingMutation__
 *
 * To run a mutation, you first call `useCancelMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMeetingMutation, { data, loading, error }] = useCancelMeetingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelMeetingMutation,
    CancelMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelMeetingMutation,
    CancelMeetingMutationVariables
  >(CancelMeetingDocument, options)
}
export type CancelMeetingMutationHookResult = ReturnType<
  typeof useCancelMeetingMutation
>
export type CancelMeetingMutationResult =
  Apollo.MutationResult<CancelMeetingMutation>
export type CancelMeetingMutationOptions = Apollo.BaseMutationOptions<
  CancelMeetingMutation,
  CancelMeetingMutationVariables
>
export const CancelSubscriptionDocument = gql`
  mutation CancelSubscription($input: CancelSubscriptionInput!) {
    cancelSubscription(input: $input) {
      ok
    }
  }
`
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>
export type CancelSubscriptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >,
  'mutation'
>

export const CancelSubscriptionComponent = (
  props: CancelSubscriptionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >
    mutation={CancelSubscriptionDocument}
    {...props}
  />
)

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, options)
}
export type CancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMutation
>
export type CancelSubscriptionMutationResult =
  Apollo.MutationResult<CancelSubscriptionMutation>
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>
export const ChangePasswordModalDocument = gql`
  mutation ChangePasswordModal($input: UpdateUserPasswordInput!) {
    user: updateUserPassword(input: $input) {
      data {
        id
        passwordUpdatedAt
      }
      errors {
        field
        messages
      }
    }
  }
`
export type ChangePasswordModalMutationFn = Apollo.MutationFunction<
  ChangePasswordModalMutation,
  ChangePasswordModalMutationVariables
>
export type ChangePasswordModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ChangePasswordModalMutation,
    ChangePasswordModalMutationVariables
  >,
  'mutation'
>

export const ChangePasswordModalComponent = (
  props: ChangePasswordModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ChangePasswordModalMutation,
    ChangePasswordModalMutationVariables
  >
    mutation={ChangePasswordModalDocument}
    {...props}
  />
)

/**
 * __useChangePasswordModalMutation__
 *
 * To run a mutation, you first call `useChangePasswordModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordModalMutation, { data, loading, error }] = useChangePasswordModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordModalMutation,
    ChangePasswordModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ChangePasswordModalMutation,
    ChangePasswordModalMutationVariables
  >(ChangePasswordModalDocument, options)
}
export type ChangePasswordModalMutationHookResult = ReturnType<
  typeof useChangePasswordModalMutation
>
export type ChangePasswordModalMutationResult =
  Apollo.MutationResult<ChangePasswordModalMutation>
export type ChangePasswordModalMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordModalMutation,
  ChangePasswordModalMutationVariables
>
export const CreateMeetingTypeModalDocument = gql`
  mutation CreateMeetingTypeModal($input: CreateMeetingTypeInput!) {
    createMeetingType(input: $input) {
      data {
        id
        order
        name
        color
        duration
        bookingUrl
        slug
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CreateMeetingTypeModalMutationFn = Apollo.MutationFunction<
  CreateMeetingTypeModalMutation,
  CreateMeetingTypeModalMutationVariables
>
export type CreateMeetingTypeModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMeetingTypeModalMutation,
    CreateMeetingTypeModalMutationVariables
  >,
  'mutation'
>

export const CreateMeetingTypeModalComponent = (
  props: CreateMeetingTypeModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateMeetingTypeModalMutation,
    CreateMeetingTypeModalMutationVariables
  >
    mutation={CreateMeetingTypeModalDocument}
    {...props}
  />
)

/**
 * __useCreateMeetingTypeModalMutation__
 *
 * To run a mutation, you first call `useCreateMeetingTypeModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeetingTypeModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeetingTypeModalMutation, { data, loading, error }] = useCreateMeetingTypeModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMeetingTypeModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMeetingTypeModalMutation,
    CreateMeetingTypeModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateMeetingTypeModalMutation,
    CreateMeetingTypeModalMutationVariables
  >(CreateMeetingTypeModalDocument, options)
}
export type CreateMeetingTypeModalMutationHookResult = ReturnType<
  typeof useCreateMeetingTypeModalMutation
>
export type CreateMeetingTypeModalMutationResult =
  Apollo.MutationResult<CreateMeetingTypeModalMutation>
export type CreateMeetingTypeModalMutationOptions = Apollo.BaseMutationOptions<
  CreateMeetingTypeModalMutation,
  CreateMeetingTypeModalMutationVariables
>
export const CreateProfileModalDocument = gql`
  mutation CreateProfileModal($input: CreateProfileInput!) {
    createProfile(input: $input) {
      data {
        allocatedSeats
        billedAnnually
        billingCustomerId
        billingSubscriptionId
        brandColor
        id
        image
        members {
          edges {
            node {
              id
              user {
                email
                id
                image
                firstName
                lastName
              }
            }
          }
        }
        name
        occupiedSeats
        personal
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CreateProfileModalMutationFn = Apollo.MutationFunction<
  CreateProfileModalMutation,
  CreateProfileModalMutationVariables
>
export type CreateProfileModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateProfileModalMutation,
    CreateProfileModalMutationVariables
  >,
  'mutation'
>

export const CreateProfileModalComponent = (
  props: CreateProfileModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateProfileModalMutation,
    CreateProfileModalMutationVariables
  >
    mutation={CreateProfileModalDocument}
    {...props}
  />
)

/**
 * __useCreateProfileModalMutation__
 *
 * To run a mutation, you first call `useCreateProfileModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileModalMutation, { data, loading, error }] = useCreateProfileModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProfileModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProfileModalMutation,
    CreateProfileModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateProfileModalMutation,
    CreateProfileModalMutationVariables
  >(CreateProfileModalDocument, options)
}
export type CreateProfileModalMutationHookResult = ReturnType<
  typeof useCreateProfileModalMutation
>
export type CreateProfileModalMutationResult =
  Apollo.MutationResult<CreateProfileModalMutation>
export type CreateProfileModalMutationOptions = Apollo.BaseMutationOptions<
  CreateProfileModalMutation,
  CreateProfileModalMutationVariables
>
export const CreateSchedulingPageModalDocument = gql`
  mutation CreateSchedulingPageModal($input: CreateTeamInput!) {
    schedulingPage: createTeam(input: $input) {
      data {
        id
        name
        private
        slug
        teamMembers {
          edges {
            node {
              id
              member {
                id
                user {
                  email
                  firstName
                  id
                  image
                  lastName
                }
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CreateSchedulingPageModalMutationFn = Apollo.MutationFunction<
  CreateSchedulingPageModalMutation,
  CreateSchedulingPageModalMutationVariables
>
export type CreateSchedulingPageModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateSchedulingPageModalMutation,
    CreateSchedulingPageModalMutationVariables
  >,
  'mutation'
>

export const CreateSchedulingPageModalComponent = (
  props: CreateSchedulingPageModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateSchedulingPageModalMutation,
    CreateSchedulingPageModalMutationVariables
  >
    mutation={CreateSchedulingPageModalDocument}
    {...props}
  />
)

/**
 * __useCreateSchedulingPageModalMutation__
 *
 * To run a mutation, you first call `useCreateSchedulingPageModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchedulingPageModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchedulingPageModalMutation, { data, loading, error }] = useCreateSchedulingPageModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSchedulingPageModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSchedulingPageModalMutation,
    CreateSchedulingPageModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSchedulingPageModalMutation,
    CreateSchedulingPageModalMutationVariables
  >(CreateSchedulingPageModalDocument, options)
}
export type CreateSchedulingPageModalMutationHookResult = ReturnType<
  typeof useCreateSchedulingPageModalMutation
>
export type CreateSchedulingPageModalMutationResult =
  Apollo.MutationResult<CreateSchedulingPageModalMutation>
export type CreateSchedulingPageModalMutationOptions =
  Apollo.BaseMutationOptions<
    CreateSchedulingPageModalMutation,
    CreateSchedulingPageModalMutationVariables
  >
export const CreateWebhookModalDocument = gql`
  mutation CreateWebhookModal($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      data {
        id
        url
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CreateWebhookModalMutationFn = Apollo.MutationFunction<
  CreateWebhookModalMutation,
  CreateWebhookModalMutationVariables
>
export type CreateWebhookModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateWebhookModalMutation,
    CreateWebhookModalMutationVariables
  >,
  'mutation'
>

export const CreateWebhookModalComponent = (
  props: CreateWebhookModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateWebhookModalMutation,
    CreateWebhookModalMutationVariables
  >
    mutation={CreateWebhookModalDocument}
    {...props}
  />
)

/**
 * __useCreateWebhookModalMutation__
 *
 * To run a mutation, you first call `useCreateWebhookModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookModalMutation, { data, loading, error }] = useCreateWebhookModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebhookModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWebhookModalMutation,
    CreateWebhookModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateWebhookModalMutation,
    CreateWebhookModalMutationVariables
  >(CreateWebhookModalDocument, options)
}
export type CreateWebhookModalMutationHookResult = ReturnType<
  typeof useCreateWebhookModalMutation
>
export type CreateWebhookModalMutationResult =
  Apollo.MutationResult<CreateWebhookModalMutation>
export type CreateWebhookModalMutationOptions = Apollo.BaseMutationOptions<
  CreateWebhookModalMutation,
  CreateWebhookModalMutationVariables
>
export const CurrencyPickerDocument = gql`
  query CurrencyPicker {
    currencies: __type(name: "Currency") {
      enumValues {
        name
        description
      }
    }
  }
`
export type CurrencyPickerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CurrencyPickerQuery,
    CurrencyPickerQueryVariables
  >,
  'query'
>

export const CurrencyPickerComponent = (
  props: CurrencyPickerComponentProps
) => (
  <ApolloReactComponents.Query<
    CurrencyPickerQuery,
    CurrencyPickerQueryVariables
  >
    query={CurrencyPickerDocument}
    {...props}
  />
)

/**
 * __useCurrencyPickerQuery__
 *
 * To run a query within a React component, call `useCurrencyPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrencyPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrencyPickerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrencyPickerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrencyPickerQuery,
    CurrencyPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrencyPickerQuery, CurrencyPickerQueryVariables>(
    CurrencyPickerDocument,
    options
  )
}
export function useCurrencyPickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrencyPickerQuery,
    CurrencyPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrencyPickerQuery, CurrencyPickerQueryVariables>(
    CurrencyPickerDocument,
    options
  )
}
export type CurrencyPickerQueryHookResult = ReturnType<
  typeof useCurrencyPickerQuery
>
export type CurrencyPickerLazyQueryHookResult = ReturnType<
  typeof useCurrencyPickerLazyQuery
>
export type CurrencyPickerQueryResult = Apollo.QueryResult<
  CurrencyPickerQuery,
  CurrencyPickerQueryVariables
>
export const CustomizeSchedulingPageModalDocument = gql`
  query CustomizeSchedulingPageModal($id: ID!) {
    team: getTeamById(id: $id) {
      description
      id
      image
      language
      name
      slug
      teamMembers {
        edges {
          node {
            id
            member {
              id
            }
          }
        }
      }
    }
  }
`
export type CustomizeSchedulingPageModalComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CustomizeSchedulingPageModalQuery,
    CustomizeSchedulingPageModalQueryVariables
  >,
  'query'
> &
  (
    | { variables: CustomizeSchedulingPageModalQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const CustomizeSchedulingPageModalComponent = (
  props: CustomizeSchedulingPageModalComponentProps
) => (
  <ApolloReactComponents.Query<
    CustomizeSchedulingPageModalQuery,
    CustomizeSchedulingPageModalQueryVariables
  >
    query={CustomizeSchedulingPageModalDocument}
    {...props}
  />
)

/**
 * __useCustomizeSchedulingPageModalQuery__
 *
 * To run a query within a React component, call `useCustomizeSchedulingPageModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomizeSchedulingPageModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomizeSchedulingPageModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomizeSchedulingPageModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomizeSchedulingPageModalQuery,
    CustomizeSchedulingPageModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CustomizeSchedulingPageModalQuery,
    CustomizeSchedulingPageModalQueryVariables
  >(CustomizeSchedulingPageModalDocument, options)
}
export function useCustomizeSchedulingPageModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomizeSchedulingPageModalQuery,
    CustomizeSchedulingPageModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CustomizeSchedulingPageModalQuery,
    CustomizeSchedulingPageModalQueryVariables
  >(CustomizeSchedulingPageModalDocument, options)
}
export type CustomizeSchedulingPageModalQueryHookResult = ReturnType<
  typeof useCustomizeSchedulingPageModalQuery
>
export type CustomizeSchedulingPageModalLazyQueryHookResult = ReturnType<
  typeof useCustomizeSchedulingPageModalLazyQuery
>
export type CustomizeSchedulingPageModalQueryResult = Apollo.QueryResult<
  CustomizeSchedulingPageModalQuery,
  CustomizeSchedulingPageModalQueryVariables
>
export const CustomizeSchedulingPageModalUpdateDocument = gql`
  mutation CustomizeSchedulingPageModalUpdate($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      data {
        bookingUrl
        description
        id
        image
        language
        meetingTypes {
          edges {
            node {
              bookingUrl
              id
            }
          }
        }
        name
        slug
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CustomizeSchedulingPageModalUpdateMutationFn =
  Apollo.MutationFunction<
    CustomizeSchedulingPageModalUpdateMutation,
    CustomizeSchedulingPageModalUpdateMutationVariables
  >
export type CustomizeSchedulingPageModalUpdateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CustomizeSchedulingPageModalUpdateMutation,
    CustomizeSchedulingPageModalUpdateMutationVariables
  >,
  'mutation'
>

export const CustomizeSchedulingPageModalUpdateComponent = (
  props: CustomizeSchedulingPageModalUpdateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CustomizeSchedulingPageModalUpdateMutation,
    CustomizeSchedulingPageModalUpdateMutationVariables
  >
    mutation={CustomizeSchedulingPageModalUpdateDocument}
    {...props}
  />
)

/**
 * __useCustomizeSchedulingPageModalUpdateMutation__
 *
 * To run a mutation, you first call `useCustomizeSchedulingPageModalUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomizeSchedulingPageModalUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customizeSchedulingPageModalUpdateMutation, { data, loading, error }] = useCustomizeSchedulingPageModalUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomizeSchedulingPageModalUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomizeSchedulingPageModalUpdateMutation,
    CustomizeSchedulingPageModalUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CustomizeSchedulingPageModalUpdateMutation,
    CustomizeSchedulingPageModalUpdateMutationVariables
  >(CustomizeSchedulingPageModalUpdateDocument, options)
}
export type CustomizeSchedulingPageModalUpdateMutationHookResult = ReturnType<
  typeof useCustomizeSchedulingPageModalUpdateMutation
>
export type CustomizeSchedulingPageModalUpdateMutationResult =
  Apollo.MutationResult<CustomizeSchedulingPageModalUpdateMutation>
export type CustomizeSchedulingPageModalUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    CustomizeSchedulingPageModalUpdateMutation,
    CustomizeSchedulingPageModalUpdateMutationVariables
  >
export const DeclineAttendeesDocument = gql`
  mutation DeclineAttendees($input: DeclineAttendeesInput!) {
    declineAttendees(input: $input) {
      data {
        id
        approved
        cancelled
        status
      }
      errors {
        field
        messages
      }
    }
  }
`
export type DeclineAttendeesMutationFn = Apollo.MutationFunction<
  DeclineAttendeesMutation,
  DeclineAttendeesMutationVariables
>
export type DeclineAttendeesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeclineAttendeesMutation,
    DeclineAttendeesMutationVariables
  >,
  'mutation'
>

export const DeclineAttendeesComponent = (
  props: DeclineAttendeesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeclineAttendeesMutation,
    DeclineAttendeesMutationVariables
  >
    mutation={DeclineAttendeesDocument}
    {...props}
  />
)

/**
 * __useDeclineAttendeesMutation__
 *
 * To run a mutation, you first call `useDeclineAttendeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineAttendeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineAttendeesMutation, { data, loading, error }] = useDeclineAttendeesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineAttendeesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineAttendeesMutation,
    DeclineAttendeesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeclineAttendeesMutation,
    DeclineAttendeesMutationVariables
  >(DeclineAttendeesDocument, options)
}
export type DeclineAttendeesMutationHookResult = ReturnType<
  typeof useDeclineAttendeesMutation
>
export type DeclineAttendeesMutationResult =
  Apollo.MutationResult<DeclineAttendeesMutation>
export type DeclineAttendeesMutationOptions = Apollo.BaseMutationOptions<
  DeclineAttendeesMutation,
  DeclineAttendeesMutationVariables
>
export const DeclineMeetingModalDocument = gql`
  mutation DeclineMeetingModal($input: DeclineMeetingInput!) {
    declineMeeting(input: $input) {
      data {
        cancelled
        confirmed
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }
`
export type DeclineMeetingModalMutationFn = Apollo.MutationFunction<
  DeclineMeetingModalMutation,
  DeclineMeetingModalMutationVariables
>
export type DeclineMeetingModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeclineMeetingModalMutation,
    DeclineMeetingModalMutationVariables
  >,
  'mutation'
>

export const DeclineMeetingModalComponent = (
  props: DeclineMeetingModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeclineMeetingModalMutation,
    DeclineMeetingModalMutationVariables
  >
    mutation={DeclineMeetingModalDocument}
    {...props}
  />
)

/**
 * __useDeclineMeetingModalMutation__
 *
 * To run a mutation, you first call `useDeclineMeetingModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineMeetingModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineMeetingModalMutation, { data, loading, error }] = useDeclineMeetingModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineMeetingModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineMeetingModalMutation,
    DeclineMeetingModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeclineMeetingModalMutation,
    DeclineMeetingModalMutationVariables
  >(DeclineMeetingModalDocument, options)
}
export type DeclineMeetingModalMutationHookResult = ReturnType<
  typeof useDeclineMeetingModalMutation
>
export type DeclineMeetingModalMutationResult =
  Apollo.MutationResult<DeclineMeetingModalMutation>
export type DeclineMeetingModalMutationOptions = Apollo.BaseMutationOptions<
  DeclineMeetingModalMutation,
  DeclineMeetingModalMutationVariables
>
export const DeleteCalendarAccountModalDocument = gql`
  query DeleteCalendarAccountModal($calendarAccountId: ID!) {
    getCalendarAccountById(id: $calendarAccountId) {
      id
      name
      provider {
        id
        name
        slug
      }
    }
  }
`
export type DeleteCalendarAccountModalComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DeleteCalendarAccountModalQuery,
    DeleteCalendarAccountModalQueryVariables
  >,
  'query'
> &
  (
    | { variables: DeleteCalendarAccountModalQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const DeleteCalendarAccountModalComponent = (
  props: DeleteCalendarAccountModalComponentProps
) => (
  <ApolloReactComponents.Query<
    DeleteCalendarAccountModalQuery,
    DeleteCalendarAccountModalQueryVariables
  >
    query={DeleteCalendarAccountModalDocument}
    {...props}
  />
)

/**
 * __useDeleteCalendarAccountModalQuery__
 *
 * To run a query within a React component, call `useDeleteCalendarAccountModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarAccountModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteCalendarAccountModalQuery({
 *   variables: {
 *      calendarAccountId: // value for 'calendarAccountId'
 *   },
 * });
 */
export function useDeleteCalendarAccountModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    DeleteCalendarAccountModalQuery,
    DeleteCalendarAccountModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    DeleteCalendarAccountModalQuery,
    DeleteCalendarAccountModalQueryVariables
  >(DeleteCalendarAccountModalDocument, options)
}
export function useDeleteCalendarAccountModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeleteCalendarAccountModalQuery,
    DeleteCalendarAccountModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DeleteCalendarAccountModalQuery,
    DeleteCalendarAccountModalQueryVariables
  >(DeleteCalendarAccountModalDocument, options)
}
export type DeleteCalendarAccountModalQueryHookResult = ReturnType<
  typeof useDeleteCalendarAccountModalQuery
>
export type DeleteCalendarAccountModalLazyQueryHookResult = ReturnType<
  typeof useDeleteCalendarAccountModalLazyQuery
>
export type DeleteCalendarAccountModalQueryResult = Apollo.QueryResult<
  DeleteCalendarAccountModalQuery,
  DeleteCalendarAccountModalQueryVariables
>
export const CalendarAccountDeleteDocument = gql`
  mutation CalendarAccountDelete($input: DeleteCalendarAccountInput!) {
    deleteCalendarAccount(input: $input) {
      data {
        id
      }
    }
  }
`
export type CalendarAccountDeleteMutationFn = Apollo.MutationFunction<
  CalendarAccountDeleteMutation,
  CalendarAccountDeleteMutationVariables
>
export type CalendarAccountDeleteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CalendarAccountDeleteMutation,
    CalendarAccountDeleteMutationVariables
  >,
  'mutation'
>

export const CalendarAccountDeleteComponent = (
  props: CalendarAccountDeleteComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CalendarAccountDeleteMutation,
    CalendarAccountDeleteMutationVariables
  >
    mutation={CalendarAccountDeleteDocument}
    {...props}
  />
)

/**
 * __useCalendarAccountDeleteMutation__
 *
 * To run a mutation, you first call `useCalendarAccountDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarAccountDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarAccountDeleteMutation, { data, loading, error }] = useCalendarAccountDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalendarAccountDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CalendarAccountDeleteMutation,
    CalendarAccountDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CalendarAccountDeleteMutation,
    CalendarAccountDeleteMutationVariables
  >(CalendarAccountDeleteDocument, options)
}
export type CalendarAccountDeleteMutationHookResult = ReturnType<
  typeof useCalendarAccountDeleteMutation
>
export type CalendarAccountDeleteMutationResult =
  Apollo.MutationResult<CalendarAccountDeleteMutation>
export type CalendarAccountDeleteMutationOptions = Apollo.BaseMutationOptions<
  CalendarAccountDeleteMutation,
  CalendarAccountDeleteMutationVariables
>
export const ConferencingAccountDeleteDocument = gql`
  mutation ConferencingAccountDelete($input: DeleteConferencingAccountInput!) {
    account: deleteConferencingAccount(input: $input) {
      data {
        id
        deleted
      }
    }
  }
`
export type ConferencingAccountDeleteMutationFn = Apollo.MutationFunction<
  ConferencingAccountDeleteMutation,
  ConferencingAccountDeleteMutationVariables
>
export type ConferencingAccountDeleteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ConferencingAccountDeleteMutation,
    ConferencingAccountDeleteMutationVariables
  >,
  'mutation'
>

export const ConferencingAccountDeleteComponent = (
  props: ConferencingAccountDeleteComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ConferencingAccountDeleteMutation,
    ConferencingAccountDeleteMutationVariables
  >
    mutation={ConferencingAccountDeleteDocument}
    {...props}
  />
)

/**
 * __useConferencingAccountDeleteMutation__
 *
 * To run a mutation, you first call `useConferencingAccountDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConferencingAccountDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [conferencingAccountDeleteMutation, { data, loading, error }] = useConferencingAccountDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConferencingAccountDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConferencingAccountDeleteMutation,
    ConferencingAccountDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConferencingAccountDeleteMutation,
    ConferencingAccountDeleteMutationVariables
  >(ConferencingAccountDeleteDocument, options)
}
export type ConferencingAccountDeleteMutationHookResult = ReturnType<
  typeof useConferencingAccountDeleteMutation
>
export type ConferencingAccountDeleteMutationResult =
  Apollo.MutationResult<ConferencingAccountDeleteMutation>
export type ConferencingAccountDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    ConferencingAccountDeleteMutation,
    ConferencingAccountDeleteMutationVariables
  >
export const DeleteSchedulingPageModalDocument = gql`
  mutation DeleteSchedulingPageModal($input: DeleteTeamInput!) {
    schedulingPage: deleteTeam(input: $input) {
      data {
        id
        deleted
      }
    }
  }
`
export type DeleteSchedulingPageModalMutationFn = Apollo.MutationFunction<
  DeleteSchedulingPageModalMutation,
  DeleteSchedulingPageModalMutationVariables
>
export type DeleteSchedulingPageModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteSchedulingPageModalMutation,
    DeleteSchedulingPageModalMutationVariables
  >,
  'mutation'
>

export const DeleteSchedulingPageModalComponent = (
  props: DeleteSchedulingPageModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteSchedulingPageModalMutation,
    DeleteSchedulingPageModalMutationVariables
  >
    mutation={DeleteSchedulingPageModalDocument}
    {...props}
  />
)

/**
 * __useDeleteSchedulingPageModalMutation__
 *
 * To run a mutation, you first call `useDeleteSchedulingPageModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchedulingPageModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchedulingPageModalMutation, { data, loading, error }] = useDeleteSchedulingPageModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSchedulingPageModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSchedulingPageModalMutation,
    DeleteSchedulingPageModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteSchedulingPageModalMutation,
    DeleteSchedulingPageModalMutationVariables
  >(DeleteSchedulingPageModalDocument, options)
}
export type DeleteSchedulingPageModalMutationHookResult = ReturnType<
  typeof useDeleteSchedulingPageModalMutation
>
export type DeleteSchedulingPageModalMutationResult =
  Apollo.MutationResult<DeleteSchedulingPageModalMutation>
export type DeleteSchedulingPageModalMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteSchedulingPageModalMutation,
    DeleteSchedulingPageModalMutationVariables
  >
export const DeletePaymentCollectionAccountDocument = gql`
  mutation deletePaymentCollectionAccount(
    $input: DeletePaymentCollectionAccountInput!
  ) {
    account: deletePaymentCollectionAccount(input: $input) {
      data {
        deleted
        id
        name
      }
    }
  }
`
export type DeletePaymentCollectionAccountMutationFn = Apollo.MutationFunction<
  DeletePaymentCollectionAccountMutation,
  DeletePaymentCollectionAccountMutationVariables
>
export type DeletePaymentCollectionAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeletePaymentCollectionAccountMutation,
    DeletePaymentCollectionAccountMutationVariables
  >,
  'mutation'
>

export const DeletePaymentCollectionAccountComponent = (
  props: DeletePaymentCollectionAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeletePaymentCollectionAccountMutation,
    DeletePaymentCollectionAccountMutationVariables
  >
    mutation={DeletePaymentCollectionAccountDocument}
    {...props}
  />
)

/**
 * __useDeletePaymentCollectionAccountMutation__
 *
 * To run a mutation, you first call `useDeletePaymentCollectionAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentCollectionAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentCollectionAccountMutation, { data, loading, error }] = useDeletePaymentCollectionAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePaymentCollectionAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaymentCollectionAccountMutation,
    DeletePaymentCollectionAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeletePaymentCollectionAccountMutation,
    DeletePaymentCollectionAccountMutationVariables
  >(DeletePaymentCollectionAccountDocument, options)
}
export type DeletePaymentCollectionAccountMutationHookResult = ReturnType<
  typeof useDeletePaymentCollectionAccountMutation
>
export type DeletePaymentCollectionAccountMutationResult =
  Apollo.MutationResult<DeletePaymentCollectionAccountMutation>
export type DeletePaymentCollectionAccountMutationOptions =
  Apollo.BaseMutationOptions<
    DeletePaymentCollectionAccountMutation,
    DeletePaymentCollectionAccountMutationVariables
  >
export const InviteMembersModalDocument = gql`
  mutation InviteMembersModal($input: InviteMembersInput!) {
    inviteMembers(input: $input) {
      data {
        id
        deleted
        user {
          email
          firstName
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type InviteMembersModalMutationFn = Apollo.MutationFunction<
  InviteMembersModalMutation,
  InviteMembersModalMutationVariables
>
export type InviteMembersModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    InviteMembersModalMutation,
    InviteMembersModalMutationVariables
  >,
  'mutation'
>

export const InviteMembersModalComponent = (
  props: InviteMembersModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    InviteMembersModalMutation,
    InviteMembersModalMutationVariables
  >
    mutation={InviteMembersModalDocument}
    {...props}
  />
)

/**
 * __useInviteMembersModalMutation__
 *
 * To run a mutation, you first call `useInviteMembersModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMembersModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMembersModalMutation, { data, loading, error }] = useInviteMembersModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMembersModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteMembersModalMutation,
    InviteMembersModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InviteMembersModalMutation,
    InviteMembersModalMutationVariables
  >(InviteMembersModalDocument, options)
}
export type InviteMembersModalMutationHookResult = ReturnType<
  typeof useInviteMembersModalMutation
>
export type InviteMembersModalMutationResult =
  Apollo.MutationResult<InviteMembersModalMutation>
export type InviteMembersModalMutationOptions = Apollo.BaseMutationOptions<
  InviteMembersModalMutation,
  InviteMembersModalMutationVariables
>
export const LanguagePickerDocument = gql`
  query LanguagePicker {
    languages: __type(name: "Language") {
      enumValues {
        name
        description
      }
    }
  }
`
export type LanguagePickerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LanguagePickerQuery,
    LanguagePickerQueryVariables
  >,
  'query'
>

export const LanguagePickerComponent = (
  props: LanguagePickerComponentProps
) => (
  <ApolloReactComponents.Query<
    LanguagePickerQuery,
    LanguagePickerQueryVariables
  >
    query={LanguagePickerDocument}
    {...props}
  />
)

/**
 * __useLanguagePickerQuery__
 *
 * To run a query within a React component, call `useLanguagePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagePickerQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagePickerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LanguagePickerQuery,
    LanguagePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LanguagePickerQuery, LanguagePickerQueryVariables>(
    LanguagePickerDocument,
    options
  )
}
export function useLanguagePickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LanguagePickerQuery,
    LanguagePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LanguagePickerQuery, LanguagePickerQueryVariables>(
    LanguagePickerDocument,
    options
  )
}
export type LanguagePickerQueryHookResult = ReturnType<
  typeof useLanguagePickerQuery
>
export type LanguagePickerLazyQueryHookResult = ReturnType<
  typeof useLanguagePickerLazyQuery
>
export type LanguagePickerQueryResult = Apollo.QueryResult<
  LanguagePickerQuery,
  LanguagePickerQueryVariables
>
export const MeetingCalendarPickerDocument = gql`
  query MeetingCalendarPicker($memberId: ID!) {
    member: getMemberById(id: $memberId) {
      id
      calendarAccounts {
        edges {
          node {
            id
            name
            provider {
              id
              name
              slug
            }
            calendars {
              edges {
                node {
                  id
                  name
                  color
                  permissionLevel
                }
              }
            }
          }
        }
      }
    }
  }
`
export type MeetingCalendarPickerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingCalendarPickerQuery,
    MeetingCalendarPickerQueryVariables
  >,
  'query'
> &
  (
    | { variables: MeetingCalendarPickerQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const MeetingCalendarPickerComponent = (
  props: MeetingCalendarPickerComponentProps
) => (
  <ApolloReactComponents.Query<
    MeetingCalendarPickerQuery,
    MeetingCalendarPickerQueryVariables
  >
    query={MeetingCalendarPickerDocument}
    {...props}
  />
)

/**
 * __useMeetingCalendarPickerQuery__
 *
 * To run a query within a React component, call `useMeetingCalendarPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingCalendarPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingCalendarPickerQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useMeetingCalendarPickerQuery(
  baseOptions: Apollo.QueryHookOptions<
    MeetingCalendarPickerQuery,
    MeetingCalendarPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    MeetingCalendarPickerQuery,
    MeetingCalendarPickerQueryVariables
  >(MeetingCalendarPickerDocument, options)
}
export function useMeetingCalendarPickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeetingCalendarPickerQuery,
    MeetingCalendarPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MeetingCalendarPickerQuery,
    MeetingCalendarPickerQueryVariables
  >(MeetingCalendarPickerDocument, options)
}
export type MeetingCalendarPickerQueryHookResult = ReturnType<
  typeof useMeetingCalendarPickerQuery
>
export type MeetingCalendarPickerLazyQueryHookResult = ReturnType<
  typeof useMeetingCalendarPickerLazyQuery
>
export type MeetingCalendarPickerQueryResult = Apollo.QueryResult<
  MeetingCalendarPickerQuery,
  MeetingCalendarPickerQueryVariables
>
export const MeetingTypesPageDocument = gql`
  query MeetingTypesPage($id: ID!, $search: String) {
    team: getTeamById(id: $id) {
      bookingUrl
      id
      meetingTypes(search: $search) {
        edges {
          node {
            bookingUrl
            color
            deleted
            description
            duration
            hostAssignmentStrategy
            id
            image
            isGroup
            maxAttendees
            meetingTypeTeamMembers {
              edges {
                node {
                  active
                  id
                  teamMember {
                    id
                    member {
                      id
                      user {
                        email
                        firstName
                        id
                        image
                        lastName
                      }
                    }
                  }
                }
              }
            }
            name
            order
            price
            priceCurrency
            tags
          }
        }
      }
      name
      slug
      teamMembers {
        edges {
          node {
            id
            member {
              id
              user {
                email
                firstName
                id
                image
                lastName
                timezone
              }
            }
          }
        }
      }
    }
  }
`
export type MeetingTypesPageComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingTypesPageQuery,
    MeetingTypesPageQueryVariables
  >,
  'query'
> &
  (
    | { variables: MeetingTypesPageQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const MeetingTypesPageComponent = (
  props: MeetingTypesPageComponentProps
) => (
  <ApolloReactComponents.Query<
    MeetingTypesPageQuery,
    MeetingTypesPageQueryVariables
  >
    query={MeetingTypesPageDocument}
    {...props}
  />
)

/**
 * __useMeetingTypesPageQuery__
 *
 * To run a query within a React component, call `useMeetingTypesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingTypesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingTypesPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useMeetingTypesPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    MeetingTypesPageQuery,
    MeetingTypesPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeetingTypesPageQuery, MeetingTypesPageQueryVariables>(
    MeetingTypesPageDocument,
    options
  )
}
export function useMeetingTypesPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeetingTypesPageQuery,
    MeetingTypesPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MeetingTypesPageQuery,
    MeetingTypesPageQueryVariables
  >(MeetingTypesPageDocument, options)
}
export type MeetingTypesPageQueryHookResult = ReturnType<
  typeof useMeetingTypesPageQuery
>
export type MeetingTypesPageLazyQueryHookResult = ReturnType<
  typeof useMeetingTypesPageLazyQuery
>
export type MeetingTypesPageQueryResult = Apollo.QueryResult<
  MeetingTypesPageQuery,
  MeetingTypesPageQueryVariables
>
export const CloneMeetingTypeDocument = gql`
  mutation CloneMeetingType($input: CloneMeetingTypeInput!) {
    cloneMeetingType(input: $input) {
      data {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CloneMeetingTypeMutationFn = Apollo.MutationFunction<
  CloneMeetingTypeMutation,
  CloneMeetingTypeMutationVariables
>
export type CloneMeetingTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CloneMeetingTypeMutation,
    CloneMeetingTypeMutationVariables
  >,
  'mutation'
>

export const CloneMeetingTypeComponent = (
  props: CloneMeetingTypeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CloneMeetingTypeMutation,
    CloneMeetingTypeMutationVariables
  >
    mutation={CloneMeetingTypeDocument}
    {...props}
  />
)

/**
 * __useCloneMeetingTypeMutation__
 *
 * To run a mutation, you first call `useCloneMeetingTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneMeetingTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneMeetingTypeMutation, { data, loading, error }] = useCloneMeetingTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneMeetingTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloneMeetingTypeMutation,
    CloneMeetingTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CloneMeetingTypeMutation,
    CloneMeetingTypeMutationVariables
  >(CloneMeetingTypeDocument, options)
}
export type CloneMeetingTypeMutationHookResult = ReturnType<
  typeof useCloneMeetingTypeMutation
>
export type CloneMeetingTypeMutationResult =
  Apollo.MutationResult<CloneMeetingTypeMutation>
export type CloneMeetingTypeMutationOptions = Apollo.BaseMutationOptions<
  CloneMeetingTypeMutation,
  CloneMeetingTypeMutationVariables
>
export const DeleteMeetingTypeModalDocument = gql`
  mutation DeleteMeetingTypeModal($input: DeleteMeetingTypeInput!) {
    deleteMeetingType(input: $input) {
      data {
        id
        deleted
      }
    }
  }
`
export type DeleteMeetingTypeModalMutationFn = Apollo.MutationFunction<
  DeleteMeetingTypeModalMutation,
  DeleteMeetingTypeModalMutationVariables
>
export type DeleteMeetingTypeModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMeetingTypeModalMutation,
    DeleteMeetingTypeModalMutationVariables
  >,
  'mutation'
>

export const DeleteMeetingTypeModalComponent = (
  props: DeleteMeetingTypeModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteMeetingTypeModalMutation,
    DeleteMeetingTypeModalMutationVariables
  >
    mutation={DeleteMeetingTypeModalDocument}
    {...props}
  />
)

/**
 * __useDeleteMeetingTypeModalMutation__
 *
 * To run a mutation, you first call `useDeleteMeetingTypeModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeetingTypeModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeetingTypeModalMutation, { data, loading, error }] = useDeleteMeetingTypeModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMeetingTypeModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMeetingTypeModalMutation,
    DeleteMeetingTypeModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteMeetingTypeModalMutation,
    DeleteMeetingTypeModalMutationVariables
  >(DeleteMeetingTypeModalDocument, options)
}
export type DeleteMeetingTypeModalMutationHookResult = ReturnType<
  typeof useDeleteMeetingTypeModalMutation
>
export type DeleteMeetingTypeModalMutationResult =
  Apollo.MutationResult<DeleteMeetingTypeModalMutation>
export type DeleteMeetingTypeModalMutationOptions = Apollo.BaseMutationOptions<
  DeleteMeetingTypeModalMutation,
  DeleteMeetingTypeModalMutationVariables
>
export const MeetingTypesPickerDocument = gql`
  query MeetingTypesPicker($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            id
            name
            meetingTypes {
              edges {
                node {
                  id
                  color
                  image
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
export type MeetingTypesPickerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingTypesPickerQuery,
    MeetingTypesPickerQueryVariables
  >,
  'query'
> &
  (
    | { variables: MeetingTypesPickerQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const MeetingTypesPickerComponent = (
  props: MeetingTypesPickerComponentProps
) => (
  <ApolloReactComponents.Query<
    MeetingTypesPickerQuery,
    MeetingTypesPickerQueryVariables
  >
    query={MeetingTypesPickerDocument}
    {...props}
  />
)

/**
 * __useMeetingTypesPickerQuery__
 *
 * To run a query within a React component, call `useMeetingTypesPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingTypesPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingTypesPickerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMeetingTypesPickerQuery(
  baseOptions: Apollo.QueryHookOptions<
    MeetingTypesPickerQuery,
    MeetingTypesPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    MeetingTypesPickerQuery,
    MeetingTypesPickerQueryVariables
  >(MeetingTypesPickerDocument, options)
}
export function useMeetingTypesPickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeetingTypesPickerQuery,
    MeetingTypesPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MeetingTypesPickerQuery,
    MeetingTypesPickerQueryVariables
  >(MeetingTypesPickerDocument, options)
}
export type MeetingTypesPickerQueryHookResult = ReturnType<
  typeof useMeetingTypesPickerQuery
>
export type MeetingTypesPickerLazyQueryHookResult = ReturnType<
  typeof useMeetingTypesPickerLazyQuery
>
export type MeetingTypesPickerQueryResult = Apollo.QueryResult<
  MeetingTypesPickerQuery,
  MeetingTypesPickerQueryVariables
>
export const MeetingsPageDocument = gql`
  query MeetingsPage(
    $after: String
    $before: String
    $first: Int
    $id: ID!
    $isCancelled: Boolean
    $isConfirmed: Boolean
    $meetingTypes: [ID]
    $members: [ID]
    $search: String
    $startOnOrAfter: DateTime
    $startOnOrBefore: DateTime
    $orderBy: String
  ) {
    profile: getProfileById(id: $id) {
      id
      meetings(
        after: $after
        before: $before
        first: $first
        isCancelled: $isCancelled
        isConfirmed: $isConfirmed
        meetingTypes: $meetingTypes
        members: $members
        search: $search
        startOnOrAfter: $startOnOrAfter
        startOnOrBefore: $startOnOrBefore
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          startCursor
          endCursor
          hasPreviousPage
        }
        ...Meetings
      }
    }
  }
  ${MeetingsFragmentDoc}
`
export type MeetingsPageComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingsPageQuery,
    MeetingsPageQueryVariables
  >,
  'query'
> &
  (
    | { variables: MeetingsPageQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const MeetingsPageComponent = (props: MeetingsPageComponentProps) => (
  <ApolloReactComponents.Query<MeetingsPageQuery, MeetingsPageQueryVariables>
    query={MeetingsPageDocument}
    {...props}
  />
)

/**
 * __useMeetingsPageQuery__
 *
 * To run a query within a React component, call `useMeetingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingsPageQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      id: // value for 'id'
 *      isCancelled: // value for 'isCancelled'
 *      isConfirmed: // value for 'isConfirmed'
 *      meetingTypes: // value for 'meetingTypes'
 *      members: // value for 'members'
 *      search: // value for 'search'
 *      startOnOrAfter: // value for 'startOnOrAfter'
 *      startOnOrBefore: // value for 'startOnOrBefore'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useMeetingsPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    MeetingsPageQuery,
    MeetingsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeetingsPageQuery, MeetingsPageQueryVariables>(
    MeetingsPageDocument,
    options
  )
}
export function useMeetingsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeetingsPageQuery,
    MeetingsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeetingsPageQuery, MeetingsPageQueryVariables>(
    MeetingsPageDocument,
    options
  )
}
export type MeetingsPageQueryHookResult = ReturnType<
  typeof useMeetingsPageQuery
>
export type MeetingsPageLazyQueryHookResult = ReturnType<
  typeof useMeetingsPageLazyQuery
>
export type MeetingsPageQueryResult = Apollo.QueryResult<
  MeetingsPageQuery,
  MeetingsPageQueryVariables
>
export const MeetingLaunchActionDocument = gql`
  query MeetingLaunchAction($id: ID!) {
    meeting: getMeetingById(id: $id) {
      id
      name
      start
    }
  }
`
export type MeetingLaunchActionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingLaunchActionQuery,
    MeetingLaunchActionQueryVariables
  >,
  'query'
> &
  (
    | { variables: MeetingLaunchActionQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const MeetingLaunchActionComponent = (
  props: MeetingLaunchActionComponentProps
) => (
  <ApolloReactComponents.Query<
    MeetingLaunchActionQuery,
    MeetingLaunchActionQueryVariables
  >
    query={MeetingLaunchActionDocument}
    {...props}
  />
)

/**
 * __useMeetingLaunchActionQuery__
 *
 * To run a query within a React component, call `useMeetingLaunchActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingLaunchActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingLaunchActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMeetingLaunchActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    MeetingLaunchActionQuery,
    MeetingLaunchActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    MeetingLaunchActionQuery,
    MeetingLaunchActionQueryVariables
  >(MeetingLaunchActionDocument, options)
}
export function useMeetingLaunchActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeetingLaunchActionQuery,
    MeetingLaunchActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MeetingLaunchActionQuery,
    MeetingLaunchActionQueryVariables
  >(MeetingLaunchActionDocument, options)
}
export type MeetingLaunchActionQueryHookResult = ReturnType<
  typeof useMeetingLaunchActionQuery
>
export type MeetingLaunchActionLazyQueryHookResult = ReturnType<
  typeof useMeetingLaunchActionLazyQuery
>
export type MeetingLaunchActionQueryResult = Apollo.QueryResult<
  MeetingLaunchActionQuery,
  MeetingLaunchActionQueryVariables
>
export const AttendeeLaunchActionDocument = gql`
  query AttendeeLaunchAction($id: ID!) {
    attendee: getAttendeeById(id: $id) {
      id
      firstName
      lastName
    }
  }
`
export type AttendeeLaunchActionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AttendeeLaunchActionQuery,
    AttendeeLaunchActionQueryVariables
  >,
  'query'
> &
  (
    | { variables: AttendeeLaunchActionQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const AttendeeLaunchActionComponent = (
  props: AttendeeLaunchActionComponentProps
) => (
  <ApolloReactComponents.Query<
    AttendeeLaunchActionQuery,
    AttendeeLaunchActionQueryVariables
  >
    query={AttendeeLaunchActionDocument}
    {...props}
  />
)

/**
 * __useAttendeeLaunchActionQuery__
 *
 * To run a query within a React component, call `useAttendeeLaunchActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeLaunchActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeLaunchActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttendeeLaunchActionQuery(
  baseOptions: Apollo.QueryHookOptions<
    AttendeeLaunchActionQuery,
    AttendeeLaunchActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AttendeeLaunchActionQuery,
    AttendeeLaunchActionQueryVariables
  >(AttendeeLaunchActionDocument, options)
}
export function useAttendeeLaunchActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AttendeeLaunchActionQuery,
    AttendeeLaunchActionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AttendeeLaunchActionQuery,
    AttendeeLaunchActionQueryVariables
  >(AttendeeLaunchActionDocument, options)
}
export type AttendeeLaunchActionQueryHookResult = ReturnType<
  typeof useAttendeeLaunchActionQuery
>
export type AttendeeLaunchActionLazyQueryHookResult = ReturnType<
  typeof useAttendeeLaunchActionLazyQuery
>
export type AttendeeLaunchActionQueryResult = Apollo.QueryResult<
  AttendeeLaunchActionQuery,
  AttendeeLaunchActionQueryVariables
>
export const UpdateUserTimezoneDocument = gql`
  mutation UpdateUserTimezone($input: UpdateUserInput!) {
    user: updateUser(input: $input) {
      data {
        id
        timezone
      }
      errors {
        field
        messages
      }
    }
  }
`
export type UpdateUserTimezoneMutationFn = Apollo.MutationFunction<
  UpdateUserTimezoneMutation,
  UpdateUserTimezoneMutationVariables
>
export type UpdateUserTimezoneComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserTimezoneMutation,
    UpdateUserTimezoneMutationVariables
  >,
  'mutation'
>

export const UpdateUserTimezoneComponent = (
  props: UpdateUserTimezoneComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateUserTimezoneMutation,
    UpdateUserTimezoneMutationVariables
  >
    mutation={UpdateUserTimezoneDocument}
    {...props}
  />
)

/**
 * __useUpdateUserTimezoneMutation__
 *
 * To run a mutation, you first call `useUpdateUserTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTimezoneMutation, { data, loading, error }] = useUpdateUserTimezoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserTimezoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserTimezoneMutation,
    UpdateUserTimezoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateUserTimezoneMutation,
    UpdateUserTimezoneMutationVariables
  >(UpdateUserTimezoneDocument, options)
}
export type UpdateUserTimezoneMutationHookResult = ReturnType<
  typeof useUpdateUserTimezoneMutation
>
export type UpdateUserTimezoneMutationResult =
  Apollo.MutationResult<UpdateUserTimezoneMutation>
export type UpdateUserTimezoneMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserTimezoneMutation,
  UpdateUserTimezoneMutationVariables
>
export const MembersPickerDocument = gql`
  query MembersPicker($id: ID!) {
    profile: getProfileById(id: $id) {
      id
      members {
        edges {
          node {
            id
            user {
              email
              firstName
              id
              image
              lastName
            }
          }
        }
      }
    }
  }
`
export type MembersPickerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MembersPickerQuery,
    MembersPickerQueryVariables
  >,
  'query'
> &
  (
    | { variables: MembersPickerQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const MembersPickerComponent = (props: MembersPickerComponentProps) => (
  <ApolloReactComponents.Query<MembersPickerQuery, MembersPickerQueryVariables>
    query={MembersPickerDocument}
    {...props}
  />
)

/**
 * __useMembersPickerQuery__
 *
 * To run a query within a React component, call `useMembersPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersPickerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMembersPickerQuery(
  baseOptions: Apollo.QueryHookOptions<
    MembersPickerQuery,
    MembersPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MembersPickerQuery, MembersPickerQueryVariables>(
    MembersPickerDocument,
    options
  )
}
export function useMembersPickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MembersPickerQuery,
    MembersPickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MembersPickerQuery, MembersPickerQueryVariables>(
    MembersPickerDocument,
    options
  )
}
export type MembersPickerQueryHookResult = ReturnType<
  typeof useMembersPickerQuery
>
export type MembersPickerLazyQueryHookResult = ReturnType<
  typeof useMembersPickerLazyQuery
>
export type MembersPickerQueryResult = Apollo.QueryResult<
  MembersPickerQuery,
  MembersPickerQueryVariables
>
export const NewMeetingTimeModalDocument = gql`
  mutation NewMeetingTimeModal($input: RescheduleMeetingInput!) {
    rescheduleMeeting(input: $input) {
      data {
        end
        id
        name
        start
      }
      errors {
        field
        messages
      }
    }
  }
`
export type NewMeetingTimeModalMutationFn = Apollo.MutationFunction<
  NewMeetingTimeModalMutation,
  NewMeetingTimeModalMutationVariables
>
export type NewMeetingTimeModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    NewMeetingTimeModalMutation,
    NewMeetingTimeModalMutationVariables
  >,
  'mutation'
>

export const NewMeetingTimeModalComponent = (
  props: NewMeetingTimeModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    NewMeetingTimeModalMutation,
    NewMeetingTimeModalMutationVariables
  >
    mutation={NewMeetingTimeModalDocument}
    {...props}
  />
)

/**
 * __useNewMeetingTimeModalMutation__
 *
 * To run a mutation, you first call `useNewMeetingTimeModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewMeetingTimeModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newMeetingTimeModalMutation, { data, loading, error }] = useNewMeetingTimeModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewMeetingTimeModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NewMeetingTimeModalMutation,
    NewMeetingTimeModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    NewMeetingTimeModalMutation,
    NewMeetingTimeModalMutationVariables
  >(NewMeetingTimeModalDocument, options)
}
export type NewMeetingTimeModalMutationHookResult = ReturnType<
  typeof useNewMeetingTimeModalMutation
>
export type NewMeetingTimeModalMutationResult =
  Apollo.MutationResult<NewMeetingTimeModalMutation>
export type NewMeetingTimeModalMutationOptions = Apollo.BaseMutationOptions<
  NewMeetingTimeModalMutation,
  NewMeetingTimeModalMutationVariables
>
export const OnboardingCalendarIntegrationsPageDocument = gql`
  query OnboardingCalendarIntegrationsPage($id: ID!) {
    member: getMemberById(id: $id) {
      id
      calendarAccounts {
        edges {
          node {
            calendars {
              edges {
                node {
                  color
                  id
                  name
                  permissionLevel
                  syncMeetings
                  transparent
                }
              }
            }
            id
            provider {
              id
              name
              slug
            }
          }
        }
      }
    }
    providers: getCalendarProviders {
      edges {
        node {
          id
          name
          oauth2AuthorizationUrl
          slug
          shortDescription
        }
      }
    }
  }
`
export type OnboardingCalendarIntegrationsPageComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OnboardingCalendarIntegrationsPageQuery,
    OnboardingCalendarIntegrationsPageQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: OnboardingCalendarIntegrationsPageQueryVariables
        skip?: boolean
      }
    | { skip: boolean }
  )

export const OnboardingCalendarIntegrationsPageComponent = (
  props: OnboardingCalendarIntegrationsPageComponentProps
) => (
  <ApolloReactComponents.Query<
    OnboardingCalendarIntegrationsPageQuery,
    OnboardingCalendarIntegrationsPageQueryVariables
  >
    query={OnboardingCalendarIntegrationsPageDocument}
    {...props}
  />
)

/**
 * __useOnboardingCalendarIntegrationsPageQuery__
 *
 * To run a query within a React component, call `useOnboardingCalendarIntegrationsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCalendarIntegrationsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingCalendarIntegrationsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnboardingCalendarIntegrationsPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    OnboardingCalendarIntegrationsPageQuery,
    OnboardingCalendarIntegrationsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OnboardingCalendarIntegrationsPageQuery,
    OnboardingCalendarIntegrationsPageQueryVariables
  >(OnboardingCalendarIntegrationsPageDocument, options)
}
export function useOnboardingCalendarIntegrationsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingCalendarIntegrationsPageQuery,
    OnboardingCalendarIntegrationsPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OnboardingCalendarIntegrationsPageQuery,
    OnboardingCalendarIntegrationsPageQueryVariables
  >(OnboardingCalendarIntegrationsPageDocument, options)
}
export type OnboardingCalendarIntegrationsPageQueryHookResult = ReturnType<
  typeof useOnboardingCalendarIntegrationsPageQuery
>
export type OnboardingCalendarIntegrationsPageLazyQueryHookResult = ReturnType<
  typeof useOnboardingCalendarIntegrationsPageLazyQuery
>
export type OnboardingCalendarIntegrationsPageQueryResult = Apollo.QueryResult<
  OnboardingCalendarIntegrationsPageQuery,
  OnboardingCalendarIntegrationsPageQueryVariables
>
export const OnboardingCalendarIntegrationsPageCreateDocument = gql`
  mutation OnboardingCalendarIntegrationsPageCreate(
    $input: CreateCalendarAccountInput!
  ) {
    createCalendarAccount(input: $input) {
      data {
        calendars {
          edges {
            node {
              color
              id
              lastSyncAt
              name
              syncMeetings
              transparent
            }
          }
        }
        id
        isConnected
        name
        provider {
          name
          slug
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingCalendarIntegrationsPageCreateMutationFn =
  Apollo.MutationFunction<
    OnboardingCalendarIntegrationsPageCreateMutation,
    OnboardingCalendarIntegrationsPageCreateMutationVariables
  >
export type OnboardingCalendarIntegrationsPageCreateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingCalendarIntegrationsPageCreateMutation,
    OnboardingCalendarIntegrationsPageCreateMutationVariables
  >,
  'mutation'
>

export const OnboardingCalendarIntegrationsPageCreateComponent = (
  props: OnboardingCalendarIntegrationsPageCreateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingCalendarIntegrationsPageCreateMutation,
    OnboardingCalendarIntegrationsPageCreateMutationVariables
  >
    mutation={OnboardingCalendarIntegrationsPageCreateDocument}
    {...props}
  />
)

/**
 * __useOnboardingCalendarIntegrationsPageCreateMutation__
 *
 * To run a mutation, you first call `useOnboardingCalendarIntegrationsPageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCalendarIntegrationsPageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingCalendarIntegrationsPageCreateMutation, { data, loading, error }] = useOnboardingCalendarIntegrationsPageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingCalendarIntegrationsPageCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingCalendarIntegrationsPageCreateMutation,
    OnboardingCalendarIntegrationsPageCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingCalendarIntegrationsPageCreateMutation,
    OnboardingCalendarIntegrationsPageCreateMutationVariables
  >(OnboardingCalendarIntegrationsPageCreateDocument, options)
}
export type OnboardingCalendarIntegrationsPageCreateMutationHookResult =
  ReturnType<typeof useOnboardingCalendarIntegrationsPageCreateMutation>
export type OnboardingCalendarIntegrationsPageCreateMutationResult =
  Apollo.MutationResult<OnboardingCalendarIntegrationsPageCreateMutation>
export type OnboardingCalendarIntegrationsPageCreateMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingCalendarIntegrationsPageCreateMutation,
    OnboardingCalendarIntegrationsPageCreateMutationVariables
  >
export const OnboardingFinishedPageUpdateDocument = gql`
  mutation OnboardingFinishedPageUpdate($input: UpdateUserInput!) {
    user: updateUser(input: $input) {
      data {
        id
        needsOnboarding
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingFinishedPageUpdateMutationFn = Apollo.MutationFunction<
  OnboardingFinishedPageUpdateMutation,
  OnboardingFinishedPageUpdateMutationVariables
>
export type OnboardingFinishedPageUpdateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingFinishedPageUpdateMutation,
    OnboardingFinishedPageUpdateMutationVariables
  >,
  'mutation'
>

export const OnboardingFinishedPageUpdateComponent = (
  props: OnboardingFinishedPageUpdateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingFinishedPageUpdateMutation,
    OnboardingFinishedPageUpdateMutationVariables
  >
    mutation={OnboardingFinishedPageUpdateDocument}
    {...props}
  />
)

/**
 * __useOnboardingFinishedPageUpdateMutation__
 *
 * To run a mutation, you first call `useOnboardingFinishedPageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingFinishedPageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingFinishedPageUpdateMutation, { data, loading, error }] = useOnboardingFinishedPageUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingFinishedPageUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingFinishedPageUpdateMutation,
    OnboardingFinishedPageUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingFinishedPageUpdateMutation,
    OnboardingFinishedPageUpdateMutationVariables
  >(OnboardingFinishedPageUpdateDocument, options)
}
export type OnboardingFinishedPageUpdateMutationHookResult = ReturnType<
  typeof useOnboardingFinishedPageUpdateMutation
>
export type OnboardingFinishedPageUpdateMutationResult =
  Apollo.MutationResult<OnboardingFinishedPageUpdateMutation>
export type OnboardingFinishedPageUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingFinishedPageUpdateMutation,
    OnboardingFinishedPageUpdateMutationVariables
  >
export const OnboardingInviteMembersPageDocument = gql`
  mutation OnboardingInviteMembersPage($input: InviteMembersInput!) {
    inviteMembers(input: $input) {
      data {
        id
        user {
          email
          firstName
          id
          image
          lastName
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingInviteMembersPageMutationFn = Apollo.MutationFunction<
  OnboardingInviteMembersPageMutation,
  OnboardingInviteMembersPageMutationVariables
>
export type OnboardingInviteMembersPageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingInviteMembersPageMutation,
    OnboardingInviteMembersPageMutationVariables
  >,
  'mutation'
>

export const OnboardingInviteMembersPageComponent = (
  props: OnboardingInviteMembersPageComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingInviteMembersPageMutation,
    OnboardingInviteMembersPageMutationVariables
  >
    mutation={OnboardingInviteMembersPageDocument}
    {...props}
  />
)

/**
 * __useOnboardingInviteMembersPageMutation__
 *
 * To run a mutation, you first call `useOnboardingInviteMembersPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingInviteMembersPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingInviteMembersPageMutation, { data, loading, error }] = useOnboardingInviteMembersPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingInviteMembersPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingInviteMembersPageMutation,
    OnboardingInviteMembersPageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingInviteMembersPageMutation,
    OnboardingInviteMembersPageMutationVariables
  >(OnboardingInviteMembersPageDocument, options)
}
export type OnboardingInviteMembersPageMutationHookResult = ReturnType<
  typeof useOnboardingInviteMembersPageMutation
>
export type OnboardingInviteMembersPageMutationResult =
  Apollo.MutationResult<OnboardingInviteMembersPageMutation>
export type OnboardingInviteMembersPageMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingInviteMembersPageMutation,
    OnboardingInviteMembersPageMutationVariables
  >
export const OnboardingProfileInfoPageDocument = gql`
  mutation OnboardingProfileInfoPage($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      data {
        id
        image
        name
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingProfileInfoPageMutationFn = Apollo.MutationFunction<
  OnboardingProfileInfoPageMutation,
  OnboardingProfileInfoPageMutationVariables
>
export type OnboardingProfileInfoPageComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingProfileInfoPageMutation,
    OnboardingProfileInfoPageMutationVariables
  >,
  'mutation'
>

export const OnboardingProfileInfoPageComponent = (
  props: OnboardingProfileInfoPageComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingProfileInfoPageMutation,
    OnboardingProfileInfoPageMutationVariables
  >
    mutation={OnboardingProfileInfoPageDocument}
    {...props}
  />
)

/**
 * __useOnboardingProfileInfoPageMutation__
 *
 * To run a mutation, you first call `useOnboardingProfileInfoPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingProfileInfoPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingProfileInfoPageMutation, { data, loading, error }] = useOnboardingProfileInfoPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingProfileInfoPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingProfileInfoPageMutation,
    OnboardingProfileInfoPageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingProfileInfoPageMutation,
    OnboardingProfileInfoPageMutationVariables
  >(OnboardingProfileInfoPageDocument, options)
}
export type OnboardingProfileInfoPageMutationHookResult = ReturnType<
  typeof useOnboardingProfileInfoPageMutation
>
export type OnboardingProfileInfoPageMutationResult =
  Apollo.MutationResult<OnboardingProfileInfoPageMutation>
export type OnboardingProfileInfoPageMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingProfileInfoPageMutation,
    OnboardingProfileInfoPageMutationVariables
  >
export const OnboardingProfileTypeCreateDocument = gql`
  mutation OnboardingProfileTypeCreate($input: CreateProfileInput!) {
    profile: createProfile(input: $input) {
      data {
        billingCustomerId
        id
        personal
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingProfileTypeCreateMutationFn = Apollo.MutationFunction<
  OnboardingProfileTypeCreateMutation,
  OnboardingProfileTypeCreateMutationVariables
>
export type OnboardingProfileTypeCreateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingProfileTypeCreateMutation,
    OnboardingProfileTypeCreateMutationVariables
  >,
  'mutation'
>

export const OnboardingProfileTypeCreateComponent = (
  props: OnboardingProfileTypeCreateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingProfileTypeCreateMutation,
    OnboardingProfileTypeCreateMutationVariables
  >
    mutation={OnboardingProfileTypeCreateDocument}
    {...props}
  />
)

/**
 * __useOnboardingProfileTypeCreateMutation__
 *
 * To run a mutation, you first call `useOnboardingProfileTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingProfileTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingProfileTypeCreateMutation, { data, loading, error }] = useOnboardingProfileTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingProfileTypeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingProfileTypeCreateMutation,
    OnboardingProfileTypeCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingProfileTypeCreateMutation,
    OnboardingProfileTypeCreateMutationVariables
  >(OnboardingProfileTypeCreateDocument, options)
}
export type OnboardingProfileTypeCreateMutationHookResult = ReturnType<
  typeof useOnboardingProfileTypeCreateMutation
>
export type OnboardingProfileTypeCreateMutationResult =
  Apollo.MutationResult<OnboardingProfileTypeCreateMutation>
export type OnboardingProfileTypeCreateMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingProfileTypeCreateMutation,
    OnboardingProfileTypeCreateMutationVariables
  >
export const OnboardingProfileTypeUpdateDocument = gql`
  mutation OnboardingProfileTypeUpdate($input: UpdateProfileInput!) {
    profile: updateProfile(input: $input) {
      data {
        billingCustomerId
        id
        personal
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingProfileTypeUpdateMutationFn = Apollo.MutationFunction<
  OnboardingProfileTypeUpdateMutation,
  OnboardingProfileTypeUpdateMutationVariables
>
export type OnboardingProfileTypeUpdateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingProfileTypeUpdateMutation,
    OnboardingProfileTypeUpdateMutationVariables
  >,
  'mutation'
>

export const OnboardingProfileTypeUpdateComponent = (
  props: OnboardingProfileTypeUpdateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingProfileTypeUpdateMutation,
    OnboardingProfileTypeUpdateMutationVariables
  >
    mutation={OnboardingProfileTypeUpdateDocument}
    {...props}
  />
)

/**
 * __useOnboardingProfileTypeUpdateMutation__
 *
 * To run a mutation, you first call `useOnboardingProfileTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingProfileTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingProfileTypeUpdateMutation, { data, loading, error }] = useOnboardingProfileTypeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingProfileTypeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingProfileTypeUpdateMutation,
    OnboardingProfileTypeUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingProfileTypeUpdateMutation,
    OnboardingProfileTypeUpdateMutationVariables
  >(OnboardingProfileTypeUpdateDocument, options)
}
export type OnboardingProfileTypeUpdateMutationHookResult = ReturnType<
  typeof useOnboardingProfileTypeUpdateMutation
>
export type OnboardingProfileTypeUpdateMutationResult =
  Apollo.MutationResult<OnboardingProfileTypeUpdateMutation>
export type OnboardingProfileTypeUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingProfileTypeUpdateMutation,
    OnboardingProfileTypeUpdateMutationVariables
  >
export const OnboardingWebConferencingPageDocument = gql`
  query OnboardingWebConferencingPage {
    providers: getConferenceProviders {
      edges {
        node {
          id
          name
          oauth2AuthorizationUrl
          slug
        }
      }
    }
  }
`
export type OnboardingWebConferencingPageComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OnboardingWebConferencingPageQuery,
    OnboardingWebConferencingPageQueryVariables
  >,
  'query'
>

export const OnboardingWebConferencingPageComponent = (
  props: OnboardingWebConferencingPageComponentProps
) => (
  <ApolloReactComponents.Query<
    OnboardingWebConferencingPageQuery,
    OnboardingWebConferencingPageQueryVariables
  >
    query={OnboardingWebConferencingPageDocument}
    {...props}
  />
)

/**
 * __useOnboardingWebConferencingPageQuery__
 *
 * To run a query within a React component, call `useOnboardingWebConferencingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingWebConferencingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingWebConferencingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingWebConferencingPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OnboardingWebConferencingPageQuery,
    OnboardingWebConferencingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OnboardingWebConferencingPageQuery,
    OnboardingWebConferencingPageQueryVariables
  >(OnboardingWebConferencingPageDocument, options)
}
export function useOnboardingWebConferencingPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingWebConferencingPageQuery,
    OnboardingWebConferencingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OnboardingWebConferencingPageQuery,
    OnboardingWebConferencingPageQueryVariables
  >(OnboardingWebConferencingPageDocument, options)
}
export type OnboardingWebConferencingPageQueryHookResult = ReturnType<
  typeof useOnboardingWebConferencingPageQuery
>
export type OnboardingWebConferencingPageLazyQueryHookResult = ReturnType<
  typeof useOnboardingWebConferencingPageLazyQuery
>
export type OnboardingWebConferencingPageQueryResult = Apollo.QueryResult<
  OnboardingWebConferencingPageQuery,
  OnboardingWebConferencingPageQueryVariables
>
export const OnboardingWebConferencingPageCreateDocument = gql`
  mutation OnboardingWebConferencingPageCreate(
    $input: CreateConferencingAccountInput!
  ) {
    account: createConferencingAccount(input: $input) {
      data {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingWebConferencingPageCreateMutationFn =
  Apollo.MutationFunction<
    OnboardingWebConferencingPageCreateMutation,
    OnboardingWebConferencingPageCreateMutationVariables
  >
export type OnboardingWebConferencingPageCreateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingWebConferencingPageCreateMutation,
    OnboardingWebConferencingPageCreateMutationVariables
  >,
  'mutation'
>

export const OnboardingWebConferencingPageCreateComponent = (
  props: OnboardingWebConferencingPageCreateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingWebConferencingPageCreateMutation,
    OnboardingWebConferencingPageCreateMutationVariables
  >
    mutation={OnboardingWebConferencingPageCreateDocument}
    {...props}
  />
)

/**
 * __useOnboardingWebConferencingPageCreateMutation__
 *
 * To run a mutation, you first call `useOnboardingWebConferencingPageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingWebConferencingPageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingWebConferencingPageCreateMutation, { data, loading, error }] = useOnboardingWebConferencingPageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingWebConferencingPageCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingWebConferencingPageCreateMutation,
    OnboardingWebConferencingPageCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingWebConferencingPageCreateMutation,
    OnboardingWebConferencingPageCreateMutationVariables
  >(OnboardingWebConferencingPageCreateDocument, options)
}
export type OnboardingWebConferencingPageCreateMutationHookResult = ReturnType<
  typeof useOnboardingWebConferencingPageCreateMutation
>
export type OnboardingWebConferencingPageCreateMutationResult =
  Apollo.MutationResult<OnboardingWebConferencingPageCreateMutation>
export type OnboardingWebConferencingPageCreateMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingWebConferencingPageCreateMutation,
    OnboardingWebConferencingPageCreateMutationVariables
  >
export const PaymentCollectionAccountIsConnectedDocument = gql`
  query PaymentCollectionAccountIsConnected($id: ID!) {
    account: getPaymentCollectionAccountById(id: $id) {
      id
      isConnected
    }
  }
`
export type PaymentCollectionAccountIsConnectedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PaymentCollectionAccountIsConnectedQuery,
    PaymentCollectionAccountIsConnectedQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: PaymentCollectionAccountIsConnectedQueryVariables
        skip?: boolean
      }
    | { skip: boolean }
  )

export const PaymentCollectionAccountIsConnectedComponent = (
  props: PaymentCollectionAccountIsConnectedComponentProps
) => (
  <ApolloReactComponents.Query<
    PaymentCollectionAccountIsConnectedQuery,
    PaymentCollectionAccountIsConnectedQueryVariables
  >
    query={PaymentCollectionAccountIsConnectedDocument}
    {...props}
  />
)

/**
 * __usePaymentCollectionAccountIsConnectedQuery__
 *
 * To run a query within a React component, call `usePaymentCollectionAccountIsConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentCollectionAccountIsConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentCollectionAccountIsConnectedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentCollectionAccountIsConnectedQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentCollectionAccountIsConnectedQuery,
    PaymentCollectionAccountIsConnectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PaymentCollectionAccountIsConnectedQuery,
    PaymentCollectionAccountIsConnectedQueryVariables
  >(PaymentCollectionAccountIsConnectedDocument, options)
}
export function usePaymentCollectionAccountIsConnectedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentCollectionAccountIsConnectedQuery,
    PaymentCollectionAccountIsConnectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PaymentCollectionAccountIsConnectedQuery,
    PaymentCollectionAccountIsConnectedQueryVariables
  >(PaymentCollectionAccountIsConnectedDocument, options)
}
export type PaymentCollectionAccountIsConnectedQueryHookResult = ReturnType<
  typeof usePaymentCollectionAccountIsConnectedQuery
>
export type PaymentCollectionAccountIsConnectedLazyQueryHookResult = ReturnType<
  typeof usePaymentCollectionAccountIsConnectedLazyQuery
>
export type PaymentCollectionAccountIsConnectedQueryResult = Apollo.QueryResult<
  PaymentCollectionAccountIsConnectedQuery,
  PaymentCollectionAccountIsConnectedQueryVariables
>
export const CreatePaymentCollectionAccountDocument = gql`
  mutation CreatePaymentCollectionAccount(
    $input: CreatePaymentCollectionAccountInput!
  ) {
    account: createPaymentCollectionAccount(input: $input) {
      data {
        createdAt
        deleted
        id
        name
        profile {
          id
        }
        provider {
          id
          name
          slug
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CreatePaymentCollectionAccountMutationFn = Apollo.MutationFunction<
  CreatePaymentCollectionAccountMutation,
  CreatePaymentCollectionAccountMutationVariables
>
export type CreatePaymentCollectionAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreatePaymentCollectionAccountMutation,
    CreatePaymentCollectionAccountMutationVariables
  >,
  'mutation'
>

export const CreatePaymentCollectionAccountComponent = (
  props: CreatePaymentCollectionAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreatePaymentCollectionAccountMutation,
    CreatePaymentCollectionAccountMutationVariables
  >
    mutation={CreatePaymentCollectionAccountDocument}
    {...props}
  />
)

/**
 * __useCreatePaymentCollectionAccountMutation__
 *
 * To run a mutation, you first call `useCreatePaymentCollectionAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentCollectionAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentCollectionAccountMutation, { data, loading, error }] = useCreatePaymentCollectionAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentCollectionAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentCollectionAccountMutation,
    CreatePaymentCollectionAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePaymentCollectionAccountMutation,
    CreatePaymentCollectionAccountMutationVariables
  >(CreatePaymentCollectionAccountDocument, options)
}
export type CreatePaymentCollectionAccountMutationHookResult = ReturnType<
  typeof useCreatePaymentCollectionAccountMutation
>
export type CreatePaymentCollectionAccountMutationResult =
  Apollo.MutationResult<CreatePaymentCollectionAccountMutation>
export type CreatePaymentCollectionAccountMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePaymentCollectionAccountMutation,
    CreatePaymentCollectionAccountMutationVariables
  >
export const ReconnectPaymentCollectionAccountDocument = gql`
  mutation ReconnectPaymentCollectionAccount(
    $input: UpdatePaymentCollectionAccountInput!
  ) {
    account: updatePaymentCollectionAccount(input: $input) {
      data {
        id
        isConnected
      }
      errors {
        field
        messages
      }
    }
  }
`
export type ReconnectPaymentCollectionAccountMutationFn =
  Apollo.MutationFunction<
    ReconnectPaymentCollectionAccountMutation,
    ReconnectPaymentCollectionAccountMutationVariables
  >
export type ReconnectPaymentCollectionAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReconnectPaymentCollectionAccountMutation,
    ReconnectPaymentCollectionAccountMutationVariables
  >,
  'mutation'
>

export const ReconnectPaymentCollectionAccountComponent = (
  props: ReconnectPaymentCollectionAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ReconnectPaymentCollectionAccountMutation,
    ReconnectPaymentCollectionAccountMutationVariables
  >
    mutation={ReconnectPaymentCollectionAccountDocument}
    {...props}
  />
)

/**
 * __useReconnectPaymentCollectionAccountMutation__
 *
 * To run a mutation, you first call `useReconnectPaymentCollectionAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconnectPaymentCollectionAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconnectPaymentCollectionAccountMutation, { data, loading, error }] = useReconnectPaymentCollectionAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReconnectPaymentCollectionAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReconnectPaymentCollectionAccountMutation,
    ReconnectPaymentCollectionAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReconnectPaymentCollectionAccountMutation,
    ReconnectPaymentCollectionAccountMutationVariables
  >(ReconnectPaymentCollectionAccountDocument, options)
}
export type ReconnectPaymentCollectionAccountMutationHookResult = ReturnType<
  typeof useReconnectPaymentCollectionAccountMutation
>
export type ReconnectPaymentCollectionAccountMutationResult =
  Apollo.MutationResult<ReconnectPaymentCollectionAccountMutation>
export type ReconnectPaymentCollectionAccountMutationOptions =
  Apollo.BaseMutationOptions<
    ReconnectPaymentCollectionAccountMutation,
    ReconnectPaymentCollectionAccountMutationVariables
  >
export const PremiumUpgradeModalDocument = gql`
  query PremiumUpgradeModal($profile: ID!) {
    paymentMethod: getDefaultPaymentSource(profile: $profile) {
      brand
      expiration
      id
      last4
    }
    premiumPlan: getTrialPlan {
      id
      name
      seatPriceMonthly
      seatPriceYearly
    }
  }
`
export type PremiumUpgradeModalComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PremiumUpgradeModalQuery,
    PremiumUpgradeModalQueryVariables
  >,
  'query'
> &
  (
    | { variables: PremiumUpgradeModalQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const PremiumUpgradeModalComponent = (
  props: PremiumUpgradeModalComponentProps
) => (
  <ApolloReactComponents.Query<
    PremiumUpgradeModalQuery,
    PremiumUpgradeModalQueryVariables
  >
    query={PremiumUpgradeModalDocument}
    {...props}
  />
)

/**
 * __usePremiumUpgradeModalQuery__
 *
 * To run a query within a React component, call `usePremiumUpgradeModalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePremiumUpgradeModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePremiumUpgradeModalQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function usePremiumUpgradeModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    PremiumUpgradeModalQuery,
    PremiumUpgradeModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PremiumUpgradeModalQuery,
    PremiumUpgradeModalQueryVariables
  >(PremiumUpgradeModalDocument, options)
}
export function usePremiumUpgradeModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PremiumUpgradeModalQuery,
    PremiumUpgradeModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PremiumUpgradeModalQuery,
    PremiumUpgradeModalQueryVariables
  >(PremiumUpgradeModalDocument, options)
}
export type PremiumUpgradeModalQueryHookResult = ReturnType<
  typeof usePremiumUpgradeModalQuery
>
export type PremiumUpgradeModalLazyQueryHookResult = ReturnType<
  typeof usePremiumUpgradeModalLazyQuery
>
export type PremiumUpgradeModalQueryResult = Apollo.QueryResult<
  PremiumUpgradeModalQuery,
  PremiumUpgradeModalQueryVariables
>
export const UpdatePlanProfileDocument = gql`
  mutation UpdatePlanProfile($input: UpdateProfilePlanInput!) {
    profile: updateProfilePlan(input: $input) {
      data {
        id
        plan {
          id
          name
          freeDefault
          requireAppointletBranding
          seatPriceMonthly
          seatPriceYearly
          trialDefault
          allowEmailCustomization
          allowFormFields
          allowManualConfirm
          allowPaymentCollection
          allowPrivateMeetingTypes
          allowRedirect
          allowReminders
          allowRoundRobin
          allowWebConferencing
          allowWebhooks
          allowUtmParameters
          allowZapier
        }
        billedAnnually
      }
    }
  }
`
export type UpdatePlanProfileMutationFn = Apollo.MutationFunction<
  UpdatePlanProfileMutation,
  UpdatePlanProfileMutationVariables
>
export type UpdatePlanProfileComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePlanProfileMutation,
    UpdatePlanProfileMutationVariables
  >,
  'mutation'
>

export const UpdatePlanProfileComponent = (
  props: UpdatePlanProfileComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdatePlanProfileMutation,
    UpdatePlanProfileMutationVariables
  >
    mutation={UpdatePlanProfileDocument}
    {...props}
  />
)

/**
 * __useUpdatePlanProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePlanProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanProfileMutation, { data, loading, error }] = useUpdatePlanProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlanProfileMutation,
    UpdatePlanProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePlanProfileMutation,
    UpdatePlanProfileMutationVariables
  >(UpdatePlanProfileDocument, options)
}
export type UpdatePlanProfileMutationHookResult = ReturnType<
  typeof useUpdatePlanProfileMutation
>
export type UpdatePlanProfileMutationResult =
  Apollo.MutationResult<UpdatePlanProfileMutation>
export type UpdatePlanProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlanProfileMutation,
  UpdatePlanProfileMutationVariables
>
export const RemoveMemberModalDocument = gql`
  mutation RemoveMemberModal($input: DeleteMemberInput!) {
    deleteMember(input: $input) {
      data {
        deleted
        id
      }
    }
  }
`
export type RemoveMemberModalMutationFn = Apollo.MutationFunction<
  RemoveMemberModalMutation,
  RemoveMemberModalMutationVariables
>
export type RemoveMemberModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveMemberModalMutation,
    RemoveMemberModalMutationVariables
  >,
  'mutation'
>

export const RemoveMemberModalComponent = (
  props: RemoveMemberModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RemoveMemberModalMutation,
    RemoveMemberModalMutationVariables
  >
    mutation={RemoveMemberModalDocument}
    {...props}
  />
)

/**
 * __useRemoveMemberModalMutation__
 *
 * To run a mutation, you first call `useRemoveMemberModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberModalMutation, { data, loading, error }] = useRemoveMemberModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMemberModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMemberModalMutation,
    RemoveMemberModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveMemberModalMutation,
    RemoveMemberModalMutationVariables
  >(RemoveMemberModalDocument, options)
}
export type RemoveMemberModalMutationHookResult = ReturnType<
  typeof useRemoveMemberModalMutation
>
export type RemoveMemberModalMutationResult =
  Apollo.MutationResult<RemoveMemberModalMutation>
export type RemoveMemberModalMutationOptions = Apollo.BaseMutationOptions<
  RemoveMemberModalMutation,
  RemoveMemberModalMutationVariables
>
export const RescheduleMeetingDatePickerDocument = gql`
  query RescheduleMeetingDatePicker(
    $end: DateTime
    $id: ID!
    $start: DateTime
  ) {
    availableTimes: getMeetingById(id: $id) {
      id
      reschedulableTimes(end: $end, start: $start) {
        data
      }
    }
  }
`
export type RescheduleMeetingDatePickerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RescheduleMeetingDatePickerQuery,
    RescheduleMeetingDatePickerQueryVariables
  >,
  'query'
> &
  (
    | { variables: RescheduleMeetingDatePickerQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const RescheduleMeetingDatePickerComponent = (
  props: RescheduleMeetingDatePickerComponentProps
) => (
  <ApolloReactComponents.Query<
    RescheduleMeetingDatePickerQuery,
    RescheduleMeetingDatePickerQueryVariables
  >
    query={RescheduleMeetingDatePickerDocument}
    {...props}
  />
)

/**
 * __useRescheduleMeetingDatePickerQuery__
 *
 * To run a query within a React component, call `useRescheduleMeetingDatePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useRescheduleMeetingDatePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRescheduleMeetingDatePickerQuery({
 *   variables: {
 *      end: // value for 'end'
 *      id: // value for 'id'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useRescheduleMeetingDatePickerQuery(
  baseOptions: Apollo.QueryHookOptions<
    RescheduleMeetingDatePickerQuery,
    RescheduleMeetingDatePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RescheduleMeetingDatePickerQuery,
    RescheduleMeetingDatePickerQueryVariables
  >(RescheduleMeetingDatePickerDocument, options)
}
export function useRescheduleMeetingDatePickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RescheduleMeetingDatePickerQuery,
    RescheduleMeetingDatePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RescheduleMeetingDatePickerQuery,
    RescheduleMeetingDatePickerQueryVariables
  >(RescheduleMeetingDatePickerDocument, options)
}
export type RescheduleMeetingDatePickerQueryHookResult = ReturnType<
  typeof useRescheduleMeetingDatePickerQuery
>
export type RescheduleMeetingDatePickerLazyQueryHookResult = ReturnType<
  typeof useRescheduleMeetingDatePickerLazyQuery
>
export type RescheduleMeetingDatePickerQueryResult = Apollo.QueryResult<
  RescheduleMeetingDatePickerQuery,
  RescheduleMeetingDatePickerQueryVariables
>
export const RescheduleMeetingModalDocument = gql`
  query RescheduleMeetingModal($end: DateTime, $id: ID!, $start: DateTime) {
    meeting: getMeetingById(id: $id) {
      end
      id
      reschedulableTimes(end: $end, start: $start) {
        data
      }
      start
    }
  }
`
export type RescheduleMeetingModalComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RescheduleMeetingModalQuery,
    RescheduleMeetingModalQueryVariables
  >,
  'query'
> &
  (
    | { variables: RescheduleMeetingModalQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const RescheduleMeetingModalComponent = (
  props: RescheduleMeetingModalComponentProps
) => (
  <ApolloReactComponents.Query<
    RescheduleMeetingModalQuery,
    RescheduleMeetingModalQueryVariables
  >
    query={RescheduleMeetingModalDocument}
    {...props}
  />
)

/**
 * __useRescheduleMeetingModalQuery__
 *
 * To run a query within a React component, call `useRescheduleMeetingModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useRescheduleMeetingModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRescheduleMeetingModalQuery({
 *   variables: {
 *      end: // value for 'end'
 *      id: // value for 'id'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useRescheduleMeetingModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    RescheduleMeetingModalQuery,
    RescheduleMeetingModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RescheduleMeetingModalQuery,
    RescheduleMeetingModalQueryVariables
  >(RescheduleMeetingModalDocument, options)
}
export function useRescheduleMeetingModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RescheduleMeetingModalQuery,
    RescheduleMeetingModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RescheduleMeetingModalQuery,
    RescheduleMeetingModalQueryVariables
  >(RescheduleMeetingModalDocument, options)
}
export type RescheduleMeetingModalQueryHookResult = ReturnType<
  typeof useRescheduleMeetingModalQuery
>
export type RescheduleMeetingModalLazyQueryHookResult = ReturnType<
  typeof useRescheduleMeetingModalLazyQuery
>
export type RescheduleMeetingModalQueryResult = Apollo.QueryResult<
  RescheduleMeetingModalQuery,
  RescheduleMeetingModalQueryVariables
>
export const SchedulingPagePickerDocument = gql`
  query SchedulingPagePicker($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            deleted
            id
            image
            name
          }
        }
      }
    }
  }
`
export type SchedulingPagePickerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SchedulingPagePickerQuery,
    SchedulingPagePickerQueryVariables
  >,
  'query'
> &
  (
    | { variables: SchedulingPagePickerQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const SchedulingPagePickerComponent = (
  props: SchedulingPagePickerComponentProps
) => (
  <ApolloReactComponents.Query<
    SchedulingPagePickerQuery,
    SchedulingPagePickerQueryVariables
  >
    query={SchedulingPagePickerDocument}
    {...props}
  />
)

/**
 * __useSchedulingPagePickerQuery__
 *
 * To run a query within a React component, call `useSchedulingPagePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulingPagePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulingPagePickerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchedulingPagePickerQuery(
  baseOptions: Apollo.QueryHookOptions<
    SchedulingPagePickerQuery,
    SchedulingPagePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SchedulingPagePickerQuery,
    SchedulingPagePickerQueryVariables
  >(SchedulingPagePickerDocument, options)
}
export function useSchedulingPagePickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchedulingPagePickerQuery,
    SchedulingPagePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchedulingPagePickerQuery,
    SchedulingPagePickerQueryVariables
  >(SchedulingPagePickerDocument, options)
}
export type SchedulingPagePickerQueryHookResult = ReturnType<
  typeof useSchedulingPagePickerQuery
>
export type SchedulingPagePickerLazyQueryHookResult = ReturnType<
  typeof useSchedulingPagePickerLazyQuery
>
export type SchedulingPagePickerQueryResult = Apollo.QueryResult<
  SchedulingPagePickerQuery,
  SchedulingPagePickerQueryVariables
>
export const SchedulingPagesPageDocument = gql`
  query SchedulingPagesPage($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            deleted
            id
          }
        }
      }
    }
  }
`
export type SchedulingPagesPageComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SchedulingPagesPageQuery,
    SchedulingPagesPageQueryVariables
  >,
  'query'
> &
  (
    | { variables: SchedulingPagesPageQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const SchedulingPagesPageComponent = (
  props: SchedulingPagesPageComponentProps
) => (
  <ApolloReactComponents.Query<
    SchedulingPagesPageQuery,
    SchedulingPagesPageQueryVariables
  >
    query={SchedulingPagesPageDocument}
    {...props}
  />
)

/**
 * __useSchedulingPagesPageQuery__
 *
 * To run a query within a React component, call `useSchedulingPagesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulingPagesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulingPagesPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSchedulingPagesPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    SchedulingPagesPageQuery,
    SchedulingPagesPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SchedulingPagesPageQuery,
    SchedulingPagesPageQueryVariables
  >(SchedulingPagesPageDocument, options)
}
export function useSchedulingPagesPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchedulingPagesPageQuery,
    SchedulingPagesPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SchedulingPagesPageQuery,
    SchedulingPagesPageQueryVariables
  >(SchedulingPagesPageDocument, options)
}
export type SchedulingPagesPageQueryHookResult = ReturnType<
  typeof useSchedulingPagesPageQuery
>
export type SchedulingPagesPageLazyQueryHookResult = ReturnType<
  typeof useSchedulingPagesPageLazyQuery
>
export type SchedulingPagesPageQueryResult = Apollo.QueryResult<
  SchedulingPagesPageQuery,
  SchedulingPagesPageQueryVariables
>
export const SchedulingPagesPageEmptyStateCreateMeetingTypeDocument = gql`
  mutation SchedulingPagesPageEmptyStateCreateMeetingType(
    $input1: CreateMeetingTypeInput!
    $input2: CreateMeetingTypeInput!
    $input3: CreateMeetingTypeInput!
  ) {
    meetingType1: createMeetingType(input: $input1) {
      data {
        id
        order
        name
        color
        duration
        bookingUrl
        slug
      }
      errors {
        field
        messages
      }
    }
    meetingType2: createMeetingType(input: $input2) {
      data {
        id
        order
        name
        color
        duration
        bookingUrl
        slug
      }
      errors {
        field
        messages
      }
    }
    meetingType3: createMeetingType(input: $input3) {
      data {
        id
        order
        name
        color
        duration
        bookingUrl
        slug
      }
      errors {
        field
        messages
      }
    }
  }
`
export type SchedulingPagesPageEmptyStateCreateMeetingTypeMutationFn =
  Apollo.MutationFunction<
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutation,
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutationVariables
  >
export type SchedulingPagesPageEmptyStateCreateMeetingTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutation,
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutationVariables
  >,
  'mutation'
>

export const SchedulingPagesPageEmptyStateCreateMeetingTypeComponent = (
  props: SchedulingPagesPageEmptyStateCreateMeetingTypeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutation,
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutationVariables
  >
    mutation={SchedulingPagesPageEmptyStateCreateMeetingTypeDocument}
    {...props}
  />
)

/**
 * __useSchedulingPagesPageEmptyStateCreateMeetingTypeMutation__
 *
 * To run a mutation, you first call `useSchedulingPagesPageEmptyStateCreateMeetingTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSchedulingPagesPageEmptyStateCreateMeetingTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [schedulingPagesPageEmptyStateCreateMeetingTypeMutation, { data, loading, error }] = useSchedulingPagesPageEmptyStateCreateMeetingTypeMutation({
 *   variables: {
 *      input1: // value for 'input1'
 *      input2: // value for 'input2'
 *      input3: // value for 'input3'
 *   },
 * });
 */
export function useSchedulingPagesPageEmptyStateCreateMeetingTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutation,
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutation,
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutationVariables
  >(SchedulingPagesPageEmptyStateCreateMeetingTypeDocument, options)
}
export type SchedulingPagesPageEmptyStateCreateMeetingTypeMutationHookResult =
  ReturnType<typeof useSchedulingPagesPageEmptyStateCreateMeetingTypeMutation>
export type SchedulingPagesPageEmptyStateCreateMeetingTypeMutationResult =
  Apollo.MutationResult<SchedulingPagesPageEmptyStateCreateMeetingTypeMutation>
export type SchedulingPagesPageEmptyStateCreateMeetingTypeMutationOptions =
  Apollo.BaseMutationOptions<
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutation,
    SchedulingPagesPageEmptyStateCreateMeetingTypeMutationVariables
  >
export const SettingsModalBillingSectionDocument = gql`
  query SettingsModalBillingSection($profile: ID!) {
    subscription: getSubscription(profile: $profile) {
      id
      paymentType
      markedForCancelation
      status
      cycleInterval
      cycleEnd
      trialEnd
      seats
    }
    paymentMethod: getDefaultPaymentSource(profile: $profile) {
      id
      brand
      last4
      expiration
    }
    profile: getProfileById(id: $profile) {
      id
      billedAnnually
      allocatedSeats
      occupiedSeats
      upcomingInvoiceAmount
      billingbotToken
      plan {
        ...PlanData
      }
    }
    plans: getPlans {
      edges {
        node {
          ...PlanData
        }
      }
    }
  }
  ${PlanDataFragmentDoc}
`
export type SettingsModalBillingSectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SettingsModalBillingSectionQuery,
    SettingsModalBillingSectionQueryVariables
  >,
  'query'
> &
  (
    | { variables: SettingsModalBillingSectionQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const SettingsModalBillingSectionComponent = (
  props: SettingsModalBillingSectionComponentProps
) => (
  <ApolloReactComponents.Query<
    SettingsModalBillingSectionQuery,
    SettingsModalBillingSectionQueryVariables
  >
    query={SettingsModalBillingSectionDocument}
    {...props}
  />
)

/**
 * __useSettingsModalBillingSectionQuery__
 *
 * To run a query within a React component, call `useSettingsModalBillingSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalBillingSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsModalBillingSectionQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useSettingsModalBillingSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    SettingsModalBillingSectionQuery,
    SettingsModalBillingSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SettingsModalBillingSectionQuery,
    SettingsModalBillingSectionQueryVariables
  >(SettingsModalBillingSectionDocument, options)
}
export function useSettingsModalBillingSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettingsModalBillingSectionQuery,
    SettingsModalBillingSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SettingsModalBillingSectionQuery,
    SettingsModalBillingSectionQueryVariables
  >(SettingsModalBillingSectionDocument, options)
}
export type SettingsModalBillingSectionQueryHookResult = ReturnType<
  typeof useSettingsModalBillingSectionQuery
>
export type SettingsModalBillingSectionLazyQueryHookResult = ReturnType<
  typeof useSettingsModalBillingSectionLazyQuery
>
export type SettingsModalBillingSectionQueryResult = Apollo.QueryResult<
  SettingsModalBillingSectionQuery,
  SettingsModalBillingSectionQueryVariables
>
export const SettingsModalCalendarSectionDocument = gql`
  query SettingsModalCalendarSection($id: ID!) {
    calendarProviders: getCalendarProviders {
      edges {
        node {
          id
          name
          oauth2AuthorizationUrl
          slug
          shortDescription
        }
      }
    }
    member: getMemberById(id: $id) {
      id
      calendarAccounts {
        edges {
          node {
            id
            name
            isConnected
            oauth2AuthorizationUrl
            provider {
              id
            }
          }
        }
      }
      meetingsCalendar {
        id
        name
        color
      }
      availabilityCalendars {
        edges {
          node {
            id
            name
            color
          }
        }
      }
    }
  }
`
export type SettingsModalCalendarSectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SettingsModalCalendarSectionQuery,
    SettingsModalCalendarSectionQueryVariables
  >,
  'query'
> &
  (
    | { variables: SettingsModalCalendarSectionQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const SettingsModalCalendarSectionComponent = (
  props: SettingsModalCalendarSectionComponentProps
) => (
  <ApolloReactComponents.Query<
    SettingsModalCalendarSectionQuery,
    SettingsModalCalendarSectionQueryVariables
  >
    query={SettingsModalCalendarSectionDocument}
    {...props}
  />
)

/**
 * __useSettingsModalCalendarSectionQuery__
 *
 * To run a query within a React component, call `useSettingsModalCalendarSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalCalendarSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsModalCalendarSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSettingsModalCalendarSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    SettingsModalCalendarSectionQuery,
    SettingsModalCalendarSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SettingsModalCalendarSectionQuery,
    SettingsModalCalendarSectionQueryVariables
  >(SettingsModalCalendarSectionDocument, options)
}
export function useSettingsModalCalendarSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettingsModalCalendarSectionQuery,
    SettingsModalCalendarSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SettingsModalCalendarSectionQuery,
    SettingsModalCalendarSectionQueryVariables
  >(SettingsModalCalendarSectionDocument, options)
}
export type SettingsModalCalendarSectionQueryHookResult = ReturnType<
  typeof useSettingsModalCalendarSectionQuery
>
export type SettingsModalCalendarSectionLazyQueryHookResult = ReturnType<
  typeof useSettingsModalCalendarSectionLazyQuery
>
export type SettingsModalCalendarSectionQueryResult = Apollo.QueryResult<
  SettingsModalCalendarSectionQuery,
  SettingsModalCalendarSectionQueryVariables
>
export const CreateCalendarAccountDocument = gql`
  mutation CreateCalendarAccount($input: CreateCalendarAccountInput!) {
    createCalendarAccount(input: $input) {
      data {
        id
        name
        isConnected
        oauth2AuthorizationUrl
        provider {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CreateCalendarAccountMutationFn = Apollo.MutationFunction<
  CreateCalendarAccountMutation,
  CreateCalendarAccountMutationVariables
>
export type CreateCalendarAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCalendarAccountMutation,
    CreateCalendarAccountMutationVariables
  >,
  'mutation'
>

export const CreateCalendarAccountComponent = (
  props: CreateCalendarAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateCalendarAccountMutation,
    CreateCalendarAccountMutationVariables
  >
    mutation={CreateCalendarAccountDocument}
    {...props}
  />
)

/**
 * __useCreateCalendarAccountMutation__
 *
 * To run a mutation, you first call `useCreateCalendarAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarAccountMutation, { data, loading, error }] = useCreateCalendarAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCalendarAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarAccountMutation,
    CreateCalendarAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateCalendarAccountMutation,
    CreateCalendarAccountMutationVariables
  >(CreateCalendarAccountDocument, options)
}
export type CreateCalendarAccountMutationHookResult = ReturnType<
  typeof useCreateCalendarAccountMutation
>
export type CreateCalendarAccountMutationResult =
  Apollo.MutationResult<CreateCalendarAccountMutation>
export type CreateCalendarAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarAccountMutation,
  CreateCalendarAccountMutationVariables
>
export const UpdateCalendarAccountDocument = gql`
  mutation UpdateCalendarAccount($input: UpdateCalendarAccountInput!) {
    updateCalendarAccount(input: $input) {
      data {
        id
        name
        isConnected
        oauth2AuthorizationUrl
        provider {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type UpdateCalendarAccountMutationFn = Apollo.MutationFunction<
  UpdateCalendarAccountMutation,
  UpdateCalendarAccountMutationVariables
>
export type UpdateCalendarAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCalendarAccountMutation,
    UpdateCalendarAccountMutationVariables
  >,
  'mutation'
>

export const UpdateCalendarAccountComponent = (
  props: UpdateCalendarAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCalendarAccountMutation,
    UpdateCalendarAccountMutationVariables
  >
    mutation={UpdateCalendarAccountDocument}
    {...props}
  />
)

/**
 * __useUpdateCalendarAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarAccountMutation, { data, loading, error }] = useUpdateCalendarAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCalendarAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCalendarAccountMutation,
    UpdateCalendarAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCalendarAccountMutation,
    UpdateCalendarAccountMutationVariables
  >(UpdateCalendarAccountDocument, options)
}
export type UpdateCalendarAccountMutationHookResult = ReturnType<
  typeof useUpdateCalendarAccountMutation
>
export type UpdateCalendarAccountMutationResult =
  Apollo.MutationResult<UpdateCalendarAccountMutation>
export type UpdateCalendarAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateCalendarAccountMutation,
  UpdateCalendarAccountMutationVariables
>
export const SettingsModalGeneralSectionDocument = gql`
  query SettingsModalGeneralSection($id: ID!) {
    profile: getProfileById(id: $id) {
      brandColor
      id
      image
      name
      showAppointletBranding
    }
  }
`
export type SettingsModalGeneralSectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SettingsModalGeneralSectionQuery,
    SettingsModalGeneralSectionQueryVariables
  >,
  'query'
> &
  (
    | { variables: SettingsModalGeneralSectionQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const SettingsModalGeneralSectionComponent = (
  props: SettingsModalGeneralSectionComponentProps
) => (
  <ApolloReactComponents.Query<
    SettingsModalGeneralSectionQuery,
    SettingsModalGeneralSectionQueryVariables
  >
    query={SettingsModalGeneralSectionDocument}
    {...props}
  />
)

/**
 * __useSettingsModalGeneralSectionQuery__
 *
 * To run a query within a React component, call `useSettingsModalGeneralSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalGeneralSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsModalGeneralSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSettingsModalGeneralSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    SettingsModalGeneralSectionQuery,
    SettingsModalGeneralSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SettingsModalGeneralSectionQuery,
    SettingsModalGeneralSectionQueryVariables
  >(SettingsModalGeneralSectionDocument, options)
}
export function useSettingsModalGeneralSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettingsModalGeneralSectionQuery,
    SettingsModalGeneralSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SettingsModalGeneralSectionQuery,
    SettingsModalGeneralSectionQueryVariables
  >(SettingsModalGeneralSectionDocument, options)
}
export type SettingsModalGeneralSectionQueryHookResult = ReturnType<
  typeof useSettingsModalGeneralSectionQuery
>
export type SettingsModalGeneralSectionLazyQueryHookResult = ReturnType<
  typeof useSettingsModalGeneralSectionLazyQuery
>
export type SettingsModalGeneralSectionQueryResult = Apollo.QueryResult<
  SettingsModalGeneralSectionQuery,
  SettingsModalGeneralSectionQueryVariables
>
export const SettingsModalGeneralSectionUpdateDocument = gql`
  mutation SettingsModalGeneralSectionUpdate($input: UpdateProfileInput!) {
    profile: updateProfile(input: $input) {
      data {
        brandColor
        id
        image
        name
        showAppointletBranding
      }
      errors {
        field
        messages
      }
    }
  }
`
export type SettingsModalGeneralSectionUpdateMutationFn =
  Apollo.MutationFunction<
    SettingsModalGeneralSectionUpdateMutation,
    SettingsModalGeneralSectionUpdateMutationVariables
  >
export type SettingsModalGeneralSectionUpdateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SettingsModalGeneralSectionUpdateMutation,
    SettingsModalGeneralSectionUpdateMutationVariables
  >,
  'mutation'
>

export const SettingsModalGeneralSectionUpdateComponent = (
  props: SettingsModalGeneralSectionUpdateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SettingsModalGeneralSectionUpdateMutation,
    SettingsModalGeneralSectionUpdateMutationVariables
  >
    mutation={SettingsModalGeneralSectionUpdateDocument}
    {...props}
  />
)

/**
 * __useSettingsModalGeneralSectionUpdateMutation__
 *
 * To run a mutation, you first call `useSettingsModalGeneralSectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalGeneralSectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsModalGeneralSectionUpdateMutation, { data, loading, error }] = useSettingsModalGeneralSectionUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSettingsModalGeneralSectionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsModalGeneralSectionUpdateMutation,
    SettingsModalGeneralSectionUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SettingsModalGeneralSectionUpdateMutation,
    SettingsModalGeneralSectionUpdateMutationVariables
  >(SettingsModalGeneralSectionUpdateDocument, options)
}
export type SettingsModalGeneralSectionUpdateMutationHookResult = ReturnType<
  typeof useSettingsModalGeneralSectionUpdateMutation
>
export type SettingsModalGeneralSectionUpdateMutationResult =
  Apollo.MutationResult<SettingsModalGeneralSectionUpdateMutation>
export type SettingsModalGeneralSectionUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsModalGeneralSectionUpdateMutation,
    SettingsModalGeneralSectionUpdateMutationVariables
  >
export const SettingsModalLoginSectionDocument = gql`
  query SettingsModalLoginSection {
    user {
      id
      passwordUpdatedAt
    }
  }
`
export type SettingsModalLoginSectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SettingsModalLoginSectionQuery,
    SettingsModalLoginSectionQueryVariables
  >,
  'query'
>

export const SettingsModalLoginSectionComponent = (
  props: SettingsModalLoginSectionComponentProps
) => (
  <ApolloReactComponents.Query<
    SettingsModalLoginSectionQuery,
    SettingsModalLoginSectionQueryVariables
  >
    query={SettingsModalLoginSectionDocument}
    {...props}
  />
)

/**
 * __useSettingsModalLoginSectionQuery__
 *
 * To run a query within a React component, call `useSettingsModalLoginSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalLoginSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsModalLoginSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsModalLoginSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SettingsModalLoginSectionQuery,
    SettingsModalLoginSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SettingsModalLoginSectionQuery,
    SettingsModalLoginSectionQueryVariables
  >(SettingsModalLoginSectionDocument, options)
}
export function useSettingsModalLoginSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettingsModalLoginSectionQuery,
    SettingsModalLoginSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SettingsModalLoginSectionQuery,
    SettingsModalLoginSectionQueryVariables
  >(SettingsModalLoginSectionDocument, options)
}
export type SettingsModalLoginSectionQueryHookResult = ReturnType<
  typeof useSettingsModalLoginSectionQuery
>
export type SettingsModalLoginSectionLazyQueryHookResult = ReturnType<
  typeof useSettingsModalLoginSectionLazyQuery
>
export type SettingsModalLoginSectionQueryResult = Apollo.QueryResult<
  SettingsModalLoginSectionQuery,
  SettingsModalLoginSectionQueryVariables
>
export const SettingsModalPaymentSectionDocument = gql`
  query SettingsModalPaymentSection($profile: ID!) {
    profile: getProfileById(id: $profile) {
      id
      paymentCollectionAccounts {
        edges {
          node {
            deleted
            id
            name
            provider {
              id
              name
              oauth2AuthorizationUrl(profile: $profile)
              slug
            }
          }
        }
      }
    }
    providers: getPaymentCollectionProviders {
      edges {
        node {
          id
          name
          oauth2AuthorizationUrl(profile: $profile)
          slug
        }
      }
    }
  }
`
export type SettingsModalPaymentSectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SettingsModalPaymentSectionQuery,
    SettingsModalPaymentSectionQueryVariables
  >,
  'query'
> &
  (
    | { variables: SettingsModalPaymentSectionQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const SettingsModalPaymentSectionComponent = (
  props: SettingsModalPaymentSectionComponentProps
) => (
  <ApolloReactComponents.Query<
    SettingsModalPaymentSectionQuery,
    SettingsModalPaymentSectionQueryVariables
  >
    query={SettingsModalPaymentSectionDocument}
    {...props}
  />
)

/**
 * __useSettingsModalPaymentSectionQuery__
 *
 * To run a query within a React component, call `useSettingsModalPaymentSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalPaymentSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsModalPaymentSectionQuery({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useSettingsModalPaymentSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    SettingsModalPaymentSectionQuery,
    SettingsModalPaymentSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SettingsModalPaymentSectionQuery,
    SettingsModalPaymentSectionQueryVariables
  >(SettingsModalPaymentSectionDocument, options)
}
export function useSettingsModalPaymentSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettingsModalPaymentSectionQuery,
    SettingsModalPaymentSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SettingsModalPaymentSectionQuery,
    SettingsModalPaymentSectionQueryVariables
  >(SettingsModalPaymentSectionDocument, options)
}
export type SettingsModalPaymentSectionQueryHookResult = ReturnType<
  typeof useSettingsModalPaymentSectionQuery
>
export type SettingsModalPaymentSectionLazyQueryHookResult = ReturnType<
  typeof useSettingsModalPaymentSectionLazyQuery
>
export type SettingsModalPaymentSectionQueryResult = Apollo.QueryResult<
  SettingsModalPaymentSectionQuery,
  SettingsModalPaymentSectionQueryVariables
>
export const SettingsModalProfileSectionDocument = gql`
  query SettingsModalProfileSection {
    user {
      id
      email
      firstName
      language
      lastName
      image
      timezone
      members {
        edges {
          node {
            id
            meetingCount
            profile {
              id
              members {
                edges {
                  node {
                    id
                    user {
                      email
                      id
                      image
                      firstName
                      lastName
                    }
                  }
                }
              }
              name
              personal
            }
            role {
              canManageBilling
              canManageMeetings
              canManageOrgSettings
              canManageMeetingTypes
            }
          }
        }
      }
    }
  }
`
export type SettingsModalProfileSectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SettingsModalProfileSectionQuery,
    SettingsModalProfileSectionQueryVariables
  >,
  'query'
>

export const SettingsModalProfileSectionComponent = (
  props: SettingsModalProfileSectionComponentProps
) => (
  <ApolloReactComponents.Query<
    SettingsModalProfileSectionQuery,
    SettingsModalProfileSectionQueryVariables
  >
    query={SettingsModalProfileSectionDocument}
    {...props}
  />
)

/**
 * __useSettingsModalProfileSectionQuery__
 *
 * To run a query within a React component, call `useSettingsModalProfileSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalProfileSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsModalProfileSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsModalProfileSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SettingsModalProfileSectionQuery,
    SettingsModalProfileSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SettingsModalProfileSectionQuery,
    SettingsModalProfileSectionQueryVariables
  >(SettingsModalProfileSectionDocument, options)
}
export function useSettingsModalProfileSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettingsModalProfileSectionQuery,
    SettingsModalProfileSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SettingsModalProfileSectionQuery,
    SettingsModalProfileSectionQueryVariables
  >(SettingsModalProfileSectionDocument, options)
}
export type SettingsModalProfileSectionQueryHookResult = ReturnType<
  typeof useSettingsModalProfileSectionQuery
>
export type SettingsModalProfileSectionLazyQueryHookResult = ReturnType<
  typeof useSettingsModalProfileSectionLazyQuery
>
export type SettingsModalProfileSectionQueryResult = Apollo.QueryResult<
  SettingsModalProfileSectionQuery,
  SettingsModalProfileSectionQueryVariables
>
export const SettingsModalProfileSectionUpdateDocument = gql`
  mutation SettingsModalProfileSectionUpdate($input: UpdateUserInput!) {
    updateUser(input: $input) {
      data {
        createdAt
        firstName
        email
        id
        image
        language
        lastName
        timezone
      }
      errors {
        field
        messages
      }
    }
  }
`
export type SettingsModalProfileSectionUpdateMutationFn =
  Apollo.MutationFunction<
    SettingsModalProfileSectionUpdateMutation,
    SettingsModalProfileSectionUpdateMutationVariables
  >
export type SettingsModalProfileSectionUpdateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SettingsModalProfileSectionUpdateMutation,
    SettingsModalProfileSectionUpdateMutationVariables
  >,
  'mutation'
>

export const SettingsModalProfileSectionUpdateComponent = (
  props: SettingsModalProfileSectionUpdateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SettingsModalProfileSectionUpdateMutation,
    SettingsModalProfileSectionUpdateMutationVariables
  >
    mutation={SettingsModalProfileSectionUpdateDocument}
    {...props}
  />
)

/**
 * __useSettingsModalProfileSectionUpdateMutation__
 *
 * To run a mutation, you first call `useSettingsModalProfileSectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalProfileSectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsModalProfileSectionUpdateMutation, { data, loading, error }] = useSettingsModalProfileSectionUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSettingsModalProfileSectionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsModalProfileSectionUpdateMutation,
    SettingsModalProfileSectionUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SettingsModalProfileSectionUpdateMutation,
    SettingsModalProfileSectionUpdateMutationVariables
  >(SettingsModalProfileSectionUpdateDocument, options)
}
export type SettingsModalProfileSectionUpdateMutationHookResult = ReturnType<
  typeof useSettingsModalProfileSectionUpdateMutation
>
export type SettingsModalProfileSectionUpdateMutationResult =
  Apollo.MutationResult<SettingsModalProfileSectionUpdateMutation>
export type SettingsModalProfileSectionUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsModalProfileSectionUpdateMutation,
    SettingsModalProfileSectionUpdateMutationVariables
  >
export const SettingsModalUsersSectionDocument = gql`
  query SettingsModalUsersSection(
    $after: String
    $before: String
    $first: Int
    $id: ID!
    $isDeleted: Boolean
    $last: Int
    $search: String
  ) {
    profile: getProfileById(id: $id) {
      id
      roles {
        edges {
          node {
            default
            id
            name
          }
        }
      }
      members(
        after: $after
        before: $before
        first: $first
        isDeleted: $isDeleted
        last: $last
        search: $search
      ) {
        edges {
          node {
            deleted
            id
            invitationAccepted
            isOwner
            isSchedulable
            role {
              id
              name
            }
            user {
              email
              firstName
              id
              image
              lastName
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`
export type SettingsModalUsersSectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SettingsModalUsersSectionQuery,
    SettingsModalUsersSectionQueryVariables
  >,
  'query'
> &
  (
    | { variables: SettingsModalUsersSectionQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const SettingsModalUsersSectionComponent = (
  props: SettingsModalUsersSectionComponentProps
) => (
  <ApolloReactComponents.Query<
    SettingsModalUsersSectionQuery,
    SettingsModalUsersSectionQueryVariables
  >
    query={SettingsModalUsersSectionDocument}
    {...props}
  />
)

/**
 * __useSettingsModalUsersSectionQuery__
 *
 * To run a query within a React component, call `useSettingsModalUsersSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalUsersSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsModalUsersSectionQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      id: // value for 'id'
 *      isDeleted: // value for 'isDeleted'
 *      last: // value for 'last'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSettingsModalUsersSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    SettingsModalUsersSectionQuery,
    SettingsModalUsersSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SettingsModalUsersSectionQuery,
    SettingsModalUsersSectionQueryVariables
  >(SettingsModalUsersSectionDocument, options)
}
export function useSettingsModalUsersSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettingsModalUsersSectionQuery,
    SettingsModalUsersSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SettingsModalUsersSectionQuery,
    SettingsModalUsersSectionQueryVariables
  >(SettingsModalUsersSectionDocument, options)
}
export type SettingsModalUsersSectionQueryHookResult = ReturnType<
  typeof useSettingsModalUsersSectionQuery
>
export type SettingsModalUsersSectionLazyQueryHookResult = ReturnType<
  typeof useSettingsModalUsersSectionLazyQuery
>
export type SettingsModalUsersSectionQueryResult = Apollo.QueryResult<
  SettingsModalUsersSectionQuery,
  SettingsModalUsersSectionQueryVariables
>
export const UpdateMemberRoleDocument = gql`
  mutation UpdateMemberRole($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      data {
        id
        role {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type UpdateMemberRoleMutationFn = Apollo.MutationFunction<
  UpdateMemberRoleMutation,
  UpdateMemberRoleMutationVariables
>
export type UpdateMemberRoleComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMemberRoleMutation,
    UpdateMemberRoleMutationVariables
  >,
  'mutation'
>

export const UpdateMemberRoleComponent = (
  props: UpdateMemberRoleComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateMemberRoleMutation,
    UpdateMemberRoleMutationVariables
  >
    mutation={UpdateMemberRoleDocument}
    {...props}
  />
)

/**
 * __useUpdateMemberRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberRoleMutation, { data, loading, error }] = useUpdateMemberRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberRoleMutation,
    UpdateMemberRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMemberRoleMutation,
    UpdateMemberRoleMutationVariables
  >(UpdateMemberRoleDocument, options)
}
export type UpdateMemberRoleMutationHookResult = ReturnType<
  typeof useUpdateMemberRoleMutation
>
export type UpdateMemberRoleMutationResult =
  Apollo.MutationResult<UpdateMemberRoleMutation>
export type UpdateMemberRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateMemberRoleMutation,
  UpdateMemberRoleMutationVariables
>
export const ResendMemberInviteDocument = gql`
  mutation ResendMemberInvite($input: ResendMemberInvitationInput!) {
    resendMemberInvitation(input: $input) {
      data {
        id
      }
    }
  }
`
export type ResendMemberInviteMutationFn = Apollo.MutationFunction<
  ResendMemberInviteMutation,
  ResendMemberInviteMutationVariables
>
export type ResendMemberInviteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ResendMemberInviteMutation,
    ResendMemberInviteMutationVariables
  >,
  'mutation'
>

export const ResendMemberInviteComponent = (
  props: ResendMemberInviteComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ResendMemberInviteMutation,
    ResendMemberInviteMutationVariables
  >
    mutation={ResendMemberInviteDocument}
    {...props}
  />
)

/**
 * __useResendMemberInviteMutation__
 *
 * To run a mutation, you first call `useResendMemberInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendMemberInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendMemberInviteMutation, { data, loading, error }] = useResendMemberInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendMemberInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendMemberInviteMutation,
    ResendMemberInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResendMemberInviteMutation,
    ResendMemberInviteMutationVariables
  >(ResendMemberInviteDocument, options)
}
export type ResendMemberInviteMutationHookResult = ReturnType<
  typeof useResendMemberInviteMutation
>
export type ResendMemberInviteMutationResult =
  Apollo.MutationResult<ResendMemberInviteMutation>
export type ResendMemberInviteMutationOptions = Apollo.BaseMutationOptions<
  ResendMemberInviteMutation,
  ResendMemberInviteMutationVariables
>
export const SettingsModalWebConferencingSectionDocument = gql`
  query SettingsModalWebConferencingSection($id: ID!) {
    member: getMemberById(id: $id) {
      id
      conferencingAccounts {
        edges {
          node {
            __typename
            deleted
            id
            isDefault
            name
            provider {
              id
              name
              slug
              oauth2AuthorizationUrl
            }
          }
        }
      }
    }
    providers: getConferenceProviders {
      edges {
        node {
          id
          name
          oauth2AuthorizationUrl
          slug
        }
      }
    }
  }
`
export type SettingsModalWebConferencingSectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SettingsModalWebConferencingSectionQuery,
    SettingsModalWebConferencingSectionQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: SettingsModalWebConferencingSectionQueryVariables
        skip?: boolean
      }
    | { skip: boolean }
  )

export const SettingsModalWebConferencingSectionComponent = (
  props: SettingsModalWebConferencingSectionComponentProps
) => (
  <ApolloReactComponents.Query<
    SettingsModalWebConferencingSectionQuery,
    SettingsModalWebConferencingSectionQueryVariables
  >
    query={SettingsModalWebConferencingSectionDocument}
    {...props}
  />
)

/**
 * __useSettingsModalWebConferencingSectionQuery__
 *
 * To run a query within a React component, call `useSettingsModalWebConferencingSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalWebConferencingSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsModalWebConferencingSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSettingsModalWebConferencingSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    SettingsModalWebConferencingSectionQuery,
    SettingsModalWebConferencingSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SettingsModalWebConferencingSectionQuery,
    SettingsModalWebConferencingSectionQueryVariables
  >(SettingsModalWebConferencingSectionDocument, options)
}
export function useSettingsModalWebConferencingSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettingsModalWebConferencingSectionQuery,
    SettingsModalWebConferencingSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SettingsModalWebConferencingSectionQuery,
    SettingsModalWebConferencingSectionQueryVariables
  >(SettingsModalWebConferencingSectionDocument, options)
}
export type SettingsModalWebConferencingSectionQueryHookResult = ReturnType<
  typeof useSettingsModalWebConferencingSectionQuery
>
export type SettingsModalWebConferencingSectionLazyQueryHookResult = ReturnType<
  typeof useSettingsModalWebConferencingSectionLazyQuery
>
export type SettingsModalWebConferencingSectionQueryResult = Apollo.QueryResult<
  SettingsModalWebConferencingSectionQuery,
  SettingsModalWebConferencingSectionQueryVariables
>
export const SettingsModalWebhooksSectionDocument = gql`
  query SettingsModalWebhooksSection($id: ID!) {
    profile: getProfileById(id: $id) {
      id
      webhooks {
        edges {
          node {
            active
            createdAt
            deleted
            id
            member {
              id
              user {
                firstName
                id
                lastName
              }
            }
            name
            url
          }
        }
      }
    }
  }
`
export type SettingsModalWebhooksSectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SettingsModalWebhooksSectionQuery,
    SettingsModalWebhooksSectionQueryVariables
  >,
  'query'
> &
  (
    | { variables: SettingsModalWebhooksSectionQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const SettingsModalWebhooksSectionComponent = (
  props: SettingsModalWebhooksSectionComponentProps
) => (
  <ApolloReactComponents.Query<
    SettingsModalWebhooksSectionQuery,
    SettingsModalWebhooksSectionQueryVariables
  >
    query={SettingsModalWebhooksSectionDocument}
    {...props}
  />
)

/**
 * __useSettingsModalWebhooksSectionQuery__
 *
 * To run a query within a React component, call `useSettingsModalWebhooksSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalWebhooksSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsModalWebhooksSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSettingsModalWebhooksSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    SettingsModalWebhooksSectionQuery,
    SettingsModalWebhooksSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SettingsModalWebhooksSectionQuery,
    SettingsModalWebhooksSectionQueryVariables
  >(SettingsModalWebhooksSectionDocument, options)
}
export function useSettingsModalWebhooksSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SettingsModalWebhooksSectionQuery,
    SettingsModalWebhooksSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SettingsModalWebhooksSectionQuery,
    SettingsModalWebhooksSectionQueryVariables
  >(SettingsModalWebhooksSectionDocument, options)
}
export type SettingsModalWebhooksSectionQueryHookResult = ReturnType<
  typeof useSettingsModalWebhooksSectionQuery
>
export type SettingsModalWebhooksSectionLazyQueryHookResult = ReturnType<
  typeof useSettingsModalWebhooksSectionLazyQuery
>
export type SettingsModalWebhooksSectionQueryResult = Apollo.QueryResult<
  SettingsModalWebhooksSectionQuery,
  SettingsModalWebhooksSectionQueryVariables
>
export const SettingsModalWebhookDeleteDocument = gql`
  mutation SettingsModalWebhookDelete($input: DeleteWebhookInput!) {
    webHook: deleteWebhook(input: $input) {
      data {
        deleted
        id
      }
    }
  }
`
export type SettingsModalWebhookDeleteMutationFn = Apollo.MutationFunction<
  SettingsModalWebhookDeleteMutation,
  SettingsModalWebhookDeleteMutationVariables
>
export type SettingsModalWebhookDeleteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SettingsModalWebhookDeleteMutation,
    SettingsModalWebhookDeleteMutationVariables
  >,
  'mutation'
>

export const SettingsModalWebhookDeleteComponent = (
  props: SettingsModalWebhookDeleteComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SettingsModalWebhookDeleteMutation,
    SettingsModalWebhookDeleteMutationVariables
  >
    mutation={SettingsModalWebhookDeleteDocument}
    {...props}
  />
)

/**
 * __useSettingsModalWebhookDeleteMutation__
 *
 * To run a mutation, you first call `useSettingsModalWebhookDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsModalWebhookDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsModalWebhookDeleteMutation, { data, loading, error }] = useSettingsModalWebhookDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSettingsModalWebhookDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsModalWebhookDeleteMutation,
    SettingsModalWebhookDeleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SettingsModalWebhookDeleteMutation,
    SettingsModalWebhookDeleteMutationVariables
  >(SettingsModalWebhookDeleteDocument, options)
}
export type SettingsModalWebhookDeleteMutationHookResult = ReturnType<
  typeof useSettingsModalWebhookDeleteMutation
>
export type SettingsModalWebhookDeleteMutationResult =
  Apollo.MutationResult<SettingsModalWebhookDeleteMutation>
export type SettingsModalWebhookDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsModalWebhookDeleteMutation,
    SettingsModalWebhookDeleteMutationVariables
  >
export const MoveMeetingTypeDocument = gql`
  mutation MoveMeetingType($input: MoveMeetingTypeInput!) {
    moveMeetingType(input: $input) {
      data {
        id
        order
      }
    }
  }
`
export type MoveMeetingTypeMutationFn = Apollo.MutationFunction<
  MoveMeetingTypeMutation,
  MoveMeetingTypeMutationVariables
>
export type MoveMeetingTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MoveMeetingTypeMutation,
    MoveMeetingTypeMutationVariables
  >,
  'mutation'
>

export const MoveMeetingTypeComponent = (
  props: MoveMeetingTypeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    MoveMeetingTypeMutation,
    MoveMeetingTypeMutationVariables
  >
    mutation={MoveMeetingTypeDocument}
    {...props}
  />
)

/**
 * __useMoveMeetingTypeMutation__
 *
 * To run a mutation, you first call `useMoveMeetingTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveMeetingTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveMeetingTypeMutation, { data, loading, error }] = useMoveMeetingTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveMeetingTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveMeetingTypeMutation,
    MoveMeetingTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MoveMeetingTypeMutation,
    MoveMeetingTypeMutationVariables
  >(MoveMeetingTypeDocument, options)
}
export type MoveMeetingTypeMutationHookResult = ReturnType<
  typeof useMoveMeetingTypeMutation
>
export type MoveMeetingTypeMutationResult =
  Apollo.MutationResult<MoveMeetingTypeMutation>
export type MoveMeetingTypeMutationOptions = Apollo.BaseMutationOptions<
  MoveMeetingTypeMutation,
  MoveMeetingTypeMutationVariables
>
export const TimeZonePickerDocument = gql`
  query TimeZonePicker {
    timeZones: __type(name: "Timezone") {
      enumValues {
        name
        description
      }
    }
  }
`
export type TimeZonePickerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TimeZonePickerQuery,
    TimeZonePickerQueryVariables
  >,
  'query'
>

export const TimeZonePickerComponent = (
  props: TimeZonePickerComponentProps
) => (
  <ApolloReactComponents.Query<
    TimeZonePickerQuery,
    TimeZonePickerQueryVariables
  >
    query={TimeZonePickerDocument}
    {...props}
  />
)

/**
 * __useTimeZonePickerQuery__
 *
 * To run a query within a React component, call `useTimeZonePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeZonePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeZonePickerQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeZonePickerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TimeZonePickerQuery,
    TimeZonePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TimeZonePickerQuery, TimeZonePickerQueryVariables>(
    TimeZonePickerDocument,
    options
  )
}
export function useTimeZonePickerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimeZonePickerQuery,
    TimeZonePickerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TimeZonePickerQuery, TimeZonePickerQueryVariables>(
    TimeZonePickerDocument,
    options
  )
}
export type TimeZonePickerQueryHookResult = ReturnType<
  typeof useTimeZonePickerQuery
>
export type TimeZonePickerLazyQueryHookResult = ReturnType<
  typeof useTimeZonePickerLazyQuery
>
export type TimeZonePickerQueryResult = Apollo.QueryResult<
  TimeZonePickerQuery,
  TimeZonePickerQueryVariables
>
export const RolePickerChangeOwnerDocument = gql`
  mutation RolePickerChangeOwner($input: SetProfileOwnerInput!) {
    profile: setProfileOwner(input: $input) {
      data {
        id
      }
    }
  }
`
export type RolePickerChangeOwnerMutationFn = Apollo.MutationFunction<
  RolePickerChangeOwnerMutation,
  RolePickerChangeOwnerMutationVariables
>
export type RolePickerChangeOwnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RolePickerChangeOwnerMutation,
    RolePickerChangeOwnerMutationVariables
  >,
  'mutation'
>

export const RolePickerChangeOwnerComponent = (
  props: RolePickerChangeOwnerComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RolePickerChangeOwnerMutation,
    RolePickerChangeOwnerMutationVariables
  >
    mutation={RolePickerChangeOwnerDocument}
    {...props}
  />
)

/**
 * __useRolePickerChangeOwnerMutation__
 *
 * To run a mutation, you first call `useRolePickerChangeOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRolePickerChangeOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rolePickerChangeOwnerMutation, { data, loading, error }] = useRolePickerChangeOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRolePickerChangeOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RolePickerChangeOwnerMutation,
    RolePickerChangeOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RolePickerChangeOwnerMutation,
    RolePickerChangeOwnerMutationVariables
  >(RolePickerChangeOwnerDocument, options)
}
export type RolePickerChangeOwnerMutationHookResult = ReturnType<
  typeof useRolePickerChangeOwnerMutation
>
export type RolePickerChangeOwnerMutationResult =
  Apollo.MutationResult<RolePickerChangeOwnerMutation>
export type RolePickerChangeOwnerMutationOptions = Apollo.BaseMutationOptions<
  RolePickerChangeOwnerMutation,
  RolePickerChangeOwnerMutationVariables
>
export const UpdateAttendeeDocument = gql`
  mutation UpdateAttendee($input: UpdateAttendeeInput!) {
    updatedAttendee: updateAttendee(input: $input) {
      data {
        id
        firstName
        lastName
        email
        timezone
        fieldSubmissions {
          edges {
            node {
              id
              fieldType
              slug
              order
              required
              visible
              name
              helpText
              value
              choices
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type UpdateAttendeeMutationFn = Apollo.MutationFunction<
  UpdateAttendeeMutation,
  UpdateAttendeeMutationVariables
>
export type UpdateAttendeeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateAttendeeMutation,
    UpdateAttendeeMutationVariables
  >,
  'mutation'
>

export const UpdateAttendeeComponent = (
  props: UpdateAttendeeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateAttendeeMutation,
    UpdateAttendeeMutationVariables
  >
    mutation={UpdateAttendeeDocument}
    {...props}
  />
)

/**
 * __useUpdateAttendeeMutation__
 *
 * To run a mutation, you first call `useUpdateAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttendeeMutation, { data, loading, error }] = useUpdateAttendeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAttendeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAttendeeMutation,
    UpdateAttendeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAttendeeMutation,
    UpdateAttendeeMutationVariables
  >(UpdateAttendeeDocument, options)
}
export type UpdateAttendeeMutationHookResult = ReturnType<
  typeof useUpdateAttendeeMutation
>
export type UpdateAttendeeMutationResult =
  Apollo.MutationResult<UpdateAttendeeMutation>
export type UpdateAttendeeMutationOptions = Apollo.BaseMutationOptions<
  UpdateAttendeeMutation,
  UpdateAttendeeMutationVariables
>
export const UpdateAttendeeModalDocument = gql`
  query UpdateAttendeeModal($id: ID!) {
    attendee: getAttendeeById(id: $id) {
      id
      email
      firstName
      lastName
      timezone
      fieldSubmissions {
        edges {
          node {
            id
            fieldType
            slug
            order
            required
            visible
            name
            helpText
            value
            choices
          }
        }
      }
    }
  }
`
export type UpdateAttendeeModalComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    UpdateAttendeeModalQuery,
    UpdateAttendeeModalQueryVariables
  >,
  'query'
> &
  (
    | { variables: UpdateAttendeeModalQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const UpdateAttendeeModalComponent = (
  props: UpdateAttendeeModalComponentProps
) => (
  <ApolloReactComponents.Query<
    UpdateAttendeeModalQuery,
    UpdateAttendeeModalQueryVariables
  >
    query={UpdateAttendeeModalDocument}
    {...props}
  />
)

/**
 * __useUpdateAttendeeModalQuery__
 *
 * To run a query within a React component, call `useUpdateAttendeeModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendeeModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateAttendeeModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateAttendeeModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateAttendeeModalQuery,
    UpdateAttendeeModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UpdateAttendeeModalQuery,
    UpdateAttendeeModalQueryVariables
  >(UpdateAttendeeModalDocument, options)
}
export function useUpdateAttendeeModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateAttendeeModalQuery,
    UpdateAttendeeModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UpdateAttendeeModalQuery,
    UpdateAttendeeModalQueryVariables
  >(UpdateAttendeeModalDocument, options)
}
export type UpdateAttendeeModalQueryHookResult = ReturnType<
  typeof useUpdateAttendeeModalQuery
>
export type UpdateAttendeeModalLazyQueryHookResult = ReturnType<
  typeof useUpdateAttendeeModalLazyQuery
>
export type UpdateAttendeeModalQueryResult = Apollo.QueryResult<
  UpdateAttendeeModalQuery,
  UpdateAttendeeModalQueryVariables
>
export const UpdateAvailabilityCalendarsModalDocument = gql`
  mutation UpdateAvailabilityCalendarsModal(
    $input: SetCalendarTransparencyInput!
  ) {
    setCalendarTransparency(input: $input) {
      data {
        id
        transparent
      }
    }
  }
`
export type UpdateAvailabilityCalendarsModalMutationFn =
  Apollo.MutationFunction<
    UpdateAvailabilityCalendarsModalMutation,
    UpdateAvailabilityCalendarsModalMutationVariables
  >
export type UpdateAvailabilityCalendarsModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateAvailabilityCalendarsModalMutation,
    UpdateAvailabilityCalendarsModalMutationVariables
  >,
  'mutation'
>

export const UpdateAvailabilityCalendarsModalComponent = (
  props: UpdateAvailabilityCalendarsModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateAvailabilityCalendarsModalMutation,
    UpdateAvailabilityCalendarsModalMutationVariables
  >
    mutation={UpdateAvailabilityCalendarsModalDocument}
    {...props}
  />
)

/**
 * __useUpdateAvailabilityCalendarsModalMutation__
 *
 * To run a mutation, you first call `useUpdateAvailabilityCalendarsModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvailabilityCalendarsModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvailabilityCalendarsModalMutation, { data, loading, error }] = useUpdateAvailabilityCalendarsModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAvailabilityCalendarsModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAvailabilityCalendarsModalMutation,
    UpdateAvailabilityCalendarsModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateAvailabilityCalendarsModalMutation,
    UpdateAvailabilityCalendarsModalMutationVariables
  >(UpdateAvailabilityCalendarsModalDocument, options)
}
export type UpdateAvailabilityCalendarsModalMutationHookResult = ReturnType<
  typeof useUpdateAvailabilityCalendarsModalMutation
>
export type UpdateAvailabilityCalendarsModalMutationResult =
  Apollo.MutationResult<UpdateAvailabilityCalendarsModalMutation>
export type UpdateAvailabilityCalendarsModalMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateAvailabilityCalendarsModalMutation,
    UpdateAvailabilityCalendarsModalMutationVariables
  >
export const UpdateConferenceAccountModalDocument = gql`
  query UpdateConferenceAccountModal($id: ID!) {
    conferencingAccount: getConferencingAccountById(id: $id) {
      id
      name
      ... on JoinMeAccountNode {
        startWithPersonalUrl
      }
      ... on GoToMeetingAccountNode {
        conferenceCallInfo
      }
      ... on ZoomAccountNode {
        audio
        autoRecording
        cnMeeting
        hostVideo
        inMeeting
        joinBeforeHost
        muteUponEntry
        participantVideo
        watermark
      }
    }
  }
`
export type UpdateConferenceAccountModalComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    UpdateConferenceAccountModalQuery,
    UpdateConferenceAccountModalQueryVariables
  >,
  'query'
> &
  (
    | { variables: UpdateConferenceAccountModalQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const UpdateConferenceAccountModalComponent = (
  props: UpdateConferenceAccountModalComponentProps
) => (
  <ApolloReactComponents.Query<
    UpdateConferenceAccountModalQuery,
    UpdateConferenceAccountModalQueryVariables
  >
    query={UpdateConferenceAccountModalDocument}
    {...props}
  />
)

/**
 * __useUpdateConferenceAccountModalQuery__
 *
 * To run a query within a React component, call `useUpdateConferenceAccountModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateConferenceAccountModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateConferenceAccountModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateConferenceAccountModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateConferenceAccountModalQuery,
    UpdateConferenceAccountModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UpdateConferenceAccountModalQuery,
    UpdateConferenceAccountModalQueryVariables
  >(UpdateConferenceAccountModalDocument, options)
}
export function useUpdateConferenceAccountModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateConferenceAccountModalQuery,
    UpdateConferenceAccountModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UpdateConferenceAccountModalQuery,
    UpdateConferenceAccountModalQueryVariables
  >(UpdateConferenceAccountModalDocument, options)
}
export type UpdateConferenceAccountModalQueryHookResult = ReturnType<
  typeof useUpdateConferenceAccountModalQuery
>
export type UpdateConferenceAccountModalLazyQueryHookResult = ReturnType<
  typeof useUpdateConferenceAccountModalLazyQuery
>
export type UpdateConferenceAccountModalQueryResult = Apollo.QueryResult<
  UpdateConferenceAccountModalQuery,
  UpdateConferenceAccountModalQueryVariables
>
export const UpdateGoToMeetingDocument = gql`
  mutation UpdateGoToMeeting($input: UpdateGoToMeetingAccountInput!) {
    account: updateGoToMeetingAccount(input: $input) {
      data {
        id
        name
        ... on GoToMeetingAccountNode {
          conferenceCallInfo
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type UpdateGoToMeetingMutationFn = Apollo.MutationFunction<
  UpdateGoToMeetingMutation,
  UpdateGoToMeetingMutationVariables
>
export type UpdateGoToMeetingComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateGoToMeetingMutation,
    UpdateGoToMeetingMutationVariables
  >,
  'mutation'
>

export const UpdateGoToMeetingComponent = (
  props: UpdateGoToMeetingComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateGoToMeetingMutation,
    UpdateGoToMeetingMutationVariables
  >
    mutation={UpdateGoToMeetingDocument}
    {...props}
  />
)

/**
 * __useUpdateGoToMeetingMutation__
 *
 * To run a mutation, you first call `useUpdateGoToMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoToMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoToMeetingMutation, { data, loading, error }] = useUpdateGoToMeetingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGoToMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGoToMeetingMutation,
    UpdateGoToMeetingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateGoToMeetingMutation,
    UpdateGoToMeetingMutationVariables
  >(UpdateGoToMeetingDocument, options)
}
export type UpdateGoToMeetingMutationHookResult = ReturnType<
  typeof useUpdateGoToMeetingMutation
>
export type UpdateGoToMeetingMutationResult =
  Apollo.MutationResult<UpdateGoToMeetingMutation>
export type UpdateGoToMeetingMutationOptions = Apollo.BaseMutationOptions<
  UpdateGoToMeetingMutation,
  UpdateGoToMeetingMutationVariables
>
export const UpdateJoinMeDocument = gql`
  mutation UpdateJoinMe($input: UpdateJoinMeAccountInput!) {
    account: updateJoinMeAccount(input: $input) {
      data {
        id
        name
        ... on JoinMeAccountNode {
          startWithPersonalUrl
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type UpdateJoinMeMutationFn = Apollo.MutationFunction<
  UpdateJoinMeMutation,
  UpdateJoinMeMutationVariables
>
export type UpdateJoinMeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateJoinMeMutation,
    UpdateJoinMeMutationVariables
  >,
  'mutation'
>

export const UpdateJoinMeComponent = (props: UpdateJoinMeComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateJoinMeMutation,
    UpdateJoinMeMutationVariables
  >
    mutation={UpdateJoinMeDocument}
    {...props}
  />
)

/**
 * __useUpdateJoinMeMutation__
 *
 * To run a mutation, you first call `useUpdateJoinMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJoinMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJoinMeMutation, { data, loading, error }] = useUpdateJoinMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJoinMeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateJoinMeMutation,
    UpdateJoinMeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateJoinMeMutation,
    UpdateJoinMeMutationVariables
  >(UpdateJoinMeDocument, options)
}
export type UpdateJoinMeMutationHookResult = ReturnType<
  typeof useUpdateJoinMeMutation
>
export type UpdateJoinMeMutationResult =
  Apollo.MutationResult<UpdateJoinMeMutation>
export type UpdateJoinMeMutationOptions = Apollo.BaseMutationOptions<
  UpdateJoinMeMutation,
  UpdateJoinMeMutationVariables
>
export const UpdateWebexDocument = gql`
  mutation UpdateWebex($input: UpdateWebexAccountInput!) {
    account: updateWebexAccount(input: $input) {
      data {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }
`
export type UpdateWebexMutationFn = Apollo.MutationFunction<
  UpdateWebexMutation,
  UpdateWebexMutationVariables
>
export type UpdateWebexComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateWebexMutation,
    UpdateWebexMutationVariables
  >,
  'mutation'
>

export const UpdateWebexComponent = (props: UpdateWebexComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateWebexMutation,
    UpdateWebexMutationVariables
  >
    mutation={UpdateWebexDocument}
    {...props}
  />
)

/**
 * __useUpdateWebexMutation__
 *
 * To run a mutation, you first call `useUpdateWebexMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebexMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebexMutation, { data, loading, error }] = useUpdateWebexMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebexMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWebexMutation,
    UpdateWebexMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateWebexMutation, UpdateWebexMutationVariables>(
    UpdateWebexDocument,
    options
  )
}
export type UpdateWebexMutationHookResult = ReturnType<
  typeof useUpdateWebexMutation
>
export type UpdateWebexMutationResult =
  Apollo.MutationResult<UpdateWebexMutation>
export type UpdateWebexMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebexMutation,
  UpdateWebexMutationVariables
>
export const UpdateZoomDocument = gql`
  mutation UpdateZoom($input: UpdateZoomAccountInput!) {
    account: updateZoomAccount(input: $input) {
      data {
        id
        name
        ... on ZoomAccountNode {
          audio
          autoRecording
          cnMeeting
          hostVideo
          inMeeting
          joinBeforeHost
          muteUponEntry
          participantVideo
          watermark
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type UpdateZoomMutationFn = Apollo.MutationFunction<
  UpdateZoomMutation,
  UpdateZoomMutationVariables
>
export type UpdateZoomComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateZoomMutation,
    UpdateZoomMutationVariables
  >,
  'mutation'
>

export const UpdateZoomComponent = (props: UpdateZoomComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateZoomMutation,
    UpdateZoomMutationVariables
  >
    mutation={UpdateZoomDocument}
    {...props}
  />
)

/**
 * __useUpdateZoomMutation__
 *
 * To run a mutation, you first call `useUpdateZoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomMutation, { data, loading, error }] = useUpdateZoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateZoomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomMutation,
    UpdateZoomMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateZoomMutation, UpdateZoomMutationVariables>(
    UpdateZoomDocument,
    options
  )
}
export type UpdateZoomMutationHookResult = ReturnType<
  typeof useUpdateZoomMutation
>
export type UpdateZoomMutationResult = Apollo.MutationResult<UpdateZoomMutation>
export type UpdateZoomMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomMutation,
  UpdateZoomMutationVariables
>
export const UpdateMeetingCalendarModalDocument = gql`
  mutation UpdateMeetingCalendarModal($input: SetDefaultCalendarInput!) {
    setDefaultCalendar(input: $input) {
      new {
        id
        name
        syncMeetings
      }
      old {
        id
        syncMeetings
      }
    }
  }
`
export type UpdateMeetingCalendarModalMutationFn = Apollo.MutationFunction<
  UpdateMeetingCalendarModalMutation,
  UpdateMeetingCalendarModalMutationVariables
>
export type UpdateMeetingCalendarModalComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMeetingCalendarModalMutation,
    UpdateMeetingCalendarModalMutationVariables
  >,
  'mutation'
>

export const UpdateMeetingCalendarModalComponent = (
  props: UpdateMeetingCalendarModalComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateMeetingCalendarModalMutation,
    UpdateMeetingCalendarModalMutationVariables
  >
    mutation={UpdateMeetingCalendarModalDocument}
    {...props}
  />
)

/**
 * __useUpdateMeetingCalendarModalMutation__
 *
 * To run a mutation, you first call `useUpdateMeetingCalendarModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingCalendarModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeetingCalendarModalMutation, { data, loading, error }] = useUpdateMeetingCalendarModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeetingCalendarModalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMeetingCalendarModalMutation,
    UpdateMeetingCalendarModalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMeetingCalendarModalMutation,
    UpdateMeetingCalendarModalMutationVariables
  >(UpdateMeetingCalendarModalDocument, options)
}
export type UpdateMeetingCalendarModalMutationHookResult = ReturnType<
  typeof useUpdateMeetingCalendarModalMutation
>
export type UpdateMeetingCalendarModalMutationResult =
  Apollo.MutationResult<UpdateMeetingCalendarModalMutation>
export type UpdateMeetingCalendarModalMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMeetingCalendarModalMutation,
    UpdateMeetingCalendarModalMutationVariables
  >
export const UpdateMeetingTypeModalDocument = gql`
  query UpdateMeetingTypeModal($id: ID!) {
    meetingType: getMeetingTypeById(id: $id) {
      ...MeetingType
    }
  }
  ${MeetingTypeFragmentDoc}
`
export type UpdateMeetingTypeModalComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    UpdateMeetingTypeModalQuery,
    UpdateMeetingTypeModalQueryVariables
  >,
  'query'
> &
  (
    | { variables: UpdateMeetingTypeModalQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const UpdateMeetingTypeModalComponent = (
  props: UpdateMeetingTypeModalComponentProps
) => (
  <ApolloReactComponents.Query<
    UpdateMeetingTypeModalQuery,
    UpdateMeetingTypeModalQueryVariables
  >
    query={UpdateMeetingTypeModalDocument}
    {...props}
  />
)

/**
 * __useUpdateMeetingTypeModalQuery__
 *
 * To run a query within a React component, call `useUpdateMeetingTypeModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingTypeModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateMeetingTypeModalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateMeetingTypeModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateMeetingTypeModalQuery,
    UpdateMeetingTypeModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UpdateMeetingTypeModalQuery,
    UpdateMeetingTypeModalQueryVariables
  >(UpdateMeetingTypeModalDocument, options)
}
export function useUpdateMeetingTypeModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateMeetingTypeModalQuery,
    UpdateMeetingTypeModalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UpdateMeetingTypeModalQuery,
    UpdateMeetingTypeModalQueryVariables
  >(UpdateMeetingTypeModalDocument, options)
}
export type UpdateMeetingTypeModalQueryHookResult = ReturnType<
  typeof useUpdateMeetingTypeModalQuery
>
export type UpdateMeetingTypeModalLazyQueryHookResult = ReturnType<
  typeof useUpdateMeetingTypeModalLazyQuery
>
export type UpdateMeetingTypeModalQueryResult = Apollo.QueryResult<
  UpdateMeetingTypeModalQuery,
  UpdateMeetingTypeModalQueryVariables
>
export const UpdateMeetingTypeModalUpdateDocument = gql`
  mutation UpdateMeetingTypeModalUpdate($input: UpdateMeetingTypeInput!) {
    updateMeetingType(input: $input) {
      data {
        ...MeetingType
      }
      errors {
        field
        messages
      }
    }
  }
  ${MeetingTypeFragmentDoc}
`
export type UpdateMeetingTypeModalUpdateMutationFn = Apollo.MutationFunction<
  UpdateMeetingTypeModalUpdateMutation,
  UpdateMeetingTypeModalUpdateMutationVariables
>
export type UpdateMeetingTypeModalUpdateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMeetingTypeModalUpdateMutation,
    UpdateMeetingTypeModalUpdateMutationVariables
  >,
  'mutation'
>

export const UpdateMeetingTypeModalUpdateComponent = (
  props: UpdateMeetingTypeModalUpdateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateMeetingTypeModalUpdateMutation,
    UpdateMeetingTypeModalUpdateMutationVariables
  >
    mutation={UpdateMeetingTypeModalUpdateDocument}
    {...props}
  />
)

/**
 * __useUpdateMeetingTypeModalUpdateMutation__
 *
 * To run a mutation, you first call `useUpdateMeetingTypeModalUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingTypeModalUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeetingTypeModalUpdateMutation, { data, loading, error }] = useUpdateMeetingTypeModalUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeetingTypeModalUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMeetingTypeModalUpdateMutation,
    UpdateMeetingTypeModalUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMeetingTypeModalUpdateMutation,
    UpdateMeetingTypeModalUpdateMutationVariables
  >(UpdateMeetingTypeModalUpdateDocument, options)
}
export type UpdateMeetingTypeModalUpdateMutationHookResult = ReturnType<
  typeof useUpdateMeetingTypeModalUpdateMutation
>
export type UpdateMeetingTypeModalUpdateMutationResult =
  Apollo.MutationResult<UpdateMeetingTypeModalUpdateMutation>
export type UpdateMeetingTypeModalUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMeetingTypeModalUpdateMutation,
    UpdateMeetingTypeModalUpdateMutationVariables
  >
export const UpdateMeetingTypeModalHostAssignmentSectionDocument = gql`
  query UpdateMeetingTypeModalHostAssignmentSection($teamId: ID!) {
    team: getTeamById(id: $teamId) {
      id
      teamMembers {
        edges {
          node {
            id
            member {
              id
              invitationAccepted
              user {
                email
                firstName
                id
                image
                lastName
                timezone
              }
              calendarAccounts {
                edges {
                  node {
                    id
                    provider {
                      id
                      slug
                    }
                  }
                }
              }
              conferencingAccounts {
                edges {
                  node {
                    id
                    provider {
                      id
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export type UpdateMeetingTypeModalHostAssignmentSectionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    UpdateMeetingTypeModalHostAssignmentSectionQuery,
    UpdateMeetingTypeModalHostAssignmentSectionQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: UpdateMeetingTypeModalHostAssignmentSectionQueryVariables
        skip?: boolean
      }
    | { skip: boolean }
  )

export const UpdateMeetingTypeModalHostAssignmentSectionComponent = (
  props: UpdateMeetingTypeModalHostAssignmentSectionComponentProps
) => (
  <ApolloReactComponents.Query<
    UpdateMeetingTypeModalHostAssignmentSectionQuery,
    UpdateMeetingTypeModalHostAssignmentSectionQueryVariables
  >
    query={UpdateMeetingTypeModalHostAssignmentSectionDocument}
    {...props}
  />
)

/**
 * __useUpdateMeetingTypeModalHostAssignmentSectionQuery__
 *
 * To run a query within a React component, call `useUpdateMeetingTypeModalHostAssignmentSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingTypeModalHostAssignmentSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateMeetingTypeModalHostAssignmentSectionQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useUpdateMeetingTypeModalHostAssignmentSectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateMeetingTypeModalHostAssignmentSectionQuery,
    UpdateMeetingTypeModalHostAssignmentSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    UpdateMeetingTypeModalHostAssignmentSectionQuery,
    UpdateMeetingTypeModalHostAssignmentSectionQueryVariables
  >(UpdateMeetingTypeModalHostAssignmentSectionDocument, options)
}
export function useUpdateMeetingTypeModalHostAssignmentSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateMeetingTypeModalHostAssignmentSectionQuery,
    UpdateMeetingTypeModalHostAssignmentSectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    UpdateMeetingTypeModalHostAssignmentSectionQuery,
    UpdateMeetingTypeModalHostAssignmentSectionQueryVariables
  >(UpdateMeetingTypeModalHostAssignmentSectionDocument, options)
}
export type UpdateMeetingTypeModalHostAssignmentSectionQueryHookResult =
  ReturnType<typeof useUpdateMeetingTypeModalHostAssignmentSectionQuery>
export type UpdateMeetingTypeModalHostAssignmentSectionLazyQueryHookResult =
  ReturnType<typeof useUpdateMeetingTypeModalHostAssignmentSectionLazyQuery>
export type UpdateMeetingTypeModalHostAssignmentSectionQueryResult =
  Apollo.QueryResult<
    UpdateMeetingTypeModalHostAssignmentSectionQuery,
    UpdateMeetingTypeModalHostAssignmentSectionQueryVariables
  >
export const MeetingTypeEditorLocationDocument = gql`
  query MeetingTypeEditorLocation($meetingTypeId: ID!) {
    providers: getConferenceProviders {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    teamMembers: getMeetingTypeById(id: $meetingTypeId) {
      id
      meetingTypeTeamMembers {
        edges {
          node {
            id
            teamMember {
              id
              member {
                calendarAccounts {
                  edges {
                    node {
                      id
                      provider {
                        id
                        slug
                      }
                    }
                  }
                }
                conferencingAccounts {
                  edges {
                    node {
                      deleted
                      id
                      provider {
                        id
                        slug
                      }
                    }
                  }
                }
                id
              }
            }
          }
        }
      }
    }
  }
`
export type MeetingTypeEditorLocationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MeetingTypeEditorLocationQuery,
    MeetingTypeEditorLocationQueryVariables
  >,
  'query'
> &
  (
    | { variables: MeetingTypeEditorLocationQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const MeetingTypeEditorLocationComponent = (
  props: MeetingTypeEditorLocationComponentProps
) => (
  <ApolloReactComponents.Query<
    MeetingTypeEditorLocationQuery,
    MeetingTypeEditorLocationQueryVariables
  >
    query={MeetingTypeEditorLocationDocument}
    {...props}
  />
)

/**
 * __useMeetingTypeEditorLocationQuery__
 *
 * To run a query within a React component, call `useMeetingTypeEditorLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingTypeEditorLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingTypeEditorLocationQuery({
 *   variables: {
 *      meetingTypeId: // value for 'meetingTypeId'
 *   },
 * });
 */
export function useMeetingTypeEditorLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    MeetingTypeEditorLocationQuery,
    MeetingTypeEditorLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    MeetingTypeEditorLocationQuery,
    MeetingTypeEditorLocationQueryVariables
  >(MeetingTypeEditorLocationDocument, options)
}
export function useMeetingTypeEditorLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeetingTypeEditorLocationQuery,
    MeetingTypeEditorLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    MeetingTypeEditorLocationQuery,
    MeetingTypeEditorLocationQueryVariables
  >(MeetingTypeEditorLocationDocument, options)
}
export type MeetingTypeEditorLocationQueryHookResult = ReturnType<
  typeof useMeetingTypeEditorLocationQuery
>
export type MeetingTypeEditorLocationLazyQueryHookResult = ReturnType<
  typeof useMeetingTypeEditorLocationLazyQuery
>
export type MeetingTypeEditorLocationQueryResult = Apollo.QueryResult<
  MeetingTypeEditorLocationQuery,
  MeetingTypeEditorLocationQueryVariables
>
export const WebConferencingProviderRowDocument = gql`
  query WebConferencingProviderRow($id: ID!) {
    conferencingAccount: getConferencingAccountById(id: $id) {
      isConnected
    }
  }
`
export type WebConferencingProviderRowComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    WebConferencingProviderRowQuery,
    WebConferencingProviderRowQueryVariables
  >,
  'query'
> &
  (
    | { variables: WebConferencingProviderRowQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const WebConferencingProviderRowComponent = (
  props: WebConferencingProviderRowComponentProps
) => (
  <ApolloReactComponents.Query<
    WebConferencingProviderRowQuery,
    WebConferencingProviderRowQueryVariables
  >
    query={WebConferencingProviderRowDocument}
    {...props}
  />
)

/**
 * __useWebConferencingProviderRowQuery__
 *
 * To run a query within a React component, call `useWebConferencingProviderRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebConferencingProviderRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebConferencingProviderRowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWebConferencingProviderRowQuery(
  baseOptions: Apollo.QueryHookOptions<
    WebConferencingProviderRowQuery,
    WebConferencingProviderRowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    WebConferencingProviderRowQuery,
    WebConferencingProviderRowQueryVariables
  >(WebConferencingProviderRowDocument, options)
}
export function useWebConferencingProviderRowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WebConferencingProviderRowQuery,
    WebConferencingProviderRowQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    WebConferencingProviderRowQuery,
    WebConferencingProviderRowQueryVariables
  >(WebConferencingProviderRowDocument, options)
}
export type WebConferencingProviderRowQueryHookResult = ReturnType<
  typeof useWebConferencingProviderRowQuery
>
export type WebConferencingProviderRowLazyQueryHookResult = ReturnType<
  typeof useWebConferencingProviderRowLazyQuery
>
export type WebConferencingProviderRowQueryResult = Apollo.QueryResult<
  WebConferencingProviderRowQuery,
  WebConferencingProviderRowQueryVariables
>
export const ReconnectConferencingAccountDocument = gql`
  mutation ReconnectConferencingAccount(
    $input: ReconnectConferencingAccountInput!
  ) {
    reconnectConferencingAccount(input: $input) {
      data {
        id
        isConnected
      }
      errors {
        field
        messages
      }
    }
  }
`
export type ReconnectConferencingAccountMutationFn = Apollo.MutationFunction<
  ReconnectConferencingAccountMutation,
  ReconnectConferencingAccountMutationVariables
>
export type ReconnectConferencingAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ReconnectConferencingAccountMutation,
    ReconnectConferencingAccountMutationVariables
  >,
  'mutation'
>

export const ReconnectConferencingAccountComponent = (
  props: ReconnectConferencingAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ReconnectConferencingAccountMutation,
    ReconnectConferencingAccountMutationVariables
  >
    mutation={ReconnectConferencingAccountDocument}
    {...props}
  />
)

/**
 * __useReconnectConferencingAccountMutation__
 *
 * To run a mutation, you first call `useReconnectConferencingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconnectConferencingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconnectConferencingAccountMutation, { data, loading, error }] = useReconnectConferencingAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReconnectConferencingAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReconnectConferencingAccountMutation,
    ReconnectConferencingAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReconnectConferencingAccountMutation,
    ReconnectConferencingAccountMutationVariables
  >(ReconnectConferencingAccountDocument, options)
}
export type ReconnectConferencingAccountMutationHookResult = ReturnType<
  typeof useReconnectConferencingAccountMutation
>
export type ReconnectConferencingAccountMutationResult =
  Apollo.MutationResult<ReconnectConferencingAccountMutation>
export type ReconnectConferencingAccountMutationOptions =
  Apollo.BaseMutationOptions<
    ReconnectConferencingAccountMutation,
    ReconnectConferencingAccountMutationVariables
  >
export const CreateConferencingAccountDocument = gql`
  mutation CreateConferencingAccount($input: CreateConferencingAccountInput!) {
    account: createConferencingAccount(input: $input) {
      data {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CreateConferencingAccountMutationFn = Apollo.MutationFunction<
  CreateConferencingAccountMutation,
  CreateConferencingAccountMutationVariables
>
export type CreateConferencingAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateConferencingAccountMutation,
    CreateConferencingAccountMutationVariables
  >,
  'mutation'
>

export const CreateConferencingAccountComponent = (
  props: CreateConferencingAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateConferencingAccountMutation,
    CreateConferencingAccountMutationVariables
  >
    mutation={CreateConferencingAccountDocument}
    {...props}
  />
)

/**
 * __useCreateConferencingAccountMutation__
 *
 * To run a mutation, you first call `useCreateConferencingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConferencingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConferencingAccountMutation, { data, loading, error }] = useCreateConferencingAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConferencingAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConferencingAccountMutation,
    CreateConferencingAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateConferencingAccountMutation,
    CreateConferencingAccountMutationVariables
  >(CreateConferencingAccountDocument, options)
}
export type CreateConferencingAccountMutationHookResult = ReturnType<
  typeof useCreateConferencingAccountMutation
>
export type CreateConferencingAccountMutationResult =
  Apollo.MutationResult<CreateConferencingAccountMutation>
export type CreateConferencingAccountMutationOptions =
  Apollo.BaseMutationOptions<
    CreateConferencingAccountMutation,
    CreateConferencingAccountMutationVariables
  >
export const ConnectedMeetingDetailDrawerDocument = gql`
  query ConnectedMeetingDetailDrawer($id: ID!) {
    meeting: getMeetingById(id: $id) {
      approved
      attendees(orderBy: "-isHost,firstName") {
        edges {
          node {
            approved
            cancelled
            confirmed
            email
            fieldSubmissions {
              edges {
                node {
                  id
                  fieldType
                  name
                  value
                }
              }
            }
            firstName
            id
            isHost
            lastName
            member {
              id
              user {
                id
                image
              }
            }
            timezone
          }
        }
      }
      cancelled
      notes {
        edges {
          node {
            createdAt
            id
            deleted
            author {
              id
              user {
                id
                firstName
                lastName
                email
                image
              }
              profile {
                id
              }
            }
            body
          }
        }
      }
      conferencingAccount {
        __typename
        deleted
        id
        name
        provider {
          id
          name
          oauth2AuthorizationUrl
          slug
        }
      }
      conferenceId
      conferenceUrl
      end
      id
      timezone
      location
      locationType
      meetingType {
        id
        image
        name
        isGroup
        color
        timezone
        price
        conferencingProvider {
          name
          slug
          oauth2AuthorizationUrl
        }
      }
      name
      start
    }
  }
`
export type ConnectedMeetingDetailDrawerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ConnectedMeetingDetailDrawerQuery,
    ConnectedMeetingDetailDrawerQueryVariables
  >,
  'query'
> &
  (
    | { variables: ConnectedMeetingDetailDrawerQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ConnectedMeetingDetailDrawerComponent = (
  props: ConnectedMeetingDetailDrawerComponentProps
) => (
  <ApolloReactComponents.Query<
    ConnectedMeetingDetailDrawerQuery,
    ConnectedMeetingDetailDrawerQueryVariables
  >
    query={ConnectedMeetingDetailDrawerDocument}
    {...props}
  />
)

/**
 * __useConnectedMeetingDetailDrawerQuery__
 *
 * To run a query within a React component, call `useConnectedMeetingDetailDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedMeetingDetailDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedMeetingDetailDrawerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConnectedMeetingDetailDrawerQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectedMeetingDetailDrawerQuery,
    ConnectedMeetingDetailDrawerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ConnectedMeetingDetailDrawerQuery,
    ConnectedMeetingDetailDrawerQueryVariables
  >(ConnectedMeetingDetailDrawerDocument, options)
}
export function useConnectedMeetingDetailDrawerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectedMeetingDetailDrawerQuery,
    ConnectedMeetingDetailDrawerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ConnectedMeetingDetailDrawerQuery,
    ConnectedMeetingDetailDrawerQueryVariables
  >(ConnectedMeetingDetailDrawerDocument, options)
}
export type ConnectedMeetingDetailDrawerQueryHookResult = ReturnType<
  typeof useConnectedMeetingDetailDrawerQuery
>
export type ConnectedMeetingDetailDrawerLazyQueryHookResult = ReturnType<
  typeof useConnectedMeetingDetailDrawerLazyQuery
>
export type ConnectedMeetingDetailDrawerQueryResult = Apollo.QueryResult<
  ConnectedMeetingDetailDrawerQuery,
  ConnectedMeetingDetailDrawerQueryVariables
>
export const CreateMeetingNoteDocument = gql`
  mutation CreateMeetingNote($input: CreateMeetingNoteInput!) {
    createMeetingNote(input: $input) {
      data {
        createdAt
        id
        deleted
        body
        author {
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CreateMeetingNoteMutationFn = Apollo.MutationFunction<
  CreateMeetingNoteMutation,
  CreateMeetingNoteMutationVariables
>
export type CreateMeetingNoteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateMeetingNoteMutation,
    CreateMeetingNoteMutationVariables
  >,
  'mutation'
>

export const CreateMeetingNoteComponent = (
  props: CreateMeetingNoteComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateMeetingNoteMutation,
    CreateMeetingNoteMutationVariables
  >
    mutation={CreateMeetingNoteDocument}
    {...props}
  />
)

/**
 * __useCreateMeetingNoteMutation__
 *
 * To run a mutation, you first call `useCreateMeetingNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeetingNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeetingNoteMutation, { data, loading, error }] = useCreateMeetingNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMeetingNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMeetingNoteMutation,
    CreateMeetingNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateMeetingNoteMutation,
    CreateMeetingNoteMutationVariables
  >(CreateMeetingNoteDocument, options)
}
export type CreateMeetingNoteMutationHookResult = ReturnType<
  typeof useCreateMeetingNoteMutation
>
export type CreateMeetingNoteMutationResult =
  Apollo.MutationResult<CreateMeetingNoteMutation>
export type CreateMeetingNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateMeetingNoteMutation,
  CreateMeetingNoteMutationVariables
>
export const UpdateMeetingNoteDocument = gql`
  mutation UpdateMeetingNote($input: UpdateMeetingNoteInput!) {
    updateMeetingNote(input: $input) {
      data {
        createdAt
        id
        deleted
        body
        author {
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type UpdateMeetingNoteMutationFn = Apollo.MutationFunction<
  UpdateMeetingNoteMutation,
  UpdateMeetingNoteMutationVariables
>
export type UpdateMeetingNoteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMeetingNoteMutation,
    UpdateMeetingNoteMutationVariables
  >,
  'mutation'
>

export const UpdateMeetingNoteComponent = (
  props: UpdateMeetingNoteComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateMeetingNoteMutation,
    UpdateMeetingNoteMutationVariables
  >
    mutation={UpdateMeetingNoteDocument}
    {...props}
  />
)

/**
 * __useUpdateMeetingNoteMutation__
 *
 * To run a mutation, you first call `useUpdateMeetingNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeetingNoteMutation, { data, loading, error }] = useUpdateMeetingNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeetingNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMeetingNoteMutation,
    UpdateMeetingNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMeetingNoteMutation,
    UpdateMeetingNoteMutationVariables
  >(UpdateMeetingNoteDocument, options)
}
export type UpdateMeetingNoteMutationHookResult = ReturnType<
  typeof useUpdateMeetingNoteMutation
>
export type UpdateMeetingNoteMutationResult =
  Apollo.MutationResult<UpdateMeetingNoteMutation>
export type UpdateMeetingNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateMeetingNoteMutation,
  UpdateMeetingNoteMutationVariables
>
export const DeleteMeetingNoteDocument = gql`
  mutation DeleteMeetingNote($input: DeleteMeetingNoteInput!) {
    deleteMeetingNote(input: $input) {
      data {
        createdAt
        id
        deleted
        body
        author {
          id
        }
      }
    }
  }
`
export type DeleteMeetingNoteMutationFn = Apollo.MutationFunction<
  DeleteMeetingNoteMutation,
  DeleteMeetingNoteMutationVariables
>
export type DeleteMeetingNoteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteMeetingNoteMutation,
    DeleteMeetingNoteMutationVariables
  >,
  'mutation'
>

export const DeleteMeetingNoteComponent = (
  props: DeleteMeetingNoteComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteMeetingNoteMutation,
    DeleteMeetingNoteMutationVariables
  >
    mutation={DeleteMeetingNoteDocument}
    {...props}
  />
)

/**
 * __useDeleteMeetingNoteMutation__
 *
 * To run a mutation, you first call `useDeleteMeetingNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeetingNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeetingNoteMutation, { data, loading, error }] = useDeleteMeetingNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMeetingNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMeetingNoteMutation,
    DeleteMeetingNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteMeetingNoteMutation,
    DeleteMeetingNoteMutationVariables
  >(DeleteMeetingNoteDocument, options)
}
export type DeleteMeetingNoteMutationHookResult = ReturnType<
  typeof useDeleteMeetingNoteMutation
>
export type DeleteMeetingNoteMutationResult =
  Apollo.MutationResult<DeleteMeetingNoteMutation>
export type DeleteMeetingNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteMeetingNoteMutation,
  DeleteMeetingNoteMutationVariables
>
export const MeetingDetailDrawerReconnectConferencingAccountDocument = gql`
  mutation MeetingDetailDrawerReconnectConferencingAccount(
    $input: ReconnectConferencingAccountInput!
  ) {
    reconnectConferencingAccount(input: $input) {
      data {
        __typename
        id
      }
      errors {
        field
        messages
      }
    }
  }
`
export type MeetingDetailDrawerReconnectConferencingAccountMutationFn =
  Apollo.MutationFunction<
    MeetingDetailDrawerReconnectConferencingAccountMutation,
    MeetingDetailDrawerReconnectConferencingAccountMutationVariables
  >
export type MeetingDetailDrawerReconnectConferencingAccountComponentProps =
  Omit<
    ApolloReactComponents.MutationComponentOptions<
      MeetingDetailDrawerReconnectConferencingAccountMutation,
      MeetingDetailDrawerReconnectConferencingAccountMutationVariables
    >,
    'mutation'
  >

export const MeetingDetailDrawerReconnectConferencingAccountComponent = (
  props: MeetingDetailDrawerReconnectConferencingAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    MeetingDetailDrawerReconnectConferencingAccountMutation,
    MeetingDetailDrawerReconnectConferencingAccountMutationVariables
  >
    mutation={MeetingDetailDrawerReconnectConferencingAccountDocument}
    {...props}
  />
)

/**
 * __useMeetingDetailDrawerReconnectConferencingAccountMutation__
 *
 * To run a mutation, you first call `useMeetingDetailDrawerReconnectConferencingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMeetingDetailDrawerReconnectConferencingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [meetingDetailDrawerReconnectConferencingAccountMutation, { data, loading, error }] = useMeetingDetailDrawerReconnectConferencingAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMeetingDetailDrawerReconnectConferencingAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MeetingDetailDrawerReconnectConferencingAccountMutation,
    MeetingDetailDrawerReconnectConferencingAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MeetingDetailDrawerReconnectConferencingAccountMutation,
    MeetingDetailDrawerReconnectConferencingAccountMutationVariables
  >(MeetingDetailDrawerReconnectConferencingAccountDocument, options)
}
export type MeetingDetailDrawerReconnectConferencingAccountMutationHookResult =
  ReturnType<typeof useMeetingDetailDrawerReconnectConferencingAccountMutation>
export type MeetingDetailDrawerReconnectConferencingAccountMutationResult =
  Apollo.MutationResult<MeetingDetailDrawerReconnectConferencingAccountMutation>
export type MeetingDetailDrawerReconnectConferencingAccountMutationOptions =
  Apollo.BaseMutationOptions<
    MeetingDetailDrawerReconnectConferencingAccountMutation,
    MeetingDetailDrawerReconnectConferencingAccountMutationVariables
  >
export const OnboardingAvailabilityPageDocument = gql`
  query OnboardingAvailabilityPage($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            id
            name
            slug
            bookingUrl
          }
        }
      }
      schedules {
        edges {
          node {
            id
            timeBlocks {
              edges {
                node {
                  weekday
                  start
                  end
                }
              }
            }
          }
        }
      }
    }
  }
`
export type OnboardingAvailabilityPageComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OnboardingAvailabilityPageQuery,
    OnboardingAvailabilityPageQueryVariables
  >,
  'query'
> &
  (
    | { variables: OnboardingAvailabilityPageQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const OnboardingAvailabilityPageComponent = (
  props: OnboardingAvailabilityPageComponentProps
) => (
  <ApolloReactComponents.Query<
    OnboardingAvailabilityPageQuery,
    OnboardingAvailabilityPageQueryVariables
  >
    query={OnboardingAvailabilityPageDocument}
    {...props}
  />
)

/**
 * __useOnboardingAvailabilityPageQuery__
 *
 * To run a query within a React component, call `useOnboardingAvailabilityPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingAvailabilityPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingAvailabilityPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnboardingAvailabilityPageQuery(
  baseOptions: Apollo.QueryHookOptions<
    OnboardingAvailabilityPageQuery,
    OnboardingAvailabilityPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OnboardingAvailabilityPageQuery,
    OnboardingAvailabilityPageQueryVariables
  >(OnboardingAvailabilityPageDocument, options)
}
export function useOnboardingAvailabilityPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingAvailabilityPageQuery,
    OnboardingAvailabilityPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OnboardingAvailabilityPageQuery,
    OnboardingAvailabilityPageQueryVariables
  >(OnboardingAvailabilityPageDocument, options)
}
export type OnboardingAvailabilityPageQueryHookResult = ReturnType<
  typeof useOnboardingAvailabilityPageQuery
>
export type OnboardingAvailabilityPageLazyQueryHookResult = ReturnType<
  typeof useOnboardingAvailabilityPageLazyQuery
>
export type OnboardingAvailabilityPageQueryResult = Apollo.QueryResult<
  OnboardingAvailabilityPageQuery,
  OnboardingAvailabilityPageQueryVariables
>
export const SetDefaultAvailabilityDocument = gql`
  mutation SetDefaultAvailability($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      data {
        id
        timeBlocks {
          edges {
            node {
              id
              start
              end
              weekday
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type SetDefaultAvailabilityMutationFn = Apollo.MutationFunction<
  SetDefaultAvailabilityMutation,
  SetDefaultAvailabilityMutationVariables
>
export type SetDefaultAvailabilityComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SetDefaultAvailabilityMutation,
    SetDefaultAvailabilityMutationVariables
  >,
  'mutation'
>

export const SetDefaultAvailabilityComponent = (
  props: SetDefaultAvailabilityComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SetDefaultAvailabilityMutation,
    SetDefaultAvailabilityMutationVariables
  >
    mutation={SetDefaultAvailabilityDocument}
    {...props}
  />
)

/**
 * __useSetDefaultAvailabilityMutation__
 *
 * To run a mutation, you first call `useSetDefaultAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultAvailabilityMutation, { data, loading, error }] = useSetDefaultAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDefaultAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDefaultAvailabilityMutation,
    SetDefaultAvailabilityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetDefaultAvailabilityMutation,
    SetDefaultAvailabilityMutationVariables
  >(SetDefaultAvailabilityDocument, options)
}
export type SetDefaultAvailabilityMutationHookResult = ReturnType<
  typeof useSetDefaultAvailabilityMutation
>
export type SetDefaultAvailabilityMutationResult =
  Apollo.MutationResult<SetDefaultAvailabilityMutation>
export type SetDefaultAvailabilityMutationOptions = Apollo.BaseMutationOptions<
  SetDefaultAvailabilityMutation,
  SetDefaultAvailabilityMutationVariables
>
export const UpdateMemberAvailabilityDocument = gql`
  mutation UpdateMemberAvailability($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
      data {
        id
        timeBlocks {
          edges {
            node {
              id
              start
              end
              weekday
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`
export type UpdateMemberAvailabilityMutationFn = Apollo.MutationFunction<
  UpdateMemberAvailabilityMutation,
  UpdateMemberAvailabilityMutationVariables
>
export type UpdateMemberAvailabilityComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateMemberAvailabilityMutation,
    UpdateMemberAvailabilityMutationVariables
  >,
  'mutation'
>

export const UpdateMemberAvailabilityComponent = (
  props: UpdateMemberAvailabilityComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateMemberAvailabilityMutation,
    UpdateMemberAvailabilityMutationVariables
  >
    mutation={UpdateMemberAvailabilityDocument}
    {...props}
  />
)

/**
 * __useUpdateMemberAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateMemberAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberAvailabilityMutation, { data, loading, error }] = useUpdateMemberAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMemberAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMemberAvailabilityMutation,
    UpdateMemberAvailabilityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMemberAvailabilityMutation,
    UpdateMemberAvailabilityMutationVariables
  >(UpdateMemberAvailabilityDocument, options)
}
export type UpdateMemberAvailabilityMutationHookResult = ReturnType<
  typeof useUpdateMemberAvailabilityMutation
>
export type UpdateMemberAvailabilityMutationResult =
  Apollo.MutationResult<UpdateMemberAvailabilityMutation>
export type UpdateMemberAvailabilityMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMemberAvailabilityMutation,
    UpdateMemberAvailabilityMutationVariables
  >
export const OnboardingAvailabilityPageUpdateUserDocument = gql`
  mutation OnboardingAvailabilityPageUpdateUser($input: UpdateUserInput!) {
    user: updateUser(input: $input) {
      data {
        id
        needsOnboarding
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingAvailabilityPageUpdateUserMutationFn =
  Apollo.MutationFunction<
    OnboardingAvailabilityPageUpdateUserMutation,
    OnboardingAvailabilityPageUpdateUserMutationVariables
  >
export type OnboardingAvailabilityPageUpdateUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingAvailabilityPageUpdateUserMutation,
    OnboardingAvailabilityPageUpdateUserMutationVariables
  >,
  'mutation'
>

export const OnboardingAvailabilityPageUpdateUserComponent = (
  props: OnboardingAvailabilityPageUpdateUserComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingAvailabilityPageUpdateUserMutation,
    OnboardingAvailabilityPageUpdateUserMutationVariables
  >
    mutation={OnboardingAvailabilityPageUpdateUserDocument}
    {...props}
  />
)

/**
 * __useOnboardingAvailabilityPageUpdateUserMutation__
 *
 * To run a mutation, you first call `useOnboardingAvailabilityPageUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingAvailabilityPageUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingAvailabilityPageUpdateUserMutation, { data, loading, error }] = useOnboardingAvailabilityPageUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingAvailabilityPageUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingAvailabilityPageUpdateUserMutation,
    OnboardingAvailabilityPageUpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingAvailabilityPageUpdateUserMutation,
    OnboardingAvailabilityPageUpdateUserMutationVariables
  >(OnboardingAvailabilityPageUpdateUserDocument, options)
}
export type OnboardingAvailabilityPageUpdateUserMutationHookResult = ReturnType<
  typeof useOnboardingAvailabilityPageUpdateUserMutation
>
export type OnboardingAvailabilityPageUpdateUserMutationResult =
  Apollo.MutationResult<OnboardingAvailabilityPageUpdateUserMutation>
export type OnboardingAvailabilityPageUpdateUserMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingAvailabilityPageUpdateUserMutation,
    OnboardingAvailabilityPageUpdateUserMutationVariables
  >
export const OnboardingAvailabilityPageUpdateProfileDocument = gql`
  mutation OnboardingAvailabilityPageUpdateProfile(
    $input: UpdateProfileInput!
  ) {
    profile: updateProfile(input: $input) {
      data {
        id
        needsOnboarding
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingAvailabilityPageUpdateProfileMutationFn =
  Apollo.MutationFunction<
    OnboardingAvailabilityPageUpdateProfileMutation,
    OnboardingAvailabilityPageUpdateProfileMutationVariables
  >
export type OnboardingAvailabilityPageUpdateProfileComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingAvailabilityPageUpdateProfileMutation,
    OnboardingAvailabilityPageUpdateProfileMutationVariables
  >,
  'mutation'
>

export const OnboardingAvailabilityPageUpdateProfileComponent = (
  props: OnboardingAvailabilityPageUpdateProfileComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingAvailabilityPageUpdateProfileMutation,
    OnboardingAvailabilityPageUpdateProfileMutationVariables
  >
    mutation={OnboardingAvailabilityPageUpdateProfileDocument}
    {...props}
  />
)

/**
 * __useOnboardingAvailabilityPageUpdateProfileMutation__
 *
 * To run a mutation, you first call `useOnboardingAvailabilityPageUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingAvailabilityPageUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingAvailabilityPageUpdateProfileMutation, { data, loading, error }] = useOnboardingAvailabilityPageUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingAvailabilityPageUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingAvailabilityPageUpdateProfileMutation,
    OnboardingAvailabilityPageUpdateProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingAvailabilityPageUpdateProfileMutation,
    OnboardingAvailabilityPageUpdateProfileMutationVariables
  >(OnboardingAvailabilityPageUpdateProfileDocument, options)
}
export type OnboardingAvailabilityPageUpdateProfileMutationHookResult =
  ReturnType<typeof useOnboardingAvailabilityPageUpdateProfileMutation>
export type OnboardingAvailabilityPageUpdateProfileMutationResult =
  Apollo.MutationResult<OnboardingAvailabilityPageUpdateProfileMutation>
export type OnboardingAvailabilityPageUpdateProfileMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingAvailabilityPageUpdateProfileMutation,
    OnboardingAvailabilityPageUpdateProfileMutationVariables
  >
export const OnboardingCreateMeetingTypePageDataDocument = gql`
  query OnboardingCreateMeetingTypePageData($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`
export type OnboardingCreateMeetingTypePageDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OnboardingCreateMeetingTypePageDataQuery,
    OnboardingCreateMeetingTypePageDataQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: OnboardingCreateMeetingTypePageDataQueryVariables
        skip?: boolean
      }
    | { skip: boolean }
  )

export const OnboardingCreateMeetingTypePageDataComponent = (
  props: OnboardingCreateMeetingTypePageDataComponentProps
) => (
  <ApolloReactComponents.Query<
    OnboardingCreateMeetingTypePageDataQuery,
    OnboardingCreateMeetingTypePageDataQueryVariables
  >
    query={OnboardingCreateMeetingTypePageDataDocument}
    {...props}
  />
)

/**
 * __useOnboardingCreateMeetingTypePageDataQuery__
 *
 * To run a query within a React component, call `useOnboardingCreateMeetingTypePageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCreateMeetingTypePageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingCreateMeetingTypePageDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnboardingCreateMeetingTypePageDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    OnboardingCreateMeetingTypePageDataQuery,
    OnboardingCreateMeetingTypePageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OnboardingCreateMeetingTypePageDataQuery,
    OnboardingCreateMeetingTypePageDataQueryVariables
  >(OnboardingCreateMeetingTypePageDataDocument, options)
}
export function useOnboardingCreateMeetingTypePageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingCreateMeetingTypePageDataQuery,
    OnboardingCreateMeetingTypePageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OnboardingCreateMeetingTypePageDataQuery,
    OnboardingCreateMeetingTypePageDataQueryVariables
  >(OnboardingCreateMeetingTypePageDataDocument, options)
}
export type OnboardingCreateMeetingTypePageDataQueryHookResult = ReturnType<
  typeof useOnboardingCreateMeetingTypePageDataQuery
>
export type OnboardingCreateMeetingTypePageDataLazyQueryHookResult = ReturnType<
  typeof useOnboardingCreateMeetingTypePageDataLazyQuery
>
export type OnboardingCreateMeetingTypePageDataQueryResult = Apollo.QueryResult<
  OnboardingCreateMeetingTypePageDataQuery,
  OnboardingCreateMeetingTypePageDataQueryVariables
>
export const OnboardingCreateMeetingTypePageCreateDocument = gql`
  mutation OnboardingCreateMeetingTypePageCreate(
    $input: CreateMeetingTypeInput!
  ) {
    createMeetingType(input: $input) {
      data {
        id
        name
        duration
        isGroup
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingCreateMeetingTypePageCreateMutationFn =
  Apollo.MutationFunction<
    OnboardingCreateMeetingTypePageCreateMutation,
    OnboardingCreateMeetingTypePageCreateMutationVariables
  >
export type OnboardingCreateMeetingTypePageCreateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingCreateMeetingTypePageCreateMutation,
    OnboardingCreateMeetingTypePageCreateMutationVariables
  >,
  'mutation'
>

export const OnboardingCreateMeetingTypePageCreateComponent = (
  props: OnboardingCreateMeetingTypePageCreateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingCreateMeetingTypePageCreateMutation,
    OnboardingCreateMeetingTypePageCreateMutationVariables
  >
    mutation={OnboardingCreateMeetingTypePageCreateDocument}
    {...props}
  />
)

/**
 * __useOnboardingCreateMeetingTypePageCreateMutation__
 *
 * To run a mutation, you first call `useOnboardingCreateMeetingTypePageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCreateMeetingTypePageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingCreateMeetingTypePageCreateMutation, { data, loading, error }] = useOnboardingCreateMeetingTypePageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingCreateMeetingTypePageCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingCreateMeetingTypePageCreateMutation,
    OnboardingCreateMeetingTypePageCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingCreateMeetingTypePageCreateMutation,
    OnboardingCreateMeetingTypePageCreateMutationVariables
  >(OnboardingCreateMeetingTypePageCreateDocument, options)
}
export type OnboardingCreateMeetingTypePageCreateMutationHookResult =
  ReturnType<typeof useOnboardingCreateMeetingTypePageCreateMutation>
export type OnboardingCreateMeetingTypePageCreateMutationResult =
  Apollo.MutationResult<OnboardingCreateMeetingTypePageCreateMutation>
export type OnboardingCreateMeetingTypePageCreateMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingCreateMeetingTypePageCreateMutation,
    OnboardingCreateMeetingTypePageCreateMutationVariables
  >
export const OnboardingCreateMeetingTypePageDeleteAndCreateDocument = gql`
  mutation OnboardingCreateMeetingTypePageDeleteAndCreate(
    $delete: DeleteMeetingTypeInput!
    $input: CreateMeetingTypeInput!
  ) {
    deleteMeetingType(input: $delete) {
      data {
        id
        deleted
      }
    }
    createMeetingType(input: $input) {
      data {
        id
        name
        duration
        isGroup
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingCreateMeetingTypePageDeleteAndCreateMutationFn =
  Apollo.MutationFunction<
    OnboardingCreateMeetingTypePageDeleteAndCreateMutation,
    OnboardingCreateMeetingTypePageDeleteAndCreateMutationVariables
  >
export type OnboardingCreateMeetingTypePageDeleteAndCreateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingCreateMeetingTypePageDeleteAndCreateMutation,
    OnboardingCreateMeetingTypePageDeleteAndCreateMutationVariables
  >,
  'mutation'
>

export const OnboardingCreateMeetingTypePageDeleteAndCreateComponent = (
  props: OnboardingCreateMeetingTypePageDeleteAndCreateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingCreateMeetingTypePageDeleteAndCreateMutation,
    OnboardingCreateMeetingTypePageDeleteAndCreateMutationVariables
  >
    mutation={OnboardingCreateMeetingTypePageDeleteAndCreateDocument}
    {...props}
  />
)

/**
 * __useOnboardingCreateMeetingTypePageDeleteAndCreateMutation__
 *
 * To run a mutation, you first call `useOnboardingCreateMeetingTypePageDeleteAndCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCreateMeetingTypePageDeleteAndCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingCreateMeetingTypePageDeleteAndCreateMutation, { data, loading, error }] = useOnboardingCreateMeetingTypePageDeleteAndCreateMutation({
 *   variables: {
 *      delete: // value for 'delete'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingCreateMeetingTypePageDeleteAndCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingCreateMeetingTypePageDeleteAndCreateMutation,
    OnboardingCreateMeetingTypePageDeleteAndCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingCreateMeetingTypePageDeleteAndCreateMutation,
    OnboardingCreateMeetingTypePageDeleteAndCreateMutationVariables
  >(OnboardingCreateMeetingTypePageDeleteAndCreateDocument, options)
}
export type OnboardingCreateMeetingTypePageDeleteAndCreateMutationHookResult =
  ReturnType<typeof useOnboardingCreateMeetingTypePageDeleteAndCreateMutation>
export type OnboardingCreateMeetingTypePageDeleteAndCreateMutationResult =
  Apollo.MutationResult<OnboardingCreateMeetingTypePageDeleteAndCreateMutation>
export type OnboardingCreateMeetingTypePageDeleteAndCreateMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingCreateMeetingTypePageDeleteAndCreateMutation,
    OnboardingCreateMeetingTypePageDeleteAndCreateMutationVariables
  >
export const TeamSlugAvailabilityDocument = gql`
  query teamSlugAvailability($slug: String!) {
    data: teamSlugAvailability(slug: $slug) {
      available
      alternative
    }
  }
`
export type TeamSlugAvailabilityComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TeamSlugAvailabilityQuery,
    TeamSlugAvailabilityQueryVariables
  >,
  'query'
> &
  (
    | { variables: TeamSlugAvailabilityQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const TeamSlugAvailabilityComponent = (
  props: TeamSlugAvailabilityComponentProps
) => (
  <ApolloReactComponents.Query<
    TeamSlugAvailabilityQuery,
    TeamSlugAvailabilityQueryVariables
  >
    query={TeamSlugAvailabilityDocument}
    {...props}
  />
)

/**
 * __useTeamSlugAvailabilityQuery__
 *
 * To run a query within a React component, call `useTeamSlugAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamSlugAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamSlugAvailabilityQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTeamSlugAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    TeamSlugAvailabilityQuery,
    TeamSlugAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    TeamSlugAvailabilityQuery,
    TeamSlugAvailabilityQueryVariables
  >(TeamSlugAvailabilityDocument, options)
}
export function useTeamSlugAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamSlugAvailabilityQuery,
    TeamSlugAvailabilityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    TeamSlugAvailabilityQuery,
    TeamSlugAvailabilityQueryVariables
  >(TeamSlugAvailabilityDocument, options)
}
export type TeamSlugAvailabilityQueryHookResult = ReturnType<
  typeof useTeamSlugAvailabilityQuery
>
export type TeamSlugAvailabilityLazyQueryHookResult = ReturnType<
  typeof useTeamSlugAvailabilityLazyQuery
>
export type TeamSlugAvailabilityQueryResult = Apollo.QueryResult<
  TeamSlugAvailabilityQuery,
  TeamSlugAvailabilityQueryVariables
>
export const OnboardingCreateSchedulingPageMemberQueryDocument = gql`
  query OnboardingCreateSchedulingPageMemberQuery($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            id
            name
            slug
            bookingUrl
          }
        }
      }
      profile {
        featureFlags
        id
        name
      }
    }
  }
`
export type OnboardingCreateSchedulingPageMemberQueryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OnboardingCreateSchedulingPageMemberQueryQuery,
    OnboardingCreateSchedulingPageMemberQueryQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: OnboardingCreateSchedulingPageMemberQueryQueryVariables
        skip?: boolean
      }
    | { skip: boolean }
  )

export const OnboardingCreateSchedulingPageMemberQueryComponent = (
  props: OnboardingCreateSchedulingPageMemberQueryComponentProps
) => (
  <ApolloReactComponents.Query<
    OnboardingCreateSchedulingPageMemberQueryQuery,
    OnboardingCreateSchedulingPageMemberQueryQueryVariables
  >
    query={OnboardingCreateSchedulingPageMemberQueryDocument}
    {...props}
  />
)

/**
 * __useOnboardingCreateSchedulingPageMemberQueryQuery__
 *
 * To run a query within a React component, call `useOnboardingCreateSchedulingPageMemberQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCreateSchedulingPageMemberQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingCreateSchedulingPageMemberQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnboardingCreateSchedulingPageMemberQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    OnboardingCreateSchedulingPageMemberQueryQuery,
    OnboardingCreateSchedulingPageMemberQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OnboardingCreateSchedulingPageMemberQueryQuery,
    OnboardingCreateSchedulingPageMemberQueryQueryVariables
  >(OnboardingCreateSchedulingPageMemberQueryDocument, options)
}
export function useOnboardingCreateSchedulingPageMemberQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingCreateSchedulingPageMemberQueryQuery,
    OnboardingCreateSchedulingPageMemberQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OnboardingCreateSchedulingPageMemberQueryQuery,
    OnboardingCreateSchedulingPageMemberQueryQueryVariables
  >(OnboardingCreateSchedulingPageMemberQueryDocument, options)
}
export type OnboardingCreateSchedulingPageMemberQueryQueryHookResult =
  ReturnType<typeof useOnboardingCreateSchedulingPageMemberQueryQuery>
export type OnboardingCreateSchedulingPageMemberQueryLazyQueryHookResult =
  ReturnType<typeof useOnboardingCreateSchedulingPageMemberQueryLazyQuery>
export type OnboardingCreateSchedulingPageMemberQueryQueryResult =
  Apollo.QueryResult<
    OnboardingCreateSchedulingPageMemberQueryQuery,
    OnboardingCreateSchedulingPageMemberQueryQueryVariables
  >
export const OnboardingCreateSchedulingPageSlugUpdateDocument = gql`
  mutation OnboardingCreateSchedulingPageSlugUpdate($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      data {
        bookingUrl
        id
        name
        slug
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingCreateSchedulingPageSlugUpdateMutationFn =
  Apollo.MutationFunction<
    OnboardingCreateSchedulingPageSlugUpdateMutation,
    OnboardingCreateSchedulingPageSlugUpdateMutationVariables
  >
export type OnboardingCreateSchedulingPageSlugUpdateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingCreateSchedulingPageSlugUpdateMutation,
    OnboardingCreateSchedulingPageSlugUpdateMutationVariables
  >,
  'mutation'
>

export const OnboardingCreateSchedulingPageSlugUpdateComponent = (
  props: OnboardingCreateSchedulingPageSlugUpdateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingCreateSchedulingPageSlugUpdateMutation,
    OnboardingCreateSchedulingPageSlugUpdateMutationVariables
  >
    mutation={OnboardingCreateSchedulingPageSlugUpdateDocument}
    {...props}
  />
)

/**
 * __useOnboardingCreateSchedulingPageSlugUpdateMutation__
 *
 * To run a mutation, you first call `useOnboardingCreateSchedulingPageSlugUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCreateSchedulingPageSlugUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingCreateSchedulingPageSlugUpdateMutation, { data, loading, error }] = useOnboardingCreateSchedulingPageSlugUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingCreateSchedulingPageSlugUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingCreateSchedulingPageSlugUpdateMutation,
    OnboardingCreateSchedulingPageSlugUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingCreateSchedulingPageSlugUpdateMutation,
    OnboardingCreateSchedulingPageSlugUpdateMutationVariables
  >(OnboardingCreateSchedulingPageSlugUpdateDocument, options)
}
export type OnboardingCreateSchedulingPageSlugUpdateMutationHookResult =
  ReturnType<typeof useOnboardingCreateSchedulingPageSlugUpdateMutation>
export type OnboardingCreateSchedulingPageSlugUpdateMutationResult =
  Apollo.MutationResult<OnboardingCreateSchedulingPageSlugUpdateMutation>
export type OnboardingCreateSchedulingPageSlugUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingCreateSchedulingPageSlugUpdateMutation,
    OnboardingCreateSchedulingPageSlugUpdateMutationVariables
  >
export const OnboardingCreateSchedulingPageUpdateUserDocument = gql`
  mutation OnboardingCreateSchedulingPageUpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      data {
        firstName
        id
        lastName
        timezone
      }
      errors {
        field
        messages
      }
    }
  }
`
export type OnboardingCreateSchedulingPageUpdateUserMutationFn =
  Apollo.MutationFunction<
    OnboardingCreateSchedulingPageUpdateUserMutation,
    OnboardingCreateSchedulingPageUpdateUserMutationVariables
  >
export type OnboardingCreateSchedulingPageUpdateUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    OnboardingCreateSchedulingPageUpdateUserMutation,
    OnboardingCreateSchedulingPageUpdateUserMutationVariables
  >,
  'mutation'
>

export const OnboardingCreateSchedulingPageUpdateUserComponent = (
  props: OnboardingCreateSchedulingPageUpdateUserComponentProps
) => (
  <ApolloReactComponents.Mutation<
    OnboardingCreateSchedulingPageUpdateUserMutation,
    OnboardingCreateSchedulingPageUpdateUserMutationVariables
  >
    mutation={OnboardingCreateSchedulingPageUpdateUserDocument}
    {...props}
  />
)

/**
 * __useOnboardingCreateSchedulingPageUpdateUserMutation__
 *
 * To run a mutation, you first call `useOnboardingCreateSchedulingPageUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCreateSchedulingPageUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardingCreateSchedulingPageUpdateUserMutation, { data, loading, error }] = useOnboardingCreateSchedulingPageUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnboardingCreateSchedulingPageUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardingCreateSchedulingPageUpdateUserMutation,
    OnboardingCreateSchedulingPageUpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    OnboardingCreateSchedulingPageUpdateUserMutation,
    OnboardingCreateSchedulingPageUpdateUserMutationVariables
  >(OnboardingCreateSchedulingPageUpdateUserDocument, options)
}
export type OnboardingCreateSchedulingPageUpdateUserMutationHookResult =
  ReturnType<typeof useOnboardingCreateSchedulingPageUpdateUserMutation>
export type OnboardingCreateSchedulingPageUpdateUserMutationResult =
  Apollo.MutationResult<OnboardingCreateSchedulingPageUpdateUserMutation>
export type OnboardingCreateSchedulingPageUpdateUserMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingCreateSchedulingPageUpdateUserMutation,
    OnboardingCreateSchedulingPageUpdateUserMutationVariables
  >
export const CreateReportDocument = gql`
  mutation CreateReport($input: CreateMeetingReportInput!) {
    report: createMeetingReport(input: $input) {
      data {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`
export type CreateReportMutationFn = Apollo.MutationFunction<
  CreateReportMutation,
  CreateReportMutationVariables
>
export type CreateReportComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateReportMutation,
    CreateReportMutationVariables
  >,
  'mutation'
>

export const CreateReportComponent = (props: CreateReportComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateReportMutation,
    CreateReportMutationVariables
  >
    mutation={CreateReportDocument}
    {...props}
  />
)

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReportMutation,
    CreateReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateReportMutation,
    CreateReportMutationVariables
  >(CreateReportDocument, options)
}
export type CreateReportMutationHookResult = ReturnType<
  typeof useCreateReportMutation
>
export type CreateReportMutationResult =
  Apollo.MutationResult<CreateReportMutation>
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<
  CreateReportMutation,
  CreateReportMutationVariables
>
export const GetReportDocument = gql`
  query GetReport($id: ID!) {
    report: getReportById(id: $id) {
      id
      url
      status
    }
  }
`
export type GetReportComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetReportQuery,
    GetReportQueryVariables
  >,
  'query'
> &
  ({ variables: GetReportQueryVariables; skip?: boolean } | { skip: boolean })

export const GetReportComponent = (props: GetReportComponentProps) => (
  <ApolloReactComponents.Query<GetReportQuery, GetReportQueryVariables>
    query={GetReportDocument}
    {...props}
  />
)

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(
    GetReportDocument,
    options
  )
}
export function useGetReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReportQuery,
    GetReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(
    GetReportDocument,
    options
  )
}
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>
export type GetReportLazyQueryHookResult = ReturnType<
  typeof useGetReportLazyQuery
>
export type GetReportQueryResult = Apollo.QueryResult<
  GetReportQuery,
  GetReportQueryVariables
>
export const CreateStripeCheckoutSessionDocument = gql`
  mutation CreateStripeCheckoutSession(
    $input: StartStripePaymentMethodUpdateSessionInput!
  ) {
    stripe: startStripePaymentMethodUpdateSession(input: $input) {
      sessionId
      errors {
        field
        messages
      }
    }
  }
`
export type CreateStripeCheckoutSessionMutationFn = Apollo.MutationFunction<
  CreateStripeCheckoutSessionMutation,
  CreateStripeCheckoutSessionMutationVariables
>
export type CreateStripeCheckoutSessionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateStripeCheckoutSessionMutation,
    CreateStripeCheckoutSessionMutationVariables
  >,
  'mutation'
>

export const CreateStripeCheckoutSessionComponent = (
  props: CreateStripeCheckoutSessionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateStripeCheckoutSessionMutation,
    CreateStripeCheckoutSessionMutationVariables
  >
    mutation={CreateStripeCheckoutSessionDocument}
    {...props}
  />
)

/**
 * __useCreateStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCheckoutSessionMutation, { data, loading, error }] = useCreateStripeCheckoutSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStripeCheckoutSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeCheckoutSessionMutation,
    CreateStripeCheckoutSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateStripeCheckoutSessionMutation,
    CreateStripeCheckoutSessionMutationVariables
  >(CreateStripeCheckoutSessionDocument, options)
}
export type CreateStripeCheckoutSessionMutationHookResult = ReturnType<
  typeof useCreateStripeCheckoutSessionMutation
>
export type CreateStripeCheckoutSessionMutationResult =
  Apollo.MutationResult<CreateStripeCheckoutSessionMutation>
export type CreateStripeCheckoutSessionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateStripeCheckoutSessionMutation,
    CreateStripeCheckoutSessionMutationVariables
  >
