import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { gql } from '@apollo/client'
import { Form, Media, Modal } from 'react-bootstrap'
import Badge from './Badge'

import './SettingsModalGeneralSection.scss'
import ColorPicker from './ColorPicker'
import HelpToolTip from './HelpToolTip'
import Illustration from './Illustration'
import ImageInput from './ImageInput'
import LoadingButton from './LoadingButton'
import ProfileContext from './ProfileContext'
import Switch from './Switch'
import UnsupportedPlan from './UnsupportedPlan'
import {
  Maybe,
  SettingsModalGeneralSectionQuery as Response,
  useSettingsModalGeneralSectionQuery,
  useSettingsModalGeneralSectionUpdateMutation,
} from '../__generated__/graphql'
import { expandClassName, toast } from '../utils'
import Translate from './Translate'
import SettingsSectionPicker from './SettingsSectionPicker'
import { SettingsSection } from './SettingsModal'
import Icon from './Icon'

gql`
  query SettingsModalGeneralSection($id: ID!) {
    profile: getProfileById(id: $id) {
      brandColor
      id
      image
      name
      showAppointletBranding
    }
  }
`

gql`
  mutation SettingsModalGeneralSectionUpdate($input: UpdateProfileInput!) {
    profile: updateProfile(input: $input) {
      data {
        brandColor
        id
        image
        name
        showAppointletBranding
      }
      errors {
        field
        messages
      }
    }
  }
`

type Props = {
  onSelect: (section: SettingsSection) => void
}

type FormValues = {
  brandColor: string
  image: Maybe<string>
  name: Maybe<string>
  showAppointletBranding: boolean
}

type InternalData = {
  brandColor: string
  image: Maybe<string>
  name: Maybe<string>
  showAppointletBranding: boolean
}

const wireDataToInternalData = (wireData: Response): InternalData => ({
  brandColor: wireData.profile.brandColor,
  image: wireData.profile.image,
  name: wireData.profile.name,
  showAppointletBranding: wireData.profile.showAppointletBranding,
})

const SettingsModalGeneralSection: React.FC<Props> = ({ onSelect }) => {
  const profile = React.useContext(ProfileContext)
  const { data, loading: queryLoading } = useSettingsModalGeneralSectionQuery({
    variables: { id: profile.id },
  })
  const [updateProfile, { loading: mutationLoading }] =
    useSettingsModalGeneralSectionUpdateMutation()
  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data) : null),
    [data]
  )
  const onSubmit = async (values: FormValues) => {
    try {
      const { data, errors } = await updateProfile({
        variables: {
          input: {
            id: profile.id,
            ...values,
          },
        },
      })
      // If client validation and GraphQL error related errors exist
      if (errors) {
        console.error(
          'updateProfile mutation [SettingsModalGeneralSection.tsx]',
          errors
        )
        return
      }
      // If data is present trigger the toast to display and close the modal
      if (data) {
        const { profile } = data
        toast.success(`${profile!.data!.name} Updated`)
        return
      }
      // Here we are catching sever related errors.
    } catch (err) {
      toast.error('Something went wrong')
      console.error(
        'updateProfile mutation [SettingsModalGeneralSection.tsx]',
        err
      )
    }
  }
  return (
    <div className="SettingsModalGeneralSection">
      <Modal.Header closeButton>
        <Icon.Settings className="tw-hidden tw-text-bsGray-600 lg:tw-block" />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title as="h2" className="tw-hidden md:tw-block">
            <Translate>General Settings</Translate>
          </Modal.Title>
          <Translate as="p">Customize your workspace.</Translate>
        </div>
        <SettingsSectionPicker onChange={onSelect} value="general" />
      </Modal.Header>
      <Modal.Body>
        {!queryLoading && internalData && (
          <FinalForm.Form<FormValues>
            initialValues={{
              brandColor: internalData.brandColor,
              image: internalData.image,
              name: internalData.name,
              showAppointletBranding: internalData.showAppointletBranding,
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Media>
                  <Media.Body className="tw-mr-6">
                    <FinalForm.Field<string> name="name">
                      {({ input }) => (
                        <Form.Group>
                          <Form.Label>
                            <Translate>Workspace Name</Translate>
                          </Form.Label>
                          <Form.Control {...input} />
                        </Form.Group>
                      )}
                    </FinalForm.Field>
                  </Media.Body>
                  <FinalForm.Field<FormValues['image']> name="image">
                    {({ input: { onChange, value: image } }) => (
                      <ImageInput
                        crop="640x640"
                        onChange={onChange}
                        uploadOnly
                        value={image || null}
                      />
                    )}
                  </FinalForm.Field>
                </Media>
                <Media>
                  <Media.Body>
                    <FinalForm.Field<string> name="brandColor">
                      {({ input }) => (
                        <Form.Group>
                          <div className="tw-flex tw-items-center tw-mb-2 tw-space-x-2">
                            <Form.Label className="tw-flex tw-items-center tw-mb-0 tw-space-x-[5px]">
                              <Translate>Brand Color</Translate>
                            </Form.Label>
                            <HelpToolTip>
                              <Translate>
                                Determines the accent color used on your
                                scheduling pages and emails to customers.
                              </Translate>
                            </HelpToolTip>
                          </div>

                          <ColorPicker
                            onSelect={input.onChange}
                            value={input.value}
                          />
                        </Form.Group>
                      )}
                    </FinalForm.Field>
                  </Media.Body>
                </Media>
                <hr />
                <FinalForm.Field<boolean> name="showAppointletBranding">
                  {({ input: { onChange, value: isBrandingVisible } }) => (
                    <Form.Group>
                      <Media className="tw-flex-col md:tw-flex-row md:tw-items-center md:tw-justify-center">
                        <Media.Body>
                          <div className="tw-items-center tw-flex tw-space-x-2">
                            <Form.Label className="tw-mb-0">
                              <Translate>Hide Appointlet Branding</Translate>
                            </Form.Label>
                            <Badge variant="success">
                              <Translate>Premium</Translate>
                            </Badge>
                            {/* TODO: */}
                            <HelpToolTip></HelpToolTip>
                          </div>

                          <Form.Text className="tw-mb-4">
                            <Translate>
                              Remove the “Powered by Appointlet” badge on your
                              scheduling pages and emails.
                            </Translate>
                          </Form.Text>
                        </Media.Body>

                        <Switch
                          // When a profile is made this is True by default.
                          checked={!isBrandingVisible}
                          disabled={profile.plan.requireAppointletBranding}
                          className="tw-mb-4 md:tw-mb-0 md:tw-mr-[3px]"
                          onChange={() => onChange(!isBrandingVisible)}
                        />
                      </Media>
                      {profile.plan.requireAppointletBranding && (
                        <UnsupportedPlan source="branding" />
                      )}
                      <Illustration
                        className={expandClassName(
                          'tw-w-full',
                          profile.plan.requireAppointletBranding
                            ? 'tw-mt-4'
                            : undefined
                        )}
                        name="appointletBranding"
                      />
                    </Form.Group>
                  )}
                </FinalForm.Field>

                <Media>
                  <LoadingButton
                    loading={mutationLoading}
                    type="submit"
                    variant="success"
                  >
                    <Translate>Save Changes</Translate>
                  </LoadingButton>
                </Media>
              </Form>
            )}
          </FinalForm.Form>
        )}
      </Modal.Body>
    </div>
  )
}

export default SettingsModalGeneralSection
