import * as React from 'react'
import { encodeQueryParams, env } from '../utils'
import './Map.scss'

const apiKey = env('GOOGLE_MAPS_API_KEY')!

const baseUrl = 'https://www.google.com/maps/embed/v1/search'

interface Props {
  center: string
  zoom?: number
  mapType?: 'roadmap' | 'satellite' | 'hybrid' | 'terrain'
  language?: string
  region?: string
}

const buildMapUrl = (props: Props): string => {
  return (
    baseUrl +
    '?' +
    encodeQueryParams({
      q: props.center,
      zoom: props.zoom,
      mapType: props.mapType,
      language: props.language,
      region: props.region,
      key: apiKey,
    })
  )
}

const Map: React.FC<Props> = props => (
  <iframe
    className="Map w-100 border-0 rounded"
    src={buildMapUrl(props)}
    frameBorder="0"
    title={`Map of ${props.center}`}
  />
)

export default Map
