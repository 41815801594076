import { InternalUser } from '../components/UserContext'
import { env } from './env'
import TagManager from 'react-gtm-module'
import { AnalyticsEventAttributes } from './analytics'

const GOOGLE_TAG_MANAGER_ID = env('GOOGLE_TAG_MANAGER_ID')

const GoogleTagManagerHandler = {
  initialize: (user: InternalUser) => {
    console.log('GoogleTagManagerHandler.initialize', user)

    if (!GOOGLE_TAG_MANAGER_ID) {
      return Promise.resolve(false)
    }

    TagManager.initialize({
      dataLayer: {
        userId: user.id,
        userFirstName: user.firstName,
        userLastName: user.lastName,
        userEmail: user.email,
        userCreatedAt: user.createdAt.toUnix(),

        // This logic isn't really that sound -- it's going to apply to all
        // users who own at least one org.
        userIsOwner: !!user.members.find(member => member.isOwner),
      },
      gtmId: GOOGLE_TAG_MANAGER_ID,
    })

    return Promise.resolve(true)
  },

  trackEvent: (
    name: string,
    attributes?: AnalyticsEventAttributes
  ): Promise<boolean> => {
    console.log('GoogleTagManagerHandler.trackEvent', name, attributes)

    TagManager.dataLayer({
      dataLayer: {
        event: name,
        ...attributes,
      },
    })

    return Promise.resolve(true)
  },

  pageChanged: (): Promise<boolean> => {
    console.log('GoogleTagManagerHandler.pageChanged')

    // Not sure if there's anything we should do here.
    // It looks like GA is able to observe page changes
    // by polling window.location.

    return Promise.resolve(true)
  },
}

export default GoogleTagManagerHandler
