import * as React from 'react'
import MeetingDetailTabButton from '../../atoms/MeetingDetailTabButton/MeetingDetailTabButton'
import './MeetingDetailTabSelector.scss'

export interface MeetingDetailTabSelectorProps {
  readonly tabs: ReadonlyArray<Readonly<{ id: string; title: string }>>
  readonly onSetActiveTab: React.Dispatch<React.SetStateAction<string>>
  readonly activeTab: string
}

const MeetingDetailTabSelector: React.FC<MeetingDetailTabSelectorProps> = ({
  tabs,
  activeTab,
  onSetActiveTab,
}) => (
  <div className="MeetingDetailTabSelector d-flex justify-content-between flex-row space-between-8">
    {tabs.map(tab => (
      <div key={tab.id} className="tab-container">
        <MeetingDetailTabButton
          title={tab.title}
          onClick={() => onSetActiveTab(tab.id)}
          active={activeTab === tab.id}
        />
      </div>
    ))}
  </div>
)

export default MeetingDetailTabSelector
