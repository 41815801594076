import * as React from 'react'
import { gql } from '@apollo/client'
import {
  ConnectedMeetingDetailDrawerQuery,
  LocationType,
  Maybe,
  useConnectedMeetingDetailDrawerQuery,
  useCreateMeetingNoteMutation,
  useDeleteMeetingNoteMutation,
  useMeetingDetailDrawerReconnectConferencingAccountMutation,
  useUpdateMeetingNoteMutation,
} from '../../../../__generated__/graphql'
import PureMeetingDetailDrawer from '../PureMeetingDetailDrawer/PureMeetingDetailDrawer'
import {
  DateTime,
  DateTimeRange,
  startOAuthFlow,
  toast,
} from '../../../../utils'
import {
  Meeting,
  ConferencingAccount,
  ConferenceAccountTypeNames,
  ConferenceProvider,
  MeetingType,
  ID,
  AttendeeNode,
} from '../../../../types'
import Spinner from '../../../Spinner'
import UserContext from '../../../UserContext'
import RescheduleMeetingModal from '../../../RescheduleMeetingModal'
import CancelMeetingModal, {
  useCancelMeetingModal,
} from '../../../CancelMeetingModal'
import MemberContext from '../../../MemberContext'
import { AttendeeNote } from '../MeetingDetailNoteUnit/MeetingDetailNoteUnit'
import { Props as MeetingDetailDrawerHeaderProps } from '../MeetingDetailDrawerHeader/MeetingDetailDrawerHeader'
import ApproveMeetingModal, {
  useApproveMeetingModal,
} from '../../../ApproveMeetingModal'
import DeclineMeetingModal, {
  useDeclineMeetingModal,
} from '../../../DeclineMeetingModal'

import { AttendeeProps } from '../MeetingDetailAttendeeUnit/MeetingDetailAttendeeUnit'
import SettingsModal from '../../../SettingsModal'
import ApproveAttendeesModal, {
  useApproveAttendeesModal,
} from '../../../ApproveAttendeesModal'
import DeclineAttendeesModal, {
  useDeclineAttendeesModal,
} from '../../../DeclineAttendeesModal'
import CancelAttendeesModal, {
  useCancelAttendeesModal,
} from '../../../CancelAttendeesModal'
import UpdateAttendeeModal, {
  useUpdateAttendeeModal,
} from '../../../UpdateAttendeeModal'
import Now from '../../../Now'
import { MeetingConferenceLocationUnitProps } from '../../../molecules/MeetingConferenceLocationUnit/MeetingConferenceLocationUnit'

gql`
  query ConnectedMeetingDetailDrawer($id: ID!) {
    meeting: getMeetingById(id: $id) {
      approved
      attendees(orderBy: "-isHost,firstName") {
        edges {
          node {
            approved
            cancelled
            confirmed
            email
            fieldSubmissions {
              edges {
                node {
                  id
                  fieldType
                  name
                  value
                }
              }
            }
            firstName
            id
            isHost
            lastName
            member {
              id
              user {
                id
                image
              }
            }
            timezone
          }
        }
      }
      cancelled
      notes {
        edges {
          node {
            createdAt
            id
            deleted
            author {
              id
              user {
                id
                firstName
                lastName
                email
                image
              }
              profile {
                id
              }
            }
            body
          }
        }
      }
      conferencingAccount {
        __typename
        deleted
        id
        name
        provider {
          id
          name
          oauth2AuthorizationUrl
          slug
        }
      }
      conferenceId
      conferenceUrl
      end
      id
      timezone
      location
      locationType
      meetingType {
        id
        image
        name
        isGroup
        color
        timezone
        price
        conferencingProvider {
          name
          slug
          oauth2AuthorizationUrl
        }
      }
      name
      start
    }
  }
`

gql`
  mutation CreateMeetingNote($input: CreateMeetingNoteInput!) {
    createMeetingNote(input: $input) {
      data {
        createdAt
        id
        deleted
        body
        author {
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation UpdateMeetingNote($input: UpdateMeetingNoteInput!) {
    updateMeetingNote(input: $input) {
      data {
        createdAt
        id
        deleted
        body
        author {
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation DeleteMeetingNote($input: DeleteMeetingNoteInput!) {
    deleteMeetingNote(input: $input) {
      data {
        createdAt
        id
        deleted
        body
        author {
          id
        }
      }
    }
  }
`

gql`
  mutation MeetingDetailDrawerReconnectConferencingAccount(
    $input: ReconnectConferencingAccountInput!
  ) {
    reconnectConferencingAccount(input: $input) {
      data {
        __typename
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

export type InternalData = {
  meeting: Pick<
    Meeting,
    | 'approved'
    | 'cancelled'
    | 'conferenceId'
    | 'conferenceUrl'
    | 'id'
    | 'location'
    | 'locationType'
    | 'name'
    | 'timezone'
  > & {
    attendees: Array<AttendeeProps & { id: ID }>
    conferencingAccount: Maybe<
      Pick<ConferencingAccount, 'id' | 'name'> & {
        __typename: ConferenceAccountTypeNames
        deleted: Maybe<DateTime>
        provider: Pick<
          ConferenceProvider,
          'id' | 'name' | 'oauth2AuthorizationUrl' | 'slug'
        >
      }
    >
    end: DateTime
    meetingType: Pick<
      MeetingType,
      'id' | 'image' | 'name' | 'isGroup' | 'timezone' | 'color'
    > & {
      conferencingProvider: Pick<
        ConferenceProvider,
        'name' | 'oauth2AuthorizationUrl' | 'slug'
      > | null
    }
    start: DateTime
    // Computed Value
    isHost: string
    isPaidMeeting: boolean
    notes: ReadonlyArray<AttendeeNote>
  }
}

const getAttendeeStatus = (
  attendee: Pick<AttendeeNode, 'cancelled' | 'approved'>,
  isGroup: boolean,
  meetingCancelled: boolean
): 'going' | 'not-going' | 'pending' | null => {
  // For non-group meetings we don't have a displayed status.
  // Also don't display if the meeting itself is cancelled.
  if (!isGroup || meetingCancelled) return null

  // If they're cancelled show them as not going
  if (attendee.cancelled) {
    return 'not-going'
  }

  // If they haven't been approved show them as pending
  if (attendee.approved === null) {
    return 'pending'
  }

  // Otherwise show them as going
  return 'going'
}

const wireDataToInternalData = (
  wire: ConnectedMeetingDetailDrawerQuery,
  currentUserId: ID
): InternalData => ({
  meeting: {
    ...wire.meeting,
    attendees: wire.meeting.attendees.edges!.map(edge => ({
      ...edge!.node!,

      // Grab the image off the user if there is one.
      image: edge!.node!.member?.user.image ?? null,

      // Get the status of the attendee
      status: getAttendeeStatus(
        edge!.node!,
        wire.meeting.meetingType.isGroup,
        wire.meeting.cancelled
      ),

      // Make a note if this attendee is the currently logged in user
      isCurrentUser: edge!.node!.member?.user.id === currentUserId,

      // TODO: We need to query this data
      paymentAmount: null,
      paymentCurrency: null,
      paymentUrl: null,

      fieldSubmissions: edge!.node!.fieldSubmissions.edges!.map(
        edge => edge!.node!
      ),
    })),
    conferencingAccount: wire.meeting.conferencingAccount
      ? {
          ...wire.meeting.conferencingAccount,
          deleted: wire.meeting.conferencingAccount.deleted
            ? new DateTime(wire.meeting.conferencingAccount.deleted)
            : null,
        }
      : null,
    end: new DateTime(wire.meeting.end),
    start: new DateTime(wire.meeting.start),
    meetingType: {
      ...wire.meeting.meetingType,
    },
    // Computed Values
    isHost: wire.meeting.attendees.edges.filter(edge => edge!.node!.isHost)[0]!
      .node!.member!.id,
    isPaidMeeting: !!wire.meeting.meetingType.price,
    notes: wire.meeting.notes.edges.map(note => ({
      userId: note!.node!.author.user.id,
      id: note!.node!.id,
      email: note!.node!.author.user.email,
      image: note!.node!.author.user.image || '',
      firstName: note!.node!.author.user.firstName,
      lastName: note!.node!.author.user.lastName,
      time: new DateTime(note!.node!.createdAt),
      note: note!.node!.body,
    })),
  },
})

const getConferenceUnitProps = (
  internalData: InternalData
): MeetingConferenceLocationUnitProps | null => {
  // Start by handling Google Meet, which is a bit of a hack...
  if (internalData.meeting.locationType === LocationType.GoogleMeet) {
    return {
      url: internalData.meeting.conferenceUrl,
      conferenceProviderName: 'Google',
      conferenceProviderSlug: 'google',
    }
  }

  // If there is a conference account associated with the meeting,
  // scoop up it's data.
  if (internalData.meeting.conferencingAccount) {
    return {
      url: internalData.meeting.conferenceUrl,
      conferenceProviderName:
        internalData.meeting.conferencingAccount.provider.name,
      conferenceProviderSlug:
        internalData.meeting.conferencingAccount.provider.slug,
    }
  }

  // If there's no conferencing account assigned to the meeting,
  // then the user needs to connect one.
  if (internalData.meeting.meetingType.conferencingProvider) {
    return {
      url: null,
      conferenceProviderName:
        internalData.meeting.meetingType.conferencingProvider.name,
      conferenceProviderSlug:
        internalData.meeting.meetingType.conferencingProvider.slug,
    }
  }

  // If there's no conference data at all, just return null
  return null
}

type integrationError = 'needs_connect' | 'needs_reconnect' | null

const parseIntegrationErrors = (
  internalData: InternalData
): integrationError => {
  if (internalData.meeting.locationType === LocationType.WebConference) {
    if (internalData.meeting.conferencingAccount === null) {
      return 'needs_connect'
    }
    if (internalData.meeting.conferenceUrl === null) {
      return 'needs_reconnect'
    }
  }
  if (internalData.meeting.locationType === LocationType.GoogleMeet) {
    if (internalData.meeting.conferenceUrl === null) {
      return 'needs_connect'
    }
  }
  return null
}

// Returns the appropriate status to use for the MeetingDetailDrawerHeader
export const getMeetingHeaderStatus = (
  approved: boolean | null,
  cancelled: boolean,
  start: DateTime,
  end: DateTime,
  isGroup: boolean,
  pendingAttendees: number,
  now: DateTime
): MeetingDetailDrawerHeaderProps['status'] | undefined => {
  // Check cancelled state first, it overrides all other states.
  if (cancelled) return 'cancelled'

  // If the meeting is over.
  if (now.isAfter(end)) return 'concluded'

  // If manual approval is enabled next and the meeting is not a group.
  if (approved === null && !isGroup) return 'pending'

  // If manual approval is enabled and it's a group meeting with pending attendees
  if (approved === null && isGroup && pendingAttendees > 0) {
    return 'pending-attendees'
  }

  // Check any other time based constraint.
  if (now.isBefore(start)) return 'upcoming'
  if (now.isBetween(new DateTimeRange(start, end))) return 'in-progress'

  // This appeases TS, should never get here, but if we do it is likely the data is bad.
  return undefined
}

export type Props = Readonly<{
  meetingId: string
}>

const ConnectedMeetingDetailDrawer: React.FC<Props> = ({ meetingId }) => {
  // Prepare state
  const [showSettingsModal, setShowSettingsModal] =
    React.useState<boolean>(false)
  const [showRescheduleModal, setShowRescheduleModal] =
    React.useState<boolean>(false)

  const { user } = React.useContext(UserContext)
  const member = React.useContext(MemberContext)

  // Prepare query
  const { data, loading, refetch, startPolling, stopPolling } =
    useConnectedMeetingDetailDrawerQuery({
      variables: { id: meetingId },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    })

  // When the data is ready, convert it to a more
  // internal-friendly format and cache it.
  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data, user.id) : null),
    [data, user.id]
  )

  // Prep state for the CancelMeetingModal
  const {
    open: openCancelMeetingModal,
    close: closeCancelMeetingModal,
    toCancel: meetingToCancel,
    submit: submitCancelMeeting,
    submitting: submittingCancelMeeting,
  } = useCancelMeetingModal()

  // Prep state for the ApproveMeetingModal
  const {
    open: openApproveMeetingModal,
    close: closeApproveMeetingModal,
    toApprove: meetingToApprove,
    submit: submitApproveMeeting,
    submitting: submittingApproveMeeting,
  } = useApproveMeetingModal()

  // Prep state for the DeclineMeetingModal
  const {
    open: openDeclineMeetingModal,
    close: closeDeclineMeetingModal,
    toDecline: meetingToDecline,
    submit: submitDeclineMeeting,
    submitting: submittingDeclineMeeting,
  } = useDeclineMeetingModal()

  // Prep state for the ApproveAttendeesModal
  const {
    open: openApproveAttendeesModal,
    close: closeApproveAttendeesModal,
    toApprove: attendeesToApprove,
    submit: submitApproveAttendees,
    submitting: submittingApproveAttendees,
  } = useApproveAttendeesModal()

  // Prep state for the CancelAttendeesModal
  const {
    open: openCancelAttendeesModal,
    close: closeCancelAttendeesModal,
    toCancel: attendeesToCancel,
    submit: submitCancelAttendees,
    submitting: submittingCancelAttendees,
  } = useCancelAttendeesModal()

  // Prep state for the DeclineAttendeesModal
  const {
    open: openDeclineAttendeesModal,
    close: closeDeclineAttendeesModal,
    toDecline: attendeesToDecline,
    submit: submitDeclineAttendees,
    submitting: submittingDeclineAttendees,
  } = useDeclineAttendeesModal()

  // Prep state for the UpdateAttendeeModal
  const {
    open: openUpdateAttendeeModal,
    close: closeUpdateAttendeeModal,
    isOpen: updateAttendeeModalIsOpen,
    data: attendeeToUpdateData,
    submit: submitUpdateAttendee,
    submitting: submittingUpdateAttendee,
  } = useUpdateAttendeeModal()

  // Prepare mutations
  const [createMeetingNote] = useCreateMeetingNoteMutation()
  const [updateMeetingNote] = useUpdateMeetingNoteMutation()
  const [deleteMeetingNote] = useDeleteMeetingNoteMutation()
  const [reconnectConferencingAccount] =
    useMeetingDetailDrawerReconnectConferencingAccountMutation()

  const handleCreateNote = async (note: string | null) => {
    try {
      await createMeetingNote({
        variables: {
          input: {
            meeting: internalData!.meeting.id,
            body: note || '',
          },
        },
      })

      refetch()
    } catch (error) {
      console.error('createMeetingNote mutation: ', error)
      toast.error(`Something went wrong`)
      return
    }
  }

  const handleUpdateNote = async (note: AttendeeNote | null) => {
    try {
      await updateMeetingNote({
        variables: {
          input: {
            id: note!.id,
            body: note!.note || '',
          },
        },
      })

      refetch()
    } catch (error) {
      console.error('updateMeetingNote mutation: ', error)
      toast.error(`Something went wrong`)
      return
    }
  }

  const handleDeleteNote = async (noteId: string) => {
    try {
      await deleteMeetingNote({
        variables: {
          input: {
            id: noteId,
          },
        },
      })

      refetch()
    } catch (error) {
      console.error('deleteMeetingNote mutation: ', error)
      toast.error(`Something went wrong`)
      return
    }
  }

  const handleReconnectConferencingAccount = async () => {
    try {
      const code = await startOAuthFlow(
        internalData!.meeting.conferencingAccount!.provider
          .oauth2AuthorizationUrl
      )

      await reconnectConferencingAccount({
        variables: {
          input: {
            id: internalData!.meeting.conferencingAccount!.id,
            oauth2Code: code,
          },
        },
      })
      // meeting url is updated async after reconnecting.
      // start polling once a second to get new meeting url
      startPolling(1000)
      toast.success(
        `Reconnected ${
          internalData!.meeting.conferencingAccount!.name
        } successfully`
      )
    } catch (error) {
      stopPolling()
      console.error('reconnectConferencingAccount mutation: ', error)
      toast.error(`Something went wrong`)
      return
    }
  }

  React.useEffect(() => {
    // we poll the backend for meeting info after reconnecting conferencing providers
    // if polling, stop once a conference url is present
    if (!!data?.meeting.conferenceUrl) {
      stopPolling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // Show loader if we haven't finished fetching our data.
  if (loading || !internalData) {
    return (
      <div className="Drawer-spinner">
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      </div>
    )
  }

  // Get a list of attendees in a pending state
  const pendingAttendees = internalData.meeting.attendees.filter(
    attendee => attendee.status === 'pending'
  )

  return (
    <>
      <Now>
        {now => (
          <PureMeetingDetailDrawer
            topBarMeta={{
              onApprove: () => openApproveMeetingModal(internalData.meeting.id),
              onCancel: () => openCancelMeetingModal(internalData.meeting.id),
              onDecline: () => openDeclineMeetingModal(internalData.meeting.id),
              onReschedule: () => setShowRescheduleModal(true),
              status: getMeetingHeaderStatus(
                internalData.meeting.approved,
                internalData.meeting.cancelled,
                internalData.meeting.start,
                internalData.meeting.end,
                internalData.meeting.meetingType.isGroup,
                pendingAttendees.length,
                now
              )!,
              pendingAttendeesCount: pendingAttendees.length,
            }}
            summaryMeta={{
              headerProps: {
                name: internalData.meeting.name,
                color: internalData.meeting.meetingType.color,
              },
              timeUnitProps: {
                start: internalData.meeting.start,
                end: internalData.meeting.end,
                timezone: internalData.meeting.timezone,
              },
              locationType: internalData.meeting.locationType,
              location: internalData.meeting.location,
              conferenceUnitProps: getConferenceUnitProps(internalData),
              integrationError: parseIntegrationErrors(internalData),
              isHost: internalData.meeting.isHost === member.id,
              onConnectConferencingAccount: () => setShowSettingsModal(true),
              onReconnectConferencingAccount:
                handleReconnectConferencingAccount,
            }}
            detailViewMeta={{
              attendeesMeta: {
                attendees: internalData.meeting.attendees,
                meetingHasConcluded: now.isAfter(internalData.meeting.end),
                onApprove: openApproveAttendeesModal,
                onDecline: openDeclineAttendeesModal,
                onCancel: openCancelAttendeesModal,
                onEdit: openUpdateAttendeeModal,
              },
              notesMeta: {
                notes: internalData.meeting.notes,
                editCallback: handleUpdateNote,
                deleteCallback: handleDeleteNote,
                loggedInUserId: user.id,
                onNoteSubmit: handleCreateNote,
              },
            }}
          />
        )}
      </Now>

      {/* We use this in the "connect new web conference" flow. */}
      {!!showSettingsModal && (
        <SettingsModal
          initialSection={
            internalData.meeting.locationType === LocationType.WebConference
              ? 'web-conferencing'
              : 'calendars'
          }
          onHide={() => {
            setShowSettingsModal(false)

            // We need to refetch data to see if the conferencing account connected.
            refetch()
          }}
        />
      )}

      {showRescheduleModal && (
        <RescheduleMeetingModal
          meeting={internalData.meeting}
          onHide={() => setShowRescheduleModal(false)}
          onSuccess={() => setShowRescheduleModal(false)}
        />
      )}

      {meetingToCancel && (
        <CancelMeetingModal
          name={internalData.meeting.name}
          submitting={submittingCancelMeeting}
          onCancel={closeCancelMeetingModal}
          onConfirm={async message => {
            try {
              await submitCancelMeeting(meetingToCancel, message)
            } catch (e) {
              toast.error('Something went wrong')
            }
          }}
        />
      )}

      {meetingToApprove && (
        <ApproveMeetingModal
          name={internalData.meeting.name}
          submitting={submittingApproveMeeting}
          onCancel={closeApproveMeetingModal}
          onConfirm={async message => {
            try {
              await submitApproveMeeting(meetingToApprove, message)
            } catch (e) {
              toast.error('Something went wrong')
            }
          }}
        />
      )}

      {meetingToDecline && (
        <DeclineMeetingModal
          name={internalData.meeting.name}
          submitting={submittingDeclineMeeting}
          onCancel={closeDeclineMeetingModal}
          onConfirm={async message => {
            try {
              await submitDeclineMeeting(meetingToDecline, message)
            } catch (e) {
              toast.error('Something went wrong')
            }
          }}
        />
      )}

      {attendeesToApprove && (
        <ApproveAttendeesModal
          // Use our internalData to look up information about the attendees that are
          // being approved and pass it off to the modal.
          attendees={internalData.meeting.attendees
            .filter(attendee => attendeesToApprove.indexOf(attendee.id) !== -1)
            .map(attendee => ({
              firstName: attendee.firstName,
              lastName: attendee.lastName,
            }))}
          submitting={submittingApproveAttendees}
          onCancel={closeApproveAttendeesModal}
          onConfirm={async message => {
            try {
              const attendees = await submitApproveAttendees(message)

              if (attendees.length === 1) {
                toast.success('Attendee Approved')
              } else {
                toast.success(`${attendees.length} Attendees Approved`)
              }
            } catch (e) {
              toast.error('Something went wrong')
            }
          }}
        />
      )}

      {attendeesToDecline && (
        <DeclineAttendeesModal
          // Use our internalData to look up information about the attendees that are
          // being declined and pass it off to the modal.
          attendees={internalData.meeting.attendees
            .filter(attendee => attendeesToDecline.indexOf(attendee.id) !== -1)
            .map(attendee => ({
              firstName: attendee.firstName,
              lastName: attendee.lastName,
            }))}
          submitting={submittingDeclineAttendees}
          onCancel={closeDeclineAttendeesModal}
          onConfirm={async message => {
            try {
              const attendees = await submitDeclineAttendees(message)

              if (attendees.length === 1) {
                toast.success('Attendee Declined')
              } else {
                toast.success(`${attendees.length} Attendees Declined`)
              }
            } catch (e) {
              toast.error('Something went wrong')
            }
          }}
        />
      )}

      {attendeesToCancel && (
        <CancelAttendeesModal
          // Use our internalData to look up information about the attendees that are
          // being cancelled and pass it off to the modal.
          attendees={internalData.meeting.attendees
            .filter(attendee => attendeesToCancel.indexOf(attendee.id) !== -1)
            .map(attendee => ({
              firstName: attendee.firstName,
              lastName: attendee.lastName,
            }))}
          submitting={submittingCancelAttendees}
          onCancel={closeCancelAttendeesModal}
          onConfirm={async message => {
            try {
              const attendees = await submitCancelAttendees(
                internalData.meeting.id,
                message
              )

              if (attendees.length === 1) {
                toast.success('Attendee Cancelled')
              } else {
                toast.success(`${attendees.length} Attendees Cancelled`)
              }
            } catch (e) {
              toast.error('Something went wrong')
            }
          }}
        />
      )}

      {updateAttendeeModalIsOpen && attendeeToUpdateData && (
        <UpdateAttendeeModal
          {...attendeeToUpdateData}
          submitting={submittingUpdateAttendee}
          onConfirm={async values => {
            try {
              await submitUpdateAttendee(values)
            } catch (e) {
              toast.error('Something went wrong')
            }
          }}
          onCancel={closeUpdateAttendeeModal}
        />
      )}
    </>
  )
}

export default ConnectedMeetingDetailDrawer
