import { InternalUser } from '../components/UserContext'
import { env } from './env'

const LOCALIZE_PROJECT_KEY = env('LOCALIZE_PROJECT_KEY')

const LocalizeHandler = {
  initialize: (user: InternalUser) => {
    console.log('LocalizeHandler.initialize', user)

    if (!LOCALIZE_PROJECT_KEY) {
      return Promise.resolve(false)
    }

    window.Localize.initialize({
      disableWidget: !Boolean(process.env.LOCALIZE_WIDGET),
      key: LOCALIZE_PROJECT_KEY,
      rememberLanguage: false,
      // TYPESCRIPT:
      // @ts-ignore
      targetLanguage: user.language.toLowerCase(),
      translateClasses: [
        'appointlet-translate',
        // This is related to the shepherd-js tour library we
        // are using for our product tours.  It doesn't have an
        // easy way to get into the title DOM structure to add
        // the appointlet-translate class, so for now we're just
        // going to put this here.
        'shepherd-title',
      ],
    })

    return Promise.resolve(true)
  },
}

export default LocalizeHandler
