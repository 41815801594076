import * as React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { gql } from '@apollo/client'
import { useParams } from '@reach/router'
import Icon from './Icon'
import LoadingButton from './LoadingButton'
import {
  useRemoveMemberModalMutation,
  MeetingTypesPageDocument,
} from '../__generated__/graphql'
import { Member, User } from '../types'
import { toast } from '../utils'
import { generateUserLabel } from './UserLabel'
import Translate from './Translate'
import Var from './Var'

gql`
  mutation RemoveMemberModal($input: DeleteMemberInput!) {
    deleteMember(input: $input) {
      data {
        deleted
        id
      }
    }
  }
`

export type Props = {
  member: Pick<Member, 'id'> & {
    user: Pick<
      User,
      '__typename' | 'email' | 'firstName' | 'id' | 'image' | 'lastName'
    >
  }
  onHide: () => void
  onSuccess: () => void
}

const RemoveMemberModal: React.FC<Props> = ({ member, onHide, onSuccess }) => {
  const params = useParams()

  const [deleteMember, { loading }] = useRemoveMemberModalMutation({
    awaitRefetchQueries: true,
    // If we are on the BookingPage when the user chooses to delete a member
    // check the URL for the schedulingPageId param. We need it to refetch the
    // MeetingTypesPage query.
    refetchQueries: params.schedulingPageId
      ? [
          {
            query: MeetingTypesPageDocument,
            variables: { id: params.schedulingPageId },
          },
        ]
      : undefined,
  })

  const onClick = async () => {
    try {
      const res = await deleteMember({
        variables: { input: { id: member.id } },
      })

      // Notify the user
      if (res.data?.deleteMember?.data?.deleted) {
        toast.success('Removed Member')
        onSuccess()
      }
      // Here we are catching sever related errors.
    } catch (error) {
      toast.error('Something went wrong')
      console.error('setProfileOwner mutation', error)
    }
  }
  return (
    <Modal className="RemoveMemberModal" onHide={onHide} show size="sm">
      <Modal.Header closeButton>
        <Icon.UserMinus className="tw-text-bsSuccess" size={36} />
        <Modal.Title>
          <Translate>Remove Member</Translate>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Translate as="p">
          Are you sure you want to remove{' '}
          <Var name="user">{generateUserLabel(member.user)}</Var>?
        </Translate>
        <Translate as="p">
          They will be removed from all meeting types and their scheduling pages
          deactivated, but existing meetings will be unaffected.
        </Translate>
      </Modal.Body>
      <Modal.Footer className="tw-items-stretch tw-justify-between md:tw-justify-end">
        <Button
          className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
          variant="outline-secondary"
          onClick={onHide}
        >
          <Translate>Cancel</Translate>
        </Button>

        <LoadingButton
          className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
          loading={loading}
          onClick={onClick}
          variant="danger"
        >
          <Translate>Remove Member</Translate>
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

export default RemoveMemberModal
