import * as React from 'react'

export interface OnboardTitleProps {
  title: string
  subtitle?: string
}

export const OnboardTitle: React.FC<OnboardTitleProps> = ({
  title,
  subtitle,
}: OnboardTitleProps) => {
  return (
    <div>
      <h1>{title}</h1>
      {!!subtitle && (
        <h5 className="subtitle text-gray-600 mt-4">{subtitle}</h5>
      )}
    </div>
  )
}
