import * as React from 'react'
import { Alert, ListGroup, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'
import Badge from './Badge'

import MemberContext from './MemberContext'
import PaymentProviderRow from './PaymentProviderRow'
import ProfileContext from './ProfileContext'
import UnSupportedPlan from './UnsupportedPlan'
import {
  Maybe,
  SettingsModalPaymentSectionQuery as Response,
  useSettingsModalPaymentSectionQuery,
} from '../__generated__/graphql'
import { PaymentCollectionAccount, PaymentCollectionProvider } from '../types'
import { DateTime, Analytics } from '../utils'
import Translate from './Translate'
import SettingsSectionPicker from './SettingsSectionPicker'
import { SettingsSection } from './SettingsModal'
import Icon from './Icon'

gql`
  query SettingsModalPaymentSection($profile: ID!) {
    profile: getProfileById(id: $profile) {
      id
      paymentCollectionAccounts {
        edges {
          node {
            deleted
            id
            name
            provider {
              id
              name
              oauth2AuthorizationUrl(profile: $profile)
              slug
            }
          }
        }
      }
    }
    providers: getPaymentCollectionProviders {
      edges {
        node {
          id
          name
          oauth2AuthorizationUrl(profile: $profile)
          slug
        }
      }
    }
  }
`

type Props = {
  onSelect: (section: SettingsSection) => void
}

export type PaymentSectionData = {
  paymentCollectionAccounts: Array<
    Pick<PaymentCollectionAccount, 'deleted' | 'id' | 'name'> & {
      provider: Pick<
        PaymentCollectionProvider,
        'id' | 'name' | 'oauth2AuthorizationUrl' | 'slug'
      >
    }
  >
  providers: Array<PaymentCollectionProvider>
}

const wireDataToInternalData = (wire: Response): PaymentSectionData => ({
  paymentCollectionAccounts: wire.profile.paymentCollectionAccounts.edges.map(
    edge => ({
      ...edge!.node!,
      deleted: edge!.node!.deleted ? new DateTime(edge!.node!.deleted) : null,
    })
  ),
  providers: wire.providers.edges.map(edge => ({ ...edge!.node! })),
})

const SettingsModalPaymentsSection: React.FC<Props> = ({ onSelect }) => {
  const member = React.useContext(MemberContext)
  const profile = React.useContext(ProfileContext)
  const { data, loading, refetch } = useSettingsModalPaymentSectionQuery({
    variables: { profile: profile.id },
  })
  const internalData: Maybe<PaymentSectionData> = React.useMemo(
    () => (data ? wireDataToInternalData(data) : null),
    [data]
  )
  return (
    <div className="SettingsModalPaymentsSection">
      <Modal.Header closeButton>
        <Icon.ShoppingCart className="tw-hidden tw-text-bsGray-600 lg:tw-block" />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title as="h2" className="tw-hidden md:tw-flex md:tw-space-x-2">
            <Translate>Payment Collection</Translate>
            <Badge
              className="tw-flex tw-items-center tw-justify-center"
              variant="success"
            >
              <Translate>Premium</Translate>
            </Badge>
          </Modal.Title>
          <Translate as="p">
            Start collecting payments when users book.
          </Translate>
        </div>
        <SettingsSectionPicker onChange={onSelect} value="payments" />
      </Modal.Header>
      <Modal.Body>
        <div className="tw-flex tw-flex-col tw-space-y-6 tw-mb-6">
          <Translate as="p">
            Want to collect payments for your services? Connect your favorite
            payment processor and attendees will be asked to pay when they book
            with you.
          </Translate>
          {/* If the user does not have permissions then let them know. */}
          {!member.role.canManageOrgSettings ? (
            <Alert className="tw-text-bsGray-900" variant="danger">
              <Translate>
                You don’t have permission to manage payment integrations.
              </Translate>
            </Alert>
          ) : // If the user does not have a Premium plan let them know.
          !profile.plan.allowPaymentCollection ? (
            <UnSupportedPlan source="paymentCollection" />
          ) : null}
        </div>

        <ListGroup>
          {!loading &&
            internalData &&
            internalData.providers.map(provider => (
              <ListGroup.Item key={provider.id}>
                <PaymentProviderRow
                  // If there is a non-deleted account that matches the provider, pass it in.
                  account={internalData.paymentCollectionAccounts.find(
                    account =>
                      !account.deleted && account.provider.id === provider.id
                  )}
                  onConnect={() => {
                    refetch()

                    Analytics.trackEvent('Connected Payment Integration', {
                      source: 'settings',
                      provider: provider.slug,
                    })
                  }}
                  provider={provider}
                />
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Modal.Body>
    </div>
  )
}

export default SettingsModalPaymentsSection
