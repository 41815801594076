import * as React from 'react'
import { env } from '../utils'

type Props = {
  color?: string
  isInline?: boolean
  text?: string
  url: string
}

const snippetURL = env('APPOINTLET_JS_URL')
const stylesheetSnippetURL = env(
  'APPOINTLET_JS_STYLESHEET_URL',
  `${snippetURL}styles.css`
)

export const snippet = (args: Props) =>
  `<script async defer src="${snippetURL}"></script>\n<link href="${stylesheetSnippetURL}" rel="stylesheet">\n${
    !!args.isInline
      ? `<a class="appointlet-button" data-appointlet-modal href="${args.url}" style="background-color:${args.color};">${args.text}</a>`
      : `<div class="appointlet-inline" data-appointlet-inline="${args.url}"></div>`
  }`

const AppointletSnippet: React.FC<Props> = props => {
  return (
    <pre className="tw-flex tw-flex-col tw-mb-0 tw-space-y-[2px]">
      {snippet(props)
        .split('\n')
        .map((snippet, i) => (
          <code key={`snippet--${i}`}>{snippet}</code>
        ))}
    </pre>
  )
}

export default AppointletSnippet
