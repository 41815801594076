import * as React from 'react'
import { navigate, RouteComponentProps } from '@reach/router'
import { gql } from '@apollo/client'
import {
  useOnboardingCreateMeetingTypePageDataQuery,
  useOnboardingCreateMeetingTypePageCreateMutation,
  useOnboardingCreateMeetingTypePageDeleteAndCreateMutation,
} from '../../../__generated__/graphql'
import UserContext from '../../UserContext'
import MemberContext from '../../MemberContext'
import ProfileContext from '../../ProfileContext'
import {
  CreateMeetingTypeScreen,
  FormValues as CreateMeetingTypeScreenFormValues,
} from '../screens/CreateMeetingTypeScreen/CreateMeetingTypeScreen'
import {
  Analytics,
  Duration,
  mutationErrorsToFormErrors,
  toast,
} from '../../../utils'
import slugify from '@sindresorhus/slugify'
import { useOnboardingState } from '../../../hooks/useOnboardingState'
import { ID } from '../../../types'

gql`
  query OnboardingCreateMeetingTypePageData($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`

gql`
  mutation OnboardingCreateMeetingTypePageCreate(
    $input: CreateMeetingTypeInput!
  ) {
    createMeetingType(input: $input) {
      data {
        id
        name
        duration
        isGroup
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation OnboardingCreateMeetingTypePageDeleteAndCreate(
    $delete: DeleteMeetingTypeInput!
    $input: CreateMeetingTypeInput!
  ) {
    deleteMeetingType(input: $delete) {
      data {
        id
        deleted
      }
    }

    createMeetingType(input: $input) {
      data {
        id
        name
        duration
        isGroup
      }
      errors {
        field
        messages
      }
    }
  }
`

const OnboardingCreateMeetingTypePage: React.FC<RouteComponentProps> = () => {
  const { user } = React.useContext(UserContext)
  const member = React.useContext(MemberContext)
  const profile = React.useContext(ProfileContext)

  React.useEffect(() => {
    Analytics.trackEvent('Onboarding Meeting Type Page: Loaded')
  }, [])

  // Get access to our onboarding state
  const [onboardingState, setOnboardingState] = useOnboardingState()

  // Prepare the query
  // TODO: this query only is useful in obtaining the teamId for the
  // profile.  In the future if we use the useOnboardingState to it's fullest
  // we could store the teamId there and not have this query at all.
  const { data } = useOnboardingCreateMeetingTypePageDataQuery({
    variables: { id: member.id },
  })

  // Prepare the create mutation
  const [createMeetingTypeMutation, { loading: isCreating }] =
    useOnboardingCreateMeetingTypePageCreateMutation()

  // Prepare the update mutation, which we'll use if the user pressed the back
  // button and wants to make changes to the existing meeting type.
  const [
    deleteAndCreateMeetingTypeMutation,
    { loading: isDeletingAndCreating },
  ] = useOnboardingCreateMeetingTypePageDeleteAndCreateMutation()

  const onSubmit = async (values: CreateMeetingTypeScreenFormValues) => {
    const createMeetingTypeInput = {
      profile: profile.id,
      team: data!.member!.teams.edges[0]!.node!.id,
      name: values.name,
      duration: values.duration.asSeconds().toString(),
      isGroup: values.isGroup,
      slug: slugify(values.name),
    }

    try {
      // Create a space to store the meeting type ID after we create it.
      let newMeetingTypeId: ID

      // Check to see if the user has already created a meeting type, in which
      // case we'll update it.
      if (onboardingState.meetingType) {
        // Try and update the meeting type
        const response = await deleteAndCreateMeetingTypeMutation({
          variables: {
            delete: {
              id: onboardingState.meetingType.id,
            },
            input: createMeetingTypeInput,
          },
        })

        // Handle any validation errors
        if (response.data?.createMeetingType?.errors) {
          return mutationErrorsToFormErrors(
            response.data.createMeetingType.errors
          )
        }

        // Store the id
        newMeetingTypeId = response.data!.createMeetingType!.data!.id
      } else {
        // Try and create the meeting type
        const response = await createMeetingTypeMutation({
          variables: {
            input: createMeetingTypeInput,
          },
        })

        // Handle any validation errors
        if (response.data?.createMeetingType?.errors) {
          return mutationErrorsToFormErrors(
            response.data.createMeetingType.errors
          )
        }

        // Store the id
        newMeetingTypeId = response.data!.createMeetingType!.data!.id
      }

      // Persist in case the user comes back
      setOnboardingState({
        ...onboardingState,
        meetingType: {
          id: newMeetingTypeId,
          name: values.name,
          slug: slugify(values.name),
          duration: values.duration.asSeconds(),
          isGroup: values.isGroup,
        },
      })

      // Analytics
      Analytics.trackEvent('Onboarding Meeting Type Page: Submitted')

      // Move the user to the next page
      await navigateToNextPage()
    } catch (error) {
      toast.error('Something went wrong')
      console.error('Failed to create meeting type', error)
    }
  }

  // Move the user to the next step
  const navigateToNextPage = async () => {
    await navigate(`/profiles/${profile.id}/onboarding/calendars`)
  }

  return (
    <CreateMeetingTypeScreen
      email={user.email}
      initialName={onboardingState.meetingType?.name ?? ''}
      initialDuration={
        onboardingState.meetingType
          ? new Duration(onboardingState.meetingType.duration)
          : new Duration({ minutes: 30 })
      }
      initialIsGroup={onboardingState.meetingType?.isGroup ?? false}
      isSubmitting={isCreating || isDeletingAndCreating}
      onSubmit={onSubmit}
      onSkip={navigateToNextPage}
    />
  )
}

export default OnboardingCreateMeetingTypePage
