import * as React from 'react'
import G from 'react-gravatar'

interface Props {
  email: string
  size?: number
  hideIfNone?: boolean
}

const Gravatar: React.FC<Props> = ({ email, size, hideIfNone }) => (
  <div className="Gravatar">
    <G
      email={email}
      size={size ? size : 150}
      default={hideIfNone ? 'blank' : undefined}
    />
  </div>
)

export default Gravatar
