import * as React from 'react'
import { LocationType } from '../../../../__generated__/graphql'
import './MeetingDetailSummary.scss'
import CopyButton from '../../../CopyButton'
import Icon from '../../../Icon'
import MeetingConferenceError from '../../../molecules/MeetingConferenceError/MeetingConferenceError'
import MeetingConferenceLocationUnit, {
  MeetingConferenceLocationUnitProps,
} from '../../../molecules/MeetingConferenceLocationUnit/MeetingConferenceLocationUnit'
import MeetingDetailHeader, {
  MeetingDetailHeaderProps,
} from '../../../molecules/MeetingDetailHeader/MeetingDetailHeader'
import MeetingPlaceLocationUnit from '../../../molecules/MeetingPlaceLocationUnit/MeetingPlaceLocationUnit'
import MeetingTimeUnit, {
  MeetingTimeUnitProps,
} from '../../../molecules/MeetingTimeUnit/MeetingTimeUnit'

export type MeetingDetailSummaryProps = Readonly<{
  headerProps: MeetingDetailHeaderProps
  timeUnitProps: MeetingTimeUnitProps
  locationType: LocationType | null
  location: string | null
  conferenceUnitProps: MeetingConferenceLocationUnitProps | null
  integrationError: 'needs_connect' | 'needs_reconnect' | null
  isHost: boolean

  onConnectConferencingAccount?: () => void
  onReconnectConferencingAccount?: () => void
}>

export const MeetingDetailSummary: React.FC<MeetingDetailSummaryProps> = ({
  headerProps,
  timeUnitProps,
  locationType,
  location,
  conferenceUnitProps,
  integrationError,
  isHost,
  onConnectConferencingAccount,
  onReconnectConferencingAccount,
}) => {
  const isPlaceMeeting =
    locationType === LocationType.Place ||
    locationType === LocationType.PlaceUser
  const isConferenceMeeting =
    locationType === LocationType.WebConference ||
    locationType === LocationType.GoogleMeet
  const isPhoneMeeting =
    locationType === LocationType.Phone ||
    locationType === LocationType.PhoneUser

  return (
    <div className="MeetingDetailSummary d-flex flex-column space-between-12">
      <MeetingDetailHeader {...headerProps} />

      <MeetingTimeUnit {...timeUnitProps} />

      {isPlaceMeeting && <MeetingPlaceLocationUnit address={location} />}

      {/* TODO: make this into it's own component? */}
      {isPhoneMeeting && (
        <div className="d-flex align-items-center space-between-8">
          <Icon.Phone size={20} strokeWidth={1} className="text-gray-500" />
          <span>{location || <em>No phone number provided</em>}</span>
          {location && <CopyButton text={location} />}
        </div>
      )}

      {isConferenceMeeting &&
        conferenceUnitProps &&
        integrationError === null && (
          <MeetingConferenceLocationUnit {...conferenceUnitProps} />
        )}

      {isConferenceMeeting &&
        conferenceUnitProps &&
        integrationError !== null && (
          <MeetingConferenceError
            provider={conferenceUnitProps}
            disabled={!isHost}
            onConnect={onConnectConferencingAccount}
            onReconnect={onReconnectConferencingAccount}
            isHost={isHost}
            integrationError={integrationError}
          />
        )}

      {locationType === null && (
        <div className="d-flex align-items-center space-between-8">
          <Icon.MapPin size={20} strokeWidth={1} className="text-gray-500" />
          <span>No location has been specified.</span>
        </div>
      )}
    </div>
  )
}
