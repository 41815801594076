import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import Icon from './Icon'
import LoadingButton from './LoadingButton'
import { InternalData } from './MeetingTypesPage'
import { useDeleteSchedulingPageModalMutation } from '../__generated__/graphql'
import { toast } from '../utils'
import Translate from './Translate'
import Var from './Var'

export type Props = {
  onHide: () => void
  onSuccess: () => void
  schedulingPage: Pick<InternalData['team'], 'id' | 'name'>
}

gql`
  mutation DeleteSchedulingPageModal($input: DeleteTeamInput!) {
    schedulingPage: deleteTeam(input: $input) {
      data {
        id
        deleted
      }
    }
  }
`

const DeleteSchedulingPageModal: React.FC<Props> = ({
  onHide,
  onSuccess,
  schedulingPage,
}) => {
  const [deleteSchedulingPage, { loading }] =
    useDeleteSchedulingPageModalMutation()
  const onClick = async () => {
    try {
      const res = await deleteSchedulingPage({
        variables: { input: { id: schedulingPage.id } },
      })
      // If client validation and GraphQL error related errors exist
      if (!res.data?.schedulingPage?.data) {
        console.error('deleteTeam mutation, [DeleteSchedulingPageModal.tsx]')
        return
      }
      // If data is present trigger the toast to display and close the modal
      if (res.data.schedulingPage.data) {
        toast.success(`Deleted Scheduling Page`, schedulingPage.name)
        onSuccess()
        return
      } // Here we are catching sever related errors.
    } catch (err) {
      toast.error('Something went wrong')
      console.error('deleteTeam mutation, [DeleteSchedulingPageModal.tsx]', err)
    }
  }
  return (
    <Modal className="DeleteSchedulingPageModal" onHide={onHide} show size="sm">
      <Modal.Header closeButton>
        <Icon.Users className="tw-text-bsDanger" size={30} />
        <Modal.Title>
          <Translate>Delete Scheduling Page</Translate>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="tw-text-base tw-font-normal">
          <Translate>
            You are about to delete{' '}
            <Var name="name">
              <strong>{schedulingPage.name}</strong>
            </Var>
            . Are you sure you want to do that?
          </Translate>
        </p>

        <p className="tw-text-bsGray-600">
          <Translate>Existing meetings will not be affected.</Translate>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <LoadingButton
          className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
          loading={loading}
          onClick={onClick}
          variant="danger"
        >
          <Translate>Delete Scheduling Page</Translate>
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteSchedulingPageModal
