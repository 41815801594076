import * as React from 'react'
import { Media } from 'react-bootstrap'
import ColoredDot from '../../ColoredDot'

export type MeetingDetailHeaderProps = {
  name: string
  color: string
}

const MeetingDetailHeader: React.FC<MeetingDetailHeaderProps> = ({
  name,
  color,
}) => (
  <Media className="MeetingDetailHeader align-items-center space-between-8">
    <ColoredDot size={16} color={color} />

    <Media.Body>
      <h2 className="font-weight-bolder">{name}</h2>
    </Media.Body>
  </Media>
)

export default MeetingDetailHeader
