import * as React from 'react'

import { Maybe } from '../__generated__/graphql'
import { Schedule, ScheduleKind, TimeBlock } from '../types'
import {
  DateTimeRange,
  DateTime,
  getDefaultFixedTimeBlocks,
  getDefaultRecurringTimeBlocks,
} from '../utils'

type ScheduleDataHook = (schedule: Schedule) => ScheduleDataHookReturn
type ScheduleDataHookReturn = {
  fixedBounds: DateTimeRange
  fixedTimeBlocks: Array<TimeBlock>
  recurringBounds: Maybe<DateTimeRange>
  recurringTimeBlocks: Array<TimeBlock>
  setFixedBounds: React.Dispatch<React.SetStateAction<DateTimeRange>>
  setFixedTimeBlocks: React.Dispatch<React.SetStateAction<Array<TimeBlock>>>
  setRecurringBounds: React.Dispatch<React.SetStateAction<Maybe<DateTimeRange>>>
  setRecurringTimeBlocks: React.Dispatch<React.SetStateAction<Array<TimeBlock>>>
}

export const useScheduleData: ScheduleDataHook = schedule => {
  const [fixedBounds, setFixedBounds] = React.useState<DateTimeRange>(() => {
    if (schedule.kind === ScheduleKind.FixedRange && schedule.bounds) {
      return schedule.bounds
    }

    const today = new DateTime()

    return new DateTimeRange(today, today.add({ days: 6 }))
  })

  const [recurringBounds, setRecurringBounds] = React.useState<
    Maybe<DateTimeRange>
  >(() => {
    if (schedule.kind === ScheduleKind.Recurring) {
      return schedule.bounds
    }

    return null
  })

  // We need to keep separate track of the recurring TimeBlocks because we'd like it to
  //  persist between switches in the schedule kind.
  const [recurringTimeBlocks, setRecurringTimeBlocks] = React.useState<
    TimeBlock[]
  >(() => {
    if (schedule.kind === ScheduleKind.Recurring) {
      return schedule.timeBlocks
    }
    return getDefaultRecurringTimeBlocks()
  })

  // We need to keep separate track of the fixed TimeBlocks because we'd like it to
  // persist between switches in the schedule kind.
  const [fixedTimeBlocks, setFixedTimeBlocks] = React.useState<TimeBlock[]>(
    () => {
      if (schedule.kind === ScheduleKind.FixedRange) {
        return schedule.timeBlocks
      }
      return getDefaultFixedTimeBlocks()
    }
  )

  return {
    fixedBounds,
    setFixedBounds,
    recurringBounds,
    setRecurringBounds,
    recurringTimeBlocks,
    setRecurringTimeBlocks,
    fixedTimeBlocks,
    setFixedTimeBlocks,
  }
}
