import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, Media, Modal } from 'react-bootstrap'
import Badge from './Badge'
import './UpdateMeetingTypeModalPaymentsCollection.scss'
import CurrencyPicker from './CurrencyPicker'
import ExternalLink from './ExternalLink'
import Icon from './Icon'
import ProfileContext from './ProfileContext'
import SelectableButton from './SelectableButton'
import { IMeetingType } from './UpdateMeetingTypeContext'
import UnsupportedPlan from './UnsupportedPlan'
import Translate from './Translate'
import MeetingTypeEditorSectionPicker from './MeetingTypeEditorSectionPicker'
import { MTESection } from './UpdateMeetingTypeModal'

type Props = {
  onSelect: (section: MTESection) => void
}

const UpdateMeetingTypeModalPaymentsCollection: React.FC<Props> = ({
  onSelect,
}) => {
  const profile = React.useContext(ProfileContext)
  return (
    <div className="UpdateMeetingTypeModalPaymentsCollection tw-w-full">
      <Modal.Header closeButton>
        <div className="tw-flex tw-items-center">
          <Icon.InternalAsset
            assetName="icn-mte-header-payments"
            className="tw-hidden lg:tw-block"
          />
          <div className="title-block tw-hidden lg:tw-block">
            <Modal.Title>
              <Translate>Collect Payments</Translate>
            </Modal.Title>
            <Translate as="p">
              Collect payment from users who schedule this meeting.
            </Translate>
          </div>
        </div>
        <MeetingTypeEditorSectionPicker onChange={onSelect} value="payments" />
      </Modal.Header>
      <Modal.Body className="md:tw-space-y-8">
        {!profile.plan.allowPaymentCollection && (
          <UnsupportedPlan source="paymentCollection" />
        )}
        <FinalForm.Field<
          IMeetingType['paymentCollectionProvider']
        > name="paymentCollectionProvider">
          {({ input: { onChange, value: provider } }) => (
            <React.Fragment>
              <Form.Group className="tw-flex tw-flex-col tw-space-y-4 tw-mb-0">
                <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
                  <Icon.InternalAsset
                    assetName="Icon-Credit-Card"
                    className="tw-mr-4"
                    size={50}
                  />

                  <Media.Body className="tw-flex tw-items-center tw-justify-between">
                    <Form.Group>
                      <Form.Label>
                        <Translate>Accept Payments</Translate>
                        <Badge className="tw-ml-2" variant="success">
                          <Translate>Premium</Translate>
                        </Badge>
                      </Form.Label>
                      <Form.Text>
                        <Translate>
                          Require users to pay before they are able to schedule.
                        </Translate>
                      </Form.Text>
                    </Form.Group>
                  </Media.Body>
                </Media>

                <div className="tw-flex tw-flex-col tw-space-y-4 tw-w-full lg:tw-flex-row lg:tw-items-center lg:tw-justify-between lg:tw-space-x-4 lg:tw-space-y-0">
                  <SelectableButton
                    className="tw-flex-row tw-items-center tw-flex-grow tw-flex-shrink"
                    onClick={() => onChange(null)}
                    selectionVariant={!provider ? 'success' : null}
                  >
                    <Icon.Slash className="tw-text-bsGray-500" size={20} />
                    <Translate>Don’t collect payment</Translate>
                  </SelectableButton>
                  <SelectableButton
                    className="tw-flex-row tw-items-center tw-flex-grow tw-flex-shrink"
                    onClick={() => onChange({ id: '1', slug: 'stripe' })}
                    selectionVariant={
                      provider!.slug === 'stripe' ? 'success' : null
                    }
                  >
                    <Icon.InternalAsset assetName="stripe" size={20} />
                    <Translate>Collect with Stripe</Translate>
                  </SelectableButton>
                  <SelectableButton
                    className="tw-flex-row tw-items-center tw-flex-grow tw-flex-shrink"
                    onClick={() => onChange({ id: '2', slug: 'paypal' })}
                    selectionVariant={
                      provider!.slug === 'paypal' ? 'success' : null
                    }
                  >
                    <Icon.InternalAsset assetName="paypal" size={20} />
                    <Translate>Collect with PayPal</Translate>
                  </SelectableButton>
                </div>
              </Form.Group>

              <hr className="tw-border tw-border-bsGray-200 tw-w-full" />

              <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-space-y-0">
                <Icon.InternalAsset
                  assetName="Icon-Invoices"
                  className="tw-mr-4"
                  size={50}
                />

                <Media.Body>
                  <Form.Group>
                    <Form.Label>
                      <Translate>Amount to collect</Translate>
                    </Form.Label>
                    <Form.Text className="tw-mb-2">
                      <Translate>
                        What is the cost for attendees to schedule this meeting?
                      </Translate>
                    </Form.Text>
                    <div className="tw-flex tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-items-center md:tw-space-x-2 md:tw-space-y-0">
                      <div className="tw-flex tw-items-center tw-space-x-2">
                        <FinalForm.Field<IMeetingType['price']> name="price">
                          {({ input }) => (
                            <Form.Control
                              {...input}
                              disabled={
                                !profile.plan.allowPaymentCollection ||
                                !provider
                              }
                              style={{ width: '8.875rem' }}
                              type="number"
                            />
                          )}
                        </FinalForm.Field>
                        <FinalForm.Field<
                          IMeetingType['priceCurrency']
                        > name="priceCurrency">
                          {({
                            input: {
                              onChange: onCurrencySelection,
                              value: currency,
                            },
                          }) => (
                            <CurrencyPicker
                              disabled={
                                !profile.plan.allowPaymentCollection ||
                                !provider
                              }
                              onChange={currency =>
                                onCurrencySelection(currency)
                              }
                              value={currency}
                            />
                          )}
                        </FinalForm.Field>
                      </div>

                      {provider && (
                        <ExternalLink
                          href={
                            provider!.slug === 'paypal'
                              ? 'https://developer.paypal.com/docs/api/reference/currency-codes/'
                              : 'https://stripe.com/docs/currencies#presentment-currencies'
                          }
                        >
                          <Translate>Supported Currencies.</Translate>
                        </ExternalLink>
                      )}
                    </div>
                  </Form.Group>
                </Media.Body>
              </Media>
            </React.Fragment>
          )}
        </FinalForm.Field>
      </Modal.Body>
    </div>
  )
}

export default UpdateMeetingTypeModalPaymentsCollection
