import * as React from 'react'
import './Picker.scss'
import { Media } from 'react-bootstrap'
import Icon from './Icon'
import Select, {
  components as rs,
  createFilter,
  OptionProps,
  MenuProps,
  GroupProps,
  ControlProps,
  SingleValueProps,
  PlaceholderProps,
  InputProps,
  ValueContainerProps,
  Props as SelectProps,
} from 'react-select'

export interface PickerOption<TValue = any> {
  value: TValue
  label: string | React.ReactElement
  image?: React.ReactElement
  isDisabled?: boolean
  rightImage?: React.ReactElement
}

export interface PickerOptionGroup<TValue = any> {
  options: Array<PickerOption<TValue>>
  label: string | React.ReactElement
}

export const Option: React.FC<OptionProps<PickerOption, false>> = props => (
  <rs.Option className="PickerOption" {...props}>
    <Media className="align-items-center space-between-8">
      {props.data.image}
      <Media.Body className="overflow-hidden align-items-center d-flex">
        <div className="text-truncate">{props.data.label}</div>
      </Media.Body>
      {props.data.rightImage}
    </Media>
  </rs.Option>
)

export const GroupHeading: React.FC<
  GroupProps<PickerOption, false>
> = props => <rs.GroupHeading className="PickerGroupHeading" {...props} />

export const Control: React.FC<ControlProps<PickerOption, false>> = ({
  children,
  innerProps,
  innerRef,
  isFocused,
}) => (
  <div
    className={`PickerControl ${isFocused ? 'PickerControl--focused' : ''}`}
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </div>
)

export const Menu: React.FC<MenuProps<PickerOption, false>> = props => (
  <rs.Menu className="PickerMenu" {...props} />
)

export const SingleValue: React.FC<SingleValueProps<PickerOption>> = props => (
  <rs.SingleValue className="PickerSingleValue w-100" {...props}>
    <Media className="align-items-center text-truncate w-100">
      {props.data.image && <div className="mr-8">{props.data.image}</div>}
      <Media.Body className="text-truncate">{props.children}</Media.Body>
      {props.data.rightImage && (
        <div className="mr-8">{props.data.rightImage}</div>
      )}
    </Media>
  </rs.SingleValue>
)

export const ValueContainer: React.FC<
  ValueContainerProps<PickerOption, false>
> = props => <rs.ValueContainer className="PickerValueContainer" {...props} />

export const Input: React.FC<InputProps> = props => (
  <div className="PickerInput">
    <div className="d-flex align-items-center">
      <Icon.Search size={20} className="mr-8 text-muted" />
      <rs.Input {...props} />
    </div>
  </div>
)

export const Placeholder: React.FC<
  PlaceholderProps<PickerOption, false>
> = props => (
  <rs.Placeholder className="PickerPlaceholder" {...props}>
    {props.children}
  </rs.Placeholder>
)

export interface Props<TValue = any> {
  value: PickerOption<TValue> | null
  options: PickerOption<TValue>[] | PickerOptionGroup<TValue>[]
  onChange: (option: PickerOption<TValue> | null) => void
  required?: boolean
  disabled?: boolean
  loading?: boolean
  invalid?: boolean
  placeholder?: string | React.ReactElement
  searchFilter?: SelectProps<PickerOption<TValue>>['filterOption']
  hideCaret?: boolean
}

export default function Picker<TValue = any>({
  value,
  options,
  onChange,
  required,
  disabled,
  loading,
  invalid,
  placeholder,
  searchFilter,
  hideCaret,
}: Props<TValue>): React.ReactElement {
  const components: SelectProps<PickerOption<TValue>>['components'] = {
    Control,
    Menu,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    IndicatorSeparator: null,
  }

  if (hideCaret) {
    components['DropdownIndicator'] = null
  }

  return (
    <Select<PickerOption<TValue>>
      className={`Picker ${
        !!invalid ? 'error border rounded border-danger' : undefined
      }`}
      onChange={onChange}
      options={options}
      value={value}
      isClearable={!required}
      isDisabled={disabled}
      isLoading={loading}
      placeholder={placeholder}
      filterOption={searchFilter || createFilter({ ignoreAccents: false })}
      blurInputOnSelect
      // menuPosition="fixed"
      menuPlacement="auto"
      components={components}
    />
  )
}
