import AmplitudeHandler from './amplitude'
import CustomAnalyticsHandler from './customAnalytics'
import GoogleTagManagerHandler from './googleTagManager'
import IntercomHandler from './intercom'
import LogRocketHandler from './logrocket'

// Somehow I'd like to get this typing into the registeredAnalyticsServices
// below, but without doing Record<string, AnalyticsService>, because that
// will cause the Array<keyof typeof registeredAnalyticsServices> below to
// be string instead of 'intercom' | 'amplitude | ...
//
export interface AnalyticsService {
  trackEvent: (name: string, attributes?: object) => Promise<boolean>
  pageChanged?: () => Promise<boolean>
}

// Form of event attributes that we accept.
export type AnalyticsEventAttributes = Record<
  string,
  string | number | boolean | string[] | number[] | boolean[] | undefined
>

const registeredAnalyticsServices = {
  intercom: IntercomHandler,
  amplitude: AmplitudeHandler,
  googleTagManager: GoogleTagManagerHandler,
  logrocket: LogRocketHandler,
  custom: CustomAnalyticsHandler,
}

// Will store any default attributes that should be passed back
// with every analytics event
let defaultAttributes = {}

export const Analytics = {
  getDefaultAttributes: () => defaultAttributes,

  setDefaultAttributes: (attributes: object) => {
    defaultAttributes = attributes
  },

  updateDefaultAttributes: (attributes: object) => {
    defaultAttributes = { ...defaultAttributes, ...attributes }
  },

  clearDefaultAttributes: () => {
    defaultAttributes = {}
  },

  // Tracks an event with our services.
  trackEvent: (
    name: string,
    attributes?: object,
    services?: Array<keyof typeof registeredAnalyticsServices>
  ) => {
    console.groupCollapsed('trackEvent', name, attributes, services)

    const promise = Promise.all(
      Object.entries(registeredAnalyticsServices)
        .filter(
          // Filter out services if the caller specified a list
          ([serviceId, _]) =>
            !services ||
            services.includes(
              // TYPESCRIPT: find a better way to type this.
              serviceId as keyof typeof registeredAnalyticsServices
            )
        )
        .map(([_, handler]) =>
          // Mix the default attributes in
          handler.trackEvent(name, { ...defaultAttributes, ...attributes })
        )
    )

    console.groupEnd()

    return promise
  },

  trackEventOnce: (
    name: string,
    attributes?: object,
    services?: Array<keyof typeof registeredAnalyticsServices>
  ) => {
    // We include the profileId in the cache string so that if a user has
    // multiple profiles they will trigger events once per profile.
    //
    // TYPESCRIPT: type this properly
    // @ts-ignore
    const profileId = defaultAttributes.profileId as string

    // Create a cache key
    const cacheKey = `analytics:trackEventOnce:${profileId}:${name}`

    // If there is no cache of this event firing, trigger the event
    if (!localStorage.getItem(cacheKey)) {
      Analytics.trackEvent(name, attributes, services)
    } else {
      console.log('trackEventOnce', name, 'already tracked')
    }

    // Make a note that we shouldn't trigger the event again
    localStorage.setItem(cacheKey, '1')
  },

  // Tracks a pageView with our services.
  pageChanged: (): Promise<unknown> => {
    console.groupCollapsed('Analytics.pageChanged')
    const promise = Promise.all(
      Object.entries(registeredAnalyticsServices).map(([_, handler]) =>
        handler.pageChanged()
      )
    )
    console.groupEnd()
    return promise
  },
}
