import * as React from 'react'
import { gql } from '@apollo/client'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import Badge from './Badge'
import DropdownItem from './DropdownItem'
import './SettingsModalWebhooksSection.scss'
import Alert from './Alert'
import CreateWebhookModal from './CreateWebhookModal'
import Icon from './Icon'
import MemberContext from './MemberContext'
import MoreButton from './MoreButton'
import ProfileContext from './ProfileContext'
import TimeLabel from './TimeLabel'
import Toggler from './Toggler'
import UnsupportedPlan from './UnsupportedPlan'
import {
  Maybe,
  SettingsModalWebhooksSectionQuery as Response,
  useSettingsModalWebhookDeleteMutation,
  useSettingsModalWebhooksSectionQuery,
} from '../__generated__/graphql'
import { Member, User, Webhook } from '../types'
import { DateTime, isNotSoftDeleted, isNotZapierHook } from '../utils'
import ExternalLink from './ExternalLink'
import SettingsSectionPicker from './SettingsSectionPicker'
import { SettingsSection } from './SettingsModal'
import Translate from './Translate'

gql`
  query SettingsModalWebhooksSection($id: ID!) {
    profile: getProfileById(id: $id) {
      id
      webhooks {
        edges {
          node {
            active
            createdAt
            deleted
            id
            member {
              id
              user {
                firstName
                id
                lastName
              }
            }
            name
            url
          }
        }
      }
    }
  }
`

gql`
  mutation SettingsModalWebhookDelete($input: DeleteWebhookInput!) {
    webHook: deleteWebhook(input: $input) {
      data {
        deleted
        id
      }
    }
  }
`

interface InternalData {
  webhooks: (Pick<Webhook, 'id' | 'active' | 'deleted' | 'name' | 'url'> & {
    createdAt: DateTime
    member: Pick<Member, 'id'> & {
      user: Pick<User, 'id' | 'firstName' | 'lastName'>
    }
  })[]
}

const wireDataToInternalData = (wireData: Response): InternalData => ({
  webhooks: wireData.profile.webhooks.edges
    .map(edge => ({
      ...edge?.node!,
      createdAt: new DateTime(edge?.node?.createdAt),
      member: edge?.node?.member!,
    }))
    .filter(isNotZapierHook),
})

type Props = {
  onSelect: (section: SettingsSection) => void
}

const SettingsModalWebhooksSection: React.FC<Props> = ({ onSelect }) => {
  const member = React.useContext(MemberContext)
  const profile = React.useContext(ProfileContext)

  const { data, refetch } = useSettingsModalWebhooksSectionQuery({
    variables: { id: profile.id },
  })

  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data) : null),
    [data]
  )

  const [deleteWebhook] = useSettingsModalWebhookDeleteMutation()

  return (
    <div className="SettingsModalWebhooksSection">
      <Modal.Header closeButton>
        <Icon.CreditCard className="tw-hidden tw-text-bsGray-600 lg:tw-block" />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title as="h2" className="tw-hidden md:tw-flex md:tw-space-x-2">
            <Translate>Webhooks</Translate>
            <Badge
              className="tw-flex tw-items-center tw-justify-center"
              variant="success"
            >
              <Translate>Premium</Translate>
            </Badge>
          </Modal.Title>
          <Translate as="p">Trigger custom actions from Appointlet.</Translate>
        </div>
        <SettingsSectionPicker onChange={onSelect} value="webhooks" />
      </Modal.Header>
      <Modal.Body>
        <p className="tw-flex tw-flex-col tw-space-y-2 tw-mb-6">
          <span>
            Webhooks allow you to send your meeting data to other services based
            on events in Appointlet.
          </span>
          <ExternalLink href="http://kb.appointlet.help/en/articles/4968412">
            Learn more
          </ExternalLink>
        </p>

        {!member.role.canManageOrgSettings ? (
          <Alert variant="danger">
            You don’t have permission to manage webhooks.
          </Alert>
        ) : !profile.plan.allowWebhooks ? (
          <UnsupportedPlan source="Webhooks" />
        ) : (
          <Toggler>
            {({ isToggled, setOff, setOn }) => (
              <React.Fragment>
                <Button onClick={setOn} variant="success">
                  <Icon.Plus size="16" />
                  Add Webhook
                </Button>
                {isToggled && (
                  <CreateWebhookModal
                    onCreate={() => {
                      // Close modal.
                      setOff()
                      // Run query again to populate newest webhook.
                      refetch()
                    }}
                    onHide={setOff}
                  />
                )}
              </React.Fragment>
            )}
          </Toggler>
        )}

        <Row className="tw-mt-6">
          <Col sm={8}>
            <p className="tw-font-medium">URL</p>
          </Col>
          <Col sm={3}>
            <p className="tw-font-medium">Created</p>
          </Col>
          <Col sm={1} />
        </Row>

        <hr className="tw-my-3" />

        {!!internalData?.webhooks.filter(isNotSoftDeleted).length ? (
          internalData.webhooks.filter(isNotSoftDeleted).map(w => (
            <div className="SettingsModalWebhooksSection--Webhook" key={w.id}>
              <Row className="tw-items-center" key={w.id}>
                <Col sm={8}>
                  <span>{w.url}</span>
                  {w.name && (
                    <p className="tw-mt-[4px] tw-italic tw-text-bsGray-500">
                      {w.name}
                    </p>
                  )}
                </Col>
                <Col sm={3}>
                  <TimeLabel format="localized-short-date" time={w.createdAt} />
                </Col>
                <Col sm={1}>
                  {member.role.canManageOrgSettings && (
                    <MoreButton alignRight size="sm">
                      <DropdownItem
                        onClick={() =>
                          deleteWebhook({
                            optimisticResponse: {
                              __typename: 'Mutations',
                              webHook: {
                                __typename: 'DeleteWebhookPayload',
                                data: {
                                  __typename: 'WebhookNode',
                                  deleted: new DateTime().toISO(),
                                  id: w.id,
                                },
                              },
                            },
                            variables: { input: { id: w.id } },
                          })
                        }
                      >
                        Delete
                      </DropdownItem>
                    </MoreButton>
                  )}
                </Col>
              </Row>
              <hr className="tw-my-3" />
            </div>
          ))
        ) : (
          <Row className="tw-items-center">
            <Col>
              <p className="tw-text-bsGray-600 tw-italic">No active webhooks</p>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </div>
  )
}

export default SettingsModalWebhooksSection
