import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'
import Translate from './Translate'
import Var from './Var'
import Icon from './Icon'
import LoadingButton from './LoadingButton'
import { useCancelMeetingMutation } from '../__generated__/graphql'
import { ID } from '../types'

gql`
  mutation CancelMeeting($input: CancelMeetingInput!) {
    cancelMeeting(input: $input) {
      data {
        cancelled
        id
        name
        start
        attendees {
          edges {
            node {
              id
              status
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

// State manager for operating on the cancel meeting modal and the related mutation.
export const useCancelMeetingModal = (initialToCancel?: ID) => {
  // Prep the mutation
  const [mutation, { loading: submitting }] = useCancelMeetingMutation()

  // Prep a piece of state that manages which meeting is in the process
  // of being cancelled.  If this is null, the modal is closed, if it's not,
  // the modal is open.
  const [toCancel, setToCancel] = React.useState<ID | null>(
    initialToCancel !== undefined ? initialToCancel : null
  )

  // Function to call to trigger the mutation and handle errors.
  const submit = async (meetingId: ID, message: string) => {
    const res = await mutation({
      variables: {
        input: {
          id: meetingId,
          message: message || null,
        },
      },
    })

    // Check for graphQL errors
    if (res.errors) {
      console.error('Unexpected error cancelling meeting', res.errors)
      throw new Error('Unexpected error cancelling meeting')
    }

    // Check for validation errors
    if (res.data?.cancelMeeting?.errors) {
      console.error(
        'Validation error cancelling meeting',
        res.data.cancelMeeting.errors
      )
      throw new Error('Validation error cancelling meeting')
    }

    // Close the modal
    setToCancel(null)

    return res.data!.cancelMeeting!.data!
  }

  return {
    open: setToCancel,
    close: () => setToCancel(null),
    toCancel,
    submit,
    submitting,
  }
}

export type Props = {
  name: string
  submitting: boolean

  // This is confusing but sticks with our patterns:
  // onConfirm == "I want to cancel"
  // onCancel == "I want to cancel the cancel" :D
  onConfirm?: (message: string) => void
  onCancel?: () => void
}

type FormValues = {
  message: string
}

const CancelMeetingModal: React.FC<Props> = ({
  name,
  submitting,
  onConfirm,
  onCancel,
}) => (
  <Modal className="CancelMeetingModal" onHide={onCancel} show size="sm">
    <Modal.Header closeButton>
      <Icon.XCircle className="tw-text-bsDanger" size={36} />
      <Modal.Title>
        <Translate>Cancel Meeting</Translate>
      </Modal.Title>
    </Modal.Header>

    <FinalForm.Form<FormValues>
      onSubmit={values => onConfirm && onConfirm(values.message)}
    >
      {({ handleSubmit }) => (
        <Form
          className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
          onSubmit={handleSubmit}
        >
          <Modal.Body>
            <p>
              <Translate>
                Are you sure you want to cancel{' '}
                <strong>
                  <Var name="meetingName">{name}</Var>
                </strong>
                ? We'll send a notification to the attendee(s) to let them know.
              </Translate>
            </p>

            <Form.Group>
              <FinalForm.Field<string> name="message">
                {({ input }) => (
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Anything you'd like to pass along to attendees?"
                    {...input}
                  />
                )}
              </FinalForm.Field>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer className="tw-items-stretch tw-justify-between md:tw-justify-end">
            <Button
              className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
              onClick={onCancel}
              variant="outline-secondary"
            >
              <Translate>Nevermind</Translate>
            </Button>

            <LoadingButton
              className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
              loading={submitting}
              variant="danger"
              type="submit"
            >
              <Translate>Cancel Meeting</Translate>
            </LoadingButton>
          </Modal.Footer>
        </Form>
      )}
    </FinalForm.Form>
  </Modal>
)

export default CancelMeetingModal
