import React from 'react'
import { Dropdown, DropdownButton, Media } from 'react-bootstrap'

import './SettingsSectionPicker.scss'
import DropdownItem from './DropdownItem'
import Icon from './Icon'
import MemberContext from './MemberContext'
import PermissionsFlag from './PermissionsFlag'
import Translate from './Translate'
import Var from './Var'
import { SettingsSection, settingsMenuOptions } from './SettingsModal'

export interface Props {
  onChange: (value: SettingsSection) => void
  value: SettingsSection
}

const SettingsSectionPicker: React.FC<Props> = ({ onChange, value }) => {
  const { role } = React.useContext(MemberContext)
  const selectedOption = React.useMemo(
    () =>
      settingsMenuOptions
        .map(({ items }) => items)
        .flat()
        .find(s => s.value === value)!,
    [value]
  )

  return (
    <DropdownButton
      className="SettingsSectionPicker tw-w-full lg:tw-hidden"
      id="SettingsSectionPicker"
      title={
        <Media className="tw-items-center tw-space-x-4 tw-w-full">
          <selectedOption.Icon className="tw-mr-0 tw-h-6 tw-w-6 tw-text-bsGray-600 group-hover:tw-text-white" />
          <Media.Body className="tw-flex tw-flex-col tw-items-start tw-space-y-0.5">
            <span className="tw-text-bsGray-900 tw-font-medium tw-text-sm">
              <Translate>
                <Var name="sectionName">{selectedOption.sectionName}</Var>
              </Translate>
            </span>
            <span className="tw-text-bsGray-600 tw-text-xs tw-text-left tw-font-normal tw-whitespace-normal">
              <Translate>
                <Var name="sectionDescription">
                  {selectedOption.description}
                </Var>
              </Translate>
            </span>
          </Media.Body>
          <Icon.ChevronDown className="tw-text-bsGray-400 tw-h-5" />
        </Media>
      }
      variant="outline-secondary"
    >
      {settingsMenuOptions.map(({ divider, heading, items }, i) => (
        <React.Fragment key={heading}>
          <Dropdown.Header
            className={
              heading !== 'Account'
                ? 'tw-uppercase'
                : role.canManageBilling ||
                  role.canManageMembers ||
                  role.canManageOrgSettings
                ? 'tw-block tw-uppercase'
                : 'tw-hidden'
            }
          >
            <Translate>{heading}</Translate>
          </Dropdown.Header>
          {items.map(({ description, Icon, permission, sectionName, value }) =>
            permission ? (
              <PermissionsFlag key={value} permission={permission}>
                <DropdownItem
                  as={Media}
                  className="tw-group tw-cursor-pointer tw-items-center tw-space-x-4"
                  eventKey={value}
                  key={value}
                  onClick={() => onChange(value)}
                >
                  <Icon className="tw-mr-0 tw-h-6 tw-w-6 tw-text-bsGray-600 group-hover:tw-text-white" />
                  <Media.Body className="tw-flex tw-flex-col tw-items-start tw-space-y-0.5">
                    <span className="tw-text-bsGray-900 tw-font-medium tw-text-sm group-hover:tw-text-white">
                      <Translate>{sectionName}</Translate>
                    </span>
                    <span className="tw-text-bsGray-600 tw-text-xs tw-text-left tw-font-normal tw-whitespace-normal group-hover:tw-text-white">
                      <Translate>{description}</Translate>
                    </span>
                  </Media.Body>
                </DropdownItem>
              </PermissionsFlag>
            ) : (
              <DropdownItem
                as={Media}
                className="tw-group tw-cursor-pointer tw-items-center tw-space-x-4"
                eventKey={value}
                key={value}
                onClick={() => onChange(value)}
              >
                <Icon className="tw-mr-0 tw-h-6 tw-w-6 tw-text-bsGray-600 group-hover:tw-text-white" />
                <Media.Body className="tw-flex tw-flex-col tw-items-start tw-space-y-0.5">
                  <span className="tw-text-bsGray-900 tw-font-medium tw-text-sm group-hover:tw-text-white">
                    <Translate>{sectionName}</Translate>
                  </span>
                  <span className="tw-text-bsGray-600 tw-text-xs tw-text-left tw-font-normal tw-whitespace-normal group-hover:tw-text-white">
                    <Translate>{description}</Translate>
                  </span>
                </Media.Body>
              </DropdownItem>
            )
          )}
          {divider && (
            <Dropdown.Divider
              className={
                role.canManageBilling ||
                role.canManageMembers ||
                role.canManageOrgSettings
                  ? 'tw-block'
                  : 'tw-hidden'
              }
            />
          )}
        </React.Fragment>
      ))}
    </DropdownButton>
  )
}

export default SettingsSectionPicker
