import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { navigate, RouteComponentProps } from '@reach/router'
import { Form } from 'react-bootstrap'
import { gql } from '@apollo/client'
import './OnboardingPages.scss'
import UpdateAvailabilityCalendarsModal from './UpdateAvailabilityCalendarsModal'
import UpdateMeetingCalendarModal from './UpdateMeetingCalendarModal'
import {
  Maybe,
  OnboardingCalendarIntegrationsPageQuery as Response,
  useOnboardingCalendarIntegrationsPageCreateMutation,
  useOnboardingCalendarIntegrationsPageQuery,
} from '../__generated__/graphql'
import {
  Calendar,
  CalendarAccount,
  CalendarProvider,
  ID,
  Member,
} from '../types'
import {
  oauthErrors,
  startOAuthFlow,
  WindowClosed,
  toast,
  Analytics,
  OAuthServerError,
} from '../utils'
import MemberContext from './MemberContext'
import UserContext from './UserContext'
import { ConnectCalendarScreen } from './onboarding/screens/ConnectCalendarScreen/ConnectCalendarScreen'
import { useState } from 'react'
import { CalendarSettingsScreen } from './onboarding/screens/CalendarSettingsScreen/CalendarSettingsScreen'

gql`
  query OnboardingCalendarIntegrationsPage($id: ID!) {
    member: getMemberById(id: $id) {
      id
      calendarAccounts {
        edges {
          node {
            calendars {
              edges {
                node {
                  color
                  id
                  name
                  permissionLevel
                  syncMeetings
                  transparent
                }
              }
            }
            id
            provider {
              id
              name
              slug
            }
          }
        }
      }
    }
    providers: getCalendarProviders {
      edges {
        node {
          id
          name
          oauth2AuthorizationUrl
          slug
          shortDescription
        }
      }
    }
  }
`

gql`
  mutation OnboardingCalendarIntegrationsPageCreate(
    $input: CreateCalendarAccountInput!
  ) {
    createCalendarAccount(input: $input) {
      data {
        calendars {
          edges {
            node {
              color
              id
              lastSyncAt
              name
              syncMeetings
              transparent
            }
          }
        }
        id
        isConnected
        name
        provider {
          name
          slug
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

type Props = RouteComponentProps & {
  profileId?: string
}

export type FormValues = 'google' | 'office365' | ''

type InternalData = {
  member: Pick<Member, 'id'> & {
    calendarAccounts: Array<
      Pick<CalendarAccount, 'id'> & {
        calendars: Array<
          Pick<
            Calendar,
            | 'color'
            | 'id'
            | 'name'
            | 'permissionLevel'
            | 'syncMeetings'
            | 'transparent'
          >
        >
        provider: Pick<CalendarProvider, 'id' | 'name' | 'slug'>
      }
    >
  }
  providers: Array<
    Pick<
      CalendarProvider,
      'id' | 'name' | 'oauth2AuthorizationUrl' | 'slug' | 'shortDescription'
    >
  >
}

const wireDataToInternalProvidersData = (wireData: Response): InternalData => ({
  member: {
    ...wireData.member,
    calendarAccounts: wireData.member.calendarAccounts.edges.map(edge => ({
      ...edge!.node!,
      calendars: edge!.node!.calendars.edges.map(edge => ({ ...edge!.node! })),
      provider: edge!.node!.provider,
    })),
  },
  providers: wireData.providers.edges.map(edge => edge!.node!),
})

const OnboardingCalendarIntegrationsPage: React.FC<Props> = ({ profileId }) => {
  const member = React.useContext(MemberContext)
  // State to control the UpdateMeetingCalendarModal modal
  const [meetingCalendarBeingEdited, setMeetingCalendarBeingEdited] =
    React.useState<boolean>(false)

  const [
    availabilityCalendarsBeingEdited,
    setAvailabilityCalendarsBeingEdited,
  ] = React.useState<boolean>(false)
  const { data, loading, refetch } = useOnboardingCalendarIntegrationsPageQuery(
    {
      variables: { id: member!.id },
    }
  )
  const [createCalendarAccount] =
    useOnboardingCalendarIntegrationsPageCreateMutation()
  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalProvidersData(data) : null),
    [data]
  )

  const { user } = React.useContext(UserContext)
  const isSingleUser = user.profiles[0].personal

  const [isLoading, setIsLoading] = useState<Maybe<ID>>(null)

  React.useEffect(() => {
    // When page loads initially.
    Analytics.trackEvent('Onboarding Calendar: Loaded')
  }, [])

  // If we are loading or data isn't present return null.
  if (loading || !internalData) {
    return null
  }
  // Get reference to calendar account.
  // If the user connected with Google or Office 365 there will
  // be an account at index 0 if not then this evaluates to
  // undefined.
  const calendarAccount = internalData.member.calendarAccounts[0]

  // Start oauth flow, handle any errors, refetch to load CalendarSettingsScreen
  const onSubmit = async (slug: FormValues) => {
    setIsLoading(slug)

    //grab correct provider from internalData based on provider
    const provider = internalData.providers.find(provider => {
      return provider.slug === slug
    })

    if (!provider) {
      setIsLoading(null)
      return
    }
    try {
      // Fetch the code.
      const code = await startOAuthFlow(provider.oauth2AuthorizationUrl, {
        height: 800,
        width: 500,
      })
      // Create the calendar account.
      const res = await createCalendarAccount({
        variables: {
          input: {
            oauth2Code: code,
            profile: profileId!,
            provider: provider.id,
          },
        },
      })

      // Handle error case.
      if (res.errors || res.data?.createCalendarAccount?.errors) {
        setIsLoading(null)
        console.error(
          'createCalendarAccount, [OnboardingCalendarIntegrationsPage.tsx (onSubmit)]',
          res.errors || res.data?.createCalendarAccount?.errors
        )
        throw new Error('Creating calendar account failed.')
      }

      // Handle success case.
      if (res.data?.createCalendarAccount?.data) {
        Analytics.trackEvent('Connected Calendar', {
          source: 'onboarding',
          calendarProvider: res.data.createCalendarAccount.data.provider.slug,
        })

        // We execute a refetch here to refresh the data on the page
        // which will show the user the next calender UI since they
        // now will have a connected calendar account.
        await refetch()
        return
      }
    } catch (error) {
      if (error === WindowClosed) {
        setIsLoading(null)
        return
      }
      toast.error(oauthErrors(error as OAuthServerError))
      console.error(
        'createCalendarAccount, [OnboardingCalendarIntegrationsPage.tsx (onSubmit)]',
        error
      )
    }
  }

  // Continue to OnboardingWebConferencingPage after connecting account
  const onContinue = async () => {
    Analytics.trackEvent('Onboarding Calendar: Submitted', {
      calendarProvider: calendarAccount.provider.slug,
    })
    await navigate('availability')
    return
  }

  // We want to see if the user has any calendars that have
  // 'transparent' set to false. If they do we want to tell them
  // that we are going to check those calendars for conflicts when
  // building their schedule.
  const readableCalendars = calendarAccount
    ? calendarAccount.calendars.filter(c => !c.transparent)
    : undefined
  // We want to see if the user has a calendar (their will only be one)
  // that has 'syncMeetings' set to true. If the do we want to tell them
  // that we will write meetings that are scheduled to that calendar when
  // someone books with them.
  const writableCalendar = calendarAccount
    ? calendarAccount.calendars.filter(c => c.syncMeetings)[0]
    : undefined

  return (
    <div className="no-gutter">
      <FinalForm.Form<FormValues> initialValues={''} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {/* If they have a calendar account connected */}
            {calendarAccount && (
              <React.Fragment>
                <CalendarSettingsScreen
                  email={user.email}
                  calendarType={calendarAccount.provider.name}
                  onSubmitCallback={onContinue}
                  skipSectionCallback={() => navigate('availability')}
                  changeAddMeetingCallback={() =>
                    setMeetingCalendarBeingEdited(true)
                  }
                  changeCheckConflictCallback={() =>
                    setAvailabilityCalendarsBeingEdited(true)
                  }
                  meetingsCalendar={
                    writableCalendar || calendarAccount.calendars[0]
                  }
                  availabilityCalendars={
                    readableCalendars || calendarAccount.calendars
                  }
                  isSingleUser={isSingleUser}
                />

                {meetingCalendarBeingEdited && (
                  <UpdateMeetingCalendarModal
                    memberId={member.id}
                    value={writableCalendar?.id ?? null}
                    onHide={() => setMeetingCalendarBeingEdited(false)}
                    onSuccess={() => {
                      // Reload the calendars and then close
                      refetch().then(() => setMeetingCalendarBeingEdited(false))
                    }}
                  />
                )}

                {availabilityCalendarsBeingEdited && (
                  <UpdateAvailabilityCalendarsModal
                    memberId={member.id}
                    value={readableCalendars!.map(c => c.id)}
                    onHide={() => setAvailabilityCalendarsBeingEdited(false)}
                    onSuccess={() => {
                      //here we close modal and then refetch to get updated availabilityCalendars
                      setAvailabilityCalendarsBeingEdited(false)
                      refetch()
                    }}
                  />
                )}
              </React.Fragment>
            )}

            {/* If they don't have a calendar account connected */}
            {!calendarAccount && (
              <React.Fragment>
                <ConnectCalendarScreen
                  email={user.email}
                  onSubmitCallback={onSubmit}
                  skipSectionCallback={() => navigate('availability')}
                  isLoading={isLoading}
                />
              </React.Fragment>
            )}
          </Form>
        )}
      </FinalForm.Form>
    </div>
  )
}

export default OnboardingCalendarIntegrationsPage
