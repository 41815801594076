import * as React from 'react'

import Icon from './Icon'
import TimeLabel from './TimeLabel'
import { AttendeeField, FormFieldType } from '../types'
import { DateTime } from '../utils'

interface Props extends Pick<AttendeeField, 'value' | 'fieldType'> {}

const FormFieldValue: React.FC<Props> = ({ value, fieldType }) => {
  const parsedValue = JSON.parse(value)
  switch (fieldType) {
    case FormFieldType.Text:
    case FormFieldType.Multitext:
    case FormFieldType.Choice:
      return parsedValue
    case FormFieldType.Multichoice:
      return (parsedValue as string[]).join(', ')
    case FormFieldType.Time:
      return (
        <TimeLabel format="localized-time" time={new DateTime(parsedValue)} />
      )
    case FormFieldType.Date:
      return (
        <TimeLabel
          format="localized-full-date"
          time={new DateTime(parsedValue)}
        />
      )
    case FormFieldType.Place:
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.com/maps/?q=${encodeURIComponent(
            parsedValue
          )}`}
        >
          <Icon.MapPin size={16} /> {parsedValue}
        </a>
      )
    case FormFieldType.Toggle:
      return (
        <span className={parsedValue ? 'text-success' : 'text-danger'}>
          {parsedValue ? <Icon.Check size={18} /> : <Icon.X size={18} />}
        </span>
      )
    default:
      return null
  }
}

export default FormFieldValue
