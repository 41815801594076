// This is a helper component to display a human-friendly timezone string to users.
// We need this because the server returns timezones in an enum-style CAPS_WITH_UNDERSCORES
// and we have no place to look for descriptions short of querying the server,
// which we do here.

import * as React from 'react'

import {
  __EnumValue,
  Maybe,
  TimeZonePickerQuery as Response,
  useTimeZonePickerQuery,
  Timezone,
} from '../__generated__/graphql'
import { DateTime } from '../utils'

type InternalData = Array<Pick<__EnumValue, 'description' | 'name'>>

const wireDataToInternalData = (wire: Response['timeZones']) => [
  ...wire!.enumValues!.map(value => ({ ...value })),
]

export interface Props {
  shorthand?: boolean
  timezone: Timezone
}

// Looks through the list of timezones to find the label for the matching one
const timezoneValueToLabel = (
  timezone: string,
  allZones: InternalData
): Maybe<string> => {
  const result = allZones.find(({ name }) => name === timezone)
  // Strip out all '_' and replace with ' ' before rendering.
  return result ? result.description!.replace('_', ' ') : null
}

const TimeZoneLabel: React.FC<Props> = ({ shorthand, timezone }) => {
  const { data, loading } = useTimeZonePickerQuery()

  const timeZones: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data.timeZones) : null),
    [data]
  )

  return loading || !timeZones ? null : (
    <>
      {shorthand
        ? new DateTime().getTimeZoneName()
        : timezoneValueToLabel(timezone, timeZones)}
    </>
  )
}

export default TimeZoneLabel
