import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, Modal } from 'react-bootstrap'

import Badge from './Badge'
import ExternalLink from './ExternalLink'
import Highlight from './Highlight'
import Icon from './Icon'
import LoadingButton from './LoadingButton'
import RadioSelectPanel from './RadioSelectPanel'
import Spinner from './Spinner'
import { Currency, Language, UpcomingInvoice } from '../__generated__/graphql'
import { formatMoney } from '../utils'
import Translate from './Translate'
import Var from './Var'

export type Props = {
  billedAnnually: boolean
  isQueryLoading: boolean
  invoiceDetails: UpcomingInvoice
  language: Language
  onHide: () => void
  onSuccess: (values: FormValues) => void
}

type FormValues = Pick<Props, 'billedAnnually'>

const ChangeBillingCycleModal: React.FC<Props> = ({
  billedAnnually,
  invoiceDetails,
  isQueryLoading,
  language,
  onHide,
  onSuccess,
}) => (
  <Modal className="ChangeBillingCycleModal" onHide={onHide} show size="sm">
    <Modal.Header closeButton>
      <Icon.InternalAsset
        assetName="Icon-Notice-2-Blue"
        className="tw-mr-3"
        size={36}
      />
      <Modal.Title>
        <Translate>Change Billing Cycle</Translate>
      </Modal.Title>
    </Modal.Header>
    {isQueryLoading ? (
      <Modal.Body className="tw-items-center tw-flex tw-justify-center tw-h-[300px]">
        <Spinner />
      </Modal.Body>
    ) : (
      <FinalForm.Form<FormValues>
        initialValues={{
          billedAnnually,
        }}
        onSubmit={(values: FormValues) => onSuccess(values)}
      >
        {({ dirty, handleSubmit, initialValues, submitting, values }) => (
          <Form
            className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
            onSubmit={handleSubmit}
          >
            <Modal.Body>
              <FinalForm.Field<boolean>
                name="billedAnnually"
                type="radio"
                value={false}
              >
                {({ input: { checked, name, onChange } }) => (
                  <RadioSelectPanel
                    checked={!!checked}
                    id="monthly-radio-select"
                    leftLabel={
                      <div className="tw-flex tw-space-x-1">
                        <strong className="tw-text-bsGray-900">
                          <Translate>Pay Monthly</Translate>
                        </strong>
                        {!initialValues.billedAnnually && (
                          <span className="tw-text-bsGray-600 tw-font-normal">
                            (<Translate>current</Translate>)
                          </span>
                        )}
                      </div>
                    }
                    name={name}
                    onSelect={() => onChange(false)}
                    rightLabel={
                      <strong className="tw-text-bsPrimary">
                        <Translate>$10/month</Translate>
                      </strong>
                    }
                  />
                )}
              </FinalForm.Field>

              <FinalForm.Field<boolean>
                name="billedAnnually"
                type="radio"
                value={true}
              >
                {({ input: { checked, name, onChange } }) => (
                  <RadioSelectPanel
                    checked={!!checked}
                    id="yearly-radio-select"
                    leftLabel={
                      <div className="tw-flex tw-space-x-1">
                        <strong className="tw-text-bsGray-900">
                          <Translate>Pay Yearly</Translate>
                        </strong>
                        {initialValues.billedAnnually && (
                          <span className="tw-text-bsGray-600 tw-font-normal">
                            (<Translate>current</Translate>)
                          </span>
                        )}
                      </div>
                    }
                    name={name}
                    onSelect={() => onChange(true)}
                    rightLabel={
                      <>
                        <Badge variant="success">
                          <Translate>Save %20</Translate>
                        </Badge>
                        <strong className="tw-text-bsPrimary">
                          <Translate>$8/month</Translate>
                        </strong>
                      </>
                    }
                  />
                )}
              </FinalForm.Field>

              {dirty && (
                <div className="tw-text-bsGray-600 tw-flex tw-flex-col tw-space-y-4">
                  <div className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-w-full">
                    <div className="tw-flex tw-items-center tw-space-x-2">
                      <Icon.RefreshCw size="16" />
                      <span className="tw-font-normal">
                        <Translate>
                          Subscription (
                          <Var
                            name="subscriptionSeats"
                            pluralize={invoiceDetails.subscriptionQuantity}
                          >
                            {invoiceDetails.subscriptionQuantity}
                          </Var>{' '}
                          seat)
                        </Translate>
                      </span>
                    </div>
                    <span className="tw-font-normal">
                      {formatMoney(
                        invoiceDetails.subscriptionAmount / 100,
                        Currency.Usd,
                        language
                      )}
                      /{values.billedAnnually ? 'year' : 'month'}
                    </span>
                  </div>

                  {invoiceDetails.discountName && (
                    <div className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-w-full">
                      <div className="tw-flex tw-items-center tw-space-x-2">
                        <Icon.Tag size="16" />
                        <span className="tw-font-normal">
                          <Translate>Discount</Translate>
                          <strong className="tw-text-bsGray-900">
                            <Var name="discountName">
                              {invoiceDetails.discountName}
                            </Var>
                          </strong>{' '}
                          <Var name="discountPercentage">
                            ({invoiceDetails.discountPercentOff}%)
                          </Var>
                        </span>
                      </div>
                      <span className="tw-font-normal">
                        {formatMoney(
                          (invoiceDetails.discountAmount / 100) * -1,
                          Currency.Usd,
                          language
                        )}
                      </span>
                    </div>
                  )}

                  {!!invoiceDetails.prorationAmount && (
                    <div className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-w-full">
                      <div className="tw-flex tw-items-center tw-space-x-2">
                        <Icon.Clock size="16" />
                        <span className="tw-font-normal">
                          <Translate>Proration from current cycle</Translate>
                        </span>
                      </div>
                      <span className="tw-font-normal">
                        {formatMoney(
                          invoiceDetails.prorationAmount / 100,
                          Currency.Usd,
                          language
                        )}
                      </span>
                    </div>
                  )}

                  {!!invoiceDetails.startingBalance && (
                    <div className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-w-full">
                      <div className="tw-flex tw-items-center tw-space-x-2">
                        <Icon.Book size="16" />
                        <span className="tw-font-normal">
                          <Translate>Account Balance</Translate>
                        </span>
                      </div>
                      <span className="tw-font-normal">
                        {formatMoney(
                          invoiceDetails.startingBalance / 100,
                          Currency.Usd,
                          language
                        )}
                      </span>
                    </div>
                  )}

                  <Highlight>
                    <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
                      <div className="tw-flex tw-items-center tw-space-x-2">
                        <Icon.CreditCard size="16" />
                        <strong className="tw-text-bsGray-900">
                          <Translate>Due Today</Translate>
                        </strong>
                      </div>
                      <strong className="tw-text-bsGray-900">
                        {formatMoney(
                          invoiceDetails.amountDue / 100,
                          Currency.Usd,
                          language
                        )}
                      </strong>
                    </div>

                    {invoiceDetails.startingBalance !==
                      invoiceDetails.endingBalance && (
                      <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
                        <div className="tw-flex tw-items-center tw-space-x-2">
                          <Icon.Book size="16" />
                          <span className="tw-font-normal">
                            <Translate>New Account Balance</Translate>
                          </span>
                        </div>
                        <span className="tw-font-normal">
                          {formatMoney(
                            (invoiceDetails.endingBalance / 100) * -1,
                            Currency.Usd,
                            language
                          )}
                        </span>
                      </div>
                    )}
                  </Highlight>
                </div>
              )}
              <div>
                <ExternalLink href="http://kb.appointlet.help/en/collections/2366883-account-management-billing">
                  <Translate>Learn more about how our billing works.</Translate>
                </ExternalLink>
              </div>
            </Modal.Body>

            <Modal.Footer className="tw-items-stretch tw-justify-between">
              <Button
                className="tw-flex-1"
                onClick={onHide}
                variant="outline-secondary"
              >
                <Translate>Cancel</Translate>
              </Button>
              <LoadingButton
                className="tw-flex-1"
                disabled={!dirty}
                loading={submitting}
                type="submit"
                variant="primary"
              >
                <Translate>Update Billing Cycle</Translate>
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </FinalForm.Form>
    )}
  </Modal>
)

export default ChangeBillingCycleModal
