import { ReactElement } from 'react'
import { NavDropdown } from 'react-bootstrap'
import './HelpDropdown.scss'
import Icon from '../../Icon'

export interface HelpDropdownProps {
  readonly chatCallback: () => void
}

export const HelpDropdown = ({
  chatCallback,
}: HelpDropdownProps): ReactElement => {
  return (
    <div className="Help-Dropdown">
      <NavDropdown
        alignRight
        className="btn-outline d-flex justify-content-center align-items-center border no-caret help-menu-container"
        id="help-dropdown"
        title={
          <div className="text-gray-700 d-flex flex-row align-items-center justify-content-center">
            <div className="mr-4 d-flex align-items-center">
              <Icon.LifeBuoy size={16} />
            </div>
            Help
          </div>
        }
      >
        <NavDropdown.Item
          className="text-gray-900 d-flex align-items-center"
          href="http://kb.appointlet.help/en/collections/2366699-getting-started"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon.CheckSquare size={17} />
          Getting Started
        </NavDropdown.Item>
        <NavDropdown.Item
          className="text-gray-900 d-flex align-items-center"
          href="http://kb.appointlet.help"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon.Book size={17} />
          Knowledge Base
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          className="text-gray-900 d-flex align-items-center"
          onClick={chatCallback}
        >
          <Icon.MessageSquare size={19} />
          Chat with us
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  )
}
