import * as React from 'react'
import { gql } from '@apollo/client'

import DatePicker from './DatePicker'
import {
  Maybe,
  RescheduleMeetingDatePickerQuery as Response,
  useRescheduleMeetingDatePickerQuery,
} from '../__generated__/graphql'
import { ID } from '../types'
import { DateTime } from '../utils'

gql`
  query RescheduleMeetingDatePicker(
    $end: DateTime
    $id: ID!
    $start: DateTime
  ) {
    availableTimes: getMeetingById(id: $id) {
      id
      reschedulableTimes(end: $end, start: $start) {
        data
      }
    }
  }
`

type InternalData = {
  [key: string]: {
    count: number
    times: DateTime[]
  }
}

const wireDataToInternalData = (wire: Response): InternalData => {
  // TODO: This will eventually need to consider timezones as well.
  const dates =
    wire.availableTimes &&
    wire.availableTimes.reschedulableTimes &&
    wire.availableTimes.reschedulableTimes.data
      ? wire.availableTimes.reschedulableTimes.data.map(t => new DateTime(t))
      : []

  const map: InternalData = {}

  for (const date of dates) {
    const key = date.format('YYYY-MM-DD')

    if (!map.hasOwnProperty(key)) {
      map[key] = {
        count: 1,
        times: [date],
      }
    } else {
      map[key].count = map[key].count + 1
      map[key].times = [...map[key].times, date]
    }
  }

  return map
}

interface Props {
  id: ID
  onSelect: (date: DateTime) => void
  startDate: DateTime
}

const RescheduleMeetingDatePicker: React.FC<Props> = ({
  id,
  onSelect,
  startDate,
}) => {
  // We start out at the beginning of the month.
  const [monthStartDate, setMonthStartDate] = React.useState<DateTime>(
    startDate.startOf('month')
  )

  const { data, loading } = useRescheduleMeetingDatePickerQuery({
    // We NEVER want to trust the cache for this query!!!
    fetchPolicy: 'network-only',
    variables: {
      end: monthStartDate.endOf('month').add({ day: 1 }).toISO(),
      id,
      start: monthStartDate.toISO(),
    },
  })

  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data) : null),
    [data]
  )

  const busyRating = (day: DateTime, times: Maybe<InternalData>) => {
    // Guard against the key not existing, paint no dots.
    if (!times!.hasOwnProperty(day.format('YYYY-MM-DD'))) {
      return 0
    } else {
      // In the event the key does exist paint corresponding dots.
      const byDate = times![day.format('YYYY-MM-DD')]
      if (byDate.count <= 3) {
        return 1
      } else if (byDate.count > 3 && byDate.count <= 7) {
        return 2
      } else if (byDate.count > 7) {
        return 3
      }
      return 0
    }
  }
  return (
    <DatePicker
      busyRating={day =>
        loading || !internalData ? 0 : busyRating(day, internalData)
      }
      disableDays={{ before: new DateTime().startOf('day') }}
      onMonthChange={setMonthStartDate}
      onSelect={onSelect}
      selected={startDate}
    />
  )
}

export default RescheduleMeetingDatePicker
