import * as React from 'react'
import { Card } from 'react-bootstrap'
import Illustration from '../../Illustration'
import './NotesTabEmptyState.scss'

export interface NotesTabEmptyStateProps {}

export const NotesTabEmptyState: React.FC<NotesTabEmptyStateProps> = () => {
  return (
    <Card className="NotesTabEmptyState align-items-center d-flex flex-column justify-content-center px-4 py-24 border-0 shadow-none">
      <Illustration name="paperAndFeather" style={{ height: 70 }} />
      <h4 className="mt-8">This meeting doesn't have any notes.</h4>
      <p className="text-gray- text-center mt-4">
        Notes are only visible to you and any other invited team members.
      </p>
    </Card>
  )
}
