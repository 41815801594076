import * as React from 'react'

import ProfileContext from './ProfileContext'

type Props = {
  flag: string
}

const FeatureFlag: React.FC<Props> = ({ children, flag }) => {
  const { featureFlags } = React.useContext(ProfileContext)
  return (
    <React.Fragment>
      {/* Check to see if the current member's profile has had the feature enabled. */}
      {featureFlags?.includes(flag) ? children : null}
    </React.Fragment>
  )
}

export default FeatureFlag
