import * as React from 'react'
import { OnboardHeader } from '../OnboardHeader/OnboardHeader'
import { OnboardFooter } from '../OnboardFooter/OnboardFooter'
import './OnboardingShell.scss'
import { logout } from '../../../../utils'
import { Row } from 'react-bootstrap'
import {
  SingleUserProgressView,
  OnboardingTask,
} from '../../views/SingleUserProgressView/SingleUserProgressView'

export interface OnboardingShellProps {
  readonly email: string
  readonly currentTask: OnboardingTask
  readonly children?: React.ReactNode
}

export const OnboardingShell: React.FC<OnboardingShellProps> = ({
  email,
  currentTask,
  children,
}: OnboardingShellProps) => {
  return (
    <Row className="OnboardingShell min-vh-100 no-gutters">
      <div className="left col-lg-7">
        <div className="header p-16 p-lg-32 d-flex justify-content-center align-items-center">
          <OnboardHeader
            email={email}
            logoutCallback={logout}
            chatCallback={() => window.Intercom('show')}
          />
        </div>
        <div className="content px-16 px-lg-32 pt-24 pt-lg-128">{children}</div>
        <div className="footer px-16 px-lg-32 d-flex align-items-center">
          <OnboardFooter />
        </div>
      </div>
      <div className="right col-lg d-none d-lg-flex bg-primary">
        <SingleUserProgressView currentTask={currentTask} />
      </div>
    </Row>
  )
}
