import * as React from 'react'
import { Redirect, RouteComponentProps } from '@reach/router'
import { gql } from '@apollo/client'

import Page from './Page'
import MemberContext from './MemberContext'
import SchedulingPagesPageEmptyState from './SchedulingPagesPageEmptyState'
import {
  SchedulingPagesPageQuery as Response,
  Maybe,
  useSchedulingPagesPageQuery,
} from '../__generated__/graphql'
import { Team } from '../types'
import { DateTime, isNotSoftDeleted } from '../utils'

gql`
  query SchedulingPagesPage($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            deleted
            id
          }
        }
      }
    }
  }
`

type InternalData = Array<Pick<Team, 'deleted' | 'id'>>

type Props = RouteComponentProps & {}

const wireDataToInternalData = (wire: Response): InternalData => [
  ...wire.member.teams.edges!.map(edge => ({
    ...edge!.node!,
    deleted: edge!.node!.deleted ? new DateTime(edge!.node!.deleted) : null,
  })),
]

const SchedulingPagesPage: React.FC<Props> = () => {
  const member = React.useContext(MemberContext)

  const { data, loading } = useSchedulingPagesPageQuery({
    variables: { id: member.id },
  })

  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data) : null),
    [data]
  )

  // TODO: We should come up with a better loading state.
  // While the query is loading show this UI.
  if (loading || !internalData) {
    return <Page className="SchedulingPagesPage" />
  }

  if (
    !loading &&
    internalData &&
    // Make sure we filter out any deleted scheduling page or we could end up redirecting
    // to a scheduling page that does not exist anymore
    internalData.filter(isNotSoftDeleted).length > 0
  ) {
    // If we find the profile has scheduling pages redirect the user to view
    // the first team in the array.
    // We wrap the redirect in the Page component to prevent a flash when
    // the redirect happens because it re-renders the TopNav.
    return (
      <Page>
        <Redirect
          from="scheduling-pages"
          noThrow
          to={internalData.filter(isNotSoftDeleted)[0].id}
        />
      </Page>
    )
  }

  // If we don't find any scheduling pages on the profile then we will show this
  // view with the ability for the user to create a scheduling page.
  return (
    <Page className="SchedulingPagesPage">
      <SchedulingPagesPageEmptyState />
    </Page>
  )
}

export default SchedulingPagesPage
