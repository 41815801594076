import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, Media, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'
import Illustration from './Illustration'
import FormErrorSubscription from './FormErrorSubscription'
import ImageInput from './ImageInput'
import LoadingButton from './LoadingButton'
import {
  AppDocument,
  useCreateProfileModalMutation,
} from '../__generated__/graphql'
import { isFormFieldInvalid, isRequired, toast } from '../utils'
import Translate from './Translate'

gql`
  mutation CreateProfileModal($input: CreateProfileInput!) {
    createProfile(input: $input) {
      data {
        allocatedSeats
        billedAnnually
        billingCustomerId
        billingSubscriptionId
        brandColor
        id
        image
        members {
          edges {
            node {
              id
              user {
                email
                id
                image
                firstName
                lastName
              }
            }
          }
        }
        name
        occupiedSeats
        personal
      }
      errors {
        field
        messages
      }
    }
  }
`

export type Props = {
  onHide: () => void
  onSuccess: (id: string) => void
}

type FormValues = {
  image: string
  name: string
}

const CreateProfileModal: React.FC<Props> = ({ onHide, onSuccess }) => {
  const [createProfile, { loading }] = useCreateProfileModalMutation({
    // Here we tell the mutation that when it has ran (Error||Success)
    // to refetch the top-level query.
    refetchQueries: [{ query: AppDocument }],
    awaitRefetchQueries: true,
  })
  const onSubmit = async (values: FormValues) => {
    try {
      const res = await createProfile({
        variables: { input: { ...values } },
      })

      if (res.data?.createProfile?.errors) {
        console.log(
          'createProfile, CreateProfileModal.tsx',
          res.data.createProfile.errors
        )
        return
      }

      if (res.data?.createProfile?.data) {
        toast.success(`Created Organization`, res.data.createProfile.data.name!)
        onSuccess(res.data.createProfile.data.id)
      }
    } catch (error) {}
  }
  return (
    <Modal className="CreateProfileModal" onHide={onHide} show>
      <Modal.Header className="tw-border-b-0 tw-pb-0" closeButton />
      <Modal.Body className="tw-pb-12 tw-px-12">
        <div className="tw-flex-col tw-justify-center tw-mb-8 tw-text-center">
          <div className="tw-mb-6">
            <Illustration
              name="markingCalendar"
              className="tw-h-32 md:tw-h-40"
            />
          </div>
          <h1 className="tw-mb-1">
            <Translate>Create an organization</Translate>
          </h1>

          <p className="tw-text-bsGray-600">
            <Translate>
              Organizations are groups of scheduling pages that are billed
              separately.
            </Translate>
          </p>
        </div>

        <FinalForm.Form<FormValues>
          initialValues={{ image: '', name: '' }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, hasValidationErrors, submitting }) => (
            <Form onSubmit={handleSubmit}>
              <Media className="tw-mb-6 tw-items-start ">
                <Media.Body className="tw-mr-3">
                  <Form.Group>
                    <Form.Label>
                      <Translate>Name</Translate>
                    </Form.Label>
                    <FinalForm.Field<FormValues['name']>
                      name="name"
                      validate={isRequired}
                    >
                      {({ input, meta }) => (
                        <React.Fragment>
                          <Form.Control
                            {...input}
                            isInvalid={isFormFieldInvalid(meta)}
                          />
                          <FormErrorSubscription name="name" />
                        </React.Fragment>
                      )}
                    </FinalForm.Field>
                  </Form.Group>
                </Media.Body>
                <FinalForm.Field<FormValues['image']> name="image">
                  {({ input }) => (
                    <ImageInput onChange={input.onChange} value={input.value} />
                  )}
                </FinalForm.Field>
              </Media>
              <LoadingButton
                block
                disabled={hasValidationErrors || submitting}
                loading={loading}
                type="submit"
              >
                <Translate>Create Organization</Translate>
              </LoadingButton>
            </Form>
          )}
        </FinalForm.Form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateProfileModal
