import * as React from 'react'

import { Attendee, User } from '../types'

type Props = {
  user: Pick<User, 'email' | 'firstName' | 'lastName'>
}

type Option =
  | Pick<Attendee, 'email' | 'firstName' | 'lastName'>
  | Pick<User, 'email' | 'firstName' | 'lastName'>

export const generateUserLabel = (option: Option): string => {
  if (option.firstName && option.lastName) {
    return `${option.firstName} ${option.lastName}`
  }
  if (option.firstName) {
    return option.firstName
  }
  return option.email
}

// If a firstName an lastName are not present render the email of the user.
const UserLabel: React.FC<Props> = ({ user }) => <>{generateUserLabel(user)}</>

export default UserLabel
