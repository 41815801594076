import * as React from 'react'
import { Button, Media, Form } from 'react-bootstrap'
import Icon from './Icon'
import IconButton from './IconButton'
import UserImage from './UserImage'
import UserLabel from './UserLabel'
import StarRating from './StarRating'
import {
  HostAssignmentStrategy,
  CalendarProviderNode,
  ConferenceProviderNode,
  Maybe,
} from '../__generated__/graphql'
import TooltipOverlay from './TooltipOverlay'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'

export type Props = {
  firstName: string
  lastName: string
  email: string
  image: Maybe<string>
  fullyOnboarded: boolean
  calendarIntegrations: Array<Pick<CalendarProviderNode, 'name' | 'slug'>>
  conferencingIntegrations: Array<Pick<ConferenceProviderNode, 'name' | 'slug'>>
  hostAssignmentStrategy: HostAssignmentStrategy
  active: boolean
  isHost: boolean
  priority: number
  otherMembersExist: boolean
  integrationsErrorMessage: string | null
  sortListeners?: SyntheticListenerMap
  onEditAvailability?: () => void
  onChangeHost?: () => void
  onChangePriority?: (priority: number) => void
  onRemove?: () => void
  onChangeActive?: () => void
}

const HostAssignmentMemberUnit: React.FC<Props> = ({
  firstName,
  lastName,
  email,
  image,
  fullyOnboarded,
  hostAssignmentStrategy,
  calendarIntegrations,
  conferencingIntegrations,
  active,
  isHost,
  priority,
  otherMembersExist,
  integrationsErrorMessage,
  sortListeners,
  onEditAvailability,
  onChangeHost,
  onChangePriority,
  onRemove,
  onChangeActive,
}) => (
  <Media className="tw-py-4 md:tw-items-center tw-space-x-4">
    {hostAssignmentStrategy === HostAssignmentStrategy.AttendeeChooses && (
      <div className="tw-flex tw-pr-[4px]" {...sortListeners}>
        <Icon.Move size={18} className="tw-text-bsGray-500" />
      </div>
    )}

    <TooltipOverlay
      id="HostAssignmentMemberUnit--Switch"
      text="This member needs to accept their invitation before they can be activated."
      disabled={fullyOnboarded}
    >
      <div className="tw-flex tw-items-center tw-pt-2 md:tw-pt-0">
        <Form.Switch
          checked={active}
          onChange={onChangeActive}
          type="switch"
          id={`active-${email}`}
          disabled={!fullyOnboarded}
        />
      </div>
    </TooltipOverlay>
    <div className="tw-flex tw-flex-col tw-space-y-3 tw-w-full md:tw-flex-row md:tw-space-y-0">
      <Media.Body className="tw-flex tw-items-center tw-space-x-3">
        <UserImage size={40} user={{ image, email }} />
        <div className="tw-flex tw-flex-col tw-space-y-[4px]">
          {!fullyOnboarded && <h6>Invited</h6>}
          <span>
            <UserLabel user={{ firstName, lastName, email }} />
          </span>
        </div>
        <div className="tw-flex tw-items-center tw-space-x-[4px]">
          <>
            {calendarIntegrations.map(ci => (
              <Icon.InternalAsset
                title={ci.name}
                key={ci.slug}
                assetName={ci.slug}
                size={16}
              />
            ))}

            {conferencingIntegrations.map(ci => (
              <Icon.InternalAsset
                title={ci.name}
                key={ci.slug}
                assetName={ci.slug}
                size={16}
              />
            ))}
          </>
        </div>
      </Media.Body>
      <div className="tw-flex tw-items-center tw-space-x-3">
        {hostAssignmentStrategy === HostAssignmentStrategy.Pooled && (
          <TooltipOverlay
            id="HostAssignmentMemberUnit--Rating"
            text={'Prioritize the selection of this member in the pool'}
            delay={{ show: 1500, hide: 0 }}
          >
            <div className="tw-flex tw-items-center tw-self-stretch">
              <StarRating value={priority} onChange={onChangePriority!} />
            </div>
          </TooltipOverlay>
        )}

        {isHost &&
          otherMembersExist &&
          hostAssignmentStrategy === HostAssignmentStrategy.Joint && (
            <Button size="sm" variant="outline-secondary" disabled>
              <Icon.UserCheck size={16} />
              Host
            </Button>
          )}

        {!isHost &&
          active &&
          otherMembersExist &&
          hostAssignmentStrategy === HostAssignmentStrategy.Joint && (
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={onChangeHost}
            >
              <Icon.UserCheck size={16} />
              Set as Host
            </Button>
          )}

        {active && integrationsErrorMessage && (
          <TooltipOverlay
            id="warning"
            text={integrationsErrorMessage}
            delay={{ show: 250, hide: 0 }}
          >
            <IconButton className="tw-text-bsDanger">
              <Icon.AlertTriangle size={24} />
            </IconButton>
          </TooltipOverlay>
        )}

        {hostAssignmentStrategy !== HostAssignmentStrategy.Joint && (
          <IconButton
            variant="success"
            tooltip="Edit Availability"
            onClick={onEditAvailability}
          >
            <Icon.Calendar size={24} />
          </IconButton>
        )}

        <IconButton variant="danger" tooltip="Remove" onClick={onRemove}>
          <Icon.Trash2 size={24} />
        </IconButton>
      </div>
    </div>
  </Media>
)

// Create a stateful version that uses the dnd-kit sorting state features.
export const SortableHostAssignmentMemberUnit: React.FC<
  Props & { id: string }
> = ({ id, ...rest }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id })

  // We wrap the MeetingCard in a memo here because dnd-kit
  // calls re-render a lot and performance is really bad if
  // we don't.
  const memoize = React.useMemo<JSX.Element>(
    () => <HostAssignmentMemberUnit {...rest} sortListeners={listeners} />,
    [rest, listeners]
  )

  return (
    <div
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Transform.toString(transform),
      }}
      {...attributes}
    >
      {memoize}
    </div>
  )
}

export default HostAssignmentMemberUnit
