import { gql } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import * as React from 'react'
import { TimeBlock } from '../../../types'
import {
  Analytics,
  Duration,
  DateTime,
  getDefaultRecurringTimeBlocks,
  toast,
  isListOfTimeBlocksWithMinimumDuration,
  isListOfTimeBlocksWithNoOverlappingRanges,
  isListOfTimeBlocksWithValidTimeRanges,
} from '../../../utils'
import MemberContext from '../../MemberContext'
import UserContext from '../../UserContext'
import { AvailabilityScreen } from '../screens/AvailabilityScreen/AvailabilityScreen'
import {
  Maybe,
  Scalars,
  Timezone,
  useOnboardingAvailabilityPageQuery,
  useSetDefaultAvailabilityMutation,
  useUpdateMemberAvailabilityMutation,
  useOnboardingAvailabilityPageUpdateUserMutation,
  useOnboardingAvailabilityPageUpdateProfileMutation,
  TimeBlockSerializerInput,
  TimeBlockNodeEdge,
} from '../../../__generated__/graphql'
import ProfileContext from '../../ProfileContext'
import { useOnboardingState } from '../../../hooks/useOnboardingState'

gql`
  query OnboardingAvailabilityPage($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            id
            name
            slug
            bookingUrl
          }
        }
      }
      schedules {
        edges {
          node {
            id
            timeBlocks {
              edges {
                node {
                  weekday
                  start
                  end
                }
              }
            }
          }
        }
      }
    }
  }
`

gql`
  mutation SetDefaultAvailability($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      data {
        id
        timeBlocks {
          edges {
            node {
              id
              start
              end
              weekday
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation UpdateMemberAvailability($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
      data {
        id
        timeBlocks {
          edges {
            node {
              id
              start
              end
              weekday
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation OnboardingAvailabilityPageUpdateUser($input: UpdateUserInput!) {
    user: updateUser(input: $input) {
      data {
        id
        needsOnboarding
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation OnboardingAvailabilityPageUpdateProfile(
    $input: UpdateProfileInput!
  ) {
    profile: updateProfile(input: $input) {
      data {
        id
        needsOnboarding
      }
      errors {
        field
        messages
      }
    }
  }
`

export interface MemberNode {
  id: Scalars['ID']
  schedules: Maybe<Array<MemberScheduleNode>>
}
export interface MemberScheduleNode {
  id: Scalars['ID']
  timeBlocks: Array<TimeBlock>
}

const internalTimeBlocksToWireData = (
  timeBlocks: TimeBlock[]
): Array<TimeBlockSerializerInput> => {
  return timeBlocks.map(timeBlock => {
    return {
      date: timeBlock.date,
      end: timeBlock.endTime!.format('twenty-four-hour-time-with-seconds'),
      start: timeBlock.startTime!.format('twenty-four-hour-time-with-seconds'),
      weekday: timeBlock.weekday,
    }
  })
}

const wireDataToInternalTimeBlocks = (
  timeBlocks: Array<TimeBlockNodeEdge>
): Array<TimeBlock> =>
  timeBlocks.map(tb => ({
    startTime: new DateTime(tb.node?.start),
    endTime: new DateTime(tb.node?.end),
    weekday: tb.node?.weekday || null,
    frequency: null,
    date: null,
  }))

const OnboardingAvailabilityPage: React.FC<RouteComponentProps> = () => {
  const [memberScheduleId, setMemberScheduleId] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [createMemberSchedule] = useSetDefaultAvailabilityMutation()
  const [updateMemberSchedule] = useUpdateMemberAvailabilityMutation()
  const member = React.useContext(MemberContext)
  const { user } = React.useContext(UserContext)
  const profile = React.useContext(ProfileContext)
  const isSingleUser = user.profiles[0].personal
  const minimumDuration = new Duration({ minutes: 15 })
  const defaultTimeBlocks = getDefaultRecurringTimeBlocks()
  const { data, refetch } = useOnboardingAvailabilityPageQuery({
    variables: { id: member.id },
  })

  const [updateUser] = useOnboardingAvailabilityPageUpdateUserMutation()
  const [updateProfile] = useOnboardingAvailabilityPageUpdateProfileMutation()

  // Get access to our onboarding state
  const clearOnboardingState = useOnboardingState()[2]

  const internalData: MemberNode | null = React.useMemo(() => {
    return data
      ? {
          id: data.member.id,
          schedules: [
            {
              id: data.member.schedules.edges[0]?.node?.id || '',
              timeBlocks: data.member.schedules.edges[0]?.node?.timeBlocks.edges
                ? wireDataToInternalTimeBlocks(
                    data.member.schedules.edges[0]?.node?.timeBlocks
                      .edges as TimeBlockNodeEdge[]
                  )
                : [],
            },
          ],
        }
      : null
  }, [data])

  const handleCreateMemberSchedule = async () => {
    const formattedDefaultTimeBlocks =
      internalTimeBlocksToWireData(defaultTimeBlocks)
    try {
      const memberSchedule = await createMemberSchedule({
        variables: {
          input: {
            timezone: user.timezone as Timezone,
            timeBlocks: formattedDefaultTimeBlocks,
            member: member.id,
            profile: profile.id,
            name: 'Default',
          },
        },
      })

      const scheduleId = memberSchedule?.data?.createSchedule?.data?.id
      if (scheduleId) {
        setMemberScheduleId(scheduleId)
        refetch()
      }
    } catch (error) {
      console.error('Failed to create schedule', error)
      toast.error('Failed to create schedule')
      return
    }
  }

  const handleUpdateMemberSchedule = async (
    formattedValues: Array<TimeBlockSerializerInput>
  ) => {
    return await updateMemberSchedule({
      variables: {
        input: {
          timezone: user.timezone as Timezone,
          timeBlocks: formattedValues,
          id: memberScheduleId,
          name: 'Default',
        },
      },
    })
  }

  React.useEffect(() => {
    Analytics.trackEvent('Onboarding Availability Page: Loaded')
  }, [])

  React.useEffect(() => {
    // handles creating new member schedule if one doesn't exist
    if (
      internalData !== null &&
      internalData.schedules &&
      !internalData.schedules[0].id
    ) {
      handleCreateMemberSchedule()
    }
    if (
      internalData !== null &&
      internalData.schedules &&
      internalData.schedules.length > 0
    ) {
      setMemberScheduleId(internalData.schedules?.[0].id)
    }
    // We only depend on internal data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalData])

  const handleUpdateNeedsOnboarding = async () => {
    try {
      await updateUser({
        variables: {
          input: {
            email: user.email,
            id: user.id,
            needsOnboarding: false,
            timezone: user.timezone!,
          },
        },
      })
      await updateProfile({
        variables: {
          input: {
            id: user.profiles[0].id,
            name: user.profiles[0].name,
            needsOnboarding: false,
          },
        },
      })
      Analytics.trackEvent('Onboarding Availability Page: Submitted')
    } catch (error) {
      setIsLoading(false)
      toast.error('Something went wrong')
      return error
    }
  }

  const onSubmit = async (values: TimeBlock[]): Promise<void> => {
    setIsLoading(true)

    // validates submitted values
    if (
      !!isListOfTimeBlocksWithMinimumDuration(minimumDuration)(values) ||
      !!isListOfTimeBlocksWithNoOverlappingRanges()(values) ||
      !!isListOfTimeBlocksWithValidTimeRanges()(values)
    ) {
      setIsLoading(false)
      console.error('error in submission')
      toast.error('Please check submission for errors')
      return
    }

    const formattedValues = internalTimeBlocksToWireData(values)
    try {
      await handleUpdateMemberSchedule(formattedValues)
      await handleUpdateNeedsOnboarding()

      // Clear the onboarding state now that we're done.
      clearOnboardingState()
    } catch (error) {
      setIsLoading(false)
      console.error('Failed to update:', error)
      toast.error('Failed to update member schedule')
      return
    }
  }

  //render with default blocks until actual timeBlocks are fetched and set in internalData to reduce perceived load time
  const timeBlocksToUse = React.useMemo(() => {
    return !!internalData &&
      internalData.schedules &&
      internalData.schedules[0].id
      ? internalData.schedules[0].timeBlocks
      : defaultTimeBlocks
    // We only depend on internal data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalData])

  return (
    <React.Fragment>
      <AvailabilityScreen
        email={user.email}
        timeBlocks={timeBlocksToUse}
        scheduleBounds={null}
        minimumTimeBlockDuration={minimumDuration}
        skipSectionCallback={() => {
          handleUpdateNeedsOnboarding()

          // Clear the onboarding state now that we're done.
          clearOnboardingState()
        }}
        onSubmitCallback={onSubmit}
        isSingleUser={isSingleUser}
        isLoading={isLoading}
      />
    </React.Fragment>
  )
}

export default OnboardingAvailabilityPage
