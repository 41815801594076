import * as React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import IconTextInput from './IconTextInput'
import Icon from './Icon'

import './PlacesInput.scss'

interface Props {
  onChange?: (value: string) => void
  onFocus?: (event?: React.FocusEvent<HTMLElement> | undefined) => void
  onBlur?: (event?: React.FocusEvent<HTMLElement> | undefined) => void
  value?: string
  isInvalid?: boolean
}

const PlacesInput = React.forwardRef<HTMLInputElement, Props>(
  ({ onChange, onFocus, onBlur, value, isInvalid }, ref) => {
    // The Google Places API has a concept called "session tokens",
    // which link multiple autocomplete requests together into a single
    // session.  We are billed by the session, so it's important that we
    // get as many requests bundled into a single session as possible.
    // It seems like sessions last about 3 minutes, so we should try and
    // refresh them with that frequency.
    //
    // Some relevant links:
    // https://github.com/hibiken/react-places-autocomplete/issues/263
    // https://stackoverflow.com/questions/50398801/how-long-do-the-new-places-api-session-tokens-last
    // https://developers.google.com/maps/documentation/places/web-service/session-tokens
    const [sessionToken, setSessionToken] =
      React.useState<google.maps.places.AutocompleteSessionToken>(
        () => new google.maps.places.AutocompleteSessionToken()
      )

    // Start an interval that fires every 3 minutes and refreshes the session token.
    React.useEffect(() => {
      const sessionRefresher = setInterval(
        () =>
          setSessionToken(new google.maps.places.AutocompleteSessionToken()),
        60 * 3 * 1000
      )

      // When this component unloads kill the refresher
      return () => clearInterval(sessionRefresher)
    }, [])

    return (
      <PlacesAutocomplete
        onChange={onChange}
        value={value}
        debounce={1000}
        searchOptions={{
          sessionToken,
        }}
        // ONLY send a a request to Google Maps if the user input
        // is 7 characters in length or greater
        shouldFetchSuggestions={!!value && value.length >= 7}
      >
        {({ getInputProps, getSuggestionItemProps, loading, suggestions }) => (
          <div className="PlacesInput">
            <IconTextInput
              {...getInputProps()}
              onFocus={onFocus}
              onBlur={onBlur}
              icon={Icon.MapPin}
              isInvalid={isInvalid}
              ref={ref}
            />

            {!!suggestions.length && (
              <div className="dropdown-menu show">
                {suggestions.map((suggestion, idx) => (
                  <div
                    className={`dropdown-item ${suggestion.active && 'active'}`}
                    {...getSuggestionItemProps(suggestion)}
                    // For some reason the key that getSuggestionItemProps doesn't
                    // seem to be unique so react throws an error about it.
                    key={idx}
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
)

export default PlacesInput
