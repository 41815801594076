import * as React from 'react'

import { Member, PlanNode, Profile, Role, User } from '../types'
import { UserLocalSettings } from '../utils'

export type InternalUser = Pick<
  User,
  | 'createdAt'
  | 'email'
  | 'firstName'
  | 'id'
  | 'image'
  | 'language'
  | 'lastName'
  | 'needsOnboarding'
  | 'timezone'
> & {
  members: Array<InternalMember>
  // Computed Values
  profileCount: number
  profiles: Array<
    Pick<
      Profile,
      | 'allocatedSeats'
      | 'billedAnnually'
      | 'billingCustomerId'
      | 'billingSubscriptionId'
      | 'brandColor'
      | 'id'
      | 'image'
      | 'name'
      | 'occupiedSeats'
      | 'personal'
    >
  >
}

export type InternalMember = Pick<
  Member,
  'id' | 'invitationAccepted' | 'isOwner' | 'meetingCount'
> & {
  profile: Pick<
    Profile,
    | 'allocatedSeats'
    | 'billedAnnually'
    | 'billingCustomerId'
    | 'billingSubscriptionId'
    | 'brandColor'
    | 'featureFlags'
    | 'id'
    | 'image'
    | 'name'
    | 'occupiedSeats'
    | 'personal'
    | 'needsOnboarding'
  > & {
    members: Array<
      Pick<Member, 'id' | 'isOwner'> & {
        user: Pick<User, 'email' | 'firstName' | 'id' | 'image' | 'lastName'>
      }
    >
    plan: Pick<
      PlanNode,
      | 'allowEmailCustomization'
      | 'allowFormFields'
      | 'allowManualConfirm'
      | 'allowPaymentCollection'
      | 'allowPrivateMeetingTypes'
      | 'allowRedirect'
      | 'allowReminders'
      | 'allowRoundRobin'
      | 'allowUtmParameters'
      | 'allowWebConferencing'
      | 'allowWebhooks'
      | 'allowZapier'
      | 'freeDefault'
      | 'id'
      | 'name'
      | 'requireAppointletBranding'
      | 'seatPriceMonthly'
      | 'seatPriceYearly'
      | 'trialDefault'
    >
  }
  role: Pick<
    Role,
    | 'canManageBilling'
    | 'canManageMeetings'
    | 'canManageMeetingTypes'
    | 'canManageMembers'
    | 'canManageOrgSettings'
    | 'id'
  >
}

type ContextType = {
  settings: UserLocalSettings
  user: InternalUser
}

const UserContext = React.createContext<ContextType>({} as ContextType)

UserContext.displayName = 'UserContext'

export default UserContext
