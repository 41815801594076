import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form } from 'react-bootstrap'
import DurationInput from './DurationInput'
import './MeetingTypeFrequencyPopover.scss'
import { IMeetingType } from './UpdateMeetingTypeContext'
import PopoverOverlay from './PopoverOverlay'
import { Maybe } from '../__generated__/graphql'
import {
  durationLessThan,
  durationGreaterThan,
  composeValidators,
  Duration,
} from '../utils'
import Disabler from './Disabler'

type Props = {
  onChange: (value: Maybe<Duration>) => void
  value: Maybe<Duration>
  duration: Duration
}

type FormValues = {
  frequency: Maybe<Duration>
}

const minFrequency = new Duration({ minutes: 5 })
const maxFrequency = new Duration({ hours: 8 })

const MeetingTypeFrequencyPopover: React.FC<Props> = ({
  onChange,
  value,
  duration,
}) => {
  const [customFrequency, setCustomFrequency] = React.useState<boolean>(!!value)

  return (
    <PopoverOverlay
      popover={({ close }) => (
        <FinalForm.Form<FormValues>
          initialValues={{
            frequency: value || duration,
          }}
          onSubmit={values => {
            // Look to the customFrequency flag to see what we should pass back.
            onChange(customFrequency ? values.frequency : null)

            close()
          }}
        >
          {({ handleSubmit, submitting, values }) => (
            <Form
              onSubmit={handleSubmit}
              className="MeetingTypeTimezonePopover"
            >
              <FinalForm.Field<IMeetingType['frequency']>
                name="frequency"
                validate={composeValidators(
                  durationGreaterThan(minFrequency),
                  durationLessThan(maxFrequency)
                )}
              >
                {({ input, meta }) => (
                  <React.Fragment>
                    <Form.Group className="mb-12">
                      <Form.Check
                        checked={!customFrequency}
                        label={`Same as meeting length (${duration.toHuman()})`}
                        onChange={() => {
                          setCustomFrequency(false)

                          // Default to the duration
                          input.onChange(duration)
                        }}
                        type="radio"
                        value={String(false)}
                        id="durationFrequency"
                      />
                    </Form.Group>

                    <Form.Group className="mb-12">
                      <Form.Check
                        checked={customFrequency}
                        label="Use a custom frequency"
                        onChange={() => {
                          setCustomFrequency(true)

                          // Default to the duration
                          input.onChange(duration)
                        }}
                        type="radio"
                        value={String(true)}
                        id="specificFrequency"
                      />
                    </Form.Group>

                    {input.value && (
                      <Form.Group className="mb-12">
                        <Disabler disabled={!customFrequency}>
                          <DurationInput
                            isInvalid={meta.invalid}
                            onChange={input.onChange}
                            value={input.value}
                          />

                          {meta.error && (
                            <Form.Text className="text-danger">
                              {meta.error}
                            </Form.Text>
                          )}
                        </Disabler>
                      </Form.Group>
                    )}
                  </React.Fragment>
                )}
              </FinalForm.Field>
              <Button
                block
                disabled={(customFrequency && !values.frequency) || submitting}
                size="sm"
                type="submit"
              >
                Apply
              </Button>
            </Form>
          )}
        </FinalForm.Form>
      )}
    >
      <Button className="ml-4" variant="link">
        Customize
      </Button>
    </PopoverOverlay>
  )
}

export default MeetingTypeFrequencyPopover
