import * as React from 'react'
import { Button, Media, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import LoadingButton from './LoadingButton'
import {
  useCalendarAccountDeleteMutation,
  useDeleteCalendarAccountModalQuery,
} from '../__generated__/graphql'
import { toast, Analytics } from '../utils'
import { ID } from '../types'
import Icon from './Icon'
import Translate from './Translate'
import Var from './Var'

gql`
  query DeleteCalendarAccountModal($calendarAccountId: ID!) {
    getCalendarAccountById(id: $calendarAccountId) {
      id
      name
      provider {
        id
        name
        slug
      }
    }
  }
`

gql`
  mutation CalendarAccountDelete($input: DeleteCalendarAccountInput!) {
    deleteCalendarAccount(input: $input) {
      data {
        id
      }
    }
  }
`

export type Props = {
  calendarAccountId: ID
  onHide: () => void
  onSuccess: () => void
}

const DeleteCalendarAccountModal: React.FC<Props> = ({
  calendarAccountId,
  onHide,
  onSuccess,
}) => {
  const { data } = useDeleteCalendarAccountModalQuery({
    variables: { calendarAccountId: calendarAccountId },
  })

  // Prep mutation
  const [deleteCalendarAccount, { called, error: submitError }] =
    useCalendarAccountDeleteMutation()

  const onSubmit = async () => {
    try {
      const res = await deleteCalendarAccount({
        optimisticResponse: {
          __typename: 'Mutations',
          deleteCalendarAccount: {
            __typename: 'DeleteCalendarAccountPayload',
            data: {
              __typename: 'CalendarAccountNode',
              id: calendarAccountId,
            },
          },
        },
        variables: { input: { id: calendarAccountId } },
      })

      // If client validation and GraphQL error related errors exist
      if (res.errors || !res.data?.deleteCalendarAccount?.data) {
        throw new Error('Failed to delete calendar integration')
      }

      // Let the user know we're good
      toast.success(
        `Deleted Calendar Integration`,
        data!.getCalendarAccountById!.name
      )

      Analytics.trackEvent('Deleted calendar account', {
        source: 'delete-calendar-account-modal',
      })

      onSuccess()

      // Here we are catching sever related errors.
    } catch (err) {
      toast.error('Something went wrong')
      console.error('deleteCalendarAccount', err)
    }
  }

  const calendarAccount = data?.getCalendarAccountById

  return (
    <Modal
      className="DeleteCalendarAccountModal"
      onHide={onHide}
      size="sm"
      show={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Translate>Disconnect Calendar Integration</Translate>
        </Modal.Title>
      </Modal.Header>

      {calendarAccount && (
        <>
          <Modal.Body className="tw-flex tw-flex-col tw-space-y-4">
            <Translate as="p">
              Are you sure you want to disconnect this calendar integration?
            </Translate>

            <Media className="tw-items-center">
              <Icon.InternalAsset
                assetName={calendarAccount.provider.slug}
                size={24}
              />
              <Media.Body className="tw-ml-2">
                <strong>
                  <Var name="name">{calendarAccount.name}</Var>
                </strong>
              </Media.Body>
            </Media>

            <Translate as="p">
              It will no longer be checked for conflicts or have meetings synced
              with it.
            </Translate>
          </Modal.Body>

          <Modal.Footer className="tw-items-stretch tw-justify-between md:tw-justify-end">
            <Button
              className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
              variant="outline-secondary"
              onClick={onHide}
            >
              <Translate>Cancel</Translate>
            </Button>
            <LoadingButton
              className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
              // Show as loading once the mutation is called
              // and stay that way until the caller dismisses
              // the modal.
              //
              // This is done so that if the caller wants to
              // perform another async task (like a refetch)
              // that it can do so while keeping the user here.
              loading={called && !submitError}
              onClick={onSubmit}
              variant="danger"
            >
              <Translate>Disconnect</Translate>
            </LoadingButton>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default DeleteCalendarAccountModal
