import * as React from 'react'
import { Props as PickerProps } from './Picker'
import { gql } from '@apollo/client'
import { Timezone, useTimeZonePickerQuery } from '../__generated__/graphql'
import { PureTimeZonePicker } from './molecules/PureTimeZonePicker/PureTimeZonePicker'

gql`
  query TimeZonePicker {
    timeZones: __type(name: "Timezone") {
      enumValues {
        name
        description
      }
    }
  }
`

export interface Props
  extends Omit<PickerProps, 'value' | 'options' | 'onChange'> {
  onChange: (timezone: Timezone | null) => void
  value: Timezone
  isInvalid?: boolean
}

const TimeZonePicker: React.FC<Props> = ({
  disabled,
  required,
  onChange,
  value,
  isInvalid,
}) => {
  const { data, loading } = useTimeZonePickerQuery()

  return (
    <PureTimeZonePicker
      required={required}
      disabled={disabled}
      isLoading={loading}
      isInvalid={isInvalid}
      onChange={onChange}
      timezoneOptionEnums={data?.timeZones?.enumValues || []}
      value={value || null}
    />
  )
}

export default TimeZonePicker
