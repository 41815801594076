import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import './OnboardingPages.scss'
import LoadingButton from './LoadingButton'
import { useUpdateAvailabilityCalendarsModalMutation } from '../__generated__/graphql'
import { ID } from '../types'
import AvailabilityCalendarsPicker from './AvailabilityCalendarsPicker'
import { toast } from '../utils'
import Translate from './Translate'

gql`
  mutation UpdateAvailabilityCalendarsModal(
    $input: SetCalendarTransparencyInput!
  ) {
    setCalendarTransparency(input: $input) {
      data {
        id
        transparent
      }
    }
  }
`

export type Props = {
  memberId: ID
  value: Array<ID>
  onHide: () => void
  onSuccess: () => void
}

type FormValues = {
  availabilityCalendars: Array<ID>
}

const UpdateAvailabilityCalendarsModal: React.FC<Props> = ({
  memberId,
  value: initialAvailabilityCalendars,
  onHide,
  onSuccess,
}) => {
  const [toggleTransparency] = useUpdateAvailabilityCalendarsModalMutation()
  const [loading, setLoading] = React.useState<boolean>(false)

  const onSubmit = async ({ availabilityCalendars }: FormValues) => {
    setLoading(true)

    // TODO: fix this with a better mutation.
    // Currently we have a mutation setCalendarTransparency that operates on a single
    // calendar, so we're going to call it in a loop.  In addition to that, also need
    // to call it for the calendars that were select an no longer are.

    // Start by filtering down to just the availabilityCalendars that were added.
    const addedAvailabilityCalendars = availabilityCalendars.filter(
      id => initialAvailabilityCalendars.indexOf(id) === -1
    )

    // Now create a list of ones that were removed.
    const removedAvailabilityCalendars = initialAvailabilityCalendars.filter(
      id => availabilityCalendars.indexOf(id) === -1
    )

    // Merge them into an easiest to use list
    const changedAvailabilityCalendars = [
      ...addedAvailabilityCalendars.map(id => ({ id, transparent: false })),
      ...removedAvailabilityCalendars.map(id => ({ id, transparent: true })),
    ]

    try {
      // It is important to note that the Promise.all API is an all or none.
      // All Promises must resolve to be successful, should one reject Promise.all
      // will reject with the specific promise that failed as the return data.
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all
      await Promise.all(
        changedAvailabilityCalendars.map(cal =>
          toggleTransparency({ variables: { input: cal } })
        )
      )

      onSuccess()
    } catch (error) {
      setLoading(false)
      toast.error('Something went wrong')
      console.error('setCalendarTransparency', error)
    }
  }
  return (
    <Modal className="OnboardingPages--Modals" onHide={onHide} show size="sm">
      <Modal.Header closeButton>
        <Modal.Title>
          <Translate>Change Availability Calendars</Translate>
        </Modal.Title>
      </Modal.Header>

      <FinalForm.Form<FormValues>
        initialValues={{ availabilityCalendars: initialAvailabilityCalendars }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form
            className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
            onSubmit={handleSubmit}
          >
            <Modal.Body>
              <Form.Group>
                <Form.Label>
                  <Translate>
                    Which calendars should Appointlet check for conflicts?
                  </Translate>
                </Form.Label>
                <FinalForm.Field<Array<ID>> name="availabilityCalendars">
                  {({ input }) => (
                    <AvailabilityCalendarsPicker
                      memberId={memberId}
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </FinalForm.Field>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="tw-items-stretch tw-justify-between md:tw-justify-end">
              <Button
                className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
                variant="outline-secondary"
                onClick={onHide}
              >
                <Translate>Cancel</Translate>
              </Button>
              <LoadingButton
                className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
                loading={loading}
                type="submit"
              >
                <Translate>Update Availability Calendars</Translate>
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </FinalForm.Form>
    </Modal>
  )
}

export default UpdateAvailabilityCalendarsModal
