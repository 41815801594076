import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form } from 'react-bootstrap'
import { uniq } from 'lodash'
import { UnsavedFormField } from './CreateFormFieldModal'
import FormErrorSubscription from './FormErrorSubscription'
import { FormFieldType } from '../types'
import { composeValidators, isRequired } from '../utils'

type Props = {}

const FormFieldSettings: React.FC<Props> = () => {
  return (
    <>
      <Form.Group>
        <Form.Label>Label</Form.Label>

        <FinalForm.Field<UnsavedFormField['name']>
          name="name"
          validate={isRequired}
        >
          {({ input, meta }) => (
            <Form.Control {...input} isInvalid={meta.invalid} />
          )}
        </FinalForm.Field>

        <FinalForm.Field<UnsavedFormField['visible']> name="visible">
          {({ input: { value: visible } }) => (
            <FinalForm.Field<UnsavedFormField['required']>
              name="required"
              type="checkbox"
            >
              {({ input: { checked, name, onChange } }) => (
                // Remove value because it evaluates to 'undefined'
                // since this is 'type=checkbox' we use 'checked' instead.
                <Form.Check
                  className="tw-mt-[4px]"
                  // If the user wants a field to be hidden
                  // we do not want it to be a required field.
                  disabled={!visible}
                  id="UpdateFormFieldModal-required"
                  label="Required (must be populated)"
                  name={name}
                  onChange={() => onChange(!checked)}
                  // If the user marks the field as hidden,
                  // uncheck the required box.
                  checked={!visible ? false : checked}
                  type="checkbox"
                />
              )}
            </FinalForm.Field>
          )}
        </FinalForm.Field>
      </Form.Group>

      <FinalForm.Field<UnsavedFormField['fieldType']> name="fieldType">
        {({ input: { value: fieldType } }) => (
          <Form.Group>
            <Form.Label>
              Help Text{' '}
              {fieldType !== FormFieldType.Toggle && (
                <small className="tw-text-bsGray-600">Optional</small>
              )}
            </Form.Label>

            <FinalForm.Field<UnsavedFormField['helpText']>
              name="helpText"
              validate={helpText =>
                fieldType === FormFieldType.Toggle
                  ? isRequired(helpText)
                  : undefined
              }
              // This is needed because of this:
              // https://github.com/final-form/react-final-form/issues/130
              parse={value => value}
            >
              {({ input, meta }) => (
                <Form.Control
                  {...input}
                  as="textarea"
                  isInvalid={meta.invalid}
                  placeholder="Additional explanation for how to populate field."
                  rows={2}
                  value={input.value || ''}
                />
              )}
            </FinalForm.Field>
            <FormErrorSubscription name="helpText" />
          </Form.Group>
        )}
      </FinalForm.Field>

      {/* We access the fieldType to decide if we should show choices, but we don't let the user update it. */}
      <FinalForm.Field<UnsavedFormField['fieldType']> name="fieldType">
        {({ input: { value: fieldType } }) =>
          (fieldType === FormFieldType.Multichoice ||
            fieldType === FormFieldType.Choice) && (
            <Form.Group>
              <Form.Label>Choices</Form.Label>

              <FinalForm.Field<UnsavedFormField['choices']>
                name="choices"
                validate={composeValidators<UnsavedFormField['choices']>(
                  value =>
                    value!.length < 2
                      ? `You must provide at least two choices.`
                      : undefined,
                  value =>
                    value!.length !== uniq(value).length
                      ? 'Please remove duplicate choices.'
                      : undefined,
                  value =>
                    value!.filter(item => item.length > 255).length > 0
                      ? 'Choices must be 255 characters or less'
                      : undefined
                )}
              >
                {({ input, meta }) => (
                  <>
                    <Form.Control
                      {...input}
                      as="textarea"
                      // One off style to cover any browser that does not
                      // support the 'wrap' API yet.
                      className="tw-overflow-auto tw-whitespace-nowrap"
                      isInvalid={meta.invalid}
                      onChange={e => {
                        const value = e.currentTarget.value
                        input.onChange(
                          value.length === 0 ? [] : value.split('\n')
                        )
                      }}
                      rows={5}
                      value={input.value ? input.value.join('\n') : ['']}
                      // Will not cause user input to wrap to next line.
                      wrap="soft"
                    />
                    {!meta.invalid && (
                      <Form.Text>One choice per line</Form.Text>
                    )}
                    <FormErrorSubscription name="choices" />
                  </>
                )}
              </FinalForm.Field>
            </Form.Group>
          )
        }
      </FinalForm.Field>
    </>
  )
}

export default FormFieldSettings
