import * as React from 'react'
import { Alert, Button, Media } from 'react-bootstrap'
import Icon from '../../Icon'
import TooltipOverlay from '../../TooltipOverlay'

export type Props = {
  readonly provider: {
    url: string | null
    conferenceProviderName: string
    conferenceProviderSlug: string
  }
  readonly disabled: boolean
  readonly isHost: boolean
  readonly integrationError: 'needs_connect' | 'needs_reconnect'
  readonly onConnect?: () => void
  readonly onReconnect?: () => void
}

const MeetingConferenceError: React.FC<Props> = ({
  provider,
  disabled,
  isHost,
  onConnect,
  onReconnect,
  integrationError,
}: Props) => {
  const errorText =
    integrationError === 'needs_connect'
      ? isHost
        ? `Connect ${provider.conferenceProviderName} to create a conferencing room.`
        : `The host of this meeting does not have ${provider.conferenceProviderName} connected.`
      : `We were unable to create a ${provider.conferenceProviderName} room.`
  return (
    <Alert variant="danger" className="p-12">
      <Media className="d-flex align-items-center space-between-8">
        <Icon.InternalAsset
          assetName={provider.conferenceProviderSlug}
          size={20}
          alt={provider.conferenceProviderName}
          title={provider.conferenceProviderName}
        />
        <Media.Body className="align-items-center text-danger">
          <span>{errorText}</span>
        </Media.Body>

        <TooltipOverlay
          id="conference-error-connect"
          disabled={!disabled}
          text="Only the host of the meeting can connect"
        >
          {/* We must wrap the button in this <div/> to trigger to tooltip */}
          <div>
            <Button
              variant="danger"
              size="sm"
              onClick={
                integrationError === 'needs_connect' ? onConnect : onReconnect
              }
              disabled={disabled}
            >
              {integrationError === 'needs_connect'
                ? `Connect ${provider.conferenceProviderName}`
                : `Reconnect ${provider.conferenceProviderName}`}
            </Button>
          </div>
        </TooltipOverlay>
      </Media>
    </Alert>
  )
}
export default MeetingConferenceError
