/* Generated by `yarn run codegen`.  Do not update manually. */

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ConferencingAccountNode: [
      'GoToMeetingAccountNode',
      'JoinMeAccountNode',
      'MicrosoftTeamsAccountNode',
      'WebexAccountNode',
      'ZoomAccountNode',
    ],
    Node: [
      'AttendeeFieldNode',
      'AttendeeNode',
      'CalendarAccountNode',
      'CalendarNode',
      'CalendarProviderNode',
      'ConferenceProviderNode',
      'FormFieldNode',
      'MeetingNode',
      'MeetingNoteNode',
      'MeetingReminderNode',
      'MeetingTypeNode',
      'MeetingTypeReminderNode',
      'MeetingTypeScheduleNode',
      'MeetingTypeTeamMemberNode',
      'MeetingTypeTimeBlockNode',
      'MemberNode',
      'PaymentCollectionAccountNode',
      'PaymentCollectionProviderNode',
      'PlanNode',
      'ProfileNode',
      'ReportNode',
      'RoleNode',
      'ScheduleNode',
      'TeamMemberNode',
      'TeamMemberScheduleNode',
      'TeamMemberTimeBlockNode',
      'TeamNode',
      'TimeBlockNode',
      'UserNode',
      'WebhookNode',
    ],
  },
}
export default result
