import * as React from 'react'
import { Col, Row } from 'react-bootstrap'

import './ReschedulableTimesPicker.scss'
import TimeLabel from './TimeLabel'
import RescheduleTimesButton from './ReschedulableTimesButton'
import Toggler from './Toggler'
import NewMeetingTimeModal from './NewMeetingTimeModal'
import Icon from './Icon'
import { Meeting } from '../types'
import { DateTime } from '../utils'

interface Props {
  meeting: Pick<Meeting, 'end' | 'id' | 'reschedulableTimes' | 'start'>
  onSuccess: () => void
  startTime: DateTime
}

const ReschedulableTimesPicker: React.FC<Props> = ({
  meeting,
  onSuccess,
  startTime,
}) => (
  <div className="ReschedulableTimesPicker">
    {meeting.reschedulableTimes && meeting.reschedulableTimes.length > 0 && (
      <Row>
        <Col className="ReschedulableTimesPicker--date" sm={12}>
          <TimeLabel
            format="localized-full-date-with-weekday"
            time={startTime}
          />
        </Col>
      </Row>
    )}
    {meeting.reschedulableTimes && meeting.reschedulableTimes.length > 0 ? (
      <React.Fragment>
        <Row className="ReschedulableTimesPicker--time">
          {meeting.reschedulableTimes.map(t => (
            <Col key={t.toISO()} sm={4}>
              <Toggler>
                {newMeetingTimeModal => (
                  <React.Fragment>
                    <RescheduleTimesButton
                      meeting={meeting}
                      onClick={newMeetingTimeModal.setOn}
                      time={t}
                    />
                    {newMeetingTimeModal.isToggled && (
                      <NewMeetingTimeModal
                        meeting={meeting}
                        newStartTime={t}
                        onHide={newMeetingTimeModal.setOff}
                        onSuccess={() => {
                          newMeetingTimeModal.setOff()
                          onSuccess()
                        }}
                      />
                    )}
                  </React.Fragment>
                )}
              </Toggler>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Row className="ReschedulableTimesPicker--no-times">
          <Col sm={12}>
            <Icon.InternalAsset assetName="no-available-times" />
            <h4>No available times</h4>
            <p>Please choose another day</p>
          </Col>
        </Row>
      </React.Fragment>
    )}
  </div>
)

export default ReschedulableTimesPicker
