import * as React from 'react'
import CopyButton from '../../CopyButton'
import Icon from '../../Icon'
import Map from '../../Map'

export type MeetingPlaceLocationUnitProps = {
  address: string | null
}

const MeetingPlaceLocationUnit: React.FC<MeetingPlaceLocationUnitProps> = ({
  address,
}) => (
  <div className="d-flex flex-column space-between-24">
    <div className="d-flex align-items-center space-between-8">
      <Icon.MapPin size={20} strokeWidth={1} className="text-gray-500" />

      {address && (
        <>
          <a
            href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
              address
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-900"
          >
            {address}
          </a>

          <div>
            <CopyButton text={address} />
          </div>
        </>
      )}

      {!address && <em>No location provided</em>}
    </div>

    {address && <Map center={address} zoom={12} />}
  </div>
)

export default MeetingPlaceLocationUnit
