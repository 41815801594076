import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { gql } from '@apollo/client'
import { Button, Nav, Tab } from 'react-bootstrap'
import { uniqBy } from 'lodash'
import arrayMutators from 'final-form-arrays'
import SDK from '@appointlet/appointlet.js'

// We need to get the styles since the are bundle separately.
import '@appointlet/appointlet.js/dist/appointlet.min.css'

import './UpdateMeetingTypeModal.scss'
import Icon from './Icon'
import LoadingButton from './LoadingButton'
import MeetingTypeVisibilityPicker, {
  MeetingTypeVisibility,
} from './MeetingTypeVisibilityPicker'
import TabbedModal, {
  TabbedModalNavigation,
  TabbedModalContent,
  TabbedModalFooter,
  TabbedModalWrapper,
} from './TabbedModal'
import Toggler from './Toggler'
import UpdateMeetingTypeContext, {
  IMeetingType,
  ISchedule,
} from './UpdateMeetingTypeContext'
import UpdateMeetingTypeModalAvailabilitySection from './UpdateMeetingTypeModalAvailabilitySection'
import UpdateMeetingTypeModalConfirmationSection from './UpdateMeetingTypeModalConfirmationSection'
import UpdateMeetingTypeModalGeneralSection from './UpdateMeetingTypeModalGeneralSection'
import UpdateMeetingTypeModalIntakeSection from './UpdateMeetingTypeModalIntakeSection'
import UpdateMeetingTypeModalLocationSection from './UpdateMeetingTypeModalLocationSection'
import UpdateMeetingTypeModalNotificationsSection from './UpdateMeetingTypeModalNotificationsSection'
import UpdateMeetingTypeModalPaymentsCollectionSection from './UpdateMeetingTypeModalPaymentsCollectionSection'
import UpdateMeetingTypeModalSchedulingRulesSection from './UpdateMeetingTypeModalSchedulingRulesSection'
import UpdateMeetingTypeModalHostAssignmentSection from './UpdateMeetingTypeModalHostAssignmentSection'
import {
  LocationType,
  Maybe,
  HostAssignmentStrategy,
  useUpdateMeetingTypeModalQuery,
  useUpdateMeetingTypeModalUpdateMutation,
  UpdateMeetingTypeInput,
  UpdateMeetingTypeModalQuery as Response,
} from '../__generated__/graphql'
import { MeetingTypeFragment } from '../graphql/fragments'
import { ID } from '../types'
import {
  Duration,
  DateTime,
  DateTimeRange,
  toast,
  unsavedChangesAlert,
  Analytics,
  mutationErrorsToFormErrors,
} from '../utils'
import { FormApi } from 'final-form'
import Translate from './Translate'
import { useToggleIntercom } from '../hooks/useToggleIntercom'

gql`
  query UpdateMeetingTypeModal($id: ID!) {
    meetingType: getMeetingTypeById(id: $id) {
      ...MeetingType
    }
  }
  ${MeetingTypeFragment}
`

gql`
  mutation UpdateMeetingTypeModalUpdate($input: UpdateMeetingTypeInput!) {
    updateMeetingType(input: $input) {
      data {
        ...MeetingType
      }
      errors {
        field
        messages
      }
    }
  }
  ${MeetingTypeFragment}
`

export type MTESection =
  | 'general'
  | 'location'
  | 'team'
  | 'availability'
  | 'form'
  | 'confirmation'
  | 'notifications'
  | 'scheduling'
  | 'payments'

type Props = {
  id: ID
  initialSection?: MTESection
  onHide: () => void
}

const valuesToServerData = (values: IMeetingType): UpdateMeetingTypeInput => {
  // Remove non-sever properties & properties that the mutation does not
  // accept.
  const {
    __typename,
    bookingUrl,
    deleted,
    isGroup,
    meetingTypeStatus,
    phoneLocation,
    placeLocation,
    priceFormatted,
    team,
    ...rest
  } = values
  let location: Maybe<string> = null

  // Here we coerce the value of 'location' to either the 'phoneLocation'
  // or 'placeLocation' input. We do this so the inputs don't share a
  // common input value.
  if (rest.locationType === LocationType.Phone) {
    location = phoneLocation
  } else if (rest.locationType === LocationType.Place) {
    location = placeLocation
  }

  const scheduleToScheduleInput = (schedule: ISchedule) => {
    const tBlock = schedule.timeBlocks.map(timeBlock => ({
      date: timeBlock.date ? timeBlock.date.format('YYYY-MM-DD') : null,
      endTime: timeBlock.endTime
        ? timeBlock.endTime.format('twenty-four-hour-time-with-seconds')
        : null,
      frequency: timeBlock.frequency
        ? timeBlock.frequency.asSeconds().toString()
        : null,
      startTime: timeBlock.startTime.format(
        'twenty-four-hour-time-with-seconds'
      ),
      weekday: timeBlock.weekday || undefined,
    }))
    return {
      bounds: schedule.bounds
        ? {
            lower: schedule.bounds.startDateTime.format('YYYY-MM-DD'),
            upper: schedule.bounds.endDateTime.format('YYYY-MM-DD'),
          }
        : null,
      kind: schedule.kind,
      name: schedule.name,
      order: schedule.order,
      timeBlocks: tBlock,
      timezone: schedule.timezone,
    }
  }

  let meetingTypeTeamMembers = rest.meetingTypeTeamMembers.map(
    ({ __typename, id, ...meetingTypeTeamMember }) => ({
      ...meetingTypeTeamMember,
      customAvailability:
        // We need to make sure that if the user toggles the hostAssignmentStrategy
        // away from Pooled that we toggle customAvailability to false so their
        // availability and schedules don't get f'd up.
        rest.hostAssignmentStrategy === HostAssignmentStrategy.Pooled
          ? meetingTypeTeamMember.customAvailability
          : false,
      schedules: meetingTypeTeamMember.schedules.map(schedule =>
        scheduleToScheduleInput(schedule)
      ),
      teamMember: meetingTypeTeamMember.teamMember.id,
    })
  )

  return {
    ...rest,
    active: meetingTypeStatus !== 'disabled',
    bufferAfter: rest.bufferAfter.isValid()
      ? rest.bufferAfter.asSeconds().toString()
      : new Duration().asSeconds().toString(),
    bufferBefore: rest.bufferBefore.isValid()
      ? rest.bufferBefore.asSeconds().toString()
      : new Duration().asSeconds().toString(),
    cancellationNotice: rest.cancellationNotice.asSeconds().toString(),
    conferencingProvider:
      rest.locationType === LocationType.WebConference
        ? rest.conferencingProvider?.id
        : null,
    description: rest.description || '',
    duration: rest.duration.isValid()
      ? rest.duration.asSeconds().toString()
      : new Duration({ minutes: 30 }).asSeconds().toString(),
    fields: rest.fields.map(f => ({
      choices: f.choices,
      fieldType: f.fieldType,
      helpText: f.helpText,
      name: f.name,
      order: f.order,
      required: f.required,
      visible: f.visible,
      slug: f.slug,
    })),
    frequency: rest.frequency ? rest.frequency.asSeconds().toString() : null,
    location,
    maximumNotice: rest.maximumNotice.isValid()
      ? rest.maximumNotice.asSeconds().toString()
      : new Duration({ days: 90 }).asSeconds().toString(),
    meetingTypeTeamMembers,
    minimumNotice: rest.minimumNotice.isValid()
      ? rest.minimumNotice.asSeconds().toString()
      : new Duration({ hour: 1 }).asSeconds().toString(),
    paymentCollectionProvider: rest.paymentCollectionProvider
      ? rest.paymentCollectionProvider?.id
      : null,
    price: !rest.paymentCollectionProvider ? 0 : rest.price,
    public: meetingTypeStatus === 'public',
    redirectUrl: rest.redirectUrl ? rest.redirectUrl : null,
    includeDataInRedirectUrl: rest.includeDataInRedirectUrl,
    reminders: uniqBy(rest.reminders, reminder =>
      reminder.leadTime.asSeconds()
    ).map(reminder => {
      const { __typename, ...rest } = reminder
      return {
        ...rest,
        leadTime: rest.leadTime.isValid()
          ? rest.leadTime.asSeconds().toString()
          : new Duration().asSeconds().toString(),
      }
    }),
    rescheduleNotice: rest.rescheduleNotice.asSeconds().toString(),
    schedules: rest.schedules.map(schedule =>
      scheduleToScheduleInput(schedule)
    ),
    usesLocalTimezone: !!rest.timezone,
  }
}
const wireDataToInternalData = (
  wireData: Response['meetingType']
): IMeetingType => {
  return {
    ...wireData,
    bufferAfter: new Duration(wireData.bufferAfter),
    bufferBefore: new Duration(wireData.bufferBefore),
    cancellationNotice: new Duration(wireData.cancellationNotice),
    conferencingProvider: wireData.conferencingProvider,
    deleted: wireData.deleted ? new DateTime(wireData.deleted) : null,
    duration: new Duration(wireData.duration),
    // TYPESCRIPT: fields.choices is not typed correctly.
    // @ts-ignore
    fields: wireData.fields.edges!.map(edge => ({
      ...edge!.node!,
      choices: edge!.node!.choices!,
    })),
    frequency: wireData.frequency ? new Duration(wireData.frequency) : null,
    locationType: wireData.locationType,
    maximumNotice: new Duration(wireData.maximumNotice),
    meetingTypeStatus:
      wireData.active && wireData.public
        ? 'public'
        : wireData.active && !wireData.public
        ? 'hidden'
        : 'disabled',
    meetingTypeTeamMembers: wireData.meetingTypeTeamMembers.edges!.map(
      edge => ({
        ...edge!.node!,
        schedules: edge!.node!.schedules.edges!.map(edge => ({
          ...edge!.node!,

          bounds: edge!.node!.bounds
            ? new DateTimeRange(
                edge!.node!.bounds.lower,
                edge!.node!.bounds.upper
              )
            : null,
          timeBlocks: edge!.node!.timeBlocks.edges!.map(edge => ({
            ...edge!.node!,
            date: edge!.node!.date ? new DateTime(edge!.node!.date) : null,
            endTime: edge!.node!.endTime
              ? new DateTime(edge!.node!.endTime)
              : null,
            frequency: edge!.node!.frequency
              ? new Duration(edge!.node!.frequency)
              : null,
            startTime: new DateTime(edge!.node!.startTime),
          })),
        })),
        teamMember: {
          ...edge!.node!.teamMember,
          member: {
            ...edge!.node!.teamMember.member,
            calendarAccounts:
              edge!.node!.teamMember.member.calendarAccounts.edges.map(
                edge => ({ ...edge!.node! })
              ),
            conferencingAccounts:
              edge!.node!.teamMember.member.conferencingAccounts.edges.map(
                edge => ({ ...edge!.node! })
              ),
            deleted: edge!.node!.teamMember.member.deleted
              ? new DateTime(edge!.node!.teamMember.member.deleted)
              : null,
          },
        },
      })
    ),
    minimumNotice: new Duration(wireData.minimumNotice),
    phoneLocation:
      wireData.locationType === LocationType.Phone ? wireData.location : null,
    placeLocation:
      wireData.locationType === LocationType.Place ? wireData.location : null,
    reminders: wireData.reminders.edges!.map(edge => ({
      ...edge!.node!,
      leadTime: new Duration(edge!.node!.leadTime),
    })),
    rescheduleNotice: new Duration(wireData.rescheduleNotice),
    schedules: wireData.schedules.edges!.map(edge => ({
      ...edge!.node!,
      bounds: edge!.node!.bounds
        ? new DateTimeRange(edge!.node!.bounds.lower, edge!.node!.bounds.upper)
        : null,
      timeBlocks: edge!.node!.timeBlocks.edges!.map(edge => ({
        ...edge!.node!,
        date: edge!.node!.date ? new DateTime(edge!.node!.date) : null,
        endTime: edge!.node!.endTime ? new DateTime(edge!.node!.endTime) : null,
        frequency: edge!.node!.frequency
          ? new Duration(edge!.node!.frequency)
          : null,
        startTime: new DateTime(edge!.node!.startTime),
      })),
    })),
  }
}

const UpdateMeetingTypeModal: React.FC<Props> = ({
  id,
  initialSection,
  onHide,
}) => {
  useToggleIntercom()
  const { data, loading: queryLoading } = useUpdateMeetingTypeModalQuery({
    variables: { id },
  })

  const meetingType: Maybe<IMeetingType> = React.useMemo(
    () => (data ? wireDataToInternalData(data.meetingType) : null),
    [data]
  )

  const [updateMeetingType, { loading: mutationLoading }] =
    useUpdateMeetingTypeModalUpdateMutation()

  const onSubmit = async (
    values: IMeetingType,
    form: FormApi<IMeetingType>
  ) => {
    try {
      const res = await updateMeetingType({
        variables: { input: valuesToServerData(values) },
      })
      // If client validation and GraphQL error related errors exist
      if (res.data?.updateMeetingType?.errors) {
        console.error(
          'updateMeetingType mutation [UpdateMeetingTypeModal.tsx]',
          res.data.updateMeetingType.errors
        )

        // We return mutationErrorsToForm errors in order to render server errors on the form
        return mutationErrorsToFormErrors(res.data?.updateMeetingType?.errors)
      }

      // No data for some reason
      if (!res.data?.updateMeetingType?.data?.id) {
        throw new Error('No data returned')
      }

      // Get the fields that changed
      const dirtyFields = form.getState().dirtyFields

      // If one of them is availability, then trigger an event.
      Analytics.trackEvent('Updated Meeting Type', {
        meetingType: res.data.updateMeetingType.data.id,
        availabilityChanged: !!dirtyFields.schedules,
      })
      // re-initialize form with updated meeting type so final-form considers it "saved"
      form.initialize(wireDataToInternalData(res.data?.updateMeetingType?.data))
      // Here we are catching sever related errors.
    } catch (err) {
      toast.error('Something went wrong')
      console.error(
        'updateMeetingType mutation [UpdateMeetingTypeModal.tsx]',
        err
      )
      return
    }
  }
  const [activeTab, setActiveTab] = React.useState<MTESection>('general')
  const [currentErrors, setCurrentErrors] = React.useState({})

  const toastFormErrors = (): void => {
    toast.error(`Please correct errors in your submission`)
  }

  const redirectMap: Record<string, string> = {
    name: 'general',
    slug: 'general',
    phoneLocation: 'location',
    placeLocation: 'location',
    hostAssignmentStrategy: 'team',
    conferencingProvider: 'team',
    schedules: 'availability',
    redirectUrl: 'confirmation',
    maxAttendees: 'scheduling',
    duration: 'general',
    maximumNotice: 'scheduling',
    minimumNotice: 'scheduling',
  }

  const onNavigateToNewTab = React.useCallback(
    (section: MTESection) => {
      if (activeTab === section) return
      setActiveTab(section)
      Analytics.trackEvent(
        `Update Meeting Type Modal: Loaded Section - ${section}`,
        {
          meetingType: meetingType?.id,
          tab: section,
        }
      )
    },
    [activeTab, meetingType?.id]
  )

  const handleRedirect = (activeTab: string): void => {
    setActiveTab(redirectMap[activeTab] as MTESection)
  }

  React.useEffect(() => {
    Analytics.trackEvent('Update Meeting Type Modal: Opened')
  }, [])

  return !queryLoading && meetingType ? (
    <FinalForm.Form<IMeetingType>
      initialValues={meetingType}
      initialValuesEqual={() => true}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
    >
      {({ dirty, handleSubmit, values }) => (
        <UpdateMeetingTypeContext.Provider value={meetingType}>
          <TabbedModal
            className="UpdateMeetingTypeModal"
            // We override the default so that Overlays & Popovers
            // are focusable.\
            // https://github.com/react-bootstrap/react-bootstrap/issues/1551#issuecomment-162875520
            enforceFocus={false}
            initialTab={initialSection || 'general'}
            activeKey={activeTab}
            onHide={() => unsavedChangesAlert(dirty, onHide)}
          >
            <FinalForm.FormSpy
              subscription={{ valid: true, errors: true }}
              onChange={props => {
                if (props.errors) {
                  setTimeout(() => setCurrentErrors(props.errors as any), 0)
                }
              }}
            />
            <TabbedModalWrapper>
              <TabbedModalNavigation>
                <Nav.Item>
                  <Nav.Link
                    eventKey="general"
                    onSelect={() => onNavigateToNewTab('general')}
                  >
                    <Icon.InternalAsset assetName="icn-mte-general" size={20} />
                    <Translate>General</Translate>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="location"
                    onSelect={() => onNavigateToNewTab('location')}
                  >
                    <Icon.InternalAsset
                      assetName="icn-mte-location"
                      size={20}
                    />
                    <Translate>Location</Translate>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="team"
                    onSelect={() => onNavigateToNewTab('team')}
                  >
                    <Icon.InternalAsset assetName="icn-mte-team" size={20} />
                    <Translate>Host Assignment</Translate>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="availability"
                    onSelect={() => onNavigateToNewTab('availability')}
                  >
                    <Icon.InternalAsset
                      assetName="icn-mte-availability"
                      size={20}
                    />
                    <Translate>Availability</Translate>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="form"
                    onSelect={() => onNavigateToNewTab('form')}
                  >
                    <Icon.InternalAsset assetName="icn-mte-intake" size={20} />
                    <Translate>Intake Form</Translate>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="confirmation"
                    onSelect={() => onNavigateToNewTab('confirmation')}
                  >
                    <Icon.InternalAsset assetName="ConfirmationNav" size={20} />
                    <Translate>Confirmation</Translate>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="notifications"
                    onSelect={() => onNavigateToNewTab('notifications')}
                  >
                    <Icon.InternalAsset
                      assetName="icn-mte-reminders"
                      size={20}
                    />
                    <Translate>Notifications</Translate>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="scheduling"
                    onSelect={() => onNavigateToNewTab('scheduling')}
                  >
                    <Icon.InternalAsset
                      assetName="SchedulingRulesNav"
                      size={20}
                    />
                    <Translate>Scheduling Rules</Translate>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="payments"
                    onSelect={() => onNavigateToNewTab('payments')}
                  >
                    <Icon.InternalAsset
                      assetName="icn-mte-payments"
                      size={20}
                    />
                    <Translate>Payments</Translate>
                  </Nav.Link>
                </Nav.Item>
              </TabbedModalNavigation>
              <TabbedModalContent className="tw-w-full tw-overflow-x-auto">
                <Tab.Pane eventKey="general">
                  <UpdateMeetingTypeModalGeneralSection
                    onSelect={onNavigateToNewTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="location">
                  <UpdateMeetingTypeModalLocationSection
                    setActiveTab={setActiveTab}
                    currentMeetingTypeMembers={values.meetingTypeTeamMembers}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="team">
                  <UpdateMeetingTypeModalHostAssignmentSection
                    onSelect={onNavigateToNewTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="availability">
                  <UpdateMeetingTypeModalAvailabilitySection
                    onSelect={onNavigateToNewTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="form">
                  <UpdateMeetingTypeModalIntakeSection
                    onSelect={onNavigateToNewTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="confirmation">
                  <UpdateMeetingTypeModalConfirmationSection
                    onSelect={onNavigateToNewTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="notifications">
                  <UpdateMeetingTypeModalNotificationsSection
                    onSelect={onNavigateToNewTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="scheduling">
                  <UpdateMeetingTypeModalSchedulingRulesSection
                    onSelect={onNavigateToNewTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="payments">
                  <UpdateMeetingTypeModalPaymentsCollectionSection
                    onSelect={onNavigateToNewTab}
                  />
                </Tab.Pane>
              </TabbedModalContent>
            </TabbedModalWrapper>
            <TabbedModalFooter className="tw-border-b-0 tw-border-t tw-border-x-0 tw-border-solid tw-border-t-[#8a9199]/[0.4] tw-p-4 tw-justify-between tw-z-50 lg:tw-z-auto tw-w-screen sm:tw-w-full">
              <Toggler>
                {({
                  isToggled: isPreviewing,
                  setOff: save,
                  setOn: preview,
                }) => (
                  <div className="tw-flex tw-m-0 tw-w-full">
                    <FinalForm.Field<MeetingTypeVisibility> name="meetingTypeStatus">
                      {({ input: { onChange, value: status } }) => (
                        <MeetingTypeVisibilityPicker
                          onChange={status => onChange(status)}
                          value={status}
                        />
                      )}
                    </FinalForm.Field>
                    <div className="tw-flex tw-justify-between tw-space-x-4 tw-w-full md:tw-justify-end md:tw-space-x-2">
                      <Button
                        className="tw-flex-1 md:tw-hidden"
                        onClick={onHide}
                        variant="outline-secondary"
                      >
                        <Translate>Cancel</Translate>
                      </Button>
                      <LoadingButton
                        className="tw-hidden md:tw-block"
                        loading={mutationLoading && isPreviewing}
                        onClick={async () => {
                          const errorKeys = Object.keys(currentErrors)
                          if (errorKeys.length) {
                            handleRedirect(errorKeys[0])
                            toastFormErrors()
                            return
                          }

                          // here we construct meeting type booking url using current slug value. This will ensure slug updates will be captured when invoking new SDK
                          const previewBookingUrl = `${meetingType.team.bookingUrl}/${values.slug}`

                          // Configure the SDK
                          const sdk = new SDK(previewBookingUrl)
                          // Trigger loading UI.
                          preview()

                          // Trigger the mutation
                          await handleSubmit()?.then(errors => {
                            if (!errors) {
                              onHide()
                            } else {
                              const errorKeys = Object.keys(errors)
                              handleRedirect(errorKeys[0])
                            }
                          })

                          // Open the scheduling page
                          sdk.openModal().then(
                            () => {},
                            () => {}
                          )
                        }}
                        variant="outline-secondary"
                      >
                        <Translate>Save & Preview</Translate>
                      </LoadingButton>
                      <LoadingButton
                        className="tw-flex-1 md:tw-flex-grow-0"
                        loading={mutationLoading && !isPreviewing}
                        // Since we are not using this with in a <Form/> where we would
                        // normally put submitFrom on the onSubmit prop we must
                        // execute this as an async function for the 'mutationLoading' prop
                        // to execute correctly.
                        onClick={async () => {
                          // Trigger loading UI.
                          const errorKeys = Object.keys(currentErrors)
                          if (errorKeys.length) {
                            handleRedirect(errorKeys[0])
                            toastFormErrors()
                            return
                          }
                          save()
                          await handleSubmit()?.then(errors => {
                            if (!errors) {
                              onHide()
                            } else {
                              const errorKeys = Object.keys(errors)
                              handleRedirect(errorKeys[0])
                            }
                          })
                        }}
                      >
                        <Translate>Save Changes</Translate>
                      </LoadingButton>
                    </div>
                  </div>
                )}
              </Toggler>
            </TabbedModalFooter>
          </TabbedModal>
        </UpdateMeetingTypeContext.Provider>
      )}
    </FinalForm.Form>
  ) : null
}

export default UpdateMeetingTypeModal
