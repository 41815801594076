import {
  ApolloClient,
  from,
  InMemoryCache,
  HttpLink,
  ServerError,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'

import { getAccessToken, redirectToLoginPage } from './auth'
import { env } from './env'

// We need this to inform Apollo of how to validate ConferencingAccountNodes.
// When we add a new conferencing provider we'll need to update this list.
// More Info:
// https://www.apollographql.com/docs/react/data/fragments/#fragments-on-unions-and-interfaces
// generated by Fragment Matcher plugin
import introspectionResult from '../__generated__/introspection-result'

const links = from([
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) =>
        console.error(`[GraphQL error]: Message: ${message}`)
      )
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`)
    }
    // If we got a bad auth error, redirect user to login page
    if (networkError && (networkError as ServerError).statusCode === 401) {
      redirectToLoginPage()
    }

    return forward(operation)
  }),
  new HttpLink({
    uri: env('GRAPHQL_ENDPOINT'),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },

    // We add a custom fetcher here because LogRocket replaces the default
    // window.fetch with a custom version that records the requests, and we want Apollo
    // to use that one.
    fetch: (
      input: RequestInfo,
      init: RequestInit | undefined
    ): Promise<Response> => window.fetch(input, init),
  }),
])

// Returns an ApolloClient configured with our access token
export const getApolloClient = (): ApolloClient<unknown> => {
  const client = new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: introspectionResult.possibleTypes,
      typePolicies: {
        ProfileNode: {
          fields: {
            // NOTE: Why?
            // We invoke this call to Apollo's Cache due to our infinite scroll code
            // on the MeetingsPage. When Apollo encounters `meetings` on the `ProfileNode`
            // we are telling it to process the request using cursor-based pagination.
            // You can see the call to `fetchMore` in the
            // <InfiniteScroll loadMore={() => fetchMore({...})} /> where we use our cursor.
            // The below helper function excepts keyArguments, and the purpose of this is that
            // we want any query that has different values for the below to be distinct entries
            // in the cache. This prevents merging issues that cause wonky UI and incorrect data
            // sets when using the meeting filters.
            // Hopefully a better explanation in the Loom:
            // https://www.loom.com/share/044db659362d4d27bed38c4f8e2241ae
            // Documentation Link:
            // https://www.apollographql.com/docs/react/pagination/cursor-based
            // https://www.apollographql.com/docs/react/pagination/key-args
            meetings: relayStylePagination([
              'startOnOrAfter',
              'startOnOrBefore',
            ]),
          },
        },
      },
    }),
    defaultOptions: {
      query: {
        // TODO: Figure out what are global policy should be.
        // defaults to: 'cache-first'
        // https://github.com/apollographql/apollo-client/issues/3900
        // https://github.com/apollographql/apollo-client/blob/master/src/core/watchQueryOptions.ts#L8
        // https://github.com/apollographql/apollo-client/issues/3130#issuecomment-478409066
        // fetchPolicy: ''
      },
    },
    link: links,
  })

  return client
}
