import * as React from 'react'

import './RemoveButton.scss'
import Icon from './Icon'
import { expandClassName } from '../utils'

interface Props {}

const RemoveButton: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = ({
  className,
  ...rest
}) => (
  <button
    className={expandClassName(
      'RemoveButton align-items-center d-flex justify-content-center',
      className
    )}
    // @ts-ignore
    type="button"
    {...rest}
  >
    <Icon.X size={18} />
  </button>
)

export default RemoveButton
