import { decodeQueryParams } from './queryParams'
import { openWindow } from './oauthTools'
import { env } from './env'

const stripePublishableKey = env('STRIPE_PUBLISHABLE_KEY')

export enum StripeErrorType {
  UserCancelled = 'user_cancelled',
  NetworkFailure = 'network_failure',
}

export const startStripeFlow = (sessionId: string) => {
  return new Promise<{ error?: StripeErrorType }>((resolve, reject) => {
    // Open a window pointed to our Stripe Checkout stub page (see public/stripe.html).
    // It will create a Stripe Checkout session and send the user on their way.
    const win = openWindow(
      `/stripe.html#${stripePublishableKey}&${sessionId}`,
      'stripeCheckout',
      { width: 400, height: 800 }
    )

    // Message handler designed to listen for messages sent from our success page.  We
    // reuse our oauth2 page here because it does everything we need.
    const messageHandler = (ev: MessageEvent) => {
      // Ignore message that are not from us.
      if (ev.origin !== 'https://oauth2.appointlet.com') {
        return
      }

      const queryString = decodeQueryParams<{ error: StripeErrorType }>(ev.data)

      if (queryString.error) {
        reject(queryString.error)
        return
      }

      resolve({})

      // cleanup
      removeMessageHandler()
    }

    const removeMessageHandler = () => {
      window.removeEventListener('message', messageHandler)
    }

    // If window closes, cleanup the listener and reject the promise.
    win.then(() => {
      removeMessageHandler()
      reject(StripeErrorType.UserCancelled)
    })

    // Start listening for messages.
    window.addEventListener('message', messageHandler, false)
  })
}
