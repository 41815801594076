import { InternalUser } from '../components/UserContext'
import LogRocket from 'logrocket'
import { env } from './env'
import { AnalyticsEventAttributes } from './analytics'

const LOG_ROCKET_APP_ID = env('LOG_ROCKET_APP_ID')

const LogRocketHandler = {
  initialize: (user: InternalUser) => {
    console.log('LogRocketHandler.initialize', user)

    if (!LOG_ROCKET_APP_ID) {
      return Promise.resolve(false)
    }

    LogRocket.init(LOG_ROCKET_APP_ID)
    LogRocket.identify(user.id, {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
    })

    return Promise.resolve(true)
  },

  trackEvent: (
    name: string,
    attributes?: AnalyticsEventAttributes
  ): Promise<boolean> => {
    console.log('LogRocketHandler.trackEvent', name, attributes)

    if (!LOG_ROCKET_APP_ID) {
      return Promise.resolve(false)
    }

    LogRocket.track(name, attributes)

    return Promise.resolve(true)
  },

  pageChanged: () => {
    // Nothing to do here...
    return Promise.resolve(true)
  },
}

export default LogRocketHandler
