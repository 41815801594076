import React from 'react'
import { DropdownButton, Media } from 'react-bootstrap'

import './MeetingTypeEditorSectionPicker.scss'
import DropdownItem from './DropdownItem'
import Icon from './Icon'
import { MTESection } from './UpdateMeetingTypeModal'

type MTEPickerItem = {
  assetName: string
  description: string
  sectionName: string
  value: MTESection
}

const options: Array<MTEPickerItem> = [
  {
    assetName: 'icn-mte-header-general',
    description: 'Set the basic details of your meeting',
    sectionName: 'General',
    value: 'general',
  },
  {
    assetName: 'icn-mte-header-location',
    description: "Set the details of where you'll meet",
    sectionName: 'Location',
    value: 'location',
  },
  {
    assetName: 'icn-mte-header-team',
    description: 'Determine who will be the host of new meetings',
    sectionName: 'Host Assignment',
    value: 'team',
  },
  {
    assetName: 'icn-mte-header-availability',
    description: 'Set up rules on when you can be booked',
    sectionName: 'Availability',
    value: 'availability',
  },
  {
    assetName: 'icn-mte-header-intake',
    description: 'Collect the information you need for your meeting',
    sectionName: 'Intake Form',
    value: 'form',
  },
  {
    assetName: 'icn-mte-header-confirmation',
    description: 'Customize the messaging you send to attendees',
    sectionName: 'Confirmation',
    value: 'confirmation',
  },
  {
    assetName: 'icn-mte-header-reminder',
    description: 'Emails sent out after a meeting is booked',
    sectionName: 'Notifications & Reminders',
    value: 'notifications',
  },
  {
    assetName: 'SchedulingRulesHeader',
    description: 'Set up limits for your availability',
    sectionName: 'Scheduling Rules',
    value: 'scheduling',
  },
  {
    assetName: 'icn-mte-header-payments',
    description: 'Collect payments from users who schedule this meeting',
    sectionName: 'Collect Payments',
    value: 'payments',
  },
]

export interface Props {
  onChange: (value: MTESection) => void
  value: MTESection
}

const MeetingTypeEditorSectionPicker: React.FC<Props> = ({
  onChange,
  value,
}) => {
  const selectedOption = React.useMemo(
    () => options.find(option => option.value === value)!,
    [value]
  )
  return (
    <DropdownButton
      className="MeetingTypeEditorSectionPicker tw-w-full lg:tw-hidden"
      id="MeetingTypeEditorSectionPicker"
      title={
        <Media className="tw-items-center tw-space-x-4 tw-w-full">
          <Icon.InternalAsset
            assetName={selectedOption.assetName}
            className="tw-mr-0 tw-h-[42px] tw-w-[42px]"
          />
          <Media.Body className="tw-flex tw-flex-col tw-items-start tw-space-y-0.5">
            <span className="tw-text-bsGray-900 tw-font-medium tw-text-sm">
              {selectedOption.sectionName}
            </span>
            <span className="tw-text-bsGray-600 tw-text-xs tw-text-left tw-font-normal tw-whitespace-normal">
              {selectedOption.description}
            </span>
          </Media.Body>
          <Icon.ChevronDown className="tw-text-bsGray-400 tw-h-5" />
        </Media>
      }
      variant="outline-secondary"
    >
      {options.map(({ assetName, description, sectionName, value }) => (
        <DropdownItem
          as={Media}
          className="tw-group tw-items-center tw-space-x-4"
          eventKey={value}
          key={value}
          onClick={() => onChange(value)}
        >
          <Icon.InternalAsset
            assetName={assetName}
            className="tw-mr-0 tw-h-[42px] tw-w-[42px]"
          />
          <Media.Body className="tw-flex tw-flex-col tw-items-start tw-space-y-0.5">
            <span className="tw-text-bsGray-900 tw-font-medium tw-text-sm group-hover:tw-text-white">
              {sectionName}
            </span>
            <span className="tw-text-bsGray-600 tw-text-xs tw-text-left tw-font-normal tw-whitespace-normal group-hover:tw-text-white">
              {description}
            </span>
          </Media.Body>
        </DropdownItem>
      ))}
    </DropdownButton>
  )
}

export default MeetingTypeEditorSectionPicker
