import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { navigate, RouteComponentProps } from '@reach/router'
import { ApolloQueryResult, gql } from '@apollo/client'
import {
  Maybe,
  Timezone,
  useTimeZonePickerQuery,
  useOnboardingCreateSchedulingPageSlugUpdateMutation,
  useOnboardingCreateSchedulingPageMemberQueryQuery,
  useOnboardingCreateSchedulingPageUpdateUserMutation,
  useTeamSlugAvailabilityQuery,
  TeamSlugAvailabilityQuery,
} from '../../../__generated__/graphql'
import UserContext from '../../UserContext'
import MemberContext from '../../MemberContext'
import { CreateSchedulingPageScreen } from '../screens/CreateSchedulingPageScreen/CreateSchedulingPageScreen'
import {
  Analytics,
  DateTime,
  isSlug,
  mutationErrorsToFormErrors,
  toast,
} from '../../../utils'
import slugify from '@sindresorhus/slugify'
import TapfiliateHandler from '../../../utils/tapfiliate'

gql`
  query teamSlugAvailability($slug: String!) {
    data: teamSlugAvailability(slug: $slug) {
      available
      alternative
    }
  }
`
gql`
  query TimeZonePicker {
    timeZones: __type(name: "Timezone") {
      enumValues {
        name
        description
      }
    }
  }
`

gql`
  query OnboardingCreateSchedulingPageMemberQuery($id: ID!) {
    member: getMemberById(id: $id) {
      id
      teams {
        edges {
          node {
            id
            name
            slug
            bookingUrl
          }
        }
      }
      profile {
        featureFlags
        id
        name
      }
    }
  }
`

gql`
  mutation OnboardingCreateSchedulingPageSlugUpdate($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      data {
        bookingUrl
        id
        name
        slug
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation OnboardingCreateSchedulingPageUpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      data {
        firstName
        id
        lastName
        timezone
      }
      errors {
        field
        messages
      }
    }
  }
`

interface FormValues {
  readonly slug: string
  readonly timezone: Maybe<Timezone>
}

const OnboardingCreateSchedulingPage: React.FC<RouteComponentProps> = () => {
  const { user } = React.useContext(UserContext)
  const member = React.useContext(MemberContext)
  const { data: zoneData, loading: zoneLoading } = useTimeZonePickerQuery()

  const { data: memberQueryData, loading: memberQueryLoading } =
    useOnboardingCreateSchedulingPageMemberQueryQuery({
      variables: { id: member.id },
    })

  const { refetch: checkSlugAvailability } = useTeamSlugAvailabilityQuery({
    fetchPolicy: 'network-only',
    skip: true,
  })

  let _clearTimeout: void | (() => void)

  const checkSlugAvailabilityDebounced = (value: string) => {
    return new Promise<ApolloQueryResult<TeamSlugAvailabilityQuery> | void>(
      resolve => {
        if (_clearTimeout) {
          _clearTimeout()
        }
        const timerId = setTimeout(() => {
          resolve(checkSlugAvailability({ slug: value }))
        }, 500)
        _clearTimeout = () => {
          clearTimeout(timerId)
          resolve()
        }
      }
    )
  }

  const [updateTeam, { loading: mutationLoading }] =
    useOnboardingCreateSchedulingPageSlugUpdateMutation()

  const [updateUser] = useOnboardingCreateSchedulingPageUpdateUserMutation()

  const initialValues = React.useMemo<FormValues>(
    () => ({
      slug: !memberQueryLoading
        ? memberQueryData!.member?.teams.edges[0]!.node!.slug
        : '',
      timezone: !!user.timezone
        ? user.timezone
        : new DateTime().getTimeZoneEnum(),
    }),
    [user, memberQueryData, memberQueryLoading]
  )

  const validateSlug = async (slugValue: string) => {
    if (!slugValue || slugValue.length === 0) {
      return 'Scheduling page URL is required'
    }
    if (isSlug(slugValue) !== undefined) {
      return isSlug(slugValue)
    }
    if (slugValue === initialValues.slug) {
      return
    }
    const result = await checkSlugAvailabilityDebounced(slugValue)
    if (!result) {
      return
    }
    return result?.data.data.available
      ? undefined
      : 'That URL is already taken, please choose another.'
  }

  const onSubmit = async (values: FormValues) => {
    // check for slug value
    if (values.slug.length === 0) {
      return
    }
    if (values.timezone === null) {
      return
    }
    if (values.timezone === null) {
      return
    }
    try {
      // first update user timezone
      await updateUser({
        variables: {
          input: {
            id: user.id,
            email: user.email,
            timezone: values.timezone as Timezone,
          },
        },
      })

      // then update team
      const res = await updateTeam({
        variables: {
          input: {
            id: memberQueryData!.member.teams.edges[0]!.node!.id,
            name: memberQueryData!.member.teams.edges[0]!.node!.name,
            slug: slugify(values.slug),
          },
        },
      })

      if (res.data?.updateTeam?.errors) {
        const errors = mutationErrorsToFormErrors(res.data.updateTeam.errors)

        return errors
      }
      // Analytics
      Analytics.trackEvent('Onboarding Scheduling Page: Submitted')
    } catch (error) {
      toast.error('Something went wrong')
      return
    }

    // Send the user to the next screen
    await navigate(`/profiles/${user.profiles[0].id}/onboarding/meeting-type`)
  }

  React.useEffect(() => {
    // We tell Tapfiliate that this person is entering a trial phase of the
    // product and send it the customers Stripe ID for tracking them.
    TapfiliateHandler.startTrial(user.profiles[0].billingCustomerId!)

    // Analytics
    Analytics.trackEventOnce('Signed Up')
    Analytics.trackEvent('Onboarding Scheduling Page: Loaded')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <FinalForm.Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={async values => {
          if (initialValues.slug === '' && initialValues.timezone !== null) {
            return {}
          }

          const slugErrors = await validateSlug(values.slug)
          return {
            slug: slugErrors,
            timezone:
              values.timezone === null
                ? 'Please select your timezone'
                : undefined,
          }
        }}
      >
        {({ handleSubmit }) => (
          <CreateSchedulingPageScreen
            onSubmitCallback={handleSubmit}
            email={user.email}
            isLoading={mutationLoading}
            timezoneOptionEnums={zoneData?.timeZones?.enumValues || []}
            isLoadingTimezones={zoneLoading}
          />
        )}
      </FinalForm.Form>
    </React.Fragment>
  )
}

export default OnboardingCreateSchedulingPage
