import * as React from 'react'
import { Timezone } from '../__generated__/graphql'

const MeetingViewingTimezoneContext = React.createContext<Timezone | undefined>(
  undefined
)

MeetingViewingTimezoneContext.displayName = 'MeetingViewingTimezoneContext'

export default MeetingViewingTimezoneContext
