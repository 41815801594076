import * as React from 'react'
import { gql } from '@apollo/client'
import { debounce } from 'lodash'
import { Button, Modal, Tabs, Tab } from 'react-bootstrap'
import InviteMembersModal from './InviteMembersModal'
import Icon from './Icon'
import MemberSettingsListItem from './MemberSettingsListItem'
import RemoveMemberModal from './RemoveMemberModal'
import TransferOwnershipModal from './TransferOwnershipModal'
import ProfileContext from './ProfileContext'
import MemberContext from './MemberContext'
import SearchInput from './SearchInput'
import Toggler from './Toggler'
import {
  Maybe,
  MemberNode,
  PageInfo,
  ProfileNodeMembersArgs,
  RoleNode,
  UserNode,
  SettingsModalUsersSectionQuery as Response,
  useSettingsModalUsersSectionQuery,
  useUpdateMemberRoleMutation,
  useResendMemberInviteMutation,
} from '../__generated__/graphql'
import { DateTime, toast } from '../utils'
import Translate from './Translate'
import SettingsSectionPicker from './SettingsSectionPicker'
import { SettingsSection } from './SettingsModal'
import './SettingsModalUsersSection.scss'

gql`
  query SettingsModalUsersSection(
    $after: String
    $before: String
    $first: Int
    $id: ID!
    $isDeleted: Boolean
    $last: Int
    $search: String
  ) {
    profile: getProfileById(id: $id) {
      id
      roles {
        edges {
          node {
            default
            id
            name
          }
        }
      }
      members(
        after: $after
        before: $before
        first: $first
        isDeleted: $isDeleted
        last: $last
        search: $search
      ) {
        edges {
          node {
            deleted
            id
            invitationAccepted
            isOwner
            isSchedulable
            role {
              id
              name
            }
            user {
              email
              firstName
              id
              image
              lastName
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`

gql`
  mutation UpdateMemberRole($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      data {
        id
        role {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation ResendMemberInvite($input: ResendMemberInvitationInput!) {
    resendMemberInvitation(input: $input) {
      data {
        id
      }
    }
  }
`

type Props = {
  onSelect: (section: SettingsSection) => void
}

type InternalData = {
  members: Array<
    Pick<
      MemberNode,
      '__typename' | 'id' | 'invitationAccepted' | 'isOwner' | 'isSchedulable'
    > & {
      deleted: Maybe<DateTime>
      role: Pick<RoleNode, '__typename' | 'id' | 'name'>
      user: Pick<
        UserNode,
        '__typename' | 'email' | 'firstName' | 'id' | 'image' | 'lastName'
      >
    }
  >
  roles: Array<Pick<RoleNode, '__typename' | 'id' | 'name'>>
  pageInfo: PageInfo
}

const wireDataToInternalData = (wireData: Response): InternalData => ({
  members: wireData.profile.members.edges.map(edge => ({
    ...edge?.node!,
    deleted: edge?.node?.deleted ? new DateTime(edge.node.deleted) : null,
    role: edge?.node?.role!,
    user: edge?.node?.user!,
  })),
  roles: wireData.profile.roles.edges.map(edge => edge!.node!),
  pageInfo: wireData.profile.members.pageInfo,
})

const SettingsModalUsersSection: React.FC<Props> = ({ onSelect }) => {
  const profile = React.useContext(ProfileContext)
  const currentMember = React.useContext(MemberContext)

  const [filters, setFilters] = React.useState<
    ProfileNodeMembersArgs & { id: string }
  >({
    id: profile.id,
    isDeleted: null,
    search: '',
  })

  const [updateMemberRole] = useUpdateMemberRoleMutation()
  const [resendInvite] = useResendMemberInviteMutation()

  const onRoleChange = async (
    member: Pick<MemberNode, 'id'>,
    role: Pick<RoleNode, 'id' | 'name'>
  ) => {
    try {
      const { data, errors } = await updateMemberRole({
        optimisticResponse: {
          updateMember: {
            __typename: 'UpdateMemberPayload',
            data: {
              __typename: 'MemberNode',
              id: member.id,
              role,
            },
            errors: null,
          },
        },
        variables: { input: { id: member.id, role: role.id } },
      }) // If client validation and GraphQL error related errors exist
      if (errors) {
        console.error('updateMember (Role) mutation', errors)
        return
      }
      // If data is presend trigger the toast to display and close the modal
      if (data) {
        toast.success(`Role Updated`)
        return
      }
      // Here we are catching sever related errors.
    } catch (err) {
      toast.error('Something went wrong')
      console.error('updateMember (Role) mutation', err)
    }
  }

  const { data, loading, refetch } = useSettingsModalUsersSectionQuery({
    variables: filters,
  })

  const internalData: InternalData | null = React.useMemo(
    () => (data ? wireDataToInternalData(data) : null),
    [data]
  )

  const members = {
    active: React.useMemo(
      () =>
        internalData
          ? internalData.members.filter(m => m.invitationAccepted && !m.deleted)
          : null,
      [internalData]
    ),
    invited: React.useMemo(
      () =>
        internalData
          ? internalData.members.filter(
              m => !m.invitationAccepted && !m.deleted
            )
          : null,
      [internalData]
    ),
    deactivated: React.useMemo(
      () => (internalData ? internalData.members.filter(m => m.deleted) : null),
      [internalData]
    ),
  }

  const searchUpdated = debounce(search => {
    setFilters({
      ...filters,
      search,
    })
  }, 350)

  return (
    <div className="tw-w-full SettingsModalUsersSection">
      <Modal.Header closeButton>
        <Icon.Users className="tw-hidden tw-text-bsGray-600 lg:tw-block" />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title as="h2" className="tw-hidden md:tw-block">
            <Translate>Members</Translate>
          </Modal.Title>
          <Translate as="p">
            Invite other members of your company/team.
          </Translate>
        </div>
        <SettingsSectionPicker onChange={onSelect} value="users" />
      </Modal.Header>
      <Modal.Body>
        <Translate as="p">
          Members can have their own scheduling page, collaborate on meetings,
          and more.
        </Translate>
        <div className="tw-flex tw-flex-col tw-mb-6 md:tw-flex-row md:tw-justify-between">
          <Toggler>
            {({ isToggled, setOff, setOn }) => (
              <>
                <Button
                  className="tw-order-2 md:tw-order-1"
                  onClick={setOn}
                  variant="success"
                >
                  <Icon.Plus size={16} /> <Translate>Add Members</Translate>
                </Button>
                {isToggled && (
                  <InviteMembersModal
                    onHide={setOff}
                    onSuccess={() => {
                      // Close the modal
                      setOff()

                      // Refetch members
                      refetch()
                    }}
                  />
                )}
              </>
            )}
          </Toggler>

          <div className="tw-mb-6 tw-w-full tw-order-1 md:tw-mb-0 md:tw-order-2 md:tw-w-80">
            <SearchInput
              defaultValue={filters.search!}
              onChange={ev => searchUpdated(ev.currentTarget.value)}
              type="text"
            />
          </div>
        </div>
        {!loading && internalData && (
          <Tabs navbar={false} defaultActiveKey="active" id="members-tabs">
            <Tab eventKey="active" title={`Active (${members.active!.length})`}>
              <div className="tw-flex tw-flex-col">
                {members.active!.map(member => (
                  <React.Fragment key={member.id}>
                    <Toggler>
                      {transferOwnershipModal => (
                        <Toggler>
                          {removeMemberModal => (
                            <>
                              <MemberSettingsListItem
                                member={member}
                                isCurrentUser={member.id === currentMember.id}
                                roles={internalData.roles}
                                onRoleChange={role =>
                                  onRoleChange(member, role)
                                }
                                onRemoveMember={removeMemberModal.setOn}
                                onTransferOwnership={
                                  currentMember.isOwner
                                    ? transferOwnershipModal.setOn
                                    : undefined
                                }
                              />

                              {removeMemberModal.isToggled && (
                                <RemoveMemberModal
                                  member={member}
                                  onHide={removeMemberModal.setOff}
                                  onSuccess={() => {
                                    // Close the removeMemberModal
                                    removeMemberModal.setOff()

                                    // Refetch members
                                    refetch()
                                  }}
                                />
                              )}

                              {transferOwnershipModal.isToggled && (
                                <TransferOwnershipModal
                                  member={member}
                                  onHide={transferOwnershipModal.setOff}
                                  onSuccess={() => {
                                    // Close the transferOwnershipModal
                                    transferOwnershipModal.setOff()

                                    // Refetch members
                                    refetch()
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Toggler>
                      )}
                    </Toggler>

                    <hr className="tw-my-0 tw-w-full" />
                  </React.Fragment>
                ))}
              </div>
            </Tab>

            {!!members.invited?.length && (
              <Tab
                eventKey="invited"
                title={`Invited (${members.invited.length})`}
              >
                <div className="tw-flex tw-flex-col">
                  {members.invited.map(member => (
                    <React.Fragment key={member.id}>
                      <Toggler>
                        {({ isToggled, setOff, setOn }) => (
                          <>
                            <MemberSettingsListItem
                              member={member}
                              isCurrentUser={member.id === currentMember.id}
                              roles={internalData.roles}
                              onRoleChange={role => onRoleChange(member, role)}
                              onRemoveMember={setOn}
                              onResendInvite={() =>
                                resendInvite({
                                  variables: { input: { id: member.id } },
                                }).then(
                                  () => toast.success('Invitation resent'),
                                  () =>
                                    toast.error(
                                      'Failed to resent',
                                      'Please try again in a few minutes.'
                                    )
                                )
                              }
                            />

                            {isToggled && (
                              <RemoveMemberModal
                                member={member}
                                onHide={setOff}
                                onSuccess={() => {
                                  // Close the removeMemberModal
                                  setOff()

                                  // Refetch members
                                  refetch()
                                }}
                              />
                            )}
                          </>
                        )}
                      </Toggler>

                      <hr className="tw-my-0 tw-w-full" />
                    </React.Fragment>
                  ))}
                </div>
              </Tab>
            )}

            {!!members.deactivated?.length && (
              <Tab
                eventKey="deactivated"
                title={`Deactivated (${members.deactivated.length})`}
              >
                <div className="tw-flex tw-flex-col">
                  {members.deactivated.map(member => (
                    <React.Fragment key={member.id}>
                      <Toggler>
                        {({ isToggled, setOff, setOn }) => (
                          <>
                            <MemberSettingsListItem
                              member={member}
                              isCurrentUser={member.id === currentMember.id}
                              roles={internalData.roles}
                              onRoleChange={role => onRoleChange(member, role)}
                              onRemoveMember={setOn}
                            />

                            {isToggled && (
                              <RemoveMemberModal
                                member={member}
                                onHide={setOff}
                                onSuccess={() => {
                                  // Close the removeMemberModal
                                  setOff()

                                  // Refetch members
                                  refetch()
                                }}
                              />
                            )}
                          </>
                        )}
                      </Toggler>

                      <hr className="tw-my-0 tw-w-full" />
                    </React.Fragment>
                  ))}
                </div>
              </Tab>
            )}
          </Tabs>
        )}
      </Modal.Body>
    </div>
  )
}

export default SettingsModalUsersSection
