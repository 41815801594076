import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Modal } from 'react-bootstrap'

import CreateFormFieldModal from './CreateFormFieldModal'
import FormBuilder from './FormBuilder'
import Icon from './Icon'
import Panel from './Panel'
import PanelBody from './PanelBody'
import PanelDescription from './PanelDescription'
import PanelIcon from './PanelIcon'
import PanelTitle from './PanelTitle'
import Toggler from './Toggler'
import { IMeetingType } from './UpdateMeetingTypeContext'
import { LocationType } from '../__generated__/graphql'
import Translate from './Translate'
import { MTESection } from './UpdateMeetingTypeModal'
import MeetingTypeEditorSectionPicker from './MeetingTypeEditorSectionPicker'

type Props = {
  onSelect: (section: MTESection) => void
}

const UpdateMeetingTypeModalIntakeSection: React.FC<Props> = ({
  onSelect,
}: Props) => {
  return (
    <div className="UpdateMeetingTypeModalIntakeSection tw-w-full">
      <Modal.Header closeButton>
        <Icon.InternalAsset
          assetName="icn-mte-header-intake"
          className="tw-hidden lg:tw-block"
        />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title>
            <Translate>Intake Form</Translate>
          </Modal.Title>
          <Translate as="p">
            Collect the information you need for your meeting.
          </Translate>
        </div>
        <MeetingTypeEditorSectionPicker onChange={onSelect} value="form" />
      </Modal.Header>
      <FinalForm.Field<IMeetingType['fields']> name="fields">
        {({ input: { onChange, value: fields } }) => (
          <Toggler>
            {({ isToggled, setOff, setOn }) => (
              <Modal.Body className="tw-space-y-[10px] md:tw-space-y-4">
                <Panel className="tw-bg-bsGray-100 tw-px-6 tw-py-4">
                  <PanelIcon className="tw-mr-3">
                    <Icon.Edit3 className="tw-text-bsGray-600" />
                  </PanelIcon>
                  <PanelBody className="md:tw-flex-row tw-justify-between tw-w-full">
                    <PanelTitle className="tw-text-bsGray-900">
                      <Translate>First & Last Name</Translate>
                    </PanelTitle>
                    <PanelDescription className="tw-text-bsGray-600 tw-flex-wrap tw-text-[12px] md:tw-text-sm">
                      <Translate>Collected automatically</Translate>
                    </PanelDescription>
                  </PanelBody>
                </Panel>
                <Panel className="tw-bg-bsGray-100 tw-px-6 tw-py-4">
                  <PanelIcon className="tw-mr-3">
                    <Icon.AtSign className="tw-text-bsGray-600" />
                  </PanelIcon>
                  <PanelBody className="md:tw-flex-row tw-justify-between tw-w-full">
                    <PanelTitle className="tw-text-bsGray-900">
                      <Translate>Email Address</Translate>
                    </PanelTitle>
                    <PanelDescription className="tw-text-bsGray-600 tw-flex-wrap tw-text-[12px] md:tw-text-sm">
                      <Translate>Collected automatically</Translate>
                    </PanelDescription>
                  </PanelBody>
                </Panel>
                <FinalForm.Field<
                  IMeetingType['locationType']
                > name="locationType">
                  {({ input: { value: locationType } }) => (
                    <>
                      {locationType === LocationType.PlaceUser && (
                        <Panel className="tw-bg-bsGray-100 tw-px-6 tw-py-4">
                          <PanelIcon className="tw-mr-3">
                            <Icon.MapPin className="tw-text-bsGray-600" />
                          </PanelIcon>
                          <PanelBody className="md:tw-flex-row tw-justify-between tw-w-full">
                            <PanelTitle className="tw-text-bsGray-900">
                              <Translate>Where should we meet?</Translate>
                            </PanelTitle>
                            <PanelDescription className="tw-text-bsGray-600 tw-flex-wrap tw-text-[12px] md:tw-text-sm">
                              <Translate>
                                Collected because of your{' '}
                                <Toggler>
                                  {({ isToggled, setOff, setOn }) => (
                                    <>
                                      <Button onClick={setOn} variant="link">
                                        location settings
                                      </Button>
                                      {/* QUESTION: How to send to another tab? */}
                                      {}
                                    </>
                                  )}
                                </Toggler>
                              </Translate>
                            </PanelDescription>
                          </PanelBody>
                        </Panel>
                      )}
                      {locationType === LocationType.PhoneUser && (
                        <Panel className="tw-bg-bsGray-100 tw-px-6 tw-py-4">
                          <PanelIcon className="tw-mr-3">
                            <Icon.Phone className="tw-text-bsGray-600" />
                          </PanelIcon>
                          <PanelBody className="md:tw-flex-row tw-justify-between tw-w-full">
                            <PanelTitle className="tw-text-bsGray-900">
                              <Translate>Phone Number</Translate>
                            </PanelTitle>
                            <PanelDescription className="tw-text-bsGray-600 tw-flex-wrap tw-text-[12px] md:tw-text-sm">
                              <Translate>
                                Collected because of your{' '}
                                <>
                                  <Button
                                    onClick={() => onSelect('location')}
                                    variant="link"
                                  >
                                    location settings
                                  </Button>
                                </>
                              </Translate>
                            </PanelDescription>
                          </PanelBody>
                        </Panel>
                      )}
                    </>
                  )}
                </FinalForm.Field>
                <FormBuilder formFields={fields} onChange={onChange} />
                <Button onClick={setOn} variant="link">
                  + <Translate>Add Another Question</Translate>
                </Button>
                {isToggled && (
                  <CreateFormFieldModal
                    onHide={setOff}
                    onSuccess={formField => {
                      onChange([
                        ...fields,
                        {
                          id: Math.random().toString(),
                          order: fields.length,
                          ...formField,
                        },
                      ])
                      setOff()
                    }}
                  />
                )}
              </Modal.Body>
            )}
          </Toggler>
        )}
      </FinalForm.Field>
    </div>
  )
}

export default UpdateMeetingTypeModalIntakeSection
