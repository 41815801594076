import React from 'react'

export const ModalContext = React.createContext<
  { onToggleModal: VoidFunction } | undefined
>(undefined)

export const ModalProvider: React.FC = ({ children }) => {
  const onToggleModal = React.useCallback(() => {
    document.documentElement.setAttribute(
      'data-modal-active',
      document.documentElement.getAttribute('data-modal-active') === 'true'
        ? 'false'
        : 'true'
    )
  }, [])

  return (
    <ModalContext.Provider value={{ onToggleModal }}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const ctx = React.useContext(ModalContext)

  if (typeof ctx === 'undefined') {
    throw new Error('useModal must be used in scope of ModalProvider')
  }
  return ctx
}
