import * as React from 'react'
import { Container } from 'react-bootstrap'

import './TopBar.scss'

interface Props {}

const TopBar: React.FC<Props> = ({ children }) => (
  <div className="TopBar mb-24">
    <Container>{children}</Container>
  </div>
)

export default TopBar
