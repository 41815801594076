import * as React from 'react'
import { prettyURL } from '../../../utils'
import CopyButton from '../../CopyButton'
import Icon from '../../Icon'

export type MeetingConferenceLocationUnitProps = {
  url: string | null
  conferenceProviderName: string
  conferenceProviderSlug: string
}

const MeetingConferenceLocationUnit: React.FC<
  MeetingConferenceLocationUnitProps
> = ({ url, conferenceProviderName, conferenceProviderSlug }) => (
  <div className="d-flex align-items-center space-between-8">
    <Icon.InternalAsset
      assetName={conferenceProviderSlug}
      size={20}
      alt={conferenceProviderName}
      title={conferenceProviderName}
    />

    {url && (
      <>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {prettyURL(url)}
        </a>
        <CopyButton text={url} />
      </>
    )}

    {!url && <em>No conference URL was created for this meeting</em>}
  </div>
)

export default MeetingConferenceLocationUnit
