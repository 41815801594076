import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Badge from './Badge'
import { UnsavedFormField } from './CreateFormFieldModal'
import ProfileContext from './ProfileContext'
import Toggler from './Toggler'
import UnsupportedPlan from './UnsupportedPlan'
import UpdateMeetingTypeContext from './UpdateMeetingTypeContext'
import ExternalLink from './ExternalLink'

type Props = {
  isDisabled: boolean
  isUpdate?: boolean
  isVisible?: boolean
}

const FormFieldPreFill: React.FC<Props> = ({ isVisible }) => {
  const meetingType = React.useContext(UpdateMeetingTypeContext)
  const profile = React.useContext(ProfileContext)
  return (
    <Toggler initial={isVisible}>
      {({ isToggled, setOn }) => (
        <>
          {isToggled && (
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Pre-fill Responses</Form.Label>
                  <Form.Text>
                    If you already have the response for this field in your
                    system, you can pre-fill it into the form via the URL. This
                    will save your users time and reduce errors. Here's how this
                    field can be pre-filled:
                  </Form.Text>
                  <div className="tw-highlight tw-p-3 tw-mt-2 tw-rounded-[5px]">
                    <code className="tw-text-bsGray-900">
                      <FinalForm.Field<UnsavedFormField['slug']> name="slug">
                        {({ input: { value: slug } }) =>
                          `${meetingType.bookingUrl}?field__${slug}=VALUE`
                        }
                      </FinalForm.Field>
                    </code>
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="tw-flex tw-items-center">
                    Hidden Field
                    <Badge className="tw-ml-2" variant="success">
                      Premium
                    </Badge>
                  </Form.Label>

                  <FinalForm.Field<UnsavedFormField['visible']>
                    name="visible"
                    type="checkbox"
                  >
                    {({ input: { checked, name, onChange } }) => (
                      <Form.Check
                        checked={!checked}
                        id="UpdateFormFieldModal--visible"
                        label="Don't show this field to customers when they are scheduling"
                        name={name}
                        onChange={() => onChange(!checked)}
                        type="checkbox"
                      />
                    )}
                  </FinalForm.Field>

                  <Form.Text>
                    <span>
                      Useful if you want to pre-fill an internal identifier,
                      campaign parameter, or some other value that should be
                      associated with the user, but not shown to them.
                    </span>{' '}
                    <ExternalLink href="http://kb.appointlet.help/en/articles/5031004">
                      Learn more
                    </ExternalLink>
                  </Form.Text>

                  {!profile.plan.allowFormFields && (
                    <div className="tw-mt-2">
                      <UnsupportedPlan source="Form Fields" />
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row className="tw-pl-4">
            {!isToggled && (
              <Button onClick={setOn} variant="link">
                Learn how to pre-fill responses
              </Button>
            )}
          </Row>
        </>
      )}
    </Toggler>
  )
}

export default FormFieldPreFill
