import * as React from 'react'
import { gql } from '@apollo/client'
import { Media } from 'react-bootstrap'
import { InternalData as WebConferenceData } from './SettingsModalWebConferencingSection'
import { startOAuthFlow, WindowClosed, toast } from '../utils'
import ProfileContext from './ProfileContext'
import MoreButton from './MoreButton'
import DeleteConferencingAccountModal from './DeleteConferencingAccountModal'
import UpdateConferencingAccountModal from './UpdateConferenceAccountModal'
import Toggler from './Toggler'
import LoadingButton from './LoadingButton'
import Icon from './Icon'
import {
  useWebConferencingProviderRowLazyQuery,
  useReconnectConferencingAccountMutation,
  useCreateConferencingAccountMutation,
} from '../__generated__/graphql'
import Spinner from './Spinner'
import DropdownItem from './DropdownItem'

// Mutation for checking to see if the conferencing account is connected for the given provider
gql`
  query WebConferencingProviderRow($id: ID!) {
    conferencingAccount: getConferencingAccountById(id: $id) {
      isConnected
    }
  }
`

// Mutation for reconnecting the conferencing account for the given provider
gql`
  mutation ReconnectConferencingAccount(
    $input: ReconnectConferencingAccountInput!
  ) {
    reconnectConferencingAccount(input: $input) {
      data {
        id
        isConnected
      }
      errors {
        field
        messages
      }
    }
  }
`

// Mutation for creating new conferencing accounts for the given provider
gql`
  mutation CreateConferencingAccount($input: CreateConferencingAccountInput!) {
    account: createConferencingAccount(input: $input) {
      data {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

type Props = {
  provider: WebConferenceData['providers'][0]
  account?: WebConferenceData['accounts'][0]
  onConnect: () => void
}

const WebConferencingProviderRow: React.FC<Props> = ({
  provider,
  account,
  onConnect: didConnect,
}) => {
  const profile = React.useContext(ProfileContext)

  // Tracks the state that we're in.
  const [state, setState] = React.useState<
    | 'unconnected'
    | 'loading'
    | 'connected'
    | 'disconnected'
    | 'reconnecting'
    | 'connecting'
  >(account ? 'loading' : 'unconnected')

  // Prep the create conferencing account mutation
  const [createConferencingAccount] = useCreateConferencingAccountMutation()

  // Prep the reconnect conferencing account mutation
  const [reconnectAccount] = useReconnectConferencingAccountMutation()

  // Prep the isConnected query
  const [fetchStatus, { refetch: refetchStatus }] =
    useWebConferencingProviderRowLazyQuery({
      onCompleted: data => {
        setState(
          data?.conferencingAccount?.isConnected ? 'connected' : 'disconnected'
        )
      },
    })

  // When we first render, check to see if we have an account and then get it's status
  // if we do.
  React.useEffect(() => {
    if (account) {
      fetchStatus({ variables: { id: account.id } })
    } else {
      setState('unconnected')
    }
  }, [account, fetchStatus])

  // Attempts to create a new conferencing account
  const onConnect = async () => {
    // Transition to connecting state
    setState('connecting')

    try {
      // Run the OAuth flow to get an OAuth2 code
      const code = await startOAuthFlow(provider.oauth2AuthorizationUrl, {
        width: 400,
        height: 600,
      })

      // Take the code and give it back to the server
      const res = await createConferencingAccount({
        variables: {
          input: {
            oauth2Code: code,
            profile: profile.id,
            provider: provider.id,
          },
        },
      })
      // If client validation and GraphQL error related errors exist
      if (res.data?.account?.errors) {
        console.error(
          'createConferencingAccount mutation',
          res.data.account.errors
        )

        throw new Error('Failed to connect')
      }
      // If data is present trigger the toast to display and close the modal
      if (res.data?.account?.data) {
        toast.success(`Connected ${provider.name} Account`)

        // Transition to connected state
        setState('connected')

        // Let caller know
        didConnect()
      }
      // Here we are catching sever related errors.
    } catch (err) {
      // Transition back to unconnected state
      setState('unconnected')

      if (err === WindowClosed) return

      console.error('createConferencingAccount mutation', err)

      // Notify user
      toast.error(`Failed to connect ${provider.name} account`)
    }
  }

  const onReconnect = async () => {
    setState('reconnecting')

    try {
      // Run the OAuth flow to get an OAuth2 code
      const code = await startOAuthFlow(
        account!.provider.oauth2AuthorizationUrl,
        { width: 400, height: 600 }
      )

      // Take the code and give it back to the server
      const { data, errors } = await reconnectAccount({
        variables: { input: { id: account!.id, oauth2Code: code } },
      })

      // Refetch the status
      refetchStatus!({ id: account!.id })

      // If client validation and GraphQL error related errors exist
      if (errors) {
        console.error('reconnectAccount mutation', errors)
        throw new Error('Failed to reconnect')
      }
      // If data is present trigger the toast to display and close the modal
      if (data) {
        toast.success(`Reconnected ${account!.name} successfully`)
        setState('connected')
      }
      // Here we are catching sever related errors.
    } catch (err) {
      setState('disconnected')

      if (err === WindowClosed) return

      toast.error(`Failed to reconnect ${account!.name}`)
      console.error('reconnectAccount mutation', err)
    }
  }

  return (
    <Media className="space-between-12 align-items-center">
      <Icon.InternalAsset assetName={provider.slug} size={40} />
      <Media.Body className="d-flex flex-column">
        <strong
          className={`mb-2 ${
            state === 'disconnected' ? 'text-danger' : undefined
          }`}
        >
          {provider.name}
        </strong>
        <p
          className={state === 'disconnected' ? 'text-danger' : 'text-gray-600'}
        >
          Generate a unique {provider.name} link for each new meeting.
        </p>
      </Media.Body>

      {state === 'loading' && (
        <div className="mr-32">
          <Spinner size={20} />
        </div>
      )}

      {state === 'connected' && (
        <MoreButton alignRight size="sm">
          <Toggler>
            {({ isToggled, setOff, setOn }) => (
              <React.Fragment>
                <DropdownItem onClick={setOn}>
                  <Icon.Settings size={20} />
                  Settings
                </DropdownItem>
                {isToggled && (
                  <UpdateConferencingAccountModal
                    id={account!.id}
                    onHide={setOff}
                    onSuccess={setOff}
                    provider={account!.provider}
                  />
                )}
              </React.Fragment>
            )}
          </Toggler>

          <Toggler>
            {({ isToggled, setOff, setOn }) => (
              <React.Fragment>
                <DropdownItem onClick={setOn}>
                  <Icon.Trash2 size={20} />
                  Disconnect
                </DropdownItem>

                {/* Make sure we check `account` here because if we don't then there is
                    a weird situation we can get into where after deleting, this component
                    will re-render without an account but the modal will still be mounted
                    and will also attempt to re-render and will fail. */}
                {isToggled && account && (
                  <DeleteConferencingAccountModal
                    account={account!}
                    onHide={setOff}
                    onSuccess={setOff}
                  />
                )}
              </React.Fragment>
            )}
          </Toggler>
        </MoreButton>
      )}

      {(state === 'reconnecting' || state === 'disconnected') && (
        <LoadingButton
          loading={state === 'reconnecting'}
          variant="danger"
          onClick={onReconnect}
        >
          Reconnect
        </LoadingButton>
      )}

      {(state === 'unconnected' || state === 'connecting') && (
        <LoadingButton
          loading={state === 'connecting'}
          variant="success"
          onClick={onConnect}
        >
          Connect
        </LoadingButton>
      )}
    </Media>
  )
}

export default WebConferencingProviderRow
