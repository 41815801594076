import * as React from 'react'
import './ButtonGroupInput.scss'
import { ButtonGroup, Button } from 'react-bootstrap'

interface Props<TValue> {
  options: { label: string; value: TValue; className?: string }[]
  value: TValue
  onChange: (value: TValue) => void
}

const ButtonGroupInput = <TValue,>({
  options,
  value,
  onChange,
}: React.PropsWithChildren<Props<TValue>>): React.ReactElement | null => (
  <div className="ButtonGroupInput">
    <ButtonGroup className="tw-w-full tw-max-w-xs md:tw-max-w-full">
      {options.map((option, idx) => (
        <Button
          key={idx}
          variant={
            option.value === value ? 'outline-primary' : 'outline-secondary'
          }
          className={option.className}
          onClick={() => {
            if (option.value !== value) {
              onChange(option.value)
            }
          }}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  </div>
)

export default ButtonGroupInput
