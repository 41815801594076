import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form } from 'react-bootstrap'
import { isFormFieldInvalid } from '../utils/validators'

interface Props {
  name: string
}

const FormErrorSubscription: React.FC<Props> = ({ name }) => (
  <FinalForm.Field
    name={name}
    subscription={{
      active: true,
      dirtySinceLastSubmit: true,
      error: true,
      invalid: true,
      submitError: true,
      touched: true,
    }}
  >
    {({ meta }) =>
      isFormFieldInvalid(meta) && (
        <Form.Control.Feedback type="invalid">
          {meta.error || meta.submitError}
        </Form.Control.Feedback>
      )
    }
  </FinalForm.Field>
)

export default FormErrorSubscription
