import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import LoadingButton from './LoadingButton'
import {
  ConferenceProviderNode,
  ConferencingAccountNode,
  Maybe,
  useConferencingAccountDeleteMutation,
} from '../__generated__/graphql'
import { ConferenceAccountTypeNames } from '../types'
import { DateTime, toast } from '../utils'
import Icon from './Icon'
import Translate from './Translate'
import Var from './Var'

gql`
  mutation ConferencingAccountDelete($input: DeleteConferencingAccountInput!) {
    account: deleteConferencingAccount(input: $input) {
      data {
        id
        deleted
      }
    }
  }
`

export type Props = {
  account: Pick<ConferencingAccountNode, 'id' | 'isDefault' | 'name'> & {
    __typename: ConferenceAccountTypeNames
    deleted: Maybe<DateTime>
    provider: Pick<
      ConferenceProviderNode,
      'id' | 'name' | 'slug' | 'oauth2AuthorizationUrl'
    >
  }
  onHide: () => void
  onSuccess: () => void
}

const DeleteConferencingAccountModal: React.FC<Props> = ({
  account,
  onHide,
  onSuccess,
}) => {
  const [deleteConferencingAccount, { loading }] =
    useConferencingAccountDeleteMutation()
  const onDeleteAccount = async () => {
    try {
      const res = await deleteConferencingAccount({
        variables: {
          input: {
            id: account.id,
          },
        },
      })

      // If client validation and GraphQL error related errors exist
      if (!res.data?.account?.data) {
        console.error('deleteConferencingAccount mutation', res.errors)
        throw new Error('Failed to delete conferencing account')
      }

      // If data is present trigger the toast to display and close the modal
      toast.success(`Deleted ${account.provider.name} account`, account.name)

      onSuccess()

      // Here we are catching sever related errors.
    } catch (err) {
      toast.error('Something went wrong!')
      console.error('deleteConferencingAccount mutation', err)
    }
  }
  return (
    <Modal
      className="DeleteConferencingAccountModal"
      show={true}
      size="sm"
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Icon.InternalAsset
          assetName={account.provider.slug}
          size={36}
          className="tw-mr-3"
        />
        <Modal.Title>
          <Translate>
            Disconnect <Var name="name">{account.provider.name}</Var>
          </Translate>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Translate as="p">
          Are you sure you want to disconnect{' '}
          <Var name="name">{account.provider.name}</Var>? Disconnecting will
          have the following impact:
        </Translate>

        <ul>
          <li>
            <Translate as="p">
              Any meeting types that were using it will no longer be able to
              create <Var name="name">{account.provider.name}</Var> conferencing
              rooms for new meetings.
            </Translate>
          </li>
          <li>
            <Translate as="p">Existing meetings will be unaffected.</Translate>
          </li>
        </ul>

        <Translate as="p">
          If you're trying to connect a different{' '}
          <Var name="name">{account.provider.name}</Var> account, do that right
          after disconnecting this one.
        </Translate>
      </Modal.Body>
      <Modal.Footer>
        <LoadingButton
          loading={loading}
          onClick={onDeleteAccount}
          variant="danger"
          block
        >
          <Translate>
            Disconnect <Var name="name">{account.provider.name}</Var>
          </Translate>
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteConferencingAccountModal
