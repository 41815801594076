import * as React from 'react'
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap'
import { LinkGetProps, Match } from '@reach/router'

import './TopNav.scss'
import Badge from './Badge'
import DropdownItem from './DropdownItem'
import Icon from './Icon'
import PermissionsFlag from './PermissionsFlag'
import Portrait from './Portrait'
import PremiumUpgradeModal from './PremiumUpgradeModal'
import ProfileContext from './ProfileContext'
import ProfileLink from './ProfileLink'
import ProfileSwitcher from './ProfileSwitcher'
import SettingsModal from './SettingsModal'
import Toggler from './Toggler'
import UserContext from './UserContext'
import { logout, getAccessToken, Analytics } from '../utils'
import Translate from './Translate'
interface Props {
  onboarding?: boolean
}

const TopNav: React.FC<Props> = ({ onboarding }) => (
  <div className="TopNav">
    <Navbar bg="primary" variant="dark" expand="sm">
      <Container>
        <ProfileSwitcher />

        <Navbar.Toggle aria-controls="TopNav--collapse" />

        <Navbar.Collapse id="TopNav--collapse" className="TopNav--navs">
          <Nav className="TopNav--primary">
            {!onboarding ? (
              <React.Fragment>
                <Match path="meetings">
                  {() => (
                    <Nav.Link
                      to="meetings"
                      as={ProfileLink}
                      getProps={(props: LinkGetProps) => ({
                        className: `nav-link ${
                          props.isPartiallyCurrent ? 'current' : ''
                        }`,
                      })}
                    >
                      <Icon.Inbox size="18" />
                      <span className="text-nowrap">
                        <Translate>My Meetings</Translate>
                      </span>
                    </Nav.Link>
                  )}
                </Match>
                <Nav.Link
                  to="scheduling-pages"
                  as={ProfileLink}
                  getProps={(props: LinkGetProps) => ({
                    className: `nav-link ${
                      props.isPartiallyCurrent ? 'current' : ''
                    }`,
                  })}
                >
                  <Icon.Server size="18" />
                  <span className="text-nowrap">
                    <Translate>Scheduling Pages</Translate>
                  </span>
                </Nav.Link>
              </React.Fragment>
            ) : null}
          </Nav>
          <Nav className="TopNav--secondary ml-auto">
            {!onboarding ? (
              <ProfileContext.Consumer>
                {profile =>
                  profile.plan.freeDefault && (
                    <PermissionsFlag permission="canManageBilling">
                      <Toggler>
                        {modal => (
                          <React.Fragment>
                            <div
                              className="align-self-center d-none d-sm-flex"
                              onClick={() => {
                                Analytics.trackEvent(
                                  'NavBar Premium Upgrade: Clicked'
                                )
                                modal.setOn()
                              }}
                            >
                              <Badge className="PremiumBadge text-white mr-16">
                                <Translate>Upgrade To Premium</Translate>
                              </Badge>
                            </div>
                            {modal.isToggled && (
                              <PremiumUpgradeModal
                                profile={profile}
                                onHide={modal.setOff}
                                onSuccess={() => {
                                  modal.setOff()
                                }}
                              />
                            )}
                          </React.Fragment>
                        )}
                      </Toggler>
                    </PermissionsFlag>
                  )
                }
              </ProfileContext.Consumer>
            ) : null}

            <NavDropdown
              alignRight
              className="help-dropdown no-caret"
              id="help-dropdown"
              title={
                <div className="align-items-center d-flex">
                  <Icon.HelpCircle color="#fff" size="18" />

                  <div className="d-block d-sm-none ml-8">
                    <Translate>Help</Translate>
                  </div>
                </div>
              }
            >
              <DropdownItem
                as="a"
                href="http://kb.appointlet.help/en/collections/2366699-getting-started"
                // @ts-ignore
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon.CheckSquare size="18" />
                <Translate>Getting Started Guide</Translate>
              </DropdownItem>
              <DropdownItem
                as="a"
                href="http://kb.appointlet.help"
                // @ts-ignore
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon.Book size="18" />
                <Translate>Knowledge Base</Translate>
              </DropdownItem>
              <DropdownItem as="a" onClick={() => window.Intercom('show')}>
                <Icon.MessageSquare size="18" />
                <Translate>Chat with us</Translate>
              </DropdownItem>
              <NavDropdown.Divider />
              <DropdownItem
                as="a"
                href="https://status.appointlet.com/"
                // @ts-ignore
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon.Activity size="18" />
                <Translate>System Status</Translate>
                <Badge variant="success" className="ml-auto">
                  <Translate>New</Translate>
                </Badge>
              </DropdownItem>
            </NavDropdown>
            <UserContext.Consumer>
              {({ user }) => (
                <NavDropdown
                  title={
                    <div className="align-items-center d-flex">
                      <div className="mr-8">
                        <Portrait {...user} size={28} hideToolTip />
                      </div>
                      <p className="d-sm-none d-md-block m-0">
                        {user.firstName}
                      </p>
                    </div>
                  }
                  id="app-dropdown"
                  alignRight
                >
                  {!onboarding ? (
                    <React.Fragment>
                      <Toggler>
                        {calendarModal => (
                          <React.Fragment>
                            <NavDropdown.Item onClick={calendarModal.setOn}>
                              <Icon.Calendar size={20} />
                              <Translate>Calendars</Translate>
                            </NavDropdown.Item>
                            {calendarModal.isToggled && (
                              <SettingsModal
                                initialSection="calendars"
                                onHide={calendarModal.setOff}
                              />
                            )}
                          </React.Fragment>
                        )}
                      </Toggler>
                      <Toggler>
                        {settingsModal => (
                          <React.Fragment>
                            <NavDropdown.Item onClick={settingsModal.setOn}>
                              <Icon.Monitor size={20} />
                              <Translate>Web Conferencing</Translate>
                            </NavDropdown.Item>
                            {settingsModal.isToggled && (
                              <SettingsModal
                                initialSection="web-conferencing"
                                onHide={settingsModal.setOff}
                              />
                            )}
                          </React.Fragment>
                        )}
                      </Toggler>
                      <NavDropdown.Divider />
                      <PermissionsFlag permission="canManageMembers">
                        <Toggler>
                          {settingsModal => (
                            <React.Fragment>
                              <NavDropdown.Item onClick={settingsModal.setOn}>
                                <Icon.Users size={20} />
                                <Translate>Members</Translate>
                              </NavDropdown.Item>
                              {settingsModal.isToggled && (
                                <SettingsModal
                                  initialSection="users"
                                  onHide={settingsModal.setOff}
                                />
                              )}
                            </React.Fragment>
                          )}
                        </Toggler>
                      </PermissionsFlag>
                      <PermissionsFlag permission="canManageBilling">
                        <Toggler>
                          {({ isToggled, setOff, setOn }) => (
                            <React.Fragment>
                              <NavDropdown.Item onClick={setOn}>
                                <Icon.CreditCard size={20} />
                                <Translate>Billing & Invoice</Translate>
                              </NavDropdown.Item>
                              {isToggled && (
                                <SettingsModal
                                  initialSection="billing"
                                  onHide={setOff}
                                />
                              )}
                            </React.Fragment>
                          )}
                        </Toggler>
                        <NavDropdown.Divider />
                      </PermissionsFlag>
                      <Toggler>
                        {settingsModal => (
                          <React.Fragment>
                            <NavDropdown.Item onClick={settingsModal.setOn}>
                              <Icon.Settings size={20} />
                              <Translate>Settings</Translate>
                            </NavDropdown.Item>
                            {settingsModal.isToggled && (
                              <SettingsModal
                                initialSection="profile"
                                onHide={settingsModal.setOff}
                              />
                            )}
                          </React.Fragment>
                        )}
                      </Toggler>
                    </React.Fragment>
                  ) : null}

                  <NavDropdown.Item onClick={logout}>
                    <Icon.LogOut size={20} />
                    <Translate>Logout</Translate>
                  </NavDropdown.Item>

                  {process.env.REACT_APP_NETLIFY &&
                    process.env.REACT_APP_BRANCH !== 'production' && (
                      <React.Fragment>
                        <NavDropdown.Divider />
                        <NavDropdown.Header>Staging</NavDropdown.Header>

                        <NavDropdown.Item
                          onClick={() => {
                            const branchOrCommit = window.prompt(
                              'Enter branch name or commit ID'
                            )
                            if (branchOrCommit) {
                              window.location.assign(
                                `https://${branchOrCommit}--appointlet-dashboard.netlify.app/auth.html#access_token=${getAccessToken()}`
                              )
                            }
                          }}
                        >
                          <Icon.Eye size={20} />
                          Preview Build
                        </NavDropdown.Item>
                      </React.Fragment>
                    )}
                </NavDropdown>
              )}
            </UserContext.Consumer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </div>
)

export default TopNav
