import * as React from 'react'
import { Router, Redirect, globalHistory } from '@reach/router'
import { gql } from '@apollo/client'
import Helmet from 'react-helmet'
import UserContext, { InternalUser } from './UserContext'
import ProfileRoute from './ProfileRoute'
import MeetingsPage from './MeetingsPage'
import MeetingTypesPage from './MeetingTypesPage'
import OnboardingCalendarIntegrationsPage from './OnboardingCalendarIntegrationsPage'
import OnboardingCreateSchedulingPage from './onboarding/pages/OnboardingCreateSchedulingPage'
import OnboardingAvailabilityPage from './onboarding/pages/OnboardingAvailabilityPage'
import OnboardingCreateMeetingTypePage from './onboarding/pages/OnboardingCreateMeetingTypePage'
import OnboardingRoute from './OnboardingRoute'
import ToastContainer from './ToastContainer'
import {
  DateTime,
  UserLocalSettings,
  initializeExternalServices,
  Analytics,
} from '../utils'
import SchedulingPagesPage from './SchedulingPagesPage'
import {
  AppQuery as Response,
  Maybe,
  Timezone,
  useAppQuery,
  useAppSetTimeZoneMutation,
} from '../__generated__/graphql'
import PremiumUpgradeModal from './PremiumUpgradeModal'
import ErrorScreen from './ErrorScreen'

gql`
  query App {
    user {
      createdAt
      email
      firstName
      id
      image
      language
      lastName
      needsOnboarding
      timezone
      members {
        edges {
          node {
            id
            invitationAccepted
            isOwner
            role {
              id
              canManageBilling
              canManageMeetings
              canManageMeetingTypes
              canManageMembers
              canManageOrgSettings
            }
            meetingCount
            profile {
              allocatedSeats
              billedAnnually
              needsOnboarding
              billingCustomerId
              billingSubscriptionId
              brandColor
              featureFlags
              id
              image
              members {
                edges {
                  node {
                    id
                    isOwner
                    user {
                      email
                      id
                      image
                      firstName
                      lastName
                    }
                  }
                }
              }
              name
              occupiedSeats
              personal
              plan {
                allowEmailCustomization
                allowFormFields
                allowManualConfirm
                allowPaymentCollection
                allowPrivateMeetingTypes
                allowRedirect
                allowReminders
                allowRoundRobin
                allowWebConferencing
                allowWebhooks
                allowUtmParameters
                allowZapier
                freeDefault
                id
                name
                requireAppointletBranding
                seatPriceMonthly
                seatPriceYearly
                trialDefault
              }
            }
          }
        }
      }
    }
  }
`

gql`
  mutation AppSetTimeZone($input: UpdateUserInput!) {
    user: updateUser(input: $input) {
      data {
        id
        timezone
      }
      errors {
        field
        messages
      }
    }
  }
`

interface Props {}

const wireDataToInternalData = (wire: Response['user']): InternalUser => ({
  ...wire!,
  createdAt: new DateTime(wire!.createdAt),
  members: wire!.members.edges
    ? wire!.members.edges.map(edge => ({
        ...edge!.node!,
        profile: {
          ...edge!.node!.profile,
          featureFlags: edge!.node!.profile.featureFlags
            ? edge!.node!.profile.featureFlags
            : [],
          members: edge!.node!.profile.members.edges
            ? edge!.node!.profile.members.edges.map(edge => ({
                ...edge!.node!,
              }))
            : [],
          plan: edge!.node!.profile.plan!,
        },
        role: edge!.node!.role,
      }))
    : [],
  // Computed Values
  profileCount: wire!.members.edges.length,
  profiles: wire!.members.edges.map(edge => ({ ...edge!.node!.profile })),
})

const determineRoute = (
  storageValue: Maybe<string>,
  user: InternalUser
): string => {
  let profile = ''

  // If a value was found in storage and that value is found as a profile
  // that the user is a part of then set that as the profile they should be
  // routed to.
  // If not grab the user's first profile they are a member of to set as the
  // profile to be directed to.
  if (
    storageValue &&
    user.members.find(member => member.profile.id === storageValue)
  ) {
    profile = storageValue
  } else {
    profile = user.members[0].profile.id
  }
  // First determine if the user is a member of any profiles or needs onboarding.
  // aka: Are they a new user.
  if (user.members.length === 1 && user.members[0].profile.needsOnboarding) {
    return `profiles/${profile}/onboarding/scheduling-page`
  }
  // In all other cases direct the user to the meeting page.
  // The "." at the end of this URL forces reach to add a trailing slash.
  return `profiles/${profile}/meetings/.`
}

// 5 minutes
const POLLING_INTERVAL = 300000

const App: React.FC<Props> = () => {
  // Fetch the data to draw our app
  const { data, error, loading } = useAppQuery({
    pollInterval: POLLING_INTERVAL,
  })

  // When the data is ready, convert it into an easier to use format
  const user: Maybe<InternalUser> = React.useMemo(
    () => (data ? wireDataToInternalData(data.user) : null),
    [data]
  )

  // Prepare a mutation to set the timezone of the user if it isn't set already
  const [updateUser] = useAppSetTimeZoneMutation()

  // This will run on mount executing the mutation.
  // It will run once because the deps updated (i.e. user.timezone changed)
  React.useEffect(() => {
    // If we haven't loaded a user yet, or they already have
    // a timezone, stop here.
    if (!user || user.timezone) {
      return
    }

    // Get the user's timezone out of the browser.
    // We need to format the value to what the server expects.
    const timezone = new DateTime()
      .getTimeZoneName()
      .replace(/\//g, '_')
      .toUpperCase() as Timezone

    // Because the mutation returns 'timezone' the cache will get busted
    // with the new value and we will not encounter issues with exiting
    // the onboarding flow.
    updateUser({
      variables: { input: { email: user.email, id: user.id, timezone } },
    })
  }, [updateUser, user])

  // If the page changes then we need to notify analytics libraries that care.
  React.useEffect(() => {
    return globalHistory.listen(() => Analytics.pageChanged())
  }, [])

  // We like to directly link users to the premium upgrade
  // modal sometimes, and this piece of state controls that modal.
  // See App.tsx for more info on where the storage state is set.
  const [premiumUpgradeModalIsOpen, setPremiumUpgradeModalIsOpen] =
    React.useState<boolean>(
      () => window.location.hash === '#premium-upgrade-modal'
    )

  // Now that we have a user, initialize all of our external libraries
  React.useEffect(() => {
    if (!user) {
      return
    }

    initializeExternalServices(user)
  }, [user])

  // We want to check if we are in an error state from the query first
  // and display this UI too the user. If this query is failing we are likely
  // in the event of an outage of some kind.
  if (error) {
    return (
      <ErrorScreen
        helmetTitle="Error"
        title="Oops, something went wrong."
        subTitle="Please try again in a few minutes."
      />
    )
  }

  // Are we in a loading state OR data is undefined?
  if (loading || !user) {
    // TODO: add a loading state
    return null
  }

  const settings = new UserLocalSettings(user)

  return (
    <div className="App">
      <ToastContainer />
      <Helmet
        htmlAttributes={{ lang: user.language.toLowerCase() }}
        titleTemplate="%s | Appointlet"
      />
      <UserContext.Provider value={{ settings, user }}>
        <Router primary={false}>
          <Redirect
            from="/"
            to={determineRoute(settings.get('profileId'), user)}
            noThrow
          />

          <ProfileRoute path="profiles/:profileId">
            <OnboardingRoute path="onboarding">
              <OnboardingCreateSchedulingPage path="scheduling-page" />
              <OnboardingCreateMeetingTypePage path="meeting-type" />
              <OnboardingCalendarIntegrationsPage path="calendars" />
              <OnboardingAvailabilityPage path="availability" />
            </OnboardingRoute>
            <MeetingsPage path="meetings" />
            {/* Direct user's to this page so we can query their scheduling pages. */}
            <SchedulingPagesPage path="scheduling-pages" />
            {/* Will evaluate to this path for all other teams. */}
            <MeetingTypesPage path="scheduling-pages/:schedulingPageId" />
          </ProfileRoute>
        </Router>

        {premiumUpgradeModalIsOpen && (
          <PremiumUpgradeModal
            // TODO: this logic will need to get smarter for multi-profile peeps
            profile={user.members[0].profile}
            onSuccess={() => setPremiumUpgradeModalIsOpen(false)}
            onHide={() => setPremiumUpgradeModalIsOpen(false)}
          />
        )}
      </UserContext.Provider>
    </div>
  )
}

export default App
