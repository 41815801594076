import * as React from 'react'
import { Link, LinkProps } from '@reach/router'

import ProfileContext from './ProfileContext'

interface Props extends LinkProps<any> {
  profile?: string
}

const ProfileLink: React.FC<Props> = ({ children, profile, to, ...rest }) => {
  const { id } = React.useContext(ProfileContext)
  return (
    // @ts-ignore
    <Link
      {...rest}
      // If a profile is provided navigate using that profile
      // If not fallback to the profile in context.
      to={`/profiles/${profile || id}/${to}/`}
    >
      {children}
    </Link>
  )
}

export default ProfileLink
