import * as React from 'react'

import './RichTextEditorButton.scss'

interface Props {
  active: boolean
  icon: React.ReactNode
  onToggle: (blockStyle: string) => void
  style: string
}

const RichTextEditorButton: React.FC<Props> = ({
  active,
  icon,
  onToggle,
  style,
}) => {
  const onMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault()
    onToggle(style)
  }

  return (
    <span
      className={`RichTextEditorButton ${
        active && 'RichTextEditorButton--active'
      }`}
      onMouseDown={onMouseDown}
    >
      {icon}
    </span>
  )
}

export default RichTextEditorButton
