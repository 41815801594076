import * as React from 'react'
import { FormControlProps } from 'react-bootstrap'
import IconTextInput from './IconTextInput'
import Icon from './Icon'

interface Props {
  defaultValue?: string
}

const URLInput: React.FC<Props & FormControlProps> = props => (
  <div className="URLInput">
    <IconTextInput
      icon={Icon.Link}
      //@ts-ignore
      placeholder="https://example.com"
      type="text"
      {...props}
    />
  </div>
)

export default URLInput
