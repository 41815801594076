import * as React from 'react'
import './ScheduleTimezoneInput.scss'
import { Button } from 'react-bootstrap'
import PopoverOverlay from './PopoverOverlay'
import * as FinalForm from 'react-final-form'
import { Timezone } from '../__generated__/graphql'
import TimeZonePicker from './TimeZonePicker'
import TimeZoneLabel from './TimeZoneLabel'

interface Props {
  onChange: (value: Timezone) => void
  value: Timezone
}

const ScheduleTimezoneInput: React.FC<Props> = ({ value, onChange }) => (
  <div>
    Times in{' '}
    <strong>
      <TimeZoneLabel timezone={value} />
    </strong>{' '}
    <PopoverOverlay
      popover={({ close }) => (
        <FinalForm.Form<{ timezone: Timezone }>
          initialValues={{ timezone: value }}
          onSubmit={values => {
            onChange(values.timezone)
            close()
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="ScheduleTimezoneInput">
              <div className="mb-8">
                <FinalForm.Field<Timezone> name="timezone">
                  {({ input, meta }) => (
                    <TimeZonePicker
                      onChange={input.onChange}
                      value={input.value}
                      required
                      isInvalid={meta.invalid}
                    />
                  )}
                </FinalForm.Field>
              </div>
              <Button type="submit" variant="primary" size="sm" block>
                Apply
              </Button>
            </form>
          )}
        </FinalForm.Form>
      )}
    >
      <Button variant="link">Edit</Button>
    </PopoverOverlay>
  </div>
)

export default ScheduleTimezoneInput
