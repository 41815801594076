import * as React from 'react'
import { GroupProps } from 'react-select/src/components/Group'
import { OptionProps } from 'react-select/src/components/Option'
import { MenuProps } from 'react-select/src/components/Menu'
import { ControlProps } from 'react-select/src/components/Control'
import { PlaceholderProps } from 'react-select/src/components/Placeholder'
import { InputProps } from 'react-select/src/components/Input'
import { ValueContainerProps } from 'react-select/src/components/containers'
import { MultiValueGenericProps } from 'react-select/src/components/MultiValue'
import { components as rs } from 'react-select'
import { Media } from 'react-bootstrap'
import Icon from './Icon'
import Select, { OptionsType } from 'react-select'
import { PickerOption, PickerOptionGroup } from './Picker'

export type { PickerOption, PickerOptionGroup } from './Picker'

export const Option: React.FC<OptionProps<PickerOption, true>> = props => (
  <rs.Option className="PickerOption" {...props}>
    <Media className="align-items-center space-between-8">
      {props.data.image}
      <Media.Body className="align-items-center d-flex overflow-hidden">
        <div className="text-truncate">{props.data.label}</div>
      </Media.Body>
      {props.data.rightImage}
    </Media>
  </rs.Option>
)

export const GroupHeading: React.FC<GroupProps<PickerOption, true>> = props => (
  <rs.GroupHeading className="PickerGroupHeading" {...props} />
)

export const Control: React.FC<ControlProps<PickerOption, true>> = ({
  children,
  innerProps,
  innerRef,
  isFocused,
}) => (
  <div
    className={`PickerControl ${isFocused ? 'PickerControl--focused' : ''}`}
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </div>
)

export const Menu: React.FC<MenuProps<PickerOption, true>> = props => (
  <rs.Menu className="PickerMenu" {...props} />
)

export const ValueContainer: React.FC<
  ValueContainerProps<PickerOption, true>
> = props => <rs.ValueContainer className="PickerValueContainer" {...props} />

export const Input: React.FC<InputProps> = props => (
  <div className="PickerInput">
    <div className="d-flex align-items-center">
      <Icon.Search size={20} className="text-muted mr-8" />
      <rs.Input {...props} />
    </div>
  </div>
)

export const Placeholder: React.FC<
  PlaceholderProps<PickerOption, true>
> = props => (
  <rs.Placeholder className="PickerPlaceholder" {...props}>
    {props.children}
  </rs.Placeholder>
)

export const MultiValueContainer: React.FC<
  MultiValueGenericProps<PickerOption>
> = props => <div className="PickerMultiValueContainer">{props.children}</div>

export const MultiValueLabel: React.FC<
  MultiValueGenericProps<PickerOption>
> = props => {
  return (
    <div className="PickerMultiValueLabel">
      {props.data.image && <div className="mr-8">{props.data.image}</div>}
      {props.children}
    </div>
  )
}

export const MultiValueRemove: React.FC<
  MultiValueGenericProps<PickerOption>
> = props => {
  const { className, ...eventHandlers } = props.innerProps
  return (
    <div className="PickerMultiValueRemove" {...eventHandlers}>
      <Icon.X size={16} />
    </div>
  )
}

export interface Props<TValue = any> {
  value: OptionsType<PickerOption<TValue>> | null
  options: PickerOption<TValue>[] | PickerOptionGroup<TValue>[]
  onChange: (option: OptionsType<PickerOption<TValue>>) => void
  required?: boolean
  disabled?: boolean
  loading?: boolean
  invalid?: boolean
  placeholder?: string | React.ReactElement
}

export default function MultiPicker<TValue = any>({
  value,
  options,
  onChange,
  required,
  disabled,
  loading,
  invalid,
  placeholder,
}: Props<TValue>): React.ReactElement {
  return (
    <Select<PickerOption<TValue>, true>
      isMulti
      className="MultiPicker"
      onChange={onChange}
      options={options}
      value={value}
      isClearable={false}
      isDisabled={disabled}
      isLoading={loading}
      placeholder={placeholder}
      blurInputOnSelect
      // menuPosition="fixed"
      components={{
        Control,
        Menu,
        MultiValueContainer,
        MultiValueLabel,
        MultiValueRemove,
        Option,
        Placeholder,
        ValueContainer,
        IndicatorSeparator: null,
      }}
    />
  )
}
