import * as React from 'react'
import { Media } from 'react-bootstrap'
import { ToastProps } from 'react-toastify'
import './Toast.scss'
import Icon from './Icon'

const typeIcons = {
  success: <Icon.InternalAsset assetName={'Green-Check'} size={25} />,
  error: <Icon.InternalAsset assetName={'Red-X'} size={25} />,
  info: null,
  warning: null,
  default: null,
  dark: null,
}

export interface Props extends ToastProps {
  title: string
  description?: string
}

const Toast: React.FC<Props> = ({ closeToast, type, title, description }) => (
  <Media className="Toast align-items-center">
    {type && <div className="mr-16">{typeIcons[type]}</div>}

    <Media.Body>
      <strong className="text-gray-900 d-block">{title}</strong>
      {description && (
        <span className="text-gray-600 d-block mt-4">{description}</span>
      )}
    </Media.Body>

    <Icon.X className="Toast--Close" size={18} onClick={closeToast} />
  </Media>
)

export default Toast
