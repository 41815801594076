import * as React from 'react'
import Icon from '../../../Icon'

export interface ProgressListItemProps {
  readonly style?: Record<string, unknown>
  readonly task: string
  readonly isComplete: boolean
}

export const ProgressListItem: React.FC<ProgressListItemProps> = ({
  style,
  task,
  isComplete,
}: ProgressListItemProps) => {
  return (
    <div
      className="ProgressListItem no-gutter d-flex justify-content-start"
      style={style}
    >
      <Icon.InternalAsset
        assetName={isComplete ? 'ProgressCheckGreen' : 'ProgressCheckGray'}
        size={24}
        className="mr-8"
      />
      <h4 className="text-white">{task}</h4>
    </div>
  )
}
