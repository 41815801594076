import * as React from 'react'

import Icon from './Icon'
import { TeamNode } from '../__generated__/graphql'

enum Sizes {
  xs = '1',
  sm = '1.25',
  md = '2',
  lg = '4',
}

type Props = {
  rounded?: boolean
  schedulingPage: Pick<TeamNode, 'id' | 'image' | 'name'> & {}
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

const SchedulingPageImage: React.FC<Props> = ({
  rounded,
  schedulingPage,
  size,
}) => {
  return (
    <div className="SchedulingPageImage">
      {/* If the schedulingPage has an image render it */}
      {schedulingPage.image ? (
        <img
          alt={schedulingPage.name}
          src={schedulingPage.image}
          style={{
            borderRadius: `${rounded ? '50%' : 'unset'}`,
            height: `${Sizes[size || 'md']}rem`,
            width: `${Sizes[size || 'md']}rem`,
          }}
        />
      ) : (
        // If not is this a personal account?
        // Not a personal schedulingPage and no schedulingPage.image set, fallback
        <Icon.Users size={`${Sizes[size || 'md']}rem`} />
      )}
    </div>
  )
}

export default SchedulingPageImage
