import { env } from '../utils'

export const openUploader = ({ crop }: { crop: string | undefined }) => {
  return (
    // We have access to this property on the window when it is
    // lazy loaded into the DOM via the associated components.
    // ImageInput & ImageButton
    window.uploadcare
      .openDialog(null, null, {
        publicKey: env('UPLOADCARE_PUBLIC_KEY'),
        imagesOnly: true,
        crop: crop,
      })
      .then(result => {
        return result
      })
      // Not sure why this second promise resolution is required, but it is.
      .then(result => {
        return result
      })
  )
}
