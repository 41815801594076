import * as React from 'react'
import {
  ProgressListItem,
  ProgressListItemProps,
} from '../ProgressListItem/ProgressListItem'

export interface ProgressListProps {
  taskList: Array<ProgressListItemProps>
}

export const ProgressList: React.FC<ProgressListProps> = ({
  taskList,
}: ProgressListProps) => {
  return (
    <div className="ProgressList d-flex flex-column align-items-start">
      {taskList.map((taskItem, i) => {
        return (
          <div className="mt-16" key={i}>
            <ProgressListItem
              task={taskItem.task}
              isComplete={taskItem.isComplete}
            />
          </div>
        )
      })}
    </div>
  )
}
