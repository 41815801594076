import * as React from 'react'
import { FormControlProps } from 'react-bootstrap'
import IconTextInput from './IconTextInput'
import Icon from './Icon'

import './SearchInput.scss'

interface Props {
  defaultValue?: string
  placeholder?: string
}

const SearchInput: React.FC<Props & FormControlProps> = props => (
  <div className="SearchInput">
    <IconTextInput
      icon={Icon.Search}
      //@ts-ignore
      placeholder={props.placeholder || 'Search'}
      type="search"
      {...props}
    />
  </div>
)

export default SearchInput
