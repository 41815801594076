export const arrayMove = (
  arr: Array<any>,
  oldIndex: number,
  newIndex: number
) => {
  // Make a copy of the array
  const clone = [...arr]

  // Move the item
  clone.splice(newIndex, 0, clone.splice(oldIndex, 1)[0])

  return clone
}
