import * as React from 'react'
import { Form } from 'react-bootstrap'

import './RadioSelectPanel.scss'
import Panel from './Panel'
import PanelBody from './PanelBody'
import { expandClassName } from '../utils'

export type Props = {
  checked: boolean
  id: string
  name: string
  leftLabel: string | React.ReactNode
  onSelect: (value: boolean) => void
  rightLabel: string | React.ReactNode
}

const RadioSelectPanel: React.FC<Props> = ({
  checked,
  id,
  leftLabel,
  name,
  onSelect,
  rightLabel,
}) => (
  <Panel
    className={expandClassName(
      'RadioSelectPanel !tw-p-6',
      checked ? 'tw-selected' : ''
    )}
    onClick={() => onSelect(checked)}
  >
    <PanelBody className="tw-space-y-3 md:tw-flex-row md:tw-items-center md:tw-space-y-0 md:tw-justify-between">
      <div className="">
        <Form.Check
          checked={checked}
          id={id}
          label={leftLabel}
          name={name}
          onChange={() => onSelect(checked)}
          type="radio"
        />
      </div>
      <div className="tw-items-center tw-flex tw-space-x-3">{rightLabel}</div>
    </PanelBody>
  </Panel>
)

export default RadioSelectPanel
