import * as React from 'react'
import { __EnumValue } from '../../../../__generated__/graphql'
import { OnboardingShell } from '../../molecules/OnboardingShell/OnboardingShell'
import { CreateSchedulingPageView } from '../../views/CreateSchedulingPageView/CreateSchedulingPageView'

export interface CreateSchedulingPageScreenProps {
  readonly email: string
  readonly onSubmitCallback: () => void
  readonly isLoading?: boolean
  readonly timezoneOptionEnums: ReadonlyArray<
    Pick<__EnumValue, 'name' | 'description'>
  >
  readonly isLoadingTimezones?: boolean
}

export const CreateSchedulingPageScreen: React.FC<
  CreateSchedulingPageScreenProps
> = ({
  email,
  onSubmitCallback,
  isLoading,
  timezoneOptionEnums,
  isLoadingTimezones,
}: CreateSchedulingPageScreenProps) => {
  return (
    <OnboardingShell email={email} currentTask="CreateYourSchedulingPage">
      <CreateSchedulingPageView
        onSubmit={onSubmitCallback}
        isLoading={isLoading}
        timezoneOptionEnums={timezoneOptionEnums}
        isLoadingTimezones={isLoadingTimezones}
      />
    </OnboardingShell>
  )
}
