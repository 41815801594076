import * as React from 'react'
import Illustration, { illustrations } from '../../../Illustration'
import { ProgressList } from '../../molecules/ProgressList/ProgressList'
import './SingleUserProgressView.scss'

export interface SingleUserProgressViewProps {
  readonly style?: Record<string, unknown>
  readonly currentTask: OnboardingTask
}

export type OnboardingTask =
  | 'CreateYourSchedulingPage'
  | 'CreateMeetingType'
  | 'ConnectYourCalendar'
  | 'ConfigureYourAvailability'

const taskStrings = {
  CreateYourSchedulingPage: 'Create Your Scheduling Page',
  CreateMeetingType: 'Create Your First Meeting Type',
  ConnectYourCalendar: 'Connect Your Calendar',
  ConfigureYourAvailability: 'Configure Your Availability',
}

const taskIllustrations = {
  CreateYourSchedulingPage: 'waving',
  CreateMeetingType: 'waving',
  ConnectYourCalendar: 'calendar',
  ConfigureYourAvailability: 'calendar',
}

export const SingleUserProgressView: React.FC<SingleUserProgressViewProps> = ({
  style,
  currentTask,
}: SingleUserProgressViewProps) => {
  const taskStringsToUse = Object.values(taskStrings)

  const currentIndex = taskStringsToUse.indexOf(taskStrings[currentTask])

  const taskList = taskStringsToUse.map((task, index) => {
    return {
      task: task,
      isComplete: currentIndex > index,
    }
  })

  const illustration = taskIllustrations[currentTask]

  return (
    <div
      className="ProgressView container d-flex flex-column justify-items-center align-items-center"
      style={style}
    >
      <div className="image">
        <Illustration name={illustration as keyof typeof illustrations} />
      </div>
      <div className="list mt-16">
        <ProgressList taskList={taskList} />
      </div>
    </div>
  )
}
