import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import Icon from './Icon'
import LoadingButton from './LoadingButton'
import Spinner from './Spinner'
import {
  GoToMeetingAccountNode,
  JoinMeAccountNode,
  Maybe,
  UpdateConferenceAccountModalQuery as Response,
  UpdateGoToMeetingAccountInput as GoToMeeting,
  UpdateJoinMeAccountInput as JoinMe,
  UpdateWebexAccountInput as Webex,
  UpdateZoomAccountInput as Zoom,
  useUpdateGoToMeetingMutation,
  useUpdateJoinMeMutation,
  useUpdateWebexMutation,
  useUpdateZoomMutation,
  useUpdateConferenceAccountModalQuery,
  ZoomAccountNode,
} from '../__generated__/graphql'
import {
  ConferenceProvider,
  GoToMeetingConferenceCallInfoOptions,
  ZoomAudioOptions,
  ZoomAutoRecordingOptions,
} from '../types'
import { toast } from '../utils'
import Translate from './Translate'
import Var from './Var'

gql`
  query UpdateConferenceAccountModal($id: ID!) {
    conferencingAccount: getConferencingAccountById(id: $id) {
      id
      name
      ... on JoinMeAccountNode {
        startWithPersonalUrl
      }
      ... on GoToMeetingAccountNode {
        conferenceCallInfo
      }
      ... on ZoomAccountNode {
        audio
        autoRecording
        cnMeeting
        hostVideo
        inMeeting
        joinBeforeHost
        muteUponEntry
        participantVideo
        watermark
      }
    }
  }
`

gql`
  mutation UpdateGoToMeeting($input: UpdateGoToMeetingAccountInput!) {
    account: updateGoToMeetingAccount(input: $input) {
      data {
        id
        name
        ... on GoToMeetingAccountNode {
          conferenceCallInfo
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation UpdateJoinMe($input: UpdateJoinMeAccountInput!) {
    account: updateJoinMeAccount(input: $input) {
      data {
        id
        name
        ... on JoinMeAccountNode {
          startWithPersonalUrl
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation UpdateWebex($input: UpdateWebexAccountInput!) {
    account: updateWebexAccount(input: $input) {
      data {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation UpdateZoom($input: UpdateZoomAccountInput!) {
    account: updateZoomAccount(input: $input) {
      data {
        id
        name
        ... on ZoomAccountNode {
          audio
          autoRecording
          cnMeeting
          hostVideo
          inMeeting
          joinBeforeHost
          muteUponEntry
          participantVideo
          watermark
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

type InternalData =
  | {
      id: string
      name: string
    }
  | Pick<GoToMeetingAccountNode, 'conferenceCallInfo'>
  | Pick<JoinMeAccountNode, 'startWithPersonalUrl'>
  | Pick<
      ZoomAccountNode,
      | 'audio'
      | 'autoRecording'
      | 'cnMeeting'
      | 'hostVideo'
      | 'inMeeting'
      | 'joinBeforeHost'
      | 'muteUponEntry'
      | 'participantVideo'
      | 'watermark'
    >

const wireDataToInternalData = (wire: Response): InternalData => {
  const { __typename, ...account } = wire.conferencingAccount!
  return account
}

export interface Props {
  id: string
  onHide: () => void
  onSuccess: () => void
  provider: Pick<ConferenceProvider, 'name' | 'slug'>
}

// We will get 'id' as a prop on the modal.
// 'name' is the only other required input value
// and is required across all conferencing account providers.
// The other input values are all Translateal (i.e. undefined or null are accepted)
type FormValues = Omit<GoToMeeting, 'id'> &
  Omit<JoinMe, 'id'> &
  Omit<Webex, 'id'> &
  Omit<Zoom, 'id'>

const UpdateConferenceAccountModal: React.FC<Props> = ({
  id,
  onHide,
  onSuccess,
  provider,
}) => {
  // Fetch data for this conferencing account.
  const { data, loading } = useUpdateConferenceAccountModalQuery({
    variables: { id },
  })
  // Parse the data.
  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data) : null),
    [data]
  )
  // Create mutations.
  const [updateGoToMeeting] = useUpdateGoToMeetingMutation()
  const [updateJoinMe] = useUpdateJoinMeMutation()
  const [updateWebex] = useUpdateWebexMutation()
  const [updateZoom] = useUpdateZoomMutation()
  const onSubmit = async (values: FormValues) => {
    try {
      let res
      if (provider.slug === 'gotomeeting') {
        res = await updateGoToMeeting({
          variables: {
            input: {
              id,
              ...values,
            },
          },
        })
      } else if (provider.slug === 'joinme') {
        res = await updateJoinMe({
          variables: {
            input: {
              id,
              ...values,
            },
          },
        })
      } else if (provider.slug === 'webex') {
        res = await updateWebex({
          variables: {
            input: {
              id,
              ...values,
            },
          },
        })
      } else if (provider.slug === 'zoom') {
        res = await updateZoom({
          variables: {
            input: {
              id,
              ...values,
            },
          },
        })
      }

      // If client validation and GraphQL error related errors exist.
      if (res?.data?.account?.errors) {
        console.error(
          `update${provider.name} mutation [UpdateConferenceAccountModal.tsx]`,
          res.data.account.errors
        )
        return
      }
      // If data is present trigger the toast to display and close the modal.
      if (res?.data?.account?.data) {
        toast.success(`Settings Updated`, res.data.account.data.name)
        onSuccess()
        return
      }
    } catch (error) {
      toast.error('Something went wrong')
      console.error(
        `update${provider.name} mutation [UpdateConferenceAccountModal.tsx]`,
        error
      )
    }
  }
  return (
    <Modal
      className="UpdateConferenceAccountModal"
      onHide={onHide}
      show
      size="sm"
    >
      <Modal.Header closeButton>
        <Icon.InternalAsset
          assetName={provider.slug}
          className="tw-mr-3"
          size={36}
        />
        <Modal.Title>
          <Translate>
            <Var name="name">{provider.name}</Var> Settings
          </Translate>
        </Modal.Title>
      </Modal.Header>
      {!loading && internalData ? (
        <FinalForm.Form<FormValues>
          initialValues={internalData}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, submitting }) => (
            <Form
              className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
              onSubmit={handleSubmit}
            >
              <Modal.Body>
                <FinalForm.Field name="name">
                  {({ input }) => (
                    <Form.Group className="tw-mb-4">
                      <Form.Label>
                        <Translate>Account Nickname</Translate>
                      </Form.Label>
                      <Form.Control {...input} />
                    </Form.Group>
                  )}
                </FinalForm.Field>

                <hr />

                {provider.slug === 'gotomeeting' && (
                  <FinalForm.Field<GoToMeetingConferenceCallInfoOptions> name="conferenceCallInfo">
                    {({ input }) => (
                      <Form.Group className="tw-mb-4">
                        <Form.Label className="tw-text-bsGray-600 tw-mb-3 tw-font-medium tw-text-xs tw-uppercase">
                          <Translate>Audio</Translate>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={input.onChange}
                          value={input.value}
                        >
                          <Translate
                            as="option"
                            value={GoToMeetingConferenceCallInfoOptions.Hybrid}
                          >
                            Hybrid
                          </Translate>
                          <Translate
                            as="option"
                            value={GoToMeetingConferenceCallInfoOptions.Private}
                          >
                            Private
                          </Translate>
                          <Translate
                            as="option"
                            value={GoToMeetingConferenceCallInfoOptions.Pstn}
                          >
                            PSTN
                          </Translate>
                          <Translate
                            as="option"
                            value={GoToMeetingConferenceCallInfoOptions.Voip}
                          >
                            VoIP
                          </Translate>
                        </Form.Control>
                      </Form.Group>
                    )}
                  </FinalForm.Field>
                )}

                {provider.slug === 'joinme' && (
                  <FinalForm.Field<boolean>
                    name="startWithPersonalUrl"
                    type="checkbox"
                  >
                    {({ input: { checked, name, onChange } }) => (
                      <Form.Group className="tw-mb-4">
                        <Form.Label className="tw-text-bsGray-600 tw-mb-3 tw-font-medium tw-text-xs tw-uppercase">
                          <Translate>General</Translate>
                        </Form.Label>
                        <Form.Check
                          id="personal-url"
                          label="Use my personal meeting URL instead of creating separate conference rooms for each meeting"
                          name={name}
                          checked={checked}
                          onChange={onChange}
                          type="checkbox"
                        />
                      </Form.Group>
                    )}
                  </FinalForm.Field>
                )}

                {provider.slug === 'zoom' && (
                  <>
                    <FinalForm.Field<boolean>
                      name="joinBeforeHost"
                      type="checkbox"
                    >
                      {({ input: { checked, name, onChange } }) => (
                        <Form.Group className="tw-mb-4">
                          <Form.Label className="tw-text-bsGray-600 tw-mb-3 tw-font-medium tw-text-xs tw-uppercase">
                            <Translate>General</Translate>
                          </Form.Label>
                          <Form.Check
                            id="joinBeforeHost"
                            label="Allow participants to join before host"
                            name={name}
                            checked={checked}
                            onChange={onChange}
                            type="checkbox"
                          />
                        </Form.Group>
                      )}
                    </FinalForm.Field>

                    <hr />

                    <FinalForm.Field<boolean> name="hostVideo" type="checkbox">
                      {({ input: { checked, name, onChange } }) => (
                        <Form.Group className="tw-mb-4">
                          <Form.Label className="tw-text-bsGray-600 tw-mb-3 tw-font-medium tw-text-xs tw-uppercase">
                            <Translate>Video Options</Translate>
                          </Form.Label>
                          <Form.Check
                            id="hostVideo"
                            label="Start meeting when host joins"
                            name={name}
                            checked={checked}
                            onChange={onChange}
                            type="checkbox"
                          />
                        </Form.Group>
                      )}
                    </FinalForm.Field>
                    <FinalForm.Field<boolean>
                      name="participantVideo"
                      type="checkbox"
                    >
                      {({ input: { checked, name, onChange } }) => (
                        <Form.Group className="tw-mb-4">
                          <Form.Check
                            id="participantVideo"
                            label="Start video when participants join"
                            name={name}
                            checked={checked}
                            onChange={onChange}
                            type="checkbox"
                          />
                        </Form.Group>
                      )}
                    </FinalForm.Field>
                    <FinalForm.Field<boolean> name="watermark" type="checkbox">
                      {({ input: { checked, name, onChange } }) => (
                        <Form.Group className="tw-mb-4">
                          <Form.Check
                            id="watermark"
                            label="Apply watermark to video"
                            name={name}
                            checked={checked}
                            onChange={onChange}
                            type="checkbox"
                          />
                        </Form.Group>
                      )}
                    </FinalForm.Field>
                    <FinalForm.Field<ZoomAutoRecordingOptions> name="autoRecording">
                      {({ input }) => (
                        <Form.Group className="tw-mb-4">
                          <Form.Control
                            as="select"
                            onChange={input.onChange}
                            value={input.value}
                          >
                            <Translate
                              as="option"
                              value={ZoomAutoRecordingOptions.None}
                            >
                              Don't record the meeting
                            </Translate>
                            <Translate
                              as="option"
                              value={ZoomAutoRecordingOptions.Local}
                            >
                              Record meeting locally
                            </Translate>
                            <Translate
                              as="option"
                              value={ZoomAutoRecordingOptions.Cloud}
                            >
                              Record meeting to cloud
                            </Translate>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </FinalForm.Field>

                    <hr />

                    <FinalForm.Field<ZoomAudioOptions> name="audio">
                      {({ input }) => (
                        <Form.Group className="tw-mb-4">
                          <Form.Label className="tw-text-bsGray-600 tw-mb-3 tw-font-medium tw-text-xs tw-uppercase">
                            <Translate>Audio Options</Translate>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            onChange={input.onChange}
                            value={input.value}
                          >
                            <Translate
                              as="option"
                              value={ZoomAudioOptions.Both}
                            >
                              Allow both computer & phone
                            </Translate>
                            <Translate
                              as="option"
                              value={ZoomAudioOptions.Voip}
                            >
                              Only computer
                            </Translate>
                            <Translate
                              as="option"
                              value={ZoomAudioOptions.Telephony}
                            >
                              Only phone
                            </Translate>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </FinalForm.Field>

                    <FinalForm.Field<boolean>
                      name="muteUponEntry"
                      type="checkbox"
                    >
                      {({ input: { checked, name, onChange } }) => (
                        <Form.Group className="tw-mb-4">
                          <Form.Check
                            id="muteUponEntry"
                            label="Mute participants when they join"
                            name={name}
                            checked={checked}
                            onChange={onChange}
                            type="checkbox"
                          />
                        </Form.Group>
                      )}
                    </FinalForm.Field>

                    <hr />

                    <FinalForm.Field<boolean> name="cnMeeting" type="checkbox">
                      {({ input: { checked, name, onChange } }) => (
                        <Form.Group className="tw-mb-4">
                          <Form.Label className="tw-text-bsGray-600 tw-mb-3 tw-font-medium tw-text-xs tw-uppercase">
                            <Translate>Hosting</Translate>
                          </Form.Label>
                          <Form.Check
                            id="cnMeeting"
                            label="Host meeting in China"
                            name={name}
                            checked={checked}
                            onChange={onChange}
                            type="checkbox"
                          />
                        </Form.Group>
                      )}
                    </FinalForm.Field>
                    <FinalForm.Field<boolean> name="inMeeting" type="checkbox">
                      {({ input: { checked, name, onChange } }) => (
                        <Form.Group className="tw-mb-4">
                          <Form.Check
                            id="inMeeting"
                            label="Host meeting in India"
                            name={name}
                            checked={checked}
                            onChange={onChange}
                            type="checkbox"
                          />
                        </Form.Group>
                      )}
                    </FinalForm.Field>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <LoadingButton
                  className="tw-flex-1 md:tw-flex-shrink md:tw-flex-grow-0"
                  loading={submitting}
                  type="submit"
                  variant="primary"
                >
                  <Translate>Save Changes</Translate>
                </LoadingButton>
              </Modal.Footer>
            </Form>
          )}
        </FinalForm.Form>
      ) : (
        <div className="tw-flex tw-items-center tw-justify-center">
          <Spinner />
        </div>
      )}
    </Modal>
  )
}

export default UpdateConferenceAccountModal
