import * as React from 'react'
import { toast as t } from 'react-toastify'
import Toast from '../components/Toast'

export const toast = (title: string, description?: string) =>
  t(
    ({ toastProps }) => (
      <Toast {...toastProps} title={title} description={description} />
    ),
    {
      position: 'bottom-center',
      draggable: false,
      closeOnClick: false,
    }
  )

toast.success = (title: string, description?: string) =>
  t.success(
    ({ toastProps }) => (
      <Toast {...toastProps} title={title} description={description} />
    ),
    {
      position: 'bottom-center',
      draggable: false,
      closeOnClick: false,
    }
  )

toast.error = (title: string, description?: string) =>
  t.error(
    ({ toastProps }) => (
      <Toast {...toastProps} title={title} description={description} />
    ),
    {
      position: 'bottom-center',
      draggable: false,
      closeOnClick: false,
    }
  )
