export const copyToClipboard = (str: string): void => {
  const isRTL = document.documentElement.getAttribute('dir') === 'rtl'
  let yPosition = window.pageYOffset || document.documentElement.scrollTop
  const body = document.body
  const input = document.createElement('input')
  body.appendChild(input)
  input.style.border = '0'
  input.style.position = 'absolute'
  input.style[isRTL ? 'right' : 'left'] = '-9999px'
  input.style.top = `${yPosition}px`
  input.setAttribute('readonly', '')
  input.value = str
  input.select()
  document.execCommand('copy')
  body.removeChild(input)
  return
}
