import * as React from 'react'
import Illustration from './Illustration'

type Props = {}

const MeetingsPageEmptyState: React.FC<Props> = () => (
  <div className="mt-64 align-items-center d-flex flex-column justify-content-center space-between-24">
    <Illustration name="holdingClipboard" className="mw-100" />
    <div className="text-center d-flex flex-column space-between-8">
      <h1>This scheduling page doesn't have any meeting types.</h1>
      <h5 className="text-gray-600">
        Create one so that people have something to book with you.
      </h5>
    </div>
  </div>
)

export default MeetingsPageEmptyState
