import * as React from 'react'
import { Media, Button } from 'react-bootstrap'
import Icon from './Icon'
import { Analytics } from '../utils'
import { BillingData } from './SettingsModalBillingSection'
import Translate from './Translate'

interface Props {
  billingData: BillingData
}

const BillingInvoicesSection: React.FC<Props> = ({ billingData }) => {
  return (
    <Media className="tw-flex-col tw-space-y-4 md:tw-flex-row md:tw-items-center md:tw-space-y-0 md:tw-space-x-4">
      <Icon.InternalAsset assetName="Icon-Invoices" size={50} />

      <Media.Body className="tw-flex-col">
        <strong>
          <Translate>Invoices</Translate>
        </strong>
        <p className="tw-text-bsGray-600">
          <Translate>View & download your latest invoices.</Translate>
        </p>
      </Media.Body>

      <Button
        onClick={() => {
          Analytics.trackEvent('Invoices Modal: Opened')

          // TYPESCRIPT: figure out how to add properties to window in Typescript
          // @ts-ignore
          var bb = new window.BillingBot({
            token: billingData.profile.billingbotToken,
          })

          bb.openModal()
        }}
        variant="link"
      >
        <Translate>View Invoices</Translate>
      </Button>
    </Media>
  )
}

export default BillingInvoicesSection
