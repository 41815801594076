import * as React from 'react'
import { sortBy } from 'lodash'

import { TimeBlock, Weekday } from '../types'
import { Maybe } from '../__generated__/graphql'
import { Duration, DateTime, weekdaysToDateTimes, DateTimeList } from '../utils'
import TimeBlockTableCopyMenu from './TimeBlockTableCopyMenu'
import TimeBlockUnit from './TimeBlockUnit'
import Icon from './Icon'
import TimeLabel from './TimeLabel'
import Translate from './Translate'

export interface Props {
  // List of time blocks that are relevant to this weekday or date
  value: TimeBlock[]

  // List of time blocks that should be used for this weekday or date, with other
  // weekdays or dates that should have their availability replaced with this one.
  onChange: (value: TimeBlock[], copyTo: Array<DateTime | Weekday>) => void

  // Date or weekday that is related to these time blocks.  Must be one or the other,
  // never both.
  date: Maybe<DateTime>
  weekday: Maybe<Weekday>

  // List of all dates that have been specified, which we need for the "copy to" feature
  allDates: Maybe<DateTimeList>

  minimumTimeBlockDuration?: Duration
}

const createNewTimeBlock = (
  date: Maybe<DateTime>,
  weekday: Maybe<Weekday>,
  existingTimeBlocks: TimeBlock[],
  minimumTimeBlockDuration?: Duration
): TimeBlock => {
  // Before we create the new time block, find out what the latest endTime is
  // so that we can create something after it.
  const latestEndTime = existingTimeBlocks.reduce<DateTime | null>(
    (acc, { endTime }) => {
      // First pass set the endTime if it exists.
      if (endTime && !acc) return endTime
      // Second pass perform comparison
      if (endTime && acc) {
        return endTime.isBefore(acc) ? acc : endTime
      }
      // Catch all: null
      return acc
    },
    null
  )

  const blockDuration = minimumTimeBlockDuration
    ? minimumTimeBlockDuration
    : new Duration({ hour: 1 })

  return {
    date,
    weekday,
    startTime: latestEndTime
      ? latestEndTime.add({ hour: 1 })
      : new DateTime().startOf('day').add({ hours: 9 }),

    endTime: latestEndTime
      ? latestEndTime.add({ hour: 1 }).add(blockDuration.asMilliseconds())
      : new DateTime().startOf('day').add({ hours: 17 }),
    frequency: null,
  }
}

const TimeBlockTableRow: React.FC<Props> = ({
  value,
  onChange,
  date,
  weekday,
  minimumTimeBlockDuration,
  allDates,
}) => {
  const weekdayMap = React.useMemo(() => weekdaysToDateTimes(), [])
  return (
    <div className="tw-flex tw-border-b tw-border-t-0 tw-border-x-0 tw-border-solid tw-border-black/[.125] tw-relative last:tw-border-b-0">
      <div className="tw-hidden md:tw-flex tw-flex-[0_0_80px] tw-justify-center tw-flex-col tw-items-center tw-font-medium tw-border-l-0 tw-border-r tw-border-y-0 tw-border-solid tw-border-black/[.125]">
        <h6 className="tw-text-bsGray-900">
          <TimeLabel
            format="localized-day-of-week-abbreviated"
            isNaive
            time={date ?? weekdayMap[weekday!]}
          />
        </h6>

        {date && (
          <span className="tw-text-bsGray-800">
            <TimeLabel format="MM/DD" isNaive time={date} />
          </span>
        )}
      </div>

      <div className="tw-flex tw-flex-col tw-w-full">
        <h6 className="tw-mt-4 tw-ml-4 tw-text-bsGray-900 tw-block sm:tw-hidden">
          <TimeLabel
            format="localized-day-of-week-abbreviated"
            isNaive
            time={date ?? weekdayMap[weekday!]}
          />
        </h6>

        <div className="tw-px-4 tw-pt-2 tw-pb-4 tw-flex tw-flex-col tw-flex-grow tw-items-start sm:tw-flex-row sm:tw-items-center sm:tw-pt-4">
          <div className="tw-flex tw-flex-col tw-items-start tw-flex-grow">
            <div className="tw-flex tw-flex-col tw-space-y-3 tw-items-start tw-flex-grow">
              {value.length ? (
                sortBy(value, [tb => tb.startTime.toUnix()]).map(
                  (timeBlock, idx) => (
                    <TimeBlockUnit
                      key={`TimeBlockUnit-${idx}`}
                      value={timeBlock}
                      minimumDuration={minimumTimeBlockDuration}
                      otherBlocksOnSameDay={value.filter(
                        timeBlockToCheck => timeBlockToCheck !== timeBlock
                      )}
                      onChange={(updated: TimeBlock) =>
                        // Look through the list of timeBlocks and swap the updated version in for
                        // the old one.
                        onChange(
                          value.map(tb => (timeBlock === tb ? updated : tb)),
                          []
                        )
                      }
                      onDelete={() =>
                        // Return a list of time blocks omitting the one that was deleted.
                        onChange(
                          value.filter(
                            timeBlockToDelete => timeBlock !== timeBlockToDelete
                          ),
                          []
                        )
                      }
                    />
                  )
                )
              ) : (
                <span className="tw-flex tw-items-center tw-h-10 tw-text-bsGray-600">
                  <Translate>Unavailable</Translate>
                </span>
              )}
            </div>
          </div>

          <div className="tw-flex tw-space-x-4 md:tw-space-x-2 tw-pt-4 tw-text-bsGray-500 sm:tw-pt-0 sm:tw-space-x-4">
            <Icon.Plus
              className="tw-cursor-pointer hover:tw-text-bsGray-600"
              size={24}
              onClick={() =>
                // Add a new timeBlock to the existing ones
                onChange(
                  [
                    ...value,
                    createNewTimeBlock(
                      date,
                      weekday,
                      value,
                      minimumTimeBlockDuration
                    ),
                  ],
                  []
                )
              }
            />

            <div className="tw-cursor-pointer hover:tw-text-bsGray-600">
              <TimeBlockTableCopyMenu
                mode={date ? 'date' : 'weekday'}
                current={date || weekday!}
                possibleDates={allDates}
                onCopyTo={(destinations: Array<DateTime | Weekday>) =>
                  // Pass the existing timeBlocks back with some destinations they should be copied to.
                  onChange(value, destinations)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimeBlockTableRow
