import React from 'react'
import IntercomHandler from '../utils/intercom'

export const useToggleIntercom = () => {
  const [mount, setMount] = React.useState(false)

  React.useEffect(() => {
    if (!mount) {
      setMount(true)
      return
    }
    IntercomHandler.toggleIntercomLauncher(true)

    return () => {
      IntercomHandler.toggleIntercomLauncher(false)
    }
  }, [mount])
  return
}
