import * as React from 'react'

import FormFieldIcon from './FormFieldIcon'
import Panel from './Panel'
import PanelBody from './PanelBody'
import PanelButton from './PanelButton'
import PanelIcon from './PanelIcon'
import PanelTitle from './PanelTitle'
import RemoveButton from './RemoveButton'
import { IFormField } from './UpdateMeetingTypeContext'

type Props = React.HTMLProps<HTMLDivElement> & {
  field: IFormField
  onRemove: (field: IFormField) => void
}

const FormBuilderField: React.FC<Props> = ({
  className,
  field,
  onRemove,
  ...rest
}) => (
  <Panel className="tw-px-6 tw-py-4" {...rest}>
    <PanelIcon className="tw-mr-3">
      <FormFieldIcon
        className="tw-text-bsGray-600"
        fieldType={field.fieldType}
      />
    </PanelIcon>
    <PanelBody>
      <PanelTitle className="tw-text-bsGray-900">{field.name}</PanelTitle>
    </PanelBody>
    <PanelButton>
      <RemoveButton
        onClick={e => {
          // This is required to prevent the onClick event from bubbling
          // up to the parent (<Panel />) which would cause it's `onClick`
          // event to fire as well.
          // https://stackoverflow.com/questions/1369035/how-do-i-prevent-a-parents-onclick-event-from-firing-when-a-child-anchor-is-cli
          e.stopPropagation()
          onRemove(field)
        }}
      />
    </PanelButton>
  </Panel>
)

export default FormBuilderField
