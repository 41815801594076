import * as React from 'react'
import { AttendeeNote } from '../MeetingDetailNoteUnit/MeetingDetailNoteUnit'
import NotesList from '../NotesList/NotesList'
import { NotesTabEmptyState } from '../NotesTabEmptyState'
import { NotesTextInput } from '../NotesTextInput/NotesTextInput'

export interface NotesTabProps {
  readonly notes: ReadonlyArray<AttendeeNote>
  readonly editCallback: (note: AttendeeNote) => void
  readonly deleteCallback: (id: string) => void
  readonly loggedInUserId: string
  readonly onNoteSubmit: (val: string | null) => void
}

const NotesTab: React.FC<NotesTabProps> = ({
  notes,
  editCallback,
  deleteCallback,
  loggedInUserId,
  onNoteSubmit,
}: NotesTabProps) => {
  return (
    <div>
      <NotesTextInput value="" onSubmit={onNoteSubmit} />
      {notes.length > 0 ? (
        <NotesList
          editCallback={editCallback}
          deleteCallback={deleteCallback}
          notes={notes}
          loggedInUserId={loggedInUserId}
        />
      ) : (
        <div className="pt-24">
          <NotesTabEmptyState />
        </div>
      )}
    </div>
  )
}

export default NotesTab
