import * as React from 'react'
import { navigate } from '@reach/router'
import { Media, Navbar, NavDropdown } from 'react-bootstrap'

import './ProfileSwitcher.scss'
import logo from '../assets/logos/pointy-circle.svg'
import CreateProfileModal from './CreateProfileModal'
import Icon from './Icon'
import ProfileContext from './ProfileContext'
import SearchInput from './SearchInput'
import Toggler from './Toggler'
import UserContext from './UserContext'

type Props = {}

const ProfileSwitcher: React.FC<Props> = () => {
  const profile = React.useContext(ProfileContext)
  const { user } = React.useContext(UserContext)

  // State for tracking search.
  const [search, setSearch] = React.useState<string>('')

  // Get a list of all the profiles.
  const profiles = user.members.map(({ profile }) => ({ ...profile }))

  // Filter profiles by the search term,
  const filteredProfiles = profiles.filter(profile =>
    profile.name!.toLowerCase().includes(search.toLowerCase())
  )

  // Is this a new user who needs to go through onboarding
  // or is this an invited member who needs to go through onboarding?
  const isNewUserOrInvitedUser =
    user.needsOnboarding || user.members.length === 0

  return (
    <NavDropdown
      className="ProfileSwitcher no-caret"
      disabled={isNewUserOrInvitedUser}
      id="profile-switcher"
      title={
        <Navbar.Brand className="mr-0">
          <div className="ProfileSwitcher-logo align-items-center d-flex justify-content-center rounded">
            <img alt="Appointlet Logo" src={logo} />
          </div>
        </Navbar.Brand>
      }
    >
      {/*
        Only show the search feature when we have 10+ profiles.
        Read the non-filtered value here!
       */}
      {profiles.length >= 10 && (
        <NavDropdown.Header>
          <SearchInput
            defaultValue={search}
            onChange={event => setSearch(event.currentTarget.value)}
          />
        </NavDropdown.Header>
      )}
      {filteredProfiles.map(({ id, image, name, members, plan }) => (
        <NavDropdown.Item
          as="a"
          // We use the NavDropdownItem as an anchor because this will
          // cause a full re-render of the tree purging stale state & data
          // that can cause problems with incorrect values being read from
          // the cache and used in queries causing the app to break.
          href={`${window.location.origin}/profiles/${id}/meetings/`}
          key={id}
          className="item"
        >
          <Media className="align-items-center">
            {image ? (
              <img alt={`${name} Logo`} className="mr-12" src={image} />
            ) : (
              <Icon.InternalAsset
                className="mr-12"
                assetName="users-avatar"
                size={36}
              />
            )}
            <Media.Body>
              <p>{name}</p>
              <div className="text-gray-600">
                <small>
                  {members.length} members - {plan.name}
                </small>
              </div>
            </Media.Body>
            {id === profile.id && (
              <Icon.Check className="item-check text-green" />
            )}
          </Media>
        </NavDropdown.Item>
      ))}
      {/* If the user is searching & hits no results remove the divider. */}
      {filteredProfiles.length > 0 && <NavDropdown.Divider />}
      <Toggler>
        {({ isToggled, setOff, setOn }) => (
          <React.Fragment>
            <NavDropdown.Item
              className="align-items-center d-flex item"
              onClick={setOn}
            >
              <Media className="align-items-center">
                <Icon.Plus className="mr-12 text-gray-500" />
                Create Organization
              </Media>
            </NavDropdown.Item>

            {isToggled && (
              <CreateProfileModal
                onHide={setOff}
                onSuccess={async id => {
                  setOff()
                  navigate(`/profiles/${id}/meetings`)
                }}
              />
            )}
          </React.Fragment>
        )}
      </Toggler>
    </NavDropdown>
  )
}

export default ProfileSwitcher
