import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import Icon from './Icon'
import LoadingButton from './LoadingButton'
import { PaymentSectionData } from './SettingsModalPaymentsSection'
import { useDeletePaymentCollectionAccountMutation } from '../__generated__/graphql'
import { toast } from '../utils'
import Translate from './Translate'
import Var from './Var'

gql`
  mutation deletePaymentCollectionAccount(
    $input: DeletePaymentCollectionAccountInput!
  ) {
    account: deletePaymentCollectionAccount(input: $input) {
      data {
        deleted
        id
        name
      }
    }
  }
`

type Props = {
  account?: PaymentSectionData['paymentCollectionAccounts'][0]
  onHide: () => void
  onSuccess: () => void
}

const DisconnectPaymentCollectionAccountModal: React.FC<Props> = ({
  account,
  onHide,
  onSuccess,
}) => {
  const [deletePaymentCollectionAccount, { loading }] =
    useDeletePaymentCollectionAccountMutation()
  const onDisconnect = async () => {
    try {
      const { data, errors } = await deletePaymentCollectionAccount({
        variables: { input: { id: account!.id } },
      })
      // If client validation and GraphQL error related errors exist
      if (errors) {
        console.error('updatePaymentCollectionAccount mutation', errors)
        return
      }
      // If data is present trigger the toast to display and close the modal
      if (data?.account?.data) {
        toast.success(`${data.account.data.name} disconnected.`)
        onSuccess()
        return
      }
    } catch (err) {
      toast.error('Something went wrong')
      console.error('updatePaymentCollectionAccount mutation', err)
    }
  }
  return (
    <Modal
      className="DisconnectPaymentCollectionAccountModal"
      onHide={onHide}
      show
      size="sm"
    >
      {account && (
        <>
          <Modal.Header closeButton>
            <Icon.InternalAsset
              assetName={account.provider.slug}
              className="tw-mr-3"
              size={36}
            />
            <Modal.Title>
              <Translate>
                Disconnect <Var name="name">{account.provider.name}</Var>
              </Translate>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="tw-flex tw-flex-col tw-space-y-6">
            <Translate as="p">
              Are you sure you want to disconnect{' '}
              <Var name="name">{account.provider.name}</Var>? Disconnecting will
              have the following impact:
            </Translate>

            <ul className="tw-pl-4 tw-flex tw-flex-col tw-font-medium tw-space-y-2">
              <Translate as="li">
                Any meeting types that were using it to collect payments will no
                longer ask users for payment.
              </Translate>
              <Translate as="li">
                Existing meetings will be unaffected.
              </Translate>
            </ul>
            <Translate as="p">
              If you are trying to switch to another payment provider, connect
              it first.
            </Translate>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              block
              loading={loading}
              onClick={onDisconnect}
              variant="danger"
            >
              <Translate>
                Disconnect <Var name="name">{account.provider.name}</Var>
              </Translate>
            </LoadingButton>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default DisconnectPaymentCollectionAccountModal
