import { AnalyticsEventAttributes } from './analytics'
import { InternalUser } from '../components/UserContext'
import { env } from './env'

const INTERCOM_APP_ID = env('INTERCOM_APP_ID')

const IntercomHandler = {
  initialize: (user: InternalUser) => {
    console.log('IntercomHandler.initialize', user)

    if (!INTERCOM_APP_ID || !window.Intercom) {
      return Promise.resolve(false)
    }

    window.Intercom('boot', {
      app_id: INTERCOM_APP_ID,
      user_id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      created_at: user.createdAt.toUnix(),
      hide_default_launcher: undefined,
    })

    return Promise.resolve(true)
  },

  trackEvent: (
    name: string,
    attributes?: AnalyticsEventAttributes
  ): Promise<boolean> => {
    console.log('IntercomHandler.trackEvent', name, attributes)

    if (!INTERCOM_APP_ID || !window.Intercom) {
      return Promise.resolve(false)
    }

    window.Intercom('trackEvent', name, attributes)

    return Promise.resolve(true)
  },

  pageChanged: (): Promise<boolean> => {
    console.log('IntercomHandler.pageChanged')

    if (!INTERCOM_APP_ID || !window.Intercom) {
      return Promise.resolve(false)
    }

    // Nothing to do here.

    return Promise.resolve(true)
  },

  toggleIntercomLauncher: (toggle: boolean): Promise<boolean> => {
    // Check to see if the current device is defined as mobile
    // NOTE: refer to tailwind.config.js for breakpoints.
    const isMobile = window.innerWidth <= 576
    console.log('IntercomHandler.toggleIntercomLauncher')

    if (!INTERCOM_APP_ID || !window.Intercom || !isMobile) {
      return Promise.resolve(false)
    }

    // This exists to remove the Intercom Launcher when a modal is opened.
    // This is due to the Launcher overlapping buttons in modals on mobile.
    // https://www.intercom.com/help/en/articles/189-turn-off-show-or-hide-the-intercom-messenger
    window.Intercom('update', {
      hide_default_launcher: toggle,
    })

    return Promise.resolve(true)
  },
}

export default IntercomHandler
