import './Portrait.scss'
import * as React from 'react'
import Image from './Image'
import { Maybe } from '../__generated__/graphql'
import { toRem } from '../utils'
import TooltipOverlay from './TooltipOverlay'

// We will only accept data types with the following signature.
export type PortraitData = {
  image: Maybe<string>
  firstName: string
  lastName: string
}

export interface Props extends PortraitData {
  size: number
  hideToolTip?: boolean
}

const Portrait: React.FC<Props> = ({
  image,
  firstName,
  lastName,
  size,
  hideToolTip,
}) => (
  <TooltipOverlay
    id="Portrait"
    text={`${firstName} ${lastName}`}
    disabled={hideToolTip}
  >
    <div
      className="Portrait"
      style={{ height: toRem(size), width: toRem(size) }}
    >
      <div className="Portrait--image">
        {image && (
          <Image
            alt={`Portrait of ${firstName} ${lastName}`}
            src={image}
            height={size}
            width={size}
          />
        )}
      </div>

      <div className="Portrait--initials text-small">
        {firstName[0]}
        {lastName[0]}
      </div>
    </div>
  </TooltipOverlay>
)

export default Portrait
