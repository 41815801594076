import * as React from 'react'

import UserContext from './UserContext'
import MeetingViewingTimezoneContext from './MeetingViewingTimezoneContext'
import { DateTime, presets } from '../utils'

interface Props {
  format: keyof typeof presets
  isNaive?: boolean
  time: DateTime
  timezone?: string | null
}

const TimeLabel: React.FC<Props> = ({ format, isNaive, time, timezone }) => {
  const { user } = React.useContext(UserContext)
  const viewingTimezone = React.useContext(MeetingViewingTimezoneContext)

  /**
   * When `isNaive` is passed we are telling Luxon that we do not care about
   * the timezone. The Date, DateTime, or Time is "naive".
   */
  if (isNaive) {
    return <>{time.format(format)}</>
  }

  return (
    <>
      {time
        .setZone(timezone || viewingTimezone || user.timezone || 'UTC')
        // We **MUST** explicitly set the locale here because Luxon is immutable!
        // We do this because thier is a bug surrouding the format associated with
        // localized full weekdays 'EEEE' in Luxon seems to be broken. All other
        // localized formats work with no issues, but without explicitly setting
        // the locale here the weekday will never render in another locale.
        .setLocale(time.getLocale())
        .format(format)}
    </>
  )
}

export default TimeLabel
