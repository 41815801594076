import GoogleTagManagerHandler from './googleTagManager'
import IntercomHandler from './intercom'
import LocalizeHandler from './localize'
import LogRocketHandler from './logrocket'
import TapfiliateHandler from './tapfiliate'
import AmplitudeHandler from './amplitude'
import { InternalUser } from '../components/UserContext'
import CustomAnalyticsHandler from './customAnalytics'

export interface ExternalService {
  initialize: (user: InternalUser) => Promise<boolean>
  uninitialize?: (user: InternalUser) => Promise<boolean>
}

// Registry of external services we use
const registeredExternalServices: Array<ExternalService> = [
  LocalizeHandler,
  GoogleTagManagerHandler,
  AmplitudeHandler,
  IntercomHandler,
  TapfiliateHandler,
  LogRocketHandler,
  CustomAnalyticsHandler,
]

// Initializes all the 3rd party libraries we use.
export const initializeExternalServices = (
  user: InternalUser
): Promise<unknown> => {
  console.groupCollapsed(
    'initializeExternalServices',
    user,
    registeredExternalServices
  )

  const promise = Promise.all(
    registeredExternalServices.map(service => service.initialize(user))
  )

  console.groupEnd()

  return promise
}
