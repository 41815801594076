/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react'

import { Maybe, MeetingsPageQueryVariables } from '../__generated__/graphql'
import { DateFilterOptions } from '../components/MeetingsPageDateFilter'
import { Member, Profile } from '../types'
import {
  DateTimeRange,
  DateTime,
  decodeQueryParams,
  encodeQueryParams,
  UserLocalSettings,
} from '../utils'
import UserContext from '../components/UserContext'

type Status =
  | 'ALL' // cancelled: true, confirmed: true, pending: true
  | 'CANCELLED' // cancelled: true, confirmed: false, pending: false
  | 'CANCELLED_AND_CONFIRMED' // cancelled: true, confirmed: true, pending: false
  | 'CANCELLED_AND_PENDING' // cancelled: true, confirmed: false, pending: true
  | 'CONFIRMED' // cancelled: false, confirmed: true, pending: false
  | 'CONFIRMED_AND_PENDING' // cancelled: false, confirmed: true, pending: true
  | 'PENDING' // cancelled: false, confirmed: false, pending: true

export type MeetingsPageFilters = {
  // Cursor for pagination.
  after?: string
  // Cursor for pagination.
  before?: string
  // Union type for filtering query by specified date.
  dateFilterOption: DateFilterOptions
  // Number of meetings to show (first segment of list).
  first: number
  // Number of meetings to show (last segment of list).
  last?: number
  // Meeting Types to constrain query to.
  meetingTypes: Array<string>
  // Members to constrain query to.
  members: Array<string>
  // Current profile to query.
  profile: string
  // Search value to constrain query to.
  search: string
  // If 'dateFilterOption' is set to 'Custom' this will represent the user's
  // custom date range to constrain query to.
  range?: DateTimeRange
  // Union type for meetings status to constrain query to.
  status: Status
}

type PossibleQueryParams = {
  dateFilterOption?: DateFilterOptions
  meetingTypes?: string
  members?: string
  search?: string
  range?: string
  status?: Status

  // Deep-linked actions
  action?: string
  id?: string

  // Legacy deep-linked actions
  cancelMeeting?: string
  confirmMeeting?: string
  declineMeeting?: string
  rescheduleMeeting?: string
}

type LaunchAction = {
  action: string
  id: string
}

type MeetingsPageDataHook = (
  member: Pick<Member, 'id'>,
  profile: Pick<Profile, 'id' | 'personal'>
) => MeetingsPageDataHookReturn

export type MeetingsPageDataHookReturn = {
  filters: MeetingsPageFilters
  filtersToVariables: (
    filters: MeetingsPageFilters
  ) => MeetingsPageQueryVariables
  launchAction: Maybe<LaunchAction>
  setFilters: React.Dispatch<React.SetStateAction<MeetingsPageFilters>>
}

export const filtersToQueryString = (filters: MeetingsPageFilters): string => {
  return filters.dateFilterOption === 'CUSTOM' && filters.range
    ? encodeQueryParams({
        dateFilterOption: filters.dateFilterOption,
        meetingTypes: filters.meetingTypes
          ? filters.meetingTypes.join(',')
          : null,
        members:
          // If members filter is cleared (empty array) show all members on the profile.
          // else show the members who were selected in the filters.
          filters.members.length === 0 ? 'all' : filters.members.join(','),
        range: `${filters.range.startDateTime
          .startOf('day')
          .toISO()},${filters.range.endDateTime.startOf('day').toISO()}`,
        search: filters.search,
        status: filters.status,
      })
    : encodeQueryParams({
        dateFilterOption: filters.dateFilterOption,
        meetingTypes: filters.meetingTypes
          ? filters.meetingTypes.join(',')
          : null,
        members:
          // If members filter is cleared (empty array) show all members on the profile.
          // else show the members who were selected in the filters.
          filters.members.length === 0 ? 'all' : filters.members.join(','),
        search: filters.search,
        status: filters.status,
      })
}

const filtersKey = (profileId: string) => `profile:${profileId}:filters`

const localStorageToFilters = (
  settings: UserLocalSettings,
  profileId: string
) => {
  const settingsFilterString = settings.get(filtersKey(profileId))
  const localStorageFilters = settingsFilterString
    ? JSON.parse(settingsFilterString || '')
    : {}

  // check to see if range exists in localStorage, if so, convert to DateTime objects
  const localStorageRange = localStorageFilters.range
    ? {
        range: new DateTimeRange(
          localStorageFilters.range.start as DateTime,
          localStorageFilters.range.end as DateTime
        ),
      }
    : {}
  return {
    ...localStorageFilters,
    ...localStorageRange,
  }
}

export const queryStringToFilters = (qs?: string): MeetingsPageFilters => {
  const params = decodeQueryParams<PossibleQueryParams>(qs)
  const filters = {} as MeetingsPageFilters
  if (params.dateFilterOption === 'CUSTOM' && params.range) {
    const [start, end] = params.range.split(',')
    filters.dateFilterOption = params.dateFilterOption
    filters.range = new DateTimeRange(
      new DateTime(start).startOf('day'),
      new DateTime(end).startOf('day')
    )
  } else if (params.dateFilterOption) {
    filters.dateFilterOption = params.dateFilterOption
  }
  if (params.meetingTypes) {
    filters.meetingTypes = params.meetingTypes.split(',')
  }
  if (params.members) {
    if (params.members === 'all') {
      filters.members = []
    } else {
      filters.members = params.members.split(',')
    }
  }
  if (params.search) {
    filters.search = params.search
  }
  if (params.status) {
    filters.status = params.status
  }
  return filters
}

export const meetingStatusFilter = (
  status: Status
): {
  isCancelled: Maybe<boolean>
  isConfirmed: Maybe<boolean>
  isPending: boolean
} => {
  switch (status) {
    case 'ALL':
      return { isCancelled: null, isConfirmed: null, isPending: true }
    case 'CANCELLED':
      return { isCancelled: true, isConfirmed: false, isPending: false }
    case 'CANCELLED_AND_CONFIRMED':
      return { isCancelled: true, isConfirmed: true, isPending: false }
    case 'CANCELLED_AND_PENDING':
      return { isCancelled: null, isConfirmed: false, isPending: true }
    case 'CONFIRMED':
      return { isCancelled: false, isConfirmed: true, isPending: false }
    case 'CONFIRMED_AND_PENDING':
      return { isCancelled: false, isConfirmed: null, isPending: true }
    case 'PENDING':
      return { isCancelled: false, isConfirmed: false, isPending: true }
    default:
      return { isCancelled: null, isConfirmed: null, isPending: true }
  }
}

export const booleansToStatusFilter = (
  cancelled: boolean,
  confirmed: boolean,
  pending: boolean
): Status | undefined => {
  if (cancelled && confirmed && pending) {
    return 'ALL'
  }
  if (cancelled && !confirmed && !pending) {
    return 'CANCELLED'
  }
  if (cancelled && confirmed && !pending) {
    return 'CANCELLED_AND_CONFIRMED'
  }
  if (cancelled && !confirmed && pending) {
    return 'CANCELLED_AND_PENDING'
  }
  if (!cancelled && confirmed && !pending) {
    return 'CONFIRMED'
  }
  if (!cancelled && confirmed && pending) {
    return 'CONFIRMED_AND_PENDING'
  }
  if (!cancelled && !confirmed && pending) {
    return 'PENDING'
  }
}

// Coerce UI filters to server variables.
export const filtersToVariables = (
  filters: MeetingsPageFilters
): MeetingsPageQueryVariables => {
  const { dateFilterOption, profile: id, range, status, ...rest } = filters
  const { startOnOrAfter, startOnOrBefore } = parseDateFilterOptionForQuery(
    dateFilterOption,
    range
  )
  // Pull these properties out as they are needed for the query.
  const { isCancelled, isConfirmed } = meetingStatusFilter(status)

  return {
    ...rest,
    isCancelled,
    isConfirmed,
    id,
    startOnOrAfter,
    startOnOrBefore,

    // Past should order the opposite direction of the other date filters
    orderBy: dateFilterOption === 'PAST' ? '-start' : 'start',
  }
}

type ParseDateFilterOptionReturn = Pick<
  MeetingsPageQueryVariables,
  'startOnOrAfter' | 'startOnOrBefore'
>

export const parseDateFilterOptionForQuery = (
  option: DateFilterOptions,
  range?: DateTimeRange
): ParseDateFilterOptionReturn => {
  const today = new DateTime()
  switch (option) {
    case 'CUSTOM':
      return {
        startOnOrAfter: range ? range.startDateTime.toISO() : undefined,
        startOnOrBefore: range ? range.endDateTime.toISO() : undefined,
      }
    case 'NEXT-7':
      return {
        startOnOrAfter: today.startOf('day').toISO(),
        // REVIEW: Why 8?
        startOnOrBefore: today.add({ days: 8 }).startOf('day').toISO(),
      }
    case 'NEXT-30':
      return {
        startOnOrAfter: today.startOf('day').toISO(),
        startOnOrBefore: today.add({ days: 31 }).startOf('day').toISO(),
      }
    case 'PAST':
      return {
        startOnOrAfter: null,
        startOnOrBefore: today.startOf('day').toISO(),
      }
    case 'TODAY':
      return {
        startOnOrAfter: today.startOf('day').toISO(),
        startOnOrBefore: today.add({ day: 1 }).startOf('day').toISO(),
      }
    case 'TOMORROW':
      return {
        startOnOrAfter: today.add({ day: 1 }).startOf('day').toISO(),
        startOnOrBefore: today.add({ days: 2 }).startOf('day').toISO(),
      }
    // The default case is going to be 'Upcoming'.
    default:
      return {
        startOnOrAfter: today.startOf('day').toISO(),
        startOnOrBefore: null,
      }
  }
}

export const useMeetingsPageData: MeetingsPageDataHook = (member, profile) => {
  // Desired initialState for app
  // This will render:
  // - the first 25 meetings
  // - that are in a pending & confirmed state
  // - if personal profile do not look at member
  // - else initially load only current member's
  const initialState: MeetingsPageFilters = {
    dateFilterOption: 'UPCOMING',
    first: 25,
    meetingTypes: [],
    members: !profile.personal ? [member.id] : [],
    profile: profile.id,
    search: '',
    status: 'CONFIRMED_AND_PENDING',
  }

  const { settings } = React.useContext(UserContext)
  const lsFilters = localStorageToFilters(settings, profile.id)

  // Look at the URL and pull out any filters to apply/override initialState.
  const qsFilters = queryStringToFilters()

  // Create state for managing the internal state of the page.
  const [filters, setFilters] = React.useState<MeetingsPageFilters>(() => ({
    ...initialState,
    ...lsFilters,
    ...qsFilters,
  }))

  // use effect will watch any changes to filters and will set them in localStorage.
  React.useEffect(() => {
    settings.set(filtersKey(profile.id), JSON.stringify(filters))
  }, [filters, settings, profile.id])

  // Check to see if we got a launch action from the query string.
  const launchAction = React.useMemo<Maybe<LaunchAction>>(() => {
    // Query the params in the URL.
    const params = decodeQueryParams<PossibleQueryParams>()

    // If we got an action and ID, return them.
    if (params.action && params.id) {
      return {
        action: params.action,
        id: params.id,
      }
    }

    // Legacy...
    if (params.confirmMeeting) {
      return {
        action: 'approveMeeting',
        id: params.confirmMeeting,
      }
    } else if (params.cancelMeeting) {
      return {
        action: 'cancelMeeting',
        id: params.cancelMeeting,
      }
    } else if (params.declineMeeting) {
      return {
        action: 'declineMeeting',
        id: params.declineMeeting,
      }
    } else if (params.rescheduleMeeting) {
      return {
        action: 'rescheduleMeeting',
        id: params.rescheduleMeeting,
      }
    }

    return null
  }, [])

  // Here we take the current filters and add them back to the URL.
  React.useEffect(() => {
    const qs = filtersToQueryString(filters)
    // In any event we want to push the queryString of params to the URL.
    window.history.pushState({}, '', `${window.location.pathname}?${qs}`)
  }, [filters])

  return {
    filters,
    filtersToVariables,
    launchAction,
    setFilters,
  }
}
