import { isString } from 'lodash'
import * as React from 'react'
import ReactDOMServer from 'react-dom/server'

import {
  Tour as InternalTour,
  ShepherdOptionsWithType,
  ShepherdButtonWithType,
} from 'react-shepherd'
import Shepherd from 'shepherd.js'

import './Tour.scss'

type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end'

type Step = Readonly<{
  id: string
  selector: string
  placement: Placement
  title: string
  content: React.ReactElement | string
  buttons?: ReadonlyArray<ShepherdButtonWithType>
  offset?: [number, number]
  spotlightPadding?: number
  spotlightBorderRadius?: number
  ignoreTargetClicks?: boolean
}>

// Generates the buttons setting for a given step.
const getButtonsForStep = (stepIndex: number, stepCount: number) => {
  if (stepCount === 1) {
    return [
      {
        text: 'Done',
        type: 'complete',
        classes: 'btn btn-primary',
      },
    ]
  }

  if (stepIndex === 0) {
    return [
      {
        text: 'Next →',
        type: 'next',
        classes: 'btn btn-primary',
      },
    ]
  }

  if (stepIndex === stepCount - 1) {
    return [
      {
        text: '← Back',
        type: 'back',
        classes: 'btn btn-link mr-auto',
      },
      {
        text: 'Done',
        type: 'complete',
        classes: 'btn btn-primary',
      },
    ]
  }

  return [
    {
      text: '← Back',
      type: 'back',
      classes: 'btn btn-link mr-auto',
    },
    {
      text: 'Next →',
      type: 'next',
      classes: 'btn btn-primary',
    },
  ]
}

// Converts our external step object into a Shepherd-compatible
// version.
const convertStepsToShepherdStep = (
  steps: ReadonlyArray<Step>
): ReadonlyArray<ShepherdOptionsWithType> =>
  steps.map((step, idx) => ({
    id: step.id,
    attachTo: {
      element: step.selector,
      on: step.placement,
    },
    title: step.title,
    text: isString(step.content)
      ? step.content
      : // Shepherd only accepts a string of traditial DOM node, so
        // if this is a react element, convert it to a string.
        ReactDOMServer.renderToString(step.content),
    buttons: step.buttons || getButtonsForStep(idx, steps.length),
    cancelIcon: { enabled: true },
    modalOverlayOpeningPadding: step.spotlightPadding || 10,
    modalOverlayOpeningRadius: step.spotlightBorderRadius || 5,
    canClickTarget: !step.ignoreTargetClicks,
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: step.offset || [0, 30],
          },
        },
      ],
    },
  }))

interface Props {
  name: string
  delay?: number
  onCancel: () => void
  onComplete: () => void
  steps: Array<Step>
  tourOptions?: InternalTour.TourOptions
}

const Tour: React.FC<Props> = ({
  name,
  delay,
  onCancel,
  onComplete,
  steps,
  tourOptions,
}) => {
  React.useEffect(() => {
    // Create a tour
    const tour = new Shepherd.Tour({
      tourName: name,
      useModalOverlay: true,
      ...tourOptions,
    })

    // We need to do a little massaging of the step data before we pass it off to
    // the tour itself.  This is primarily to map button.type to an equivalent
    // button.action.
    tour.addSteps(
      convertStepsToShepherdStep(steps).map(step => ({
        ...step,
        buttons: step.buttons?.map(button => ({
          ...button,
          action:
            // There are some TS issues here that seem unresolvable at this time.
            // Inspiration comes from here:
            // https://github.com/shipshapecode/react-shepherd/blob/e945f5b46fd0eb015efd87c54e4cdb53da6492b0/packages/lib/src/index.tsx#L34-L52
            (tour as any)[(button as ShepherdButtonWithType).type!] ||
            button.action,
        })),
      }))
    )

    // Bind up our listeners
    tour.on('cancel', onCancel)
    tour.on('complete', onComplete)

    // Configure a timer and start the tour after that
    const timer = setTimeout(() => tour.start(), delay || 0)

    return () => {
      // Clean up the timer
      clearTimeout(timer)

      // Just in case we haven't closed down the tour.
      tour.cancel()

      // Unbind listeners
      tour.off('cancel', onCancel)
      tour.off('complete', onComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Tour
