import * as React from 'react'
import Picker from './Picker'

import { FormFieldType } from '../types'
import FormFieldIcon from './FormFieldIcon'

export const fieldTypeLabels: { [key in FormFieldType]: string } = {
  [FormFieldType.Text]: 'Text Field',
  [FormFieldType.Multitext]: 'Paragraph Field',
  [FormFieldType.Choice]: 'Choice Field',
  [FormFieldType.Multichoice]: 'Multiple Choice Field',
  [FormFieldType.Toggle]: 'Checkbox Field',
  [FormFieldType.Place]: 'Place Field',
  [FormFieldType.Date]: 'Date Field',
  [FormFieldType.Time]: 'Time Field',
}

export const fieldTypeDescriptions: { [key in FormFieldType]: string } = {
  [FormFieldType.Text]: 'Allows the user to provide a short piece of text.',
  [FormFieldType.Multitext]: 'Allows the user to provide a long piece of text.',
  [FormFieldType.Choice]: 'Allows the user to select one choice from a list.',
  [FormFieldType.Multichoice]:
    'Allows the user to select many choices from a list.',
  [FormFieldType.Toggle]: 'Allows the user to answer yes/no questions.',
  [FormFieldType.Place]: 'Provides a text field with location auto-complete.',
  [FormFieldType.Date]: 'Provides a date picker for the user to choose a date.',
  [FormFieldType.Time]: 'Provides a time picker for the user to choose a time.',
}

const options = (Object.keys(fieldTypeLabels) as FormFieldType[]).map(
  (fieldType: FormFieldType) => ({
    value: fieldType,
    label: fieldTypeLabels[fieldType],
    image: <FormFieldIcon fieldType={fieldType} size={20} />,
  })
)

interface Props {
  value: FormFieldType
  onChange: (fieldType: FormFieldType) => void
}

const FormFieldTypePicker: React.FC<Props> = ({ value, onChange }) => (
  <div className="FormFieldTypePicker">
    <Picker<FormFieldType>
      required
      options={options}
      value={options.find(option => option.value === value) || null}
      onChange={option => onChange(option!.value)}
    />
  </div>
)

export default FormFieldTypePicker
