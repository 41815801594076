import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'
import Illustration from './Illustration'
import LoadingButton from './LoadingButton'
import ProfileContext from './ProfileContext'
import FormErrorSubscription from './FormErrorSubscription'
import {
  AppDocument as Query,
  useInviteMembersModalMutation,
} from '../__generated__/graphql'
import {
  isEmailAddress,
  isRequired,
  composeValidators,
  toast,
  isFormFieldInvalid,
  mutationErrorsToFormErrors,
  Analytics,
} from '../utils'
import { uniq } from 'lodash'
import Translate from './Translate'

gql`
  mutation InviteMembersModal($input: InviteMembersInput!) {
    inviteMembers(input: $input) {
      data {
        id
        deleted
        user {
          email
          firstName
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export type Props = {
  onHide: () => void
  onSuccess: () => void
}

type FormValues = {
  invitees: Array<string>
}

const InviteMembersModal: React.FC<Props> = ({ onHide, onSuccess }) => {
  const profile = React.useContext(ProfileContext)

  const [inviteMembers, { loading }] = useInviteMembersModalMutation({
    awaitRefetchQueries: true,
    // After inviting, refetch the app query
    // TODO: remove this when we have better refetching throughout the app.
    refetchQueries: [{ query: Query }],
  })

  const onSubmit = async (values: FormValues) => {
    const invitees = uniq(values.invitees)

    try {
      // Send to the server
      const res = await inviteMembers({
        variables: {
          input: {
            profile: profile.id,
            invitees: invitees.map(email => ({ email: email })),
          },
        },
      })

      // Return any errors to the form
      if (res.data?.inviteMembers?.errors) {
        return mutationErrorsToFormErrors(res.data.inviteMembers.errors)
      }

      // If data is present trigger the toast to display and close the modal
      if (res.data?.inviteMembers?.data) {
        toast.success(`${res.data.inviteMembers.data.length} invites sent.`)
        onSuccess()

        Analytics.trackEvent('Invited Members', {
          source: 'settings',
          membersInvited: invitees.length,
        })
      }
      // Here we are catching sever related errors.
    } catch (err) {
      toast.error('Something went wrong')
      console.error('inviteMembers mutation', err)
    }
  }

  return (
    <Modal className="InviteMembersModal" onHide={onHide} size="sm" show>
      <Modal.Header className="tw-pb-0 tw-border-b-0" closeButton />

      <FinalForm.Form<FormValues>
        onSubmit={values => {
          // Prevents empty strings from being submitted
          const filteredValues = values.invitees.filter(item => item)
          onSubmit({ invitees: filteredValues })
        }}
        initialValues={{ invitees: [] }}
        // TODO: remove this when we can remove the refetchQueries above.
        keepDirtyOnReinitialize
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="tw-pb-12 tw-px-12 tw-pt-0">
              <div className="tw-flex-col tw-justify-center tw-mb-8 tw-text-center">
                <div className="tw-mb-6">
                  <Illustration className="tw-h-28" name="flyingWithCard" />
                </div>
                <h1 className="tw-mb-1">
                  <Translate>Invite Members</Translate>
                </h1>
                <p className="tw-text-bsGray-600">
                  <Translate>
                    We'll send your invitees an email with instructions on how
                    to sign up and set up their account.
                  </Translate>
                </p>
              </div>

              <Form.Group>
                <FinalForm.Field<FormValues['invitees']>
                  name="invitees"
                  validate={composeValidators<FormValues['invitees']>(
                    items =>
                      !items || items.length === 0
                        ? 'You must enter at least one email.'
                        : undefined,
                    items => {
                      // prevents empty strings from being validated
                      const filteredItems = items!.filter(item => item)
                      return filteredItems!.filter(
                        item =>
                          !composeValidators(isRequired, isEmailAddress)(item)
                      ).length !== filteredItems!.length
                        ? 'Please enter valid email addresses only.'
                        : undefined
                    }
                  )}
                >
                  {({ input, meta }) => (
                    <>
                      <Form.Control
                        {...input}
                        value={input.value.join('\n')}
                        onChange={ev =>
                          input.onChange(
                            (ev.currentTarget.value || '').split(/\r?\n/)
                          )
                        }
                        as="textarea"
                        isInvalid={isFormFieldInvalid(meta)}
                        placeholder="Enter one email address per line."
                        rows={6}
                        autoFocus
                      />
                      <FormErrorSubscription name="invitees" />
                    </>
                  )}
                </FinalForm.Field>
              </Form.Group>

              <LoadingButton loading={loading} type="submit" block>
                <Translate>Invite Members</Translate>
              </LoadingButton>
            </Modal.Body>
          </Form>
        )}
      </FinalForm.Form>
    </Modal>
  )
}

export default InviteMembersModal
