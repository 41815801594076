import * as React from 'react'
import { OnboardingShell } from '../../molecules/OnboardingShell/OnboardingShell'
import { TimeBlock } from '../../../../types'
import { AvailabilityView } from '../../views/AvailabilityView/AvailabilityView'
import { DateTimeRange, Duration } from '../../../../utils'

export interface AvailabilityScreenProps {
  readonly email: string
  readonly timeBlocks: TimeBlock[]
  readonly scheduleBounds: DateTimeRange | null
  readonly minimumTimeBlockDuration: Duration
  readonly skipSectionCallback: () => void
  readonly onSubmitCallback: (timeBlocks: TimeBlock[]) => void
  readonly isLoading?: boolean
  readonly isSingleUser?: boolean
}

export const AvailabilityScreen: React.FC<AvailabilityScreenProps> = ({
  email,
  timeBlocks,
  scheduleBounds,
  minimumTimeBlockDuration,
  skipSectionCallback,
  onSubmitCallback,
  isLoading,
}: AvailabilityScreenProps) => {
  return (
    <OnboardingShell email={email} currentTask="ConfigureYourAvailability">
      <AvailabilityView
        timeBlocks={timeBlocks}
        scheduleBounds={scheduleBounds}
        minimumTimeBlockDuration={minimumTimeBlockDuration}
        skipSectionCallback={skipSectionCallback}
        onSubmitCallback={onSubmitCallback}
        isLoading={isLoading}
      />
    </OnboardingShell>
  )
}
