import * as React from 'react'
import { DropdownButton, ButtonProps } from 'react-bootstrap'
import './MoreButton.scss'
import Icon from './Icon'

interface Props extends Pick<ButtonProps, 'size'> {
  alignRight?: boolean
}

const MoreButton: React.FC<Props> = ({ alignRight, children, size }) => (
  <DropdownButton
    alignRight={!!alignRight}
    className="MoreButton"
    id="more-button"
    variant="outline-secondary"
    title={<Icon.MoreHorizontal size={21} />}
    size={size}
  >
    {children}
  </DropdownButton>
)

export default MoreButton
