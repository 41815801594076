import { sortBy } from 'lodash'
import * as React from 'react'
import { ID } from '../../../../types'
import {
  MeetingDetailAttendeeUnit,
  AttendeeProps,
} from '../MeetingDetailAttendeeUnit/MeetingDetailAttendeeUnit'

export type Props = Readonly<{
  attendees: ReadonlyArray<AttendeeProps & { id: ID }>
  meetingHasConcluded: boolean

  onApprove?: (attendees: Array<ID>) => void
  onDecline?: (attendees: Array<ID>) => void
  onCancel?: (attendees: Array<ID>) => void
  onEdit?: (attendee: ID) => void
}>

// Determines the sort order based on status
const sortMap = {
  pending: 1,
  going: 2,
  'not-going': 3,
}

export const AttendeesTab: React.FC<Props> = ({
  attendees,
  onApprove,
  onDecline,
  onCancel,
  onEdit,
  meetingHasConcluded,
}) => {
  // Start by sorting the attendees
  const sortedAttendees = sortBy(attendees, [
    // Put the host at the front of the list
    attendee => (attendee.isHost ? 0 : 1),

    // Sort by status, if any is provided.
    attendee => (attendee.status ? sortMap[attendee.status] : 0),

    // Finally sort alphabetically
    'firstName',
    'lastName',
  ])

  // Create a list of any attendees that are in a pending status
  const pendingAttendees = sortedAttendees.filter(
    attendee => attendee.status === 'pending'
  )

  return (
    <div className="AttendeesTab">
      {!!pendingAttendees.length && (
        <div className="AttendeesTab--Pending d-flex flex-column space-between-24 mb-24">
          <div className="d-flex justify-content-between">
            <h6>Pending Attendees</h6>

            {!meetingHasConcluded && (
              <>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={ev => {
                    // Make sure we block the default link click
                    ev.preventDefault()

                    // Trigger the onApprove with all pending attendee IDs.
                    onApprove &&
                      onApprove(pendingAttendees.map(attendee => attendee.id))
                  }}
                >
                  <h6 className="text-primary">Approve All</h6>
                </a>
              </>
            )}
          </div>

          <ol className="list-unstyled d-flex flex-column space-between-24">
            {pendingAttendees.map(attendee => (
              <li key={attendee.id}>
                <MeetingDetailAttendeeUnit
                  {...attendee}
                  meetingHasConcluded={meetingHasConcluded}
                  onApprove={() => onApprove && onApprove([attendee.id])}
                  onDecline={() => onDecline && onDecline([attendee.id])}
                  onCancel={() => onCancel && onCancel([attendee.id])}
                  onEdit={() => onEdit && onEdit(attendee.id)}
                />
              </li>
            ))}
          </ol>

          <hr className="mb-0 w-100" />
        </div>
      )}

      <ol className="list-unstyled d-flex flex-column space-between-24">
        {sortedAttendees
          // Make sure we don't include any pending attendees here.
          .filter(attendee => attendee.status !== 'pending')
          .map(attendee => (
            <li key={attendee.id}>
              <MeetingDetailAttendeeUnit
                {...attendee}
                meetingHasConcluded={meetingHasConcluded}
                onApprove={() => onApprove && onApprove([attendee.id])}
                onDecline={() => onDecline && onDecline([attendee.id])}
                onCancel={() => onCancel && onCancel([attendee.id])}
                onEdit={() => onEdit && onEdit(attendee.id)}
              />
            </li>
          ))}
      </ol>
    </div>
  )
}
