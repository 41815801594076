import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

import FormFieldSettings from './FormFieldSettings'
import FormFieldTypePicker from './FormFieldTypePicker'
import FormFieldIcon from './FormFieldIcon'
import FormBuilderFieldPreview from './FormBuilderFieldPreview'
import FormFieldPreFill from './FormFieldPreFill'
import { FormFieldType, FormField } from '../types'
import Translate from './Translate'

export type UnsavedFormField = Pick<
  FormField,
  | 'name'
  | 'helpText'
  | 'choices'
  | 'required'
  | 'visible'
  | 'slug'
  | 'fieldType'
>

export type Props = {
  onHide: () => void
  onSuccess: (formField: UnsavedFormField) => void
}

const CreateFormFieldModal: React.FC<Props> = ({ onHide, onSuccess }) => {
  const onSubmit = (values: UnsavedFormField) => {
    if (
      values.fieldType === FormFieldType.Choice ||
      values.fieldType === FormFieldType.Multichoice
    ) {
      // Remove any white space user may have accidentally injected.
      values = {
        ...values,
        choices: values.choices!.filter(choice => /\S/.test(choice)),
      }
    }
    // If the user marked the field to be a hidden field...
    if (!values.visible) {
      // Overwrite the required value to be false.
      values.required = false
    }
    onSuccess(values)
  }
  return (
    <Modal className="CreateFormFieldModal" onHide={onHide} show size="lg">
      <Modal.Header closeButton>
        <FormFieldIcon
          className="tw-text-bsSuccess"
          fieldType={FormFieldType.Toggle}
          size={30}
        />
        <Modal.Title>
          <Translate>Create Form Field</Translate>
        </Modal.Title>
      </Modal.Header>

      <FinalForm.Form<UnsavedFormField>
        initialValues={{
          choices: ['Choice 1', 'Choice 2', 'Choice 3'],
          fieldType: FormFieldType.Text,
          name: 'Label',
          required: true,
          // For now we just generate a random slug.  At some point maybe we'll
          // let the user customize it.
          slug: Math.random().toString(36).substring(2, 5),
          visible: true,
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, hasValidationErrors, values }) => (
          <Form
            className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
            onSubmit={handleSubmit}
          >
            <Modal.Body>
              <Row className="tw-mb-6">
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label>
                      <Translate>What type of field should this be?</Translate>
                    </Form.Label>

                    <FinalForm.Field<FormFieldType> name="fieldType">
                      {({ input }) => (
                        <FormFieldTypePicker
                          onChange={input.onChange}
                          value={input.value}
                        />
                      )}
                    </FinalForm.Field>
                  </Form.Group>

                  <FormFieldSettings />
                </Col>
                <Col className="tw-hidden lg:tw-block">
                  <div className="tw-flex tw-h-full tw-justify-center tw-relative tw-bg-bsGray-200 tw-p-6 tw-items-center">
                    <Translate
                      as="p"
                      className="tw-text-bsGray-600 tw-absolute tw-top-4 tw-right-4"
                    >
                      Preview
                    </Translate>
                    <FormBuilderFieldPreview field={values as FormField} />
                  </div>
                </Col>
              </Row>
              <FormFieldPreFill isDisabled={hasValidationErrors} />
            </Modal.Body>

            <Modal.Footer className="sm:tw-justify-end">
              <Button
                className="tw-flex-1 sm:tw-flex-initial"
                onClick={onHide}
                variant="outline-secondary"
              >
                Cancel
              </Button>
              <Button className="tw-flex-1 sm:tw-flex-initial" type="submit">
                Create Form Field
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </FinalForm.Form>
    </Modal>
  )
}

export default CreateFormFieldModal
