import { sortBy } from 'lodash'
import {
  DateObjectUnits,
  DateTime as LuxonDateTime,
  DateTimeUnit,
  DiffOptions,
  Duration as LuxonDuration,
  DurationLike,
  DurationLikeObject,
  DurationObjectUnits,
  DurationUnits,
  Interval as LuxonInterval,
  ToRelativeOptions,
  ZoneOptions,
} from 'luxon'
import { InternalUser } from '../components/UserContext'

import { Language, Timezone } from '../__generated__/graphql'
import LocalizeHandler from './localize'
import { translateString } from './translation'

// Custom Type
type ZoneInfo = {
  isInDST: boolean
  isInLeapYear: boolean
  isOffsetFixed: boolean
  offset: number
  offsetNameLong: string
  offsetNameShort: string
  zoneName: string
}

export const presets = {
  'localized-day-of-week-abbreviated': 'EEE', // Mon, Tue
  'localized-full-day-of-week': 'EEEE', // Monday, Tuesday
  'localized-full-day-of-week-plural': "EEEE's'", // Mondays, Tuesdays
  'localized-full-date-with-weekday': 'DDDD', // Monday, February 28, 2022
  'localized-full-date': 'DDD', // February 28, 2022
  'localized-full-month': 'MMMM', // March, marzo
  'localized-medium-date-with-time': 'ff', // Feb 28, 2022 8:30pm
  'localized-medium-date': 'DD', // Feb 28, 2022
  'localized-short-date': 'D', // 2/28/2022
  'localized-time': 't', // 8:30pm
  'MM/DD': 'MM/dd', // 02/28
  'twenty-four-hour-time-with-seconds': 'HH:mm:ss', // 20:30:00
  'two-char-day-of-week': 'EEE', // Mo, Tu
  'YYYY-MM-DD': 'yyyy-MM-dd', // 2022-02-28
}

export type DateTimeList = Array<DateTime>

// This union type represents the timezones that the server does
// not recognize, so when they are encountered they should be
// mapped to the `this.deprecatedTimeZones` property.
type DeprecatedZones =
  | 'America/Indianapolis'
  | 'America/Montreal'
  | 'America/Buenos_Aires'
  | 'Asia/Calcutta'
  | 'Asia/Saigon'

const deprecatedTimeZones: Record<DeprecatedZones, string> = {
  'America/Indianapolis': 'America/Indiana/Indianapolis',
  'America/Montreal': 'America/Toronto',
  'America/Buenos_Aires': 'America/Argentina/Buenos_Aires',
  'Asia/Calcutta': 'Asia/Kolkata',
  'Asia/Saigon': 'Asia/Ho_Chi_Minh',
}

export class DateTime {
  public luxonDateTime: LuxonDateTime

  public constructor(public isoOrDateTime?: Date | LuxonDateTime | string) {
    if (typeof isoOrDateTime === 'string') {
      this.luxonDateTime = LuxonDateTime.fromISO(isoOrDateTime)
    } else if (typeof isoOrDateTime === 'undefined') {
      this.luxonDateTime = LuxonDateTime.fromISO(new Date().toISOString())
    } else if (isoOrDateTime instanceof Date) {
      this.luxonDateTime = LuxonDateTime.fromJSDate(isoOrDateTime)
    } else {
      this.luxonDateTime = isoOrDateTime
    }
  }

  public add(duration: DurationLike | Duration): DateTime {
    if (duration instanceof Duration) {
      return new DateTime(this.luxonDateTime.plus(duration.asMilliseconds()))
    }
    return new DateTime(this.luxonDateTime.plus(duration))
  }

  public diff(dateTime: DateTime): Duration {
    return new Duration(this.luxonDateTime.diff(dateTime.luxonDateTime))
  }

  public endOf(unit: DateTimeUnit): DateTime {
    return new DateTime(this.luxonDateTime.endOf(unit))
  }

  public format(preset: keyof typeof presets): string {
    return preset === 'two-char-day-of-week'
      ? this.luxonDateTime.toFormat(presets[preset]).substring(0, 2)
      : this.luxonDateTime.toFormat(presets[preset])
  }

  public fromNow(unit?: DurationUnits, opts?: DiffOptions): Duration {
    return new Duration(this.luxonDateTime.diffNow(unit, opts))
  }

  public get(unit: keyof LuxonDateTime): number {
    return this.luxonDateTime.get(unit)
  }

  public getLocale(): string {
    return this.luxonDateTime.locale
  }

  public getZoneInfo(): ZoneInfo {
    return {
      isInDST: this.luxonDateTime.isInDST,
      isInLeapYear: this.luxonDateTime.isInLeapYear,
      isOffsetFixed: this.luxonDateTime.isOffsetFixed,
      offset: this.luxonDateTime.offset,
      offsetNameLong: this.luxonDateTime.offsetNameLong,
      offsetNameShort: this.luxonDateTime.offsetNameShort,
      zoneName: this.luxonDateTime.zoneName,
    }
  }

  public getTimeZoneName(): string {
    return this.luxonDateTime.zoneName
  }

  public getTimeZoneEnum(): Timezone {
    const { zoneName } = this.luxonDateTime

    // NOTE Why?
    // This ensures we do not send the server timezones it does not recognize.
    // See note attached to `deprecatedTimeZones`.
    const zone = deprecatedTimeZones[zoneName as DeprecatedZones] || zoneName

    return zone.replace(/\//g, '_').toUpperCase() as Timezone
  }

  public isAfter(dateTime: DateTime): boolean {
    return this.luxonDateTime > dateTime.luxonDateTime
  }

  public isBefore(dateTime: DateTime): boolean {
    return this.luxonDateTime < dateTime.luxonDateTime
  }

  public isBetween(range: DateTimeRange): boolean {
    return range.isBetween(new DateTime(this.luxonDateTime))
  }

  public isSame(dateTime: DateTime): boolean {
    return this.toUnix() === dateTime.toUnix()
  }

  public isSameTimeZoneOffset(datetime: DateTime): boolean {
    const offsetA = this.luxonDateTime.get('offset')
    const offsetB = datetime.get('offset')

    return offsetA === offsetB
  }

  public now(): DateTime {
    return new DateTime(LuxonDateTime.now())
  }

  public set(values: DateObjectUnits): DateTime {
    return new DateTime(this.luxonDateTime.set(values))
  }

  public setLocale(locale: Language | string): DateTime {
    return new DateTime(this.luxonDateTime.setLocale(locale.toLowerCase()))
  }

  public setZone(zone: string, opts?: ZoneOptions): DateTime {
    const splitZone = zone.split('_')

    // NOTE:
    // We need to format the zone from the server format to what
    // Luxon expects. That is not the same as formatTimezone/1
    // near the top of the file.
    // Server: REGION_CITY
    // Client: Region/Country|State/City_Name_Here
    const formattedZone = splitZone
      .reduce((acc, word, i) => {
        const [first, ...rest] = word.split('')
        if (i === 0) return [...acc, `${first}${rest.join('').toLowerCase()}/`]
        return [
          ...acc,
          `${first}${rest.join('').toLowerCase()}${
            i !== splitZone.length - 1 ? '_' : ''
          }`,
        ]
      }, [] as Array<string>)
      .join('')
      .trim()

    // NOTE Why?
    // Formatting timezones from the server format to the expected
    // format by Luxon is pretty much impossible outside of defining
    // a map like below.
    const oddBalls: Record<string, string> = {
      'Africa/Porto_Novo': 'Africa/Porto-Novo',
      'America/Argentina_Buenos_Aires': 'America/Argentina/Buenos_Aires',
      'America/Argentina_Catamarca': 'America/Argentina/Catamarca',
      'America/Argentina_Cordoba': 'America/Argentina/Cordoba',
      'America/Argentina_Jujuy': 'America/Argentina/Jujuy',
      'America/Argentina_La_Rioja': 'America/Argentina/La_Rioja',
      'America/Argentina_Mendoza': 'America/Argentina/Mendoza',
      'America/Argentina_Rio_Gallegos': 'America/Argentina/Rio_Gallegos',
      'America/Argentina_Salta': 'America/Argentina/Salta',
      'America/Argentina_San_Juan': 'America/Argentina/San_Juan',
      'America/Argentina_San_Luis': 'America/Argentina/San_Luis',
      'America/Argentina_Tucuman': 'America/Argentina/Tucuman',
      'America/Argentina_Ushuaia': 'America/Argentina/Ushuaia',
      'America/Blanc_Sablon': 'America/Blanc-Sablon',
      'America/Indiana_Indianapolis': 'America/Indiana/Indianapolis',
      'America/Indiana_Knox': 'America/Indiana/Knox',
      'America/Indiana_Marengo': 'America/Indiana/Marengo',
      'America/Indiana_Petersburg': 'America/Indiana/Petersburg',
      'America/Indiana_Tell_City': 'America/Indiana/Tell_City',
      'America/Indiana_Vevay': 'America/Indiana/Vevay',
      'America/Indiana_Vincennes': 'America/Indiana/Vincennes',
      'America/Indiana_Winamac': 'America/Indiana/Winamac',
      'America/Kentucky_Louisville': 'America/Kentucky/Louisville',
      'America/Kentucky_Monticello': 'America/Kentucky/Monticello',
      'America/North_Dakota_Beulah': 'America/North_Dakota/Beulah',
      'America/North_Dakota_Center': 'America/North_Dakota/Center',
      'America/North_Dakota_New_Salem': 'America/North_Dakota/New_Salem',
      'America/Port_Au_Prince': 'America/Port-au-Prince',
      'Asia/Ust_Nera': 'Asia/Ust-Nera',
      'Gmt/': 'GMT',
      'Utc/': 'UTC',
    }

    return new DateTime(
      this.luxonDateTime.setZone(oddBalls[formattedZone] || formattedZone, opts)
    )
  }

  public startOf(unit: DateTimeUnit): DateTime {
    return new DateTime(this.luxonDateTime.startOf(unit))
  }

  public subtract(duration: DurationLike): DateTime {
    return new DateTime(this.luxonDateTime.minus(duration))
  }

  public toDate(): Date {
    return this.luxonDateTime.toJSDate()
  }

  public toISO(): string {
    return this.luxonDateTime.toISO()
  }

  public toRelative(options?: ToRelativeOptions): string | null {
    return this.luxonDateTime.toRelative(options)
  }

  public toUnix(): number {
    return this.luxonDateTime.toUnixInteger()
  }

  public toUTC(): string {
    return this.luxonDateTime.setZone('utc').toISO()
  }
}

export class DateTimeRange {
  public endDateTime: DateTime
  public startDateTime: DateTime
  private interval: LuxonInterval

  public constructor(
    public start?: Date | DateTime | string,
    public end?: Date | DateTime | string
  ) {
    let e: LuxonDateTime | undefined, s: LuxonDateTime | undefined
    if (end instanceof Date || start instanceof Date) {
      e = LuxonDateTime.fromJSDate(end as Date)
      s = LuxonDateTime.fromJSDate(start as Date)
    } else if (end instanceof DateTime || start instanceof DateTime) {
      e = (end as DateTime).luxonDateTime
      s = (start as DateTime).luxonDateTime
    } else if (typeof end === 'string' || typeof start === 'string') {
      e = LuxonDateTime.fromISO(end as string)
      s = LuxonDateTime.fromISO(start as string)
    } else {
      e = LuxonDateTime.fromJSDate(new Date())
      s = LuxonDateTime.fromJSDate(new Date())
    }

    this.interval = LuxonInterval.fromDateTimes(s, e)
    this.endDateTime = new DateTime(e)
    this.startDateTime = new DateTime(s)
  }

  public createDateTimeList(duration: DurationLike): DateTimeList {
    return this.interval
      .splitBy(duration)
      .map(({ start }) => new DateTime(start))
  }

  // Checks to see if this range intersects with another.
  public overlapsWith(checkRange: DateTimeRange): boolean {
    return (
      this.startDateTime.toUnix() < checkRange.endDateTime.toUnix() &&
      checkRange.startDateTime.toUnix() < this.endDateTime.toUnix()
    )
  }

  /**
   * isBetween is inclusive to both start & end.
   * ex: dtr.isBetween('2022-01-01T22:00:00', '2022-12-31T22:00:00')
   *    2022-01-01T22:00:00 will evaluate to true.
   *    2022-12-31T22:00:00 will evaluate to true.
   *
   * We are doing this to mimic the behavior of the `contains` function
   * in 'moment-range' which is inclusive on both ends of the range;
   * where as Luxon is inclusive to the start.
   */
  public isBetween(dateTime: Date | DateTime | string): boolean {
    // NOTE: Why?
    // We need to create an inclusive interval & because this.interval
    // is immutable we need to create a new interval using the existing
    // this.interval[end|start].
    const inclusiveInterval = LuxonInterval.fromDateTimes(
      this.interval.start,
      this.interval.end.plus({ second: 1 }) // Make end inclusive.
    )
    if (dateTime instanceof Date) {
      return inclusiveInterval.contains(
        LuxonDateTime.fromISO((dateTime as Date).toISOString())
      )
    } else if (dateTime instanceof DateTime) {
      return inclusiveInterval.contains(
        LuxonDateTime.fromISO((dateTime as DateTime).toISO())
      )
    } else {
      return inclusiveInterval.contains(
        LuxonDateTime.fromISO(dateTime as string)
      )
    }
  }
}

export class Duration {
  private luxonDuration: LuxonDuration

  public constructor(public arg?: number | DurationLikeObject | LuxonDuration) {
    if (typeof arg === 'number') {
      this.luxonDuration = LuxonDuration.fromMillis(arg * 1000)
    } else if (!(arg instanceof LuxonDuration)) {
      this.luxonDuration = LuxonDuration.fromObject(
        typeof arg === 'undefined' ? {} : arg
      )
    } else {
      this.luxonDuration = arg
    }
  }

  public add(duration: DurationLike): Duration {
    return new Duration(this.luxonDuration.plus(duration))
  }
  public asDays(round: boolean = true): number {
    const days = this.luxonDuration.as('days')
    return round ? Math.floor(days) : days
  }
  public asHours(round: boolean = true): number {
    const hours = this.luxonDuration.as('hours')
    return round ? Math.floor(hours) : hours
  }
  public asMilliseconds(round: boolean = true): number {
    const millis = this.luxonDuration.toMillis()
    return round ? Math.floor(millis) : millis
  }
  public asMinutes(round: boolean = true): number {
    const minutes = this.luxonDuration.as('minutes')
    return round ? Math.floor(minutes) : minutes
  }
  public asSeconds(round: boolean = true): number {
    const seconds = this.luxonDuration.as('seconds')
    return round ? Math.floor(seconds) : seconds
  }
  public get(unit: keyof DurationLikeObject): number {
    return this.luxonDuration.get(unit)
  }
  public isValid(): boolean {
    return this.luxonDuration.isValid
  }
  public set(values: DateObjectUnits): Duration {
    return new Duration(this.luxonDuration.set(values))
  }
  public shiftTo(units: keyof DurationLikeObject): Duration {
    return new Duration(this.luxonDuration.shiftTo(units))
  }
  public subtract(duration: DurationLike): Duration {
    return new Duration(this.luxonDuration.minus(duration))
  }

  public toHuman(): string {
    const units = this.toUnits()
    const pieces = []

    // Check if there are days
    if (units.days && units.days > 0) {
      pieces.push(
        `${units.days} ${
          units.days > 1 ? translateString('days') : translateString('day')
        }`
      )
    }

    // Check if there are hours
    if (units.hours && units.hours > 0) {
      pieces.push(
        `${units.hours} ${
          units.hours > 1 ? translateString('hours') : translateString('hour')
        }`
      )
    }

    // Check if there are minutes
    if (units.minutes && units.minutes > 0) {
      pieces.push(
        `${units.minutes} ${
          units.minutes > 1
            ? translateString('minutes')
            : translateString('minute')
        }`
      )
    }

    return pieces.join(', ')
  }

  public toUnits(): DurationObjectUnits {
    return this.luxonDuration.shiftTo('days', 'hours', 'minutes').toObject()
  }
}

export const applyUserLocale = (user: InternalUser) => {
  return LocalizeHandler.initialize(user)
}

export const currentWeekDateRange = (): DateTimeRange => {
  const dt = new DateTime(
    LuxonDateTime.fromObject({
      weekNumber: LuxonDateTime.fromJSDate(new Date()).get('weekNumber'),
      weekYear: LuxonDateTime.fromJSDate(new Date()).get('weekYear'),
    })
  )

  return new DateTimeRange(dt.startOf('week'), dt.endOf('week'))
}

// Helper function to check a list of time ranges to see if any
// overlap with each other.
export const timeRangesOverlap = (
  timeRanges: ReadonlyArray<DateTimeRange>
): boolean =>
  // First we sort the ranges from earliest to latest and then we'll compare
  // each one to it's previous neighbor to see if it overlaps in some way.
  sortBy(timeRanges, [tr => tr.startDateTime.toUnix()]).some(
    // Compare time ranges with the previous one in the list to see
    // if it overlaps.
    (tr, idx, allTimeRanges) =>
      idx > 0 && allTimeRanges[idx - 1].overlapsWith(tr)
  )
