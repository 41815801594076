import * as React from 'react'
import { Media } from 'react-bootstrap'
import { gql } from '@apollo/client'

import './PaymentProviderRow.scss'
import DropdownItem from './DropdownItem'
import DisconnectPaymentCollectionAccountModal from './DisconnectPaymentCollectionAccountModal'
import Icon from './Icon'
import LoadingButton from './LoadingButton'
import MemberContext from './MemberContext'
import MoreButton from './MoreButton'
import ProfileContext from './ProfileContext'
import { PaymentSectionData } from './SettingsModalPaymentsSection'
import Spinner from './Spinner'
import Toggler from './Toggler'
import {
  useCreatePaymentCollectionAccountMutation,
  usePaymentCollectionAccountIsConnectedLazyQuery,
  useReconnectPaymentCollectionAccountMutation,
} from '../__generated__/graphql'
import { expandClassName, startOAuthFlow, WindowClosed, toast } from '../utils'

gql`
  query PaymentCollectionAccountIsConnected($id: ID!) {
    account: getPaymentCollectionAccountById(id: $id) {
      id
      isConnected
    }
  }
`

gql`
  mutation CreatePaymentCollectionAccount(
    $input: CreatePaymentCollectionAccountInput!
  ) {
    account: createPaymentCollectionAccount(input: $input) {
      data {
        createdAt
        deleted
        id
        name
        profile {
          id
        }
        provider {
          id
          name
          slug
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation ReconnectPaymentCollectionAccount(
    $input: UpdatePaymentCollectionAccountInput!
  ) {
    account: updatePaymentCollectionAccount(input: $input) {
      data {
        id
        isConnected
      }
      errors {
        field
        messages
      }
    }
  }
`

type Props = {
  account?: PaymentSectionData['paymentCollectionAccounts'][0]
  onConnect: () => void
  provider: PaymentSectionData['providers'][0]
}

const PaymentProviderRow: React.FC<Props> = ({
  account,
  onConnect: didConnect,
  provider,
}) => {
  const member = React.useContext(MemberContext)
  const profile = React.useContext(ProfileContext)
  // Tracks the state that we're in.
  const [state, setState] = React.useState<
    | 'unconnected'
    | 'loading'
    | 'connected'
    | 'disconnected'
    | 'reconnecting'
    | 'connecting'
  >(account ? 'loading' : 'unconnected')

  const [fetchStatus, { refetch: refetchStatus }] =
    usePaymentCollectionAccountIsConnectedLazyQuery({
      onCompleted: data => {
        setState(data.account?.isConnected ? 'connected' : 'disconnected')
      },
    })
  const [createAccount] = useCreatePaymentCollectionAccountMutation()
  const [reconnectAccount] = useReconnectPaymentCollectionAccountMutation()

  // When we first render, check to see if we have an account and then get it's status
  // if we do.
  React.useEffect(() => {
    if (account) {
      fetchStatus({ variables: { id: account.id } })
    } else {
      setState('unconnected')
    }
  }, [account, fetchStatus])

  // Attempts to create a new conferencing account
  const onConnect = async () => {
    // Transition to connecting state
    setState('connecting')

    try {
      // Run the OAuth flow to get an OAuth2 code
      const code = await startOAuthFlow(provider.oauth2AuthorizationUrl, {
        width: 1000,
        height: 800,
      })

      // Take the code and give it back to the server
      const res = await createAccount({
        variables: {
          input: {
            oauth2Code: code,
            profile: profile.id,
            provider: provider.id,
          },
        },
      })
      // If client validation and GraphQL error related errors exist
      if (res.data?.account?.errors) {
        console.error(
          'createPaymentCollectionAccount mutation',
          res.data.account.errors
        )

        throw new Error('Failed to connect')
      }
      // If data is present trigger the toast to display and close the modal
      if (res.data?.account?.data) {
        toast.success(`Connected ${provider.name} account!`)

        // Transition to connected state
        setState('connected')

        // Let caller know
        didConnect()
      }
      // Here we are catching sever related errors.
    } catch (err) {
      // Transition back to unconnected state
      setState('unconnected')

      if (err === WindowClosed) return

      console.error('createPaymentCollectionAccount mutation', err)

      // Notify user
      toast.error(`Failed to connect ${provider.name} account`)
    }
  }

  const onReconnect = async () => {
    setState('reconnecting')

    try {
      // Run the OAuth flow to get an OAuth2 code
      const code = await startOAuthFlow(
        account!.provider.oauth2AuthorizationUrl,
        { width: 1000, height: 800 }
      )

      // Take the code and give it back to the server
      const { data, errors } = await reconnectAccount({
        variables: {
          input: { id: account!.id, name: account!.name, oauth2Code: code },
        },
      })

      // Refetch the status
      refetchStatus!({ id: account!.id })

      // If client validation and GraphQL error related errors exist
      if (errors) {
        console.error('updatePaymentCollectionAccount mutation', errors)
        throw new Error('Failed to reconnect')
      }
      // If data is present trigger the toast to display and close the modal
      if (data) {
        toast.success(`Reconnected ${account!.name} successfully`)
        setState('connected')
      }
      // Here we are catching sever related errors.
    } catch (err) {
      setState('disconnected')

      if (err === WindowClosed) return

      toast.error(`Failed to reconnect ${account!.name}`)
      console.error('updatePaymentCollectionAccount mutation', err)
    }
  }

  return (
    <Media className="align-items-center space-between-12">
      <Icon.InternalAsset assetName={provider.slug} size={40} />
      <Media.Body className="d-flex flex-column">
        <strong
          className={expandClassName(
            'mb-2',
            state === 'disconnected' ? 'text-danger' : undefined
          )}
        >
          {provider.name}
        </strong>
        <p
          className={state === 'disconnected' ? 'text-danger' : 'text-gray-600'}
        >
          Allow your users to pay with their {provider.name} balance or a
          credit/debit card.
        </p>
      </Media.Body>

      {state === 'loading' && (
        <div className="mr-32">
          <Spinner size={20} />
        </div>
      )}

      {state === 'connected' && (
        <MoreButton alignRight size="sm">
          <Toggler>
            {({ isToggled, setOff, setOn }) => (
              <React.Fragment>
                <DropdownItem
                  disabled={!member.role.canManageOrgSettings}
                  onClick={setOn}
                >
                  <Icon.Trash2 size={20} />
                  Disconnect
                </DropdownItem>
                {isToggled && account && (
                  <DisconnectPaymentCollectionAccountModal
                    account={account!}
                    onHide={setOff}
                    onSuccess={setOff}
                  />
                )}
              </React.Fragment>
            )}
          </Toggler>
        </MoreButton>
      )}

      {(state === 'reconnecting' || state === 'disconnected') && (
        <LoadingButton
          disabled={
            !member.role.canManageOrgSettings ||
            !profile.plan.allowPaymentCollection
          }
          loading={state === 'reconnecting'}
          onClick={onReconnect}
          variant="danger"
        >
          Reconnect
        </LoadingButton>
      )}

      {(state === 'unconnected' || state === 'connecting') && (
        <LoadingButton
          disabled={
            !member.role.canManageOrgSettings ||
            !profile.plan.allowPaymentCollection
          }
          loading={state === 'connecting'}
          onClick={onConnect}
          variant="success"
        >
          Connect
        </LoadingButton>
      )}
    </Media>
  )
}

export default PaymentProviderRow
