import { Weekday, TimeBlock } from '../types'
import { currentWeekDateRange, DateTime } from '../utils'
import { DateTimeRange } from './datetime'

export const weekdaysToDateTimes = (): Record<Weekday, DateTime> => {
  const weekdayDateTimes = currentWeekDateRange().createDateTimeList({
    days: 1,
  })

  const weekdayMap = {
    [Weekday.Monday]: weekdayDateTimes[0],
    [Weekday.Tuesday]: weekdayDateTimes[1],
    [Weekday.Wednesday]: weekdayDateTimes[2],
    [Weekday.Thursday]: weekdayDateTimes[3],
    [Weekday.Friday]: weekdayDateTimes[4],
    [Weekday.Saturday]: weekdayDateTimes[5],
    [Weekday.Sunday]: weekdayDateTimes[6],
  }

  return weekdayMap
}

export const isoWeekdayToWeekday = (i: number) => {
  const arr = [
    Weekday.Monday,
    Weekday.Tuesday,
    Weekday.Wednesday,
    Weekday.Thursday,
    Weekday.Friday,
    Weekday.Saturday,
    Weekday.Sunday,
  ]
  return arr[i]
}

export const getDefaultRecurringTimeBlocks = (): TimeBlock[] =>
  [
    Weekday.Monday,
    Weekday.Tuesday,
    Weekday.Wednesday,
    Weekday.Thursday,
    Weekday.Friday,
  ].map<TimeBlock>(weekday => ({
    startTime: new DateTime().startOf('day').add({ hours: 9 }),
    endTime: new DateTime().startOf('day').add({ hours: 17 }),
    weekday,
    frequency: null,
    date: null,
  }))

export const getDefaultFixedTimeBlocks = (): TimeBlock[] => {
  return new DateTimeRange()
    .createDateTimeList({ days: 1 })
    .map<TimeBlock>(dateTime => ({
      startTime: dateTime.startOf('day').add({ hours: 9 }),
      endTime: dateTime.startOf('day').add({ hours: 17 }),
      date: dateTime.startOf('day'),
      frequency: null,
      weekday: null,
    }))
}
