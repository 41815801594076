export const expandClassName = (
  className: string,
  otherClassNames?: string | Array<any>
): string => {
  if (!otherClassNames) {
    return className
  }
  if (typeof otherClassNames === 'string') {
    return `${className} ${otherClassNames}`.trim()
  }
  return `${className} ${otherClassNames.reduce((acc, className) => {
    return `${acc} ${className}`
  }, '')}`.trim()
}
