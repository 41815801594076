import * as React from 'react'
import { FormControl } from 'react-bootstrap'

import CopyButton from './CopyButton'

type Props = {
  rows?: number
  text: string
}

const CopyBox: React.FC<Props> = ({ text, rows }) => {
  return (
    <div className="tw-relative">
      <FormControl
        as="textarea"
        className="disabled:tw-bg-white read-only:tw-bg-white tw-resize-none"
        readOnly
        value={text}
        rows={rows || 4}
      />

      <div className="tw-absolute tw-right-3 tw-top-3">
        <CopyButton text={text} />
      </div>
    </div>
  )
}

export default CopyBox
