import * as React from 'react'
import Icon from './Icon'
import { FormFieldType } from '../types'

interface Props {
  className?: string
  fieldType: FormFieldType
  size?: number
}

const FormFieldIcon: React.FC<Props> = ({ className, fieldType, size }) => {
  switch (fieldType) {
    case FormFieldType.Choice:
      return <Icon.Minus className={className} size={size} />
    case FormFieldType.Date:
      return <Icon.Calendar className={className} size={size} />
    case FormFieldType.Multichoice:
      return <Icon.List className={className} size={size} />
    case FormFieldType.Multitext:
      return <Icon.AlignLeft className={className} size={size} />
    case FormFieldType.Place:
      return <Icon.MapPin className={className} size={size} />
    case FormFieldType.Text:
      return <Icon.Edit3 className={className} size={size} />
    case FormFieldType.Time:
      return <Icon.Clock className={className} size={size} />
    case FormFieldType.Toggle:
      return <Icon.CheckSquare className={className} size={size} />
  }

  // TODO: choose a default
  return null
}

export default FormFieldIcon
