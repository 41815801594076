import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'
import './OnboardingPages.scss'
import LoadingButton from './LoadingButton'
import { useUpdateMeetingCalendarModalMutation } from '../__generated__/graphql'
import { ID } from '../types'
import { toast } from '../utils'
import MeetingCalendarPicker from './MeetingCalendarPicker'
import Translate from './Translate'

gql`
  mutation UpdateMeetingCalendarModal($input: SetDefaultCalendarInput!) {
    setDefaultCalendar(input: $input) {
      new {
        id
        name
        syncMeetings
      }
      old {
        id
        syncMeetings
      }
    }
  }
`

export type Props = {
  memberId: ID
  value: ID | null
  onHide: () => void
  onSuccess: () => void
}

type FormValues = {
  meetingsCalendar: ID | null
}

const UpdateMeetingCalendarModal: React.FC<Props> = ({
  memberId,
  value,
  onHide,
  onSuccess,
}) => {
  const [setDefault, { called, error }] =
    useUpdateMeetingCalendarModalMutation()

  const onSubmit = async (values: FormValues) => {
    try {
      const res = await setDefault({
        // FIXME: Needs to accept null values.
        variables: { input: { id: values.meetingsCalendar! } },
      })
      // Handle error case.
      if (res.errors) {
        console.error('setDefaultCalendar', res.errors)
        throw new Error('Setting default calendar failed.')
      }

      onSuccess()
    } catch (error) {
      console.error('setDefaultCalendar', error)
      toast.error('Something went wrong')
    }
  }

  return (
    <Modal className="OnboardingPages--Modals" onHide={onHide} show size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Change Meeting Calendar</Modal.Title>
      </Modal.Header>

      <FinalForm.Form<FormValues>
        initialValues={{
          // Get a reference to the current calendar that is
          // set to sync. This can evaluate to an empty array
          // if not calendars are set to sync.
          meetingsCalendar: value,
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form
            className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
            onSubmit={handleSubmit}
          >
            <Modal.Body>
              <Form.Group>
                <Form.Label>
                  <Translate>
                    Which calendar should Appointlet add meetings to?
                  </Translate>
                </Form.Label>

                <FinalForm.Field name="meetingsCalendar">
                  {({ input }) => (
                    <MeetingCalendarPicker
                      value={input.value}
                      onChange={input.onChange}
                      memberId={memberId}
                    />
                  )}
                </FinalForm.Field>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer className="tw-items-stretch tw-justify-between tw-flex-nowrap">
              <Button
                className="tw-flex-1"
                variant="outline-secondary"
                onClick={onHide}
              >
                <Translate>Cancel</Translate>
              </Button>

              <LoadingButton
                className="tw-flex-1"
                loading={called && !error}
                type="submit"
              >
                <Translate>Change Meeting Calendar</Translate>
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </FinalForm.Form>
    </Modal>
  )
}

export default UpdateMeetingCalendarModal
