import { toPairs } from 'lodash'

// Passes a string through LocalizeJS to translate it.  Supports variables
// with the Localize syntax.
// More info: https://developers.localizejs.com/docs/library-api#translate
export const translateString = (
  stringToTranslate: string,
  variables?: object
): string => {
  // If Localize isn't loaded (like in tests or maybe their CDN is down),
  // then we perform the variable swap and return the string untranslated.
  if (!window.Localize) {
    // If there are no variables we can safely just return the string
    if (!variables) return stringToTranslate

    // Loop through and replace the variables
    return toPairs(variables).reduce(
      (acc, [name, value]) => acc.replace(`%{${name}}`, value),
      stringToTranslate
    )
  }

  return window.Localize.translate(stringToTranslate, variables)
}
