import * as React from 'react'

import './Disabler.scss'

interface Props {
  disabled: boolean
}

// Go ahead, make a joke about this
const Disabler: React.FC<Props> = ({ disabled, children }) => (
  <div className="Disabler">
    {children}
    {disabled && <div className="Disabler--Blocker" />}
  </div>
)

export default Disabler
