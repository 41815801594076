import { FieldMetaState, FormRenderProps } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { ErrorType, Maybe } from '../__generated__/graphql'
import { Schedule, TimeBlock } from '../types'
import { DateTimeRange, Duration, timeRangesOverlap } from './datetime'
import { groupBy, toPairs } from 'lodash'
import { translateString } from './translation'

// https://emailregex.com/
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const urlRegex =
  /^(?:https?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
const slugRegex = /^[-a-zA-Z0-9]+$/
const reservedSlugs = /(^meeting$|^m$)/i

export type ValidationError = string | undefined

// Allows multiple validations to be run together, short circuiting on the first one
// that says value is invalid.
export const composeValidators =
  <T = string>(...validators: Array<(x: Maybe<T>) => ValidationError>) =>
  (value: Maybe<T>) =>
    // Return the first error we find.
    validators.reduce<ValidationError>(
      (error, validator) => error || validator(value),
      undefined
    )

export const isRequired = (value: Maybe<string> | undefined): ValidationError =>
  value ? undefined : translateString('Required.')

export const isEmailAddress = (
  email: Maybe<string> | undefined
): ValidationError => {
  return email && !emailRegex.test(email.toLowerCase())
    ? translateString('Invalid Email.')
    : undefined
}

export const isSecureURL = (
  url: Maybe<string> | undefined
): ValidationError => {
  if (url && url.substr(0, 8) !== 'https://') {
    return translateString('Must start with https://')
  }
  if (url && !urlRegex.test(url)) {
    return translateString('Please enter a valid URL')
  }
  return undefined
}

export const isURL = (url: Maybe<string> | undefined): ValidationError =>
  !url || urlRegex.test(url) ? undefined : translateString('Invalid URL')

export const isSlug = (slug: Maybe<string> | undefined): ValidationError => {
  return !slug || (slugRegex.test(slug) && !reservedSlugs.test(slug))
    ? undefined
    : translateString(
        'Must only contain letters, numbers, and hyphens, and may not be a reserved name.'
      )
}

export const hasMinimumLength =
  (min: number) => (value: Maybe<string> | undefined) =>
    value && value.length < min
      ? translateString('Must be at least %{min} characters long', { min: min })
      : undefined

export const maxLength = (max: number) => (value: Maybe<string | undefined>) =>
  value && value.length >= max
    ? translateString('Cannot exceed %{max} characters', { max: max })
    : undefined

export const durationGreaterThan =
  (lowerLimit: Duration, customMessage?: string) =>
  (duration: Maybe<Duration>): ValidationError =>
    duration && lowerLimit.asSeconds() > duration.asSeconds()
      ? customMessage ||
        translateString('Must be at least %{duration}', {
          duration: lowerLimit.toHuman(),
        })
      : undefined

export const durationLessThan =
  (upperLimit: Duration, customMessage?: string) =>
  (duration: Maybe<Duration>): ValidationError =>
    duration && upperLimit.asSeconds() < duration.asSeconds()
      ? customMessage ||
        translateString('Must be less than %{duration}', {
          duration: upperLimit.toHuman(),
        })
      : undefined

export const mutationErrorsToFormErrors = (errors: Maybe<ErrorType>[]) => {
  return errors.reduce<{ [key: string]: string[] }>((acc, val) => {
    if (val!.field === 'non_field_errors') {
      acc[FORM_ERROR] = val!.messages
      return acc
    }
    acc[val!.field] = val!.messages
    return acc
  }, {})
}

// TODO: make this work with custom inputs.
// Touched: https://stackoverflow.com/questions/51776786/
export const isFormFieldInvalid = (meta: FieldMetaState<any>): boolean => {
  return !!(
    // Field is not active (aka blurred)
    // It's invalid
    // It's been touched by the user
    (
      (!meta.active && meta.invalid && meta.touched) ||
      // OR there's a submit error and it hasn't been corrected yet.
      (meta.submitError && !meta.dirtySinceLastSubmit)
    )
  )
}

export const shouldDisable = (
  args: Pick<
    FormRenderProps,
    'dirtySinceLastSubmit' | 'hasSubmitErrors' | 'submitting'
  >
): boolean => {
  // If dirtySinceLastSubmit is true we want the button to be enabled again.
  // If either hasSubmitErrors or submitting is true then we do want to disable the button.
  return !args.dirtySinceLastSubmit && (args.hasSubmitErrors || args.submitting)
}

// access_denied – the user or authorization server denied the request
// server_error – instead of displaying a 500 Internal Server Error page to the user, the server can redirect with this error code.
// temporarily_unavailable – if the server is undergoing maintenance, or is otherwise unavailable, this error code can be returned instead of responding with a 503 Service Unavailable status code.

export type OAuthServerError =
  | 'access_denied'
  | 'server_error'
  | 'temporarily_unavailable'

export const oauthErrors = (serverError: OAuthServerError) => {
  switch (serverError) {
    case 'access_denied':
      return translateString(
        'We could not connect this account, please try again.'
      )
    case 'server_error':
      return translateString('Internal Server Error')
    case 'temporarily_unavailable':
      return translateString(
        'We are sorry but that service is not available at this time.'
      )
    default:
      return translateString('Something went wrong')
  }
}

// Validates a single TimeBlock's time range
export const isTimeBlockWithValidTimeRange =
  (
    errorMessage: string = translateString(
      'Start of a time range must come before the end.'
    )
  ) =>
  (timeBlock: TimeBlock | null): string | undefined => {
    if (timeBlock?.startTime.isAfter(timeBlock.endTime!)) {
      return errorMessage
    }
  }

// Validates a single TimeBlock's time range is at least
// the minimum duration.
export const isTimeBlockWithMinimumDuration =
  (
    minimumDuration: Duration,
    errorMessage: string = translateString(
      'Time range must be at least %{duration}.',
      { duration: minimumDuration.toHuman() }
    )
  ) =>
  (timeBlock: TimeBlock | null): string | undefined => {
    if (!timeBlock) return

    // Get the minimum length in seconds
    const minLengthAsSeconds = minimumDuration.asSeconds()

    // Check that the time range of the time block is at least
    // as long as the minimum.
    if (
      minLengthAsSeconds >
      timeBlock.endTime!.diff(timeBlock.startTime).asSeconds()
    ) {
      return errorMessage
    }
  }

// Validates a list of time blocks to make sure that none of them
// overlap with each other.  Used in the TimeBlockTable.
export const isListOfTimeBlocksWithNoOverlappingRanges =
  (
    errorMessage: string = translateString(
      "Time ranges can't overlap with each other."
    )
  ) =>
  (timeBlocks: TimeBlock[] | null): string | undefined => {
    if (!timeBlocks) return

    // We only care if timeblocks that are on the same day of the week
    // or date overlap, so this groupBy function organizes the ranges
    // into buckets of dates or weekdays.
    //
    // groupBy will return in an object where the key is either a date or
    // weekday, so the toPairs converts that back to an array that's
    // easier to traverse.
    const hasOverlaps = toPairs(
      groupBy(timeBlocks, tb => tb.weekday || tb.date!.toISO())
    ).some(([_, timeBlocks]) =>
      // Check to see if any of the timeRanges in this bucket overlap with
      // each other.
      timeRangesOverlap(
        timeBlocks.map(tb => new DateTimeRange(tb.startTime, tb.endTime!))
      )
    )

    if (hasOverlaps) {
      return errorMessage
    }
  }

// Validates a list of time blocks to make sure that none of them
// have a start that is after the end.  Used in the TimeBlockTable.
export const isListOfTimeBlocksWithValidTimeRanges =
  (
    errorMessage: string = translateString(
      "Time ranges can't overlap with each other."
    )
  ) =>
  (timeBlocks: ReadonlyArray<TimeBlock> | null): string | undefined => {
    if (!timeBlocks) return

    // Reuse the logic in this validator.
    const validator = isTimeBlockWithValidTimeRange()

    // See if there is an invalid block
    const hasInvalidTimeBlock = timeBlocks.some(
      tb => validator(tb) !== undefined
    )

    // If we found one, return appropriate error message
    if (hasInvalidTimeBlock) {
      return errorMessage
    }
  }

// Validates a list of time blocks to make sure that none of them
// are too short.  Used in the TimeBlockTable.
export const isListOfTimeBlocksWithMinimumDuration =
  (
    minimumDuration: Duration,
    errorMessage: string = translateString(
      'Time ranges must be at least %{duration}.',
      { duration: minimumDuration.toHuman() }
    )
  ) =>
  (timeBlocks: ReadonlyArray<TimeBlock> | null): string | undefined => {
    if (!timeBlocks) return

    // Reuse the logic in this validator.
    const validator = isTimeBlockWithMinimumDuration(minimumDuration)

    // Check through all of the timeblocks in all of the schedules and
    // if any are too short, then we'll return an error.
    const hasShortTimeBlock = timeBlocks.some(tb => validator(tb) !== undefined)

    // If we found one, return appropriate error message
    if (hasShortTimeBlock) {
      return errorMessage
    }
  }

// Validates all time blocks in a list of schedules to make sure they are
// not too short.  Used in AvailabilityInput
export const isListOfSchedulesWithTimeBlocksWithMinimumDuration =
  (
    minimumDuration: Duration,
    errorMessage: string = translateString(
      'Time ranges must be at least %{duration}.',
      { duration: minimumDuration.toHuman() }
    )
  ) =>
  (schedules: ReadonlyArray<Schedule> | null): string | undefined => {
    if (!schedules) return

    // Reuse this validator
    const validator = isListOfTimeBlocksWithMinimumDuration(minimumDuration)

    // Check through all of the timeblocks in all of the schedules and
    // if any are too short, then we'll return an error.
    const hasShortTimeBlock = schedules.some(
      schedule => validator(schedule.timeBlocks) !== undefined
    )

    if (hasShortTimeBlock) {
      return errorMessage
    }
  }

// Validator used with our AvailabilityInput to determine if
// one of the timeblocks is in an invalid state.
export const isListOfSchedulesWithTimeBlocksWithValidTimeRanges =
  (
    errorMessage: string = translateString(
      'Start of a time ranges must come before the end.'
    )
  ) =>
  (schedules: ReadonlyArray<Schedule> | null): string | undefined => {
    if (!schedules) return

    // Reuse this validator
    const validator = isListOfTimeBlocksWithValidTimeRanges()

    // Check through all of the timeblocks in all of the schedules and
    // if any are too short, then we'll return an error.
    const hasInvalidTimeBlock = schedules.some(
      schedule => validator(schedule.timeBlocks) !== undefined
    )

    if (hasInvalidTimeBlock) {
      return errorMessage
    }
  }

// Validator used with our AvailabilityInput to determine if
// one of the timeblocks is in an invalid state.
export const isListOfSchedulesWithTimeBlocksWithNoOverlappingRanges =
  (
    errorMessage: string = translateString(
      "Time ranges can't overlap with each other."
    )
  ) =>
  (schedules: ReadonlyArray<Schedule> | null): string | undefined => {
    if (!schedules) return

    const validator = isListOfTimeBlocksWithNoOverlappingRanges()

    // Scan through all of the timeblocks to see if related blocks
    // overlap with each other.
    const hasOverlappingTimeBlock = schedules.some(
      schedule => validator(schedule.timeBlocks) !== undefined
    )

    if (hasOverlappingTimeBlock) {
      return errorMessage
    }
  }
