import * as React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { gql } from '@apollo/client'

import Icon from './Icon'
import LoadingButton from './LoadingButton'
import ProfileContext from './ProfileContext'
import {
  SettingsModalUsersSectionDocument as Query,
  useRolePickerChangeOwnerMutation,
} from '../__generated__/graphql'
import { Member, User } from '../types'
import { toast } from '../utils'
import Translate from './Translate'
import Var from './Var'

gql`
  mutation RolePickerChangeOwner($input: SetProfileOwnerInput!) {
    profile: setProfileOwner(input: $input) {
      data {
        id
      }
    }
  }
`

export type Props = {
  member: Pick<Member, 'id'> & {
    user: Pick<
      User,
      '__typename' | 'email' | 'firstName' | 'id' | 'image' | 'lastName'
    >
  }
  onHide: () => void
  onSuccess: () => void
}

const TransferOwnershipModal: React.FC<Props> = ({
  member,
  onHide,
  onSuccess,
}) => {
  const profile = React.useContext(ProfileContext)
  const [transferOwnership, { loading }] = useRolePickerChangeOwnerMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: Query, variables: { id: profile.id } }],
  })
  const fullName =
    member.user.firstName && member.user.lastName
      ? `${member.user.firstName} ${member.user.lastName}`
      : member.user.firstName
  const onClick = async () => {
    try {
      const res = await transferOwnership({
        variables: { input: { member: member.id, profile: profile.id } },
      })
      // If data is present trigger the toast to display and close the modal
      if (res.data?.profile?.data) {
        toast.success('Owner Updated')
        onSuccess()
        return
      }
      // Here we are catching sever related errors.
    } catch (error) {
      toast.error('Something went wrong')
      console.error('setProfileOwner mutation', error)
    }
  }
  return (
    <Modal className="TransferOwnershipModal" onHide={onHide} show size="sm">
      <Modal.Header closeButton>
        <Icon.UserCheck className="tw-text-bsSuccess" size={36} />
        <Modal.Title>
          <Translate>Transfer Ownership</Translate>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Translate as="p">
          Are you sure you want to transfer ownership to{' '}
          <Var name="fullName">{fullName}</Var>? They will be promoted to an
          admin if they aren’t already.
        </Translate>
      </Modal.Body>
      <Modal.Footer className="tw-items-stretch tw-justify-between md:tw-justify-end">
        <Button
          className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
          variant="outline-secondary"
          onClick={onHide}
        >
          <Translate>Cancel</Translate>
        </Button>

        <LoadingButton
          className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
          loading={loading}
          onClick={onClick}
        >
          <Translate>Transfer Ownership</Translate>
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  )
}

export default TransferOwnershipModal
