import * as React from 'react'
import Picker, {
  PickerOption,
  PickerOptionGroup,
  Props as PickerProps,
} from './Picker'
import { gql } from '@apollo/client'
import {
  Maybe,
  useMeetingCalendarPickerQuery,
  CalendarPermissionLevel,
} from '../__generated__/graphql'
import { ID } from '../types'
import ColoredDot from './ColoredDot'
import Icon from './Icon'

gql`
  query MeetingCalendarPicker($memberId: ID!) {
    member: getMemberById(id: $memberId) {
      id
      calendarAccounts {
        edges {
          node {
            id
            name
            provider {
              id
              name
              slug
            }
            calendars {
              edges {
                node {
                  id
                  name
                  color
                  permissionLevel
                }
              }
            }
          }
        }
      }
    }
  }
`

// Options won't be handed in, we'll get them ourselves.
type Props = Omit<PickerProps<ID>, 'options' | 'value' | 'onChange'> & {
  value: ID | null
  onChange: (id: ID | null) => void
  memberId: ID
}

const MeetingCalendarPicker: React.FC<Props> = ({
  memberId,
  value,
  onChange,
  ...rest
}) => {
  const { data, loading } = useMeetingCalendarPickerQuery({
    variables: { memberId: memberId },
  })

  // If we got data, reduce it into Picker-friendly option groups
  const optionGroups: PickerOptionGroup<ID>[] = React.useMemo(
    () =>
      data?.member.calendarAccounts.edges.map(edge => ({
        label: edge!.node!.name,
        options: edge!.node!.calendars.edges.map(calEdge => ({
          label: calEdge!.node!.name,
          value: calEdge!.node!.id,
          image: <ColoredDot size={12} color={calEdge!.node!.color!} />,
          rightImage:
            calEdge!.node!.permissionLevel ===
              CalendarPermissionLevel.Freebusy ||
            calEdge!.node!.permissionLevel ===
              CalendarPermissionLevel.Reader ? (
              <span className="text-danger">Shared</span>
            ) : undefined,
          isDisabled:
            calEdge!.node!.permissionLevel ===
              CalendarPermissionLevel.Freebusy ||
            calEdge!.node!.permissionLevel === CalendarPermissionLevel.Reader,
        })),
      })) ?? [],
    [data]
  )

  // If we got a value, look up it's corresponding option and feed that to value below.
  const pickerValue =
    React.useMemo(
      () =>
        value &&
        optionGroups &&
        optionGroups.reduce(
          (acc, group) =>
            acc || group.options.find(opt => opt.value === value) || null,
          null as Maybe<PickerOption<ID>>
        ),
      [value, optionGroups]
    ) || null

  return (
    <Picker<ID>
      required
      options={optionGroups}
      value={pickerValue}
      loading={loading}
      onChange={option => onChange(option ? option.value : null)}
      placeholder={
        <React.Fragment>
          <Icon.Calendar className="mr-8 text-gray-400" size="18" />
          <span>Choose Calendar</span>
        </React.Fragment>
      }
      {...rest}
    />
  )
}

export default MeetingCalendarPicker
