import * as React from 'react'
import { PickerOption, PickerOptionGroup, Props as PickerProps } from './Picker'
import MultiPicker from './Multipicker'
import { useMeetingCalendarPickerQuery } from '../__generated__/graphql'
import { ID } from '../types'
import ColoredDot from './ColoredDot'
import Icon from './Icon'

// Options won't be handed in, we'll get them ourselves.
type Props = Omit<PickerProps<ID>, 'options' | 'value' | 'onChange'> & {
  value: Array<ID>
  onChange: (id: Array<ID>) => void
  memberId: ID
}

const AvailabilityCalendarsPicker: React.FC<Props> = ({
  memberId,
  value,
  onChange,
  ...rest
}) => {
  const { data, loading } = useMeetingCalendarPickerQuery({
    variables: { memberId: memberId },
  })

  // If we got data, reduce it into Picker-friendly option groups
  const optionGroups: PickerOptionGroup<ID>[] = React.useMemo(
    () =>
      data?.member.calendarAccounts.edges.map(edge => ({
        label: edge!.node!.name,
        options: edge!.node!.calendars.edges.map(calEdge => ({
          label: calEdge!.node!.name,
          value: calEdge!.node!.id,
          image: calEdge!.node!.color ? (
            <ColoredDot size={12} color={calEdge!.node!.color} />
          ) : undefined,
        })),
      })) ?? [],
    [data]
  )

  // If we got a value, look up it's corresponding option and feed that to value below.
  const pickerValue = React.useMemo(
    () =>
      value &&
      optionGroups &&
      optionGroups.reduce(
        (acc, group) => [
          // Existing matching calendars
          ...acc,

          // Matching calendars from current group
          ...group.options.filter(opt => value.indexOf(opt.value) !== -1),
        ],
        [] as Array<PickerOption<ID>>
      ),
    [value, optionGroups]
  )

  return (
    <MultiPicker<ID>
      options={optionGroups}
      value={pickerValue}
      loading={loading}
      onChange={values => onChange(values.map(({ value }) => value))}
      placeholder={
        <div className="tw-flex tw-items-center tw-space-x-2">
          <Icon.Calendar className="tw-text-bsGray-400" size="18" />
          <span>Choose Calendars</span>
        </div>
      }
      {...rest}
    />
  )
}

export default AvailabilityCalendarsPicker
