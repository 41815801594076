import * as React from 'react'
import {
  AttendeesTab,
  Props as AttendeesTabProps,
} from '../AttendeesTab/AttendeesTab'
import MeetingDetailTabSelector from '../MeetingDetailTabSelector/MeetingDetailTabSelector'
import NotesTab, { NotesTabProps } from '../NotesTab/NotesTab'

export type Props = Readonly<{
  attendeesMeta: AttendeesTabProps
  notesMeta: NotesTabProps
}>

const MeetingDetailView: React.FC<Props> = ({ attendeesMeta, notesMeta }) => {
  const [activeTab, setActiveTab] = React.useState<string>('attendees')

  const tabs = [
    {
      id: 'attendees',
      title: `ATTENDEES (${attendeesMeta.attendees.length})`,
    },
    {
      id: 'notes',
      title:
        notesMeta.notes.length > 0
          ? `NOTES (${notesMeta.notes.length})`
          : 'NOTES',
    },
  ]

  return (
    <div className="d-flex flex-column">
      <MeetingDetailTabSelector
        activeTab={activeTab}
        onSetActiveTab={setActiveTab}
        tabs={tabs}
      />

      <div className="mt-24">
        {activeTab === tabs[0].id && <AttendeesTab {...attendeesMeta} />}
        {activeTab === tabs[1].id && <NotesTab {...notesMeta} />}
      </div>
    </div>
  )
}

export default MeetingDetailView
