import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import './index.scss'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { getApolloClient } from './utils/api'
import { ModalProvider } from './components/ModalContext'
// here we import promise library from core-js to polyfill support
// for 'finally', as it is not supported by older browsers (eg. Safari <=10.1, Edge <=17).
// This shims an issue in Apollo client where fetchQuery.finally was not defined.
//from core-js docs: By default, core-js sets polyfills only when they are required.
// That means that core-js checks if a feature is available and
// works correctly or not and if it has no problems, core-js use native implementation.
import 'core-js/features/promise'

ReactDOM.render(
  <ApolloProvider client={getApolloClient()}>
    <ModalProvider>
      <App />
    </ModalProvider>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
