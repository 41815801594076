import * as React from 'react'
import { Button } from 'react-bootstrap'
import { navigate } from '@reach/router'
import { gql } from '@apollo/client'
import Illustration from './Illustration'
import CreateSchedulingPageModal from './CreateSchedulingPageModal'
import Icon from './Icon'
import ProfileContext from './ProfileContext'
import Toggler from './Toggler'
import {
  SchedulingPagesPageEmptyStateCreateMeetingTypeMutation,
  useSchedulingPagesPageEmptyStateCreateMeetingTypeMutation,
} from '../__generated__/graphql'
import { FetchResult } from '@apollo/client'

gql`
  mutation SchedulingPagesPageEmptyStateCreateMeetingType(
    $input1: CreateMeetingTypeInput!
    $input2: CreateMeetingTypeInput!
    $input3: CreateMeetingTypeInput!
  ) {
    meetingType1: createMeetingType(input: $input1) {
      data {
        id
        order
        name
        color
        duration
        bookingUrl
        slug
      }
      errors {
        field
        messages
      }
    }

    meetingType2: createMeetingType(input: $input2) {
      data {
        id
        order
        name
        color
        duration
        bookingUrl
        slug
      }
      errors {
        field
        messages
      }
    }

    meetingType3: createMeetingType(input: $input3) {
      data {
        id
        order
        name
        color
        duration
        bookingUrl
        slug
      }
      errors {
        field
        messages
      }
    }
  }
`

type Props = {}

// TODO: move this to a shared location
export const useCreateDefaultMeetingTypes = (): [
  (
    profileId: string,
    teamId: string
  ) => Promise<
    FetchResult<SchedulingPagesPageEmptyStateCreateMeetingTypeMutation>
  >,
  boolean
] => {
  const defaultMeetingTypes = [
    { duration: '900', name: '15 Minute Meeting', slug: '15-minute-meeting' },
    { duration: '1800', name: '30 Minute Meeting', slug: '30-minute-meeting' },
    { duration: '3600', name: '60 Minute Meeting', slug: '60-minute-meeting' },
  ]

  const [createMeetingType] =
    useSchedulingPagesPageEmptyStateCreateMeetingTypeMutation()

  const [loading, setLoading] = React.useState<boolean>(false)

  const perform = async (profileId: string, teamId: string) => {
    setLoading(true)

    const resp = await createMeetingType({
      variables: {
        input1: { profile: profileId, team: teamId, ...defaultMeetingTypes[0] },
        input2: { profile: profileId, team: teamId, ...defaultMeetingTypes[1] },
        input3: { profile: profileId, team: teamId, ...defaultMeetingTypes[2] },
      },
    })

    setLoading(false)

    return resp
  }

  return [perform, loading]
}

const SchedulingPagesPageEmptyState: React.FC<Props> = () => {
  const profile = React.useContext(ProfileContext)
  const [createDefaultMeetingTypes] = useCreateDefaultMeetingTypes()
  return (
    <div className="mt-64 px-16 align-items-center d-flex flex-column justify-content-center space-between-24">
      <Illustration name="holdingClipboard" className="mw-100" />

      <div className="text-center align-items-center d-flex flex-column space-between-8">
        <h1>Let's create your first scheduling page.</h1>
        <h5 className="text-gray-600">
          Scheduling pages are where you send people to schedule meetings with
          you.
        </h5>
      </div>

      <Toggler>
        {({ isToggled, setOff, setOn }) => (
          <React.Fragment>
            <Button onClick={setOn} variant="success">
              <Icon.Plus size="16" />
              Create scheduling page
            </Button>
            {isToggled && (
              <CreateSchedulingPageModal
                onHide={setOff}
                onSuccess={async teamId => {
                  // This is the user's first scheduling page so we want to
                  // give them some stock MTs to work with initially.
                  await createDefaultMeetingTypes(profile.id, teamId)

                  // Close the modal.
                  setOff()

                  // Redirect the user to their new scheduling page.
                  await navigate(
                    `/profiles/${profile.id}/scheduling-pages/${teamId}`
                  )
                }}
              />
            )}
          </React.Fragment>
        )}
      </Toggler>
    </div>
  )
}

export default SchedulingPagesPageEmptyState
