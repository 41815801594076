import { gql } from '@apollo/client'

export const MeetingsFragment = gql`
  fragment Meetings on MeetingNodeConnection {
    edges {
      node {
        attendees {
          edges {
            node {
              firstName
              email
              id
              lastName
              status
              approved
              member {
                id
                user {
                  firstName
                  email
                  id
                  image
                  timezone
                }
              }
              timezone
            }
          }
        }
        cancelled
        conferenceId
        conferenceUrl
        conferencingAccount {
          id
          name
          provider {
            id
            name
            slug
          }
        }
        confirmed
        end
        id
        isGroup
        location
        locationType
        meetingType {
          id
          name
          color
          conferencingProvider {
            id
            name
            slug
          }
        }
        name
        start
      }
    }
  }
`
