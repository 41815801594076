import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { gql } from '@apollo/client'
import { Modal, Form } from 'react-bootstrap'

import FormErrorSubscription from './FormErrorSubscription'
import Icon from './Icon'
import LoadingButton from './LoadingButton'
import ProfileContext from './ProfileContext'
import URLInput from './URLInput'
import Translate from './Translate'
import {
  useCreateWebhookModalMutation,
  WebhookNode,
} from '../__generated__/graphql'
import { composeValidators, isRequired, isSecureURL, toast } from '../utils'

gql`
  mutation CreateWebhookModal($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      data {
        id
        url
      }
      errors {
        field
        messages
      }
    }
  }
`

export type Props = {
  onCreate: (id: string) => void
  onHide: () => void
}

type FormValues = Pick<WebhookNode, 'name' | 'url'>

const CreateWebhookModal: React.FC<Props> = ({ onHide, onCreate }) => {
  const profile = React.useContext(ProfileContext)
  const [createWebhook, { loading }] = useCreateWebhookModalMutation()
  const onSubmit = async (values: FormValues) => {
    try {
      const res = await createWebhook({
        variables: {
          input: {
            profile: profile.id,
            active: true,
            selectedActivities: null,
            ...values,
          },
        },
      })
      // If client validation and GraphQL error related errors exist
      if (res.data?.createWebhook?.errors) {
        console.error(
          'createWebhookSubscription mutation',
          res.data.createWebhook.errors
        )
        return
      }
      // If data is present trigger the toast to display and close the modal
      if (res.data?.createWebhook?.data) {
        toast.success(`Created Webhook`, res.data.createWebhook.data.url)
        onCreate(res.data.createWebhook.data.id)
        return
      }
    } catch (err) {
      toast.error('Something went wrong')
      console.error('createWebhookSubscription mutation', err)
    }
  }
  return (
    <Modal className="CreateWebhookModal" onHide={onHide} size="sm" show>
      <Modal.Header closeButton>
        <Icon.PlusCircle size={30} />
        <Modal.Title>
          <Translate>Add Webhook</Translate>
        </Modal.Title>
      </Modal.Header>

      <FinalForm.Form<FormValues> onSubmit={onSubmit}>
        {({ handleSubmit, invalid }) => (
          <Form
            className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
            onSubmit={handleSubmit}
          >
            <Modal.Body>
              <Form.Group>
                <Form.Label>
                  <Translate>URL to Notify</Translate>
                </Form.Label>

                <FinalForm.Field<WebhookNode['url']>
                  name="url"
                  validate={composeValidators(isRequired, isSecureURL)}
                >
                  {({ input, meta }) => (
                    <URLInput
                      {...input}
                      // @ts-ignore
                      maxLength={1000}
                      isInvalid={meta.invalid && meta.touched}
                    />
                  )}
                </FinalForm.Field>
                <FormErrorSubscription name="url" />
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  <Translate>Label</Translate>{' '}
                  <small className="text-muted">
                    <Translate>Optional</Translate>
                  </small>
                </Form.Label>
                <FinalForm.Field<WebhookNode['name']> name="name">
                  {({ input }) => (
                    <Form.Control {...input} value={input.value || ''} />
                  )}
                </FinalForm.Field>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <LoadingButton
                className="tw-flex-1 md:tw-flex-shrink md:tw-flex-grow-0"
                disabled={invalid}
                loading={loading}
                type="submit"
                variant="primary"
              >
                <Translate>Create Webhook</Translate>
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </FinalForm.Form>
    </Modal>
  )
}

export default CreateWebhookModal
