import * as React from 'react'

import './ExternalLink.scss'
import Icon from './Icon'
import { expandClassName } from '../utils'

export interface Props extends React.HTMLProps<HTMLAnchorElement> {
  hideIcon?: boolean
}

const ExternalLink: React.FC<Props> = ({
  children,
  className,
  hideIcon,
  ...rest
}) => (
  <a
    className={expandClassName('ExternalLink', className)}
    target="_blank"
    rel="noreferrer noopener"
    {...rest}
  >
    {children}
    {!hideIcon && <Icon.ExternalLink size={14} />}
  </a>
)

export default ExternalLink
