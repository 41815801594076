import * as React from 'react'
import { Modal } from 'react-bootstrap'
import SettingsSectionPicker from './SettingsSectionPicker'
import { SettingsSection } from './SettingsModal'
import Icon from './Icon'
import Translate from './Translate'

type Props = {
  onSelect: (section: SettingsSection) => void
}

const SettingsModalZapierSection: React.FC<Props> = ({ onSelect }) => {
  return (
    <div className="SettingsModalZapierSection">
      <Modal.Header closeButton>
        <Icon.Zapier className="tw-hidden tw-text-bsGray-600 lg:tw-block" />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title as="h2" className="tw-hidden md:tw-block">
            <Translate>Zapier</Translate>
          </Modal.Title>
          <Translate as="p">Manage and create zaps.</Translate>
        </div>
        <SettingsSectionPicker onChange={onSelect} value="zapier" />
      </Modal.Header>
      <Modal.Body>
        {/* @ts-ignore */}
        <zapier-app-directory app="appointlet" theme="light" applimit={0} />
      </Modal.Body>
    </div>
  )
}

export default SettingsModalZapierSection
