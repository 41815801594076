import { Maybe } from 'graphql/jsutils/Maybe'
import useLocalStorageState from 'use-local-storage-state'

type OnboardingState = {
  meetingType: Maybe<{
    id: string
    name: string
    duration: number
    isGroup: boolean
    slug: string
  }>
}

// During the onboarding process we need to store various pieces of data between screens.
// For example, when the user creates an initial meeting type, we preserve information about
// it so that if they press the back button we display it back to them.
export const useOnboardingState = (): [
  OnboardingState,
  (state: OnboardingState) => void,
  () => void
] => {
  const [onboardingState, setOnboardingState, { removeItem }] =
    useLocalStorageState<OnboardingState>('onboarding', {
      defaultValue: {
        meetingType: null,
      },
    })

  return [onboardingState, setOnboardingState, removeItem]
}
