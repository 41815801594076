import * as React from 'react'

import useLocalStorageState from 'use-local-storage-state'
import UserContext from '../components/UserContext'

export const useViewedTours = (): [
  Record<string, boolean>,
  (viewedTour: string) => void
] => {
  // Get the current user
  const { user } = React.useContext(UserContext)

  // Get the tours they've viewed so far
  const [viewedTours, setViewedTours] = useLocalStorageState<
    Record<string, boolean>
  >(`user:${user.id}:viewedTours`, {
    defaultValue: {},
  })

  return [
    viewedTours,
    // Instead of handing back setViewedTours, give back an
    // "addViewedTour" helper that is easier to work with.
    (viewedTour: string) =>
      setViewedTours({ ...viewedTours, [viewedTour]: true }),
  ]
}
