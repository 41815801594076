import * as React from 'react'
import { Button, Card, Col, Form, Modal, Row, Media } from 'react-bootstrap'

import AppointletSnippet, { snippet } from './AppointletSnippet'
import CopyBox from './CopyBox'
import ColorPicker from './ColorPicker'
import Icon from './Icon'
import inlineIllustration from '../assets/Inline-Illustration.svg'
import modalIllustration from '../assets/Modal-Illustration.svg'
import { expandClassName } from '../utils'
import Illustration from './Illustration'
import Translate from './Translate'

export type Props = {
  onHide: () => void
  onSuccess: () => void
  url: string
}

type State = 'initial' | 'inline' | 'modal'
type Selection = 'inline' | 'modal'

const InstallOnWebsiteModal: React.FC<Props> = ({ onHide, onSuccess, url }) => {
  const [state, setState] = React.useState<State>('initial')
  const [selection, setSelection] = React.useState<Selection>('inline')
  const [label, setLabel] = React.useState<string | undefined>(() => undefined)
  const [color, setColor] = React.useState(() => '#1e9bff')
  return (
    <Modal onHide={onHide} show size="lg">
      <Modal.Header className="tw-pb-0 tw-border-b-0" closeButton>
        {state !== 'initial' && (
          <Icon.ArrowLeft
            className="tw-text-bsGray-500"
            onClick={() => setState('initial')}
          />
        )}
      </Modal.Header>
      <Modal.Body className="tw-pb-12 tw-px-12 tw-pt-0">
        <div className="tw-flex tw-flex-col tw-justify-center tw-mb-8 tw-text-center">
          <div className="tw-mb-8">
            <Illustration className="tw-h-40" name="atComputer" />
          </div>
          <Modal.Title as="h2">
            <Translate>
              {state === 'initial'
                ? 'How do you want to add Appointlet to your website?'
                : 'Here’s how to add Appointlet to your website:'}
            </Translate>
          </Modal.Title>
        </div>

        {state === 'initial' && (
          <>
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mb-12 tw-space-y-4 md:tw-flex-row md:tw-space-x-4 md:tw-space-y-0">
              <Card
                className={expandClassName(
                  'tw-mb-0 tw-max-w-[252px]',
                  selection === 'inline' ? 'tw-selected' : undefined
                )}
                onClick={() => setSelection('inline')}
              >
                <Card.Img
                  alt="Inline Illustration"
                  className="tw-px-4 tw-pt-4"
                  src={inlineIllustration}
                />
                <Card.Body className="tw-p-4">
                  <Card.Title
                    as="h5"
                    className="tw-mb-[2px] tw-text-base tw-font-medium"
                  >
                    <Translate>Directly into your site</Translate>
                  </Card.Title>
                  <Card.Text className="tw-text-bsGray-600">
                    <Translate>
                      Loaded directly into your site inline with your other
                      content.
                    </Translate>
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card
                className={expandClassName(
                  'tw-mb-0 tw-max-w-[252px]',
                  selection === 'modal' ? 'tw-selected' : undefined
                )}
                onClick={() => setSelection('modal')}
              >
                <Card.Img
                  alt="Modal Illustration"
                  className="tw-px-4 tw-pt-4"
                  src={modalIllustration}
                />
                <Card.Body className="tw-pt-4">
                  <Card.Title as="h5" className="tw-mb-[2px] tw-font-medium">
                    <Translate>Floating popup widget</Translate>
                  </Card.Title>
                  <Card.Text className="tw-text-bsGray-600">
                    <Translate>
                      Opens on top of your website when your users click a
                      button.
                    </Translate>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            <div className="tw-flex tw-flex-col tw-items-center">
              <Button
                className="tw-mb-4 tw-items-center tw-flex tw-space-x-1"
                onClick={() => setState(selection)}
                variant="success"
              >
                <Translate>Customize & Get Instructions</Translate>
                <span>→</span>
              </Button>

              <Button
                as="a"
                className="tw-whitespace-normal"
                href="TODO"
                rel="noreferrer noopener"
                target="_blank"
                variant="link"
              >
                <Translate>
                  See some examples of how the booking page looks when added to
                  a website.
                </Translate>
              </Button>
            </div>
          </>
        )}

        {state === 'inline' && (
          <div className="tw-flex tw-flex-col tw-space-y-4">
            <Translate as="h4">Install Code Snippet</Translate>

            <Translate as="p">
              Put this code where you want the scheduling page to appear on your
              website.
            </Translate>
            <CopyBox text={snippet({ url })}>
              <AppointletSnippet url={url} />
            </CopyBox>

            <div>
              <Button
                as="a"
                className="tw-whitespace-normal"
                href="https://www.npmjs.com/package/@appointlet/appointlet.js"
                rel="noreferrer noopener"
                target="_blank"
                variant="link"
              >
                <Translate>
                  I have a single page app (SPA) like React or Angular.
                </Translate>
              </Button>
            </div>
          </div>
        )}

        {state === 'modal' && (
          <>
            <Media className="tw-mb-4 tw-items-center">
              <div className="tw-items-center tw-flex tw-justify-center tw-text-bsPrimary tw-font-medium tw-text-base tw-h-8 tw-w-8 tw-rounded-full tw-bg-bsPrimary/10">
                1
              </div>

              <Media.Body className="tw-ml-3">
                <Translate as="h4">Customize your button</Translate>
              </Media.Body>
            </Media>
            <Row className="tw-mb-12">
              <Col lg={6} className="tw-mb-6 lg:tw-mb-0">
                <Form.Group>
                  <Form.Label>
                    <Translate>Label</Translate>
                  </Form.Label>
                  <Form.Control
                    maxLength={28}
                    placeholder="Schedule a time"
                    onChange={e =>
                      setLabel(
                        e.currentTarget.value ? e.currentTarget.value : ''
                      )
                    }
                    value={label}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    <Translate>Background Color</Translate>
                  </Form.Label>
                  <ColorPicker onSelect={setColor} value={color} />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <div className="tw-p-6 tw-rounded-[5px] tw-items-center tw-bg-bsGray-100 tw-flex tw-h-full tw-justify-center tw-relative">
                  <p className="tw-text-bsGray-600 tw-absolute tw-top-1 tw-right-4 lg:tw-top-4">
                    <Translate>Preview</Translate>
                  </p>
                  <Button
                    style={{ backgroundColor: color, borderColor: color }}
                  >
                    <Icon.InternalAsset
                      assetName="Icon-Calendar"
                      className="tw-mr-3"
                      size={30}
                    />
                    <span className="tw-text-base">
                      {label || <Translate>Schedule a time</Translate>}
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>

            <div className="tw-flex tw-flex-col tw-space-y-4">
              <Media className="tw-items-center">
                <div className="tw-items-center tw-flex tw-justify-center tw-text-bsPrimary tw-font-medium tw-text-base tw-h-8 tw-w-8 tw-rounded-full tw-bg-bsPrimary/10">
                  2
                </div>

                <Media.Body className="tw-ml-3">
                  <Translate as="h4">Install Code Snippet</Translate>
                </Media.Body>
              </Media>

              <Translate as="p">
                Put this code where you want the button to appear on your
                website.
              </Translate>
              <CopyBox
                text={snippet({ color, isInline: true, text: label, url })}
              >
                <AppointletSnippet
                  color={color}
                  isInline
                  text={label}
                  url={url}
                />
              </CopyBox>

              <div>
                <Button
                  as="a"
                  className="tw-whitespace-normal"
                  href="https://www.npmjs.com/package/@appointlet/appointlet.js"
                  rel="noreferrer noopener"
                  target="_blank"
                  variant="link"
                >
                  <Translate>
                    I have a single page app (SPA) like React or Angular.
                  </Translate>
                </Button>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default InstallOnWebsiteModal
