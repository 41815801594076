import * as React from 'react'

import { expandClassName } from '../utils'

type Props = React.HTMLProps<HTMLDivElement>

const Panel: React.FC<Props> = ({ children, className, ...rest }) => (
  <div
    className={expandClassName(
      'tw-flex tw-items-center tw-flex-grow tw-flex-shrink tw-bg-white tw-rounded-[5px] tw-cursor-pointer tw-p-3 tw-border tw-border-solid tw-border-[#d8dade] tw-shadow-[0_1px_4px_rgba(0,0,0,0.05)]',
      className
    )}
    {...rest}
  >
    {children}
  </div>
)

export default Panel
