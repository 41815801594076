import * as React from 'react'
import { Media, Button } from 'react-bootstrap'
import { gql } from '@apollo/client'

import Badge from './Badge'
import CancelSubscriptionModal from './CancelSubscriptionModal'
import ChangeBillingCycleModal from './ChangeBillingCycleModal'
import Icon from './Icon'
import LoadingButton from './LoadingButton'
import PremiumUpgradeModal from './PremiumUpgradeModal'
import ProfileContext from './ProfileContext'
import { BillingData } from './SettingsModalBillingSection'
import TimeLabel from './TimeLabel'
import Toggler from './Toggler'
import UserContext from './UserContext'
import {
  Currency,
  useGetUpcomingInvoiceLazyQuery,
  useResumeSubscriptionMutation,
  useUpdatePlanProfileMutation,
} from '../__generated__/graphql'
import { formatMoney, toast, Analytics } from '../utils'
import Translate from './Translate'
import Var from './Var'

interface Props {
  billingData: BillingData
  onChange: () => void
}

gql`
  mutation ResumeSubscription($input: ResumeSubscriptionInput!) {
    resumeSubscription(input: $input) {
      ok
    }
  }
`

gql`
  query GetUpcomingInvoice($profile: ID!, $billedAnnually: Boolean) {
    invoice: previewInvoice(
      profile: $profile
      billedAnnually: $billedAnnually
    ) {
      amountDue
      discountAmount
      discountName
      discountPercentOff
      endingBalance
      prorationAmount
      startingBalance
      subscriptionAmount
      subscriptionQuantity
      total
    }
  }
`

const BillingSubscriptionSection: React.FC<Props> = ({
  billingData,
  onChange,
}) => {
  const { user } = React.useContext(UserContext)
  const profile = React.useContext(ProfileContext)

  // Prepare the resumeSubscription mutation
  const [resumeSubscription, { loading }] = useResumeSubscriptionMutation({
    variables: { input: { profile: profile.id } },
  })

  // Query the invoice data
  const [fetchUpcomingInvoice, { data, loading: isInvoiceLoading }] =
    useGetUpcomingInvoiceLazyQuery({
      fetchPolicy: 'no-cache',
      variables: {
        profile: profile.id,
        billedAnnually: !billingData.profile.billedAnnually,
      },
    })

  // Mutation to update the plan on a profile
  const [updateProfilePlan] = useUpdatePlanProfileMutation()

  const onResume = async () => {
    try {
      const res = await resumeSubscription()

      if (!res.data?.resumeSubscription?.ok) {
        console.error(
          '[BillingSubscriptionSection] Failed to resume subscription'
        )
        throw new Error('Failed to resume subscription')
      }

      // Let the parent know it should refetch the billing data
      onChange()
    } catch (err) {
      toast.error('Something went wrong')
    }
  }

  return (
    <Media className="tw-flex-col tw-space-y-4 md:tw-flex-row md:tw-items-center md:tw-space-y-0 md:tw-space-x-4">
      <Icon.InternalAsset assetName="Icon-Subscription" size={50} />

      <Media.Body className="tw-flex-col">
        <strong>
          <Translate>Subscription</Translate>
        </strong>

        <p className="tw-text-bsGray-600">
          {billingData.profile.plan!.freeDefault ? (
            <Translate>
              You’re currently on the Free plan. Upgrade to access premium
              features.
            </Translate>
          ) : billingData.subscription.status === 'trialing' ? (
            <>
              {/* If they haven't provided a payment method or have specifically told us that they want to cancel */}
              {!billingData.paymentMethod ||
              billingData.subscription.markedForCancelation ? (
                <Translate>
                  You’re currently trialing the {billingData.profile.plan!.name}{' '}
                  plan until{' '}
                  <Var name="trialEnd">
                    <TimeLabel
                      format="localized-full-date"
                      time={billingData.subscription.trialEnd}
                    />
                  </Var>
                  , after which you'll be downgraded to the Free plan.
                </Translate>
              ) : (
                <Translate>
                  You’re currently trialing the {billingData.profile.plan!.name}{' '}
                  plan until{' '}
                  <Var name="trialEnd">
                    <TimeLabel
                      format="localized-full-date"
                      time={billingData.subscription.trialEnd}
                    />
                  </Var>
                  , after which you'll be charged
                  <Var name="invoiceAmount">
                    {formatMoney(
                      billingData.profile.upcomingInvoiceAmount! / 100,
                      Currency.Usd,
                      user.language
                    )}
                  </Var>
                  .
                </Translate>
              )}
            </>
          ) : billingData.subscription.markedForCancelation ? (
            <Translate>
              Your subscription will be cancelled on{' '}
              <Var name="cycleEnd">
                <TimeLabel
                  format="localized-full-date"
                  time={billingData.subscription.cycleEnd}
                />
              </Var>
              . The premium features will continue to function until then.
            </Translate>
          ) : (
            <Translate>
              You’re currently on the{' '}
              <Var name="name">{billingData.profile.plan!.name}</Var> plan with{' '}
              <Var name="seats" pluralize={1}>
                {billingData.profile.occupiedSeats}
              </Var>{' '}
              seat, which will renew on{' '}
              <Var name="cycleEnd">
                <TimeLabel
                  format="localized-full-date"
                  time={billingData.subscription.cycleEnd}
                />
              </Var>{' '}
              for{' '}
              <Var name="invoiceAmount">
                {formatMoney(
                  billingData.profile.upcomingInvoiceAmount! / 100,
                  Currency.Usd,
                  user.language
                )}
              </Var>
              .
            </Translate>
          )}
        </p>

        {!billingData.subscription.markedForCancelation &&
          !billingData.profile.plan!.freeDefault && (
            <Toggler>
              {({ isToggled, setOff, setOn }) => (
                <>
                  <Button
                    className="tw-space-x-2"
                    onClick={() => {
                      fetchUpcomingInvoice()
                      setOn()
                    }}
                    variant="link"
                  >
                    {billingData.profile.billedAnnually ? (
                      <Translate>Change billing cycle</Translate>
                    ) : (
                      <>
                        <Translate>Switch to annual billing</Translate>{' '}
                        <Badge variant="success">
                          <Translate>Save 20%</Translate>
                        </Badge>
                      </>
                    )}
                  </Button>
                  {isToggled && (
                    <ChangeBillingCycleModal
                      billedAnnually={billingData.profile.billedAnnually}
                      invoiceDetails={data?.invoice!}
                      isQueryLoading={isInvoiceLoading}
                      language={user.language}
                      onHide={() => {
                        Analytics.trackEvent(
                          'Change Billing Cycle Modal: Closed'
                        )
                        setOff()
                      }}
                      onSuccess={async ({ billedAnnually }) => {
                        await updateProfilePlan({
                          variables: {
                            input: {
                              billedAnnually,
                              id: profile.id,
                              plan: billingData.profile.plan!.id,
                            },
                          },
                        })
                          .then(() => {
                            // Close the modal
                            setOff()

                            // Refetch the view data
                            onChange()

                            Analytics.trackEvent(
                              `Billing: Changed Cycle to ${
                                billedAnnually ? 'Yearly' : 'Monthly'
                              }`
                            )

                            toast.success(
                              `Changed Billing Cycle to ${
                                billedAnnually ? 'Yearly' : 'Monthly'
                              }`
                            )
                          })
                          .catch(err => {
                            console.error(err)
                            toast.error('Something went wrong.')
                          })
                      }}
                    />
                  )}
                </>
              )}
            </Toggler>
          )}
      </Media.Body>

      {/*If they're on the free plan OR trialing the premium without having entered a payment method already.*/}
      {billingData.profile.plan!.freeDefault ||
      (billingData.subscription.status === 'trialing' &&
        !billingData.paymentMethod) ? (
        <Toggler>
          {modal => (
            <>
              <Button variant="primary" onClick={modal.setOn}>
                {billingData.subscription.status === 'trialing' ? (
                  <Translate>Subscribe to Premium</Translate>
                ) : (
                  <Translate>Upgrade to Premium</Translate>
                )}
              </Button>
              {modal.isToggled && (
                <PremiumUpgradeModal
                  profile={profile}
                  onHide={modal.setOff}
                  onSuccess={() => {
                    // Let the parent know it should refetch the billing data
                    onChange()
                    modal.setOff()
                  }}
                />
              )}
            </>
          )}
        </Toggler>
      ) : billingData.subscription.markedForCancelation ? (
        <LoadingButton
          variant="primary"
          onClick={() => {
            Analytics.trackEvent('Billing: Keep Subscription')
            onResume()
          }}
          loading={loading}
        >
          {billingData.subscription.status === 'trialing' ? (
            <Translate>Subscribe to Premium</Translate>
          ) : (
            <Translate>Keep my subscription</Translate>
          )}
        </LoadingButton>
      ) : (
        <Toggler>
          {modal => (
            <>
              <Button
                variant="link"
                className="tw-text-bsDanger"
                onClick={modal.setOn}
              >
                {billingData.subscription.status === 'trialing' ? (
                  <Translate>Cancel Upgrade</Translate>
                ) : (
                  <Translate>Cancel Subscription</Translate>
                )}
              </Button>

              {modal.isToggled && (
                <CancelSubscriptionModal
                  onHide={modal.setOff}
                  onSuccess={() => {
                    // Let the parent know it should refetch the billing data
                    onChange()
                    modal.setOff()
                  }}
                />
              )}
            </>
          )}
        </Toggler>
      )}
    </Media>
  )
}

export default BillingSubscriptionSection
