import * as React from 'react'
import * as FinalForm from 'react-final-form'
import {
  Accordion,
  Card,
  Form,
  Modal,
  ListGroup,
  Button,
} from 'react-bootstrap'
import { gql } from '@apollo/client'

import './UpdateMeetingTypeModalLocationSection.scss'
import Icon from './Icon'
import IconTextInput from './IconTextInput'
import MemberContext from './MemberContext'
import PlacesInput from './PlacesInput'
import SettingsModal from './SettingsModal'
import Toggler from './Toggler'
import UpdateMeetingTypeContext, {
  IMeetingType,
} from './UpdateMeetingTypeContext'
import {
  LocationType,
  MeetingTypeEditorLocationComponent,
  MeetingTypeEditorLocationQuery as Response,
} from '../__generated__/graphql'
import { ConferenceProvider } from '../types'
import {
  expandClassName,
  isFormFieldInvalid,
  isNotSoftDeleted,
  isRequired,
} from '../utils'
import { Dispatch, SetStateAction } from 'react'
import TooltipOverlay from './TooltipOverlay'
import Translate from './Translate'
import Var from './Var'
import { MTESection } from './UpdateMeetingTypeModal'
import MeetingTypeEditorSectionPicker from './MeetingTypeEditorSectionPicker'

gql`
  query MeetingTypeEditorLocation($meetingTypeId: ID!) {
    providers: getConferenceProviders {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    teamMembers: getMeetingTypeById(id: $meetingTypeId) {
      id
      meetingTypeTeamMembers {
        edges {
          node {
            id
            teamMember {
              id
              member {
                calendarAccounts {
                  edges {
                    node {
                      id
                      provider {
                        id
                        slug
                      }
                    }
                  }
                }
                conferencingAccounts {
                  edges {
                    node {
                      deleted
                      id
                      provider {
                        id
                        slug
                      }
                    }
                  }
                }
                id
              }
            }
          }
        }
      }
    }
  }
`

type Props = {
  readonly setActiveTab: Dispatch<SetStateAction<MTESection>>
  readonly currentMeetingTypeMembers: IMeetingType['meetingTypeTeamMembers']
}

type InternalData = {
  meetingTypeMembers: Array<{
    memberId: string
    conferencingProviders: Array<string>
    hasGoogleAccount: boolean
  }>
  providers: Array<Pick<ConferenceProvider, 'id' | 'name' | 'slug'>>
}

const wireDataToInternalData = (wireData: Response): InternalData => ({
  meetingTypeMembers: wireData.teamMembers.meetingTypeTeamMembers.edges!.map(
    edge => ({
      memberId: edge!.node!.teamMember.member.id,

      // List of slugs of conferencing providers this MTTM has connected
      conferencingProviders: edge!
        .node!.teamMember.member.conferencingAccounts.edges.filter(edge =>
          isNotSoftDeleted(edge!.node!)
        )
        .map(edge => edge!.node!.provider.slug),

      // Whether or not they have a Google account connected
      hasGoogleAccount:
        !!edge!.node!.teamMember.member.calendarAccounts.edges.find(
          edge => edge!.node!.provider.slug === 'google'
        ) || false,
    })
  ),
  providers: wireData.providers.edges.map(edge => ({ ...edge!.node! })),
})

// formats currentMeetingTypeMembers grabbed from form spy to expected shape on InternalData
// (currentMeetingTypeMembers includes members added in HostAssignmentSection before form is saved)
const formatCurrentMembers = (
  currentMembers: IMeetingType['meetingTypeTeamMembers']
): InternalData['meetingTypeMembers'] => {
  return currentMembers
    .filter(member => {
      return member.active
    })
    .map(member => {
      const conferencingAccounts = member.teamMember.member.conferencingAccounts
      const calendarAccounts = member.teamMember.member.calendarAccounts

      return {
        memberId: member.teamMember.member.id,
        conferencingProviders: conferencingAccounts
          .filter(isNotSoftDeleted)
          .map(account => {
            return account.provider.slug
          }),
        hasGoogleAccount:
          !!calendarAccounts.find(
            account => account.provider.slug === 'google'
          ) || false,
      }
    })
}

enum Category {
  None = 'none',
  PhoneCall = 'phone-call',
  Place = 'place',
  WebConference = 'web-conference',
}

// Takes a location type and returns a corresponding category for it
const categoryForLocationType = (
  locationType: IMeetingType['locationType']
) => {
  switch (locationType) {
    case LocationType.Phone:
    case LocationType.PhoneUser:
      return Category.PhoneCall
    case LocationType.Place:
    case LocationType.PlaceUser:
      return Category.Place
    case LocationType.GoogleMeet:
    case LocationType.WebConference:
      return Category.WebConference
    default:
      return Category.None
  }
}

const UpdateMeetingTypeModalLocationSection: React.FC<Props> = ({
  setActiveTab,
  currentMeetingTypeMembers,
}: Props) => {
  const meetingType = React.useContext(UpdateMeetingTypeContext)
  const member = React.useContext(MemberContext)

  const phoneLocationInputRef = React.useRef<HTMLInputElement>(null)
  const placeLocationInputRef = React.useRef<HTMLInputElement>(null)

  const focusField = (locationType: IMeetingType['locationType']) => {
    setTimeout(() => {
      switch (locationType) {
        case LocationType.Place:
          return placeLocationInputRef.current?.focus()
        case LocationType.Phone:
          return phoneLocationInputRef.current?.focus()
      }
    })
  }

  return (
    <div className="UpdateMeetingTypeModalLocationSection tw-w-full">
      <Modal.Header closeButton>
        <Icon.InternalAsset
          assetName="icn-mte-header-location"
          className="tw-hidden lg:tw-block"
        />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title>
            <Translate>Location</Translate>
          </Modal.Title>
          <Translate as="p">Set the details of where you will meet</Translate>
        </div>
        <MeetingTypeEditorSectionPicker
          onChange={setActiveTab}
          value="location"
        />
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="tw-space-y-4">
          <FinalForm.Field<IMeetingType['locationType']> name="locationType">
            {({ input: locationType }) => {
              const category = categoryForLocationType(locationType.value)
              return (
                <>
                  <Accordion
                    activeKey={category}
                    onClick={() => locationType.onChange(null)}
                  >
                    <Card
                      className={expandClassName(
                        'tw-overflow-visible',
                        category === Category.None ? 'tw-selected' : undefined
                      )}
                    >
                      <Accordion.Toggle
                        as={Card.Header}
                        className="tw-cursor-pointer tw-flex-wrap tw-p-4 md:tw-px-6"
                        eventKey="none"
                      >
                        <Icon.InternalAsset
                          assetName="Icon-Cancellations"
                          className="tw-mr-3 tw-h-9 tw-w-9 md:tw-h-[50px] md:tw-w-[50px]"
                        />
                        <Translate as="strong">No location specified</Translate>
                        {category === Category.None && (
                          <Icon.InternalAsset
                            assetName="icn-round-check"
                            size={25}
                            className="tw-ml-auto"
                          />
                        )}
                      </Accordion.Toggle>
                    </Card>
                  </Accordion>
                  <Accordion activeKey={category}>
                    <Card
                      className={expandClassName(
                        'tw-overflow-visible',
                        category === Category.Place ? 'tw-selected' : undefined
                      )}
                    >
                      <Accordion.Toggle
                        as={Card.Header}
                        className="tw-cursor-pointer tw-flex-wrap tw-p-4 md:tw-px-6"
                        eventKey="place"
                        onClick={() => {
                          locationType.onChange(LocationType.Place)
                          focusField(LocationType.Place)
                        }}
                      >
                        <Icon.InternalAsset
                          assetName={
                            locationType.value === LocationType.Place
                              ? 'Place-Incoming'
                              : locationType.value === LocationType.PlaceUser
                              ? 'Place-Outgoing'
                              : 'Place-Incoming'
                          }
                          className="tw-mr-3 tw-h-9 tw-w-9 md:tw-h-[50px] md:tw-w-[50px]"
                        />
                        <Translate as="strong">We’ll meet at a place</Translate>
                        {category === Category.Place && (
                          <Icon.InternalAsset
                            assetName="icn-round-check"
                            size={25}
                            className="tw-ml-auto"
                          />
                        )}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="place">
                        <Card.Body>
                          {meetingType.isGroup ? (
                            <Form.Group
                              onClick={() => {
                                locationType.onChange(LocationType.Place)
                                focusField(LocationType.Place)
                              }}
                            >
                              <Form.Label>
                                <Translate>We will meet at:</Translate>
                              </Form.Label>
                              <FinalForm.Field<IMeetingType['placeLocation']>
                                name="placeLocation"
                                validate={(value, allValues: any) => {
                                  return allValues.locationType ===
                                    LocationType.Place && isRequired(value)
                                    ? 'Required'
                                    : undefined
                                }}
                              >
                                {({ input, meta }) => (
                                  <PlacesInput
                                    onChange={input.onChange}
                                    onFocus={input.onFocus}
                                    onBlur={input.onBlur}
                                    value={input.value || ''}
                                    isInvalid={
                                      isFormFieldInvalid(meta) &&
                                      locationType.value === LocationType.Place
                                    }
                                    ref={placeLocationInputRef}
                                  />
                                )}
                              </FinalForm.Field>
                            </Form.Group>
                          ) : (
                            <>
                              <Form.Group
                                onClick={() => {
                                  locationType.onChange(LocationType.Place)
                                  focusField(LocationType.Place)
                                }}
                              >
                                <Form.Check
                                  {...locationType}
                                  checked={
                                    locationType.value === LocationType.Place
                                  }
                                  className="tw-mb-2"
                                  id="meeting-type-location-editor-place"
                                  label="I'll decide where we meet:"
                                  type="radio"
                                  value={LocationType.Place}
                                />

                                <Form.Check>
                                  <FinalForm.Field<
                                    IMeetingType['placeLocation']
                                  >
                                    name="placeLocation"
                                    validate={(value, allValues: any) => {
                                      return allValues.locationType ===
                                        LocationType.Place && isRequired(value)
                                        ? 'Required'
                                        : undefined
                                    }}
                                  >
                                    {({ input, meta }) => (
                                      <PlacesInput
                                        onChange={input.onChange}
                                        onFocus={input.onFocus}
                                        onBlur={input.onBlur}
                                        value={input.value || ''}
                                        isInvalid={
                                          isFormFieldInvalid(meta) &&
                                          locationType.value ===
                                            LocationType.Place
                                        }
                                        ref={placeLocationInputRef}
                                      />
                                    )}
                                  </FinalForm.Field>
                                </Form.Check>
                              </Form.Group>
                              <Form.Group>
                                <Form.Check
                                  {...locationType}
                                  checked={
                                    locationType.value ===
                                    LocationType.PlaceUser
                                  }
                                  id="meeting-type-location-editor-place-user"
                                  label="Attendee will decide where we meet"
                                  type="radio"
                                  value={LocationType.PlaceUser}
                                />
                                <Form.Check>
                                  <Form.Text>
                                    <Translate>
                                      A questionnaire field will be added to
                                      collect their location.
                                    </Translate>
                                  </Form.Text>
                                </Form.Check>
                              </Form.Group>
                            </>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  <Accordion activeKey={category}>
                    <Card
                      className={expandClassName(
                        'tw-overflow-visible',
                        category === Category.PhoneCall
                          ? 'tw-selected'
                          : undefined
                      )}
                    >
                      <Accordion.Toggle
                        as={Card.Header}
                        className="tw-cursor-pointer tw-flex-wrap tw-p-4 md:tw-px-6"
                        eventKey="phone-call"
                        onClick={() => {
                          locationType.onChange(LocationType.Phone)
                          focusField(LocationType.Phone)
                        }}
                      >
                        <Icon.InternalAsset
                          assetName={
                            locationType.value === LocationType.Phone
                              ? 'Phone-Call-Incoming'
                              : locationType.value === LocationType.PhoneUser
                              ? 'Phone-Call-Outgoing'
                              : 'Phone-Call-Incoming'
                          }
                          className="tw-mr-3 tw-h-9 tw-w-9 md:tw-h-[50px] md:tw-w-[50px]"
                        />
                        <Translate as="strong">
                          We’ll meet on a phone call
                        </Translate>
                        {category === Category.PhoneCall && (
                          <Icon.InternalAsset
                            assetName="icn-round-check"
                            size={25}
                            className="tw-ml-auto"
                          />
                        )}
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey="phone-call">
                        <Card.Body>
                          {meetingType.isGroup ? (
                            <Form.Group
                              onClick={() => {
                                locationType.onChange(LocationType.Phone)
                                focusField(LocationType.Phone)
                              }}
                            >
                              <Form.Label>
                                <Translate>Attendees will call me:</Translate>
                              </Form.Label>

                              <FinalForm.Field<IMeetingType['phoneLocation']>
                                name="phoneLocation"
                                validate={(value, allValues: any) => {
                                  return allValues.locationType ===
                                    LocationType.Phone && isRequired(value)
                                    ? 'Required'
                                    : undefined
                                }}
                              >
                                {({ input, meta }) => (
                                  <IconTextInput
                                    {...input}
                                    ref={phoneLocationInputRef}
                                    icon={Icon.Phone}
                                    value={input.value || ''}
                                    isInvalid={
                                      isFormFieldInvalid(meta) &&
                                      locationType.value === LocationType.Phone
                                    }
                                  />
                                )}
                              </FinalForm.Field>

                              <Form.Text>
                                <Translate>
                                  Include any extensions or PINs
                                </Translate>
                              </Form.Text>
                            </Form.Group>
                          ) : (
                            <>
                              <Form.Group
                                onClick={() => {
                                  locationType.onChange(LocationType.Phone)
                                  focusField(LocationType.Phone)
                                }}
                              >
                                <Form.Check
                                  {...locationType}
                                  checked={
                                    locationType.value === LocationType.Phone
                                  }
                                  className="tw-mb-2"
                                  id="meeting-type-location-editor-phone"
                                  label={
                                    meetingType.isGroup
                                      ? 'Attendees will call me:'
                                      : 'Attendee will call me:'
                                  }
                                  value={LocationType.Phone}
                                  type="radio"
                                />
                                <Form.Check>
                                  <FinalForm.Field<
                                    IMeetingType['phoneLocation']
                                  >
                                    name="phoneLocation"
                                    validate={(value, allValues: any) => {
                                      return allValues.locationType ===
                                        LocationType.Phone && isRequired(value)
                                        ? 'Required'
                                        : undefined
                                    }}
                                  >
                                    {({ input, meta }) => (
                                      <IconTextInput
                                        {...input}
                                        ref={phoneLocationInputRef}
                                        icon={Icon.Phone}
                                        value={input.value || ''}
                                        isInvalid={
                                          isFormFieldInvalid(meta) &&
                                          locationType.value ===
                                            LocationType.Phone
                                        }
                                      />
                                    )}
                                  </FinalForm.Field>

                                  <Form.Text>
                                    <Translate>
                                      Include any extensions or PINs
                                    </Translate>
                                  </Form.Text>
                                </Form.Check>
                              </Form.Group>
                              <Form.Group>
                                <Form.Check
                                  {...locationType}
                                  checked={
                                    locationType.value ===
                                    LocationType.PhoneUser
                                  }
                                  id="meeting-type-location-editor-phone-user"
                                  label={
                                    meetingType.isGroup
                                      ? 'I will call the attendees'
                                      : 'I will call the attendee'
                                  }
                                  type="radio"
                                  value={LocationType.PhoneUser}
                                />
                                <Form.Check>
                                  <Form.Text>
                                    <Translate>
                                      A questionnaire field will be added to
                                      collect their phone number.
                                    </Translate>
                                  </Form.Text>
                                </Form.Check>
                              </Form.Group>
                            </>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <MeetingTypeEditorLocationComponent
                    variables={{
                      meetingTypeId: meetingType.id,
                    }}
                    fetchPolicy="network-only"
                    nextFetchPolicy="network-only"
                  >
                    {({ data, loading, refetch }) => {
                      if (!loading && data) {
                        const { providers, meetingTypeMembers } =
                          wireDataToInternalData(data)

                        // format up-to-date member data from HostAssignmentSection
                        const formattedCurrentMeetingTypeMembers =
                          formatCurrentMembers(currentMeetingTypeMembers)

                        // grab the currently logged-in member from query response, for new conferencing integrations made in SettingsModal
                        const currentlyLoggedInMember = meetingTypeMembers.find(
                          mem => {
                            return mem.memberId === member.id
                          }
                        )

                        // generate array of members from current members in HostAssignmentSection, with currently logged-in member replaced with data from query for up-to-date integrations
                        const membersToUse =
                          formattedCurrentMeetingTypeMembers.map(mem => {
                            return mem.memberId ===
                              currentlyLoggedInMember?.memberId
                              ? currentlyLoggedInMember
                              : mem
                          })

                        return (
                          <FinalForm.Field<IMeetingType['conferencingProvider']>
                            allowNull
                            name="conferencingProvider"
                          >
                            {({
                              input: { onChange, value: conferencingProvider },
                            }) => (
                              <Accordion
                                activeKey={category}
                                onClick={() => {
                                  if (!conferencingProvider) {
                                    onChange({ id: '', slug: 'google' })
                                    locationType.onChange(
                                      LocationType.GoogleMeet
                                    )
                                    return
                                  }
                                  locationType.onChange(
                                    LocationType.WebConference
                                  )
                                }}
                              >
                                <Card
                                  className={
                                    category === Category.WebConference
                                      ? 'tw-selected'
                                      : undefined
                                  }
                                >
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    className="tw-cursor-pointer tw-flex-wrap tw-p-4 md:tw-px-6"
                                    eventKey="web-conferencing"
                                  >
                                    <Icon.InternalAsset
                                      assetName="Web-Conference"
                                      className="tw-mr-3 tw-h-9 tw-w-9 md:tw-h-[50px] md:tw-w-[50px]"
                                    />
                                    <Translate as="strong">
                                      Meet on a web conference
                                    </Translate>
                                    {category === Category.WebConference && (
                                      <Icon.InternalAsset
                                        className="tw-ml-auto"
                                        assetName="icn-round-check"
                                        size={25}
                                      />
                                    )}
                                  </Accordion.Toggle>

                                  <Accordion.Collapse eventKey="web-conference">
                                    <Card.Body
                                      className="tw-p-0 tw-rounded-none last:tw-border-b-0"
                                      as={ListGroup}
                                    >
                                      {/*Google Meet is special, so we hard-code it in here. */}
                                      <ListGroup.Item
                                        className="tw-border-x-0 tw-cursor-pointer tw-flex tw-flex-col tw-px-6 tw-py-4 tw-space-y-2 md:tw-flex-row md:tw-justify-between md:tw-items-center md:tw-space-y-0 hover:tw-bg-bsPrimary/[0.1]"
                                        onClick={(ev: any) => {
                                          // Google Meet isn't a conference provider, so null it out
                                          onChange(null)

                                          // Update the locationType to Google Meet
                                          locationType.onChange(
                                            LocationType.GoogleMeet
                                          )

                                          // Make sure the accordion doesn't hear about this,
                                          // otherwise it's going to try and switch back to LocationType.WebConference.
                                          ev.stopPropagation()
                                        }}
                                      >
                                        <div className="tw-flex tw-items-center tw-space-x-4">
                                          <Form.Check
                                            checked={
                                              locationType.value ===
                                              LocationType.GoogleMeet
                                            }
                                            type="radio"
                                            readOnly
                                          />
                                          <Icon.InternalAsset
                                            assetName={'google'}
                                            size={35}
                                          />
                                          <Translate>Google Meet</Translate>

                                          {locationType.value ===
                                            LocationType.GoogleMeet &&
                                            !membersToUse.find(
                                              member => !member.hasGoogleAccount
                                            ) && (
                                              <TooltipOverlay
                                                id="warning"
                                                text={
                                                  <Translate>
                                                    Host(s) must connect a
                                                    Google account
                                                  </Translate>
                                                }
                                                delay={{ show: 250, hide: 0 }}
                                              >
                                                <Icon.AlertTriangle
                                                  className="text-danger md:tw-hidden"
                                                  onClick={() =>
                                                    setActiveTab('team')
                                                  }
                                                  size={24}
                                                />
                                              </TooltipOverlay>
                                            )}
                                        </div>

                                        {/* If this is selected and we have a MTTM that doesn't have a Google calendar account,
                                          we're going to render something to let them know. */}

                                        {locationType.value ===
                                          LocationType.GoogleMeet &&
                                          !!membersToUse.find(
                                            member => !member.hasGoogleAccount
                                          ) && (
                                            <div className="tw-flex tw-items-center">
                                              {/* If the currently logged in member is not connected to google, show them a "Connect" button. */}
                                              {!!membersToUse.find(mem => {
                                                return (
                                                  mem.memberId === member.id &&
                                                  !mem.hasGoogleAccount
                                                )
                                              }) ? (
                                                <Toggler>
                                                  {modal => (
                                                    <>
                                                      <Button
                                                        variant="link"
                                                        onClick={modal.setOn}
                                                        className="tw-flex tw-items-center"
                                                        size="sm"
                                                      >
                                                        <Icon.ExternalLink
                                                          size={16}
                                                        />
                                                        <Translate>
                                                          Set up Google Calendar
                                                          integration
                                                        </Translate>
                                                      </Button>

                                                      {modal.isToggled && (
                                                        <SettingsModal
                                                          initialSection="calendars"
                                                          onHide={async () => {
                                                            modal.setOff()
                                                            // We refetch here instead of performing a 'refetchQueries'
                                                            // on the mutations in 'calendars' & 'web-conferencing' because
                                                            // those screens are accesses in other scopes than just this one.
                                                            await refetch({
                                                              meetingTypeId:
                                                                meetingType.id,
                                                            })
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </Toggler>
                                              ) : (
                                                /* If there are many hosts, or the only host isn't the
                                                current member, show them an alert */
                                                <TooltipOverlay
                                                  id="warning"
                                                  text={
                                                    <Translate>
                                                      Host(s) must connect a
                                                      Google account
                                                    </Translate>
                                                  }
                                                  delay={{ show: 250, hide: 0 }}
                                                >
                                                  <Icon.AlertTriangle
                                                    className="text-danger"
                                                    onClick={() =>
                                                      setActiveTab('team')
                                                    }
                                                    size={24}
                                                  />
                                                </TooltipOverlay>
                                              )}
                                            </div>
                                          )}
                                      </ListGroup.Item>

                                      {/*Now loop through each provider and print out a row*/}
                                      {providers.map(provider => (
                                        <ListGroup.Item
                                          className="tw-border-x-0 tw-cursor-pointer tw-flex tw-flex-col tw-px-6 tw-py-4 tw-space-y-2 md:tw-flex-row md:tw-justify-between md:tw-items-center md:tw-space-y-0 hover:tw-bg-bsPrimary/[0.1]"
                                          key={provider.id}
                                          onClick={(ev: any) => {
                                            // Set the provider.
                                            onChange(provider)
                                            // Set the locationType.
                                            locationType.onChange(
                                              LocationType.WebConference
                                            )
                                            ev.stopPropagation()
                                          }}
                                        >
                                          <div className="tw-flex tw-items-center tw-space-x-4">
                                            <Form.Check
                                              checked={
                                                provider.slug ===
                                                conferencingProvider?.slug
                                              }
                                              type="radio"
                                              readOnly
                                            />

                                            <Icon.InternalAsset
                                              assetName={provider.slug}
                                              size={35}
                                            />
                                            <span>{provider.name}</span>
                                            {provider.slug ===
                                              conferencingProvider?.slug &&
                                              !membersToUse.find(
                                                member =>
                                                  !member.conferencingProviders.includes(
                                                    provider.slug
                                                  )
                                              ) && (
                                                <TooltipOverlay
                                                  id="warning"
                                                  text={
                                                    <Translate>
                                                      Host(s) must connect a{' '}
                                                      <Var name="name">
                                                        {provider.name}
                                                      </Var>{' '}
                                                      account
                                                    </Translate>
                                                  }
                                                  delay={{ show: 250, hide: 0 }}
                                                >
                                                  <Icon.AlertTriangle
                                                    className="text-danger md:tw-hidden"
                                                    onClick={() =>
                                                      setActiveTab('team')
                                                    }
                                                    size={24}
                                                  />
                                                </TooltipOverlay>
                                              )}
                                          </div>

                                          {/* If this is selected and we have a MTTM that doesn't have a conferencing account that matches the provider,
                                          we're going to render something to let them know. */}
                                          {provider.slug ===
                                            conferencingProvider?.slug &&
                                          !!membersToUse.find(
                                            member =>
                                              !member.conferencingProviders.includes(
                                                provider.slug
                                              )
                                          ) ? (
                                            <div className="tw-flex tw-items-center">
                                              {/* If the currently logged in member is not connected, show them a "Connect" button. */}
                                              {!!membersToUse.find(mem => {
                                                return (
                                                  mem.memberId === member.id &&
                                                  !mem.conferencingProviders.includes(
                                                    provider.slug
                                                  )
                                                )
                                              }) ? (
                                                <Toggler>
                                                  {modal => (
                                                    <>
                                                      <Button
                                                        variant="link"
                                                        onClick={modal.setOn}
                                                        className="tw-flex tw-items-center"
                                                        size="sm"
                                                      >
                                                        <Icon.ExternalLink
                                                          size={16}
                                                        />
                                                        <Translate>
                                                          Set up{' '}
                                                          <Var name="name">
                                                            {provider.name}
                                                          </Var>{' '}
                                                          integration
                                                        </Translate>
                                                      </Button>

                                                      {modal.isToggled && (
                                                        <SettingsModal
                                                          initialSection="web-conferencing"
                                                          onHide={async () => {
                                                            modal.setOff()
                                                            // We refetch here instead of performing a 'refetchQueries'
                                                            // on the mutations in 'calendars' & 'web-conferencing' because
                                                            // those screens are accesses in other scopes than just this one.
                                                            await refetch({
                                                              meetingTypeId:
                                                                meetingType.id,
                                                            })
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                  )}
                                                </Toggler>
                                              ) : (
                                                /* If there are many hosts, or the only host isn't the
                                                current member, show them an alert */
                                                <TooltipOverlay
                                                  id="warning"
                                                  text={
                                                    <Translate>
                                                      Host(s) must connect a{' '}
                                                      <Var name="name">
                                                        {provider.name}
                                                      </Var>{' '}
                                                      account
                                                    </Translate>
                                                  }
                                                  delay={{ show: 250, hide: 0 }}
                                                >
                                                  <Icon.AlertTriangle
                                                    className="text-danger"
                                                    onClick={() =>
                                                      setActiveTab('team')
                                                    }
                                                    size={24}
                                                  />
                                                </TooltipOverlay>
                                              )}
                                            </div>
                                          ) : null}
                                        </ListGroup.Item>
                                      ))}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            )}
                          </FinalForm.Field>
                        )
                      }
                      return null
                    }}
                  </MeetingTypeEditorLocationComponent>
                </>
              )
            }}
          </FinalForm.Field>
        </Form.Group>
      </Modal.Body>
    </div>
  )
}

export default UpdateMeetingTypeModalLocationSection
