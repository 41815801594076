import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, ListGroup, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'
import Alert from './Alert'
import HostAssignmentStrategyPicker from './HostAssignmentStrategyPicker'
import Icon from './Icon'
import ProfileContext from './ProfileContext'
import UpdateMeetingTypeContext, {
  IMeetingTypeTeamMember,
} from './UpdateMeetingTypeContext'

import {
  HostAssignmentStrategy,
  useUpdateMeetingTypeModalHostAssignmentSectionQuery,
  MeetingTypeTeamMemberNode,
  UserNode,
  TeamMemberNode,
  MemberNode,
  Maybe,
  LocationType,
} from '../__generated__/graphql'
import { ConferenceProvider, ID, Schedule } from '../types'
import MemberPicker from './MemberPicker'
import UpdateMeetingTypeTeamMemberAvailabilityModal from './UpdateMeetingTypeTeamMemberAvailabilityModal'
import UnsupportedPlan from './UnsupportedPlan'
import { orderBy, uniqBy } from 'lodash'
import { Duration, isNotSoftDeleted } from '../utils'
import MemberContext from './MemberContext'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableHostAssignmentMemberUnit } from './HostAssignmentMemberUnit'
import Translate from './Translate'
import MeetingTypeEditorSectionPicker from './MeetingTypeEditorSectionPicker'
import { MTESection } from './UpdateMeetingTypeModal'

gql`
  query UpdateMeetingTypeModalHostAssignmentSection($teamId: ID!) {
    team: getTeamById(id: $teamId) {
      id
      teamMembers {
        edges {
          node {
            id
            member {
              id
              invitationAccepted
              user {
                email
                firstName
                id
                image
                lastName
                timezone
              }
              calendarAccounts {
                edges {
                  node {
                    id
                    provider {
                      id
                      slug
                    }
                  }
                }
              }
              conferencingAccounts {
                edges {
                  node {
                    id
                    provider {
                      id
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// Helper typing for MeetingTypeTeamMembers
type AddedMeetingTypeTeamMember = Pick<
  MeetingTypeTeamMemberNode,
  'active' | 'customAvailability' | 'willHost' | 'priority' | 'order'
> & {
  teamMember: Pick<TeamMemberNode, 'id'> & {
    member: Pick<MemberNode, 'id'> & {
      user: Pick<UserNode, 'id' | 'firstName' | 'lastName' | 'email' | 'image'>
    }
  }
  schedules: Array<Schedule>
}

type Props = {
  onSelect: (section: MTESection) => void
}

const UpdateMeetingTypeModalHostAssignmentSection: React.FC<Props> = ({
  onSelect,
}) => {
  const profile = React.useContext(ProfileContext)
  const meetingType = React.useContext(UpdateMeetingTypeContext)
  const member = React.useContext(MemberContext)

  const [teamMemberEditingAvailability, setTeamMemberEditingAvailability] =
    React.useState<Maybe<IMeetingTypeTeamMember>>(null)

  // Fetch a list of all team members in the meeting type's team so that when
  // we want to create a new MTTM, we can get some of the necessary data from here.
  const { data } = useUpdateMeetingTypeModalHostAssignmentSectionQuery({
    variables: {
      teamId: meetingType.team.id,
    },
    fetchPolicy: 'network-only', //first fetch
    nextFetchPolicy: 'network-only', // all subsequent fetches
  })

  // Helper to convert a memberId into a new MeetingTypeTeamMember-like struct
  const memberIdToMeetingTypeTeamMember = (
    memberId: ID
  ): AddedMeetingTypeTeamMember => {
    // Find the teamMember that matches the member ID
    const edge = data!.team.teamMembers.edges.find(
      edge => memberId === edge!.node!.member.id
    )

    const teamMemberNode = edge!.node!

    // Return a MTTM with sane defaults
    return {
      active: teamMemberNode.member.invitationAccepted,
      customAvailability: false,
      willHost: false,
      priority: 0,
      order: 0,
      teamMember: {
        ...teamMemberNode,
        member: {
          ...teamMemberNode.member,
          calendarAccounts: teamMemberNode.member.calendarAccounts.edges.map(
            edge => edge!.node!
          ),
          conferencingAccounts:
            teamMemberNode.member.conferencingAccounts.edges.map(
              edge => edge!.node!
            ),
        },
      },
      schedules: [],
    }
  }

  // Get the duration field so we can access it's value.
  const {
    input: { value: meetingDuration },
  } = FinalForm.useField<Duration>('duration')

  // Get the hostAssignmentStrategy field so we can access it's value and update it.
  const { input: hostAssignmentStrategyInput } =
    FinalForm.useField<HostAssignmentStrategy>('hostAssignmentStrategy')

  // Get the meetingTypeTeamMembers field so we can access it's value and update it.
  const { input: meetingTypeTeamMembersInput } = FinalForm.useField<
    Array<IMeetingTypeTeamMember>
  >('meetingTypeTeamMembers')

  // Get the locationType because we need to know it to draw conferencing errors
  const {
    input: { value: locationType },
  } = FinalForm.useField<LocationType>('locationType')

  // Get the conferencingProvider because we need to know it to draw conferencing errors
  const {
    input: { value: conferencingProvider },
  } = FinalForm.useField<Pick<ConferenceProvider, 'id' | 'name' | 'slug'>>(
    'conferencingProvider'
  )

  // We have an annoying problem which is that if the user goes to the location screen and
  // connects a conferencing account, final-form won't update the meetingTypeTeamMembers value,
  // so when reporting the conferencing errors, etc, we need the most up-to-date data,
  // so we need to get it from meetingType.meetingTypeTeamMembers.
  const meetingTypeTeamMembers = React.useMemo(() => {
    // Get the MTTM for the current user, if any
    const meetingTypeTeamMemberForCurrentUser =
      meetingType.meetingTypeTeamMembers.find(
        mem => mem.teamMember.member.id === member.id
      )

    return orderBy(
      meetingTypeTeamMembersInput.value.map(mem =>
        mem.teamMember.member.id === member.id
          ? !!meetingTypeTeamMemberForCurrentUser
            ? {
                ...mem,
                teamMember: {
                  ...mem.teamMember,
                  member: {
                    ...mem.teamMember.member,
                    calendarAccounts:
                      meetingTypeTeamMemberForCurrentUser.teamMember.member
                        .calendarAccounts,
                    conferencingAccounts:
                      meetingTypeTeamMemberForCurrentUser.teamMember.member
                        .conferencingAccounts,
                  },
                },
              }
            : mem
          : mem
      ),
      ['order', 'teamMember.member.invitationAccepted'],
      ['asc', 'desc']
    )
  }, [
    meetingType.meetingTypeTeamMembers,
    meetingTypeTeamMembersInput.value,
    member.id,
  ])

  return (
    <div className="UpdateMeetingTypeModalHostAssignmentSection tw-w-full">
      <Modal.Header closeButton>
        <Icon.InternalAsset
          assetName="icn-mte-header-team"
          className="tw-hidden lg:tw-block"
        />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title>
            <Translate>Host Assignment</Translate>
          </Modal.Title>
          <Translate as="p">
            Determine who will be the host of new meetings
          </Translate>
        </div>
        <MeetingTypeEditorSectionPicker onChange={onSelect} value="team" />
      </Modal.Header>
      <Modal.Body>
        <Form.Label className="tw-mb-2">
          <Translate>
            Who will meet with attendees that schedule this meeting?
          </Translate>
        </Form.Label>
        <div className="tw-flex tw-flex-col tw-space-y-4">
          <>
            <HostAssignmentStrategyPicker
              onChange={hostAssignmentStrategyInput.onChange}
              value={hostAssignmentStrategyInput.value}
            />

            {!profile.plan.allowRoundRobin &&
              hostAssignmentStrategyInput.value ===
                HostAssignmentStrategy.Pooled && (
                <div className="tw-mt-4">
                  <UnsupportedPlan source="Round-Robin" />
                </div>
              )}

            <Form.Group>
              <Form.Label>
                <Translate>
                  {hostAssignmentStrategyInput.value !==
                  HostAssignmentStrategy.Pooled
                    ? 'Who should new meetings be assigned to?'
                    : 'Who should be eligible to be picked in the round-robin?'}
                </Translate>
              </Form.Label>

              <div className="tw-flex tw-flex-col tw-items-start tw-space-y-2 md:tw-flex-row md:tw-items-center md:tw-space-y-0 md:tw-space-x-4">
                <div className="tw-w-full md:tw-w-80">
                  <MemberPicker
                    value={null}
                    onChange={memberId =>
                      meetingTypeTeamMembersInput.onChange([
                        ...meetingTypeTeamMembersInput.value,
                        memberIdToMeetingTypeTeamMember(memberId),
                      ])
                    }
                    filterOptions={member =>
                      !meetingTypeTeamMembersInput.value.find(
                        mttm => member.id === mttm.teamMember.member.id
                      )
                    }
                  />
                </div>

                {data?.team.teamMembers.edges.length !==
                  meetingTypeTeamMembersInput.value.length && (
                  <Button
                    variant="link"
                    onClick={() =>
                      meetingTypeTeamMembersInput.onChange([
                        // Make sure our existing MTTMs stay untouched
                        ...meetingTypeTeamMembersInput.value,

                        // Loop through the list of all possible members and
                        // add the missing one.
                        ...data!.team.teamMembers.edges
                          .filter(
                            edge =>
                              !meetingTypeTeamMembersInput.value.find(
                                mttm =>
                                  mttm.teamMember.member.id ===
                                  edge!.node!.member.id
                              )
                          )
                          .map(edge =>
                            memberIdToMeetingTypeTeamMember(
                              edge!.node!.member.id
                            )
                          ),
                      ])
                    }
                  >
                    + <Translate>Add all members</Translate>
                  </Button>
                )}
              </div>
            </Form.Group>

            {hostAssignmentStrategyInput.value ===
              HostAssignmentStrategy.Pooled &&
              !meetingTypeTeamMembersInput.value.find(mttm => mttm.active) && (
                <Alert variant="danger">
                  <Translate>
                    There must be at least one active member in the pool.
                  </Translate>
                </Alert>
              )}

            {hostAssignmentStrategyInput.value ===
              HostAssignmentStrategy.Joint &&
              !meetingTypeTeamMembersInput.value.find(
                mttm => mttm.active && mttm.willHost
              ) && (
                <Alert variant="danger">
                  <Translate>
                    At least one active member must be assigned hosting duties.
                  </Translate>
                </Alert>
              )}

            {teamMemberEditingAvailability && (
              <UpdateMeetingTypeTeamMemberAvailabilityModal
                value={{
                  customAvailability:
                    teamMemberEditingAvailability.customAvailability,
                  schedules: teamMemberEditingAvailability.schedules,
                }}
                onChange={value =>
                  meetingTypeTeamMembersInput.onChange(
                    meetingTypeTeamMembersInput.value.map(
                      ({
                        customAvailability,
                        schedules,
                        teamMember,
                        ...rest
                      }) => ({
                        ...rest,

                        // If this is the mttm we were just editing, use the new data.
                        customAvailability:
                          teamMember.id ===
                          teamMemberEditingAvailability.teamMember.id
                            ? value.customAvailability
                            : customAvailability,
                        schedules:
                          teamMember.id ===
                          teamMemberEditingAvailability.teamMember.id
                            ? value.schedules
                            : schedules,
                        teamMember,
                      })
                    )
                  )
                }
                onHide={() => setTeamMemberEditingAvailability(null)}
                defaultSchedules={meetingType.schedules}
                minimumTimeBlockDuration={meetingDuration}
              />
            )}

            <DndContext
              sensors={useSensors(
                // Don't consider the drag activated unless it's been moved at least
                // 10px.  We do this so that dnd-kit doesn't preventDefault on click
                // events that bubble up from the card below.
                useSensor(PointerSensor, {
                  activationConstraint: {
                    distance: 10,
                  },
                })
              )}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={ev => {
                // If there was no valid drop or it's the same item, stop here.
                if (!ev.over || ev.active.id === ev.over.id) return

                // Calculate the index of the meeting types in the original list
                const oldIndex = meetingTypeTeamMembers.findIndex(
                  member => member.id === ev.active.id
                )
                const newIndex = meetingTypeTeamMembers.findIndex(
                  member => member.id === ev.over!.id
                )

                // Update the order of the MTTMs
                meetingTypeTeamMembersInput.onChange(
                  arrayMove(meetingTypeTeamMembers, oldIndex, newIndex).map(
                    (member, idx) => ({
                      ...member,
                      order: idx + 1,
                    })
                  )
                )
              }}
            >
              <SortableContext
                items={meetingTypeTeamMembers}
                strategy={verticalListSortingStrategy}
              >
                <ListGroup>
                  {meetingTypeTeamMembers.map(mttm => (
                    <ListGroup.Item
                      key={mttm.teamMember.member.id}
                      // p-0 bg-transparent border-gray-300 border-left-0 border-right-0
                      className="tw-bg-transparent tw-p-0"
                    >
                      <SortableHostAssignmentMemberUnit
                        id={mttm.id}
                        firstName={mttm.teamMember.member.user.firstName}
                        lastName={mttm.teamMember.member.user.lastName}
                        email={mttm.teamMember.member.user.email}
                        image={mttm.teamMember.member.user.image}
                        fullyOnboarded={
                          mttm.teamMember.member.invitationAccepted
                        }
                        calendarIntegrations={uniqBy(
                          mttm.teamMember.member.calendarAccounts,
                          'slug'
                        ).map(ca => ({
                          slug: ca.provider.slug,
                          name: ca.provider.slug,
                        }))}
                        conferencingIntegrations={uniqBy(
                          mttm.teamMember.member.conferencingAccounts.filter(
                            isNotSoftDeleted
                          ),
                          'slug'
                        ).map(ca => ({
                          slug: ca.provider.slug,
                          name: ca.provider.slug,
                        }))}
                        hostAssignmentStrategy={
                          hostAssignmentStrategyInput.value
                        }
                        isHost={mttm.willHost}
                        active={mttm.active}
                        priority={mttm.priority}
                        otherMembersExist={meetingTypeTeamMembers.length > 1}
                        integrationsErrorMessage={
                          locationType === LocationType.GoogleMeet &&
                          !mttm.teamMember.member.calendarAccounts.find(
                            ca => ca.provider.slug === 'google'
                          )
                            ? 'Needs to connect a Google Calendar account to enable Google Meet'
                            : locationType === LocationType.WebConference &&
                              !mttm.teamMember.member.conferencingAccounts.find(
                                ca =>
                                  isNotSoftDeleted(ca) &&
                                  ca.provider.slug === conferencingProvider.slug
                              )
                            ? `Needs to connect a ${conferencingProvider.name} account.`
                            : null
                        }
                        onEditAvailability={() =>
                          setTeamMemberEditingAvailability(mttm)
                        }
                        onChangeHost={() =>
                          meetingTypeTeamMembersInput.onChange(
                            meetingTypeTeamMembers.map(mttmToUpdate => ({
                              ...mttmToUpdate,
                              // Only the mttm that just claimed to be a host should be host.
                              willHost: mttmToUpdate === mttm,
                            }))
                          )
                        }
                        onChangePriority={priority =>
                          meetingTypeTeamMembersInput.onChange(
                            meetingTypeTeamMembers.map(mttmToUpdate => ({
                              ...mttmToUpdate,
                              priority:
                                // Only update the priority for the mttm in question.
                                mttmToUpdate === mttm
                                  ? priority
                                  : mttmToUpdate.priority,
                            }))
                          )
                        }
                        onRemove={() => {
                          if (
                            mttm.active &&
                            meetingTypeTeamMembers.filter(mttm => mttm.active)
                              .length === 1
                          ) {
                            alert('You must have at least one active member.')
                            return
                          }

                          if (
                            mttm.willHost &&
                            hostAssignmentStrategyInput.value ===
                              HostAssignmentStrategy.Joint
                          ) {
                            alert(
                              'Please choose a new host before removing this member.'
                            )
                            return
                          }

                          meetingTypeTeamMembersInput.onChange(
                            meetingTypeTeamMembers
                              .filter(filterMttm => mttm !== filterMttm)
                              .map((mapMttm, idx) => ({
                                ...mapMttm,
                                // If we just deleted the host, assign to the first
                                // of the remaining members.
                                willHost:
                                  // If the deleted MTTM was the host, and we're on the first
                                  // of the remaining mttms, make it the host, otherwise fall
                                  // back to whatever value it had before.
                                  mttm.willHost && idx === 0
                                    ? true
                                    : mapMttm.willHost,
                              }))
                          )
                        }}
                        onChangeActive={() => {
                          if (
                            mttm.active &&
                            meetingTypeTeamMembers.filter(mttm => mttm.active)
                              .length === 1
                          ) {
                            alert('You must have at least one active member.')
                            return
                          }

                          if (
                            mttm.active &&
                            mttm.willHost &&
                            hostAssignmentStrategyInput.value ===
                              HostAssignmentStrategy.Joint
                          ) {
                            alert(
                              'Please choose a new host before deactivating this member.'
                            )
                            return
                          }

                          meetingTypeTeamMembersInput.onChange(
                            meetingTypeTeamMembers.map(mttmToUpdate => ({
                              ...mttmToUpdate,
                              active:
                                // Only update the active flag for the mttm in question.
                                mttmToUpdate === mttm
                                  ? !mttmToUpdate.active
                                  : mttmToUpdate.active,
                            }))
                          )
                        }}
                      />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </SortableContext>
            </DndContext>
          </>
        </div>
      </Modal.Body>
    </div>
  )
}

export default UpdateMeetingTypeModalHostAssignmentSection
