import * as React from 'react'
import { Form } from 'react-bootstrap'

import DateInput from './DateInput'
import PlacesInput from './PlacesInput'
import { FormField, FormFieldType } from '../types'
import { DateTime } from '../utils'

type Props = {
  field: Pick<FormField, 'choices' | 'fieldType' | 'helpText' | 'name'>
  onChange?: (value: any) => void
  value?: any
}

// TYPESCRIPT
// @ts-ignore - a king's ransom to whomever can figure this error out
const FormFieldControl: React.FC<Props> = ({
  field,
  onChange: onChangeParent,
  value: encodedValue,
}) => {
  let value: any = undefined

  try {
    // Decode the value as it will be JSON-encoded
    value = JSON.parse(encodedValue)
  } catch (e) {
    value = field.fieldType === FormFieldType.Multichoice ? [] : ''
  }

  // Wrapper to re-encode as JSON
  const onChange = (value: any) => {
    if (onChangeParent) {
      onChangeParent(JSON.stringify(value))
    }
  }

  switch (field.fieldType) {
    case FormFieldType.Toggle:
      return (
        <Form.Check
          checked={value}
          id={field.name}
          label={field.helpText}
          onChange={() => onChange(!value)}
          type="checkbox"
        />
      )
    case FormFieldType.Choice:
      return (
        <Form.Control
          as="select"
          onChange={ev => onChange(ev.currentTarget.value)}
          value={value}
        >
          {field.choices!.map((choice, i) => (
            <option key={`${choice}--${i}`} value={choice}>
              {choice}
            </option>
          ))}
        </Form.Control>
      )
    case FormFieldType.Date:
      return (
        <DateInput
          onSelect={d => onChange(d.toISO())}
          selected={new DateTime(value)}
        />
      )
    case FormFieldType.Multichoice:
      // Assert this as a list of strings
      const multiValue = value as Array<string>

      return field.choices!.map((choice, i) => (
        <Form.Check
          checked={multiValue.includes(choice)}
          id={`${choice}--${i}`}
          key={`${choice}--${i}`}
          label={choice}
          onChange={() => {
            // If it's already included, remove it
            if (multiValue.includes(choice)) {
              onChange(multiValue.filter(c => c !== choice))
            } else {
              // If not, add it
              onChange([...multiValue, choice])
            }
          }}
          type="checkbox"
        />
      ))
    case FormFieldType.Multitext:
      return (
        <Form.Control
          as="textarea"
          onChange={ev => onChange(ev.currentTarget.value)}
          rows={3}
          value={value}
        />
      )
    case FormFieldType.Place:
      return <PlacesInput onChange={onChange} value={value} />
    case FormFieldType.Text:
      return (
        <Form.Control
          as="input"
          onChange={ev => onChange(ev.currentTarget.value)}
          type="text"
          value={value}
        />
      )
    case FormFieldType.Time:
      return (
        <Form.Control
          as="input"
          onChange={ev => onChange(ev.currentTarget.value)}
          type="time"
          value={value}
        />
      )
  }
}

export default FormFieldControl
