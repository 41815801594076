import * as React from 'react'
import './MeetingDetailTabButton.scss'

export interface MeetingDetailTabButtonProps {
  readonly title: string
  readonly active: boolean
  readonly onClick: () => void
}

const MeetingDetailTabButton: React.FC<MeetingDetailTabButtonProps> = ({
  title,
  onClick,
  active,
}: MeetingDetailTabButtonProps) => (
  <div
    onClick={onClick}
    className={`MeetingDetailTabButton d-flex py-12 justify-content-center ${
      active ? 'active' : 'non-active'
    }`}
  >
    <h6>{title}</h6>
  </div>
)
export default MeetingDetailTabButton
