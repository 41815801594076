import * as React from 'react'
import { ToastContainer as ReactToastContainer } from 'react-toastify'

import './ToastContainer.scss'

// Pull in toastify styles
if (process.env.NODE_ENV !== 'test') {
  // Prevents Jest from trying to parse css.
  require('react-toastify/dist/ReactToastify.min.css')
}

interface Props {}

const ToastContainer: React.FC<Props> = () => (
  <div className="ToastContainer">
    <ReactToastContainer
      closeButton={false}
      hideProgressBar={true}
      position="top-center"
    />
  </div>
)

export default ToastContainer
