import * as React from 'react'
import { uniq } from 'lodash'

import { Language } from '../__generated__/graphql'
import { Schedule, ScheduleKind, Weekday } from '../types'
import { DateTime, weekdaysToDateTimes, dateRangeLabelRenderer } from '../utils'

interface Props {
  schedule: Schedule
}

const formatter = (schedule: Schedule): string => {
  switch (schedule.kind) {
    case ScheduleKind.Recurring:
      // Parse the enum values to an array of equivalent numbers.
      const weekdays = schedule.timeBlocks.map(block => {
        return block.weekday!
      })

      // Check if the array contains all days.
      if (
        weekdays.includes(Weekday.Monday) &&
        weekdays.includes(Weekday.Tuesday) &&
        weekdays.includes(Weekday.Wednesday) &&
        weekdays.includes(Weekday.Thursday) &&
        weekdays.includes(Weekday.Friday) &&
        weekdays.includes(Weekday.Saturday) &&
        weekdays.includes(Weekday.Sunday)
      ) {
        return 'Everyday'
      }
      // Check if the array contains only "Saturday" & "Sunday".
      if (
        !weekdays.includes(Weekday.Monday) &&
        !weekdays.includes(Weekday.Tuesday) &&
        !weekdays.includes(Weekday.Wednesday) &&
        !weekdays.includes(Weekday.Thursday) &&
        !weekdays.includes(Weekday.Friday) &&
        weekdays.includes(Weekday.Saturday) &&
        weekdays.includes(Weekday.Sunday)
      ) {
        return 'Weekends'
      }
      // Check if array contains only "weekdays".
      if (
        weekdays.includes(Weekday.Monday) &&
        weekdays.includes(Weekday.Tuesday) &&
        weekdays.includes(Weekday.Wednesday) &&
        weekdays.includes(Weekday.Thursday) &&
        weekdays.includes(Weekday.Friday)
      ) {
        return 'Weekdays'
      }

      const locale = new DateTime().getLocale()
      const weekdayMap = weekdaysToDateTimes()
      return uniq(weekdays)
        .map(day =>
          weekdayMap[day]
            // NOTE Why?
            // We explicitly set the locale here because `weekdaysToDateTimes`
            // is created at build time with the locale at build time and will
            // not change on it's own.
            .setLocale(locale as Language)
            .format('localized-full-day-of-week-plural')
        )
        .join(', ')

    case ScheduleKind.FixedRange:
      return schedule.bounds ? dateRangeLabelRenderer(schedule.bounds) : ''
    default:
      return ''
  }
}

const ScheduleDescription: React.FC<Props> = ({ schedule }) => {
  return <span>{formatter(schedule)}</span>
}

export default ScheduleDescription
