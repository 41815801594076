import * as React from 'react'
import { OverlayTrigger, Tooltip, OverlayTriggerProps } from 'react-bootstrap'

interface Props extends Omit<OverlayTriggerProps, 'overlay'> {
  id: string
  text: string | JSX.Element
  disabled?: boolean

  // Make overlay optional to the caller, but available if they want to use it.
  overlay?: OverlayTriggerProps['overlay']
}

const TooltipOverlay: React.FC<Props> = ({
  text,
  id,
  disabled,
  children,
  ...overlayProps
}) =>
  disabled ? (
    <>{children}</>
  ) : (
    <OverlayTrigger
      placement="top"
      delay={{ show: 500, hide: 0 }}
      transition={false}
      overlay={<Tooltip id={id}>{text}</Tooltip>}
      {...overlayProps}
    >
      {children}
    </OverlayTrigger>
  )

export default TooltipOverlay
