import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import FormErrorSubscription from './FormErrorSubscription'
import Icon from './Icon'
import LoadingButton from './LoadingButton'
import Translate from './Translate'
import {
  UpdateUserPasswordInput,
  useChangePasswordModalMutation,
} from '../__generated__/graphql'
import {
  composeValidators,
  env,
  isFormFieldInvalid,
  isRequired,
  mutationErrorsToFormErrors,
  shouldDisable,
  toast,
  hasMinimumLength,
} from '../utils'

gql`
  mutation ChangePasswordModal($input: UpdateUserPasswordInput!) {
    user: updateUserPassword(input: $input) {
      data {
        id
        passwordUpdatedAt
      }
      errors {
        field
        messages
      }
    }
  }
`

export type Props = {
  onHide: () => void
  onSuccess: () => void
}

type FormValues = Omit<UpdateUserPasswordInput, 'clientMutationId'> & {
  confirmPassword: string
}

const ChangePasswordModal: React.FC<Props> = ({ onHide, onSuccess }) => {
  const [updateUserPassword, { loading }] = useChangePasswordModalMutation()
  const onSubmit = async (values: FormValues) => {
    const { confirmPassword, ...rest } = values
    try {
      const res = await updateUserPassword({
        variables: { input: { ...rest } },
      })
      if (res.data?.user?.errors) {
        console.error(
          'updateUserPassword mutation, [ChangePasswordModal.tsx]',
          res.data.user.errors
        )
        const errors = mutationErrorsToFormErrors(res.data.user.errors)
        console.log('mutationErrorsToFormErrors(res.data.user.errors)', errors)
        return errors
      }
      // If data is present trigger the toast to display and close the modal
      if (res.data?.user?.data) {
        toast.success('Password updated')
        onSuccess()
        return
      }
      // Here we are catching sever related errors.
    } catch (error) {
      toast.error('Something went wrong')
      console.error(
        'updateUserPassword mutation, [ChangePasswordModal.tsx]',
        error
      )
    }
  }
  return (
    <Modal className="ChangePasswordModal" onHide={onHide} show size="sm">
      <Modal.Header closeButton>
        <Icon.Lock size="36" />
        <Modal.Title>
          <Translate>Change Password</Translate>
        </Modal.Title>
      </Modal.Header>
      <FinalForm.Form<FormValues>
        onSubmit={onSubmit}
        validate={values => {
          const errors: Record<string, string> = {}
          if (values.confirmPassword !== values.newPassword) {
            errors.confirmPassword = 'Password does not match.'
          }
          return errors
        }}
      >
        {({ handleSubmit, ...rest }) => (
          <Form
            className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
            onSubmit={handleSubmit}
          >
            <Modal.Body>
              <Form.Group>
                <FinalForm.Field<FormValues['oldPassword']> name="oldPassword">
                  {({ input, meta }) => (
                    <React.Fragment>
                      <Form.Label
                        className={
                          isFormFieldInvalid(meta)
                            ? 'tw-text-bsDanger'
                            : undefined
                        }
                      >
                        <Translate>Current Password</Translate>
                      </Form.Label>
                      <Form.Control
                        {...input}
                        isInvalid={isFormFieldInvalid(meta)}
                        type="password"
                      />
                      {!isFormFieldInvalid(meta) ? (
                        <Form.Text className="tw-mt-2">
                          <Translate>
                            Forgot or never set up your password?
                          </Translate>{' '}
                          <a
                            className="tw-text-bsPrimary"
                            href={`${env('LOGIN_URL')}/password_reset`}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <Translate>Request a new password.</Translate>
                          </a>
                        </Form.Text>
                      ) : (
                        <div className="tw-items-center tw-flex">
                          <FormErrorSubscription name="oldPassword" />
                          {meta.submitError && (
                            <a
                              className="tw-ml-2 tw-mt-1 tw-text-bsPrimary"
                              href={`${env('LOGIN_URL')}/password_reset`}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <Translate>Forgot it?</Translate>
                            </a>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </FinalForm.Field>
              </Form.Group>
              <Form.Group>
                <FinalForm.Field<FormValues['newPassword']>
                  name="newPassword"
                  validate={composeValidators(isRequired, hasMinimumLength(8))}
                >
                  {({ input, meta }) => (
                    <React.Fragment>
                      <Form.Label
                        className={
                          isFormFieldInvalid(meta)
                            ? 'tw-text-bsDanger'
                            : undefined
                        }
                      >
                        <Translate>New Password</Translate>
                      </Form.Label>
                      <Form.Control
                        {...input}
                        isInvalid={isFormFieldInvalid(meta)}
                        type="password"
                      />

                      <FormErrorSubscription name="newPassword" />
                    </React.Fragment>
                  )}
                </FinalForm.Field>
              </Form.Group>
              <Form.Group>
                <FinalForm.Field<FormValues['confirmPassword']>
                  name="confirmPassword"
                  validate={composeValidators(isRequired, hasMinimumLength(8))}
                >
                  {({ input, meta }) => (
                    <React.Fragment>
                      <Form.Label
                        className={
                          isFormFieldInvalid(meta)
                            ? 'tw-text-bsDanger'
                            : undefined
                        }
                      >
                        <Translate>Confirm New Password</Translate>
                      </Form.Label>
                      <Form.Control
                        {...input}
                        isInvalid={isFormFieldInvalid(meta)}
                        type="password"
                      />

                      <FormErrorSubscription name="confirmPassword" />
                    </React.Fragment>
                  )}
                </FinalForm.Field>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <LoadingButton
                block
                disabled={shouldDisable(rest)}
                loading={loading}
                type="submit"
              >
                <Translate>Change Password</Translate>
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </FinalForm.Form>
    </Modal>
  )
}

export default ChangePasswordModal
