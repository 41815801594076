import * as React from 'react'
import { Form } from 'react-bootstrap'

import './DurationInput.scss'
import { Duration } from '../utils'
import Translate from './Translate'

export type Props = {
  isInvalid?: boolean
  value: Duration
  includeDays?: boolean
  onChange?: (duration: Duration) => void

  // We add these mostly so that our form validation library
  // can detect when the field is active, touched, etc.
  onBlur?: () => void
  onFocus?: () => void
}

enum Increment {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
}

// Checks each increment type, from largest to smallest, and find the biggest
// one that the duration divides evenly into.
const calculateIncrementAndType = (
  duration: Duration,
  includeDays: boolean
): [number, Increment] => {
  // Start with days if allowed
  if (includeDays) {
    const days = duration.asDays(false)

    if (days === Math.round(days)) {
      return [days, Increment.Days]
    }
  }

  // Now try hours
  const hours = duration.asHours(false)

  if (hours === Math.round(hours)) {
    return [hours, Increment.Hours]
  }

  // Finally return in minutes if nothing else works
  return [duration.asMinutes(false), Increment.Minutes]
}

const DurationInput: React.FC<Props> = ({
  isInvalid,
  value,
  includeDays,
  onChange,
  onBlur,
  onFocus,
}) => {
  // Convert the duration into an increment and increment type
  const [increment, incrementType] = calculateIncrementAndType(
    value,
    includeDays || false
  )

  // We provide refs to our inputs to be able to capture values on both inputs
  const incrementRef: React.RefObject<HTMLInputElement> = React.createRef()
  const incrementTypeRef: React.RefObject<HTMLSelectElement> = React.createRef()

  // Checks both the text box and select menu and reports to the caller a
  // duration that represents their values.
  const didChange = () => {
    const increment = incrementTypeRef.current?.value as Increment
    // NOTE:
    // The input does not allow the UI to show an empty input; however
    // that does not mean the value can't evaluate to an empty string.
    // The check here ensures we do not end up with the `Invalid unit value NaN`.
    const value = incrementRef.current?.value.length
      ? incrementRef.current.value
      : '0'
    onChange && onChange(new Duration({ [increment]: parseInt(value) }))
  }

  return (
    <div
      className={`DurationInput d-flex space-between-8 ${
        // This is to satisfy Bootstrap's Form.Control.Feedback component
        // which typically wants to hide unless the input next to it has
        // this class.
        isInvalid ? 'is-invalid' : ''
      }`}
    >
      <Form.Control
        className="DurationInput--increment"
        isInvalid={isInvalid}
        min={1}
        onChange={didChange}
        onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
          // If the field is empty, make it 0 and call the didChange
          if (ev.currentTarget.value === '') {
            ev.currentTarget.value = '0'
            didChange()
          }

          // Call regular onBlur handler if present
          onBlur && onBlur()
        }}
        onFocus={onFocus}
        ref={incrementRef}
        type="number"
        value={increment.toString()}
      />

      <Form.Control
        as="select"
        className="DurationInput--increment-type"
        isInvalid={isInvalid}
        onChange={didChange}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={incrementTypeRef}
        value={incrementType}
      >
        <Translate as="option" value={Increment.Minutes}>
          Minutes
        </Translate>
        <Translate as="option" value={Increment.Hours}>
          Hours
        </Translate>
        {includeDays && (
          <Translate as="option" value={Increment.Days}>
            Days
          </Translate>
        )}
      </Form.Control>
    </div>
  )
}

export default DurationInput
