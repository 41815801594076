import * as React from 'react'
import { RouteComponentProps } from '@reach/router'

import MemberContext from './MemberContext'
import ProfileContext from './ProfileContext'
import UserContext from './UserContext'

import { Analytics } from '../utils'
import ErrorScreen from './ErrorScreen'

interface Props extends RouteComponentProps {
  profileId?: string
}

const ProfileRoute: React.FC<Props> = ({ children, profileId }) => {
  // Get the user out of the context
  const { settings, user } = React.useContext(UserContext)

  React.useEffect(() => {
    settings.set('profileId', profileId!)
  }, [settings, profileId])

  // Look for the requested profile
  const memberForProfile = user.members.find(
    member => member.profile.id === profileId
  )

  if (!memberForProfile) {
    return (
      <ErrorScreen
        helmetTitle="Not Found"
        title="We couldn't find what you're looking for"
      />
    )
  }

  // Update the default analytics parameters to include the current profileID
  // and whether or not they are the owner.
  Analytics.updateDefaultAttributes({
    profileId: profileId,
    isOwner: memberForProfile.isOwner,
  })

  return (
    <ProfileContext.Provider value={memberForProfile.profile}>
      <MemberContext.Provider value={memberForProfile}>
        {children}
      </MemberContext.Provider>
    </ProfileContext.Provider>
  )
}
export default ProfileRoute
