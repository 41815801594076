import * as React from 'react'
import Image from './Image'

import './UserImage.scss'
import Gravatar from './Gravatar'
import { User } from '../types'

type Props = {
  size: number
  user: Pick<User, 'email' | 'image'>
}

const UserImage: React.FC<Props> = ({ size, user }) => {
  return (
    <div className="UserImage">
      {user.image ? (
        <Image
          roundedCircle
          src={user.image}
          style={{ height: `${size}px`, width: `${size}px` }}
        />
      ) : (
        <Gravatar email={user.email} size={size} />
      )}
    </div>
  )
}

export default UserImage
