import { InternalUser } from '../components/UserContext'
import { env } from './env'
import amplitude from 'amplitude-js'
import { AnalyticsEventAttributes } from './analytics'

const AMPLITUDE_API_KEY = env('AMPLITUDE_API_KEY')

const AmplitudeHandler = {
  initialize: (user: InternalUser) => {
    console.log('AmplitudeHandler.initialize', user)

    if (!AMPLITUDE_API_KEY) {
      return Promise.resolve(false)
    }

    amplitude.getInstance().init(AMPLITUDE_API_KEY)
    amplitude.getInstance().setUserId(user.id)
    amplitude.getInstance().setUserProperties({
      'Sign Up Date': user.createdAt.toISO(),
    })

    return Promise.resolve(true)
  },

  trackEvent: (
    name: string,
    attributes?: AnalyticsEventAttributes
  ): Promise<boolean> => {
    console.log('AmplitudeHandler.trackEvent', name, attributes)

    if (!AMPLITUDE_API_KEY) {
      return Promise.resolve(false)
    }

    // Send event to amplitude
    return new Promise(resolve => {
      amplitude.getInstance().logEvent(name, attributes, () => resolve(true))
    })
  },

  pageChanged: (): Promise<boolean> => {
    console.log('AmplitudeHandler.pageChanged')

    if (!AMPLITUDE_API_KEY) {
      return Promise.resolve(false)
    }

    // Nothing to do here.

    return Promise.resolve(true)
  },
}

export default AmplitudeHandler
