import * as React from 'react'
import * as Draft from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention'
import createLinkPlugin, { defaultTheme } from '@draft-js-plugins/anchor'
import createLinkifyPlugin from '@draft-js-plugins/linkify'
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar'

import './RichTextEditor.scss'
// Import the alignment css from draft-js.
import 'draft-js/dist/Draft.css'
// Import the css for the LinkPlugin.
import '@draft-js-plugins/anchor/lib/plugin.css'
// Import the css for the MentionPlugin.
import '@draft-js-plugins/mention/lib/plugin.css'
// Import the css for the InlineToolbarPlugin.
import '@draft-js-plugins/inline-toolbar/lib/plugin.css'
import RichTextEditorControls from './RichTextEditorControls'

// Create the mentionPlugin with our configuration.
const mentionPlugin = createMentionPlugin({
  // Removes the mention from the UI on delete.
  entityMutability: 'IMMUTABLE',
  // User will trigger the mention suggestion using '#'.
  mentionTrigger: '#',
})

// Create linkPlugin.
export const linkPlugin = createLinkPlugin({
  linkTarget: '_blank',
  // This is needed to strip the default styling off of the links
  theme: {
    ...defaultTheme,
    link: '',
  },
})

// Create linkify plugin
export const linkifyPlugin = createLinkifyPlugin({
  target: '_blank',
})

// Create inlineToolbarPlugin.
const inlineToolbarPlugin = createInlineToolbarPlugin()

// Get the React Component.
const { MentionSuggestions } = mentionPlugin

// Get the React Component.
const { InlineToolbar } = inlineToolbarPlugin

// Get the React Component.
const { LinkButton } = linkPlugin

// Plugins to pass to the <Editor />.
const plugins = [mentionPlugin, inlineToolbarPlugin, linkPlugin, linkifyPlugin]

export interface Props {
  value: string | null
  onChange: (content: string | null) => void
  variables: Variable[]
  placeholder?: string
  editorRef?: React.RefObject<Editor>
  onBlur?: () => void
}

interface Variable {
  name: string
  value: string
}

const RichTextEditor: React.FC<Props> = ({
  value,
  onChange,
  variables,
  placeholder,
  editorRef,
  onBlur,
}) => {
  // Derive the initial state of the editor
  let [editorState, setEditorState] = React.useState<Draft.EditorState>(() =>
    value
      ? Draft.EditorState.createWithContent(
          Draft.convertFromRaw(JSON.parse(value))
        )
      : Draft.EditorState.createEmpty()
  )

  // Manages the search term used in the mention plugin
  const [mentionSearchTerm, setMentionSearchTerm] = React.useState<string>('')
  const [mentionIsOpen, setMentionIsOpen] = React.useState<boolean>(false)

  const editorStateChanged = (editorState: Draft.EditorState) => {
    // Notify the caller that the state has changed
    onChange(
      // Check to see if there is any text present in the RTE.
      editorState.getCurrentContent().hasText()
        ? // Extract the content state and convert it to a JSON-serialized blob
          JSON.stringify(Draft.convertToRaw(editorState.getCurrentContent()))
        : null
    )

    // Update the editor state
    setEditorState(editorState)
  }

  return (
    <div className="RichTextEditor">
      <RichTextEditorControls
        editorState={editorState}
        toggleBlock={(blockType: string): void => {
          editorStateChanged(
            Draft.RichUtils.toggleBlockType(editorState, blockType)
          )
        }}
        toggleInline={(inlineType: string): void => {
          editorStateChanged(
            Draft.RichUtils.toggleInlineStyle(editorState, inlineType)
          )
        }}
      />
      <Editor
        onChange={editorStateChanged}
        editorState={editorState}
        handleKeyCommand={(
          command: string,
          editorState: Draft.EditorState
        ): Draft.DraftHandleValue => {
          const newState = Draft.RichUtils.handleKeyCommand(
            editorState,
            command
          )
          if (newState) {
            editorStateChanged(newState)
            return 'handled'
          }

          return 'not-handled'
        }}
        keyBindingFn={event => {
          if (event.keyCode === 9) {
            editorStateChanged(Draft.RichUtils.onTab(event, editorState, 6))
            return null
          }

          return Draft.getDefaultKeyBinding(event)
        }}
        placeholder={placeholder || ''}
        plugins={plugins}
        spellCheck
        ref={editorRef}
        onBlur={onBlur}
      />
      <MentionSuggestions
        open={mentionIsOpen}
        onOpenChange={setMentionIsOpen}
        onSearchChange={({ value }) => setMentionSearchTerm(value)}
        suggestions={defaultSuggestionsFilter(mentionSearchTerm, variables)}
      />
      <InlineToolbar>
        {props => (
          <>
            <LinkButton {...props} />
          </>
        )}
      </InlineToolbar>
    </div>
  )
}

export default RichTextEditor
