import * as React from 'react'

type Props = {
  size: number
  color: string
}

const ColoredDot: React.FC<Props> = ({ size, color }) => (
  <div
    className="ColoredDot"
    style={{
      width: `${size / 16}rem`,
      height: `${size / 16}rem`,
      backgroundColor: color,
      borderRadius: '50%',
    }}
  ></div>
)

export default ColoredDot
