import * as React from 'react'
import { Alert as BootstrapAlert, AlertProps } from 'react-bootstrap'

import './Alert.scss'
import { expandClassName } from '../utils'

type Props = AlertProps & {}

const Alert: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <BootstrapAlert
      className={expandClassName(
        'tw-flex tw-items-center tw-border-0 tw-mb-0 tw-rounded-[4px] tw-px-3 tw-py-2 tw-whitespace-pre-wrap',
        className
      )}
      {...rest}
    >
      {children}
    </BootstrapAlert>
  )
}

export default Alert
