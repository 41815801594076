import * as React from 'react'
import { Button } from 'react-bootstrap'

import AdvancedAvailabilityPicker from './AdvancedAvailabilityPicker'
import DateRangeInput from './DateRangeInput'
import RemoveButton from './RemoveButton'
import ScheduleKindInput from './ScheduleKindInput'
import ScheduleTimezoneInput from './ScheduleTimezoneInput'
import TimeBlockTable from './TimeBlockTable'
import Toggler from './Toggler'
import { IMeetingType } from './UpdateMeetingTypeContext'
import UpdateScheduleModal from './UpdateScheduleModal'
import { useScheduleData } from '../hooks'
import { ScheduleKind } from '../types'
import { getDefaultRecurringTimeBlocks, Duration } from '../utils'

type Props = {
  minimumTimeBlockDuration: Duration
  onChange: (value: IMeetingType['schedules']) => void
  value: IMeetingType['schedules']
}

const AvailabilityInput: React.FC<Props> = ({
  onChange,
  value: schedules,
  minimumTimeBlockDuration,
}) => {
  const {
    fixedBounds,
    fixedTimeBlocks,
    recurringBounds,
    recurringTimeBlocks,
    setFixedBounds,
    setFixedTimeBlocks,
    setRecurringBounds,
    setRecurringTimeBlocks,
  } = useScheduleData(schedules[0])

  return (
    <div className="AvailabilityInput">
      {schedules.length > 1 ? (
        <AdvancedAvailabilityPicker
          schedules={schedules}
          onChange={onChange}
          minimumTimeBlockDuration={minimumTimeBlockDuration}
        />
      ) : (
        <>
          <div className="tw-flex tw-flex-col tw-w-full tw-space-y-3 tw-mb-4 md:tw-flex-row md:tw-space-x-3 md:tw-space-y-0">
            <ScheduleKindInput
              onChange={kind => {
                onChange([
                  {
                    ...schedules[0],
                    kind,

                    // If we're moving to fixed mode, add our bounds
                    bounds:
                      kind === ScheduleKind.FixedRange
                        ? fixedBounds
                        : recurringBounds,

                    // Set the appropriate time blocks
                    timeBlocks:
                      kind === ScheduleKind.FixedRange
                        ? fixedTimeBlocks
                        : recurringTimeBlocks,
                  },
                ])
              }}
              value={schedules[0].kind}
            />

            <div className="tw-flex tw-items-center">
              {schedules[0].kind === ScheduleKind.Recurring && (
                <>
                  {schedules[0].bounds ? (
                    <>
                      <DateRangeInput
                        onChange={bounds => {
                          // Update the schedule
                          onChange([{ ...schedules[0], bounds }])

                          // Update the local state
                          setRecurringBounds(bounds)
                        }}
                        value={schedules[0].bounds}
                      />
                      <RemoveButton
                        className="tw-ml-3"
                        onClick={() => {
                          // Update the schedule
                          onChange([{ ...schedules[0], bounds: null }])

                          // update local state
                          setRecurringBounds(null)
                        }}
                      />
                    </>
                  ) : (
                    <Button
                      onClick={() => {
                        // When creating initial recurring bounds we just use the fixed
                        // bounds which have a sane default and were possibly customized
                        // by the user.
                        onChange([{ ...schedules[0], bounds: fixedBounds }])
                        setRecurringBounds(fixedBounds)
                      }}
                      variant="link"
                    >
                      Repeats between...
                    </Button>
                  )}
                </>
              )}

              {schedules[0].kind === ScheduleKind.FixedRange && (
                <DateRangeInput
                  onChange={bounds => {
                    // We need to use the exclusive bounds to filter
                    // the time blocks to avoid an extra time block being
                    // present that should not be.
                    const exclusiveBounds = bounds

                    // Parse time blocks based on new bounds.
                    const updatedTimeBlocks = schedules[0].timeBlocks.filter(
                      // We want to filter the time blocks based on if they are
                      // present in the new bounds.
                      // If no time blocks are present then we just hand onChange
                      // and empty array which is fine.
                      tb => tb.date!.isBetween(exclusiveBounds)
                    )

                    // Update the schedule
                    onChange([
                      {
                        ...schedules[0],
                        bounds,
                        timeBlocks: updatedTimeBlocks,
                      },
                    ])

                    // Update the local state for the fixedBounds.
                    setFixedBounds(bounds)

                    // Update the local state for the fixedTimeBlocks.
                    setFixedTimeBlocks(updatedTimeBlocks)
                  }}
                  value={schedules[0].bounds!}
                />
              )}
            </div>
          </div>

          <TimeBlockTable
            onChange={timeBlocks => {
              // Update the schedule
              onChange([{ ...schedules[0], timeBlocks }])

              // Update our local copy of the state
              schedules[0].kind === ScheduleKind.Recurring
                ? setRecurringTimeBlocks(timeBlocks)
                : setFixedTimeBlocks(timeBlocks)
            }}
            scheduleBounds={schedules[0].bounds ? schedules[0].bounds : null}
            scheduleKind={schedules[0].kind}
            value={schedules[0].timeBlocks}
            minimumTimeBlockDuration={minimumTimeBlockDuration}
          />
        </>
      )}

      <div className="tw-flex tw-flex-col tw-items-start tw-space-y-2 tw-mt-4 md:tw-flex-row md:tw-space-y-0">
        <Toggler>
          {({ isToggled, setOff, setOn }) => (
            <>
              <Button variant="link" onClick={setOn}>
                + Add another schedule
              </Button>

              {isToggled && (
                <UpdateScheduleModal
                  minimumTimeBlockDuration={minimumTimeBlockDuration}
                  onChange={schedule => {
                    // Close the modal
                    setOff()

                    // Add the schedule to the list
                    onChange([...schedules, schedule])
                  }}
                  onHide={setOff}
                  // Create a new schedule object here and pass it into the modal
                  schedule={{
                    kind: ScheduleKind.Recurring,
                    // TODO: make sure this is what we want going forward.  Uses the
                    // last timezone in the selection of schedules.
                    timezone: schedules[schedules.length - 1].timezone,
                    name: 'New Schedule',
                    bounds: null,
                    order: schedules.length,
                    timeBlocks: getDefaultRecurringTimeBlocks(),
                  }}
                />
              )}
            </>
          )}
        </Toggler>

        <div className="md:tw-ml-auto">
          {schedules.length > 1 ? (
            <p>Lower schedules take priority. Drag to reorder.</p>
          ) : (
            <ScheduleTimezoneInput
              onChange={timezone => onChange([{ ...schedules[0], timezone }])}
              value={schedules[0].timezone}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AvailabilityInput
