import * as React from 'react'

import './PanelDescription.scss'

interface Props {}

const PanelDescription: React.FC<
  Props & React.HTMLProps<HTMLParagraphElement>
> = ({ children, ...rest }) => (
  <p className="PanelDescription" {...rest}>
    {children}
  </p>
)

export default PanelDescription
