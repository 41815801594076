import React, { ReactElement, useState } from 'react'
import { OnboardTitle } from '../../molecules/OnboardTitle/OnboardTitle'
import { ScheduleKind, TimeBlock } from '../../../../types'
import TimeBlockTable from '../../../TimeBlockTable'
import { Button } from 'react-bootstrap'
import LoadingButton from '../../../LoadingButton'
import { DateTimeRange, Duration } from '../../../../utils'

export interface AvailabilityViewProps {
  readonly timeBlocks: TimeBlock[]
  readonly scheduleBounds: DateTimeRange | null
  readonly minimumTimeBlockDuration: Duration
  readonly skipSectionCallback: () => void
  readonly onSubmitCallback: (timeBlocks: TimeBlock[]) => void
  readonly isLoading?: boolean
}

export const AvailabilityView = ({
  timeBlocks,
  scheduleBounds,
  minimumTimeBlockDuration,
  skipSectionCallback,
  onSubmitCallback,
  isLoading,
}: AvailabilityViewProps): ReactElement => {
  const [currentTimeBlocks, setCurrentTimeBlocks] =
    useState<AvailabilityViewProps['timeBlocks']>(timeBlocks)

  //initially renders with default blocks to reduce perceived load time- we update blocks when actual timeblocks are fetched
  React.useEffect(() => {
    setCurrentTimeBlocks(timeBlocks)
  }, [timeBlocks])

  const shouldHideWeekend =
    timeBlocks.find(timeBlock => {
      return timeBlock.weekday === 'SATURDAY' || timeBlock.weekday === 'SUNDAY'
    }) === undefined

  const [hideWeekends, setHideWeekends] = useState(shouldHideWeekend)
  const toggleWeekends = () => {
    setHideWeekends(!hideWeekends)
  }
  const handleOnChange = (value: TimeBlock[]) => {
    setCurrentTimeBlocks(value)
  }
  return (
    <div>
      <OnboardTitle
        title="Set up your availability"
        subtitle="Keep your calendar in sync and prevent double-bookings"
      />
      <div className="pt-24 pb-12">
        <TimeBlockTable
          onChange={handleOnChange}
          minimumTimeBlockDuration={minimumTimeBlockDuration}
          scheduleBounds={scheduleBounds}
          scheduleKind={ScheduleKind.Recurring}
          value={currentTimeBlocks}
          hideWeekends={hideWeekends}
        />
      </div>
      <Button onClick={toggleWeekends} type="button" variant="link">
        {hideWeekends ? 'Show' : 'Hide'} weekends
      </Button>
      <div className="pt-32 d-flex flex-row justify-content-between space-between">
        <Button onClick={skipSectionCallback} type="button" variant="link">
          I'll do this later
        </Button>
        <LoadingButton
          type="submit"
          variant="success"
          loading={isLoading}
          onClick={() => onSubmitCallback(currentTimeBlocks)}
        >
          Save & Continue
        </LoadingButton>
      </div>
    </div>
  )
}
