import * as React from 'react'
import { Button } from 'react-bootstrap'

import Alert from './Alert'
import PremiumUpgradeModal from './PremiumUpgradeModal'
import Toggler from './Toggler'
import { Analytics } from '../utils'
import PermissionsFlag from './PermissionsFlag'
import ProfileContext from './ProfileContext'

type Props = {
  source: string
}

const UnsupportedPlan: React.FC<Props> = ({ source }) => {
  const profile = React.useContext(ProfileContext)

  return (
    <Alert
      className="tw-flex-col tw-items-start tw-text-bsGray-900 md:tw-flex-row md:tw-items-center"
      variant="danger"
    >
      <div className="tw-flex-grow tw-truncate">
        This feature isn’t supported by your plan.
      </div>

      <PermissionsFlag permission="canManageBilling">
        <Toggler>
          {({ isToggled, setOff, setOn }) => (
            <React.Fragment>
              <Button
                onClick={() => {
                  Analytics.trackEvent('Unsupported Feature Banner: Clicked', {
                    source: source,
                  })
                  setOn()
                }}
                variant="link"
              >
                Upgrade to Premium &#8594;
              </Button>
              {isToggled && (
                <PremiumUpgradeModal
                  profile={profile}
                  onHide={setOff}
                  onSuccess={setOff}
                />
              )}
            </React.Fragment>
          )}
        </Toggler>
      </PermissionsFlag>
    </Alert>
  )
}

export default UnsupportedPlan
