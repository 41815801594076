import * as React from 'react'
import { FormControl, FormControlProps } from 'react-bootstrap'

import './IconTextInput.scss'
import { Icon } from 'react-feather'

interface Props {
  icon: Icon
  defaultValue?: string
  onFocus?: (event?: React.FocusEvent<HTMLElement> | undefined) => void
  onBlur?: (event?: React.FocusEvent<HTMLElement> | undefined) => void
}

// IconTextInput is an abstract component meant to be implemented by other
// components like SearchInput.  It renders an icon in front of the input
const IconTextInput = React.forwardRef<
  HTMLInputElement,
  Props & FormControlProps
>(({ icon: Icon, ...rest }, ref) => (
  <div className="IconTextInput">
    <Icon size={20} className="text-muted" />
    <FormControl {...rest} ref={ref} />
  </div>
))

export default IconTextInput
