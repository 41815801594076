import * as React from 'react'
import './Drawer.scss'
import Modal from 'react-overlays/Modal'
import { CSSTransition } from 'react-transition-group'
import { TransitionProps } from 'react-transition-group/Transition'
import ModalManager from 'react-overlays/ModalManager'
import Icon from '../../Icon'

export type Props = {
  size?: 'sm' | 'md' | 'lg'
  isOpen: boolean
  onHide: () => void
}

const Fade: React.FC<Omit<TransitionProps, 'onEndListener'>> = props => (
  <CSSTransition {...props} timeout={500} classNames="modal-backdrop" />
)

const SlideIn: React.FC<Omit<TransitionProps, 'onEndListener'>> = props => (
  <CSSTransition {...props} timeout={500} classNames="Drawer" />
)

//this method properly applies overflow: hidden to body and aria-hidden rules to correct siblings when the drawer is open & closed:
const handleModalAppliedStyles = (action: 'open' | 'close'): void => {
  if (action === 'open') {
    document.body.style.overflow = 'hidden'
    Array.from(document.body.children).forEach(node => {
      if (
        node.className === 'Drawer' ||
        node.className === 'modal-backdrop' ||
        node.nodeName === 'SCRIPT'
      ) {
        return
      }
      node.setAttribute('aria-hidden', 'true')
    })
  } else {
    document.body.removeAttribute('style')
    Array.from(document.body.children).forEach(node => {
      if (
        node.className === 'Drawer' ||
        node.className === 'modal-backdrop' ||
        node.nodeName === 'SCRIPT'
      ) {
        return
      }
      node.removeAttribute('aria-hidden')
    })
  }
}

const Drawer: React.FC<Props> = ({ size, isOpen, onHide, children }) => {
  React.useEffect(() => {
    if (isOpen) {
      handleModalAppliedStyles('open')
    }
  }, [isOpen])

  // Closes the drawer and removes any styling on the <body>
  const closeDrawer = () => {
    onHide()
    handleModalAppliedStyles('close')
  }

  return (
    <Modal
      className="Drawer-container"
      backdrop={true}
      show={isOpen}
      onClick={(ev: React.MouseEvent) => {
        // If the click came from a descendent, ignore it.
        if (ev.target !== ev.currentTarget) return

        // Close the drawer
        closeDrawer()
      }}
      onHide={closeDrawer}
      enforceFocus={false}
      manager={
        new ModalManager({
          handleContainerOverflow: false,
          hideSiblingNodes: false,
        })
      }
      // For some reason the react-overlays library doesn't pass unmountOnExit
      // to the backdrop (they do for the modal itself).  We manually pass it
      // so that the backdrop is removed from the DOM.
      // https://github.com/react-bootstrap/react-overlays/blob/6a56f3009e1d48ea57a417877fc6170c534c68c6/src/Modal.tsx#L358
      backdropTransition={props => <Fade {...props} unmountOnExit />}
      // Transitioning out seems to fail, for reasons that I think are related to the react-overlays
      // library.  Opened a ticket there:
      // https://github.com/react-bootstrap/react-overlays/issues/971
      transition={props => <SlideIn {...props} />}
      // Re-use the bootstrap backdrop
      renderBackdrop={props => <div {...props} className="modal-backdrop" />}
    >
      {/* We need this extra div here so that the white background
      of the drawer extends below the fold if the contents are taller
      than the screen. */}
      <div className={`Drawer-wrapper Drawer-wrapper-${size || 'md'}`}>
        <div className={`Drawer p-24`}>{children}</div>

        <div
          onClick={closeDrawer}
          className="Drawer-close d-sm-none p-8 border-gray-400 border border-right-0 border-top-0 background-gray-100"
        >
          <Icon.ChevronRight size={20} className="text-gray-700" />
          <span className="sr-only">Close</span>
        </div>
      </div>
    </Modal>
  )
}

export default Drawer
