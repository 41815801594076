import * as React from 'react'
import './MeetingTypeImage.scss'
import { MeetingType } from '../types'
import Icon from './Icon'
import { toRem } from '../utils'

enum Sizes {
  xs = 16,
  sm = 20,
  md = 32,
  lg = 64,
}

interface Props {
  meetingType: Pick<MeetingType, 'id' | 'name' | 'image'>
  size?: 'xs' | 'sm' | 'md' | 'lg' | number
}

const MeetingTypeImage: React.FC<Props> = ({ meetingType, size }) => {
  let style: React.CSSProperties | undefined = {}
  if (typeof size === 'number') {
    style = {
      height: toRem(size),
      width: toRem(size),
    }
  } else {
    style = {
      height: toRem(Sizes[size || 'md']),
      width: toRem(Sizes[size || 'md']),
    }
  }
  return (
    <div className="MeetingTypeImage d-flex align-items-center justify-content-center">
      {meetingType.image ? (
        <img src={meetingType.image} alt={meetingType.name} style={style} />
      ) : (
        <Icon.Calendar
          size={typeof size === 'number' ? size : Sizes[size || 'md']}
        />
      )}
    </div>
  )
}

export default MeetingTypeImage
