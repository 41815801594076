import * as React from 'react'
import { Dropdown, Media } from 'react-bootstrap'
import DropdownItem from './DropdownItem'
// TYPESCRIPT
// @ts-ignore
// TS doesn't want to recognize this import.
import SelectableContext from 'react-bootstrap/SelectableContext'

import './MeetingTypeVisibilityPicker.scss'
import Icon from './Icon'

export type MeetingTypeVisibility = 'disabled' | 'hidden' | 'public'

interface Props {
  onChange: (status: MeetingTypeVisibility) => void
  value: MeetingTypeVisibility
}

/**
 * // NOTE:
 *
 * We wrap this component with the SelectableContext to prevent
 * any Parent SelectableContext from trying to update based on the
 * onSelect call updating 'eventKey'.
 *
 */
const MeetingTypeVisibilityPicker: React.FC<Props> = ({ onChange, value }) => {
  return (
    <SelectableContext.Provider value={null}>
      <Dropdown
        className="MeetingTypeVisibilityPicker tw-hidden md:tw-block"
        drop="up"
        // TYPESCRIPT
        // @ts-ignore
        // We aren't matching the SelectCallback type
        onSelect={(eventKey: MeetingTypeVisibility) => onChange(eventKey)}
      >
        <Dropdown.Toggle
          id="MeetingTypeVisibilityPicker"
          variant="outline-secondary"
        >
          {value === 'public' ? (
            <Icon.Eye className="text-success" size={18} />
          ) : value === 'hidden' ? (
            <Icon.EyeOff className="text-warning" size={18} />
          ) : (
            <Icon.XCircle className="text-danger" size={18} />
          )}
          {value === 'public'
            ? 'Public'
            : value === 'hidden'
            ? 'Hidden'
            : 'Disabled'}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <DropdownItem eventKey="public">
            <Media>
              <Icon.Eye size={20} className="mr-8 text-success" />

              <Media.Body>
                <p className="mb-2">Public</p>
                <small className="text-muted">Anyone can book</small>
              </Media.Body>
            </Media>
          </DropdownItem>
          <DropdownItem eventKey="hidden">
            <Media>
              <Icon.EyeOff size={20} className="mr-8 text-warning" />

              <Media.Body>
                <p className="mb-2">Hidden</p>
                <small className="text-muted">
                  Only people with a direct link can book
                </small>
              </Media.Body>
            </Media>
          </DropdownItem>
          <DropdownItem eventKey="disabled">
            <Media>
              <Icon.XCircle size={20} className="mr-8 text-danger" />

              <Media.Body>
                <p className="mb-2">Disabled</p>
                <small className="text-muted">Cannot be booked by anyone</small>
              </Media.Body>
            </Media>
          </DropdownItem>
        </Dropdown.Menu>
      </Dropdown>
    </SelectableContext.Provider>
  )
}

export default MeetingTypeVisibilityPicker
