import * as React from 'react'
import { DropdownButton, Media } from 'react-bootstrap'

import DropdownItem from './DropdownItem'
import Badge from './Badge'
import './HostAssignmentStrategyPicker.scss'
import Icon from './Icon'
import { HostAssignmentStrategy } from '../__generated__/graphql'
import ProfileContext from './ProfileContext'

type Props = {
  onChange: (value: HostAssignmentStrategy) => void
  value: HostAssignmentStrategy
}

type StrategyOption = {
  asset: string
  description: string
  value: HostAssignmentStrategy
  premium: boolean
}

const options: Array<StrategyOption> = [
  {
    asset: 'Icon-Max-Attendees',
    description: 'You determine the member(s) which will attend',
    value: HostAssignmentStrategy.Joint,
    premium: false,
  },
  {
    asset: 'Attendee-Chooses',
    description: 'Attendees will pick a member when scheduling',
    value: HostAssignmentStrategy.AttendeeChooses,
    premium: false,
  },
  {
    asset: 'Round-Robin-Meeting',
    description:
      'A member will automatically be assigned based on a round-robin',
    value: HostAssignmentStrategy.Pooled,
    premium: true,
  },
]

const HostAssignmentStrategyPicker: React.FC<Props> = ({ onChange, value }) => {
  const selectedOption = React.useMemo(
    () => options.find(option => option.value === value)!,
    [value]
  )
  const { featureFlags } = React.useContext(ProfileContext)

  const optionsToUse = !featureFlags?.includes('attendee-chooses-strategy')
    ? options.filter(option => {
        return option.value !== HostAssignmentStrategy.AttendeeChooses
      })
    : options

  return (
    <DropdownButton
      className="HostAssignmentStrategyPicker"
      id="HostAssignmentStrategyPicker"
      title={
        <Media className="tw-items-center tw-space-x-4 tw-w-full">
          <Icon.InternalAsset
            assetName={selectedOption.asset}
            className="tw-mr-0 tw-h-9 tw-w-9 md:tw-h-12 md:tw-w-12"
          />
          <Media.Body className="tw-flex tw-items-start">
            <span className="tw-text-bsGray-600 tw-text-left tw-font-medium tw-text-sm tw-whitespace-normal">
              {selectedOption.description}
            </span>
          </Media.Body>
          {selectedOption.premium && <Badge variant="success">Premium</Badge>}
          <Icon.ChevronDown className="tw-text-bsGray-400 tw-h-5" />
        </Media>
      }
      variant="outline-secondary"
    >
      {optionsToUse.map(({ asset, description, premium, value }) => (
        <DropdownItem
          as={Media}
          key={value}
          className="tw-group tw-items-center tw-space-x-4"
          eventKey={value}
          onClick={() => onChange(value)}
        >
          <Icon.InternalAsset
            assetName={asset}
            className="tw-mr-0 tw-h-9 tw-w-9 md:tw-h-12 md:tw-w-12 group-hover:tw-border group-hover:tw-border-solid group-hover:tw-border-white group-hover:tw-rounded-full"
          />
          <Media.Body className="tw-flex tw-items-start">
            <span className="tw-text-bsGray-600 tw-text-left tw-font-normal tw-text-sm tw-whitespace-normal group-hover:tw-text-white">
              {description}
            </span>
          </Media.Body>
          {premium && <Badge variant="success">Premium</Badge>}
        </DropdownItem>
      ))}
    </DropdownButton>
  )
}

export default HostAssignmentStrategyPicker
