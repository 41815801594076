import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { gql } from '@apollo/client'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import Badge from './Badge'

import Icon from './Icon'
import LoadingButton from './LoadingButton'
import Panel from './Panel'
import PanelBody from './PanelBody'
import PanelTitle from './PanelTitle'
import SettingsModal from './SettingsModal'
import Toggler from './Toggler'
import { startStripeFlow, StripeErrorType, toast, Analytics } from '../utils'
import {
  usePremiumUpgradeModalQuery,
  useUpdatePlanProfileMutation,
  PremiumUpgradeModalQuery,
  Maybe,
} from '../__generated__/graphql'
import { useStripeCheckoutSession } from '../hooks'
import { Profile } from '../types'
import Translate from './Translate'
import Var from './Var'

gql`
  query PremiumUpgradeModal($profile: ID!) {
    paymentMethod: getDefaultPaymentSource(profile: $profile) {
      brand
      expiration
      id
      last4
    }

    premiumPlan: getTrialPlan {
      id
      name
      seatPriceMonthly
      seatPriceYearly
    }
  }
`

gql`
  mutation UpdatePlanProfile($input: UpdateProfilePlanInput!) {
    profile: updateProfilePlan(input: $input) {
      data {
        id
        plan {
          id
          name
          freeDefault
          requireAppointletBranding
          seatPriceMonthly
          seatPriceYearly
          trialDefault
          allowEmailCustomization
          allowFormFields
          allowManualConfirm
          allowPaymentCollection
          allowPrivateMeetingTypes
          allowRedirect
          allowReminders
          allowRoundRobin
          allowWebConferencing
          allowWebhooks
          allowUtmParameters
          allowZapier
        }
        billedAnnually
      }
    }
  }
`

type InternalData = {
  premiumPlan: PremiumUpgradeModalQuery['premiumPlan']
  paymentMethod: PremiumUpgradeModalQuery['paymentMethod']
}

export type Props = {
  profile: Pick<Profile, 'id' | 'occupiedSeats'>
  onHide: () => void
  onSuccess: () => void
}

type FormValues = {
  billedAnnually: boolean
}

const PremiumUpgradeModal: React.FC<Props> = ({
  profile,
  onHide,
  onSuccess,
}) => {
  const { stripeSessionId } = useStripeCheckoutSession(profile)

  // Fetch the data we need to draw the modal
  const { data } = usePremiumUpgradeModalQuery({
    variables: { profile: profile.id },
  })

  // Convert the data into our internal format.
  const internalData: Maybe<InternalData> = React.useMemo(
    () =>
      data
        ? {
            premiumPlan: data.premiumPlan,
            paymentMethod: data.paymentMethod,
          }
        : null,
    [data]
  )

  // Mutation to update the plan on a profile
  const [updateProfilePlan] = useUpdatePlanProfileMutation()

  React.useEffect(() => {
    Analytics.trackEvent('Premium Upgrade Modal: Opened')
  }, [])

  const onSubmit = async (values: FormValues) => {
    try {
      // If there is no payment method on file, then we need to collect one from the user
      if (!internalData?.paymentMethod) {
        console.log(
          '[PremiumUpgradeModal]',
          "Didn't find payment method, starting Stripe Checkout flow"
        )

        // Fire event saying we opened the payment modal.
        Analytics.trackEvent('Payment Card Modal: Opened', {
          'Has existing method': false,
          source: 'premium-upgrade-modal',
        })

        // Use the session token to start a Stripe Checkout flow where we'll collect
        // the payment method from the user.
        //
        // This will raise an exception if the user bails or there is some other kind
        // of issue.
        await startStripeFlow(stripeSessionId!)

        // Analytics
        Analytics.trackEvent('Billing: Added Payment Card')
      }

      console.log(
        '[PremiumUpgradeModal]',
        `Upgrading to ${internalData!.premiumPlan.name} (${
          internalData!.premiumPlan.id
        }), billed ${values.billedAnnually ? 'annually' : 'monthly'}`
      )

      // Now that we know the user has a valid payment method, try and put them on the
      // premium plan.
      const { data } = await updateProfilePlan({
        variables: {
          input: {
            id: profile.id,
            plan: internalData!.premiumPlan.id,
            billedAnnually: values.billedAnnually,
          },
        },
      })

      if (!data) {
        throw new Error('Failed to update profile plan')
      }

      // Analytics
      Analytics.trackEvent('Billing: Upgraded to Premium', {
        cycle: values.billedAnnually ? 'yr' : 'mo',
      })

      // We made it, let the user know that they were upgraded.
      toast.success('Your plan has been updated to premium!')
      onSuccess()
    } catch (error) {
      // We ignore the UserCancelled error that comes out of startStripeFlow because
      // it's a conscious act made by the user.
      if (error !== StripeErrorType.UserCancelled) {
        console.error(
          '[PremiumUpgradeModal] Failed to upgrade to premium',
          error
        )
        toast.error('Something went wrong')
      }
    }
  }

  // TODO: We need to have a loader
  if (!internalData) {
    return null
  }

  return (
    <Modal
      className="PremiumUpgradeModal"
      onHide={async () => {
        Analytics.trackEvent('Premium Upgrade Modal: Closed')
        onHide()
      }}
      show
      size="lg"
    >
      <Modal.Header closeButton>
        <Icon.Gift size={32} />
        <div className="title-block">
          <Modal.Title className="tw-text-[19px]">
            <Translate>Upgrade to Premium</Translate>
          </Modal.Title>
          <p className="tw-mb-0 tw-text-bsGray-600">
            <Translate>Get access to powerful scheduling features.</Translate>
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row className="tw-flex-col tw-space-y-4 md:tw-flex-row md:tw-space-y-0">
          <Col className="tw-flex tw-flex-col" sm={6}>
            <h5 className="tw-mb-4">
              <Translate>Included in Premium:</Translate>
            </h5>

            <ul className="tw-list-none tw-pl-0 tw-flex tw-flex-col tw-space-y-4">
              <li className="tw-flex tw-space-x-2">
                <Icon.Bell className="tw-text-bsPink" />
                <Translate>Reminders</Translate>
              </li>
              <li className="tw-flex tw-space-x-2">
                <Icon.Monitor className="tw-text-bsPink" />
                <Translate>Web Conferencing Integrations</Translate>
              </li>
              <li className="tw-flex tw-space-x-2">
                <Icon.RefreshCw className="tw-text-bsPink" />
                <Translate>Round-Robin Meeting Types</Translate>
              </li>
              <li className="tw-flex tw-space-x-2">
                <Icon.Edit3 className="tw-text-bsPink" />
                <Translate>Custom Booking Forms</Translate>
              </li>
              <li className="tw-flex tw-space-x-2">
                <Icon.ExternalLink className="tw-text-bsPink" />
                <Translate>Post-Booking Redirect</Translate>
              </li>
              <li className="tw-flex tw-space-x-2">
                <Icon.Star className="tw-text-bsPink" />
                <Translate>Remove Appointlet Branding</Translate>
              </li>
            </ul>
          </Col>
          <Col sm={6}>
            <FinalForm.Form<FormValues>
              initialValues={{
                billedAnnually: false,
              }}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, submitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="tw-flex tw-flex-col tw-space-y-3">
                    <FinalForm.Field<boolean> name="billedAnnually">
                      {({ input: { onChange, value: billedAnnually } }) => (
                        <>
                          {profile.occupiedSeats && profile.occupiedSeats > 1 && (
                            <div className="tw-items-center tw-flex tw-space-x-2">
                              <Icon.Users
                                size={20}
                                className="tw-text-bsGray-600"
                              />
                              <Translate>
                                You are currently using{' '}
                                <Var
                                  name="occupiedSeats"
                                  pluralize={profile.occupiedSeats}
                                >
                                  <strong>{profile.occupiedSeats}</strong>
                                </Var>
                                seat.
                              </Translate>
                              <Toggler>
                                {({ isToggled, setOff, setOn }) => (
                                  <>
                                    <Button onClick={setOn} variant="link">
                                      <Translate>Manage</Translate>
                                    </Button>
                                    {isToggled && (
                                      <SettingsModal
                                        initialSection="users"
                                        onHide={setOff}
                                      />
                                    )}
                                  </>
                                )}
                              </Toggler>
                            </div>
                          )}

                          <Panel
                            className={`tw-mb-0 tw-h-[67px] ${
                              !billedAnnually
                                ? 'tw-border tw-border-solid tw-border-bsPrimary tw-bg-bsPrimary/[0.07]'
                                : ''
                            }`}
                            onClick={() => onChange(false)}
                          >
                            <Form.Check
                              checked={!billedAnnually}
                              className="tw-mr-4"
                              type="radio"
                              readOnly
                            />
                            <PanelBody className="tw-items-start">
                              <PanelTitle className="tw-mb-0">
                                <Translate>Pay Month-to-Month</Translate>
                              </PanelTitle>
                            </PanelBody>
                            <p className="tw-items-end tw-font-medium tw-flex tw-flex-col">
                              <span
                                className={
                                  !billedAnnually
                                    ? 'tw-text-bsPrimary'
                                    : 'tw-text-bsGray-600'
                                }
                              >
                                $
                                {profile.occupiedSeats *
                                  internalData.premiumPlan.seatPriceMonthly}
                                /<Translate>month</Translate>
                              </span>
                              {profile.occupiedSeats > 1 && (
                                <small className="tw-text-bsGray-600 tw-font-normal tw-mt-[2px]">
                                  ($
                                  {internalData.premiumPlan.seatPriceMonthly}/
                                  <Translate>seat</Translate>)
                                </small>
                              )}
                            </p>
                          </Panel>

                          <Panel
                            className={`tw-mb-0 tw-h-[67px] ${
                              billedAnnually
                                ? 'tw-border tw-border-solid tw-border-bsPrimary tw-bg-bsPrimary/[0.07]'
                                : ''
                            }`}
                            onClick={() => onChange(true)}
                          >
                            <Form.Check
                              checked={billedAnnually}
                              className="tw-mr-4"
                              type="radio"
                              readOnly
                            />
                            <PanelBody className="tw-items-start">
                              <PanelTitle className="tw-mb-0">
                                <Translate>Pay Annually</Translate>
                              </PanelTitle>
                            </PanelBody>
                            <div className="tw-flex tw-items-center tw-space-x-1">
                              <Badge variant="success">
                                <Translate>Save 20%</Translate>
                              </Badge>
                              <p className="tw-items-end tw-font-medium tw-flex tw-flex-col">
                                <span
                                  className={
                                    billedAnnually
                                      ? 'tw-text-bsPrimary'
                                      : 'tw-text-bsGray-600'
                                  }
                                >
                                  $
                                  {profile.occupiedSeats *
                                    internalData.premiumPlan.seatPriceYearly}
                                  /<Translate>year</Translate>
                                </span>
                                {profile.occupiedSeats > 1 && (
                                  <small className="tw-text-bsGray-600 tw-font-normal tw-mt-[2px]">
                                    ($
                                    {internalData.premiumPlan.seatPriceYearly}/
                                    <Translate>seat</Translate>)
                                  </small>
                                )}
                              </p>
                            </div>
                          </Panel>
                        </>
                      )}
                    </FinalForm.Field>

                    <LoadingButton
                      block
                      loading={submitting}
                      type="submit"
                      variant="primary"
                    >
                      <Translate>Upgrade to Premium</Translate>
                    </LoadingButton>

                    <Form.Text>
                      <Translate>
                        100% satisfaction guaranteed. If you are not happy we
                        will refund your payment.
                      </Translate>
                    </Form.Text>
                  </div>
                </Form>
              )}
            </FinalForm.Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default PremiumUpgradeModal
