import * as React from 'react'
import DayPicker, {
  DayModifiers,
  LocaleUtils,
  Modifiers,
} from 'react-day-picker'

import './DatePicker.scss'
import { Language } from '../__generated__/graphql'
import { DateTime } from '../utils'

// NOTE: Why?
// `react-day-picker` uses `moment` under the hood for doing this
// maybe someday in the future they will move off `moment` since it
// is deprecated, but for now we can role our own custom utils by
// following the typings
// https://react-day-picker.js.org/api/LocaleUtils
export const CustomLocaleUtils: LocaleUtils = {
  ...LocaleUtils,
  formatMonthTitle(month, locale) {
    const datetime = new DateTime(month.toISOString()).setLocale(
      (locale as Language | string) ?? 'En'
    )
    const localizedMonth = datetime.format('localized-full-month')
    const year = datetime.get('year')
    // March 2022 || marzo 2022
    return `${localizedMonth} ${year}`
  },
  formatWeekdayShort(weekday, locale) {
    const datetime = new DateTime()
      .setLocale((locale as Language | string) ?? 'En')
      .startOf('week')
      // NOTE: Why?
      // react-day-picker indexes at 0-6, but Luxon indexes at 1-7
      // so we subtract 1 to get back on the same page as react-day-picker.
      .add({ day: weekday - 1 })
    // Mo, Tu, We || Lu, Ma, Mi
    return datetime.format('two-char-day-of-week')
  },
}

export interface Props {
  // Called to determine a busy rating for the day.  This should return a
  // number between 1-3, which will determine how many dots to show below the
  // date.
  busyRating?: (date: DateTime) => number

  // Called to set the starting and ending date of the next/previous month
  // for use with the getMeetingsCountByDay query.
  onMonthChange?: (date: DateTime) => void

  // Called when a date is selected on the date picker
  onSelect: (date: DateTime) => void

  // Date to consider as "selected"
  selected: DateTime
  // Prop for providing a date to disable days in the DatePicker.
  disableDays?: {
    before: DateTime
  }
}

const DatePicker: React.FC<Props> = ({
  selected,
  onSelect,
  onMonthChange,
  busyRating,
  disableDays,
}) => {
  // Container for any modifiers we want to have on a date
  const mods: Partial<Modifiers> = {}

  // We want to pass back moment objects instead of dates
  const didSelect = (date: Date, modifiers: DayModifiers) =>
    // As per IRL conversation the date picker should return the
    // start of day. It is not the date picker's responsibility
    // to return "times", but this will ensure the parent is getting
    // back a moment object that is the date + a set time that is
    // not locale specific.
    !modifiers.disabled
      ? onSelect(new DateTime(date).startOf('day'))
      : undefined

  // If a busyRating function is passed in, then setup the busy modifiers
  if (busyRating) {
    mods.busy1 = (date: Date) => busyRating(new DateTime(date)) === 1
    mods.busy2 = (date: Date) => busyRating(new DateTime(date)) === 2
    mods.busy3 = (date: Date) => busyRating(new DateTime(date)) === 3
  }

  return (
    <div className="DatePicker">
      <DayPicker
        initialMonth={selected.toDate()}
        locale={new DateTime().getLocale()}
        localeUtils={CustomLocaleUtils}
        modifiers={mods}
        onDayClick={didSelect}
        onMonthChange={date =>
          onMonthChange && onMonthChange(new DateTime(date))
        }
        selectedDays={[selected.toDate()]}
        showOutsideDays
        disabledDays={
          disableDays
            ? { before: new Date(disableDays.before.toDate()) }
            : undefined
        }
      />
    </div>
  )
}

export default DatePicker
