import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Modal, Form } from 'react-bootstrap'
import { gql } from '@apollo/client'
import Illustration from './Illustration'

import LoadingButton from './LoadingButton'
import { useCancelSubscriptionMutation } from '../__generated__/graphql'
import ProfileContext from './ProfileContext'
import { toast, Analytics } from '../utils'
import Translate from './Translate'

gql`
  mutation CancelSubscription($input: CancelSubscriptionInput!) {
    cancelSubscription(input: $input) {
      ok
    }
  }
`

export type Props = {
  onSuccess: () => void
  onHide: () => void
}

type FormValues = {
  message: string
  reason: string
}

const CancelSubscriptionModal: React.FC<Props> = ({ onSuccess, onHide }) => {
  const profile = React.useContext(ProfileContext)

  // Prepare the cancelSubscription mutation
  const [cancelSubscription, { loading }] = useCancelSubscriptionMutation({
    variables: { input: { profile: profile.id } },
  })

  React.useEffect(() => {
    Analytics.trackEvent('Cancel Subscription Modal: Opened')
  }, [])

  const onSubmit = async (values: FormValues) => {
    try {
      // Perform the cancellation
      const res = await cancelSubscription()

      // If client validation and GraphQL error related errors exist
      if (!res.data?.cancelSubscription?.ok) {
        console.error('[CancelSubscriptionModal] Failed to cancel subscription')

        throw new Error('Failed to cancel subscription')
      }

      Analytics.trackEvent('Billing: Scheduled cancellation', {
        'Cancellation Reason': values.reason,
        'Cancellation Message': values.message,
      })

      // Looks good, tell the user and close the modal
      toast.success(`Subscription scheduled for cancellation`)
      onSuccess()
    } catch (err) {
      toast.error('Something went wrong')
    }
  }

  return (
    <Modal
      className="CancelSubscriptionModal"
      onHide={async () => {
        Analytics.trackEvent('Cancel Subscription Modal: Closed')
        onHide()
      }}
      show
    >
      <Modal.Header className="tw-pb-0 tw-border-b-0" closeButton />
      <Modal.Body className="tw-px-12 tw-pt-0 tw-pb-12">
        <div className="tw-mb-8 tw-text-center tw-flex-col tw-justify-center">
          <div className="tw-mb-6">
            <Illustration name="standing" className="tw-h-40" />
          </div>
          <h1 className="tw-mb-1">
            <Translate>Cancel Premium Subscription</Translate>
          </h1>
          <p className="tw-text-bsGray-600">
            <Translate>
              We’re sorry to see you go. Could you tell us why you’re
              cancelling?
            </Translate>
          </p>
        </div>
        <FinalForm.Form<FormValues>
          initialValues={{ message: '', reason: '' }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="tw-mb-6">
                <Form.Label>
                  <Translate>Reason for cancellation</Translate>
                </Form.Label>

                <FinalForm.Field<FormValues['reason']>
                  name="reason"
                  component="select"
                >
                  {({ input }) => (
                    <Form.Control {...input} as="select" custom>
                      <option value=""></option>

                      <Translate as="option" value="No Need">
                        I don't need to schedule meetings anymore
                      </Translate>

                      <Translate as="option" value="Competitor">
                        I found an app that works better for my needs
                      </Translate>

                      <Translate as="option" value="Cost">
                        The subscription cost is too high
                      </Translate>

                      <Translate as="option" value="Other">
                        Other (specify below)
                      </Translate>
                    </Form.Control>
                  )}
                </FinalForm.Field>
              </Form.Group>
              <Form.Group className="tw-mb-6">
                <FinalForm.Field<FormValues['message']>
                  name="message"
                  component="textarea"
                >
                  {({ input }) => (
                    <Form.Control
                      {...input}
                      as="textarea"
                      placeholder="Any other information you’d like to share with us."
                      rows={6}
                    />
                  )}
                </FinalForm.Field>
              </Form.Group>
              <LoadingButton
                block
                loading={loading}
                type="submit"
                variant="danger"
              >
                <Translate>Cancel Subscription</Translate>
              </LoadingButton>
            </Form>
          )}
        </FinalForm.Form>
      </Modal.Body>
    </Modal>
  )
}

export default CancelSubscriptionModal
