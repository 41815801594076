import * as React from 'react'
import Icon from './Icon'
import { Button, Dropdown, Form } from 'react-bootstrap'
import * as FinalForm from 'react-final-form'
import { weekdaysToDateTimes } from '../utils'
import { Weekday } from '../types'
import { Maybe } from '../__generated__/graphql'
import { DateTime } from '../utils'
import Translate from './Translate'

const Toggle = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({ onClick }, ref) => (
  <div
    ref={ref}
    onClick={ev => {
      ev.preventDefault()
      onClick && onClick(ev)
    }}
  >
    <Icon.Copy size={24} />
  </div>
))

interface FormValues {
  weekdays: Weekday[]
  dates: DateTime[]
  allDates: boolean
}

export interface Props {
  current: Weekday | DateTime
  mode: 'date' | 'weekday'
  onCopyTo: (destinations: Array<Weekday | DateTime>) => void
  possibleDates: Maybe<DateTime[]>
}

const TimeBlockTableCopyMenu: React.FC<Props> = ({
  current,
  mode,
  onCopyTo,
  possibleDates,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const weekdayMap = React.useMemo(() => weekdaysToDateTimes(), [])

  // NOTE: Why?
  // We need to pattern match against if the value is a DateTime or Weekday.
  const isSame = React.useCallback<(dateTime: DateTime) => boolean>(
    dateTime => {
      if (current instanceof DateTime) {
        return dateTime.isSame(current)
      } else {
        return (
          (dateTime.get('weekdayLong') as unknown as string).toUpperCase() ===
          current
        )
      }
    },
    [current]
  )

  return (
    <Dropdown onToggle={() => setIsOpen(!isOpen)} show={isOpen}>
      <Dropdown.Toggle as={Toggle} />

      <Dropdown.Menu>
        <FinalForm.Form<FormValues>
          initialValues={{ weekdays: [], dates: [], allDates: false }}
          mutators={{
            selectAllDates: (args, state, utils) =>
              utils.changeValue(state, 'dates', () => possibleDates!),
            unSelectAllDates: (args, state, utils) =>
              utils.changeValue(state, 'dates', () => []),
          }}
          onSubmit={values => {
            // Notify the parent that we have weekdays to copy to
            onCopyTo(mode === 'date' ? values.dates : values.weekdays)

            // Close the dropdown
            setIsOpen(false)
          }}
        >
          {({
            handleSubmit,
            form: {
              mutators: { selectAllDates, unSelectAllDates },
            },
          }) => (
            <div className="d-flex flex-column space-between-12 px-12 py-4">
              <h6>
                <Translate>Copy To</Translate>
              </h6>

              {mode === 'weekday' &&
                Object.entries(weekdayMap).map(([weekday, date]) => (
                  <FinalForm.Field
                    key={weekday}
                    name="weekdays"
                    value={weekday}
                    type="checkbox"
                  >
                    {({ input: { checked, name, onChange } }) => (
                      <Form.Check
                        id={`copy-time-block-${current}-${weekday}`}
                        title={date.format('localized-full-day-of-week')}
                        label={date.format('localized-full-day-of-week')}
                        disabled={weekday === current}
                        name={name}
                        onChange={onChange}
                        checked={checked || weekday === current}
                      />
                    )}
                  </FinalForm.Field>
                ))}

              {mode === 'date' && (
                <div
                  className="d-flex flex-column space-between-12"
                  style={{ maxHeight: '12.5rem', overflow: 'scroll' }}
                >
                  <FinalForm.Field<boolean> name="allDates" type="checkbox">
                    {({ input: { checked, name, onChange } }) => (
                      <Form.Check
                        id={Math.random().toString()}
                        title="All dates"
                        label="All dates"
                        name={name}
                        checked={checked}
                        onChange={ev => {
                          onChange(ev)

                          ev.target.checked
                            ? selectAllDates()
                            : unSelectAllDates()
                        }}
                      />
                    )}
                  </FinalForm.Field>

                  {possibleDates!.map(possibleDate => (
                    <FinalForm.Field<DateTime>
                      key={possibleDate.toISO()}
                      name="dates"
                      value={possibleDate}
                      type="checkbox"
                    >
                      {({ input: { checked, name, onChange } }) => (
                        <Form.Check
                          id={Math.random().toString()}
                          name={name}
                          title={possibleDate.format('localized-full-date')}
                          label={possibleDate.format('localized-full-date')}
                          disabled={isSame(possibleDate)}
                          onChange={onChange}
                          checked={checked || isSame(possibleDate)}
                        />
                      )}
                    </FinalForm.Field>
                  ))}
                </div>
              )}

              <Button variant="primary" size="sm" block onClick={handleSubmit}>
                <Translate>Apply</Translate>
              </Button>
            </div>
          )}
        </FinalForm.Form>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default TimeBlockTableCopyMenu
