import * as React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

import './JoinWebConferenceButton.scss'
import Icon from './Icon'
import { ConferenceProvider } from '../types'

type Props = ButtonProps & {
  provider: Pick<ConferenceProvider, 'id' | 'name' | 'slug'>
  url: string
}

const JoinWebConferenceButton: React.FC<Props> = ({
  provider,
  url,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      as="a"
      className="align-items-center d-flex space-between-8 JoinWebConferenceButton"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      variant="outline-secondary"
    >
      <Icon.InternalAsset assetName={provider.slug} size={16} />
      <span>Join {provider.name}</span>
    </Button>
  )
}

export default JoinWebConferenceButton
