import * as React from 'react'
import { gql } from '@apollo/client'
import { Modal, Media, Button, ListGroup } from 'react-bootstrap'
import Icon from './Icon'
import MemberContext from './MemberContext'
import WebConferencingProviderRow from './WebConferencingProviderRow'
import GoogleMeetExplainerModal from './GoogleMeetExplainerModal'
import Toggler from './Toggler'
import {
  Maybe,
  SettingsModalWebConferencingSectionQuery as Response,
  useSettingsModalWebConferencingSectionQuery,
} from '../__generated__/graphql'
import {
  ConferenceProvider,
  ConferencingAccount,
  ConferenceAccountTypeNames,
} from '../types'
import { DateTime, Analytics } from '../utils'
import Translate from './Translate'
import SettingsSectionPicker from './SettingsSectionPicker'
import { SettingsSection } from './SettingsModal'

gql`
  query SettingsModalWebConferencingSection($id: ID!) {
    member: getMemberById(id: $id) {
      id
      conferencingAccounts {
        edges {
          node {
            # We query this because this is an interface and __typename
            # will be different for each provider.
            __typename
            deleted
            id
            isDefault
            name
            provider {
              id
              name
              slug
              oauth2AuthorizationUrl
            }
          }
        }
      }
    }
    providers: getConferenceProviders {
      edges {
        node {
          id
          name
          oauth2AuthorizationUrl
          slug
        }
      }
    }
  }
`

type Props = {
  onSelect: (section: SettingsSection) => void
}

export type InternalData = {
  accounts: Array<
    Pick<ConferencingAccount, 'deleted' | 'id' | 'isDefault' | 'name'> & {
      __typename: ConferenceAccountTypeNames
      provider: Pick<
        ConferenceProvider,
        'id' | 'name' | 'oauth2AuthorizationUrl' | 'slug'
      >
    }
  >
  providers: Array<
    Pick<ConferenceProvider, 'id' | 'name' | 'oauth2AuthorizationUrl' | 'slug'>
  >
}

const wireDataToInternalData = (wireData: Response): InternalData => ({
  accounts: wireData.member.conferencingAccounts.edges.map(edge => ({
    ...edge!.node!,
    __typename: edge!.node!.__typename!,
    deleted: edge!.node!.deleted ? new DateTime(edge!.node!.deleted) : null,
    provider: edge!.node!.provider!,
  })),
  providers: wireData.providers.edges.map(edge => ({ ...edge!.node! })),
})

const SettingsModalWebConferencingSection: React.FC<Props> = ({ onSelect }) => {
  const member = React.useContext(MemberContext)
  const { data, loading, refetch } =
    useSettingsModalWebConferencingSectionQuery({
      variables: { id: member.id },
    })
  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? wireDataToInternalData(data) : null),
    [data]
  )
  return (
    <div className="SettingsModalWebConferencingSection">
      <Modal.Header closeButton>
        <Icon.Monitor className="tw-hidden tw-text-bsGray-600 lg:tw-block" />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title as="h2" className="tw-hidden md:tw-block">
            <Translate>Web Conferencing</Translate>
          </Modal.Title>
          <Translate as="p">
            Connect your preferred tool for virtual meetings.
          </Translate>
        </div>
        <SettingsSectionPicker onChange={onSelect} value="web-conferencing" />
      </Modal.Header>
      {!loading && internalData ? (
        <Modal.Body>
          <p className="tw-mb-6">
            <Translate>
              We’ll automatically add meeting room links with your favorite
              tool.
            </Translate>
          </p>

          <ListGroup>
            {internalData.providers.map(provider => (
              <ListGroup.Item key={provider.id}>
                <WebConferencingProviderRow
                  provider={provider}
                  onConnect={() => {
                    refetch()

                    Analytics.trackEvent('Connected Conferencing Integration', {
                      source: 'settings',
                      provider: provider.slug,
                    })
                  }}
                  // If there is a non-deleted account that matches the provider, pass it in.
                  account={internalData.accounts.find(
                    account =>
                      !account.deleted && account.provider.id === provider.id
                  )}
                />
              </ListGroup.Item>
            ))}
            <ListGroup.Item>
              <Media className="tw-items-center tw-space-x-3">
                <Icon.InternalAsset assetName="google" size={40} />
                <Media.Body className="tw-flex tw-flex-col">
                  <Translate as="strong">Google Meet</Translate>
                  <span className="tw-text-bsGray-600">
                    <Translate>
                      Generate a unique Google Meet link for each new meeting
                    </Translate>
                    .
                  </span>
                </Media.Body>

                <Toggler>
                  {({ isToggled, setOff, setOn }) => (
                    <React.Fragment>
                      <Button variant="link" onClick={setOn}>
                        <Translate>More Info</Translate>
                      </Button>
                      {isToggled && (
                        <GoogleMeetExplainerModal onHide={setOff} />
                      )}
                    </React.Fragment>
                  )}
                </Toggler>
              </Media>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
      ) : null}
    </div>
  )
}

export default SettingsModalWebConferencingSection
