import * as React from 'react'
import { Modal, Button } from 'react-bootstrap'
import Icon from './Icon'
import Translate from './Translate'

export type Props = {
  onHide: () => void
}

const GoogleMeetingExplainerModal: React.FC<Props> = ({ onHide }) => (
  <Modal
    className="GoogleMeetingExplainerModal"
    show={true}
    size="sm"
    onHide={onHide}
  >
    <Modal.Header closeButton>
      <Icon.InternalAsset assetName="google" size={36} className="tw-mr-3" />
      <Modal.Title>
        <Translate>Google Meeting Integration</Translate>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Translate as="p">
        Google Meet uses the Google Calendar integration to add Meet conference
        rooms to your meetings.
      </Translate>

      <Translate as="p">
        Once you have connected a Google Calendar account, you can edit your
        meeting types and set the location to “Web Conferencing” {`>`} “Google
        Meeting”.
      </Translate>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" block onClick={onHide}>
        <Translate>Got It</Translate>
      </Button>
    </Modal.Footer>
  </Modal>
)

export default GoogleMeetingExplainerModal
