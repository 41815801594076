import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Form, FormControl, InputGroup } from 'react-bootstrap'

import './SlugInput.scss'

export interface SlugInputProps {
  readonly slug: string
  readonly onChange: (value: string) => void
  readonly error?: FinalForm.FieldMetaState<string>
}

export const SlugInput: React.FC<SlugInputProps> = ({
  onChange,
  error,
  slug,
}: SlugInputProps) => {
  return (
    <div className="SlugInput">
      <Form.Label
        className={`${!!error?.invalid ? 'text-danger' : undefined}`}
        htmlFor="slug"
      >
        Choose Your Scheduling Page URL
      </Form.Label>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text
            className={`inner-label border-right-0 ${
              !!error?.invalid
                ? 'error text-danger border-top border-bottom border-left border-danger'
                : undefined
            }`}
            id="slug"
          >
            appt.link/
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          isInvalid={!!error?.invalid}
          onChange={ev => onChange(ev.currentTarget.value)}
          placeholder="Scheduling Page URL"
          value={slug}
        />
      </InputGroup>
      {!error?.invalid && (
        <div className="text-gray-600 mt-4">
          You’ll send your users here to schedule a time to meet. Base it on
          your name or your company.
        </div>
      )}
      {!!error?.invalid && (
        <Form.Text className="text-danger">{error.submitError}</Form.Text>
      )}
    </div>
  )
}
