import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

import FormBuilderFieldPreview from './FormBuilderFieldPreview'
import FormFieldIcon from './FormFieldIcon'
import FormFieldSettings from './FormFieldSettings'
import { fieldTypeLabels } from './FormFieldTypePicker'
import FormFieldPreFill from './FormFieldPreFill'
import { IFormField } from './UpdateMeetingTypeContext'
import Translate from './Translate'

export type Props = {
  field: IFormField
  onChange: (field: IFormField) => void
  onHide: () => void
}

const UpdateFormFieldModal: React.FC<Props> = ({ field, onChange, onHide }) => {
  return (
    <Modal onHide={onHide} show size="lg">
      <Modal.Header closeButton>
        <FormFieldIcon fieldType={field.fieldType} size={30} />
        <Modal.Title>Update: {fieldTypeLabels[field.fieldType]}</Modal.Title>
      </Modal.Header>

      <FinalForm.Form<IFormField>
        initialValues={{
          ...field,
          helpText: field.helpText || '',
        }}
        // For updates we don't allow fieldType to be changed, but it's included
        // in initialValues so that FormFieldSettings can access it.
        onSubmit={({ fieldType, ...rest }) =>
          onChange({
            ...field,
            ...rest,
          })
        }
      >
        {({ handleSubmit, hasValidationErrors, values }) => (
          <Form
            className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
            onSubmit={handleSubmit}
          >
            <Modal.Body>
              <Row className="tw-mb-6">
                <Col lg={6}>
                  <FormFieldSettings />
                </Col>
                <Col className="tw-hidden lg:tw-block">
                  <div className="tw-flex tw-items-center tw-h-full tw-rounded-[5px] tw-relative tw-p-6 tw-justify-center tw-bg-bsGray-200">
                    <p className="text-gray-600 position-absolute tw-text-bsGray-600 tw-absolute tw-right-4 tw-top-4">
                      <Translate>Preview</Translate>
                    </p>
                    <FormBuilderFieldPreview field={values as IFormField} />
                  </div>
                </Col>
              </Row>
              <FormFieldPreFill
                isDisabled={hasValidationErrors}
                isUpdate
                isVisible={!field.visible}
              />
            </Modal.Body>

            <Modal.Footer className="sm:tw-justify-end">
              <Button
                className="tw-flex-1 sm:tw-flex-initial"
                onClick={onHide}
                variant="outline-secondary"
              >
                Cancel
              </Button>
              <Button className="tw-flex-1 sm:tw-flex-initial" type="submit">
                Update Form Field
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </FinalForm.Form>
    </Modal>
  )
}

export default UpdateFormFieldModal
