import * as React from 'react'

import './PortraitList.scss'
import Portrait, { PortraitData } from './Portrait'
import { toRem } from '../utils'

export interface Props {
  size: number
  portraits: Array<PortraitData>

  // Will only show this many portraits before summarizing the rest with "+..."
  // at the end.
  max?: number
}

const PortraitList: React.FC<Props> = ({ portraits, size, max }) => (
  <ul className="PortraitList d-flex flex-row list-unstyled m-0 p-0">
    {portraits.slice(0, max ? max : portraits.length).map((portrait, idx) => (
      // portraits.length - idx will give us the desired result of the first
      // portrait sitting on top and the other descending in depth.
      <li style={{ zIndex: portraits.length - idx }} key={idx}>
        <Portrait {...portrait} size={size} />
      </li>
    ))}

    {max && portraits.length > max && (
      <li style={{ zIndex: portraits.length - max }}>
        <div
          className="Portrait"
          style={{ height: toRem(size), width: toRem(size) }}
        >
          <div className="Portrait--initials">+{portraits.length - max}</div>
        </div>
      </li>
    )}
  </ul>
)

export default PortraitList
