import * as React from 'react'
import { Button, Card, Dropdown } from 'react-bootstrap'

import './MeetingsListItem.scss'
import DropdownItem from './DropdownItem'
import Icon from './Icon'
import { IMeeting } from './MeetingsPage'
import MemberContext from './MemberContext'
import MoreButton from './MoreButton'
import Now from './Now'
import PortraitList from './PortraitList'
import RescheduleMeetingModal from './RescheduleMeetingModal'
import TimeLabel from './TimeLabel'
import Toggler from './Toggler'
import { generateUserLabel } from './UserLabel'
import { LocationType, AttendeeStatus } from '../__generated__/graphql'
import { DateTime, DateTimeRange, truncateList } from '../utils'
import JoinWebConferenceButton from './JoinWebConferenceButton'

type Props = {
  meeting: IMeeting
  onCancel?: VoidFunction
  onApprove?: VoidFunction
  onDecline?: VoidFunction
  onViewDetails: VoidFunction
}

type Status = 'cancelled' | 'confirmed' | 'past' | 'pending'

const meetingStatus = (
  meeting: IMeeting,
  pendingAttendeeCount: number
): Status | undefined => {
  // Handle cancelled case.
  if (meeting.cancelled) {
    return 'cancelled'
  }
  // Check if the meeting is in the past.
  if (meeting.end.isBefore(new DateTime())) {
    return 'past'
  }

  if (
    meeting.confirmed ||
    // If this is a group meeting and we don't have any pending attendees,
    // show as confirmed.
    (meeting.isGroup && pendingAttendeeCount === 0)
  ) {
    return 'confirmed'
  }

  return 'pending'
}

const MeetingsListItem: React.FC<Props> = ({
  meeting,
  onCancel,
  onApprove,
  onDecline,
  onViewDetails,
}) => {
  const member = React.useContext(MemberContext)

  // Filter out the current user from the list of attendees.
  const attendees = meeting.attendees.filter(
    attendee =>
      // Don't show the portrait of the current user or cancelled attendees
      // (unless the whole meeting is cancelled).
      !(
        (attendee.member && attendee.member.id === member.id) ||
        (attendee.status === AttendeeStatus.Cancelled && !meeting.cancelled)
      )
  )

  // Tells us if this is a group meeting with pending attendees
  const pendingAttendees = meeting.attendees.filter(
    attendee => attendee.approved === null
  )

  // Get this value each render.
  const currentStatus: Status = meetingStatus(meeting, pendingAttendees.length)!

  return (
    <Card
      className={`MeetingsListItem flex-row MeetingsListItem--status--${currentStatus}`}
    >
      {/*
      We use this element to place an overlay on the component for the
      navigation action to live on in which the z-index is set to zero.
      All the underlying actionable items have a z-index of one so they
      will present higher in the DOM and be intractable without the click
      for this component being propagated. Unfortunately our hack of using
      event.stopPropagation here and I'm not entirely sure why that's the
      case.
     */}
      <div className="overlay-opener" onClick={() => onViewDetails()} />
      <div className="align-items-center d-flex MeetingsListItem--Time background-gray-100 pl-16">
        <TimeLabel format="localized-time" time={meeting.start} />
        {' - '}
        <TimeLabel format="localized-time" time={meeting.end} />
      </div>

      <div className="align-items-center d-flex flex-fill p-12 MeetingsListItem--Body space-between-8 text-truncate">
        <PortraitList
          // TYPESCRIPT: This is angry because of Attendee typings.
          // @ts-ignore
          portraits={attendees}
          max={3}
          size={36}
        />

        <div className="MeetingsListItem--Body--Meta d-flex flex-column flex-fill text-truncate">
          <small className="text-gray-600">{meeting.name}</small>

          <strong>
            {truncateList(
              attendees.map(attendee => generateUserLabel(attendee))
            )}
          </strong>
        </div>

        <div className="align-items-center d-flex space-between-8 MeetingsListItem--Body--Actions">
          {/* Cancel/Reschedule Buttons [ONLY VISIBLE ON HOVER] */}
          {currentStatus === 'confirmed' && (
            <Now>
              {now => (
                <React.Fragment>
                  <Toggler>
                    {({ isToggled, setOff, setOn }) =>
                      now.isBefore(meeting.end) && (
                        <React.Fragment>
                          <Button
                            // This is shown on hover
                            className="MeetingsListItem--RescheduleButton d-none"
                            onClick={setOn}
                            size="sm"
                            variant="outline-secondary"
                          >
                            <Icon.Repeat className="text-purple" size={16} />
                            Reschedule
                          </Button>

                          {isToggled && (
                            <RescheduleMeetingModal
                              meeting={meeting}
                              onHide={setOff}
                              onSuccess={setOff}
                            />
                          )}
                        </React.Fragment>
                      )
                    }
                  </Toggler>

                  <Button
                    // This is shown on hover
                    className="MeetingsListItem--CancelButton d-none"
                    onClick={onCancel}
                    size="sm"
                    variant="outline-secondary"
                  >
                    <Icon.X className="text-danger" size={16} />
                    Cancel
                  </Button>
                </React.Fragment>
              )}
            </Now>
          )}

          {/* Confirm/Decline Buttons for manual confirmation. */}
          {currentStatus === 'pending' && !meeting.isGroup && (
            <React.Fragment>
              <Button onClick={onApprove} size="sm" variant="outline-secondary">
                <Icon.ThumbsUp className="text-success" size={16} />
                Approve
              </Button>

              <Button onClick={onDecline} size="sm" variant="outline-secondary">
                <Icon.ThumbsDown className="text-danger" size={16} />
                Decline
              </Button>
            </React.Fragment>
          )}

          {currentStatus === 'pending' &&
            meeting.isGroup &&
            pendingAttendees.length > 0 && (
              <Button
                onClick={() => onViewDetails()}
                size="sm"
                variant="outline-secondary"
              >
                <Icon.Users className="text-success" size={16} />
                {pendingAttendees.length === 1 && '1 Pending Attendee'}
                {pendingAttendees.length > 1 &&
                  `${pendingAttendees.length} Pending Attendees`}
              </Button>
            )}

          {/*
            If there is a conferencing account and this is one of
            the Web Conference options then render <Now />
            We do the check like this to prevent unneeded polling in the background.
          */}
          {meeting.confirmed &&
            (meeting.locationType === LocationType.GoogleMeet ||
              meeting.locationType === LocationType.WebConference) &&
            (meeting.conferenceUrl || meeting.conferencingAccount) && (
              <Now>
                {now => (
                  <React.Fragment>
                    {/* Show this one hour before the meeting until the end of the meeting */}
                    {now.isBetween(
                      new DateTimeRange(
                        meeting.start.subtract({ hour: 1 }),
                        meeting.end
                      )
                    ) ? (
                      meeting.locationType === LocationType.GoogleMeet ? (
                        <JoinWebConferenceButton
                          provider={{
                            slug: 'google',
                            id: '',
                            name: 'Google Meet',
                          }}
                          url={meeting.conferenceUrl!}
                        />
                      ) : (
                        <JoinWebConferenceButton
                          provider={meeting.conferencingAccount!.provider}
                          url={meeting.conferenceUrl!}
                        />
                      )
                    ) : null}
                  </React.Fragment>
                )}
              </Now>
            )}
        </div>
      </div>

      {/*This is kinda hacky having this here, but it's the only way to keep it out of
       the "overflow hidden" that impacts the MeetingsListItem--Body */}
      <div className="MeetingsListItem--More d-flex align-items-center pr-12">
        <Now>
          {now => (
            <MoreButton alignRight size="sm">
              <DropdownItem onClick={() => onViewDetails()}>
                View Details
              </DropdownItem>

              {!meeting.cancelled &&
                meeting.confirmed &&
                now.isBefore(meeting.end) && (
                  <React.Fragment>
                    <Dropdown.Divider />

                    <DropdownItem onClick={onCancel}>Cancel</DropdownItem>

                    <Toggler>
                      {modal => (
                        <React.Fragment>
                          <DropdownItem onClick={modal.setOn}>
                            Reschedule
                          </DropdownItem>

                          {modal.isToggled && (
                            <RescheduleMeetingModal
                              meeting={meeting}
                              onHide={modal.setOff}
                              onSuccess={modal.setOff}
                            />
                          )}
                        </React.Fragment>
                      )}
                    </Toggler>
                  </React.Fragment>
                )}

              {!meeting.cancelled &&
                !meeting.confirmed &&
                now.isBefore(meeting.end) && (
                  <React.Fragment>
                    <Dropdown.Divider />

                    <DropdownItem onClick={onApprove}>Approve</DropdownItem>
                    <DropdownItem onClick={onDecline}>Decline</DropdownItem>
                  </React.Fragment>
                )}
            </MoreButton>
          )}
        </Now>
      </div>
    </Card>
  )
}

export default MeetingsListItem
