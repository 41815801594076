import * as React from 'react'
import './TimeInput.scss'
import Picker from './Picker'
import { DateTime, DateTimeRange, Duration } from '../utils'
import Icon from './Icon'
import Translate from './Translate'

export interface Props {
  isInvalid?: boolean
  onChange: (newTime: DateTime) => void
  step?: Duration
  value: DateTime
}

const TimeInput: React.FC<Props> = ({ isInvalid, onChange, step, value }) => {
  // Generate a list of options
  const options = React.useMemo(() => {
    return new DateTimeRange(
      value.startOf('day'),
      // NOTE: We need to add 1 day & move it to the startOf('day')
      // This is necessary to populate the "end" <TimeInput />.
      value.add({ day: 1 }).startOf('day')
    )
      .createDateTimeList({ minutes: step ? step.asMinutes() : 5 })
      .map(datetime => ({
        label: datetime.format('localized-time'),
        value: datetime,
      }))
  }, [step, value])

  // If we got a value, look up it's option
  const currentOption = React.useMemo(
    () => value && options.find(opt => opt.value.isSame(value)),
    [value, options]
  )

  return (
    <div className="TimeInput d-flex space-between-4">
      <Picker<DateTime>
        required
        invalid={isInvalid}
        value={currentOption || null}
        onChange={value => onChange(value?.value!)}
        options={options}
        hideCaret={true}
        searchFilter={(option, search) =>
          option.label
            .replace(':', '')
            .replace(' ', '')
            .startsWith(search.toUpperCase().replace(/[^0-9APM]+/, ''))
        }
        placeholder={
          <React.Fragment>
            <Icon.Clock className="mr-8 text-gray-400" size="18" />
            <Translate>Choose a time</Translate>
          </React.Fragment>
        }
      />
    </div>
  )
}

export default TimeInput
