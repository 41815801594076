import React, { ReactElement } from 'react'
import { NavDropdown } from 'react-bootstrap'
import './OnboardHeader.scss'
import Icon from '../../../Icon'
import emptyUser from '../../../../assets/empty-user.svg'
import logo from '../../../../assets/bird-and-text-logo.svg'
import Gravatar from '../../../Gravatar'
import { HelpDropdown } from '../../../molecules/HelpDropdown/HelpDropdown'
import { getAccessToken } from '../../../../utils'

export interface OnboardHeaderProps {
  readonly email: string
  readonly logoutCallback: () => void
  readonly chatCallback: () => void
}

export const OnboardHeader = ({
  email,
  logoutCallback,
  chatCallback,
}: OnboardHeaderProps): ReactElement => {
  return (
    <div className="OnboardHeader align-items-center justify-content-between d-flex">
      <img className="logo-img" alt="Appointlet Logo" src={logo} />
      <div className="d-flex align-items-center">
        <HelpDropdown chatCallback={chatCallback} />
        <NavDropdown
          alignRight
          className="no-caret account-dropdown ml-16"
          id="account menu"
          title={
            <div className="user-gravatar">
              <img className="user-image" alt="empty user" src={emptyUser} />
              <Gravatar email={email} size={36} hideIfNone />
            </div>
          }
        >
          <NavDropdown.Item
            className="text-gray-900 d-flex align-items-center"
            onClick={logoutCallback}
          >
            <Icon.LogOut size={18} />
            Logout
          </NavDropdown.Item>
          {process.env.REACT_APP_NETLIFY &&
            process.env.REACT_APP_BRANCH !== 'production' && (
              <React.Fragment>
                <NavDropdown.Divider />

                <NavDropdown.Header>Staging</NavDropdown.Header>

                <NavDropdown.Item
                  onClick={() => {
                    const branchOrCommit = window.prompt(
                      'Enter branch name or commit ID'
                    )

                    if (branchOrCommit) {
                      window.location.assign(
                        `https://${branchOrCommit}--appointlet-dashboard.netlify.app/auth.html#access_token=${getAccessToken()}`
                      )
                    }
                  }}
                >
                  <Icon.Eye size={20} />
                  Preview Build
                </NavDropdown.Item>
              </React.Fragment>
            )}
        </NavDropdown>
      </div>
    </div>
  )
}
