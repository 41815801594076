export function truncateList(items: Array<string>, max = 3): string {
  // Get a list of the items sliced to the 'max' value and sort it A-Z.
  const labels = items.slice(0, 3).sort()

  // If the items list is greater than the 'max' value add "X others" to the end of the label.
  if (items.length > max) {
    labels.push(`${items.length - max} others`)
  }

  // If there are more than one label, prefix "and" to the front of the last label.
  if (labels.length > 1) {
    labels[labels.length - 1] = `and ${labels[labels.length - 1]}`
  }

  // Create the final string.
  const fullLabel = labels.length > 2 ? labels.join(', ') : labels.join(' ')

  return fullLabel
}
