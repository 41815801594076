import * as React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/esm/Overlay'

import './IconButton.scss'
import TooltipOverlay from './TooltipOverlay'

interface Props extends ButtonProps {
  placement?: Placement
  tooltip?: string
}

const IconButton: React.FC<Props> = ({
  tooltip,
  children,
  className,
  placement,
  variant,
  ...rest
}) => {
  return (
    <TooltipOverlay
      disabled={!(tooltip && tooltip.length)}
      delay={{ show: 300, hide: 0 }}
      id="icon-button"
      placement={placement}
      text={tooltip || ''}
    >
      <Button
        className={`IconButton tw-bg-transparent tw-border-0 tw-p-0 tw-shadow-none tw-text-bsGray-500 ${
          variant === 'danger'
            ? 'focus:tw-text-bsDanger hover:tw-text-bsDanger'
            : variant === 'primary'
            ? 'focus:tw-text-bsPrimary hover:tw-text-bsPrimary'
            : variant === 'success'
            ? 'focus:tw-text-bsSuccess hover:tw-text-bsSuccess'
            : ''
        } ${className || ''}`}
        variant={variant}
        {...rest}
      >
        {children}
      </Button>
    </TooltipOverlay>
  )
}

export default IconButton
