import * as React from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import Image from './Image'
import Helmet from 'react-helmet'

import './ImageInput.scss'
import EmojiPicker from './EmojiPicker'
import Icon from './Icon'
import { expandClassName, openUploader } from '../utils'

interface Props {
  crop?: string
  value: string | null
  defaultValue?: string
  onChange: (string: string | null) => void
  uploadOnly?: boolean
}

interface UploadData {
  cdnUrl: string
}

const ImageInput: React.FC<Props> = ({
  value,
  onChange,
  defaultValue,
  crop,
  uploadOnly,
}) => {
  const selectAndClose = (url: string) => {
    onChange(url)

    // Closes the popover
    document.body.click()
  }

  const openImageUploader = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    openUploader({ crop }).then((result: UploadData) => {
      selectAndClose(result.cdnUrl)
    })
  }
  return (
    <div
      className={expandClassName(
        'ImageInput tw-overflow-hidden tw-cursor-pointer tw-relative tw-h-20 tw-w-20 md:tw-h-[100px] md:tw-w-[100px]',
        // We do not want to add the border when an image has been uploaded.
        // If we don't we get a double border, one gray, one transparent and it
        // looks wonky.
        value
          ? undefined
          : 'tw-border tw-border-solid tw-border-bsGray-300 tw-rounded-[5px]'
      )}
    >
      <Helmet>
        <script
          async
          defer
          charSet="utf-8"
          src="https://ucarecdn.com/libs/widget/3.x/uploadcare.full.min.js"
        ></script>
      </Helmet>
      <OverlayTrigger
        // This fixes the bug where if the window is resized or opened to small the
        // popover and it's contents are clipped.
        // https://app.clubhouse.io/appointlet/story/1143/when-adding-time-blocks-to-a-schedule-if-the-window-is-too-small-the-popover-goes-off-the-screen
        flip
        trigger="click"
        placement="right"
        show={uploadOnly ? false : undefined}
        rootClose
        rootCloseEvent="mousedown"
        popperConfig={{
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                // This causes the popover to position itself relative to the window
                // instead of the first position:fixed parent.  It allows it to hang
                // off a modal's edge, for example.
                boundariesElement: 'window',
              },
            },
          ],
        }}
        overlay={
          <Popover id="ImageInput-popover">
            {!uploadOnly && (
              <React.Fragment>
                <EmojiPicker onSelect={selectAndClose} />

                <Popover.Content>
                  <div>
                    <Button onClick={openImageUploader} variant="link">
                      Upload custom image...
                    </Button>
                  </div>
                </Popover.Content>
              </React.Fragment>
            )}
          </Popover>
        }
      >
        <div
          className="tw-overflow-hidden tw-items-center tw-flex tw-justify-center tw-absolute tw-inset-0"
          onClick={e => (uploadOnly ? openImageUploader(e) : null)}
        >
          <Icon.UploadCloud className="tw-p-2 tw-rounded-full" size={40} />
        </div>
      </OverlayTrigger>

      {!value && (
        <Icon.InternalAsset assetName="egg-in-nest" className="tw-max-w-full" />
      )}

      {value && (
        <React.Fragment>
          <div
            className="tw-absolute tw-top-0 tw-right-0 tw-z-[2]"
            onClick={() => onChange(null)}
          >
            <Icon.XCircle className="tw-p-[2px] tw-rounded-full" size={20} />
          </div>
          <Image
            className="tw-rounded-[5px] tw-h-full tw-w-full"
            src={value || defaultValue}
          />
        </React.Fragment>
      )}
    </div>
  )
}

export default ImageInput
