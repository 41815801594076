import * as React from 'react'
import ButtonGroupInput from './ButtonGroupInput'
import { ScheduleKind } from '../types'

interface Props {
  value: ScheduleKind
  onChange: (value: ScheduleKind) => void
}

const ScheduleKindInput: React.FC<Props> = ({ value, onChange }) => (
  <div className="ScheduleKindInput">
    <ButtonGroupInput<ScheduleKind>
      options={[
        {
          label: 'Repeats Weekly',
          value: ScheduleKind.Recurring,
          className: 'ScheduleKindInput--RepeatsWeekly',
        },
        {
          label: 'Date Range',
          value: ScheduleKind.FixedRange,
          className: 'ScheduleKindInput--DateRange',
        },
      ]}
      value={value}
      onChange={onChange}
    />
  </div>
)

export default ScheduleKindInput
