import * as React from 'react'

import './PanelTitle.scss'
import { expandClassName } from '../utils'

type Props = {}

const PanelTitle: React.FC<Props & React.HTMLProps<HTMLParagraphElement>> = ({
  children,
  className,
  ...rest
}) => (
  <p className={expandClassName('PanelTitle', className)} {...rest}>
    {children}
  </p>
)

export default PanelTitle
