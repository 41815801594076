import * as React from 'react'
import { gql } from '@apollo/client'
import { ListGroup, Media, Modal } from 'react-bootstrap'

import './SettingsModalCalendarSection.scss'
import MemberContext from './MemberContext'
import {
  Maybe,
  SettingsModalCalendarSectionQuery as QueryResponse,
  useSettingsModalCalendarSectionQuery,
  useCreateCalendarAccountMutation,
  useUpdateCalendarAccountMutation,
} from '../__generated__/graphql'
import { Calendar, CalendarAccount, CalendarProvider } from '../types'
import { startOAuthFlow, WindowClosed, toast, Analytics } from '../utils'
import CalendarProviderItem from './CalendarProviderItem'
import CalendarAccountItem from './CalendarAccountItem'
import DeleteCalendarAccountModal from './DeleteCalendarAccountModal'
import ProfileContext from './ProfileContext'
import Spinner from './Spinner'
import Icon from './Icon'
import Switch from 'react-bootstrap/esm/Switch'
import UpdateMeetingCalendarModal from './UpdateMeetingCalendarModal'
import UpdateAvailabilityCalendarsModal from './UpdateAvailabilityCalendarsModal'
import FeatureFlag from './FeatureFlag'
import {
  AddToCalendarOption,
  CheckForConflictsOption,
} from './molecules/CalendarSettingsOption/CalendarSettingsOption'
import Translate from './Translate'
import SettingsSectionPicker from './SettingsSectionPicker'
import { SettingsSection } from './SettingsModal'

gql`
  query SettingsModalCalendarSection($id: ID!) {
    calendarProviders: getCalendarProviders {
      edges {
        node {
          id
          name
          oauth2AuthorizationUrl
          slug
          shortDescription
        }
      }
    }

    member: getMemberById(id: $id) {
      id
      calendarAccounts {
        edges {
          node {
            id
            name
            isConnected
            oauth2AuthorizationUrl
            provider {
              id
            }
          }
        }
      }
      meetingsCalendar {
        id
        name
        color
      }
      availabilityCalendars {
        edges {
          node {
            id
            name
            color
          }
        }
      }
    }
  }
`

gql`
  mutation CreateCalendarAccount($input: CreateCalendarAccountInput!) {
    createCalendarAccount(input: $input) {
      data {
        id
        name
        isConnected
        oauth2AuthorizationUrl
        provider {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  mutation UpdateCalendarAccount($input: UpdateCalendarAccountInput!) {
    updateCalendarAccount(input: $input) {
      data {
        id
        name
        isConnected
        oauth2AuthorizationUrl
        provider {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

type Props = {
  onSelect: (section: SettingsSection) => void
}

export type InternalData = {
  calendarProviders: Array<
    Pick<
      CalendarProvider,
      'id' | 'name' | 'oauth2AuthorizationUrl' | 'slug' | 'shortDescription'
    >
  >
  calendarAccounts: Array<
    Pick<
      CalendarAccount,
      'id' | 'name' | 'isConnected' | 'oauth2AuthorizationUrl'
    > & {
      provider: Pick<CalendarProvider, 'id'>
    }
  >
  meetingsCalendar: Maybe<Pick<Calendar, 'id' | 'name' | 'color'>>
  availabilityCalendars: Array<Pick<Calendar, 'id' | 'name' | 'color'>>
}

const queryDataToInternalData = (queryData: QueryResponse): InternalData => ({
  calendarProviders: queryData.calendarProviders.edges.map(edge => edge!.node!),
  meetingsCalendar: queryData.member.meetingsCalendar,
  availabilityCalendars: queryData.member.availabilityCalendars.edges.map(
    edge => edge!.node!
  ),
  calendarAccounts: queryData.member.calendarAccounts.edges.map(edge => ({
    ...edge!.node!,
    provider: edge!.node!.provider!,
  })),
})

const SettingsModalCalendarSection: React.FC<Props> = ({ onSelect }) => {
  const profile = React.useContext(ProfileContext)
  const member = React.useContext(MemberContext)

  // Prep mutations
  const [createCalendarAccount] = useCreateCalendarAccountMutation()
  const [updateCalendarAccount] = useUpdateCalendarAccountMutation()

  // Fetch our data from the server
  const { data, refetch } = useSettingsModalCalendarSectionQuery({
    variables: { id: member.id },
  })

  // Shape the data
  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data ? queryDataToInternalData(data) : null),
    [data]
  )

  // Routine to connect a new calendar account
  const connectCalendarAccount = async (
    provider: Pick<
      CalendarProvider,
      'id' | 'name' | 'slug' | 'oauth2AuthorizationUrl'
    >
  ) => {
    try {
      // Launch the OAuth flow
      const code = await startOAuthFlow(provider.oauth2AuthorizationUrl, {
        width: 400,
        height: 800,
      })

      const res = await createCalendarAccount({
        variables: {
          input: {
            oauth2Code: code,
            profile: profile.id,
            provider: provider.id,
          },
        },
      })

      // If client validation and GraphQL error related errors exist
      if (
        res.data?.createCalendarAccount?.errors ||
        !res.data?.createCalendarAccount?.data
      ) {
        console.error('createCalendarAccount', res.data?.createCalendarAccount)
        throw new Error('Failed to reconnect calendar account')
      }

      toast.success(
        `Connected ${provider.name} account`,
        res.data.createCalendarAccount.data.name
      )

      Analytics.trackEvent('Connected Calendar', {
        source: 'settings',
        calendarProvider: provider.slug,
      })
    } catch (err) {
      if (err === WindowClosed) return

      toast.error('Something went wrong')
      console.error('createCalendarAccount', err)
    }

    // Refetch the calendar data
    refetch()
  }

  // Routine to reconnect a disconnected calendar
  const reconnectCalendarAccount = async (
    account: Pick<CalendarAccount, 'id' | 'name' | 'oauth2AuthorizationUrl'>
  ) => {
    try {
      // Launch the OAuth flow
      const code = await startOAuthFlow(account.oauth2AuthorizationUrl, {
        width: 400,
        height: 800,
      })

      // Try and update the calendar account
      const res = await updateCalendarAccount({
        variables: {
          input: {
            id: account.id,
            name: account.name,
            oauth2Code: code,
          },
        },
      })

      // If client validation and GraphQL error related errors exist
      if (
        res.data?.updateCalendarAccount?.errors ||
        !res.data?.updateCalendarAccount?.data
      ) {
        console.error(
          'reconnectCalendarAccount',
          res.data?.updateCalendarAccount
        )

        throw new Error('Failed to reconnect calendar account')
      }

      toast.success(`Reconnected ${account.name}`)
    } catch (err) {
      if (err === WindowClosed) return

      toast.error('Something went wrong')
      console.error('reconnectCalendarAccount', err)
    }
  }

  // State to contain a calendar account that is in the process of being deleted.
  const [calendarAccountBeingDeleted, setCalendarAccountBeingDeleted] =
    React.useState<Maybe<Pick<CalendarAccount, 'id' | 'name'>>>(null)

  // State to control the UpdateMeetingCalendarModal modal
  const [meetingCalendarBeingEdited, setMeetingCalendarBeingEdited] =
    React.useState<boolean>(false)

  const [
    availabilityCalendarsBeingEdited,
    setAvailabilityCalendarsBeingEdited,
  ] = React.useState<boolean>(false)

  if (!internalData) {
    return (
      <div className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-flex-col">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="SettingsModalCalendarSection">
      <Modal.Header closeButton>
        <Icon.Calendar className="tw-hidden tw-text-bsGray-600 lg:tw-block" />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title as="h2" className="tw-hidden md:tw-block">
            <Translate>Calendar Sync</Translate>
          </Modal.Title>
          <Translate as="p">Connect your calendar to sync meetings.</Translate>
        </div>
        <SettingsSectionPicker onChange={onSelect} value="calendars" />
      </Modal.Header>
      <Modal.Body>
        <p className="tw-mb-6">
          <Translate>
            Meetings will automatically be shown on your connected calendar, and
            you won’t be double-booked.
          </Translate>
        </p>

        <ListGroup className="tw-mb-6">
          {internalData.calendarProviders.map(calendarProvider => {
            // Get a list of the calendar accounts that relate to this provider
            const providerCalendarAccounts =
              internalData.calendarAccounts.filter(
                calendarAccount =>
                  calendarAccount.provider.id === calendarProvider.id
              )

            return (
              <React.Fragment key={calendarProvider.id}>
                <ListGroup.Item>
                  <CalendarProviderItem
                    name={calendarProvider.name}
                    description={calendarProvider.shortDescription!}
                    slug={calendarProvider.slug}
                    onConnect={() => connectCalendarAccount(calendarProvider)}
                    isConnecting={false}
                    calendarAccount={
                      // If there are exactly one calendar accounts, pass this
                      // data in so that we can render a slightly different layout.
                      providerCalendarAccounts.length === 1
                        ? {
                            name: providerCalendarAccounts[0].name,
                            isConnected:
                              providerCalendarAccounts[0].isConnected,
                            onReconnect: () =>
                              reconnectCalendarAccount(
                                providerCalendarAccounts[0]
                              ),
                            onDelete: () =>
                              setCalendarAccountBeingDeleted(
                                providerCalendarAccounts[0]
                              ),
                          }
                        : undefined
                    }
                  />
                </ListGroup.Item>

                {providerCalendarAccounts.length !== 1 &&
                  providerCalendarAccounts.map(calendarAccount => (
                    <ListGroup.Item
                      className="tw-ml-12"
                      key={calendarAccount.id}
                    >
                      <CalendarAccountItem
                        name={calendarAccount.name}
                        lastSyncedAt={null}
                        isConnected={calendarAccount.isConnected}
                        onDelete={() =>
                          setCalendarAccountBeingDeleted(calendarAccount)
                        }
                        onReconnect={() =>
                          reconnectCalendarAccount(calendarAccount)
                        }
                      />
                    </ListGroup.Item>
                  ))}
              </React.Fragment>
            )
          })}
        </ListGroup>

        <h4 className="tw-mb-6">Settings</h4>

        <ListGroup>
          <ListGroup.Item className="MeetingsCalendarSetting">
            <AddToCalendarOption
              onClick={() => setMeetingCalendarBeingEdited(true)}
              meetingsCalendar={internalData.meetingsCalendar}
            />
          </ListGroup.Item>

          <ListGroup.Item className="AvailabilityCalendarsSetting">
            <CheckForConflictsOption
              onClick={() => setAvailabilityCalendarsBeingEdited(true)}
              availabilityCalendars={internalData.availabilityCalendars}
            />
          </ListGroup.Item>

          <FeatureFlag flag="allows-syncs-from-calendar">
            <ListGroup.Item>
              <Media className="tw-items-center">
                <Icon.InternalAsset assetName="Icon-Rescheduling" size={50} />

                <Media.Body className="tw-mx-3">
                  <Translate>
                    When deleting or moving an event, cancel/reschedule the
                    corresponding meeting.
                  </Translate>
                </Media.Body>

                <Switch />
              </Media>
            </ListGroup.Item>
          </FeatureFlag>
        </ListGroup>

        {calendarAccountBeingDeleted && (
          <DeleteCalendarAccountModal
            calendarAccountId={calendarAccountBeingDeleted.id}
            onSuccess={() => {
              // Reload the calendars and then close
              refetch().then(() => setCalendarAccountBeingDeleted(null))
            }}
            onHide={() => setCalendarAccountBeingDeleted(null)}
          />
        )}

        {meetingCalendarBeingEdited && (
          <UpdateMeetingCalendarModal
            memberId={member.id}
            value={internalData.meetingsCalendar?.id ?? null}
            onHide={() => setMeetingCalendarBeingEdited(false)}
            onSuccess={() => {
              // Reload the calendars and then close
              refetch().then(() => setMeetingCalendarBeingEdited(false))
            }}
          />
        )}

        {availabilityCalendarsBeingEdited && (
          <UpdateAvailabilityCalendarsModal
            memberId={member.id}
            value={internalData.availabilityCalendars.map(c => c.id)}
            onHide={() => setAvailabilityCalendarsBeingEdited(false)}
            onSuccess={() => {
              // Reload the calendars and then close
              refetch().then(() => setAvailabilityCalendarsBeingEdited(false))
            }}
          />
        )}
      </Modal.Body>
    </div>
  )
}

export default SettingsModalCalendarSection
