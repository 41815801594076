import { InternalUser } from '../components/UserContext'
import { env } from './env'

const TAPFILIATE_ID = env('TAPFILIATE_ID')

const TapfiliateHandler = {
  initialize: (user: InternalUser) => {
    console.log('TapfiliateHandler.initialize', user)

    if (!TAPFILIATE_ID) {
      return Promise.resolve(false)
    }

    // @ts-ignore
    window.tap('create', TAPFILIATE_ID, { integration: 'stripe' })

    return Promise.resolve(true)
  },

  startTrial: (customerId: string) => {
    console.log('TapfiliateHandler.startTrial', customerId)

    if (!TAPFILIATE_ID) {
      return Promise.resolve(false)
    }

    // @ts-ignore
    window.tap('trial', customerId)

    return Promise.resolve(true)
  },
}

export default TapfiliateHandler
