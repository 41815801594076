import * as React from 'react'

import './MeetingsListDateItem.scss'
import MeetingsListDateBlock from './MeetingsListDateBlock'
import MeetingsListItem from './MeetingsListItem'
import { IMeeting } from './MeetingsPage'
import { ID } from '../types'
import { DateTime } from '../utils'
import { orderBy } from 'lodash'

type Props = {
  date: DateTime
  meetings: Array<IMeeting>
  onCancel?: (meetingId: ID) => void
  onApprove?: (meetingId: ID) => void
  onDecline?: (meetingId: ID) => void
  onViewDetails?: (meetingId: ID) => void
}

const MeetingsListDateItem: React.FC<Props> = ({
  date,
  meetings,
  onCancel,
  onApprove,
  onDecline,
  onViewDetails,
}) => (
  <div className="mb-24 MeetingsListDateItem">
    <MeetingsListDateBlock date={date} />

    <ul className="list-unstyled flex-column space-between-12">
      {/* We do a manual re-order here because if the user is using "PAST" we want to
      ensure that meetings within a day still show up in the expected order */}
      {orderBy<IMeeting>(meetings, ['start']).map(meeting => (
        <li key={meeting.id}>
          <MeetingsListItem
            meeting={meeting}
            onCancel={() => onCancel && onCancel(meeting.id)}
            onApprove={() => onApprove && onApprove(meeting.id)}
            onDecline={() => onDecline && onDecline(meeting.id)}
            onViewDetails={() => onViewDetails && onViewDetails(meeting.id)}
          />
        </li>
      ))}
    </ul>
  </div>
)

export default MeetingsListDateItem
