import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import LoadingButton from './LoadingButton'
import { useApproveMeetingMutation } from '../__generated__/graphql'
import { ID } from '../types'
import Icon from './Icon'
import Translate from './Translate'

gql`
  mutation ApproveMeeting($input: ApproveMeetingInput!) {
    approveMeeting(input: $input) {
      data {
        id
        approved
        confirmed
      }
      errors {
        field
        messages
      }
    }
  }
`

// State manager for operating on the approve meeting modal and the related mutation.
export const useApproveMeetingModal = (initialToApprove?: ID) => {
  // Prep the mutation
  const [mutation, { loading: submitting }] = useApproveMeetingMutation()

  // Prep a piece of state that manages which meeting is in the process
  // of being approved.  If this is null, the modal is closed, if it's not,
  // the modal is open.
  const [toApprove, setToApprove] = React.useState<ID | null>(
    initialToApprove !== undefined ? initialToApprove : null
  )

  // Function to call to trigger the mutation and handle errors.
  const submit = async (meetingId: ID, message: string) => {
    const res = await mutation({
      variables: {
        input: {
          meeting: meetingId,
          message: message || null,
        },
      },
    })

    // Check for graphQL errors
    if (res.errors) {
      console.error('Unexpected error approving meeting', res.errors)
      throw new Error('Unexpected error approving meeting')
    }

    // Check for validation errors
    if (res.data?.approveMeeting?.errors) {
      console.error(
        'Validation error approving meeting',
        res.data.approveMeeting.errors
      )
      throw new Error('Validation error approving meeting')
    }

    // Close the modal
    setToApprove(null)

    return res.data!.approveMeeting!.data!
  }

  return {
    open: setToApprove,
    close: () => setToApprove(null),
    toApprove,
    submit,
    submitting,
  }
}

export type Props = {
  name: string
  submitting: boolean
  onConfirm?: (message: string) => void
  onCancel?: () => void
}

type FormValues = {
  message: string
}

const ApproveMeetingModal: React.FC<Props> = ({
  name,
  submitting,
  onConfirm,
  onCancel,
}) => (
  <Modal className="ApproveMeetingModal" onHide={onCancel} show size="sm">
    <Modal.Header closeButton>
      <Icon.ThumbsUp className="tw-text-bsSuccess" size={30} />
      <Modal.Title>
        <Translate>Approve Meeting</Translate>
      </Modal.Title>
    </Modal.Header>
    <FinalForm.Form<FormValues>
      onSubmit={values => onConfirm && onConfirm(values.message)}
    >
      {({ handleSubmit }) => (
        <Form
          className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
          onSubmit={handleSubmit}
        >
          <Modal.Body>
            <Translate as="p">
              We'll send a notification to your attendees to let them know they
              have been approved.
            </Translate>

            <Form.Group>
              <FinalForm.Field<string> name="message">
                {({ input }) => (
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Anything you'd like to pass along to attendees?"
                    {...input}
                  />
                )}
              </FinalForm.Field>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer className="tw-items-stretch tw-justify-between md:tw-justify-end">
            <Button
              className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
              onClick={onCancel}
              variant="outline-secondary"
            >
              <Translate>Cancel</Translate>
            </Button>

            <LoadingButton
              className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
              loading={submitting}
              variant="success"
              type="submit"
            >
              <Translate>Approve Meeting</Translate>
            </LoadingButton>
          </Modal.Footer>
        </Form>
      )}
    </FinalForm.Form>
  </Modal>
)

export default ApproveMeetingModal
