import * as React from 'react'

import Icon from './Icon'
import TooltipOverlay from './TooltipOverlay'
import { copyToClipboard } from '../utils'
import Translate from './Translate'

type Props = {
  onClick?: VoidFunction
  text: string
}

const CopyButton: React.FC<Props> = ({ onClick, text }) => {
  const [isCopied, setIsCopied] = React.useState<boolean>(false)
  const onCopy = React.useCallback(() => {
    copyToClipboard(text)
    setIsCopied(true)
    onClick && onClick()
    setTimeout(() => setIsCopied(false), 3000)
  }, [onClick, text])
  return (
    <TooltipOverlay text={<Translate>Copy</Translate>} id="copy-button">
      {isCopied ? (
        <Icon.Check className="tw-text-bsSuccess" size={18} strokeWidth={1} />
      ) : (
        <Icon.Copy
          className="tw-cursor-pointer tw-text-bsGray-500"
          onClick={onCopy}
          size={18}
          strokeWidth={1}
        />
      )}
    </TooltipOverlay>
  )
}

export default CopyButton
