import * as React from 'react'

import { InternalMember } from './UserContext'

const ProfileContext = React.createContext<InternalMember['profile']>(
  {} as InternalMember['profile']
)

ProfileContext.displayName = 'ProfileContext'

export default ProfileContext
