import * as React from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import * as FinalForm from 'react-final-form'
import LoadingButton from './LoadingButton'
import Icon from './Icon'
import {
  AttendeeNode,
  useApproveAttendeesMutation,
} from '../__generated__/graphql'
import { gql } from '@apollo/client'
import { ID } from '../types'
import Translate from './Translate'
import Var from './Var'

gql`
  mutation ApproveAttendees($input: ApproveAttendeesInput!) {
    approveAttendees(input: $input) {
      data {
        id
        status
        approved
      }
      errors {
        field
        messages
      }
    }
  }
`

// State manager for operating on the approve attendees modal and the related mutation.
export const useApproveAttendeesModal = (
  initialToApprove?: ReadonlyArray<ID>
) => {
  // Prep the mutation
  const [mutation, { loading: submitting }] = useApproveAttendeesMutation()

  // Prep a piece of state that manages the attendees we're about to
  // approve (which will determine if the modal is open/closed).
  const [toApprove, setToApprove] = React.useState<ReadonlyArray<ID> | null>(
    initialToApprove || null
  )

  // Function to call to trigger the mutation and handle errors.
  const submit = async (message: string) => {
    const res = await mutation({
      variables: {
        input: {
          attendees: [...toApprove!],
          message: message || null,
        },
      },
    })

    // Check for graphQL errors
    if (res.errors) {
      console.error('Unexpected error approving attendees', res.errors)
      throw new Error('Unexpected error approving attendees')
    }

    // Check for validation errors
    if (res.data?.approveAttendees?.errors) {
      console.error(
        'Validation error approving attendees',
        res.data.approveAttendees.errors
      )
      throw new Error('Validation error approving attendees')
    }

    // If for some reason the expected data isn't present then generate
    // an error.
    if (!res.data?.approveAttendees?.data) {
      console.error('No attendee data found in response', res)
      throw new Error('No attendee data found in response')
    }

    // Close the modal
    setToApprove(null)

    // Return the attendee data
    return res.data.approveAttendees.data.map(attendee => attendee!)
  }

  return {
    open: setToApprove,
    close: () => setToApprove(null),
    toApprove: toApprove,
    submit,
    submitting,
  }
}

export type Props = {
  attendees: ReadonlyArray<Pick<AttendeeNode, 'firstName' | 'lastName'>>
  submitting: boolean
  onConfirm?: (message: string) => void
  onCancel?: () => void
}

type FormValues = {
  message: string
}

const ApproveAttendeesModal: React.FC<Props> = ({
  attendees,
  submitting,
  onConfirm,
  onCancel,
}) => (
  <Modal onHide={onCancel} show size="sm">
    <Modal.Header closeButton>
      <Icon.ThumbsUp className="tw-text-bsSuccess" size={30} />
      <Modal.Title>
        <Translate>
          {attendees.length > 1 ? 'Approve Attendees' : 'Approve Attendee'}
        </Translate>
      </Modal.Title>
    </Modal.Header>

    <FinalForm.Form<FormValues>
      onSubmit={values => onConfirm && onConfirm(values.message)}
    >
      {({ handleSubmit }) => (
        <Form
          className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
          onSubmit={handleSubmit}
        >
          <Modal.Body>
            {attendees.length > 1 ? (
              <Translate as="p">
                We'll send a notification to your attendees to let them know
                they have been approved.
              </Translate>
            ) : (
              <Translate as="p">
                We'll send a notification to{' '}
                <Var name="name">
                  {attendees[0].firstName} {attendees[0].lastName}
                </Var>{' '}
                to let them know their meeting request has been approved.
              </Translate>
            )}

            <Form.Group>
              <FinalForm.Field<string> name="message">
                {({ input }) => (
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Anything you'd like to pass along?"
                    {...input}
                  />
                )}
              </FinalForm.Field>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="tw-items-stretch tw-justify-between md:tw-justify-end">
            <Button
              className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
              onClick={onCancel}
              variant="outline-secondary"
            >
              <Translate>Cancel</Translate>
            </Button>

            <LoadingButton
              className="tw-flex-1 md:tw-flex-grow-0 md:tw-flex-shrink"
              loading={submitting}
              type="submit"
              variant="success"
            >
              <Translate>
                {attendees.length > 1 ? (
                  <>
                    Approve <Var name="attendees">{attendees.length}</Var>{' '}
                    Attendees
                  </>
                ) : (
                  'Approve Attendee'
                )}
              </Translate>
            </LoadingButton>
          </Modal.Footer>
        </Form>
      )}
    </FinalForm.Form>
  </Modal>
)
export default ApproveAttendeesModal
