import * as React from 'react'
import { Redirect, RouteComponentProps } from '@reach/router'
import Helmet from 'react-helmet'

import ProfileContext from './ProfileContext'
import UserContext from './UserContext'

type Props = RouteComponentProps & {}

const OnboardingRoute: React.FC<Props> = ({ children }) => {
  const profile = React.useContext(ProfileContext)
  const { user } = React.useContext(UserContext)

  // If they're already on-boarded, move them back into the app.
  if (user.members.length && !user.needsOnboarding) {
    return <Redirect noThrow to={`/profiles/${profile.id}/scheduling-pages`} />
  }

  return (
    <React.Fragment>
      <Helmet title="Getting Started" />
      {children}
    </React.Fragment>
  )
}

export default OnboardingRoute
