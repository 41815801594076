import * as React from 'react'

import Badge from '../../../Badge'
import Icon from '../../../Icon'
import IconButton from '../../../IconButton'

export type Props = Readonly<{
  onApprove: VoidFunction
  onCancel: VoidFunction
  onDecline: VoidFunction
  onReschedule: VoidFunction
  status:
    | 'cancelled'
    | 'concluded'
    | 'in-progress'
    | 'pending'
    | 'pending-attendees'
    | 'upcoming'
  // For group meeting types we display the number of attendees who are pending
  pendingAttendeesCount?: number
}>

const statusBadgeMap: Record<Props['status'], string> = {
  upcoming: 'success',
  pending: 'warning',
  'pending-attendees': 'warning',
  'in-progress': 'success',
  concluded: 'secondary',
  cancelled: 'danger',
}

const createStatusLabel = (
  status: Props['status'],
  pendingAttendeeCount?: number
): string => {
  switch (status) {
    case 'upcoming':
      return 'Upcoming'
    case 'pending':
      return 'Pending Approval'
    case 'pending-attendees':
      // This should never happen but let's just make things
      // look reasonable in case it does.
      if (pendingAttendeeCount === undefined) {
        return 'Pending Attendee(s)'
      }

      // If there's just one, make it singular
      if (pendingAttendeeCount === 1) {
        return '1 Pending Attendee'
      }

      return `${pendingAttendeeCount} Pending Attendees`
    case 'in-progress':
      return 'In Progress'
    case 'concluded':
      return 'Concluded'
    case 'cancelled':
      return 'Cancelled'
  }
}

const MeetingDetailDrawerHeader: React.FC<Props> = ({
  onApprove,
  onCancel,
  onDecline,
  onReschedule,
  status,
  pendingAttendeesCount,
}) => (
  <div className="MeetingDetailDrawerHeader align-items-center d-flex justify-content-between">
    <Badge className="text-white" variant={statusBadgeMap[status]}>
      {createStatusLabel(status, pendingAttendeesCount)}
    </Badge>

    {/* Add some right padding when on mobile devices because the drawer
    close button will overlap with it otherwise. */}
    <div className="align-items-center d-flex space-between-16 pr-32 pr-sm-0">
      {status === 'pending' && (
        <>
          <IconButton
            onClick={onApprove}
            placement="bottom"
            variant="success"
            tooltip="Approve"
          >
            <Icon.ThumbsUp size="20" />
          </IconButton>

          <IconButton
            onClick={onDecline}
            placement="bottom"
            variant="danger"
            tooltip="Decline"
          >
            <Icon.ThumbsDown size="20" />
          </IconButton>
        </>
      )}

      {(status === 'in-progress' ||
        status === 'upcoming' ||
        status === 'pending-attendees') && (
        <>
          <IconButton
            onClick={onReschedule}
            placement="bottom"
            variant="success"
            tooltip="Reschedule"
          >
            <Icon.Calendar size="20" />
          </IconButton>
          <IconButton
            onClick={onCancel}
            placement="bottom"
            variant="danger"
            tooltip="Cancel"
          >
            <Icon.Slash size="20" />
          </IconButton>
        </>
      )}
    </div>
  </div>
)
export default MeetingDetailDrawerHeader
