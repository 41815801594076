import * as React from 'react'
import { expandClassName } from '../utils'

type Props = {
  className?: string
}

const PanelBody: React.FC<Props> = ({ children, className }) => (
  <div
    className={expandClassName(
      'tw-flex tw-flex-col tw-flex-grow tw-flex-shrink',
      className
    )}
  >
    {children}
  </div>
)

export default PanelBody
