import * as React from 'react'

import { DateTime } from '../utils'

interface Props {
  children: (now: DateTime) => React.ReactNode | React.ReactNode[]
}

// Create a global array that contains callback fns that accept DateTime objects.
let listeners: Array<(m: DateTime) => void> = []

// Every minute invoke a callback from the array.
// So basically set state in the component below every minute.
setInterval(() => {
  const now = new DateTime()
  listeners.forEach(listener => {
    listener(now)
  })
}, 60000)

const Now: React.FC<Props> = ({ children }) => {
  // Get the current date and time,
  // only get this on first-render.
  // Don't run the computation on every render.
  const [now, setNow] = React.useState<DateTime>(() => new DateTime())

  // On mount create a listener that sets the state.
  React.useEffect(() => {
    const listener = (now: DateTime) => setNow(now)
    // Push that listener to the global list that will invoke it every minute.
    listeners.push(listener)
    return () => {
      // When unmounting the component remove the listener from the global array.
      listeners = listeners.filter(l => l !== listener)
    }
  }, [])

  return <React.Fragment>{children(now)}</React.Fragment>
}

export default Now
