import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { gql } from '@apollo/client'
import { Col, Modal, Media, Form, Button } from 'react-bootstrap'

import { Attendee, AttendeeField, FormFieldType, ID, UnMaybe } from '../types'
import AttendeeImage from './AttendeeImage'
import TimeZonePicker from './TimeZonePicker'
import FormFieldControl from './FormFieldControl'
import LoadingButton from './LoadingButton'
import {
  useUpdateAttendeeMutation,
  UpdateAttendeeModalQuery as QueryData,
  Maybe,
  Timezone,
  useUpdateAttendeeModalLazyQuery,
  AttendeeFieldNode,
  AttendeeNode,
} from '../__generated__/graphql'
import { isRequired } from '../utils'
import Translate from './Translate'

gql`
  mutation UpdateAttendee($input: UpdateAttendeeInput!) {
    updatedAttendee: updateAttendee(input: $input) {
      data {
        id
        firstName
        lastName
        email
        timezone
        fieldSubmissions {
          edges {
            node {
              id
              fieldType
              slug
              order
              required
              visible
              name
              helpText
              value
              choices
            }
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

gql`
  query UpdateAttendeeModal($id: ID!) {
    attendee: getAttendeeById(id: $id) {
      id
      email
      firstName
      lastName
      timezone
      fieldSubmissions {
        edges {
          node {
            id
            fieldType
            slug
            order
            required
            visible
            name
            helpText
            value
            choices
          }
        }
      }
    }
  }
`

// State manager for operating on the approve attendees modal and the related mutation.
export const useUpdateAttendeeModal = () => {
  // Prep the query
  const [query, { data }] = useUpdateAttendeeModalLazyQuery()

  // If we've loaded the data, translate it into a a more useable format.
  const internalData: Maybe<InternalData> = React.useMemo(
    () => (data?.attendee ? queryDataToInternalData(data.attendee) : null),
    [data]
  )

  // Prep the mutation
  const [mutation, { loading: submitting }] = useUpdateAttendeeMutation()

  // Prep a piece of state that manages the attendee we're about to update.
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  // Function to call to trigger the mutation and handle errors.
  const submit = async (values: FormValues) => {
    // Gather up the form values and submit them.
    const res = await mutation({
      variables: {
        input: {
          id: internalData!.id,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          timezone: values.timezone,
          fieldSubmissions: internalData!.fieldSubmissions.map(
            attendeeField => ({
              fieldType: attendeeField.fieldType,
              slug: attendeeField.slug,
              order: attendeeField.order,
              required: attendeeField.required,
              visible: attendeeField.visible,
              name: attendeeField.name,
              helpText: attendeeField.helpText,
              choices: attendeeField.choices,

              // I'm not sure how to type this.  The issue comes from the fact that
              // FormValues is a mix of known and unknown attributes.  Maybe there
              // is a better way to do this.
              // @ts-ignore
              value: values[attendeeField.slug],
            })
          ),
        },
      },
    })

    // Check for graphQL errors
    if (res.errors) {
      console.error('Unexpected error updating attendee', res.errors)
      throw new Error('Unexpected error updating attendee')
    }

    // Check for validation errors
    if (res.data?.updatedAttendee?.errors) {
      console.error(
        'Validation error updating attendee',
        res.data.updatedAttendee.errors
      )
      throw new Error('Validation error updating attendee')
    }

    // If for some reason the expected data isn't present then generate
    // an error.
    if (!res.data?.updatedAttendee?.data) {
      console.error('No attendee data found in response', res)
      throw new Error('No attendee data found in response')
    }

    // Close the modal
    setIsOpen(false)

    // Return the attendee data
    return res.data.updatedAttendee.data!
  }

  return {
    // This is a little tricky to follow:
    // 1. When open is called, it will fetch the data necessary to draw this modal.
    // 2. When the data becomes available, it will be translated into internalData.
    // 3. internalData is passed out the toUpdate parameter so that callers can render the modal.
    open: async (attendeeId: ID) => {
      // TODO: we might want to introduce some sort of loadings state here...
      await query({ variables: { id: attendeeId } })

      // Open the modal
      setIsOpen(true)
    },
    close: () => setIsOpen(false),
    data: internalData,
    isOpen,
    submit,
    submitting,
  }
}

type InternalData = Pick<
  Attendee,
  'id' | 'email' | 'firstName' | 'lastName' | 'timezone'
> & {
  fieldSubmissions: Array<
    Pick<
      AttendeeField,
      | 'fieldType'
      | 'slug'
      | 'order'
      | 'required'
      | 'visible'
      | 'name'
      | 'helpText'
      | 'value'
      | 'choices'
    >
  >
}

export type Props = Readonly<
  {
    fieldSubmissions: ReadonlyArray<
      Pick<
        AttendeeFieldNode,
        | 'fieldType'
        | 'slug'
        | 'order'
        | 'required'
        // | 'visible'
        | 'name'
        | 'helpText'
        | 'choices'
        | 'value'
      >
    >
    submitting: boolean
    onCancel: VoidFunction
    onConfirm: (updatedValues: FormValues) => void
  } & Pick<AttendeeNode, 'firstName' | 'lastName' | 'email' | 'timezone'>
>

// The form values will have the attendee data, but it will also have
// the field submissions stored as key/values.  Because we can't predict
// what those are ahead of time, we can't type them, but by using an interface
// here we should be covered.
interface FormValues
  extends Pick<Attendee, 'email' | 'firstName' | 'lastName' | 'timezone'> {}

// Translates the query data into a more internal-friendly format.
const queryDataToInternalData = (
  queryData: UnMaybe<QueryData['attendee']>
): InternalData => ({
  ...queryData,
  fieldSubmissions: queryData.fieldSubmissions.edges.map(edge => ({
    ...edge!.node!,
  })),
})

const UpdateAttendeeModal: React.FC<Props> = ({
  firstName,
  lastName,
  email,
  timezone,
  fieldSubmissions,
  submitting,
  onConfirm,
  onCancel,
}) => (
  <Modal className="UpdateAttendeeModal" onHide={onCancel} show>
    <Modal.Header closeButton>
      <Media className="tw-items-center">
        {/* TODO: we should find a way to unify this with AttendeePortrait */}
        <AttendeeImage attendee={{ firstName, lastName, email }} size={36} />

        <div className="tw-ml-3">
          <span className="tw-text-bsGray-600">
            <Translate>Edit Attendee</Translate>
          </span>
          <Modal.Title className="tw-items-center tw-flex">
            {firstName} {lastName}
          </Modal.Title>
        </div>
      </Media>
    </Modal.Header>

    <FinalForm.Form<FormValues>
      initialValues={{
        email,
        firstName,
        lastName,
        timezone,
        // Spread the fieldSubmissions into the form values with the key
        // being the field slug and the value being the field value.
        ...fieldSubmissions.reduce(
          (acc, fieldSubmission) => ({
            ...acc,
            [fieldSubmission.slug]: fieldSubmission.value,
          }),
          {}
        ),
      }}
      onSubmit={onConfirm}
    >
      {({ handleSubmit }) => (
        <Form
          className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
          onSubmit={handleSubmit}
        >
          <Modal.Body>
            <Form.Group>
              <Form.Row>
                <Col>
                  <Form.Label>
                    <Translate>First Name</Translate>
                  </Form.Label>
                  <FinalForm.Field<string>
                    name="firstName"
                    validate={isRequired}
                  >
                    {({ input, meta }) => (
                      <Form.Control {...input} isInvalid={meta.invalid} />
                    )}
                  </FinalForm.Field>
                </Col>
                <Col>
                  <Form.Label>
                    <Translate>Last Name</Translate>
                  </Form.Label>
                  <FinalForm.Field<string>
                    name="lastName"
                    validate={isRequired}
                  >
                    {({ input, meta }) => (
                      <Form.Control {...input} isInvalid={meta.invalid} />
                    )}
                  </FinalForm.Field>
                </Col>
              </Form.Row>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <Translate>Email Address</Translate>
              </Form.Label>
              <FinalForm.Field<string> name="email" validate={isRequired}>
                {({ input, meta }) => (
                  <Form.Control {...input} isInvalid={meta.invalid} />
                )}
              </FinalForm.Field>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <Translate>Timezone</Translate>
              </Form.Label>
              <FinalForm.Field<Timezone> name="timezone" validate={isRequired}>
                {({ input, meta }) => (
                  <TimeZonePicker
                    required
                    onChange={input.onChange}
                    value={input.value}
                    isInvalid={meta.invalid}
                  />
                )}
              </FinalForm.Field>
            </Form.Group>

            {fieldSubmissions.length > 0 && (
              <>
                <hr />

                {fieldSubmissions.map(submission => (
                  <Form.Group key={submission.slug}>
                    <Form.Label>{submission.name}</Form.Label>
                    <FinalForm.Field name={submission.slug}>
                      {({ input }) => (
                        <>
                          <FormFieldControl
                            field={submission}
                            value={input.value}
                            onChange={input.onChange}
                          />

                          {submission.helpText &&
                            submission.fieldType !== FormFieldType.Toggle && (
                              <Form.Text>{submission.helpText}</Form.Text>
                            )}
                        </>
                      )}
                    </FinalForm.Field>
                  </Form.Group>
                ))}
              </>
            )}
          </Modal.Body>

          <Modal.Footer className="tw-items-stretch tw-justify-between md:tw-justify-end">
            <Button
              className="tw-flex-1 md:tw-flex-shrink md:tw-flex-grow-0"
              variant="outline-secondary"
              onClick={onCancel}
            >
              <Translate>Cancel</Translate>
            </Button>
            <LoadingButton
              className="tw-flex-1 md:tw-flex-shrink md:tw-flex-grow-0"
              loading={submitting}
              type="submit"
              variant="primary"
            >
              <Translate>Save Changes</Translate>
            </LoadingButton>
          </Modal.Footer>
        </Form>
      )}
    </FinalForm.Form>
  </Modal>
)

export default UpdateAttendeeModal
