import * as React from 'react'
import './TimeBlockUnit.scss'
import RemoveButton from './RemoveButton'
import { TimeBlock } from '../types'
import {
  DateTimeRange,
  Duration,
  isTimeBlockWithMinimumDuration,
  isTimeBlockWithValidTimeRange,
  translateString,
} from '../utils'
import TimeRangeInput from './TimeRangeInput'
import { Form } from 'react-bootstrap'
import Translate from './Translate'

export interface Props {
  value: TimeBlock
  onChange: (updated: TimeBlock) => void
  onDelete: () => void
  otherBlocksOnSameDay: TimeBlock[]
  minimumDuration?: Duration
}

// Here we want to make absolutely sure that the time label
// is being rendered in UTC so we set the timezone to that.
const TimeBlockUnit: React.FC<Props> = ({
  value,
  onChange,
  onDelete,
  otherBlocksOnSameDay,
  minimumDuration,
}) => {
  // Reuse the isTimeBlockWithMinimumDuration validator here.
  const isTooShortError =
    minimumDuration &&
    isTimeBlockWithMinimumDuration(
      minimumDuration,
      translateString(
        "Time range can't be less than the meeting length (%{duration}).",
        {
          duration: minimumDuration.toHuman(),
        }
      )
    )(value)

  // Check to see if any other timeblocks overlap with this one.
  const isOverlapping = otherBlocksOnSameDay.some(tb =>
    new DateTimeRange(tb.startTime, tb.endTime!).overlapsWith(
      new DateTimeRange(value.startTime, value.endTime!)
    )
  )

  // Reuse the isTimeBlockWithValidTimeRange validator to confirm
  // if the time block's start comes before it's end.
  const isInvalidError = isTimeBlockWithValidTimeRange()(value)

  return (
    <div className="TimeBlockUnit">
      <div className="d-flex align-items-center text-gray-800">
        <TimeRangeInput
          isInvalid={
            isTooShortError !== undefined ||
            isOverlapping ||
            isInvalidError !== undefined
          }
          value={new DateTimeRange(value.startTime, value.endTime!)}
          onChange={range =>
            onChange({
              ...value,
              startTime: range.startDateTime,
              endTime: range.endDateTime,
            })
          }
        />
        <div className="ml-4">
          <RemoveButton onClick={onDelete} title="Remove" />
        </div>
      </div>

      {isOverlapping && (
        <Form.Text className="text-danger">
          <Translate>This time range overlaps with another</Translate>.
        </Form.Text>
      )}

      {isInvalidError && (
        <Form.Text className="text-danger">{isInvalidError}</Form.Text>
      )}

      {isTooShortError && !isInvalidError && (
        <Form.Text className="text-danger">{isTooShortError}</Form.Text>
      )}
    </div>
  )
}

export default TimeBlockUnit
