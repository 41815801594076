import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form, Modal } from 'react-bootstrap'

import './UpdateScheduleModal.scss'
import DateRangeInput from './DateRangeInput'
import RemoveButton from './RemoveButton'
import ScheduleKindInput from './ScheduleKindInput'
import ScheduleTimezoneInput from './ScheduleTimezoneInput'
import TimeBlockTable from './TimeBlockTable'
import { Maybe, Timezone } from '../__generated__/graphql'
import { useScheduleData } from '../hooks'
import { Schedule, ScheduleKind, TimeBlock } from '../types'
import {
  DateTimeRange,
  Duration,
  composeValidators,
  isListOfTimeBlocksWithMinimumDuration,
  isListOfTimeBlocksWithNoOverlappingRanges,
  isListOfTimeBlocksWithValidTimeRanges,
} from '../utils'
import Translate from './Translate'

export type Props = {
  minimumTimeBlockDuration: Duration
  onChange: (schedule: Schedule) => void
  onHide: () => void
  schedule: Schedule
}

type FormValues = Pick<Schedule, 'name' | 'kind' | 'timezone'> & {
  fixedBounds: DateTimeRange
  fixedTimeBlocks: TimeBlock[]
  recurringBounds: Maybe<DateTimeRange>
  recurringTimeBlocks: TimeBlock[]
}

const UpdateScheduleModal: React.FC<Props> = ({
  minimumTimeBlockDuration,
  onChange,
  onHide,
  schedule,
}) => {
  const { fixedBounds, fixedTimeBlocks, recurringBounds, recurringTimeBlocks } =
    useScheduleData(schedule)

  return (
    <Modal
      className="UpdateScheduleModal"
      // We override the default so that Overlays & Popovers
      // are focusable.\
      // https://github.com/react-bootstrap/react-bootstrap/issues/1551#issuecomment-162875520
      enforceFocus={false}
      onHide={onHide}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Translate>Update Schedule</Translate>
        </Modal.Title>
      </Modal.Header>

      <FinalForm.Form<FormValues>
        initialValues={{
          fixedBounds,
          fixedTimeBlocks,
          kind: schedule.kind,
          name: schedule.name || '',
          recurringBounds,
          recurringTimeBlocks,
          timezone: schedule.timezone,
        }}
        onSubmit={values =>
          onChange({
            ...schedule,
            bounds:
              values.kind === ScheduleKind.Recurring
                ? values.recurringBounds
                : values.fixedBounds,
            kind: values.kind,
            name: values.name,
            timeBlocks:
              values.kind === ScheduleKind.Recurring
                ? values.recurringTimeBlocks
                : values.fixedTimeBlocks.filter(tb =>
                    values.fixedBounds.isBetween(tb.date!)
                  ),
            timezone: values.timezone,
          })
        }
      >
        {({ handleSubmit }) => (
          <Form
            className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto md:tw-block md:tw-overflow-y-visible"
            onSubmit={handleSubmit}
          >
            <Modal.Body>
              <FinalForm.Field<string> name="name">
                {({ input }) => (
                  <Form.Group>
                    <Form.Label>
                      <Translate>Nickname</Translate>
                    </Form.Label>
                    <Form.Control
                      onChange={input.onChange}
                      value={input.value}
                    />
                  </Form.Group>
                )}
              </FinalForm.Field>

              <FinalForm.Field<ScheduleKind> name="kind">
                {({ input: kindInput }) => (
                  <>
                    <div className="tw-flex tw-flex-col tw-w-full tw-space-y-3 tw-mb-4 md:tw-flex-row md:tw-space-x-3 md:tw-space-y-0">
                      <ScheduleKindInput
                        onChange={kindInput.onChange}
                        value={kindInput.value}
                      />

                      <div className="tw-flex tw-items-center md:tw-ml-3">
                        {kindInput.value === ScheduleKind.Recurring && (
                          <FinalForm.Field<
                            Maybe<DateTimeRange>
                          > name="recurringBounds">
                            {({ input: { onChange, value: bounds } }) => (
                              <>
                                {!bounds && (
                                  <Button
                                    onClick={() => {
                                      // When creating initial recurring bounds we just use the fixed
                                      // bounds which have a sane default and were possibly customized
                                      // by the user.
                                      onChange(fixedBounds)
                                    }}
                                    variant="link"
                                  >
                                    <Translate>Repeats between...</Translate>
                                  </Button>
                                )}
                                {bounds && (
                                  <div className="align-items-center d-flex">
                                    <DateRangeInput
                                      onChange={updatedBounds => {
                                        // Update the schedule
                                        onChange(updatedBounds)
                                      }}
                                      value={bounds}
                                    />
                                    <RemoveButton
                                      className="ml-12"
                                      onClick={() => {
                                        // Update the schedule
                                        onChange(null)
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </FinalForm.Field>
                        )}

                        {kindInput.value === ScheduleKind.FixedRange && (
                          <FinalForm.Field<DateTimeRange> name="fixedBounds">
                            {({ input }) => (
                              <DateRangeInput
                                onChange={input.onChange}
                                value={input.value}
                              />
                            )}
                          </FinalForm.Field>
                        )}
                      </div>
                    </div>

                    {kindInput.value === ScheduleKind.Recurring && (
                      <FinalForm.Field<TimeBlock[]> name="recurringTimeBlocks">
                        {({ input }) => (
                          <TimeBlockTable
                            minimumTimeBlockDuration={minimumTimeBlockDuration}
                            onChange={input.onChange}
                            scheduleBounds={null}
                            scheduleKind={ScheduleKind.Recurring}
                            value={input.value}
                          />
                        )}
                      </FinalForm.Field>
                    )}

                    {kindInput.value === ScheduleKind.FixedRange && (
                      <FinalForm.Field<DateTimeRange> name="fixedBounds">
                        {({ input: { value: bounds } }) => (
                          <FinalForm.Field<TimeBlock[]>
                            name="fixedTimeBlocks"
                            validate={composeValidators(
                              isListOfTimeBlocksWithNoOverlappingRanges(),
                              isListOfTimeBlocksWithValidTimeRanges(),
                              isListOfTimeBlocksWithMinimumDuration(
                                minimumTimeBlockDuration
                              )
                            )}
                          >
                            {({ input }) => (
                              <TimeBlockTable
                                onChange={input.onChange}
                                scheduleBounds={
                                  new DateTimeRange(
                                    bounds.startDateTime,
                                    bounds.endDateTime
                                  )
                                }
                                scheduleKind={ScheduleKind.FixedRange}
                                value={input.value}
                              />
                            )}
                          </FinalForm.Field>
                        )}
                      </FinalForm.Field>
                    )}
                  </>
                )}
              </FinalForm.Field>

              <div className="mt-16 d-flex">
                <FinalForm.Field<Timezone> name="timezone">
                  {({ input }) => (
                    <ScheduleTimezoneInput
                      onChange={input.onChange}
                      value={input.value}
                    />
                  )}
                </FinalForm.Field>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                className="tw-flex-1 md:tw-flex-shrink md:tw-flex-grow-0"
                type="submit"
              >
                <Translate>Save Changes</Translate>
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </FinalForm.Form>
    </Modal>
  )
}

export default UpdateScheduleModal
