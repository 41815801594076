import * as React from 'react'

import Icon from './Icon'
import Panel from './Panel'
import PanelBody from './PanelBody'
import PanelButton from './PanelButton'
import PanelDescription from './PanelDescription'
import PanelIcon from './PanelIcon'
import PanelTitle from './PanelTitle'
import ScheduleDescription from './ScheduleDescription'
import { Schedule, ScheduleKind } from '../types'
import RemoveButton from './RemoveButton'

interface Props {
  onClick: () => void
  onDelete: () => void
  schedule: Schedule
}

const SchedulePanel: React.FC<Props> = ({ onClick, onDelete, schedule }) => {
  return (
    <Panel onClick={onClick}>
      <PanelIcon>
        {schedule.kind === ScheduleKind.Recurring ? (
          <Icon.Repeat className="tw-text-[#ffb800]" size={30} />
        ) : (
          <Icon.Calendar className="tw-text-[#9b59b6]" size={30} />
        )}
      </PanelIcon>
      <PanelBody>
        <PanelTitle>{schedule.name}</PanelTitle>
        <PanelDescription>
          <ScheduleDescription schedule={schedule} />
        </PanelDescription>
      </PanelBody>
      <PanelButton>
        <RemoveButton
          onClick={e => {
            // This is required to prevent the onClick event from bubbling
            // up to the parent (<Panel />) which would cause it's `onClick`
            // event to fire as well.
            // https://stackoverflow.com/questions/1369035/how-do-i-prevent-a-parents-onclick-event-from-firing-when-a-child-anchor-is-cli
            e.stopPropagation()
            onDelete()
          }}
        />
      </PanelButton>
    </Panel>
  )
}

export default SchedulePanel
