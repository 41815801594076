import * as React from 'react'
import './LoadingButton.scss'
import { Button, ButtonProps } from 'react-bootstrap'
import { ReplaceProps } from 'react-bootstrap/helpers'
import Spinner from './Spinner'

interface Props {
  light?: boolean
  loading?: boolean
}

const LoadingButton: React.FC<
  Props & ReplaceProps<React.ElementType<any>, ButtonProps>
> = ({ light, loading, className, children, disabled, ...rest }) => (
  <Button
    className={`LoadingButton ${className || ''} ${loading ? 'loading' : ''}`}
    disabled={loading ? true : disabled}
    {...rest}
  >
    {loading && <Spinner light={light} size={20} />}
    <div className="LoadingButton--content">{children}</div>
  </Button>
)

export default LoadingButton
