import * as React from 'react'

import TopNav from './TopNav'

interface Props extends React.HTMLProps<HTMLDivElement> {
  onboarding?: boolean
}

const Page: React.FC<Props> = ({
  children,
  className,
  onboarding,
  ...rest
}) => (
  <div className="Page" {...rest}>
    <TopNav onboarding={onboarding} />
    <div className={className}>{children}</div>
  </div>
)

export default Page
