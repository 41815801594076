import * as React from 'react'
import { Dropdown, Media } from 'react-bootstrap'
import IconButton from '../../../IconButton'
import './MeetingDetailAttendeeUnit.scss'
import Badge from '../../../Badge'
import DropdownItem from '../../../DropdownItem'
import Icon from '../../../Icon'
import TooltipOverlay from '../../../TooltipOverlay'
import MoreButton from '../../../MoreButton'
import {
  AttendeeFormResponses,
  Props as AttendeeFormResponsesProps,
} from '../AttendeeFormResponses/AttendeeFormResponses'
import { AttendeeNode, Currency } from '../../../../__generated__/graphql'
import TimeZoneLabel from '../../../TimeZoneLabel'
import ExternalLink from '../../../ExternalLink'
import AttendeePortrait from '../AttendeePortrait/AttendeePortrait'
import Now from '../../../Now'
import TimeLabel from '../../../TimeLabel'

// We separate these props out so that we can import them in other
// components that feed this one.
export type AttendeeProps = Readonly<
  Pick<
    AttendeeNode,
    'firstName' | 'lastName' | 'email' | 'isHost' | 'timezone'
  > & {
    image: string | null
    status: 'going' | 'not-going' | 'pending' | null
    isCurrentUser: boolean

    paymentAmount: number | null
    paymentCurrency: Currency | null
    paymentUrl: string | null
  } & AttendeeFormResponsesProps
>

export type Props = Readonly<
  AttendeeProps & {
    meetingHasConcluded: boolean

    // Actions
    onApprove?: () => void
    onDecline?: () => void
    onCancel?: () => void
    onEdit?: () => void
  }
>

export const MeetingDetailAttendeeUnit: React.FC<Props> = ({
  firstName,
  lastName,
  email,
  isHost,
  timezone,
  image,
  status,
  paymentAmount,
  paymentCurrency,
  paymentUrl,
  isCurrentUser,
  fieldSubmissions,
  meetingHasConcluded,
  onApprove,
  onDecline,
  onCancel,
  onEdit,
}) => (
  <div
    className={`MeetingDetailAttendeeUnit d-flex flex-column ${
      status === 'not-going' ? 'cancelled' : ''
    }`}
  >
    <Media className="align-items-center space-between-12">
      <AttendeePortrait
        image={image}
        firstName={firstName!}
        lastName={lastName!}
        status={status}
      />

      <Media.Body className="text-truncate">
        <div className="d-flex align-items-center">
          <strong>{isCurrentUser ? 'You' : `${firstName} ${lastName}`}</strong>

          <div className="text-gray-400 d-flex flex-row ml-4">
            <TooltipOverlay
              id="TimezoneTooltip"
              text={
                <Now>
                  {now => (
                    <>
                      <div>
                        Currently{' '}
                        <TimeLabel
                          format="localized-time"
                          time={now}
                          timezone={timezone}
                        />
                      </div>
                      <TimeZoneLabel timezone={timezone} />
                    </>
                  )}
                </Now>
              }
            >
              <div className="mr-4">
                <Icon.Globe size={16} />
              </div>
            </TooltipOverlay>

            {paymentAmount && paymentCurrency && paymentUrl && (
              <TooltipOverlay
                id="PaidMeetingTooltip"
                text="This attendee has paid."
              >
                <div className="mr-4">
                  <ExternalLink href={paymentUrl}>
                    <Icon.CreditCard size={16} />
                  </ExternalLink>
                </div>
              </TooltipOverlay>
            )}
          </div>
        </div>

        <a href={`mailto:${email}`} className="text-gray-600">
          {email}
        </a>
      </Media.Body>

      {isHost && <Badge variant="success">Host</Badge>}

      {!isHost && (
        <div className="d-flex space-between-16">
          {/* Approve/Decline buttons */}
          {status === 'pending' && !meetingHasConcluded && (
            <>
              <IconButton
                onClick={onApprove}
                placement="top"
                variant="success"
                tooltip="Approve"
              >
                <Icon.ThumbsUp size="20" />
              </IconButton>
              <IconButton
                onClick={onDecline}
                placement="top"
                variant="danger"
                tooltip="Decline"
              >
                <Icon.ThumbsDown size="20" />
              </IconButton>
            </>
          )}

          <MoreButton alignRight size="sm">
            <DropdownItem onClick={onEdit}>
              <Icon.Edit size={18} />
              Edit Info
            </DropdownItem>

            {status === 'going' && !meetingHasConcluded && (
              <>
                <Dropdown.Divider />

                <DropdownItem onClick={onCancel} className="text-danger">
                  <Icon.XCircle size={18} />
                  Cancel Invitation...
                </DropdownItem>
              </>
            )}
          </MoreButton>
        </div>
      )}
    </Media>

    {fieldSubmissions.length > 0 && (
      <div className="mt-24 w-100">
        <AttendeeFormResponses fieldSubmissions={fieldSubmissions} />
      </div>
    )}
  </div>
)
