import * as React from 'react'
import MeetingDetailNoteUnit, {
  AttendeeNote,
} from '../MeetingDetailNoteUnit/MeetingDetailNoteUnit'

export interface NotesListProps {
  readonly notes: ReadonlyArray<AttendeeNote>
  readonly editCallback: (note: AttendeeNote) => void
  readonly deleteCallback: (id: string) => void
  readonly loggedInUserId: string
}

const NotesList: React.FC<NotesListProps> = ({
  notes,
  editCallback,
  deleteCallback,
  loggedInUserId,
}: NotesListProps) => {
  return (
    <div>
      {notes.map((note, i) => {
        return (
          <div
            key={`note:${note.id}`}
            className={`${
              i === notes.length - 1 ? undefined : 'border-bottom'
            } py-16`}
          >
            <MeetingDetailNoteUnit
              attendeeNote={{
                ...note,
                isLoggedInUser: loggedInUserId === note.userId,
              }}
              editCallback={editCallback}
              deleteCallback={deleteCallback}
            />
          </div>
        )
      })}
    </div>
  )
}

export default NotesList
