import * as React from 'react'
import { Media } from 'react-bootstrap'
import MemberSettingsListItemMenu from './MemberSettingsListItemMenu'
import UserImage from './UserImage'
import UserLabel from './UserLabel'
import { Maybe, RoleNode } from '../__generated__/graphql'
import { Member, Role, User } from '../types'
import TooltipOverlay from './TooltipOverlay'
import Disabler from './Disabler'
import LoadingButton from './LoadingButton'
import Toggler from './Toggler'
import { DateTime } from '../utils'
import Translate from './Translate'
import Var from './Var'

interface Props {
  member: Pick<
    Member,
    '__typename' | 'id' | 'invitationAccepted' | 'isOwner' | 'isSchedulable'
  > & {
    deleted: Maybe<DateTime>
    role: Pick<Role, '__typename' | 'id' | 'name'>
    user: Pick<
      User,
      '__typename' | 'email' | 'firstName' | 'id' | 'image' | 'lastName'
    >
  }
  isCurrentUser: boolean
  roles: Pick<RoleNode, 'id' | 'name'>[]
  onRoleChange: (role: Pick<RoleNode, 'id' | 'name'>) => void
  onRemoveMember: () => void
  onTransferOwnership?: () => void
  onResendInvite?: () => Promise<unknown>
}

const MemberSettingsListItem: React.FC<Props> = ({
  member,
  isCurrentUser,
  roles,
  onResendInvite,
  onRoleChange,
  onRemoveMember,
  onTransferOwnership,
}) => (
  <Media className="tw-flex-col tw-py-4 tw-space-y-2 md:tw-flex-row md:tw-space-y-0 md:tw-justify-between md:tw-items-center">
    <div className="tw-flex tw-space-x-2 md:tw-space-x-3">
      <UserImage size={40} user={member.user} />
      <Media.Body className="tw-flex tw-flex-col">
        <UserLabel user={member.user} />
        <Translate as="p">
          <a href={`mailto:${member.user.email}`}>
            <Var name="email">{member.user.email}</Var>
          </a>
        </Translate>
      </Media.Body>
    </div>

    {!member.invitationAccepted && onResendInvite && (
      <Toggler>
        {({ isToggled, setOff, setOn }) => (
          <LoadingButton
            variant="outline-success"
            loading={isToggled}
            onClick={async () => {
              setOn()
              await onResendInvite()
              setOff()
            }}
          >
            <Translate>Resend Invite</Translate>
          </LoadingButton>
        )}
      </Toggler>
    )}

    {member.isOwner && (
      <span className="tw-text-bsGray-600 tw-ml-[48px] md:tw-ml-0">
        <Translate>Owner</Translate>
      </span>
    )}

    {!member.isOwner && (
      <TooltipOverlay
        id="no-update-self-tooltip"
        text="You can't update your own role."
        disabled={!isCurrentUser}
      >
        {/*This extra div is here so that the tooltip works*/}
        <div className="tw-ml-[48px] md:tw-ml-0">
          <Disabler disabled={isCurrentUser}>
            <MemberSettingsListItemMenu
              role={member.role}
              roles={roles}
              onRoleChange={onRoleChange}
              onRemoveMember={onRemoveMember}
              onTransferOwnership={onTransferOwnership}
            />
          </Disabler>
        </div>
      </TooltipOverlay>
    )}
  </Media>
)

export default MemberSettingsListItem
