import * as React from 'react'
import { Card, Form } from 'react-bootstrap'
import { AttendeeField } from '../../../../types'
import FormFieldValue from '../../../FormFieldValue'
import './AttendeeFormResponses.scss'

export type Props = Readonly<{
  fieldSubmissions: ReadonlyArray<
    Readonly<Pick<AttendeeField, 'value' | 'fieldType' | 'name'>>
  >
}>

export const AttendeeFormResponses: React.FC<Props> = ({
  fieldSubmissions,
}) => (
  <Card className="AttendeeFormResponses border-0 shadow-none p-12">
    {fieldSubmissions.map(submission => (
      <Form.Group className="d-flex flex-column" key={submission.name}>
        <Form.Label className="text-gray-900">{submission.name}</Form.Label>
        <span>
          <FormFieldValue
            fieldType={submission.fieldType}
            value={submission.value}
          />
        </span>
      </Form.Group>
    ))}
  </Card>
)
