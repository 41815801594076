import * as React from 'react'
import { OnboardingShell } from '../../molecules/OnboardingShell/OnboardingShell'
import { OnboardTitle } from '../../molecules/OnboardTitle/OnboardTitle'
import { Button, Form, Media } from 'react-bootstrap'
import LoadingButton from '../../../LoadingButton'
import DurationInput from '../../../DurationInput'
import { Duration } from '../../../../utils'
import * as FinalForm from 'react-final-form'
import MeetingTypeIsGroupPicker from '../../../MeetingTypeIsGroupPicker'
import {
  isRequired,
  isFormFieldInvalid,
  composeValidators,
  durationGreaterThan,
  durationLessThan,
} from '../../../../utils/validators'
import FormErrorSubscription from '../../../FormErrorSubscription'

export interface CreateMeetingTypeScreenProps {
  readonly email: string
  readonly isSubmitting: boolean
  readonly initialName: string
  readonly initialDuration: Duration
  readonly initialIsGroup: boolean
  readonly onSkip?: () => void
  readonly onSubmit?: (formValues: FormValues) => void
}

export interface FormValues {
  readonly name: string
  readonly duration: Duration
  readonly isGroup: boolean
}

const minDuration = new Duration({ minutes: 5 })
const maxDuration = new Duration({ hours: 8 })

export const CreateMeetingTypeScreen: React.FC<
  CreateMeetingTypeScreenProps
> = ({
  email,
  onSkip,
  onSubmit,
  isSubmitting,
  initialName,
  initialDuration,
  initialIsGroup,
}) => (
  <OnboardingShell email={email} currentTask="CreateMeetingType">
    <OnboardTitle
      title="Let's create your first type of meeting"
      subtitle="Think of a common meeting you need scheduled"
    />

    <FinalForm.Form<FormValues>
      initialValues={{
        name: initialName,
        duration: initialDuration,
        isGroup: initialIsGroup,
      }}
      // We set this to always be true because we don't want the caller to be able
      // to change the initial values on later renders.  This would cause a bug where
      // the form would reset.
      initialValuesEqual={() => true}
      onSubmit={async values => onSubmit && (await onSubmit(values))}
    >
      {({ handleSubmit }) => (
        <>
          <div className="pt-24 pb-12">
            <Media className="d-block d-md-flex mb-24">
              <Media.Body className="mr-md-16 mb-24 mb-md-0">
                <FinalForm.Field<string>
                  name="name"
                  type="text"
                  validate={isRequired}
                >
                  {({ input, meta }) => (
                    <Form.Group>
                      <Form.Label>What do you call this meeting?</Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Example: Initial Consultation"
                        isInvalid={isFormFieldInvalid(meta)}
                        maxLength={150}
                        autoFocus
                        {...input}
                      />
                    </Form.Group>
                  )}
                </FinalForm.Field>
              </Media.Body>

              <FinalForm.Field<Duration>
                name="duration"
                validate={composeValidators(
                  durationGreaterThan(minDuration),
                  durationLessThan(maxDuration)
                )}
              >
                {({ input, meta }) => (
                  <Form.Group>
                    <Form.Label>Meeting Length</Form.Label>

                    <DurationInput
                      value={input.value}
                      onChange={input.onChange}
                      isInvalid={isFormFieldInvalid(meta)}
                      onBlur={input.onBlur}
                      onFocus={input.onFocus}
                    />

                    <FormErrorSubscription name="duration" />
                  </Form.Group>
                )}
              </FinalForm.Field>
            </Media>

            <Form.Group>
              <Form.Label>What kind of meeting is this?</Form.Label>

              <FinalForm.Field<boolean> name="isGroup">
                {({ input }) => (
                  <MeetingTypeIsGroupPicker
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </FinalForm.Field>
            </Form.Group>
          </div>

          <div className="pt-32 d-flex flex-row justify-content-between space-between">
            <Button onClick={onSkip} type="button" variant="link">
              I'll do this later
            </Button>

            <LoadingButton
              type="submit"
              variant="success"
              loading={isSubmitting}
              onClick={handleSubmit}
            >
              Save & Continue
            </LoadingButton>
          </div>
        </>
      )}
    </FinalForm.Form>
  </OnboardingShell>
)
