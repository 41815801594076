import * as React from 'react'
import { Button, Media, Modal } from 'react-bootstrap'
import { gql } from '@apollo/client'

import ChangePasswordModal from './ChangePasswordModal'
import Icon from './Icon'
import Toggler from './Toggler'
import { useSettingsModalLoginSectionQuery } from '../__generated__/graphql'
import { DateTime } from '../utils'
import Translate from './Translate'
import Var from './Var'
import SettingsSectionPicker from './SettingsSectionPicker'
import { SettingsSection } from './SettingsModal'

gql`
  query SettingsModalLoginSection {
    user {
      id
      passwordUpdatedAt
    }
  }
`

type Props = {
  onSelect: (section: SettingsSection) => void
}

const SettingsModalLoginSection: React.FC<Props> = ({ onSelect }) => {
  const { data, loading } = useSettingsModalLoginSectionQuery()

  return (
    <div className="SettingsModalLoginSection tw-w-full">
      <Modal.Header closeButton>
        <Icon.Lock className="tw-hidden tw-text-bsGray-600 lg:tw-block" />
        <div className="title-block tw-hidden lg:tw-block">
          <Modal.Title as="h2" className="tw-hidden md:tw-block">
            <Translate>Login & Password</Translate>
          </Modal.Title>
          <Translate as="p">Set your security preferences.</Translate>
        </div>
        <SettingsSectionPicker onChange={onSelect} value="login" />
      </Modal.Header>
      <Modal.Body className="tw-flex tw-flex-col tw-space-y-6">
        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-items-center md:tw-justify-between md:tw-space-y-0">
          <div className="tw-flex tw-space-x-2 tw-items-center md:tw-space-x-4">
            <Icon.InternalAsset assetName="Password" size={50} />

            <Media.Body className="tw-flex-col">
              <Translate as="strong">Change Password</Translate>

              <p className="tw-text-bsGray-600">
                {!loading && data && data.user?.passwordUpdatedAt ? (
                  <Translate>
                    Last changed{' '}
                    <Var name="lastUpdatedAt">
                      {new DateTime()
                        .diff(new DateTime(data.user.passwordUpdatedAt))
                        .asDays()}
                    </Var>{' '}
                    days ago.
                  </Translate>
                ) : (
                  <Translate>Password has not been updated before.</Translate>
                )}
              </p>
            </Media.Body>
          </div>

          <Toggler>
            {({ isToggled, setOff, setOn }) => (
              <>
                <Button
                  className="tw-ml-[58px] md:tw-ml-0"
                  onClick={setOn}
                  variant="link"
                >
                  <Translate>Change password</Translate>
                </Button>
                {isToggled && (
                  <ChangePasswordModal onHide={setOff} onSuccess={setOff} />
                )}
              </>
            )}
          </Toggler>
        </Media>

        <hr className="tw-w-full" />

        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-items-center md:tw-justify-between md:tw-space-y-0">
          <div className="tw-flex tw-space-x-2 tw-items-center md:tw-space-x-4">
            <Icon.InternalAsset assetName="2FA" size={50} />

            <Media.Body className="tw-flex-col">
              <Translate as="strong">Two-Factor Authentication</Translate>
              <p className="tw-text-bsGray-600">
                <Translate>Not enabled for your account.</Translate>
              </p>
            </Media.Body>
          </div>

          <Button className="tw-ml-[58px] md:tw-ml-0" disabled variant="link">
            <Translate>Coming soon</Translate>
          </Button>
        </Media>

        <hr className="tw-w-full" />

        <Media className="tw-flex-col tw-space-y-2 md:tw-flex-row md:tw-items-center md:tw-justify-between md:tw-space-y-0">
          <div className="tw-flex tw-space-x-2 tw-items-center md:tw-space-x-4">
            <Icon.InternalAsset assetName="SSO" size={50} />

            <Media.Body className="tw-flex-col">
              <Translate as="strong">Single Sign-On</Translate>
              <p className="tw-text-bsGray-600">
                <Translate>
                  Connect a calendar integration to use it as a SSO provider.
                </Translate>
              </p>
            </Media.Body>
          </div>
        </Media>
      </Modal.Body>
    </div>
  )
}

export default SettingsModalLoginSection
