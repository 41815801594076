import * as React from 'react'
import { CalendarSettingsView } from '../../views/CalendarSettingsView/CalendarSettingsView'
import { OnboardingShell } from '../../molecules/OnboardingShell/OnboardingShell'
import { CalendarProviderNode, Maybe } from '../../../../__generated__/graphql'
import { Calendar } from '../../../../types'

export interface CalendarSettingsScreenProps {
  readonly email: string
  readonly calendarType: CalendarProviderNode['name']
  readonly onSubmitCallback: () => Promise<void>
  readonly skipSectionCallback: () => void
  changeAddMeetingCallback: () => void
  changeCheckConflictCallback: () => void
  meetingsCalendar: Maybe<Pick<Calendar, 'id' | 'name' | 'color'>>
  availabilityCalendars: Array<Pick<Calendar, 'id' | 'name' | 'color'>>
  isSingleUser?: boolean
}

export const CalendarSettingsScreen: React.FC<CalendarSettingsScreenProps> = ({
  email,
  calendarType,
  onSubmitCallback,
  skipSectionCallback,
  changeAddMeetingCallback,
  changeCheckConflictCallback,
  meetingsCalendar,
  availabilityCalendars,
}: CalendarSettingsScreenProps) => {
  return (
    <OnboardingShell email={email} currentTask="ConnectYourCalendar">
      <CalendarSettingsView
        calendarType={calendarType}
        doLaterOnClick={skipSectionCallback}
        onSubmit={onSubmitCallback}
        changeAddMeetingSettingOnClick={changeAddMeetingCallback}
        changeCheckConflictSettingOnClick={changeCheckConflictCallback}
        meetingsCalendar={meetingsCalendar}
        availabilityCalendars={availabilityCalendars}
      />
    </OnboardingShell>
  )
}
