import * as React from 'react'
import * as FinalForm from 'react-final-form'
import { Button, Form } from 'react-bootstrap'
import PopoverOverlay from './PopoverOverlay'

import './MeetingTypeTimezonePopover.scss'
import UpdateMeetingTypeContext, {
  IMeetingType,
} from './UpdateMeetingTypeContext'
import TimeZonePicker from './TimeZonePicker'
import { Maybe, Timezone } from '../__generated__/graphql'
import { DateTime } from '../utils'

type Props = {
  onChange: (value: Maybe<Timezone>) => void
  value: Maybe<Timezone>
}

type FormValues = {
  timezone: Maybe<Timezone>
}

const MeetingTypeTimezonePopover: React.FC<Props> = ({ onChange, value }) => {
  const meetingType = React.useContext(UpdateMeetingTypeContext)
  const [localTimezone, setLocalTimezone] = React.useState(
    meetingType.usesLocalTimezone
  )
  return (
    <PopoverOverlay
      popover={({ close }) => (
        <FinalForm.Form<FormValues>
          initialValues={{
            timezone: value || new DateTime().getTimeZoneEnum(),
          }}
          onSubmit={values => {
            onChange(values.timezone)

            // close the popover
            close()
          }}
        >
          {({ handleSubmit, submitting, values }) => (
            <Form
              onSubmit={handleSubmit}
              className="MeetingTypeTimezonePopover"
            >
              <FinalForm.Field<IMeetingType['timezone']> name="timezone">
                {({
                  input: { onChange: onSetTimezone, value: timezone },
                  meta,
                }) => (
                  <React.Fragment>
                    <Form.Group className="mb-12">
                      <Form.Check
                        checked={!localTimezone}
                        label="Show times in the user’s timezone"
                        onChange={() => {
                          setLocalTimezone(false)
                          onSetTimezone(null)
                        }}
                        type="radio"
                        value={String(false)}
                        id="localTimezone"
                      />
                    </Form.Group>
                    <Form.Group className="mb-12">
                      <Form.Check
                        checked={localTimezone}
                        label="Use a specific timezone"
                        onChange={() => setLocalTimezone(true)}
                        type="radio"
                        value={String(true)}
                        id="specificTimezone"
                      />
                    </Form.Group>

                    <Form.Group className="mb-12">
                      <TimeZonePicker
                        required
                        disabled={!localTimezone}
                        onChange={onSetTimezone}
                        value={timezone!}
                        isInvalid={meta.invalid}
                      />
                    </Form.Group>
                  </React.Fragment>
                )}
              </FinalForm.Field>
              <Button
                block
                disabled={(localTimezone && !values.timezone) || submitting}
                size="sm"
                type="submit"
              >
                Apply
              </Button>
            </Form>
          )}
        </FinalForm.Form>
      )}
    >
      <Button className="ml-4" variant="link">
        Customize
      </Button>
    </PopoverOverlay>
  )
}

export default MeetingTypeTimezonePopover
